import type { TFunction } from 'i18next';
import { BookingStatus } from 'generated/api';

export const getBookingStatusMultiSelectData = (t: TFunction) =>
  [
    BookingStatus.PENDING,
    BookingStatus.CONFIRMED,
    BookingStatus.IN_PROGRESS,
    BookingStatus.COMPLETED,
    BookingStatus.CANCELLED,
  ].map((value) => ({
    value: String(value),
    label: t(`booking.statuses.${value}`),
    selected: false,
    transformLabel: true,
  }));
