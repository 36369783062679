import { BookingStatus } from 'generated/api';

export const bookingRequestQuery = {
  status: [BookingStatus.PENDING, BookingStatus.CONFIRMED],
};

export const bookingsInTransportationQuery = {
  status: [BookingStatus.IN_PROGRESS],
};

export const tasksRequestQuery = {
  hasOpenTasks: true,
};

export const exceptionsRequestQuery = {
  hasExceptions: true,
};
