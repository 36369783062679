import React from 'react';
import clsx from 'clsx';
import styles from './styles.module.scss';

type TProps = {
  error?: string;
  hint?: string | boolean;
  className?: string;
};

export const TextFieldMeta: React.FC<TProps> = ({ error, hint, className }) => (
  <div data-testid="TextFieldMetaRoot" className={clsx(styles.meta, className)}>
    {error && (
      <div className={styles.error} data-testid="TextFieldMetaError">
        {error}
      </div>
    )}
    {hint && (
      <div className={styles.hint} data-testid="TextFieldMetaHint">
        {hint}
      </div>
    )}
  </div>
);
