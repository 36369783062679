import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ApiError, ErrorType } from 'generated/api';
import { Toast, TOAST_TYPE } from './toast';

interface ToastContextType {
  toast: {
    isOpen: boolean;
    message: string;
    type: TOAST_TYPE;
  };
  playInfoToast: (msg?: string) => void;
  playWarningToast: (msg?: string) => void;
  playErrorToast: (msg?: string) => void;
  playSuccessToast: (msg?: string) => void;
  playApiErrorToast: (err: ApiError) => void;
}

const ToastContext = React.createContext<ToastContextType>({
  toast: {
    isOpen: false,
    message: '',
    type: TOAST_TYPE.INFO,
  },
  playInfoToast: () => {},
  playErrorToast: () => {},
  playSuccessToast: () => {},
  playWarningToast: () => {},
  playApiErrorToast: () => {},
});

const ContextProvider = ToastContext.Provider;

export default ToastContext;

export const ToastProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const { t } = useTranslation('default', { keyPrefix: 'components/toast' });
  const { t: errorT } = useTranslation('default', { keyPrefix: 'errors' });

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');
  const [toastType, setToastType] = useState<TOAST_TYPE>(TOAST_TYPE.INFO);

  useEffect(() => {
    if (isOpen) {
      const timer = setTimeout(() => {
        setIsOpen(false);
      }, 8000);

      return () => clearTimeout(timer);
    }
  }, [isOpen]);

  const handleOpenToast = () => {
    if (isOpen) {
      setIsOpen(false);
      setTimeout(() => {
        setIsOpen(true);
      }, 100);
    } else {
      setIsOpen(true);
    }
  };

  const setToast = (toOpen: boolean, msg: string, toToastType: TOAST_TYPE) => {
    if (toOpen) {
      handleOpenToast();
    } else {
      setIsOpen(false);
    }

    setMessage(msg);
    setToastType(toToastType);
  };

  const playErrorToast = (msg = t('error')) => {
    setToast(true, msg, TOAST_TYPE.ERROR);
  };

  const playInfoToast = (msg = t('info')) => {
    setToast(true, msg, TOAST_TYPE.INFO);
  };

  const playSuccessToast = (msg = t('success')) => {
    setToast(true, msg, TOAST_TYPE.SUCCESS);
  };

  const playWarningToast = (msg = t('warning')) => {
    setToast(true, msg, TOAST_TYPE.WARNING);
  };

  const playApiErrorToast = (err: ApiError) => {
    setToast(true, errorT(err.errorType || ErrorType.SERVER_ERROR), TOAST_TYPE.ERROR);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const value = {
    toast: { isOpen, message, type: toastType },
    setToast,
    playWarningToast,
    playInfoToast,
    playSuccessToast,
    playErrorToast,
    playApiErrorToast,
  };

  return (
    <ContextProvider value={value}>
      <div>
        {children}
        {isOpen && <Toast message={message} type={toastType} onClose={handleClose} />}
      </div>
    </ContextProvider>
  );
};
