import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@tanstack/react-query';
import { ContentBox } from 'components/contentBox';
import { Input } from 'components/input';
import { passwordAccountValues, PasswordAccountValues } from 'constants/account';
import { SavingButtonSection } from 'components/savingButtonSection';
import { updatePassword } from 'api/user';
import { UpdatePasswordRequest } from 'generated/api';
import { useGetUser } from 'utils/hooks/useGetUser';
import { extractError } from 'utils/misc';
import styles from './styles.module.scss';

export const ChangePassword = () => {
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [user] = useGetUser();
  const { t } = useTranslation('default', { keyPrefix: 'pages/accounts.passwordSettings' });
  const { t: commonT } = useTranslation('default', { keyPrefix: 'common' });

  const { control, reset, getValues, handleSubmit } = useForm<PasswordAccountValues>({
    defaultValues: passwordAccountValues,
  });

  const {
    mutate: passwordMutate,
    isSuccess: isPasswordSuccess,
    error: passwordError,
    isLoading: isPasswordLoading,
  } = useMutation(
    (params: { userId: string; passwordObj: UpdatePasswordRequest }) =>
      updatePassword(params.userId, params.passwordObj),
    {
      onSettled: () => {
        reset(passwordAccountValues);
      },
    },
  );

  useEffect(() => {
    if (passwordError) {
      const message = extractError(passwordError);

      if (message) {
        setErrorMessage(message);
      }
    } else {
      setErrorMessage('');
    }
  }, [passwordError]);

  const onSubmit = (data: PasswordAccountValues) => {
    setErrorMessage('');

    passwordMutate({
      userId: user.id,
      passwordObj: {
        oldPassword: data.currentPassword,
        newPassword: data.newPassword,
      },
    });
  };

  return (
    <ContentBox title={t('password')} roundedBtm isExpandable>
      <form onSubmit={handleSubmit(onSubmit)} data-testid="ChangePasswordForm">
        <div className={styles.root}>
          <div className={styles.flexRowDouble}>
            <Controller
              name="currentPassword"
              control={control}
              rules={{
                required: commonT('validation.required'),
              }}
              render={({ field, fieldState: { error } }) => (
                <Input
                  showRequired
                  type="password"
                  testId="CurrentPasswordInput"
                  label={t('currentPassword')}
                  error={error?.message}
                  {...field}
                />
              )}
            />
          </div>
          <div className={styles.flexRowDouble}>
            <Controller
              name="newPassword"
              control={control}
              rules={{
                required: commonT('validation.required'),
                minLength: {
                  value: 8,
                  message: commonT('validation.minLength', { limit: 8 }),
                },
              }}
              render={({ field, fieldState: { error } }) => (
                <Input
                  showRequired
                  type="password"
                  testId="NewPasswordInput"
                  label={t('newPassword')}
                  error={error?.message}
                  {...field}
                />
              )}
            />
            <Controller
              name="confirmNewPassword"
              control={control}
              rules={{
                required: commonT('validation.required'),
                validate: (value) => {
                  const newPassword = getValues('newPassword');
                  const isValid = value === newPassword;

                  return isValid || commonT('validation.passwordNoMatch');
                },
              }}
              render={({ field, fieldState: { error } }) => (
                <Input
                  showRequired
                  type="password"
                  testId="ConfirmNewPasswordInput"
                  label={t('confirmNewPassword')}
                  error={error?.message}
                  {...field}
                />
              )}
            />
          </div>
        </div>
        <SavingButtonSection isLoading={isPasswordLoading} isSuccess={isPasswordSuccess} errorMessage={errorMessage} />
      </form>
    </ContentBox>
  );
};
