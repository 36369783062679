import React, { ReactNode } from 'react';
import clsx from 'clsx';
import {
  AlertSolidIcon,
  AlertTriangleSolidIcon,
  CheckCircleSolidIcon,
  CloseIcon,
  ErrorOctagonSolidIcon,
} from '@rouvia/icons';

import styles from './styles.module.scss';

export enum TOAST_TYPE {
  ERROR = 'ERROR',
  INFO = 'INFO',
  SUCCESS = 'SUCCESS',
  WARNING = 'WARNING',
}

const iconMap = new Map<TOAST_TYPE, ReactNode>([
  [
    TOAST_TYPE.INFO,
    <AlertSolidIcon
      key={TOAST_TYPE.INFO}
      data-testid="ToastIconInfo"
      className={clsx(styles.mediumIcon, styles.info)}
    />,
  ],
  [
    TOAST_TYPE.ERROR,
    <ErrorOctagonSolidIcon
      key={TOAST_TYPE.ERROR}
      data-testid="ToastIconError"
      className={clsx(styles.mediumIcon, styles.error)}
    />,
  ],
  [
    TOAST_TYPE.WARNING,
    <AlertTriangleSolidIcon
      key={TOAST_TYPE.WARNING}
      data-testid="ToastIconWarning"
      className={clsx(styles.mediumIcon, styles.warning)}
    />,
  ],
  [
    TOAST_TYPE.SUCCESS,
    <CheckCircleSolidIcon
      key={TOAST_TYPE.SUCCESS}
      data-testid="ToastIconSuccess"
      className={clsx(styles.mediumIcon, styles.success)}
    />,
  ],
]);

type TPros = {
  message: string;
  type: TOAST_TYPE;
  onClose: () => void;
};

export const Toast: React.FC<TPros> = ({ message, type, onClose }) => (
  <div className={styles.root}>
    {iconMap.get(type)}
    <div className={styles.message} data-testid="ToastMessage">
      {message}
    </div>
    <CloseIcon className={clsx(styles.smallIcon, styles.clickable)} onClick={onClose} />
  </div>
);
