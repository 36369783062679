import React, { useCallback, useEffect, useRef } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { GroupBase } from 'react-select';
import { CheckIcon } from '@rouvia/icons';
import { useOnClickOutside } from 'utils/hooks/onClickOutside';
import styles from './styles.module.scss';

export type TOption<T> = { label: string; secondaryText?: string; value: T };

export type GroupOption<T> = { readonly label?: string; readonly options: TOption<T>[] };

type TProps<T> = {
  value: TOption<T> | null;
  onChange: (newValue: TOption<T>) => void;
  filteredOptions: TOption<T>[];
  filteredGroupOptions: GroupBase<T>[];
  loadingOptionsEnabled: boolean;
  setDropdownOpen: (open: boolean) => void;
  reset: () => void;
  isLoading: boolean;
  focusedOption: number | null;
  onOptionRefChange: (refs: Map<any, any>) => void;
};

export const EditableSelectDropdown: React.FC<TProps<unknown>> = ({
  value,
  onChange,
  filteredOptions,
  filteredGroupOptions,
  loadingOptionsEnabled,
  setDropdownOpen,
  reset,
  isLoading,
  focusedOption,
  onOptionRefChange,
}) => {
  const ref = useRef(null);
  const optionRefs = useRef(new Map());

  useEffect(() => {
    onOptionRefChange(optionRefs.current);
  }, [onOptionRefChange]);

  const setOptionRef = useCallback((element: HTMLDivElement | null, index: number) => {
    optionRefs.current.set(index, element);
  }, []);

  const { t } = useTranslation('default', { keyPrefix: 'common' });

  useOnClickOutside(ref, reset);

  const handleSelect = (newOption: TOption<unknown>) => {
    onChange(newOption);
    setDropdownOpen(false);
  };

  if (isLoading) {
    return (
      <div className={styles.dropdownList} ref={ref}>
        <div className={clsx(styles.option)}>{t('loading')}</div>
      </div>
    );
  }

  const hasOptionsAvailable =
    Boolean(filteredOptions.length) || Boolean(filteredGroupOptions.find((group) => Boolean(group.options.length)));

  if (!hasOptionsAvailable) {
    return (
      <div className={styles.dropdownList} ref={ref}>
        <div className={clsx(styles.option)}>{t('noOptions')}</div>
      </div>
    );
  }

  if (loadingOptionsEnabled) {
    const castedGroupOptions = filteredGroupOptions as GroupOption<unknown>[];
    let counterIndex = -1; // Will be set to 0

    return (
      <div className={styles.dropdownList} ref={ref}>
        {castedGroupOptions
          .filter(({ options }) => options.length)
          .map((group, groupIndex) => (
            <div key={groupIndex}>
              {group.label && (
                <div className={styles.groupLabel}>
                  {group.label} ({group.options.length})
                </div>
              )}
              {group.options.map((option, optionIndex) => {
                const isSelected = option.label === value?.label;

                counterIndex += 1;

                const localCounterIndex = counterIndex;

                return (
                  <div
                    className={clsx(styles.option, styles.selectableOption, {
                      [styles.selectedOption]: isSelected,
                      [styles.focusedOption]: focusedOption != null && counterIndex === focusedOption,
                    })}
                    key={option.value ? JSON.stringify(option.value) : optionIndex}
                    onClick={() => handleSelect(option)}
                    ref={(el) => setOptionRef(el, localCounterIndex)}
                  >
                    <span>
                      <span className={clsx({ [styles.boldText]: option.secondaryText })}>{option.label}</span>
                      {option.secondaryText && <span className={styles.smallerLabel}>{option.secondaryText}</span>}
                    </span>{' '}
                    {isSelected && <CheckIcon className={styles.checkmark} />}
                  </div>
                );
              })}
            </div>
          ))}
      </div>
    );
  }

  return (
    <div className={styles.dropdownList} ref={ref}>
      {filteredOptions.map((option, index) => {
        const isSelected = option.label === value?.label;

        return (
          <div
            className={clsx(styles.option, styles.selectableOption, {
              [styles.selectedOption]: isSelected,
              [styles.focusedOption]: focusedOption != null && index === focusedOption,
            })}
            key={option.value ? JSON.stringify(option.value) : index}
            ref={(el) => setOptionRef(el, index)}
            onClick={() => handleSelect(option)}
          >
            <span>
              <span className={clsx({ [styles.boldText]: option.secondaryText })}>{option.label}</span>
              {option.secondaryText && <span className={styles.smallerLabel}>{option.secondaryText}</span>}
            </span>{' '}
            {isSelected && <CheckIcon className={styles.checkmark} />}
          </div>
        );
      })}
    </div>
  );
};
