import React, { useContext, useEffect, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { queryClient } from 'constants/api';
import { Loader } from 'components/loader';
import ToastContext from 'components/toast';
import {
  getNotificationSettingsQueryKey,
  updateNotificationSettings,
  useGetNotificationSettings,
} from 'api/notifications';
import { ContentBox } from 'components/contentBox';
import { Switch } from 'components/switch';
import styles from './styles.module.scss';

export const NotificationsSetting = () => {
  const { t } = useTranslation('default', { keyPrefix: 'pages/accounts.notificationsSettings' });
  const [isSwitchOn, setIsSwitchOn] = useState<boolean>(false);
  const { data: notifications, isLoading: isGettingNotification } = useGetNotificationSettings();
  const { playErrorToast, playSuccessToast } = useContext(ToastContext);

  useEffect(() => {
    setIsSwitchOn(notifications?.email.allEmails || false);
  }, [notifications, setIsSwitchOn]);

  const { mutate: toggleEmailNotifications, isLoading: isUpdatingEmailNotification } = useMutation(
    (isActive: boolean) => updateNotificationSettings({ email: { allEmails: isActive } }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([getNotificationSettingsQueryKey]).then(() => {
          playSuccessToast();
        });
      },
      onError: () => {
        playErrorToast();
      },
    },
  );

  const handleSwitchToggle = () => {
    toggleEmailNotifications(!isSwitchOn);
  };

  return (
    <ContentBox title="Notifications" roundedBtm isExpandable>
      <div className={styles.root}>
        {isGettingNotification && <Loader />}

        {!isGettingNotification && (
          <div className={clsx(styles.flexRowDouble, styles.label)}>
            {t('emailNotifications')}

            <div>
              <Switch
                bold
                disabled={isUpdatingEmailNotification}
                isActive={isSwitchOn}
                onClick={handleSwitchToggle}
                className={styles.switch}
              />
            </div>
          </div>
        )}
      </div>
    </ContentBox>
  );
};
