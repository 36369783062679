import React from 'react';
import clsx from 'clsx';
import { TextFieldMeta } from 'components/textFieldMeta';
import styles from './styles.module.scss';

export type IBasicInput = React.InputHTMLAttributes<HTMLTextAreaElement>;

type TProps = {
  label?: string;
  error?: string;
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>, value: string) => void;
  className?: string;
  inputClassName?: string;
  testId?: string;
  hint?: string;
  showRequired?: boolean;
} & Partial<Omit<IBasicInput, 'onChange'>>;

export const Textarea = React.forwardRef<HTMLTextAreaElement, TProps>(
  (
    {
      label,
      placeholder,
      error,
      value = '',
      name,
      onChange,
      className,
      inputClassName,
      hint,
      disabled,
      onFocus,
      onBlur,
      showRequired,
      readOnly,
      testId = 'textAreaInput',
      ...rest
    },
    ref,
  ) => {
    const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      const val = e?.target?.value;

      onChange?.(e, val);
    };

    return (
      <div className={styles.root}>
        <label className={styles.label}>
          {label && (
            <span data-testid="textAreaLabel" className={styles.labelText}>
              {label}
              {showRequired && <span className={styles.required}>*</span>}
            </span>
          )}

          <div
            className={clsx(
              styles.inputWrapper,
              {
                [styles.withError]: error,
                [styles.readOnly]: readOnly,
                [styles.disabled]: disabled,
              },
              className,
            )}
          >
            <textarea
              ref={ref}
              data-testid={testId}
              className={clsx(styles.input, inputClassName)}
              onChange={handleChange}
              value={value}
              disabled={disabled}
              autoComplete="off"
              name={name}
              onFocus={onFocus}
              onBlur={onBlur}
              placeholder={placeholder}
              readOnly={readOnly}
              {...rest}
            />
          </div>
        </label>

        {(error || hint) && <TextFieldMeta className={styles.meta} hint={hint} error={error} />}
      </div>
    );
  },
);

Textarea.displayName = 'Textarea';
