import { useTranslation } from 'react-i18next';
import React, { useEffect } from 'react';
import { useFieldArray } from 'react-hook-form';
import type { Control, UseFormWatch, UseFormTrigger } from 'react-hook-form';

import { CargoItem } from 'generated/api';
import { SectionHeading } from 'components/sectionHeading';
import { containerInfoDefaultValues, IForm } from '../../constants';
import { ContainerInformationItem } from './containerInformationItem';
import styles from './styles.module.scss';

type TProps = {
  cargoItem: CargoItem;
  equipmentIndex: number;
  formControl: Control<IForm>;
  formWatch: UseFormWatch<IForm>;
  formTrigger: UseFormTrigger<IForm>;
};

type TFormName = `equipmentInformation.${number}.containerInformation`;

export const ContainerInformation: React.FC<TProps> = ({ equipmentIndex, formControl, formTrigger, cargoItem }) => {
  const { t } = useTranslation('default', {
    keyPrefix: 'pages/quotes/components/equipmentInfo.containerInformation',
  });

  const formPrefix: TFormName = `equipmentInformation.${equipmentIndex}.containerInformation`;

  const { fields, replace } = useFieldArray<IForm>({
    control: formControl,
    name: formPrefix,
  });

  useEffect(() => {
    if (!fields.length) {
      replace(Array(cargoItem.amount).fill(containerInfoDefaultValues));
    }
  }, [cargoItem, fields.length, replace]);

  return (
    <div className={styles.modalItem}>
      <SectionHeading title={t('containerInfo')} description={t('specifyGoods')} />
      <div>
        {fields.map((field, containerIndex) => (
          <ContainerInformationItem
            key={field.id}
            testId={`ContainerInformationItem${containerIndex}`}
            equipmentIndex={equipmentIndex}
            containerIndex={containerIndex}
            control={formControl}
            trigger={formTrigger}
            defaultExpanded={containerIndex === 0}
          />
        ))}
      </div>
    </div>
  );
};
