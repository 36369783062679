import React from 'react';

export enum PAGES {
  HOME = 'HOME',
  PLANNING = 'PLANNING',
  PLANNING_PREFILLED = 'PLANNING_PREFILLED',
  QUOTES_SEARCH = 'QUOTES_SEARCH',
  SINGLE_QUOTE = 'SINGLE_QUOTE',
  BOOKINGS = 'BOOKINGS',
  NOTIFICATIONS = 'NOTIFICATIONS',
  SINGLE_BOOKING = 'SINGLE_BOOKING',
  SINGLE_BOOKING_REDIRECT = 'SINGLE_BOOKING_REDIRECT',
  ACCOUNT = 'ACCOUNT',
  ORG_MANAGEMENT = 'ORG_MANAGEMENT',
  NOT_FOUND = 'NOT_FOUND',
}

export interface IRouteInfo {
  path: string;
  parent: null | PAGES;
  title: PAGES;
}

export interface IRoute {
  routeInfo: IRouteInfo;
  Component: React.ReactNode | null;
}

export type IRoutesInfo = {
  [key in PAGES]: IRouteInfo;
};

export type IRoutes = Partial<{
  [key in PAGES]: IRoute;
}>;

export const ROUTES_INFO: IRoutesInfo = {
  [PAGES.HOME]: {
    path: '/',
    parent: null,
    title: PAGES.HOME,
  },
  [PAGES.PLANNING]: {
    path: '/planning',
    parent: null,
    title: PAGES.PLANNING,
  },
  [PAGES.PLANNING_PREFILLED]: {
    path: '/planning/:id',
    parent: null,
    title: PAGES.PLANNING,
  },
  [PAGES.QUOTES_SEARCH]: {
    path: '/quotes/search/:id',
    parent: PAGES.PLANNING,
    title: PAGES.QUOTES_SEARCH,
  },
  [PAGES.SINGLE_QUOTE]: {
    path: '/quote/:id',
    parent: PAGES.QUOTES_SEARCH,
    title: PAGES.SINGLE_QUOTE,
  },
  [PAGES.ACCOUNT]: {
    path: '/profile',
    parent: null,
    title: PAGES.ACCOUNT,
  },
  [PAGES.ORG_MANAGEMENT]: {
    path: '/team-management',
    parent: PAGES.HOME,
    title: PAGES.ORG_MANAGEMENT,
  },
  [PAGES.BOOKINGS]: {
    path: '/bookings',
    parent: null,
    title: PAGES.BOOKINGS,
  },
  [PAGES.SINGLE_BOOKING]: {
    path: '/bookings/:id/:tab',
    parent: PAGES.BOOKINGS,
    title: PAGES.SINGLE_BOOKING,
  },
  [PAGES.SINGLE_BOOKING_REDIRECT]: {
    path: '/bookings/:id',
    parent: PAGES.BOOKINGS,
    title: PAGES.SINGLE_BOOKING,
  },
  [PAGES.NOTIFICATIONS]: {
    path: '/notifications',
    parent: PAGES.HOME,
    title: PAGES.NOTIFICATIONS,
  },
  [PAGES.NOT_FOUND]: {
    path: '*',
    parent: null,
    title: PAGES.NOT_FOUND,
  },
};
