import React from 'react';
import type { ColumnDef, Table, Row, Column, Cell } from '@tanstack/table-core';
import type { TFunction } from 'i18next';
import { User } from 'generated/api';
import { getFullName, isAdminUser } from 'utils/misc';
import { UserAvatar } from 'components/userAvatar';
import { Actions } from './components/actions';
import { SwitchRole } from './components/switchRole';
import styles from './styles.module.scss';

type TCell = {
  table: Table<User>;
  column: Column<User, unknown>;
  row: Row<User>;
  cell: Cell<User, unknown>;
  getValue: () => unknown;
  renderValue: () => unknown;
};

export const getTableColumns = (t: TFunction, currentUserId: string): ColumnDef<User>[] => [
  {
    header: `${t('pages/orgManagement.table.headers.name')}`,
    cell: ({ cell }: TCell): JSX.Element => {
      const user = cell.row.original;
      const username = getFullName(user);

      return (
        <div className={styles.userBlock}>
          <UserAvatar user={user} size="small" />

          <div>
            {username && <div className={styles.username}>{getFullName(user)}</div>}
            <div className={styles.email}>{user.email}</div>
          </div>
        </div>
      );
    },
  },
  {
    header: `${t('pages/orgManagement.table.headers.status')}`,
    cell: ({ cell }: TCell): JSX.Element => {
      const user = cell.row.original;

      if (user.isVerified) {
        return <div className={styles.active}>{t('pages/orgManagement.table.active')}</div>;
      }

      return <div className={styles.pending}>{t('pages/orgManagement.table.pending')}</div>;
    },
  },
  {
    header: `${t('pages/orgManagement.table.headers.role')}`,
    cell: ({ cell }: TCell): JSX.Element | null | string => {
      const user = cell.row.original;
      const isCurrentUser = user.id === currentUserId;

      if (user.isVerified) {
        if (isCurrentUser) {
          return isAdminUser(user) ? t('common.roles.admin') : t('common.roles.member');
        }

        return <SwitchRole user={user} />;
      }

      return null;
    },
  },
  {
    header: `${t('pages/orgManagement.table.headers.actions')}`,
    cell: ({ cell }): JSX.Element | null => {
      const user = cell.row.original;
      const isCurrentUser = user.id === currentUserId;

      return isCurrentUser ? null : <Actions user={user} />;
    },
  },
];
