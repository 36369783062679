import React, { useState } from 'react';
import clsx from 'clsx';
import styles from './styles.module.scss';

export type TTab = {
  key: string;
  label: string;
  component?: React.ReactNode;
};

type TProps = {
  tabs: TTab[];
  lazy?: boolean;
  initialUncontrolledTab?: string;
  controlledTab?: string;
  setControlledTab?: (tab: string) => void;
  noChildren?: boolean;
  underline?: boolean;
};

export const Tabs: React.FC<TProps> = ({
  tabs,
  lazy,
  initialUncontrolledTab,
  controlledTab,
  setControlledTab,
  noChildren,
  underline = true,
}) => {
  const [activeTab, setActiveTab] = useState<string>(tabs.length ? tabs[0].key : initialUncontrolledTab || 'NULL');

  const getActiveTab = () => (controlledTab !== undefined ? controlledTab : activeTab);

  const handleSetActiveIndex = (tab: string) => {
    if (setControlledTab) {
      setControlledTab(tab);
    } else {
      setActiveTab(tab);
    }
  };

  const renderedTabs = tabs.map((tab) => {
    const isActive = getActiveTab() === tab.key;

    if (lazy && !isActive) {
      return null;
    }

    return (
      <div
        key={tab.label}
        className={clsx(styles.item, {
          [styles.active]: isActive,
        })}
        data-testid={`TabsComponent-${tab.key}`}
      >
        {tab.component}
      </div>
    );
  });

  return (
    <>
      <div className={clsx(styles.labels, { [styles.underline]: underline })}>
        {tabs.map((tab) => {
          const isActive = getActiveTab() === tab.key;

          return (
            <div
              data-testid={`TabsLabel-${tab.key}`}
              key={tab.key}
              className={clsx(styles.label, {
                [styles.active]: isActive,
              })}
              onClick={() => handleSetActiveIndex(tab.key)}
            >
              {tab.label}
            </div>
          );
        })}
      </div>

      {!noChildren && <>{renderedTabs}</>}
    </>
  );
};
