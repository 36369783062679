import React, { RefObject, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useOnClickOutside } from 'utils/hooks/onClickOutside';
import { TMultiSelectData } from 'components/filtersGenerator/types';
import { Checkbox } from 'components/checkbox';
import { Button } from 'components/button';
import { Link } from 'components/link';
import styles from './styles.module.scss';

type TProps = {
  onClose: () => void;
  parentRef: RefObject<HTMLDivElement> | null;
  title?: string;
  data: TMultiSelectData[];
  setData: (data: TMultiSelectData[]) => void;
};

export const MultiSelectCheckboxPopup: React.FC<TProps> = ({ onClose, parentRef, title, data, setData }) => {
  const { t } = useTranslation('default', { keyPrefix: 'components/multiSelectCheckbox' });
  const [localData, setLocalData] = useState<TMultiSelectData[]>(data);

  useOnClickOutside(parentRef, onClose);

  const handleToggleSelect = (index: number) => {
    const tempData = [...localData];

    tempData[index] = { ...tempData[index], selected: !tempData[index].selected };

    setLocalData(tempData);
  };

  const handleSave = () => {
    setData(localData);
    onClose();
  };

  const reset = () => {
    const tempData = data.map((checkbox) => ({ ...checkbox, selected: false }));

    setLocalData(tempData);
    setData(tempData);
    onClose();
  };

  return (
    <div className={styles.popup} data-testid="multiSelectCheckboxPopupRoot">
      <div className={styles.flexColumn}>
        {title && (
          <div className={styles.title} data-testid="multiSelectCheckboxPopupTitle">
            {title}
          </div>
        )}
        <div style={{ padding: 6 }}>
          {localData.map((checkboxData, index) => (
            <Checkbox
              key={checkboxData.value}
              className={styles.checkboxRow}
              checked={checkboxData.selected}
              label={checkboxData.label}
              onChange={() => handleToggleSelect(index)}
            />
          ))}
        </div>
        <div className={styles.footer}>
          <Button size="small" onClick={handleSave}>
            {t('save')}
          </Button>
          <Link onClick={reset} theme="accent" underline="none">
            {t('reset')}
          </Link>
        </div>
      </div>
    </div>
  );
};
