import React from 'react';
import clsx from 'clsx';
import { SectionHeading } from 'components/sectionHeading';
import styles from './styles.module.scss';

export type TProps = {
  title: string;
  description?: string;
  actions?: JSX.Element;
  isActive?: boolean;
  hideActionsWhenActive?: boolean;
  children?: React.ReactNode;
  testId?: string;
  showError?: boolean;
};

export const Expandable: React.FC<TProps> = ({
  title,
  description,
  actions,
  isActive,
  hideActionsWhenActive,
  testId,
  showError,
  children,
}) => (
  <div className={clsx(styles.expandableRoot, { [styles.showError]: showError })} data-testid={testId}>
    <div className={styles.header}>
      <div className={styles.text}>
        <SectionHeading title={title} description={description} size="medium" />
      </div>
      {actions && !(hideActionsWhenActive && isActive) && <div className={styles.actions}>{actions}</div>}
    </div>

    <div className={clsx(styles.content, { [styles.active]: isActive })} data-testid="ExpandableContent">
      {children}
    </div>
  </div>
);
