import React, { ChangeEvent, SyntheticEvent, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import ReactCrop, { centerCrop, Crop, makeAspectCrop } from 'react-image-crop';
import { Button } from 'components/button';
import { TDocumentUploadForm } from 'types/common';
import 'react-image-crop/dist/ReactCrop.css';
import styles from './styles.module.scss';

type TProps = {
  errorMessage?: string;
};

export const ImageCropUpload: React.FC<TProps> = ({ errorMessage }) => {
  const { t } = useTranslation('default', { keyPrefix: 'pages/accounts.personalInfo' });
  const [crop, setCrop] = useState<Crop>();
  const [src, setSrc] = useState<string>('');
  const [file, setFile] = useState<File>();
  const [fileValue, setFileValue] = useState('');
  const { register, handleSubmit } = useForm<TDocumentUploadForm>();

  //TODO: Submit image
  const onSubmit = () => {
    // eslint-disable-next-line no-console
    console.log('Submit crop image');
  };

  const onFileSelected = (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) {
      return;
    }

    const selectedFile = event.target.files[0];

    setFile(selectedFile);
    setFileValue(event.target.value);

    const reader = new FileReader();

    reader.onload = function (e: ProgressEvent<FileReader>) {
      if (typeof e.target?.result === 'string') {
        setSrc(e.target.result);
      }
    };

    reader.readAsDataURL(selectedFile);
  };

  const onImageLoad = (event: SyntheticEvent<HTMLImageElement, Event>) => {
    const { naturalWidth: width, naturalHeight: height } = event.currentTarget;

    const tempCrop = centerCrop(
      makeAspectCrop(
        {
          unit: '%',
          width: 90,
        },
        1,
        width,
        height,
      ),
      width,
      height,
    );

    setCrop(tempCrop);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className={styles.fileSelector}>
        {src && (
          <ReactCrop crop={crop} aspect={1} ruleOfThirds onChange={(c) => setCrop(c)}>
            <img src={src} width="300px" alt="profile" onLoad={onImageLoad} />
          </ReactCrop>
        )}
        <label className={styles.label}>
          <input
            type="file"
            value={fileValue}
            className={styles.input}
            {...register('file')}
            onChange={onFileSelected}
          />
          {t('selectImage')}
        </label>
      </div>
      {errorMessage && <div className={styles.error}>{errorMessage}</div>}
      <Button className={styles.submit} type="submit" theme="accent" disabled={!file}>
        {t('uploadImage')}
      </Button>
    </form>
  );
};
