import { TCommunityGoods, TCustomsGoods, TEmptyGoods } from 'constants/planning';
import { ShippingLineEnum } from 'constants/shippingLine';
import {
  BookingContainerInformation,
  BookingCustomsInformation,
  CommunityGoodsBookingEquipmentInformation,
  ContainerGoodsInformation,
  CustomsGoodsBookingEquipmentInformation,
  EmptyContainerBookingEquipmentInformation,
  EmptyContainerDepotInformation,
  PortInformation,
  TypeOfPackaging,
  WarehouseInformation,
} from 'generated/api';
import { TDefaultSelectOption } from 'types/common';

export enum SORT_MAP {
  PRICE = 'PRICE',
  SPEED = 'SPEED',
  CO2 = 'CO2',
}

export type TFromBookingContainerInformation = Omit<
  BookingContainerInformation,
  'goodsInformation' | 'originCountry' | 'destinationCountry'
> & {
  originCountry: TDefaultSelectOption<string> | null;
  destinationCountry: TDefaultSelectOption<string> | null;
  goodsInformation?: TFormContainerGoodsInformation[];
};

type TFormContainerGoodsInformation = Omit<ContainerGoodsInformation, 'typeOfPackaging'> & {
  typeOfPackaging: TDefaultSelectOption<TypeOfPackaging> | null;
};

type TFormBookingCustomsInformation = Omit<BookingCustomsInformation, 'customsOffice'> & {
  customsOffice: TDefaultSelectOption<string> | null;
  clearanceConfirmation: boolean;
};

type TLocalPortInformation = Omit<PortInformation, 'shippingLine'> & {
  shippingLine: TDefaultSelectOption<ShippingLineEnum>;
};

type TLocalEmptyContainerDepotInformation = Omit<EmptyContainerDepotInformation, 'shippingLine'> & {
  shippingLine: TDefaultSelectOption<ShippingLineEnum>;
};

interface TLocalBookingAdditionalInformation {
  origin?: TLocalPortInformation | WarehouseInformation;
  destination?: TLocalPortInformation | WarehouseInformation;
  emptyContainerOrigin?: TLocalEmptyContainerDepotInformation;
  emptyContainerDestination?: TLocalEmptyContainerDepotInformation;
  additionalNotes?: string;
}

export type TLocalCustomsGoodsBookingEquipmentInformation = Omit<
  CustomsGoodsBookingEquipmentInformation,
  'goodsType' | 'containerInformation' | 'customsInformation'
> & {
  goodsType: { value: TCustomsGoods; label: string };
  containerInformation: TFromBookingContainerInformation[];
  customsInformation: TFormBookingCustomsInformation;
};

export type TLocalCommunityGoodsBookingEquipmentInformation = Omit<
  CommunityGoodsBookingEquipmentInformation,
  'goodsType' | 'containerInformation'
> & {
  goodsType: { value: TCommunityGoods; label: string };
  containerInformation: TFromBookingContainerInformation[];
};

export type TLocalEmptyContainerBookingEquipmentInformation = Omit<
  EmptyContainerBookingEquipmentInformation,
  'goodsType'
> & {
  goodsType?: { value: TEmptyGoods; label: string };
};

export type TLocalEquipmentInformation =
  | TLocalCustomsGoodsBookingEquipmentInformation
  | TLocalCommunityGoodsBookingEquipmentInformation
  | TLocalEmptyContainerBookingEquipmentInformation;

export const goodsInfoDefaultVales: TFormContainerGoodsInformation = {
  amount: 0,
  typeOfPackaging: null,
  description: '',
  netWeight: 0,
  grossWeight: 0,
  nhmCode: '',
  volume: 0,
};

export const containerInfoDefaultValues: TFromBookingContainerInformation = {
  containerNumber: '',
  sealNumber: '',
  originCountry: null,
  destinationCountry: null,
};

// The Form
export interface IForm {
  fwdId?: string;
  assignees: {
    label: string;
    value: string;
  }[];
  additionalInformation: TLocalBookingAdditionalInformation;
  equipmentInformation: TLocalEquipmentInformation[];
}
