import React, { useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import clsx from 'clsx';

type TProps = {
  to: string;
  validPaths: string[];
  className?: string;
  children?: React.ReactNode;
};

/**
 * Use this component when you need to highlight menu item as Active in multiple paths
 * Example usage with props:
 *  to="/planning"
 *  validPaths={['planning', 'quotes', 'quote']}
 */
export const MenuLink: React.FC<TProps> = ({ to, validPaths, className, children }) => {
  const { pathname: currentLocation } = useLocation();

  const isActive = useMemo(
    () => validPaths.some((path) => currentLocation.indexOf(path) !== -1),
    [currentLocation, validPaths],
  );

  return (
    <Link className={clsx(className, { active: isActive })} to={to}>
      {children}
    </Link>
  );
};
