import React, { RefObject } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { BellIcon } from '@rouvia/icons';
import { Notification } from 'generated/api';
import { useOnClickOutside } from 'utils/hooks/onClickOutside';
import { NotificationList } from './notificationList';
import styles from './style.module.scss';

type TProps = {
  notifications: Notification[];
  onClose: () => void;
  parentRef: RefObject<HTMLDivElement> | null;
};

export const NotificationPopup: React.FC<TProps> = ({ notifications, onClose, parentRef }) => {
  const { t } = useTranslation('default', {
    keyPrefix: 'components/notificationsWidget',
  });
  const navigate = useNavigate();

  useOnClickOutside(parentRef, onClose);

  const handleGoToNotificationCenter = () => {
    navigate('/notifications');
    onClose();
  };

  return (
    <div className={styles.popup} data-testid="NotificationPopupRoot">
      <NotificationList notifications={notifications} onClose={onClose} />
      <div className={styles.notificationCenterBtn} onClick={handleGoToNotificationCenter}>
        <BellIcon />
        <div>{t('notificationCenter')}</div>
      </div>
    </div>
  );
};
