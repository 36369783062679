import { AlertSolidIcon } from '@rouvia/icons';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import clsx from 'clsx';

import { WidgetContainer } from 'components/widgetContainer';
import { WidgetItem } from 'components/widgetContainer/item';
import { formatDate, DATE_FORMAT } from 'utils/date';
import { EmptyList } from 'components/emptyList';
import { useGetBookingsInfinite } from 'api/bookings';
import { exceptionsRequestQuery } from 'pages/dashboard/constants';
import { useExtractedItemsInfinite } from 'utils/hooks/useExtractedItemsInfinite';
import { CustomInfiniteScroll } from 'components/customInfityScroll';

import styles from './styles.module.scss';
import { extractExceptions } from './utils';

type TProps = {
  className?: string;
};

const scrollDivId = 'ExceptionsListWidget';

export const ExceptionsListWidget: React.FC<TProps> = ({ className }) => {
  const { t } = useTranslation('default', {
    keyPrefix: 'components/exceptionsListWidget',
  });
  const {
    data: bookingsWithExceptions,
    fetchNextPage,
    hasNextPage,
    isLoading,
  } = useGetBookingsInfinite({ query: exceptionsRequestQuery, key: 'ExceptionsListWidget' });
  const bookings = useExtractedItemsInfinite(bookingsWithExceptions);

  const exceptions = useMemo(() => extractExceptions(bookings), [bookings]);

  return (
    <WidgetContainer isLoading={isLoading} title={t('title')} className={className} id={scrollDivId}>
      {exceptions.length ? (
        <CustomInfiniteScroll
          scrollableTargetId={scrollDivId}
          hasMore={Boolean(hasNextPage)}
          next={fetchNextPage}
          height={460}
          items={exceptions.map((item) => {
            const { booking, message, title, createdAt, completed: isCompleted } = item;

            return (
              <Link to={`/bookings/${booking.id}`} className={styles.exceptionsListItem} key={createdAt}>
                <WidgetItem>
                  <div className={styles.content}>
                    <div className={styles.bookingId}>
                      {booking.id} {booking.fwdId && <span className={styles.refId}>({booking.fwdId})</span>}
                    </div>
                    <div className={clsx(styles.title)}>{title}</div>
                    <div className={clsx(styles.message)}>{message}</div>
                    <div className={clsx(styles.update)}>{formatDate(createdAt, DATE_FORMAT.COMPLETE)}</div>
                    {!isCompleted && <AlertSolidIcon className={styles.icon} />}
                  </div>
                </WidgetItem>
              </Link>
            );
          })}
        />
      ) : (
        <EmptyList fixedHeight="300px" title={t('emptyExceptionTitle')} subtitle={t('emptyExceptionSubtitle')} />
      )}
    </WidgetContainer>
  );
};
