import React from 'react';
import { useTranslation } from 'react-i18next';
import { captureMessage } from '@sentry/react';
import type {
  Quote,
  RailQuote,
  RailServicesRequest,
  TruckQuote,
  Booking,
  TruckBooking,
  RailBooking,
} from 'generated/api';
import { ContentBox } from 'components/contentBox';
import { NODE_TYPE, ServicesState } from 'components/routeInformation/types';
import RouteNodeGenerator from 'components/routeInformation/routeNodeGenerator';
import styles from './styles.module.scss';

export const getRailContent = (
  entity: RailQuote | RailBooking,
  mutableLocalServices?: ServicesState<RailServicesRequest>,
  isServicesSubmitted?: boolean,
) => {
  const { destination, origin, route } = entity;

  const castedSubmittedServices =
    'bookedServices' in entity && isServicesSubmitted ? (entity.bookedServices as RailServicesRequest) : undefined;

  return [
    route.emptyContainerPickupLeg && (
      <RouteNodeGenerator
        key="rail-0"
        testId="rail-0"
        services={mutableLocalServices}
        shortLine={false}
        nodeData={{
          type: NODE_TYPE.CONTAINER_PICKUP,
          carrier: route.emptyContainerPickupLeg.operator.name,
          addressObj: route.emptyContainerPickupLeg.origin,
          serviceRequested: castedSubmittedServices ? castedSubmittedServices.firstMile : undefined,
        }}
      />
    ),
    route.firstMileLeg && (
      <RouteNodeGenerator
        key="rail-1"
        testId="rail-1"
        services={mutableLocalServices}
        shortLine={false}
        nodeData={{
          type: NODE_TYPE.FIRST_MILE,
          addressObj: origin,
          mileLeg: route.firstMileLeg,
          departureDatetime: route.firstMileLeg.departureDatetime,
          arrivalDatetime: route.firstMileLeg.arrivalDatetime,
          serviceRequested: castedSubmittedServices ? castedSubmittedServices.firstMile : undefined,
          isEmptyContainerOptionAvailable: Boolean(route.emptyContainerPickupLeg),
          cost: entity.priceBreakdown.firstMile,
        }}
      />
    ),
    <RouteNodeGenerator
      key="rail-2"
      testId="rail-2"
      services={mutableLocalServices}
      nodeData={{
        type: NODE_TYPE.LOADING,
        operator: route.railLeg.origin.operator,
        addressObj: route.railLeg.origin.address,
        terminal: route.railLeg.origin,
        departureDate: route.railLeg.departureDatetime,
        earliestOrLatestTime: route.railLeg.latestHandoverTime,
      }}
    />,
    <RouteNodeGenerator
      key="rail-3"
      testId="rail-3"
      services={mutableLocalServices}
      nodeData={{
        type: NODE_TYPE.LONG_LEG,
        carrier: route.railLeg.operator.name,
        trainNumber: route.railLeg.trainNumber,
        departureDatetime: route.railLeg.departureDatetime,
        arrivalDatetime: route.railLeg.arrivalDatetime,
      }}
    />,
    <RouteNodeGenerator
      key="rail-4"
      testId="rail-4"
      services={mutableLocalServices}
      nodeData={{
        type: NODE_TYPE.UNLOADING,
        operator: route.railLeg.destination.operator,
        addressObj: route.railLeg.destination.address,
        terminal: route.railLeg.destination,
        departureDate: route.railLeg.arrivalDatetime,
        earliestOrLatestTime: route.railLeg.earliestPickupTime,
      }}
      isLineDisabled={!Boolean(route.lastMileLeg)}
    />,
    route.lastMileLeg && (
      <RouteNodeGenerator
        key="rail-5"
        testId="rail-5"
        services={mutableLocalServices}
        isLineDisabled={!Boolean(route.emptyContainerDropoffLeg)}
        nodeData={{
          type: NODE_TYPE.LAST_MILE,
          addressObj: destination,
          mileLeg: route.lastMileLeg,
          departureDatetime: route.lastMileLeg.departureDatetime,
          arrivalDatetime: route.lastMileLeg.arrivalDatetime,
          serviceRequested: castedSubmittedServices ? castedSubmittedServices.lastMile : undefined,
          isEmptyContainerOptionAvailable: Boolean(route.emptyContainerDropoffLeg),
          cost: entity.priceBreakdown.lastMile,
        }}
      />
    ),
    route.emptyContainerDropoffLeg && (
      <RouteNodeGenerator
        key="rail-6"
        testId="rail-6"
        services={mutableLocalServices}
        nodeData={{
          type: NODE_TYPE.CONTAINER_DROPOFF,
          carrier: route.emptyContainerDropoffLeg.operator.name,
          addressObj: route.emptyContainerDropoffLeg.destination,
          serviceRequested: castedSubmittedServices ? castedSubmittedServices.lastMile : undefined,
        }}
      />
    ),
  ];
};

export const getTruckContent = (
  entity: TruckQuote | TruckBooking,
  mutableLocalServices?: ServicesState<RailServicesRequest>,
) => {
  const { destination, origin, route } = entity;

  return [
    route.emptyContainerPickupLeg && (
      <RouteNodeGenerator
        key="truck-0"
        testId="truck-0"
        shortLine={false}
        nodeData={{
          type: NODE_TYPE.CONTAINER_PICKUP,
          carrier: route.emptyContainerPickupLeg.operator.name,
          addressObj: route.emptyContainerPickupLeg.origin,
          serviceRequested: mutableLocalServices?.requestedServices?.firstMile,
        }}
        truckOnly
      />
    ),
    <RouteNodeGenerator
      key="truck-1"
      testId="truck-1"
      nodeData={{
        type: NODE_TYPE.ORIGIN,
        addressObj: origin,
      }}
    />,
    <RouteNodeGenerator
      key="truck-2"
      testId="truck-2"
      nodeData={{
        type: NODE_TYPE.DELIVERY,
        carrier: route.truckLeg.operator.name,
        departureDatetime: route.truckLeg.departureDatetime,
        arrivalDatetime: route.truckLeg.arrivalDatetime,
      }}
    />,
    <RouteNodeGenerator
      key="truck-3"
      testId="truck-3"
      isLineDisabled={!Boolean(route.emptyContainerDropoffLeg)}
      nodeData={{
        type: NODE_TYPE.DESTINATION,
        addressObj: destination,
      }}
    />,
    route.emptyContainerDropoffLeg && (
      <RouteNodeGenerator
        key="truck-4"
        testId="truck-4"
        nodeData={{
          type: NODE_TYPE.CONTAINER_DROPOFF,
          carrier: route.emptyContainerDropoffLeg.operator.name,
          addressObj: route.emptyContainerDropoffLeg.destination,
          serviceRequested: mutableLocalServices?.requestedServices?.lastMile,
        }}
        truckOnly
      />
    ),
  ];
};

type TProps = {
  entity: Quote | Booking;
  mutableLocalServices?: ServicesState<RailServicesRequest>;
  isServicesSubmitted?: boolean;
};

export const RouteInformation: React.FC<TProps> = ({ entity, mutableLocalServices, isServicesSubmitted = false }) => {
  const { t } = useTranslation('default', { keyPrefix: 'pages/quotes/singleQuotePage' });

  const getMainContent = () => {
    const isRailQuote = entity.type === 'RAIL';
    const isTruckQuote = entity.type === 'TRUCK';

    if (isTruckQuote) {
      return getTruckContent(entity, mutableLocalServices);
    } else if (isRailQuote) {
      return getRailContent(entity, mutableLocalServices, isServicesSubmitted);
    } else {
      captureMessage(`Νο compatible type found in quote with the id ${entity.id}`);

      return;
    }
  };

  return (
    <div>
      <ContentBox title={t('routeInformation')} contentClassName={styles.routeInfo}>
        {getMainContent()}
      </ContentBox>
    </div>
  );
};
