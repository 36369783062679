import { QueryClient } from '@tanstack/react-query';
import type { QueryClientConfig } from '@tanstack/react-query';
import config from 'constants/config';

export const API_URL = config.apiURL;

const reactQueryOptions: QueryClientConfig = {
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    },
  },
};

export const queryClient = new QueryClient(reactQueryOptions);
