import { DateTime, Duration } from 'luxon';
import i18n from 'i18n';

const currentTimeZone = DateTime.local().zoneName;
const locale = i18n.language;

export enum DATE_FORMAT {
  // Local
  TIME_SIMPLE = 'TIME_SIMPLE', // 10:03
  TIME_SIMPLE_RAW = 'TIME_SIMPLE_RAW', // 10:03
  DATE_MONTH_FULL = 'DATE_MONTH_FULL', // 23 July
  DATE_MONTH_HALF = 'DATE_MONTH_HALF', // 23 Jul

  // Format
  TIME_ONLY = 'HH:mm', // 10:03
  MONTH_FULL_YEAR = 'd LLLL yyyy', // 31 July 2022
  MONTH_HALF_YEAR = 'd LLL yyyy', // 31 Jul 2022
  COMPLETE = 'ccc d LLL yyyy, T', // TUE 11 Oct 2022, 15:11
  WEEKDAY_DATE_MONTH_TIME = 'ccc d LLL, T', // TUE 11 Oct, 15:11
  DATE_MONTH_S_HOURS = 'd LLL t', // 23 Jul 10:03
  WEEKDAY_DATE = 'ccc dd.LL', // Sat 23.07
  DATE_YEAR = 'dd.LL.yyyy', // 23.07.2022
  DATE_YEAR_DASHED = 'yyyy-MM-dd', // 2022-07-23
  DATE_YEAR_W_TIME = 'dd.LL.yyyy, HH:mm', // 23.07.2022, 15:00
  DYNAMIC_COMPLETE = 'DYNAMIC_COMPLETE', // Today at 9:42 || 22/0/9/2022 at 5:00

  // Special
  ISO = 'ISO',
}

const isToday = (date: DateTime) => date.toISODate() === DateTime.utc().toISODate();
const isYesterday = (date: DateTime) => date.toISODate() === DateTime.utc().minus({ days: 1 }).toISODate();

const resetTimeOffset = (date: Date): Date => {
  const stringify = DateTime.fromJSDate(date, { zone: currentTimeZone || 'UTC' }).toISO();
  const formatted = `${stringify?.slice(0, 19)}+00:00`;

  return DateTime.fromISO(formatted, { zone: 'UTC' }).toJSDate();
};

const setTimeOffset = (date: string) => {
  const localNow = new Date();
  const formatted = DateTime.fromJSDate(localNow, { zone: currentTimeZone || 'UTC' }).toISO();

  return `${date.slice(0, 19)}${formatted?.slice(-6)}`;
};

const getDynamicDate = (date: string, zone?: string) => {
  const dateTime = DateTime.fromISO(date, { zone });
  const timeOnly = dateTime.toFormat('t', {
    locale: i18n.language,
  });

  if (isToday(dateTime)) {
    return `${i18n.t('default:common.today')} ${i18n.t('default:common.at')} ${timeOnly}`;
  } else if (isYesterday(dateTime)) {
    return `${i18n.t('default:common.yesterday')} ${i18n.t('default:common.at')} ${timeOnly}`;
  } else {
    return `${dateTime.toFormat('dd/LL/yyyy')} ${i18n.t('default:common.at')} ${timeOnly}`;
  }
};

export const getDurationInHours = (duration: number) => Duration.fromMillis(duration * 1000).toFormat("hh'h' mm'm'");

export const formatDate = (date: string, format: DATE_FORMAT, useCurrentTimeZone?: boolean) => {
  const zone = useCurrentTimeZone ? currentTimeZone || undefined : 'UTC';

  switch (format) {
    case DATE_FORMAT.DYNAMIC_COMPLETE:
      return getDynamicDate(date, zone);
    case DATE_FORMAT.DATE_MONTH_FULL:
      return DateTime.fromISO(date, { zone }).toLocaleString(
        {
          month: 'long',
          day: 'numeric',
        },
        { locale },
      );
    case DATE_FORMAT.DATE_MONTH_HALF:
      return DateTime.fromISO(date, { zone }).toLocaleString(
        {
          month: 'short',
          day: 'numeric',
        },
        { locale },
      );
    case DATE_FORMAT.TIME_SIMPLE:
      return DateTime.fromISO(date, { zone }).toLocaleString(DateTime.TIME_SIMPLE, { locale });
    case DATE_FORMAT.TIME_SIMPLE_RAW:
      return DateTime.fromISO(date, { zone }).toLocaleString(DateTime.TIME_SIMPLE, { locale });

    case DATE_FORMAT.DATE_MONTH_S_HOURS:
    case DATE_FORMAT.WEEKDAY_DATE:
    case DATE_FORMAT.DATE_YEAR:
    case DATE_FORMAT.DATE_YEAR_W_TIME:
    case DATE_FORMAT.COMPLETE:
    case DATE_FORMAT.MONTH_FULL_YEAR:
    case DATE_FORMAT.MONTH_HALF_YEAR:
    case DATE_FORMAT.WEEKDAY_DATE_MONTH_TIME:
    case DATE_FORMAT.TIME_ONLY:
      return DateTime.fromISO(date, { zone }).toFormat(format, { locale: i18n.language });

    default:
      return 'INVALID DATE';
  }
};

export const formatJSDate = (date: Date, format: DATE_FORMAT, useCurrentTimeZone?: boolean): string => {
  const zone = useCurrentTimeZone ? currentTimeZone || undefined : 'UTC';
  const sourceDate = useCurrentTimeZone ? date : resetTimeOffset(date);

  switch (format) {
    case DATE_FORMAT.TIME_SIMPLE:
      return DateTime.fromJSDate(sourceDate, { zone }).toFormat(DATE_FORMAT.TIME_ONLY, { locale });

    case DATE_FORMAT.DATE_YEAR_DASHED:
      return DateTime.fromJSDate(sourceDate, { zone }).toFormat(format);

    case DATE_FORMAT.ISO:
      return DateTime.fromJSDate(sourceDate, { zone }).toISO() || 'Invalid Date';

    default:
      return 'INVALID DATE';
  }
};

export const toJSDate = (date: string, useCurrentTimeZone?: boolean): Date => {
  const zone = useCurrentTimeZone ? currentTimeZone || undefined : 'UTC';

  return DateTime.fromISO(setTimeOffset(date), { zone }).toJSDate();
};

// UnixTime 1658563916
export const getUnixTime = (date?: string) => {
  if (!date) {
    return DateTime.now().toUnixInteger();
  }

  return DateTime.fromISO(date).toUnixInteger();
};
