import React from 'react';
import clsx from 'clsx';
import { Tooltip } from 'components/tooltip';
import styles from './styles.module.scss';

type TProps = {
  label?: string;
  isActive: boolean;
  theme?: 'round' | 'default';
  onClick: () => void;
  disabled?: boolean;
  className?: string;
  bold?: boolean;
  tooltip?: string;
  testId?: string;
};

export const Switch: React.FC<TProps> = ({
  label,
  onClick,
  isActive,
  disabled,
  theme = 'default',
  className,
  bold,
  tooltip,
  testId = 'SwitchRoot',
}) => {
  const handleClick = () => {
    if (disabled) {
      return;
    }

    onClick();
  };

  return (
    <div className={clsx(styles.root, className)}>
      {label && (
        <>
          <div
            className={clsx(styles.label, { [styles.bold]: bold, [styles.withTooltip]: Boolean(tooltip) })}
            data-testid="SwitchLabel"
          >
            {label}
          </div>
          {tooltip && (
            <div className={styles.tooltip}>
              <Tooltip text={tooltip} position="top" width={300} />
            </div>
          )}
        </>
      )}

      <div
        onClick={handleClick}
        data-testid={testId}
        className={clsx(styles.switch, styles[theme], {
          [styles.active]: isActive,
          [styles.disabled]: disabled,
        })}
      />
    </div>
  );
};
