import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetBooking } from 'api/bookings';
import { ActionsRequired } from 'components/actionsRequired';
import { ActivityFeed } from 'components/activityFeed';
import { StatusPill } from 'components/badge/statusPill';
import { Layout } from 'components/layout';
import { NoContent } from 'components/noContent';
import { OrderDocuments } from 'components/orderDocuments';
import { OverviewTab } from 'components/orderOverview';
import { Tabs } from 'components/tabs';
import { BookingStatus } from 'generated/api';

import { ProgressTab } from './progressTab';
import styles from './styles.module.scss';
import { BOOKING_TAB } from './utils';

export const BookingPage = () => {
  const { t } = useTranslation('default', { keyPrefix: 'pages/booking' });
  const { t: docsT } = useTranslation('default', {
    keyPrefix: 'components/openTasksWidget',
  });

  const { id, tab } = useParams();
  const navigate = useNavigate();
  const { data, isError, isLoading, error } = useGetBooking(id);

  const actions = useMemo(
    () =>
      data?.tasks.map((task) => ({
        title: `${t('add-missing')} (${docsT(`types.${task.content}`)})`,
        dueDate: task.dueDate,
        action: () => navigate(`/bookings/${id}/${BOOKING_TAB.DOCUMENTS}`),
        completed: task.completed,
      })) || [],

    [data?.tasks, docsT, id, navigate, t],
  );

  const openTasks = actions.filter((task) => !task.completed);

  const tabs = useMemo(
    () =>
      data
        ? [
            {
              key: BOOKING_TAB.OVERVIEW,
              label: t('tabs.overview'),
              component: <OverviewTab booking={data} key="overviewTab" />,
            },
            {
              key: BOOKING_TAB.DOCUMENTS,
              label: t('tabs.documents'),
              component: <OrderDocuments bookingId={data.id} documents={data.documents} key="orderDocumentsTab" />,
              shouldRender: () => Boolean(actions.length),
            },
            {
              key: BOOKING_TAB.ACTIVITY,
              label: t('tabs.activityFeed'),
              component: <ActivityFeed booking={data} key="activityFeedTab" />,
            },
            {
              key: BOOKING_TAB.PROGRESS,
              label: t('tabs.progress'),
              component: <ProgressTab booking={data} key="progressTab" />,
              shouldRender: () =>
                [BookingStatus.PENDING, BookingStatus.CONFIRMED, BookingStatus.CANCELLED].every(
                  (status) => status !== data?.status,
                ),
            },
          ].filter((tempTab) => (tempTab.shouldRender ? tempTab.shouldRender() : true))
        : [],
    [data, t, actions.length],
  );

  const handleSetTab = (goToTab: string) => {
    navigate(`/bookings/${id}/${goToTab}`);
  };

  if (isError || isLoading || !data) {
    return (
      <NoContent
        error={error}
        isError={isError}
        isLoading={isLoading}
        title={t('noContent.title')}
        testId="BookingPageNoContent"
      />
    );
  }

  return (
    <Layout
      title={`${t('title')} ${id}`}
      testId="BookingPageLayout"
      adornment={<StatusPill status={data.status} />}
      backLink="/bookings"
    >
      {Boolean(openTasks.length) && tab !== BOOKING_TAB.DOCUMENTS && (
        <div className={styles.actionsRequired}>
          <ActionsRequired actions={openTasks} />
        </div>
      )}
      <Tabs tabs={tabs} lazy controlledTab={tab} setControlledTab={handleSetTab} />
    </Layout>
  );
};
