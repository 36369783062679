import React, { useContext, useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import { ApiError, Booking, FeedEntry, MessageRecipient, MessageRecipientType, Operator } from 'generated/api';
import { Button } from 'components/button';
import { Textarea } from 'components/textarea';
import { Loader } from 'components/loader';
import {
  activityFeedPostedMessage,
  activityFeedQueryKey,
  postActivityFeed,
  useGetActivityFeed,
} from 'api/activityFeed';
import { ActivityFeedForm } from 'constants/activityFeed';
import { queryClient } from 'constants/api';
import { Tabs } from 'components/tabs';
import { Entity } from 'components/entity';
import { ReactComponent as RailIcon } from 'assets/images/icons/default/rail.svg';
import { ReactComponent as RoadIcon } from 'assets/images/icons/default/road.svg';
import ToastContext from 'components/toast';
import { truthyFilter } from 'utils/misc';
import { ActivityFilters } from './activityFilters';
import { ActivityFeedItem } from './activityFeedItem';
import styles from './styles.module.scss';

export type TProps = {
  booking: Booking;
  children?: React.ReactNode;
};

export const defaultFilter = {
  EVENT: true,
  MESSAGE: true,
};

export const ActivityFeed: React.FC<TProps> = ({ booking }) => {
  const { t } = useTranslation('default', {
    keyPrefix: 'components/activityFeed',
  });

  const { t: commonT } = useTranslation();
  const [tab, setTab] = useState<string>(MessageRecipientType.INTERNAL_TEAM);
  const [filters, setFilters] = useState(defaultFilter);
  const [feedItems, setFeedItems] = useState<FeedEntry[]>([]);
  const { playApiErrorToast, playSuccessToast } = useContext(ToastContext);
  const [roadOperators, setRoadOperator] = useState<Operator[] | null>(null);
  const [railOperator, setRailOperator] = useState<Operator | null>(null);
  const [isMultiModal, setIsMultiModal] = useState<boolean>(false);

  const recipientsTabs = useMemo(() => {
    const { route } = booking;

    const tempRecipients: { key: string; label: string }[] = [
      { key: MessageRecipientType.INTERNAL_TEAM, label: t(MessageRecipientType.INTERNAL_TEAM) },
      {
        key: MessageRecipientType.ROUVIA_CUSTOMER_SERVICE,
        label: t(MessageRecipientType.ROUVIA_CUSTOMER_SERVICE),
      },
    ];

    if (route.type === 'RAIL') {
      tempRecipients.push({
        key: MessageRecipientType.OPERATOR,
        label: route.railLeg.operator.name,
      });

      if (route.firstMileLeg && route.railLeg.operator.id !== route.firstMileLeg.operator.id) {
        tempRecipients.push({
          key: `${MessageRecipientType.ROAD_CARRIER}=${route.firstMileLeg.operator.id}`,
          label: route.firstMileLeg.operator.name,
        });
      }

      if (
        route.lastMileLeg &&
        !tempRecipients.some((recipient) => recipient.label === route.lastMileLeg?.operator.name)
      ) {
        tempRecipients.push({
          key: `${MessageRecipientType.ROAD_CARRIER}=${route.lastMileLeg.operator.id}`,
          label: route.lastMileLeg.operator.name,
        });
      }
    } else if (booking.type === 'TRUCK') {
      tempRecipients.push({
        key: MessageRecipientType.ROAD_CARRIER,
        label: booking.route.truckLeg.operator.name,
      });
    }

    return tempRecipients;
  }, [booking, t]);

  useEffect(() => {
    const { type, route } = booking;

    if (type === 'RAIL') {
      const railLegOperator = route.railLeg.operator;
      const availableRoadOperators: Operator[] = [route.firstMileLeg?.operator, route.lastMileLeg?.operator].filter(
        truthyFilter<Operator>,
      );

      setIsMultiModal(railLegOperator.operatorType === 'MULTIMODAL');
      setRoadOperator(availableRoadOperators.length ? availableRoadOperators : null);
      setRailOperator(railLegOperator);
    } else if (type === 'TRUCK') {
      setRoadOperator([route.truckLeg.operator]);
      setRailOperator(null);
      setIsMultiModal(false);
    }
  }, [booking]);

  /**
   * "Type" taken from "tab" variable could be either "MessageRecipientType" type
   * or "MessageRecipientType=UUID"
   *
   * This function extracts Type and UUID (operatorID) from "tab" string
   * @return {MessageRecipient}
   */
  const getPostBody = (): MessageRecipient => {
    const { type, route } = booking;

    const [tabType, operatorId] = tab.split('=');

    if (tabType === MessageRecipientType.OPERATOR && type === 'RAIL') {
      return { type: tabType, operatorId: route.railLeg.operator.id };
    } else if (tabType === MessageRecipientType.ROAD_CARRIER) {
      if (type === 'TRUCK') {
        return { type: tabType, operatorId: route.truckLeg.operator.id };
      } else {
        return { type: tabType, operatorId };
      }
    } else if (tabType === MessageRecipientType.ROUVIA_CUSTOMER_SERVICE) {
      return {
        type: MessageRecipientType.ROUVIA_CUSTOMER_SERVICE,
      };
    }

    return {
      type: MessageRecipientType.INTERNAL_TEAM,
    };
  };

  const {
    control,
    getValues: getActivityFeedForm,
    handleSubmit,
    setValue: updateForm,
  } = useForm<ActivityFeedForm>({
    defaultValues: {
      message: '',
      recipient: MessageRecipientType.INTERNAL_TEAM,
    },
  });

  const { data: feed, isError, isLoading } = useGetActivityFeed(booking.id);
  const { mutate: postMessage, isError: isPostMessageError } = useMutation(
    [activityFeedPostedMessage],
    (activityFeedForm: ActivityFeedForm) => postActivityFeed(booking.id, activityFeedForm.message, getPostBody()),
    {
      onError: (err: ApiError) => {
        playApiErrorToast(err);
      },
      onSuccess: () => {
        updateForm('message', '');

        queryClient.invalidateQueries([activityFeedQueryKey, booking.id]).then(() => {
          playSuccessToast();
        });
      },
    },
  );

  useEffect(() => {
    if (feed) {
      setFeedItems(
        feed.filter(
          (item: FeedEntry) => (item.type === 'MESSAGE' && filters.MESSAGE) || (item.type === 'EVENT' && filters.EVENT),
        ),
      );
    }
  }, [filters, feed]);

  const onSubmit = () => {
    if (Boolean(getActivityFeedForm('message').length)) {
      postMessage(getActivityFeedForm());
    }
  };

  const getStakeholders = () => {
    const { type } = booking;

    if (type === 'RAIL') {
      const route = booking.route;
      const firstMileLeg = route.firstMileLeg && (
        <Entity
          image={<RoadIcon />}
          title={route.firstMileLeg.operator.name}
          subtitle={route.firstMileLeg.operator.contactInfo}
          fullWidth
        />
      );

      const lastMileLeg = route.lastMileLeg && route.lastMileLeg.operator.id !== route?.firstMileLeg?.operator?.id && (
        <Entity
          image={<RoadIcon />}
          title={route.lastMileLeg.operator.name}
          subtitle={route.lastMileLeg.operator.contactInfo}
          fullWidth
        />
      );

      return (
        <>
          <div className={styles.entityList} data-testid="railStakeholders">
            <div className={styles.entityTitle}>
              {isMultiModal ? t('mainOperator') : t(MessageRecipientType.OPERATOR)}:
            </div>
            <Entity
              image={<RailIcon />}
              title={route.railLeg.operator.name}
              subtitle={route.railLeg.operator.contactInfo}
              fullWidth
            />
          </div>
          {(firstMileLeg || lastMileLeg) && !isMultiModal && (
            <div className={styles.entityList}>
              <div className={styles.entityTitle}>{t(MessageRecipientType.ROAD_CARRIER)}:</div>
              {firstMileLeg}
              {lastMileLeg}
            </div>
          )}
        </>
      );
    } else if (type === 'TRUCK') {
      const route = booking.route;

      return (
        <>
          <div className={styles.entityTitle} data-testid="truckStakeholders">
            {t(MessageRecipientType.ROAD_CARRIER)}:
          </div>
          <Entity
            image={<RoadIcon />}
            title={route.truckLeg.operator.name}
            subtitle={route.truckLeg.operator.contactInfo}
          />
        </>
      );
    }
  };

  return (
    <div className={styles.root} data-testid="activityFeedRoot">
      <div className={styles.sideColumn}>{getStakeholders()}</div>
      <div className={styles.mainColumn}>
        <div className={styles.wrapper}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className={styles.form}>
              <Tabs tabs={recipientsTabs} controlledTab={tab} setControlledTab={setTab} noChildren underline={false} />
              <div className={styles.recipientSelect}></div>
              <Controller
                name="message"
                control={control}
                render={({ field }) => (
                  <Textarea
                    placeholder={t('inputPlaceholder')}
                    className={clsx(styles.inputWrapper, tab)}
                    inputClassName={styles.input}
                    testId="textAreaInputActivity"
                    {...field}
                  />
                )}
              />

              <div className={styles.textareaSubtitle}>
                {t('thisMessage')} {recipientsTabs.find((r) => r.key === tab)?.label}
              </div>
              <div className={styles.simpleFlex}>
                <Button testId="activityMessageSubmitBtn" className={clsx(styles.submit, tab)} type="submit">
                  {t('submitButton')}
                </Button>
              </div>
            </div>
          </form>
          <div className={styles.alignEnd}>
            <ActivityFilters filters={filters} setFilters={setFilters} />
          </div>
          <div className={styles.notifications}>
            {isLoading && <Loader />}

            {(isError || isPostMessageError) && <div className={styles.error}>{commonT('common.error')}</div>}

            <div className={styles.notifications} data-testid="ActivityFeedItems">
              {feedItems.map((notification: FeedEntry) => (
                <ActivityFeedItem
                  key={notification.createdAt}
                  notification={notification}
                  roadOperators={roadOperators}
                  railOperator={railOperator}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
