import React from 'react';
import { createRoot } from 'react-dom/client';
import { AuthProvider } from 'react-oidc-context';
import { HelmetProvider } from 'react-helmet-async';
import { ErrorBoundary } from '@sentry/react';
import { AuthWall } from 'components/authWall';
import { ErrorBoundaryFallback } from 'components/errorBoundary';
import { App } from 'pages';
import { oidcConfig } from 'keycloak';
import './i18n';

const container = document.getElementById('root');

if (container) {
  const root = createRoot(container);

  root.render(
    <React.StrictMode>
      <ErrorBoundary fallback={ErrorBoundaryFallback}>
        <AuthProvider {...oidcConfig}>
          <AuthWall>
            <HelmetProvider>
              <App />
            </HelmetProvider>
          </AuthWall>
        </AuthProvider>
      </ErrorBoundary>
    </React.StrictMode>,
  );
}
