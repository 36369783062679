import React, { MouseEvent } from 'react';
import clsx from 'clsx';
import { Loader } from 'components/loader';
import styles from './styles.module.scss';

export type IBasicButton = React.ButtonHTMLAttributes<HTMLButtonElement>;

type TProps = {
  as?: any;
  to?: string;
  className?: string;
  width?: number;
  size?: 'large' | 'medium' | 'small';
  theme?: 'accent' | 'neutral' | 'black' | 'ghost' | 'danger';
  iconOnly?: boolean;
  isLoading?: boolean;
  children?: React.ReactNode;
  noBlur?: boolean;
  testId?: string;
} & Partial<IBasicButton>;

export const Button: React.FC<TProps> = ({
  children,
  as = 'button',
  className,
  theme = 'neutral',
  size = 'medium',
  iconOnly,
  width,
  isLoading,
  disabled,
  onClick,
  noBlur,
  testId = 'button',
  ...rest
}) => {
  const Component = as;

  const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
    onClick?.(e);

    if (!noBlur) {
      e.currentTarget.blur();
    }
  };

  return (
    <Component
      {...rest}
      className={clsx(styles.root, styles[theme], styles[size], { [styles.iconOnly]: iconOnly }, className)}
      style={width ? { width: `${width}px` } : undefined}
      disabled={isLoading || disabled}
      onClick={handleClick}
      data-testid={testId}
    >
      {isLoading ? <Loader /> : children}
    </Component>
  );
};
