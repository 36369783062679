import React from 'react';
import { useTranslation } from 'react-i18next';
import { ContentBox } from 'components/contentBox';
import { Map } from 'components/map';
import { Booking, ShipmentEvent } from 'generated/api';
import { ListItem } from './ListItem';
import styles from './styles.module.scss';

type TProps = {
  booking: Booking;
};

export const ProgressTab: React.FC<TProps> = ({ booking }) => {
  const { t } = useTranslation('default', { keyPrefix: 'pages/booking' });
  const { t: eventsT } = useTranslation('default', { keyPrefix: 'bookingEventUpdates' });

  return (
    <div className={styles.progressTabContainer}>
      <ContentBox title={t('tabs.progress')} roundedBtm>
        <div className={styles.listWrapper}>
          <ul>
            {booking.events.map((stage: ShipmentEvent, index) => (
              <ListItem
                key={stage.name}
                title={eventsT(`${stage.name}.title`)}
                message={eventsT(`${stage.name}.message`)}
                ata={stage.ata}
                eta={stage.eta}
                stageStatus={stage}
                isLastItem={booking.events.length === index + 1}
              />
            ))}
          </ul>
        </div>
      </ContentBox>

      <ContentBox title={t('map')} contentClassName={styles.tabRoot} roundedBtm>
        <div className={styles.map}>
          <Map bookings={[booking]} mapConfig={{ zoom: 4 }} />
        </div>
      </ContentBox>
    </div>
  );
};
