import { Language } from 'generated/api';

export interface UserInformationAccountValues {
  name: string;
  surname: string;
  email: string;
  timezone: {
    value: string;
    label: string;
  } | null;
  preferredLanguage: {
    value: Language;
    label: string;
  } | null;
}

export const userInformationFormValues: UserInformationAccountValues = {
  name: '',
  surname: '',
  email: '',
  timezone: null,
  preferredLanguage: null,
};

export interface NotificationsAccountValues {
  emailNotifications: boolean;
}

export const notificationsAccountValues: NotificationsAccountValues = {
  emailNotifications: false,
};

export interface PasswordAccountValues {
  currentPassword: string;
  newPassword: string;
  confirmNewPassword: string;
}

export const passwordAccountValues: PasswordAccountValues = {
  currentPassword: '',
  newPassword: '',
  confirmNewPassword: '',
};
