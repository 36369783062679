import React from 'react';

type TProps = {
  children?: React.ReactNode;
};

export const SpaceSeparator: React.FC<TProps> = ({ children }) => {
  const childCount = React.Children.count(children);

  return (
    <>
      {React.Children.map(children, (child, index) => (
        <>
          {child}
          {index + 1 !== childCount ? ' ' : ''}
        </>
      ))}
    </>
  );
};
