import type { AuthProviderProps } from 'react-oidc-context';
import config from 'constants/config';

const redirectUrl = `${window.location.origin}${window.location.pathname}`;

export const oidcConfig: AuthProviderProps = {
  authority: `${config.oidc.authority}/realms/rouvia`,
  client_id: 'frontend',
  redirect_uri: redirectUrl,
  post_logout_redirect_uri: redirectUrl,
  onSigninCallback: (): void => {
    window.history.replaceState({}, document.title, window.location.pathname);
  },
};
