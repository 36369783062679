import { Navigate } from 'react-router-dom';
import { DashboardPage } from 'pages/dashboard';
import { PlanningPage } from 'pages/planning';
import { QuotesSearchPage } from 'pages/quotes';
import { SingleQuotePage } from 'pages/quotes/singleQuotePage';
import { BookingsOverviewPage } from 'pages/bookings';
import { BookingPage } from 'pages/booking';
import { AccountPage } from 'pages/accounts';
import { OrganisationManagementPage } from 'pages/orgManagement';
import { NotificationsPage } from 'pages/notifications';
import NotFoundPage from 'pages/404';

import { IRoute, IRoutes, PAGES, ROUTES_INFO } from 'constants/routes/utils';
import { User } from 'generated/api';
import { isAdminUser } from 'utils/misc';

const ROUTES_ADMIN: IRoutes = {
  [PAGES.ORG_MANAGEMENT]: {
    routeInfo: ROUTES_INFO[PAGES.ORG_MANAGEMENT],
    Component: <OrganisationManagementPage />,
  },
};

export const ROUTES: IRoutes = {
  [PAGES.HOME]: {
    routeInfo: ROUTES_INFO[PAGES.HOME],
    Component: <DashboardPage />,
  },
  [PAGES.PLANNING]: {
    routeInfo: ROUTES_INFO[PAGES.PLANNING],
    Component: <PlanningPage />,
  },
  [PAGES.PLANNING_PREFILLED]: {
    routeInfo: ROUTES_INFO[PAGES.PLANNING_PREFILLED],
    Component: <PlanningPage />,
  },
  [PAGES.QUOTES_SEARCH]: {
    routeInfo: ROUTES_INFO[PAGES.QUOTES_SEARCH],
    Component: <QuotesSearchPage />,
  },
  [PAGES.SINGLE_QUOTE]: {
    routeInfo: ROUTES_INFO[PAGES.SINGLE_QUOTE],
    Component: <SingleQuotePage />,
  },
  [PAGES.ACCOUNT]: {
    routeInfo: ROUTES_INFO[PAGES.ACCOUNT],
    Component: <AccountPage />,
  },
  [PAGES.BOOKINGS]: {
    routeInfo: ROUTES_INFO[PAGES.BOOKINGS],
    Component: <BookingsOverviewPage />,
  },
  [PAGES.SINGLE_BOOKING]: {
    routeInfo: ROUTES_INFO[PAGES.SINGLE_BOOKING],
    Component: <BookingPage />,
  },
  [PAGES.SINGLE_BOOKING_REDIRECT]: {
    routeInfo: ROUTES_INFO[PAGES.SINGLE_BOOKING_REDIRECT],
    Component: <Navigate to="overview" replace={true} />,
  },
  [PAGES.NOTIFICATIONS]: {
    routeInfo: ROUTES_INFO[PAGES.NOTIFICATIONS],
    Component: <NotificationsPage />,
  },
  [PAGES.NOT_FOUND]: {
    routeInfo: ROUTES_INFO[PAGES.NOT_FOUND],
    Component: <NotFoundPage />,
  },
};

const getRoutesArray = (routes: IRoutes) =>
  Object.values(PAGES)
    .map((key: PAGES) => routes[key])
    .filter((key): key is NonNullable<typeof key> => key !== undefined);

export const PAGES_ARRAY_USER: IRoute[] = getRoutesArray(ROUTES);

const PAGES_ARRAY_ADMIN: IRoute[] = [...PAGES_ARRAY_USER, ...getRoutesArray(ROUTES_ADMIN)];

export const getRoutesBasedOnUser = (user: User): IRoute[] => {
  if (isAdminUser(user)) {
    return PAGES_ARRAY_ADMIN;
  }

  return PAGES_ARRAY_USER;
};
