import React, { useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@tanstack/react-query';

import i18n from 'i18n';
import { ContentBox } from 'components/contentBox';
import { Input } from 'components/input';
import { CustomSelect } from 'components/select';
import { useGetUser } from 'utils/hooks/useGetUser';
import { timezones } from 'constants/timezones';
import { languages } from 'constants/languages';
import { SavingButtonSection } from 'components/savingButtonSection';
import { Modal } from 'components/modal';
import { extractError } from 'utils/misc';
import { Language, UpdateUserRequest } from 'generated/api';
import { queryClient } from 'constants/api';
import { UserInformationAccountValues, userInformationFormValues } from 'constants/account';
import { updateUserInfo, useGetUserInfo, userInfoQueryKey } from 'api/user';

import { ImageCropUpload } from '../imageCropUpload';
import styles from './styles.module.scss';

export const PersonalInfo = () => {
  const { t } = useTranslation('default', { keyPrefix: 'pages/accounts.personalInfo' });
  const { t: commonT } = useTranslation('default', { keyPrefix: 'common' });

  const [isImageModalOpen, setIsImageModalOpen] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [user] = useGetUser();

  const { control, reset, handleSubmit } = useForm<UserInformationAccountValues>({
    defaultValues: userInformationFormValues,
  });

  const { data: userData } = useGetUserInfo(user.id);

  const {
    mutate: infoMutate,
    isSuccess: isInfoSuccess,
    error: infoError,
    isLoading: isInfoLoading,
  } = useMutation(
    (params: { userId: string; userObj: UpdateUserRequest }) => updateUserInfo(params.userId, params.userObj),
    {
      onSettled: () => {
        queryClient.invalidateQueries([userInfoQueryKey]);
      },
    },
  );

  const formTimezones = useMemo(() => timezones.map((zone) => ({ label: zone, value: zone })), []);

  useEffect(() => {
    if (userData) {
      reset({
        name: userData.firstName,
        surname: userData.lastName,
        email: userData.email,
        timezone: formTimezones.find((zone) => zone.value === userData.timezone) || null,
        preferredLanguage: languages.find((lang) => lang.value === userData.languagePreference) || null,
      });
    }
  }, [reset, formTimezones, userData]);

  useEffect(() => {
    if (infoError) {
      const message = extractError(infoError);

      if (message) {
        setErrorMessage(message);
      }
    } else {
      setErrorMessage('');
    }
  }, [infoError]);

  const onSubmit = (data: UserInformationAccountValues) => {
    if (userData) {
      infoMutate(
        {
          userId: userData.id,
          userObj: {
            firstName: data.name,
            lastName: data.surname,
            timezone: data.timezone?.value || '',
            languagePreference: data.preferredLanguage?.value || Language.EN,
          },
        },
        {
          onSuccess: () => {
            i18n.changeLanguage(data.preferredLanguage?.value.toLocaleLowerCase() || Language.EN.toLocaleLowerCase());
          },
        },
      );
    }
  };

  return (
    <>
      <ContentBox title={t('personalInfo')} roundedBtm boxShadow isExpandable contentClassName={styles.content}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={styles.root}>
            {/*<div className={styles.flexRowDouble}>*/}
            {/*  <div className={styles.simpleFlex}>*/}
            {/*    <UserAvatar size={'large'} user={user} />*/}
            {/*    <div className={styles.labelUpload} onClick={() => setImageModalOpen(true)}>*/}
            {/*      {t('uploadImage')}*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*</div>*/}

            <div className={styles.flexRowDouble}>
              <Controller
                name="name"
                control={control}
                rules={{
                  required: commonT('validation.required'),
                }}
                render={({ field, fieldState: { error } }) => (
                  <Input showRequired type="text" label={t('name')} error={error?.message} {...field} />
                )}
              />
              <Controller
                name="surname"
                control={control}
                rules={{
                  required: commonT('validation.required'),
                }}
                render={({ field, fieldState: { error } }) => (
                  <Input showRequired type="text" label={t('surname')} error={error?.message} {...field} />
                )}
              />
            </div>
            <div className={styles.flexRowDouble}>
              <Controller
                name="email"
                control={control}
                render={({ field }) => <Input showRequired type="text" label={t('email')} disabled {...field} />}
              />
              <Controller
                name="timezone"
                control={control}
                rules={{
                  required: commonT('validation.required'),
                }}
                render={({ field, fieldState: { error } }) => (
                  <CustomSelect
                    showRequired
                    options={formTimezones}
                    error={error?.message}
                    label={t('timezone')}
                    {...field}
                  />
                )}
              />
            </div>
            <div className={styles.flexRowDouble}>
              <Controller
                name="preferredLanguage"
                control={control}
                rules={{
                  required: commonT('validation.required'),
                }}
                render={({ field, fieldState: { error } }) => (
                  <CustomSelect
                    showRequired
                    options={languages}
                    error={error?.message}
                    label={t('prefLanguage')}
                    {...field}
                  />
                )}
              />
            </div>
          </div>
          <SavingButtonSection isLoading={isInfoLoading} isSuccess={isInfoSuccess} errorMessage={errorMessage} />
        </form>
      </ContentBox>

      {isImageModalOpen && (
        <Modal onClose={() => setIsImageModalOpen(false)} title={t('newProfile')}>
          <ImageCropUpload />
        </Modal>
      )}
    </>
  );
};
