import React from 'react';
import clsx from 'clsx';

import { CheckIcon } from '@rouvia/icons';
import { TextFieldMeta } from 'components/textFieldMeta';
import styles from './styles.module.scss';

export type IBasicInput = React.InputHTMLAttributes<HTMLInputElement>;

type TProps = {
  className?: string;
  label?: string;
  labelPosition?: 'left' | 'right';
  error?: string;
  testId?: string;
} & Partial<IBasicInput>;

export const Checkbox = React.forwardRef<HTMLInputElement, TProps>(
  ({ label, error, labelPosition = 'right', checked, className, name, onChange, disabled, testId, ...rest }, ref) => (
    <label className={clsx(styles.label, className, { [styles.disabled]: disabled })} data-testid={testId}>
      <div className={styles.content}>
        <input
          ref={ref}
          className={styles.input}
          onChange={onChange}
          disabled={disabled}
          type="checkbox"
          checked={checked}
          name={name}
          {...rest}
        />
        <i
          className={clsx(styles.checkbox, styles[labelPosition], {
            [styles.disabled]: disabled,
            [styles.error]: error,
            [styles.checked]: checked,
          })}
          data-testid="checkbox"
        >
          <CheckIcon className={styles.icon} />
        </i>

        {label && (
          <div
            data-testid="checkboxLabel"
            className={clsx(styles.labelText, styles[labelPosition], {
              [styles.checked]: checked,
              [styles.disabled]: disabled,
            })}
          >
            {label}
          </div>
        )}
      </div>

      {error && <TextFieldMeta className={styles.errorMessage} error={error} />}
    </label>
  ),
);

Checkbox.displayName = 'Checkbox';
