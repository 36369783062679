import React from 'react';
import { useTranslation } from 'react-i18next';
import { ContentBox } from 'components/contentBox';
import { BookingAdditionalInformation, PortInformation, WarehouseInformation } from 'generated/api';
import { DATE_FORMAT, formatDate } from 'utils/date';
import styles from './styles.module.scss';

type TProps = {
  data: BookingAdditionalInformation;
};

export const AdditionalInformation: React.FC<TProps> = ({ data: { origin, destination } }) => {
  const { t } = useTranslation('default', {
    keyPrefix: 'components/orderOverview/additionalInformation',
  });

  if ((!origin || !('port' in origin)) && (!destination || !('port' in destination))) {
    return null;
  }

  const getData = (point: 'origin' | 'destination', location?: PortInformation | WarehouseInformation) => {
    if (!location || !('port' in location)) {
      return;
    }

    return (
      <div className={styles.additionalInfoRoot}>
        <div className={styles.header}>{t(`${point}.title`)}</div>

        <div className={styles.doubleFlex}>
          <div className={styles.flexGroup}>
            <div className={styles.flexItem}>
              <div className={styles.underValue}>{t('vesselName')}:</div>
              <div className={styles.labelValue}>{location.vesselName}</div>
            </div>

            <div className={styles.flexItem}>
              <div className={styles.underValue}>{t(`${point}.date`)}</div>
              <div className={styles.labelValue}>{formatDate(location.dateTime, DATE_FORMAT.COMPLETE)}</div>
            </div>
          </div>
          <div className={styles.flexGroup}>
            <div className={styles.flexItem}>
              <div className={styles.underValue}>{t(`${point}.portOf`)}</div>
              <div className={styles.labelValue}>{location.port}</div>
            </div>

            <div className={styles.flexItem}>
              <div className={styles.underValue}>{t('shippingLine')}</div>
              <div className={styles.labelValue}>{location.shippingLine}</div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <ContentBox title={t('title')} contentClassName={styles.overview}>
      {getData('origin', origin)}
      {getData('destination', destination)}
    </ContentBox>
  );
};
