import React, { ReactNode } from 'react';
import clsx from 'clsx';
import { DATE_FORMAT, formatDate } from 'utils/date';
import styles from './styles.module.scss';

type TProps = {
  image?: ReactNode;
  title: string | React.ReactNode;
  subtitle?: string;
  iconSize?: 'sm' | 'md' | 'lg';
  dateTime?: string;
  fullWidth?: boolean;
  children?: React.ReactNode;
};

export const Entity: React.FC<TProps> = ({
  children,
  image,
  title,
  subtitle,
  iconSize = 'sm',
  dateTime,
  fullWidth,
}) => (
  <div className={clsx(styles.root, { [styles.fullWidth]: fullWidth })} data-testid="entityRoot">
    <div className={clsx(styles.avatar, styles[iconSize])}>{image || <img src="/images/logo.png" alt="entity" />}</div>
    <div className={styles.flexColumn}>
      <div className={styles.titles}>
        <div className={styles.title}>
          <div data-testid="entityTitle">{title}</div>
          {dateTime && <div className={styles.dateTime}>{formatDate(dateTime, DATE_FORMAT.DATE_MONTH_S_HOURS)}</div>}
        </div>
        {subtitle && (
          <div className={styles.subtitle} data-testid="entitySubtitle">
            {subtitle}
          </div>
        )}
      </div>
      {children}
    </div>
  </div>
);
