import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import { UsersMenu } from 'components/userMenu';
import { MainMenu } from 'components/mainMenu';
import { PageHeader } from 'components/pageHeader';
import { useGetUser } from 'utils/hooks/useGetUser';
import { NotificationWidget } from 'components/notificationsWidget';
import { CentricContainer } from 'components/centricContainer';
import { SCREEN_SIZE } from 'constants/screenSize';
import { ReactComponent as Logo } from './logo.svg';
import styles from './styles.module.scss';

type TProps = {
  title?: string;
  adornment?: ReactNode;
  cta?: JSX.Element;
  backLink?: string | boolean;
  contentMaxWidth?: SCREEN_SIZE;
  children?: React.ReactNode;
  testId?: string;
};

export const Layout: React.FC<TProps> = ({
  title,
  adornment,
  cta,
  backLink,
  children,
  contentMaxWidth = SCREEN_SIZE.XL,
  testId,
}) => {
  const [user] = useGetUser();

  return (
    <div className={styles.layoutRoot} data-testid={testId}>
      <Helmet>
        <title>{title ? `${title} | Rouvia` : 'Rouvia'}</title>
      </Helmet>
      <div className={styles.header}>
        <CentricContainer className={styles.flexContainer}>
          <Link to="/">
            <Logo className={styles.logo} title="Rouvia" />
          </Link>

          <div className={styles.mainMenu}>
            <MainMenu />
          </div>

          <div className={styles.rightSide}>
            <div className={styles.item}>
              <NotificationWidget />
            </div>
            <div className={styles.item}>{user && <UsersMenu user={user} />}</div>
          </div>
        </CentricContainer>
      </div>

      <CentricContainer maxWidth={contentMaxWidth}>
        {title && <PageHeader title={title} cta={cta} backLink={backLink} adornment={adornment} />}
        <div className={styles.layoutContent}>{children}</div>
      </CentricContainer>
    </div>
  );
};
