import { DateTime } from 'luxon';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, UseFormResetField } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import type { UseFormSetValue, Control, UseFormGetValues, UseFormTrigger, UseFormWatch } from 'react-hook-form';

import { EmptyContainerDepot, Terminal, TerminalType } from 'generated/api';
import { SectionHeading } from 'components/sectionHeading';
import { Input } from 'components/input';
import { ISearchForm, TAddressType } from 'constants/planning';
import { loadSelectGeoOptionsDepots, loadSelectGeoOptionsTerminals } from 'utils/geo';
import { getAddressType, getSelectIcon, isTerminal } from 'utils/planning';

import { CustomDatePicker } from 'components/customDatePicker';
import { EditableSelect } from 'components/editableSelect';
import { Switch } from 'components/switch';
import styles from './styles.module.scss';

type TProps = {
  control: Control<ISearchForm>;
  getValues: UseFormGetValues<ISearchForm>;
  setValue: UseFormSetValue<ISearchForm>;
  resetField: UseFormResetField<ISearchForm>;
  watch: UseFormWatch<ISearchForm>;
  trigger: UseFormTrigger<ISearchForm>;
  terminals?: Terminal[];
  depots?: EmptyContainerDepot[];
};

export const CustomerInfoForm: React.FC<TProps> = ({
  depots = [],
  terminals = [],
  control,
  resetField,
  getValues,
  setValue,
  watch,
  trigger,
}) => {
  const { t } = useTranslation('default', {
    keyPrefix: 'pages/planning/components/customerForm',
  });
  const { t: commonT } = useTranslation('default', { keyPrefix: 'common' });
  const [originTerminalType, setOriginTerminalType] = useState<TAddressType | null>();
  const [destinationTerminalType, setDestinationTerminalType] = useState<TAddressType | null>();
  const [isContainerPickupRequest, setIsContainerPickupRequested] = useState<boolean>(false);
  const [isContainerDropOffRequest, setIsContainerDropOffRequested] = useState<boolean>(false);
  const [collectionDateHint, setCollectionDateHint] = useState<string>();
  const watchContainerPickup = watch('containerPickupAddress');
  const watchContainerDropoff = watch('containerDropOffAddress');

  useEffect(() => {
    setIsContainerPickupRequested(Boolean(watchContainerPickup));
  }, [watchContainerPickup]);

  useEffect(() => {
    setIsContainerDropOffRequested(Boolean(watchContainerDropoff));
  }, [watchContainerDropoff]);

  useEffect(() => {
    if (!isContainerPickupRequest) {
      resetField('containerPickupAddress', { defaultValue: null });
    }

    if (!isContainerDropOffRequest) {
      resetField('containerDropOffAddress', { defaultValue: null });
    }
  }, [isContainerPickupRequest, isContainerDropOffRequest, resetField]);

  const watchCollectionDate = watch('collectionDate');
  const watchDaysOffset = watch('collectionDaysOffset');
  const watchDeliveryDate = watch('deliveryDate');
  const watchOrigin = watch('originAddress');
  const watchDestination = watch('destinationAddress');

  useEffect(() => {
    if (watchOrigin?.value) {
      const addressType = getAddressType(watchOrigin.value);

      if (addressType === 'MARITIME') {
        setValue('earliestCollectionTime', '00:00');
        setValue('latestCollectionTime', '23:59');
      }

      if (addressType !== 'WAREHOUSE') {
        setIsContainerPickupRequested(false);
      }

      setOriginTerminalType(addressType);
    }
  }, [setValue, watchOrigin]);

  useEffect(() => {
    if (watchDestination?.value) {
      const addressType = getAddressType(watchDestination.value);

      setDestinationTerminalType(addressType);

      if (addressType === 'MARITIME') {
        setValue('earliestDeliveryTime', '00:00');
        setValue('latestDeliveryTime', '23:59');
      }

      if (addressType !== 'WAREHOUSE') {
        setIsContainerDropOffRequested(false);
      }
    }
  }, [setValue, watchDestination]);

  const closestCollectionDateString = useMemo(() => DateTime.now().plus({ days: 3 }).toFormat('yyyy-MM-dd'), []);
  const closestCollectionDate = new Date();

  closestCollectionDate.setDate(closestCollectionDate.getDate() + 4);

  const loadTerminalOptions = useCallback(() => loadSelectGeoOptionsTerminals(terminals), [terminals])();
  const loadDepotOptions = useCallback(() => loadSelectGeoOptionsDepots(depots), [depots])();

  useEffect(() => {
    if (watchCollectionDate && watchDeliveryDate) {
      trigger(['collectionDate', 'deliveryDate']);
    }
  }, [watchCollectionDate, watchDeliveryDate, trigger]);

  useEffect(() => {
    const dayOffset = +watchDaysOffset;

    if (dayOffset === 0 || !watchCollectionDate) {
      setCollectionDateHint('');

      return;
    }

    const from = DateTime.fromISO(watchCollectionDate).minus({ days: dayOffset }).toFormat('dd.MM.y');
    const to = DateTime.fromISO(watchCollectionDate).plus({ days: dayOffset }).toFormat('dd.MM.y');

    setCollectionDateHint(`${from} — ${to}`);
  }, [watchDaysOffset, watchCollectionDate]);

  return (
    <div className={styles.sectionGrid}>
      <div className={styles.section}>
        <SectionHeading title={t('section.origin')} />

        <div className={styles.fields}>
          <Controller
            name="originAddress"
            control={control}
            rules={{ required: commonT('validation.required') }}
            render={({ field, fieldState: { error } }) => (
              <EditableSelect
                testId="originInput"
                label={t('fields.address')}
                placeholder={t('fields.address')}
                Icon={getSelectIcon(watchOrigin)}
                iconClassName={styles.selectIcon}
                loadOptions={loadTerminalOptions}
                showRequired
                error={error?.message}
                {...field}
              />
            )}
          />

          <div className={styles.locationSubfields}>
            <div className={styles.row}>
              <Controller
                name="collectionDate"
                control={control}
                rules={{
                  required: commonT('validation.required'),
                  min: {
                    value: closestCollectionDateString,
                    message: t('fields.pickUpDateErrorMsg'),
                  },
                }}
                render={({ field: { ref, ...restField }, fieldState: { error } }) => (
                  <CustomDatePicker
                    {...restField}
                    showRequired
                    metaPosition="relative"
                    label={t('fields.collection')}
                    error={error?.message}
                    minDate={closestCollectionDate}
                    testId="collectionDate"
                    hint={collectionDateHint}
                  />
                )}
              />

              <Controller
                name="collectionDaysOffset"
                control={control}
                rules={{
                  required: true,
                  min: {
                    value: 0,
                    message: t('fields.daysErrorMsg'),
                  },
                }}
                render={({ field }) => (
                  <Input
                    testId="collectionDaysOffset"
                    label={t('fields.days')}
                    min={0}
                    type="number"
                    required
                    placeholder={t('fields.daysPlaceholder')}
                    style={{ width: 40 }}
                    tooltip={t('fields.daysTooltip')}
                    {...field}
                  />
                )}
              />
            </div>
            <div className={styles.row}>
              <Controller
                name="earliestCollectionTime"
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field: { ref, ...restField }, fieldState: { error } }) => (
                  <CustomDatePicker
                    {...restField}
                    showRequired
                    metaPosition="relative"
                    timeOnly={true}
                    label={t('fields.time')}
                    error={error?.message}
                    placeholder={commonT('from')}
                    testId="earliestCollectionTime"
                    readonly={originTerminalType === 'MARITIME'}
                  />
                )}
              />

              <Controller
                name="latestCollectionTime"
                control={control}
                rules={{
                  validate: (value) => {
                    const collectionTimeFrom = getValues('earliestCollectionTime');
                    const [fromHours] = collectionTimeFrom?.split(':');
                    const [toHours] = value?.split(':');
                    const isValid = Number(toHours) > Number(fromHours);

                    return isValid || t('fields.latestPickupTimeErrorMsg');
                  },
                }}
                render={({ field: { ref, ...restField }, fieldState: { error } }) => (
                  <CustomDatePicker
                    {...restField}
                    metaPosition="relative"
                    timeOnly={true}
                    label="&nbsp;"
                    error={error?.message}
                    placeholder={commonT('to')}
                    testId="latestCollectionTime"
                    readonly={originTerminalType === 'MARITIME'}
                  />
                )}
              />
            </div>
            {originTerminalType === 'WAREHOUSE' && (
              <div className={styles.row}>
                <Switch
                  className={styles.containerSwitch}
                  label={t('fields.requestContainerPickup')}
                  isActive={isContainerPickupRequest}
                  onClick={() => setIsContainerPickupRequested(!isContainerPickupRequest)}
                />
              </div>
            )}
            {isContainerPickupRequest && (
              <div className={styles.subRow}>
                <Controller
                  name="containerPickupAddress"
                  control={control}
                  rules={{ required: commonT('validation.required') }}
                  render={({ field, fieldState: { error } }) => (
                    <EditableSelect
                      showRequired
                      loadOptions={loadDepotOptions}
                      error={error?.message}
                      testId="containerPickupAddress"
                      label={t('fields.containerPickupAddress')}
                      placeholder={t('fields.address')}
                      {...field}
                    />
                  )}
                />
              </div>
            )}
          </div>
        </div>
      </div>

      <div className={styles.section}>
        <SectionHeading title={t('section.destination')} />

        <div className={styles.fields}>
          <Controller
            name="destinationAddress"
            control={control}
            rules={{
              required: commonT('validation.required'),
              validate: (value) => {
                if (watchOrigin?.label === value?.label) {
                  return t('fields.fieldsSameOriginDestinationError');
                }

                if (
                  isTerminal(watchOrigin?.value, TerminalType.MARITIME) &&
                  isTerminal(value?.value, TerminalType.MARITIME)
                ) {
                  return t('fields.fieldsBothSeaportTerminal');
                }

                return true;
              },
            }}
            render={({ field, fieldState: { error } }) => (
              <EditableSelect
                testId="destinationInput"
                showRequired
                label={t('fields.address')}
                loadOptions={loadTerminalOptions}
                error={error?.message}
                placeholder={t('fields.address')}
                Icon={getSelectIcon(watchDestination)}
                iconClassName={styles.selectIcon}
                {...field}
              />
            )}
          />

          <div className={styles.locationSubfields}>
            <div className={styles.row}>
              <Controller
                name="deliveryDate"
                control={control}
                rules={{
                  validate: (value) => {
                    const collectionDateValue = DateTime.fromISO(getValues('collectionDate'));
                    const latestDeliveryDate = DateTime.fromISO(value);

                    const isValid = latestDeliveryDate.startOf('day') >= collectionDateValue.startOf('day');

                    return isValid || t('fields.deliveryDateErrorMsg');
                  },
                }}
                render={({ field: { ref, ...restField }, fieldState: { error } }) => (
                  <CustomDatePicker
                    {...restField}
                    showRequired
                    metaPosition="relative"
                    label={t('fields.latestDelivery')}
                    error={error?.message}
                    minDate={watchCollectionDate ? new Date(String(watchCollectionDate)) : closestCollectionDate}
                    testId="deliveryDate"
                  />
                )}
              />
            </div>
            <div className={styles.row}>
              <Controller
                name="earliestDeliveryTime"
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field: { ref, ...restField }, fieldState: { error } }) => (
                  <CustomDatePicker
                    {...restField}
                    showRequired
                    metaPosition="relative"
                    timeOnly={true}
                    label={t('fields.deliveryTime')}
                    error={error?.message}
                    placeholder={commonT('from')}
                    testId="earliestDeliveryTime"
                    readonly={destinationTerminalType === 'MARITIME'}
                  />
                )}
              />

              <Controller
                name="latestDeliveryTime"
                control={control}
                rules={{
                  validate: (value) => {
                    const collectionTimeFrom = getValues('earliestDeliveryTime');
                    const [fromHours] = collectionTimeFrom?.split(':');
                    const [toHours] = value?.split(':');
                    const isValid = Number(toHours) > Number(fromHours);

                    return isValid || t('fields.latestPickupTimeErrorMsg');
                  },
                }}
                render={({ field: { ref, ...restField }, fieldState: { error } }) => (
                  <CustomDatePicker
                    {...restField}
                    metaPosition="relative"
                    timeOnly={true}
                    label="&nbsp;"
                    error={error?.message}
                    placeholder={commonT('to')}
                    testId="latestDeliveryTime"
                    readonly={destinationTerminalType === 'MARITIME'}
                  />
                )}
              />
            </div>
            {destinationTerminalType === 'WAREHOUSE' && (
              <div className={styles.row}>
                <Switch
                  className={styles.containerSwitch}
                  label={t('fields.requestContainerDropOff')}
                  isActive={isContainerDropOffRequest}
                  onClick={() => setIsContainerDropOffRequested(!isContainerDropOffRequest)}
                />
              </div>
            )}
            {isContainerDropOffRequest && (
              <div className={styles.row}>
                <Controller
                  name="containerDropOffAddress"
                  control={control}
                  rules={{ required: commonT('validation.required') }}
                  render={({ field, fieldState: { error } }) => (
                    <EditableSelect
                      showRequired
                      testId="containerDropOffAddress"
                      loadOptions={loadDepotOptions}
                      error={error?.message}
                      label={t('fields.containerDropOffAddress')}
                      placeholder={t('fields.address')}
                      {...field}
                    />
                  )}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
