import React from 'react';
import { Feature, Point } from 'geojson';
import midpoint from '@turf/midpoint';
import bezierSpline from '@turf/bezier-spline';
import { lineString, point } from '@turf/helpers';
import combine from '@turf/combine';
import * as ReactDOMServer from 'react-dom/server';

import clsx from 'clsx';
import distance from '@turf/distance';
import { Quote, Booking } from 'generated/api';
import { getAddressFromOnD } from 'utils/misc';
import { ReactComponent as MarkerBulletSvg } from 'assets/images/icons/marker.svg';

import styles from 'components/map/styles.module.scss';
import { MapBadge } from './mapBadge';

export type SGroupType = {
  origin: [number, number];
  destination: [number, number];
  bookings: Booking[];
  quoteEntity?: Quote | Booking;
};

export const DISTANCES = {
  XS: 400,
  M: 1200,
  L: 2000,
};

export const ROUTE_SIZES = {
  routeXs: 'route-xs',
  routeM: 'route-m',
  routeL: 'route-l',
  routeXl: 'route-xl',
};

export const getMidCurvedCoordinates = (start: Feature<Point>, end: Feature<Point>): [number, number] => {
  const midPoint = midpoint(start, end).geometry.coordinates;
  const startP = start.geometry.coordinates;
  const endP = end.geometry.coordinates;

  // https://github.com/prettier/prettier/issues/187
  // eslint-disable-next-line no-mixed-operators
  return [midPoint[0] + Math.abs(startP[0] - endP[0]) / 12, midPoint[1] + Math.abs(startP[1] - endP[1]) / 6];
};

export const generateFeatureCollection = (bookingGroups: SGroupType[]) => {
  const groupsXs: SGroupType[] = [];
  const groupsM: SGroupType[] = [];
  const groupsL: SGroupType[] = [];
  const groupsXl: SGroupType[] = [];

  bookingGroups.forEach((group) => {
    const distanceBetween = distance(point(group.origin), point(group.destination));

    if (distanceBetween >= 1200 && distanceBetween < 2000) {
      groupsL.push(group);
    } else if (distanceBetween >= 400 && distanceBetween < 1200) {
      groupsM.push(group);
    } else if (distanceBetween < 400) {
      groupsXs.push(group);
    } else {
      groupsXl.push(group);
    }
  });

  const getBazierLines = (groups: SGroupType[]) =>
    groups.map((group) =>
      bezierSpline(
        lineString([
          group.origin,
          getMidCurvedCoordinates(point(group.origin), point(group.destination)),
          group.destination,
        ]),
        { resolution: 1200 },
      ),
    );

  const getCombinedCollection = (groups: SGroupType[]) =>
    combine({
      type: 'FeatureCollection',
      features: getBazierLines(groups),
    }) as GeoJSON.FeatureCollection<GeoJSON.MultiLineString>;

  return [
    getCombinedCollection(groupsXs),
    getCombinedCollection(groupsM),
    getCombinedCollection(groupsL),
    getCombinedCollection(groupsXl),
    getCombinedCollection(bookingGroups),
  ];
};

export const convertBookingToGroup = (booking: Booking): SGroupType => {
  const rawQuote = booking;

  return {
    origin: getAddressFromOnD(rawQuote.origin).point as [number, number],
    destination: getAddressFromOnD(rawQuote.destination).point as [number, number],
    bookings: [booking],
  };
};

export const pointsAreEqual = (pointA: [number, number], pointB: [number, number]) =>
  pointA[0] === pointB[0] && pointA[1] === pointB[1];

export const getGroupMarker = (group: SGroupType) => {
  const marker = document.createElement('div');

  marker.classList.add(styles.groupMarker);

  if (group.bookings.length) {
    marker.innerHTML = ReactDOMServer.renderToStaticMarkup(
      <MapBadge bookings={group.bookings} testid="CoordinatedBadge" />,
    );
  }

  return marker;
};

export const getBulletMarker = (className?: string) => {
  const marker = document.createElement('div');

  if (className) {
    marker.classList.add(className);
  }

  marker.innerHTML = ReactDOMServer.renderToStaticMarkup(<MarkerBulletSvg className={clsx(styles.markerBullet)} />);

  return marker;
};
