import { useEffect, useState } from 'react';
import Lottie from 'lottie-react';
import clsx from 'clsx';
import truckAnimation from 'assets/lottie/Truck.json';
import trainAnimation from 'assets/lottie/Train.json';
import bargeAnimation from 'assets/lottie/Barge.json';
import styles from './styles.module.scss';

const lottieAnimations = [truckAnimation, trainAnimation, bargeAnimation];

export const ModalityLoader = () => {
  const [frame, setFrame] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      const frameId = frame === lottieAnimations.length - 1 ? 0 : frame + 1;

      setFrame(frameId);
    }, 1800);

    return () => clearInterval(interval);
  });

  return (
    <div className={styles.modalityLoaderRoot}>
      <div className={styles.spinnerRoot}>
        <div className={styles.spinner} />

        {lottieAnimations.map((animation, index) => (
          <div className={clsx(styles.animation, { [styles.hidden]: index !== frame })} key={index}>
            <Lottie animationData={animation} />
          </div>
        ))}
      </div>
    </div>
  );
};
