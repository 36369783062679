import React from 'react';
import { useTranslation } from 'react-i18next';
import { Container2Icon, GreenIcon, VolumeIcon } from '@rouvia/icons';

import { DashboardStats } from 'generated/api';
import { MetricWidget } from 'components/metricWidget';
import { formatNumber, getKgToTonString } from 'utils/misc';
import { NOT_FOUND } from './constants';
import styles from './styles.module.scss';

type TProps = {
  isLoading?: boolean;
  stats?: DashboardStats;
};

export const SummaryWidget: React.FC<TProps> = ({ stats, isLoading }) => {
  const { t } = useTranslation('default', {
    keyPrefix: 'components/summaryWidget',
  });

  return (
    <div className={styles.container}>
      <MetricWidget
        testId="MetricWidgetTotalBookings"
        isLoading={isLoading}
        value={stats ? formatNumber(stats.totalBookings) : NOT_FOUND}
        description={t('totalBooking')}
        icon={<Container2Icon />}
      />
      <MetricWidget
        testId="MetricWidgetTotalEmissions"
        isLoading={isLoading}
        value={stats ? getKgToTonString(stats.emissions) : NOT_FOUND}
        description={t('emission')}
        icon={<GreenIcon style={{ color: '#088d39' }} />}
        highlightValue
      />
      <MetricWidget
        testId="MetricWidgetTotalWeight"
        isLoading={isLoading}
        value={stats ? getKgToTonString(stats.weight) : NOT_FOUND}
        description={t('volume')}
        icon={<VolumeIcon />}
      />
    </div>
  );
};
