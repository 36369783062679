import { useMemo } from 'react';
import { useAuth } from 'react-oidc-context';
import type { UserProfile, User as OidcUser } from 'oidc-client-ts';
import { Language, Organisation, User, UserRole } from 'generated/api';
import { isAdminUser } from 'utils/misc';

export interface ICustomToken extends UserProfile {
  realm_access: {
    roles: UserRole[];
  };
  organisation: Organisation;
  avatar?: string;
  languagePreference?: Language;
  timezone?: string;
  enabled?: boolean;
}

export const extractUser = (token: ICustomToken): User => ({
  id: token?.sub || '0',
  email: token?.email || '',
  firstName: token?.given_name,
  lastName: token?.family_name,
  avatar: token?.avatar || '',
  organisation: token?.organisation,
  roles: token?.realm_access?.roles || [],
  isVerified: token?.email_verified || false,
  languagePreference: token?.languagePreference || Language.EN,
  timezone: token?.timezone || '',
  enabled: token?.enabled || true,
});

interface IRoles {
  isAdmin: boolean;
}

export const useGetUser = (): [User, IRoles] => {
  const { user } = useAuth();

  return useMemo(() => {
    const extractedUser = extractUser((user as OidcUser).profile as ICustomToken);
    const isAdmin = isAdminUser(extractedUser);

    return [extractedUser, { isAdmin }];
  }, [user]);
};
