import React from 'react';
import { useTranslation } from 'react-i18next';
import { PlusIcon } from '@rouvia/icons';
import { DATE_FORMAT, formatDate } from 'utils/date';
import styles from './styles.module.scss';

export enum ACTION_TYPE {
  ADD = 'ADD',
}

const IconsMap: { [key in keyof typeof ACTION_TYPE]: JSX.Element } = {
  ADD: <PlusIcon data-testid="icon-add" />,
};

export type TProps = {
  actions: {
    title: string;
    dueDate?: string;
    action?: () => void;
    actionType?: ACTION_TYPE;
    completed?: boolean;
  }[];
};

export const ActionsRequired: React.FC<TProps> = ({ actions }) => {
  const { t } = useTranslation('default', {
    keyPrefix: 'components/actionsRequired',
  });

  return (
    <div className={styles.root}>
      <div className={styles.header}>{t('actionsRequired')}</div>
      <div className={styles.body}>
        {actions.map((actionItem, index) => (
          <div className={styles.row} key={index}>
            {actionItem.title}
            <div className={styles.lastColumn}>
              {actionItem.dueDate && `Due: ${formatDate(actionItem.dueDate, DATE_FORMAT.MONTH_FULL_YEAR)}`}
              <div className={styles.cta}>
                <div className={styles.cell} onClick={actionItem.action} data-testid="icon">
                  {IconsMap[actionItem.actionType || ACTION_TYPE.ADD]}
                  {t(`actionsTypes.${actionItem.actionType || ACTION_TYPE.ADD}`)}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
