import React from 'react';
import { useTranslation } from 'react-i18next';
import { Layout } from 'components/layout';

const NotFoundPage = () => {
  const { t } = useTranslation('default', { keyPrefix: 'pages/404' });

  return (
    <Layout title={t('title')}>
      <h1>{t('header')}</h1>
      {t('description')}
    </Layout>
  );
};

export default NotFoundPage;
