import { isExperimentalEnv } from 'constants/config';
import { TDefaultSelectOption } from 'types/common';
import { EquipmentType, TypeOfPackaging } from 'generated/api';

export const defaultEquipmentList: TDefaultSelectOption<string>[] = [
  { value: EquipmentType.CONTAINER, label: 'Container' },
];

export const extendedEquipmentList: TDefaultSelectOption<string>[] = [
  ...defaultEquipmentList,
  { value: EquipmentType.SEMITRAILER, label: 'Semi-Trailer' },
  { value: EquipmentType.SWAPBODY, label: 'Swap Body' },
];

export const equipment: TDefaultSelectOption<string>[] = isExperimentalEnv
  ? extendedEquipmentList
  : defaultEquipmentList;

// 13.60m
export const semiTrailers = [
  { value: 'semitrailer', label: 'Semi-Trailer' },
  { value: 'megatrailer', label: 'Mega-Trailer' },
  { value: 'tanktrailer', label: 'Tank-Trailer' },
  { value: 'reefertrailer', label: 'Reefer-Trailer' },
  { value: 'silotrailer', label: 'Silo-Trailer' },
];

export const loadingUnitsObject: { [key: string]: string } = {
  SEMITRAILER: 'Semi-Trailer',
  SWAPBODY: 'Swap Body',
  CONTAINER: 'Container',
};

// Swap Body lengths
// Class C with lengths of 7.15m, 7.45m or 7.82m (standard EN 284)
// Class A with lengths of 12.50m or 13.60m (standard EN 452)
export const swapBodies = [
  { value: 'swapbody', label: 'Swap Body' },
  { value: 'swapbodybulker', label: 'Swap Body Bulker' },
  { value: 'swapbodyflatrack', label: 'Swap Body Flat-Rack' },
  { value: 'swapbodyfixedflatrack', label: 'Swap Body Fixed End Flat Rack' },
  { value: 'swapbodyopentop', label: 'Swap Body Open Top' },
  { value: 'swapbodysilo', label: 'Swap Body Silo' },
];

export const sizes = [
  { value: '10sa', label: "10' (Swap Body) / 13.6m" },
  { value: '10mt', label: "10' (Megatrailer)" },
  { value: '20', label: "20' / 6.05m" },
  { value: '21', label: "21' / 6.25m" },
  { value: '22', label: "22' / 7.15m" },
  { value: '23', label: "23' / 7.45m" },
  { value: '24', label: "24' / 7.82m" },
  { value: '26', label: "26' / 8.15m" },
  { value: '30', label: "30' / 9.12m" },
  { value: '31', label: "31' / 9.3m" },
  { value: '36', label: "36' / 10.9m" },
  { value: '40', label: "40' / 12.19m" },
  { value: '42', label: "42' / 12.5m" },
  { value: '44', label: "44' / 13.1m" },
  { value: '45', label: "45' / 13.72m" },
  { value: '81', label: "81' / 9.275m" },
  { value: '82', label: "82' / 9.33m" },
  { value: '84', label: "84' / 10.04m" },
  { value: '85', label: "85' / 10.2m" },
  { value: '86', label: "86' / 10.9m" },
  { value: '91', label: "91' / 12.5m" },
  { value: '94', label: "94' / 12.75m" },
  { value: '96', label: "96' / 13.6m" },
  { value: '97', label: "97' / 14.4m" },
];

export const containerSizes = [
  { value: '20', label: "20' / 6.05m" },
  { value: '40', label: "40' / 12.19m" },
];

interface IContainerUnits {
  [key: string]: TDefaultSelectOption<string>[];
}

export const containerUnitsFull: IContainerUnits = {
  '20': [
    {
      value: '22U0',
      label: "22U0 - 8,6' - OT (Open-top Container)",
    },
    {
      value: '25U0',
      label: "25U0 - 9,6' - OT (Open-top Container)",
    },
    {
      value: '22G1',
      label: "22G1 - 8,6' - DC (Standard Container)",
    },
    {
      value: '22T1',
      label: "22T1 - 8,6' - TK (Standard Tank Container)",
    },
    {
      value: '22R1',
      label: "22R1 - 8,6' - RF (Reefer UNCOOLED)",
    },
    {
      value: '25R1',
      label: "25R1 - 9,6' - RF (Reefer High Cube UNCOOLED)",
    },
    {
      value: '25G1',
      label: "25G1 - 9,6' - HC (High Cube Container)",
    },
    {
      value: '25T1',
      label: "25T1 - 9,6' - TK (High Cube Tank Container)",
    },
    {
      value: '2CG0',
      label: "2CG0 - 8,6' - GP (Pallet wide)",
    },
    {
      value: '2CG1',
      label: "2CG1 - 8,6' - GP (Pallet wide)",
    },
    {
      value: '2CG2',
      label: "2CG2 - 8,6' - GP (Pallet wide)",
    },
    {
      value: '2CG3',
      label: "2CG3 - 8,6' - GP (Pallet wide)",
    },
    {
      value: '2CGP',
      label: "2CGP - 8,6' - GP (Pallet wide)",
    },
  ],
  '40': [
    {
      value: '42U0',
      label: "42U0 - 8,6' - OT (Open-top Container)",
    },
    {
      value: '45U0',
      label: "45U0 - 9,6' - OT (Open-top Container)",
    },
    {
      value: '45G1',
      label: "45G1 - 9,6' - HC (High Cube Container)",
    },
    {
      value: '42G1',
      label: "42G1 - 8,6' - DC (Standard Container)",
    },
    {
      value: '45R1',
      label: "45R1 - 9,6' - RF (Reefer High Cube UNCOOLED)",
    },
    {
      value: '42T1',
      label: "42T1 - 8,6' - RF (Reefer UNCOOLED)",
    },
    {
      value: '42R1',
      label: "42R1 - 8,6' - RF (Reefer UNCOOLED)",
    },
    {
      value: '4NT0',
      label: "4NT0 - 9,6' - TK (High Cube Tank Container)",
    },
    {
      value: '4CG0',
      label: "4CG0 - 8,6' - GP (Pallet wide)",
    },
    {
      value: '4CG1',
      label: "4CG1 - 8,6' - GP (Pallet wide)",
    },
    {
      value: '4CG2',
      label: "4CG2 - 8,6' - GP (Pallet wide)",
    },
    {
      value: '4CG3',
      label: "4CG3 - 8,6' - GP (Pallet wide)",
    },
    {
      value: '4CGP',
      label: "4CGP - 8,6' - GP (Pallet wide)",
    },
    {
      value: '4EG0',
      label: "4EG0 - 9,6' - GP (Pallet wide)",
    },
    {
      value: '4EG1',
      label: "4EG1 - 9,6' - GP (Pallet wide)",
    },
    {
      value: '4EG2',
      label: "4EG2 - 9,6' - GP (Pallet wide)",
    },
    {
      value: '4EG3',
      label: "4EG3 - 9,6' - GP (Pallet wide)",
    },
    {
      value: '4EGP',
      label: "4EGP - 9,6' - GP (Pallet wide)",
    },
  ],
};
export const containerUnitsProd: IContainerUnits = {
  '20': [
    {
      value: '22G1',
      label: "22G1 - 8,6' - DC (Standard Container)",
    },
    {
      value: '25G1',
      label: "25G1 - 9,6' - HC (High Cube Standard Container)",
    },
    {
      value: '22R1',
      label: "22R1 - 8,6' - RF (Reefer Container)",
    },
    {
      value: '25R1',
      label: "25R1 - 9,6' - RF (High Cube Reefer Container)",
    },
  ],
  '40': [
    {
      value: '45G1',
      label: "45G1 - 9,6' - HC (High Cube Standard Container)",
    },
    {
      value: '42G1',
      label: "42G1 - 8,6' - DC (Standard Container)",
    },
    {
      value: '45R1',
      label: "45R1 - 9,6' - RF (High Cube Reefer Container)",
    },
    {
      value: '42R1',
      label: "42R1 - 8,6' - RF (Reefer Container)",
    },
  ],
};

export const containerUnits: IContainerUnits = isExperimentalEnv ? containerUnitsFull : containerUnitsProd;

export const sizesObject: { [key: string]: string } = {
  '10sa': "10' (Swap Body) / 13.6m",
  '10mt': "10' (Megatrailer)",
  20: "20' / 6.05m",
  21: "21' / 6.25m",
  22: "22' / 7.15m",
  23: "23' / 7.45m",
  24: "24' / 7.82m",
  26: "26' / 8.15m",
  30: "30' / 9.12m",
  31: "31' / 9.3m",
  36: "36' / 10.9m",
  40: "40' / 12.19m",
  42: "42' / 12.5m",
  44: "44' / 13.1m",
  45: "45' / 13.72m",
  81: "81' / 9.275m",
  82: "82' / 9.33m",
  84: "84' / 10.04m",
  85: "85' / 10.2m",
  86: "86' / 10.9m",
  91: "91' / 12.5m",
  94: "94' / 12.75m",
  96: "96' / 13.6m",
  97: "97' / 14.4m",
};

export const groupedLoadingUnits: {
  [key: string]: TDefaultSelectOption<string>[];
} = {
  SEMITRAILER: semiTrailers,
  SWAPBODY: swapBodies,
};

export const unitProfileTable: { [key: string]: TDefaultSelectOption<string>[] } = {
  SEMITRAILER: [
    // 2.50m
    { label: 'P00', value: 'P00' },
    { label: 'P01', value: 'P01' },
    { label: 'P02', value: 'P02' },
    { label: 'P03', value: 'P03' },
    { label: 'P04', value: 'P04' },
    { label: 'P05', value: 'P05' },
    { label: 'P06', value: 'P06' },
    { label: 'P07', value: 'P07' },
    { label: 'P08', value: 'P08' },
    { label: 'P09', value: 'P09' },
    { label: 'P10', value: 'P10' },
    { label: 'P11', value: 'P11' },
    { label: 'P12', value: 'P12' },
    { label: 'P13', value: 'P13' },
    { label: 'P14', value: 'P14' },
    { label: 'P15', value: 'P15' },
    { label: 'P16', value: 'P16' },
    { label: 'P17', value: 'P17' },
    { label: 'P18', value: 'P18' },
    { label: 'P19', value: 'P19' },
    { label: 'P20', value: 'P20' },
    { label: 'P21', value: 'P21' },
    { label: 'P22', value: 'P22' },
    { label: 'P23', value: 'P23' },
    { label: 'P24', value: 'P24' },
    { label: 'P25', value: 'P25' },
    { label: 'P26', value: 'P26' },
    { label: 'P27', value: 'P27' },
    { label: 'P28', value: 'P28' },
    { label: 'P29', value: 'P29' },
    { label: 'P30', value: 'P30' },
    { label: 'P31', value: 'P31' },
    { label: 'P32', value: 'P32' },
    { label: 'P33', value: 'P33' },
    { label: 'P34', value: 'P34' },
    { label: 'P35', value: 'P35' },
    { label: 'P36', value: 'P36' },
    { label: 'P37', value: 'P37' },
    { label: 'P38', value: 'P38' },
    { label: 'P39', value: 'P39' },
    { label: 'P40', value: 'P40' },
    { label: 'P41', value: 'P41' },
    { label: 'P42', value: 'P42' },
    { label: 'P43', value: 'P43' },
    { label: 'P44', value: 'P44' },
    { label: 'P45', value: 'P45' },
    { label: 'P46', value: 'P46' },
    { label: 'P47', value: 'P47' },
    { label: 'P48', value: 'P48' },
    { label: 'P49', value: 'P49' },
    { label: 'P50', value: 'P50' },
    { label: 'P51', value: 'P51' },
    { label: 'P52', value: 'P52' },
    { label: 'P53', value: 'P53' },
    { label: 'P54', value: 'P54' },
    { label: 'P55', value: 'P55' },
    { label: 'P56', value: 'P56' },
    { label: 'P57', value: 'P57' },
    { label: 'P58', value: 'P58' },
    { label: 'P59', value: 'P59' },
    { label: 'P60', value: 'P60' },
    { label: 'P61', value: 'P61' },
    { label: 'P62', value: 'P62' },
    { label: 'P63', value: 'P63' },
    { label: 'P64', value: 'P64' },
    { label: 'P65', value: 'P65' },
    { label: 'P66', value: 'P66' },
    { label: 'P67', value: 'P67' },
    { label: 'P68', value: 'P68' },
    { label: 'P69', value: 'P69' },
    { label: 'P70', value: 'P70' },
    { label: 'P71', value: 'P71' },
    { label: 'P72', value: 'P72' },
    { label: 'P73', value: 'P73' },
    { label: 'P74', value: 'P74' },
    { label: 'P75', value: 'P75' },
    { label: 'P76', value: 'P76' },
    { label: 'P77', value: 'P77' },
    { label: 'P78', value: 'P78' },
    { label: 'P79', value: 'P79' },
    { label: 'P80', value: 'P80' },
    // 2.55m, 2.60m, 2.65m
    { label: 'P330', value: 'P330' },
    { label: 'P331', value: 'P331' },
    { label: 'P332', value: 'P332' },
    { label: 'P333', value: 'P333' },
    { label: 'P334', value: 'P334' },
    { label: 'P335', value: 'P335' },
    { label: 'P336', value: 'P336' },
    { label: 'P337', value: 'P337' },
    { label: 'P338', value: 'P338' },
    { label: 'P339', value: 'P339' },
    { label: 'P340', value: 'P340' },
    { label: 'P341', value: 'P341' },
    { label: 'P342', value: 'P342' },
    { label: 'P343', value: 'P343' },
    { label: 'P344', value: 'P344' },
    { label: 'P345', value: 'P345' },
    { label: 'P346', value: 'P346' },
    { label: 'P347', value: 'P347' },
    { label: 'P348', value: 'P348' },
    { label: 'P349', value: 'P349' },
    { label: 'P350', value: 'P350' },
    { label: 'P351', value: 'P351' },
    { label: 'P352', value: 'P352' },
    { label: 'P353', value: 'P353' },
    { label: 'P354', value: 'P354' },
    { label: 'P355', value: 'P355' },
    { label: 'P356', value: 'P356' },
    { label: 'P357', value: 'P357' },
    { label: 'P358', value: 'P358' },
    { label: 'P359', value: 'P359' },
    { label: 'P360', value: 'P360' },
    { label: 'P361', value: 'P361' },
    { label: 'P362', value: 'P362' },
    { label: 'P363', value: 'P363' },
    { label: 'P364', value: 'P364' },
    { label: 'P365', value: 'P365' },
    { label: 'P366', value: 'P366' },
    { label: 'P367', value: 'P367' },
    { label: 'P368', value: 'P368' },
    { label: 'P369', value: 'P369' },
    { label: 'P370', value: 'P370' },
    { label: 'P371', value: 'P371' },
    { label: 'P372', value: 'P372' },
    { label: 'P373', value: 'P373' },
    { label: 'P374', value: 'P374' },
    { label: 'P375', value: 'P375' },
    { label: 'P376', value: 'P376' },
    { label: 'P377', value: 'P377' },
    { label: 'P378', value: 'P378' },
    { label: 'P379', value: 'P379' },
    { label: 'P380', value: 'P380' },
    { label: 'P381', value: 'P381' },
    { label: 'P382', value: 'P382' },
    { label: 'P383', value: 'P383' },
    { label: 'P384', value: 'P384' },
    { label: 'P385', value: 'P385' },
    { label: 'P386', value: 'P386' },
    { label: 'P387', value: 'P387' },
    { label: 'P388', value: 'P388' },
    { label: 'P389', value: 'P389' },
    { label: 'P390', value: 'P390' },
    { label: 'P391', value: 'P391' },
    { label: 'P392', value: 'P392' },
    { label: 'P393', value: 'P393' },
    { label: 'P394', value: 'P394' },
    { label: 'P395', value: 'P395' },
    { label: 'P396', value: 'P396' },
    { label: 'P397', value: 'P397' },
    { label: 'P398', value: 'P398' },
    { label: 'P399', value: 'P399' },
    { label: 'P400', value: 'P400' },
    { label: 'P401', value: 'P401' },
    { label: 'P402', value: 'P402' },
    { label: 'P403', value: 'P403' },
    { label: 'P404', value: 'P404' },
    { label: 'P405', value: 'P405' },
    { label: 'P406', value: 'P406' },
    { label: 'P407', value: 'P407' },
    { label: 'P408', value: 'P408' },
    { label: 'P409', value: 'P409' },
    { label: 'P410', value: 'P410' },
  ],
  SWAPBODY: [
    // 2.50m, 2.55m
    { label: 'С00', value: 'С00' },
    { label: 'С01', value: 'С01' },
    { label: 'С02', value: 'С02' },
    { label: 'С03', value: 'С03' },
    { label: 'С04', value: 'С04' },
    { label: 'С05', value: 'С05' },
    { label: 'С06', value: 'С06' },
    { label: 'С07', value: 'С07' },
    { label: 'С08', value: 'С08' },
    { label: 'С09', value: 'С09' },
    { label: 'С10', value: 'С10' },
    { label: 'С11', value: 'С11' },
    { label: 'С12', value: 'С12' },
    { label: 'С13', value: 'С13' },
    { label: 'С14', value: 'С14' },
    { label: 'С15', value: 'С15' },
    { label: 'С16', value: 'С16' },
    { label: 'С17', value: 'С17' },
    { label: 'С18', value: 'С18' },
    { label: 'С19', value: 'С19' },
    { label: 'С20', value: 'С20' },
    { label: 'С21', value: 'С21' },
    { label: 'С22', value: 'С22' },
    { label: 'С23', value: 'С23' },
    { label: 'С24', value: 'С24' },
    { label: 'С25', value: 'С25' },
    { label: 'С26', value: 'С26' },
    { label: 'С27', value: 'С27' },
    { label: 'С28', value: 'С28' },
    { label: 'С29', value: 'С29' },
    { label: 'С30', value: 'С30' },
    { label: 'С31', value: 'С31' },
    { label: 'С32', value: 'С32' },
    { label: 'С33', value: 'С33' },
    { label: 'С34', value: 'С34' },
    { label: 'С35', value: 'С35' },
    { label: 'С36', value: 'С36' },
    { label: 'С37', value: 'С37' },
    { label: 'С38', value: 'С38' },
    { label: 'С39', value: 'С39' },
    { label: 'С40', value: 'С40' },
    { label: 'С41', value: 'С41' },
    { label: 'С42', value: 'С42' },
    { label: 'С43', value: 'С43' },
    { label: 'С44', value: 'С44' },
    { label: 'С45', value: 'С45' },
    { label: 'С46', value: 'С46' },
    { label: 'С47', value: 'С47' },
    { label: 'С48', value: 'С48' },
    { label: 'С49', value: 'С49' },
    { label: 'С50', value: 'С50' },
    { label: 'С51', value: 'С51' },
    { label: 'С52', value: 'С52' },
    { label: 'С53', value: 'С53' },
    { label: 'С54', value: 'С54' },
    { label: 'С55', value: 'С55' },
    { label: 'С56', value: 'С56' },
    { label: 'С57', value: 'С57' },
    { label: 'С58', value: 'С58' },
    { label: 'С59', value: 'С59' },
    { label: 'С60', value: 'С60' },
    { label: 'С61', value: 'С61' },
    { label: 'С62', value: 'С62' },
    { label: 'С63', value: 'С63' },
    { label: 'С64', value: 'С64' },
    { label: 'С65', value: 'С65' },
    { label: 'С66', value: 'С66' },
    { label: 'С67', value: 'С67' },
    { label: 'С68', value: 'С68' },
    { label: 'С69', value: 'С69' },
    { label: 'С70', value: 'С70' },
    { label: 'С71', value: 'С71' },
    { label: 'С72', value: 'С72' },
    { label: 'С73', value: 'С73' },
    { label: 'С74', value: 'С74' },
    { label: 'С75', value: 'С75' },
    { label: 'С76', value: 'С76' },
    { label: 'С77', value: 'С77' },
    { label: 'С78', value: 'С78' },
    { label: 'С79', value: 'С79' },
    { label: 'С80', value: 'С80' },
    // 2.50m
    { label: 'C330', value: 'C330' },
    { label: 'C331', value: 'C331' },
    { label: 'C332', value: 'C332' },
    { label: 'C333', value: 'C333' },
    { label: 'C334', value: 'C334' },
    { label: 'C335', value: 'C335' },
    { label: 'C336', value: 'C336' },
    { label: 'C337', value: 'C337' },
    { label: 'C338', value: 'C338' },
    { label: 'C339', value: 'C339' },
    { label: 'C340', value: 'C340' },
    { label: 'C341', value: 'C341' },
    { label: 'C342', value: 'C342' },
    { label: 'C343', value: 'C343' },
    { label: 'C344', value: 'C344' },
    { label: 'C345', value: 'C345' },
    { label: 'C346', value: 'C346' },
    { label: 'C347', value: 'C347' },
    { label: 'C348', value: 'C348' },
    { label: 'C349', value: 'C349' },
    { label: 'C350', value: 'C350' },
    { label: 'C351', value: 'C351' },
    { label: 'C352', value: 'C352' },
    { label: 'C353', value: 'C353' },
    { label: 'C354', value: 'C354' },
    { label: 'C355', value: 'C355' },
    { label: 'C356', value: 'C356' },
    { label: 'C357', value: 'C357' },
    { label: 'C358', value: 'C358' },
    { label: 'C359', value: 'C359' },
    { label: 'C360', value: 'C360' },
    { label: 'C361', value: 'C361' },
    { label: 'C362', value: 'C362' },
    { label: 'C363', value: 'C363' },
    { label: 'C364', value: 'C364' },
    { label: 'C365', value: 'C365' },
    { label: 'C366', value: 'C366' },
    { label: 'C367', value: 'C367' },
    { label: 'C368', value: 'C368' },
    { label: 'C369', value: 'C369' },
    { label: 'C370', value: 'C370' },
    { label: 'C371', value: 'C371' },
    { label: 'C372', value: 'C372' },
    { label: 'C373', value: 'C373' },
    { label: 'C374', value: 'C374' },
    { label: 'C375', value: 'C375' },
    { label: 'C376', value: 'C376' },
    { label: 'C377', value: 'C377' },
    { label: 'C378', value: 'C378' },
    { label: 'C379', value: 'C379' },
    { label: 'C380', value: 'C380' },
    { label: 'C381', value: 'C381' },
    { label: 'C382', value: 'C382' },
    { label: 'C383', value: 'C383' },
    { label: 'C384', value: 'C384' },
    { label: 'C385', value: 'C385' },
    { label: 'C386', value: 'C386' },
    { label: 'C387', value: 'C387' },
    { label: 'C388', value: 'C388' },
    { label: 'C389', value: 'C389' },
    { label: 'C390', value: 'C390' },
    { label: 'C391', value: 'C391' },
    { label: 'C392', value: 'C392' },
    { label: 'C393', value: 'C393' },
    { label: 'C394', value: 'C394' },
    { label: 'C395', value: 'C395' },
    { label: 'C396', value: 'C396' },
    { label: 'C397', value: 'C397' },
    { label: 'C398', value: 'C398' },
    { label: 'C399', value: 'C399' },
    { label: 'C400', value: 'C400' },
    { label: 'C401', value: 'C401' },
    { label: 'C402', value: 'C402' },
    { label: 'C403', value: 'C403' },
    { label: 'C404', value: 'C404' },
    { label: 'C405', value: 'C405' },
    { label: 'C406', value: 'C406' },
    { label: 'C407', value: 'C407' },
    { label: 'C408', value: 'C408' },
    { label: 'C409', value: 'C409' },
    { label: 'C410', value: 'C410' },
  ],
};

export const typesOfPackagingLabels: { [key in TypeOfPackaging]: string } = {
  [TypeOfPackaging.BA]: 'Fass ("Barrel")',
  [TypeOfPackaging.BL]: 'Ballen, gepresst',
  [TypeOfPackaging.BN]: 'Ballen, nicht gepresst',
  [TypeOfPackaging.BX]: 'Kasten',
  [TypeOfPackaging.CI]: 'Kanister',
  [TypeOfPackaging.CS]: 'Kiste ("Case")',
  [TypeOfPackaging.DR]: 'Trommel, Fass',
  [TypeOfPackaging.PC]: 'Paket',
  [TypeOfPackaging.PX]: 'Palette',
  [TypeOfPackaging.SA]: 'Sack',
  [TypeOfPackaging.SK]: 'Verschlag',
  [TypeOfPackaging.TY]: 'Tank, zylindrisch',
  [TypeOfPackaging.BE]: 'Bündel ("Bundle")',
  [TypeOfPackaging.BG]: 'Beutel, Tüte',
  [TypeOfPackaging.BH]: 'Bund',
  [TypeOfPackaging.PK]: 'Packung/Packstück',
  [TypeOfPackaging.PO]: 'Beutel, Tasche',
  [TypeOfPackaging.PY]: 'Platten, im Bündel/Bund',
  [TypeOfPackaging.RL]: 'Haspel, Spule',
  [TypeOfPackaging.SL]: 'Kufenbrett',
  [TypeOfPackaging.VO]: 'Massengut, fest, große Teilchen ("Knollen")',
  [TypeOfPackaging.CR]: 'Lattenkiste',
  [TypeOfPackaging.CT]: 'Karton',
  [TypeOfPackaging.EC]: 'Beutel, Kunststoff',
  [TypeOfPackaging.JC]: 'Kanister, rechteckig',
  [TypeOfPackaging.KG]: 'Fass ("Keg")',
  [TypeOfPackaging.NE]: 'Nicht verpackt oder nicht abgepackt',
  [TypeOfPackaging.PA]: 'Päckchen',
  [TypeOfPackaging.PP]: 'Stück',
};

export const typesOfPackaging: TDefaultSelectOption<TypeOfPackaging>[] = (
  Object.keys(typesOfPackagingLabels) as TypeOfPackaging[]
).map((key) => ({
  label: typesOfPackagingLabels[key],
  value: key,
}));

export const loadingUnits = [...semiTrailers, ...swapBodies, ...containerUnits['20'], ...containerUnits['40']];

export const isValidContainerNumber = (input: string): boolean => /^[A-Za-z]{4}[0-9]{7}$/.test(input);
