import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@tanstack/react-query';

import { BinSimpleIcon, DownloadIcon, UploadIcon } from '@rouvia/icons';
import ToastContext from 'components/toast';
import { queryClient } from 'constants/api';
import { bookingQueryKey } from 'api/bookings';
import { deleteDocument, downloadDocument, reuploadDocument, uploadDocument } from 'api/documents';
import { Button } from 'components/button';
import { ContentBox } from 'components/contentBox';
import { Modal } from 'components/modal';
import { ApiError, Document, DocumentType, UploadFile } from 'generated/api';
import { TDocumentUploadRequest } from 'types/common';
import { documentTypes } from 'constants/documents';

import { LoaderBackdrop } from '../loaderBackdrop';
import styles from './styles.module.scss';
import { UploadForm } from './uploadForm';

const FORM_DATA_DEFAULT = {
  documentType: DocumentType.OTHER,
  body: {},
};

type TProps = {
  documents: Document[];
  bookingId: string;
};

export const OrderDocuments: React.FC<TProps> = ({ bookingId, documents }) => {
  const { t } = useTranslation('default', {
    keyPrefix: 'components/orderDocuments',
  });
  const { t: docsT } = useTranslation('default', {
    keyPrefix: 'components/openTasksWidget',
  });
  const [isUploadModalActive, setUploadModalActive] = useState<boolean>(false);
  const [isReuploadModalActive, setReuploadModalActive] = useState<boolean>(false);
  const [uploadFormData, setUploadFormData] = useState<TDocumentUploadRequest>(FORM_DATA_DEFAULT);
  const [reuploadFormData, setReuploadFormData] = useState<TDocumentUploadRequest>(FORM_DATA_DEFAULT);
  const [selectedReuploadDocument, setSelectedReuploadDocument] = useState<Document>();
  const { playSuccessToast, playErrorToast } = useContext(ToastContext);

  const preselectedDocument = useMemo(
    () => documentTypes.find((d) => selectedReuploadDocument && d.value === selectedReuploadDocument.type),
    [selectedReuploadDocument],
  );

  const { mutate: reupload, isLoading: isReuploadLoading } = useMutation<
    Document,
    ApiError,
    { documentId: string; file: UploadFile }
  >(({ documentId, file }) => reuploadDocument(bookingId, documentId, file), {
    onSuccess: () => {
      setReuploadFormData(FORM_DATA_DEFAULT);
      queryClient.invalidateQueries([bookingQueryKey, bookingId.toString()]).then(() => {
        setReuploadModalActive(false);
      });

      playSuccessToast();
    },
    onError: () => {
      playErrorToast();
    },
  });

  const { mutate: upload, isLoading: isUploadLoading } = useMutation<
    Document,
    ApiError,
    { documentType: DocumentType; file: UploadFile }
  >(({ documentType, file }) => uploadDocument(bookingId, documentType, file), {
    onSuccess: () => {
      setUploadFormData(FORM_DATA_DEFAULT);
      queryClient.invalidateQueries([bookingQueryKey, bookingId.toString()]).then(() => {
        setUploadModalActive(false);
      });

      playSuccessToast();
    },
    onError: () => {
      playErrorToast();
    },
  });

  const { mutate: deleteMutation, isLoading: isDeleteLoading } = useMutation(
    (documentId: string) => deleteDocument(bookingId, documentId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([bookingQueryKey, bookingId.toString()]);
        playSuccessToast();
      },
      onError: () => {
        playErrorToast();
      },
    },
  );

  const uploadFile = (data: TDocumentUploadRequest) => {
    setUploadFormData(data);
  };

  const reuploadFile = (data: TDocumentUploadRequest) => {
    setReuploadFormData(data);
  };

  const handleDocumentReupload = (document: Document) => {
    setSelectedReuploadDocument(document);
    setReuploadModalActive(true);
  };

  const handleDocumentDownload = async (documentId: string, documentName: string) => {
    try {
      const isSuccess = await downloadDocument(bookingId, documentId, documentName);

      if (!isSuccess) {
        playErrorToast();
      }
    } catch {
      playErrorToast();
    }
  };

  const handleDocumentDelete = async (documentId: string) => {
    deleteMutation(documentId);
  };

  useEffect(() => {
    if (uploadFormData.body.file) {
      upload({
        file: {
          file: uploadFormData.body.file,
        },
        documentType: uploadFormData?.documentType,
      });
    }
  }, [uploadFormData, upload]);

  useEffect(() => {
    if (reuploadFormData.body.file && selectedReuploadDocument) {
      reupload({
        documentId: selectedReuploadDocument.id,
        file: {
          file: reuploadFormData.body.file,
        },
      });
    }
  }, [selectedReuploadDocument, reuploadFormData, reupload]);

  return (
    <ContentBox testId="OrderDocuments" title={t('title')} contentClassName={styles.content}>
      <header className={styles.header}>{t('userDocuments')}</header>
      <div className={styles.documents}>
        {documents.map((document, index) => (
          <div className={styles.document} key={document.id}>
            <div>
              <div className={styles.title}>{docsT(`types.${document.type}`)}</div>
              <div className={styles.filename}>{document.name}</div>
            </div>
            <div className={styles.actions}>
              <button
                type="button"
                title={t('btnDownload')}
                className={styles.action}
                data-testid={`OrderDocumentsItemDownloadBtn-${index}`}
                onClick={() => handleDocumentDownload(document.id, document.name)}
              >
                <DownloadIcon />
              </button>
              <button
                type="button"
                title={t('btnReUpload')}
                className={styles.action}
                data-testid={`OrderDocumentsItemUploadBtn-${index}`}
                onClick={() => handleDocumentReupload(document)}
              >
                <UploadIcon />
              </button>
              <button
                type="button"
                title={t('btnDelete')}
                className={styles.action}
                data-testid={`OrderDocumentsItemDeleteBtn-${index}`}
                onClick={() => handleDocumentDelete(document.id)}
              >
                <BinSimpleIcon />
              </button>
            </div>
          </div>
        ))}
      </div>

      <div className={styles.footer}>
        <Button
          type="button"
          testId="OrderDocumentsUploadBtn"
          onClick={() => setUploadModalActive(!isUploadModalActive)}
        >
          {t('upload')}
        </Button>
      </div>
      <LoaderBackdrop loading={isDeleteLoading} />
      {isUploadModalActive && (
        <Modal
          onClose={() => setUploadModalActive(false)}
          title={t('uploadModalTitle')}
          isBackdropLoading={isUploadLoading}
        >
          <UploadForm callback={uploadFile} />
        </Modal>
      )}
      {isReuploadModalActive && selectedReuploadDocument && (
        <Modal
          testId="ReuploadModal"
          onClose={() => setReuploadModalActive(false)}
          title={t('reuploadModalTitle')}
          isBackdropLoading={isReuploadLoading}
        >
          <UploadForm callback={reuploadFile} preselected={preselectedDocument} />
        </Modal>
      )}
    </ContentBox>
  );
};
