import clsx from 'clsx';
import React from 'react';
import styles from './styles.module.scss';

type TProps = {
  items: {
    label: string;
    value: string;
  }[];
  active: string;
  onClick: (value: any) => void;
};

export const Switcher: React.FC<TProps> = ({ items, onClick, active }) => (
  <div className={styles.root}>
    {items.map(({ label, value }, index) => (
      <div
        data-testid={`SwitcherItem-${index}`}
        className={clsx(styles.item, { [styles.active]: value === active })}
        key={value}
        onClick={() => onClick(value)}
      >
        {label}
      </div>
    ))}
  </div>
);
