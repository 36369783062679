import { TDefaultSelectOption } from 'types/common';

interface ICustoms {
  country: string;
  city: string;
  name: string;
}

export const customs: { [key: string]: ICustoms } = {
  AD000001: {
    country: 'Andorra - AD (043)',
    city: 'SANT JULIÀ DE LÒRIA',
    name: 'CUSTOMS OFFICE SANT JULIÀ DE LÒRIA',
  },
  AD000002: {
    country: 'Andorra - AD (043)',
    city: 'PAS DE LA CASA',
    name: 'DCNJ PORTA',
  },
  AD000003: {
    country: 'Andorra - AD (043)',
    city: 'ESCALDES - ENGORDANY',
    name: 'CENTRAL CUSTOMS OFFICE',
  },
  AT010000: {
    country: 'Austria - AT (038)',
    city: 'Graz',
    name: 'Zollamt Österreich',
  },
  AT010100: {
    country: 'Austria - AT (038)',
    city: 'Wien',
    name: 'Dienststelle NORD',
  },
  AT010200: {
    country: 'Austria - AT (038)',
    city: 'Wien-Flughafen',
    name: 'Dienststelle OST',
  },
  AT010300: {
    country: 'Austria - AT (038)',
    city: 'Klagenfurt',
    name: 'Dienststelle SÜD',
  },
  AT010400: {
    country: 'Austria - AT (038)',
    city: 'Salzburg',
    name: 'Dienststelle MITTE',
  },
  AT010500: {
    country: 'Austria - AT (038)',
    city: 'Wolfurt',
    name: 'Dienststelle WEST',
  },
  AT100000: {
    country: 'Austria - AT (038)',
    city: 'Wien',
    name: 'Zollstelle Wien',
  },
  AT100003: {
    country: 'Austria - AT (038)',
    city: 'Wien',
    name: 'Zollamt Österreich (ZVZ)',
  },
  AT100200: {
    country: 'Austria - AT (038)',
    city: 'Wien',
    name: 'Zollstelle Hafen Wien',
  },
  AT100900: {
    country: 'Austria - AT (038)',
    city: 'Wien',
    name: 'Zollstelle Wien/Post',
  },
  AT230000: {
    country: 'Austria - AT (038)',
    city: 'St. Pölten',
    name: 'Zollstelle St. Pölten',
  },
  AT230200: {
    country: 'Austria - AT (038)',
    city: 'Mistelbach',
    name: 'Zollstelle Mistelbach',
  },
  AT240000: {
    country: 'Austria - AT (038)',
    city: 'Krems an der Donau',
    name: 'Zollstelle Krems',
  },
  AT240100: {
    country: 'Austria - AT (038)',
    city: 'Krems',
    name: 'Zollstelle Hafen Krems',
  },
  AT240200: {
    country: 'Austria - AT (038)',
    city: 'Amstetten',
    name: 'Zollstelle Amstetten',
  },
  AT240400: {
    country: 'Austria - AT (038)',
    city: 'Nagelberg',
    name: 'Zollstelle Gmünd-Nagelberg',
  },
  AT240500: {
    country: 'Austria - AT (038)',
    city: 'Hollabrunn',
    name: 'Zollstelle Hollabrunn',
  },
  AT250000: {
    country: 'Austria - AT (038)',
    city: 'Wiener Neustadt',
    name: 'Zollstelle Wiener Neustadt',
  },
  AT250100: {
    country: 'Austria - AT (038)',
    city: 'Wiener Neudorf',
    name: 'Zollstelle Wiener Neudorf',
  },
  AT250101: {
    country: 'Austria - AT (038)',
    city: 'Wiener Neudorf',
    name: 'Zollstelle Wr. Neudorf Außenstelle Abfertigung',
  },
  AT320000: {
    country: 'Austria - AT (038)',
    city: 'Eisenstadt',
    name: 'Zollstelle Eisenstadt',
  },
  AT320200: {
    country: 'Austria - AT (038)',
    city: 'Eisenstadt',
    name: 'Zollstelle Eisenstadt/Strasse',
  },
  AT320300: {
    country: 'Austria - AT (038)',
    city: 'Nickelsdorf an der Leitha',
    name: 'Zollstelle Nickelsdorf',
  },
  AT320400: {
    country: 'Austria - AT (038)',
    city: 'Heiligenkreuz im Lafnitztal',
    name: 'Zollstelle Heiligenkreuz',
  },
  AT320600: {
    country: 'Austria - AT (038)',
    city: 'Schachendorf',
    name: 'Zollstelle Schachendorf',
  },
  AT330000: {
    country: 'Austria - AT (038)',
    city: 'Wien-Flughafen',
    name: 'Zollstelle Flughafen Wien',
  },
  AT330100: {
    country: 'Austria - AT (038)',
    city: 'Wien-Flughafen',
    name: 'Zollstelle Flughafen Wien Güterabfertigung',
  },
  AT330200: {
    country: 'Austria - AT (038)',
    city: 'Wien-Flughafen',
    name: 'Zollstelle Flughafen Wien Reisendenabfertigung',
  },
  AT330300: {
    country: 'Austria - AT (038)',
    city: 'Maria Lanzendorf',
    name: 'Zollstelle Maria Lanzendorf',
  },
  AT330400: {
    country: 'Austria - AT (038)',
    city: 'Wien-Flughafen',
    name: 'Zollstelle Flughafen Wien Cargo Center Nord',
  },
  AT420000: {
    country: 'Austria - AT (038)',
    city: 'Klagenfurt',
    name: 'Zollstelle Klagenfurt',
  },
  AT420100: {
    country: 'Austria - AT (038)',
    city: 'Klagenfurt',
    name: 'Zollstelle Klagenfurt Flughafen/Strasse',
  },
  AT430000: {
    country: 'Austria - AT (038)',
    city: 'Villach',
    name: 'Zollstelle Villach',
  },
  AT430100: {
    country: 'Austria - AT (038)',
    city: 'Villach',
    name: 'Zollstelle Bahnhof Villach Süd',
  },
  AT520000: {
    country: 'Austria - AT (038)',
    city: 'Linz',
    name: 'Zollstelle Linz',
  },
  AT520100: {
    country: 'Austria - AT (038)',
    city: 'Hörsching',
    name: 'Zollstelle Flughafen Linz',
  },
  AT520300: {
    country: 'Austria - AT (038)',
    city: 'Enns',
    name: 'Zollstelle Hafen Enns',
  },
  AT530000: {
    country: 'Austria - AT (038)',
    city: 'Wels',
    name: 'Zollstelle Wels',
  },
  AT530200: {
    country: 'Austria - AT (038)',
    city: 'Suben',
    name: 'Zollstelle Suben',
  },
  AT530300: {
    country: 'Austria - AT (038)',
    city: 'Schärding',
    name: 'Zollstelle Schärding',
  },
  AT600000: {
    country: 'Austria - AT (038)',
    city: 'Salzburg',
    name: 'Zollstelle Salzburg',
  },
  AT600200: {
    country: 'Austria - AT (038)',
    city: 'Salzburg',
    name: 'Zollstelle Flughafen Salzburg',
  },
  AT600201: {
    country: 'Austria - AT (038)',
    city: 'Salzburg',
    name: 'Zollstelle Flughafen Salzburg Reisendenabf.',
  },
  AT600300: {
    country: 'Austria - AT (038)',
    city: 'Salzburg',
    name: 'Zollstelle Zollstelle Güterverkehr Straße',
  },
  AT600400: {
    country: 'Austria - AT (038)',
    city: 'Wals',
    name: 'Zollstelle Liefering/Bahn',
  },
  AT700000: {
    country: 'Austria - AT (038)',
    city: 'Graz',
    name: 'Zollstelle Graz',
  },
  AT700100: {
    country: 'Austria - AT (038)',
    city: 'Feldkirchen bei Graz',
    name: 'Zollstelle Flughafen Graz',
  },
  AT700200: {
    country: 'Austria - AT (038)',
    city: 'Werndorf',
    name: 'Zollstelle Containerterminal Werndorf',
  },
  AT700300: {
    country: 'Austria - AT (038)',
    city: 'Niklasdorf',
    name: 'Zollstelle Leoben',
  },
  AT700400: {
    country: 'Austria - AT (038)',
    city: 'Spielfeld',
    name: 'Zollstelle Spielfeld',
  },
  AT700500: {
    country: 'Austria - AT (038)',
    city: 'Werndorf',
    name: 'Zollstelle Werndorf',
  },
  AT800000: {
    country: 'Austria - AT (038)',
    city: 'Innsbruck',
    name: 'Zollstelle Innsbruck',
  },
  AT800200: {
    country: 'Austria - AT (038)',
    city: 'Innsbruck',
    name: 'Zollstelle Flughafen Innsbruck',
  },
  AT800300: {
    country: 'Austria - AT (038)',
    city: 'Hall in Tirol',
    name: 'Zollstelle Hall',
  },
  AT800600: {
    country: 'Austria - AT (038)',
    city: 'Lienz in Osttirol',
    name: 'Zollstelle Lienz',
  },
  AT800700: {
    country: 'Austria - AT (038)',
    city: 'Kufstein',
    name: 'Zollstelle Kufstein',
  },
  AT800800: {
    country: 'Austria - AT (038)',
    city: 'Landeck in Tirol',
    name: 'Zollstelle Landeck',
  },
  AT800900: {
    country: 'Austria - AT (038)',
    city: 'Nauders',
    name: 'Zollstelle Martinsbruck',
  },
  AT801000: {
    country: 'Austria - AT (038)',
    city: 'Pfunds',
    name: 'Zollstelle Pfunds',
  },
  AT801100: {
    country: 'Austria - AT (038)',
    city: 'Pfunds',
    name: 'Zollstelle Spiss',
  },
  AT920000: {
    country: 'Austria - AT (038)',
    city: 'Feldkirch',
    name: 'Zollstelle Feldkirch',
  },
  AT920100: {
    country: 'Austria - AT (038)',
    city: 'Feldkirch',
    name: 'Zollstelle Buchs/Bahnhof',
  },
  AT920400: {
    country: 'Austria - AT (038)',
    city: 'Feldkirch',
    name: 'Zollstelle Tisis',
  },
  AT920700: {
    country: 'Austria - AT (038)',
    city: 'Meiningen',
    name: 'Zollstelle Meiningen',
  },
  AT920900: {
    country: 'Austria - AT (038)',
    city: 'Mäder',
    name: 'Zollstelle Mäder',
  },
  AT930000: {
    country: 'Austria - AT (038)',
    city: 'Wolfurt',
    name: 'Zollstelle Wolfurt',
  },
  AT930100: {
    country: 'Austria - AT (038)',
    city: 'Wolfurt',
    name: 'Zollstelle Wolfurt/Post',
  },
  AT930101: {
    country: 'Austria - AT (038)',
    city: 'Wolfurt',
    name: 'Zollstelle Wolfur/Post Selbstverzollung',
  },
  AT930200: {
    country: 'Austria - AT (038)',
    city: 'Hohenems',
    name: 'Zollstelle Hohenems',
  },
  AT930300: {
    country: 'Austria - AT (038)',
    city: 'Lustenau',
    name: 'Zollstelle Lustenau',
  },
  AT930400: {
    country: 'Austria - AT (038)',
    city: 'Lustenau',
    name: 'Zollstelle Schmitterbrücke',
  },
  AT930600: {
    country: 'Austria - AT (038)',
    city: 'Höchst',
    name: 'Zollstelle Höchst',
  },
  AT930800: {
    country: 'Austria - AT (038)',
    city: 'Bregenz',
    name: 'Zollstelle Seehafen Bregenz',
  },
  AT930900: {
    country: 'Austria - AT (038)',
    city: 'St. Margrethen (Schweiz)',
    name: 'Zollstelle St. Margrethen',
  },
  BE000001: {
    country: 'Belgium - BE (017)',
    city: 'BRUSSEL',
    name: 'FOD Financiën - Douane en Accijnzen HOGERE AUTORITEIT',
  },
  BE000011: {
    country: 'Belgium - BE (017)',
    city: '1030 Brussel',
    name: 'OPSCC',
  },
  BE000033: {
    country: 'Belgium - BE (017)',
    city: 'Brussel',
    name: 'Algemene Administratie D&A',
  },
  BE000034: {
    country: 'Belgium - BE (017)',
    city: 'Bruxelles',
    name: 'Administration générale des D&A',
  },
  BE000100: {
    country: 'Belgium - BE (017)',
    city: 'Brussel',
    name: 'Enig Kantoor der Douane & Accijnzen',
  },
  BE100000: {
    country: 'Belgium - BE (017)',
    city: 'ANTWERPEN',
    name: 'ANTWERPEN (GEWESTELIJKE DIRECTIE) hogere autoriteit',
  },
  BE100001: {
    country: 'Belgium - BE (017)',
    city: 'Antwerpen',
    name: 'ABC Antwerpen',
  },
  BE100011: {
    country: 'Belgium - BE (017)',
    city: 'Antwerpen',
    name: 'Regionale Component KLAMA Antwerpen',
  },
  BE101000: {
    country: 'Belgium - BE (017)',
    city: 'ANTWERPEN',
    name: 'ANTWERPEN DAE DOUANEKANTOOR',
  },
  BE200000: {
    country: 'Belgium - BE (017)',
    city: 'BRUSSEL',
    name: 'BRUSSEL (GEWESTELIJKE DIRECTIE) hogere autoriteit',
  },
  BE200001: {
    country: 'Belgium - BE (017)',
    city: 'Brussel',
    name: 'ABC Brussel',
  },
  BE200011: {
    country: 'Belgium - BE (017)',
    city: 'Brussel',
    name: 'Regionale Component KLAMA Brussel',
  },
  BE204000: {
    country: 'Belgium - BE (017)',
    city: 'BRUSSEL',
    name: 'BRUSSEL DE DOUANEKANTOOR',
  },
  BE211000: {
    country: 'Belgium - BE (017)',
    city: 'OTTIGNIES - LOUVAIN-LA-NEUVE',
    name: 'OTTIGNIES DA douanekantoor',
  },
  BE212000: {
    country: 'Belgium - BE (017)',
    city: 'MACHELEN',
    name: 'ZAVENTEM D douanekantoor',
  },
  BE300000: {
    country: 'Belgium - BE (017)',
    city: 'GENT',
    name: 'GENT (GEWESTELIJKE DIRECTIE) hogere autoriteit',
  },
  BE300001: {
    country: 'Belgium - BE (017)',
    city: 'Gent',
    name: 'ABC Gent',
  },
  BE300002: {
    country: 'Belgium - BE (017)',
    city: 'Roeselare',
    name: 'ABC Roeselare',
  },
  BE300011: {
    country: 'Belgium - BE (017)',
    city: 'Gent',
    name: 'Regionale Component KLAMA Gent',
  },
  BE312000: {
    country: 'Belgium - BE (017)',
    city: 'GENT',
    name: 'GENT DAE douanekantoor',
  },
  BE343000: {
    country: 'Belgium - BE (017)',
    city: 'ZEEBRUGGE (BRUGGE)',
    name: 'ZEEBRUGGE D douanekantoor',
  },
  BE400000: {
    country: 'Belgium - BE (017)',
    city: 'HASSELT',
    name: 'HASSELT (GEWESTIJKE DIRECTIE) hogere autoriteit',
  },
  BE400001: {
    country: 'Belgium - BE (017)',
    city: 'Hasselt',
    name: 'ABC Hasselt',
  },
  BE400002: {
    country: 'Belgium - BE (017)',
    city: 'Mechelen',
    name: 'ABC Mechelen',
  },
  BE400003: {
    country: 'Belgium - BE (017)',
    city: 'Turnhout',
    name: 'ABC Turnhout',
  },
  BE400011: {
    country: 'Belgium - BE (017)',
    city: 'Hasselt',
    name: 'Regionale Component KLAMA Hasselt',
  },
  BE408000: {
    country: 'Belgium - BE (017)',
    city: 'HASSELT',
    name: 'HASSELT DA douanekantoor',
  },
  BE424000: {
    country: 'Belgium - BE (017)',
    city: 'TIENEN',
    name: 'TIENEN DAE DOUANEKANTOOR',
  },
  BE432000: {
    country: 'Belgium - BE (017)',
    city: 'GEEL',
    name: 'GEEL DAE douanekantoor',
  },
  BE500000: {
    country: 'Belgium - BE (017)',
    city: 'LIEGE',
    name: 'LIEGE (GEWESTELIJKE DIRECTIE) hogere autoriteit',
  },
  BE500001: {
    country: 'Belgium - BE (017)',
    city: 'Liège',
    name: 'CAC Liège',
  },
  BE500002: {
    country: 'Belgium - BE (017)',
    city: 'Arlon',
    name: 'CAC Luxembourg - Arlon',
  },
  BE500003: {
    country: 'Belgium - BE (017)',
    city: 'Eupen',
    name: 'CAC Eupen',
  },
  BE500011: {
    country: 'Belgium - BE (017)',
    city: 'Lüttich',
    name: 'KLAMA - BEWILLIGUNGEN - Lüttich',
  },
  BE501000: {
    country: 'Belgium - BE (017)',
    city: 'ARLON',
    name: 'ARLON DA douanekantoor',
  },
  BE514000: {
    country: 'Belgium - BE (017)',
    city: 'EYNATTEN',
    name: 'EYNATTEN (RAEREN) D douanekantoor',
  },
  BE531000: {
    country: 'Belgium - BE (017)',
    city: 'LIEGE',
    name: 'LIEGE (MONSIN) DOUANEKANTOOR',
  },
  BE532000: {
    country: 'Belgium - BE (017)',
    city: 'GRACE-HOLLOGNE',
    name: 'BIERSET (GRACE-HOLLOGNE) DE douanekantoor',
  },
  BE600000: {
    country: 'Belgium - BE (017)',
    city: 'MONS',
    name: 'MONS (GEWESTELIJKE DIRECTIE) hogere autoriteit',
  },
  BE600001: {
    country: 'Belgium - BE (017)',
    city: 'Mons',
    name: 'CAC Mons',
  },
  BE600002: {
    country: 'Belgium - BE (017)',
    city: 'Charleroi',
    name: 'CAC charleroi',
  },
  BE600003: {
    country: 'Belgium - BE (017)',
    city: 'Namur',
    name: 'CAC Namur',
  },
  BE600011: {
    country: 'Belgium - BE (017)',
    city: 'Mons',
    name: 'Composante Regionale KLAMA Mons',
  },
  BE632000: {
    country: 'Belgium - BE (017)',
    city: 'HOUDENG-GOEGNIES',
    name: 'LA LOUVIERE DAE douanekantoor',
  },
  BE648000: {
    country: 'Belgium - BE (017)',
    city: 'NAMUR (Saint - Servais)',
    name: 'NAMUR DA bureau de douane',
  },
  BE662000: {
    country: 'Belgium - BE (017)',
    city: 'MOUSCRON (RISQUONS-TOUT)',
    name: 'RISQUONS-TOUT (MOUSCRON) douanekantoor',
  },
  BE663000: {
    country: 'Belgium - BE (017)',
    city: 'CHIEVRES',
    name: 'SHAPE (CASTEAU) douanekantoor',
  },
  BE680000: {
    country: 'Belgium - BE (017)',
    city: 'GOSSELIES',
    name: 'GOSSELIES (CHARLEROI) DAE douanekantoor',
  },
  BE683000: {
    country: 'Belgium - BE (017)',
    city: 'CHIEVRES',
    name: 'CHIEVRES (C.A.B.) DOUANEKANTOOR',
  },
  BE700001: {
    country: 'Belgium - BE (017)',
    city: 'Leuven',
    name: 'ABC Leuven',
  },
  BE700011: {
    country: 'Belgium - BE (017)',
    city: 'Leuven',
    name: 'Regionale Component KLAMA Leuven',
  },
  BE800000: {
    country: 'Belgium - BE (017)',
    city: 'Brussel',
    name: 'Onderzoek en Opsporingen',
  },
  BE800001: {
    country: 'Belgium - BE (017)',
    city: 'Antwerpen',
    name: 'O&O Antwerpen',
  },
  BE800002: {
    country: 'Belgium - BE (017)',
    city: 'Brussel',
    name: 'O&O Brussel',
  },
  BE800003: {
    country: 'Belgium - BE (017)',
    city: 'Gent',
    name: 'O&O Gent',
  },
  BE800004: {
    country: 'Belgium - BE (017)',
    city: 'Luik',
    name: 'O&O Luik',
  },
  BE800005: {
    country: 'Belgium - BE (017)',
    city: 'Bergen',
    name: 'O&O Bergen',
  },
  BG001000: {
    country: 'Bulgaria - BG (068)',
    city: 'Burgas',
    name: 'TD Customs Burgas',
  },
  BG001002: {
    country: 'Bulgaria - BG (068)',
    city: 'Burgas',
    name: 'MP Letishte Burgas',
  },
  BG001003: {
    country: 'Bulgaria - BG (068)',
    city: 'Malko Tarnovo',
    name: 'MP M.Tarnovo',
  },
  BG001005: {
    country: 'Bulgaria - BG (068)',
    city: 'Burgas',
    name: 'MB Neftohimicheski kombinat',
  },
  BG001007: {
    country: 'Bulgaria - BG (068)',
    city: 'Burgas',
    name: 'MP Pristanishte Burgas tsentar',
  },
  BG001008: {
    country: 'Bulgaria - BG (068)',
    city: 'Burgas',
    name: 'MB Svobodna Zona',
  },
  BG001009: {
    country: 'Bulgaria - BG (068)',
    city: 'Sliven',
    name: 'MB Sliven',
  },
  BG001010: {
    country: 'Bulgaria - BG (068)',
    city: 'Yambol',
    name: 'MB Yambol',
  },
  BG001011: {
    country: 'Bulgaria - BG (068)',
    city: 'Lesovo',
    name: 'MP Lesovo',
  },
  BG001012: {
    country: 'Bulgaria - BG (068)',
    city: 'Tsarevo',
    name: 'MP Tsarevo',
  },
  BG001013: {
    country: 'Bulgaria - BG (068)',
    city: 'Burgas',
    name: 'MP Neftopristanishte',
  },
  BG001014: {
    country: 'Bulgaria - BG (068)',
    city: 'Svilengrad',
    name: 'MP Zh.P. Gara Svilengrad',
  },
  BG001015: {
    country: 'Bulgaria - BG (068)',
    city: 'GKPP Kapitan Andreevo',
    name: 'MP Kapitan Andreevo',
  },
  BG002000: {
    country: 'Bulgaria - BG (068)',
    city: 'Varna',
    name: 'TD Customs Varna',
  },
  BG002002: {
    country: 'Bulgaria - BG (068)',
    city: 'Devnya',
    name: 'MP Pristanishte Varna-West',
  },
  BG002003: {
    country: 'Bulgaria - BG (068)',
    city: 'Varna',
    name: 'MP Letishte Varna',
  },
  BG002005: {
    country: 'Bulgaria - BG (068)',
    city: 'Varna',
    name: 'MP Pristanishte Varna',
  },
  BG002006: {
    country: 'Bulgaria - BG (068)',
    city: 'Topoli',
    name: 'MP Pristanishte Lesport',
  },
  BG002007: {
    country: 'Bulgaria - BG (068)',
    city: 'Beloslav',
    name: 'MP Varna-Feribot',
  },
  BG002008: {
    country: 'Bulgaria - BG (068)',
    city: 'Shumen',
    name: 'MB Shumen',
  },
  BG002009: {
    country: 'Bulgaria - BG (068)',
    city: 'Dobrich',
    name: 'MB Dobrich',
  },
  BG002010: {
    country: 'Bulgaria - BG (068)',
    city: 'Balchik',
    name: 'MP Balchik',
  },
  BG003000: {
    country: 'Bulgaria - BG (068)',
    city: 'Plovdiv',
    name: 'TD Customs Plovdiv',
  },
  BG003001: {
    country: 'Bulgaria - BG (068)',
    city: 'Kazanlak',
    name: 'MB Kazanlak',
  },
  BG003002: {
    country: 'Bulgaria - BG (068)',
    city: 'Plovdiv',
    name: 'MP Letishte Plovdiv',
  },
  BG003004: {
    country: 'Bulgaria - BG (068)',
    city: 'Pazardzhik',
    name: 'MB Pazardzhik',
  },
  BG003005: {
    country: 'Bulgaria - BG (068)',
    city: 'Plovdiv',
    name: 'MB Svobodna zona Plovdiv',
  },
  BG003006: {
    country: 'Bulgaria - BG (068)',
    city: 'Smolyan',
    name: 'MB Smolyan',
  },
  BG003007: {
    country: 'Bulgaria - BG (068)',
    city: 'Karlovo',
    name: 'MB Karlovo',
  },
  BG003008: {
    country: 'Bulgaria - BG (068)',
    city: 'Stara Zagora',
    name: 'MB Stara Zagora',
  },
  BG003009: {
    country: 'Bulgaria - BG (068)',
    city: 'Plovdiv',
    name: 'MB Plovdiv',
  },
  BG003010: {
    country: 'Bulgaria - BG (068)',
    city: 'Svilengrad',
    name: 'MB Svilengrad',
  },
  BG003011: {
    country: 'Bulgaria - BG (068)',
    city: 'Kardzhali',
    name: 'MB Kardzhali',
  },
  BG003012: {
    country: 'Bulgaria - BG (068)',
    city: 'Haskovo',
    name: 'MB Haskovo',
  },
  BG003013: {
    country: 'Bulgaria - BG (068)',
    city: 'Zlatitrap, obl.Plovdiv',
    name: 'MB Intermodalen terminal',
  },
  BG004000: {
    country: 'Bulgaria - BG (068)',
    city: 'Ruse',
    name: 'TD Customs Ruse',
  },
  BG004002: {
    country: 'Bulgaria - BG (068)',
    city: 'Razgrad',
    name: 'MB Razgrad',
  },
  BG004006: {
    country: 'Bulgaria - BG (068)',
    city: 'Ruse',
    name: 'MP Pristanishte',
  },
  BG004011: {
    country: 'Bulgaria - BG (068)',
    city: 'Targovishte',
    name: 'MB Targovishte',
  },
  BG004014: {
    country: 'Bulgaria - BG (068)',
    city: 'Silistra',
    name: 'MP Silistra',
  },
  BG004201: {
    country: 'Bulgaria - BG (068)',
    city: 'Vratsa',
    name: 'MB Vratsa',
  },
  BG004203: {
    country: 'Bulgaria - BG (068)',
    city: 'Oryahovo',
    name: 'MP Oryahovо',
  },
  BG004205: {
    country: 'Bulgaria - BG (068)',
    city: 'Lom',
    name: 'MP Pristanishte Lom',
  },
  BG004210: {
    country: 'Bulgaria - BG (068)',
    city: 'Montana',
    name: 'MB Montana',
  },
  BG004211: {
    country: 'Bulgaria - BG (068)',
    city: 'Vidin',
    name: 'MB Vidin',
  },
  BG004212: {
    country: 'Bulgaria - BG (068)',
    city: 'Bregovo',
    name: 'MP Bregovo',
  },
  BG004213: {
    country: 'Bulgaria - BG (068)',
    city: 'Gramada',
    name: 'MP Vrashka chuka',
  },
  BG004300: {
    country: 'Bulgaria - BG (068)',
    city: 'Svishtov',
    name: 'CustBorderPoint Svishtov',
  },
  BG004301: {
    country: 'Bulgaria - BG (068)',
    city: 'Gabrovo',
    name: 'MB Gabrovo',
  },
  BG004303: {
    country: 'Bulgaria - BG (068)',
    city: 'Gorna Oryahovitsa',
    name: 'MP Letishte Gorna Oryahovitsa',
  },
  BG004304: {
    country: 'Bulgaria - BG (068)',
    city: 'Lovech',
    name: 'MB Lovech',
  },
  BG004306: {
    country: 'Bulgaria - BG (068)',
    city: 'Pleven',
    name: 'MB Pleven',
  },
  BG004307: {
    country: 'Bulgaria - BG (068)',
    city: 'Somovit',
    name: 'MP Somovit',
  },
  BG004309: {
    country: 'Bulgaria - BG (068)',
    city: 'Sevlievo',
    name: 'MB Sevlievo',
  },
  BG004311: {
    country: 'Bulgaria - BG (068)',
    city: 'Troyan',
    name: 'MB Troyan',
  },
  BG005100: {
    country: 'Bulgaria - BG (068)',
    city: 'Sofia',
    name: 'CustOffice Airport Sofia',
  },
  BG005106: {
    country: 'Bulgaria - BG (068)',
    city: 'Sofia',
    name: 'MP Letishte Sofia - patnitsi',
  },
  BG005107: {
    country: 'Bulgaria - BG (068)',
    city: 'Sofia',
    name: 'MP Aerogara Sofia - tovari',
  },
  BG005700: {
    country: 'Bulgaria - BG (068)',
    city: 'Blagoevgrad',
    name: 'CustOffice Blagoevgrad',
  },
  BG005701: {
    country: 'Bulgaria - BG (068)',
    city: 'Gotse Delchev',
    name: 'MB Gotse Delchev',
  },
  BG005702: {
    country: 'Bulgaria - BG (068)',
    city: 'GKPP Gyueshevo',
    name: 'MP Gyueshevo',
  },
  BG005703: {
    country: 'Bulgaria - BG (068)',
    city: 'GKPP Zlatarevo',
    name: 'MP Zlatarevo',
  },
  BG005704: {
    country: 'Bulgaria - BG (068)',
    city: 'Kulata',
    name: 'MB Kulata',
  },
  BG005705: {
    country: 'Bulgaria - BG (068)',
    city: 'Kyustendil',
    name: 'MB Kyustendil',
  },
  BG005706: {
    country: 'Bulgaria - BG (068)',
    city: 'Blagoevgrad',
    name: 'MP Logodazh',
  },
  BG005707: {
    country: 'Bulgaria - BG (068)',
    city: 'Oltomantsi',
    name: 'MP Oltomantsi',
  },
  BG005800: {
    country: 'Bulgaria - BG (068)',
    city: 'Sofia',
    name: 'TD Customs Sofia',
  },
  BG005801: {
    country: 'Bulgaria - BG (068)',
    city: 'Botevgrad',
    name: 'MB Botevgrad',
  },
  BG005803: {
    country: 'Bulgaria - BG (068)',
    city: 'Dragoman',
    name: 'MB Dragoman',
  },
  BG005804: {
    country: 'Bulgaria - BG (068)',
    city: 'Kalotina',
    name: 'MP Kalotina',
  },
  BG005805: {
    country: 'Bulgaria - BG (068)',
    city: 'Pernik',
    name: 'MB Pernik',
  },
  BG005807: {
    country: 'Bulgaria - BG (068)',
    city: 'Sofia',
    name: 'MB Sofia-Zapad',
  },
  BG005808: {
    country: 'Bulgaria - BG (068)',
    city: 'Sofia',
    name: 'MB Sofia-Iztok',
  },
  BG005809: {
    country: 'Bulgaria - BG (068)',
    city: 'Strezimirovtsi',
    name: 'MP Strezimirovtsi',
  },
  BG005810: {
    country: 'Bulgaria - BG (068)',
    city: 'Sofia',
    name: 'MB BRSC',
  },
  BG005811: {
    country: 'Bulgaria - BG (068)',
    city: 'Sofia',
    name: 'MB Slatina',
  },
  BG009999: {
    country: 'Bulgaria - BG (068)',
    city: 'Sofia',
    name: 'Tsentralno mitnichesko upravlenie',
  },
  CH001001: {
    country: 'Switzerland - CH (039)',
    city: 'CH - 5001 Aarau',
    name: 'Zoll Nord - Zentralstelle gVV',
  },
  CH001004: {
    country: 'Switzerland - CH (039)',
    city: 'Basel',
    name: 'Aufgabenvollzug Zoll Nord',
  },
  CH001141: {
    country: 'Switzerland - CH (039)',
    city: 'BONCOURT',
    name: 'Douane Centre - Boncourt-Delle-Auto',
  },
  CH001251: {
    country: 'Switzerland - CH (039)',
    city: 'CH - 4019 Basel',
    name: 'Zoll Nord - Basel Mitte EVO',
  },
  CH001252: {
    country: 'Switzerland - CH (039)',
    city: 'CH - 4019 Basel',
    name: 'Zoll Nord - Basel Mitte Konv.',
  },
  CH001253: {
    country: 'Switzerland - CH (039)',
    city: 'CH - 4019 Basel',
    name: 'Zoll Nord - Basel Mitte Kurier',
  },
  CH001454: {
    country: 'Switzerland - CH (039)',
    city: 'CH - 4019 Basel',
    name: 'Zoll Nord - Basel Mitte Messe',
  },
  CH001471: {
    country: 'Switzerland - CH (039)',
    city: 'CH - 4019 Basel',
    name: 'Zoll Nord - Basel Mitte Rheinhäfen',
  },
  CH001501: {
    country: 'Switzerland - CH (039)',
    city: 'Riehen',
    name: 'Zoll Nord - Riehen',
  },
  CH001551: {
    country: 'Switzerland - CH (039)',
    city: 'Riehen',
    name: 'Zoll Nord - Grenzacherstrasse',
  },
  CH001571: {
    country: 'Switzerland - CH (039)',
    city: 'CH - 4019 Basel',
    name: 'Zoll Nord - Basel Mitte UBF',
  },
  CH001591: {
    country: 'Switzerland - CH (039)',
    city: 'Stein AG',
    name: 'Zoll Nord - Stein/Bad Säckingen',
  },
  CH001601: {
    country: 'Switzerland - CH (039)',
    city: 'Laufenburg',
    name: 'Zoll Nord - Laufenburg',
  },
  CH001611: {
    country: 'Switzerland - CH (039)',
    city: 'Moehlin',
    name: 'Zollamt RHEINFELDEN-AB-DA MOEHLIN',
  },
  CH001631: {
    country: 'Switzerland - CH (039)',
    city: 'Aarau',
    name: 'Zoll Nord - Aarau',
  },
  CH001651: {
    country: 'Switzerland - CH (039)',
    city: 'Luzern',
    name: 'Zollinspektorat AARAU DA LUZERN',
  },
  CH001661: {
    country: 'Switzerland - CH (039)',
    city: 'BERN',
    name: 'Zoll Mitte - Bern',
  },
  CH001671: {
    country: 'Switzerland - CH (039)',
    city: 'Belp',
    name: 'Zoll Mitte - Bern Belp',
  },
  CH001711: {
    country: 'Switzerland - CH (039)',
    city: 'Basel',
    name: 'Zoll Nord - Basel Flughafen Fracht',
  },
  CH001712: {
    country: 'Switzerland - CH (039)',
    city: 'Basel',
    name: 'Zoll Nord - Basel Flughafen Kurier',
  },
  CH001731: {
    country: 'Switzerland - CH (039)',
    city: 'Pratteln',
    name: 'Zoll Nord - Pratteln',
  },
  CH001801: {
    country: 'Switzerland - CH (039)',
    city: 'Basel',
    name: 'Zoll Nord - Basel/Weil Rhein-Autoba',
  },
  CH001841: {
    country: 'Switzerland - CH (039)',
    city: 'Basel',
    name: 'Zoll Nord - Basel/St.Louis Autobahn',
  },
  CH001842: {
    country: 'Switzerland - CH (039)',
    city: 'Basel',
    name: 'Zollamt Basel/St.Louis-Autobahn EVO',
  },
  CH001921: {
    country: 'Switzerland - CH (039)',
    city: 'Rheinfelden',
    name: 'Zoll Nord - Rheinfelden Autobahn',
  },
  CH002001: {
    country: 'Switzerland - CH (039)',
    city: 'Kreuzlingen',
    name: 'Zoll Nordost - Kompetenzzentrum gVV',
  },
  CH002003: {
    country: 'Switzerland - CH (039)',
    city: 'Schaffhausen',
    name: 'Aufgabenvollzug Zoll Nordost',
  },
  CH002041: {
    country: 'Switzerland - CH (039)',
    city: 'Koblenz',
    name: 'Zoll Nord - Koblenz',
  },
  CH002091: {
    country: 'Switzerland - CH (039)',
    city: 'Thayngen',
    name: 'Zoll Nordost - Trasadingen',
  },
  CH002151: {
    country: 'Switzerland - CH (039)',
    city: 'Schaffhausen',
    name: 'Zollamt Grenzübergang SCHLEITHEIM',
  },
  CH002171: {
    country: 'Switzerland - CH (039)',
    city: 'SCHAFFHAUSEN',
    name: 'Zollamt SCHAFFHAUSEN DA SCHAFFHAUSEN',
  },
  CH002261: {
    country: 'Switzerland - CH (039)',
    city: 'Embrach',
    name: 'Zoll Nordost - Rafz-Solgen',
  },
  CH002291: {
    country: 'Switzerland - CH (039)',
    city: 'Thayngen',
    name: 'Zoll Nordost - Neuhausen',
  },
  CH002311: {
    country: 'Switzerland - CH (039)',
    city: 'Thayngen',
    name: 'Zoll Nordost - Bargen',
  },
  CH002411: {
    country: 'Switzerland - CH (039)',
    city: 'Thayngen',
    name: 'Zoll Nordost - Ramsen',
  },
  CH002471: {
    country: 'Switzerland - CH (039)',
    city: 'Thayngen',
    name: 'Zoll Nordost - Thayngen',
  },
  CH002621: {
    country: 'Switzerland - CH (039)',
    city: 'Kreuzlingen',
    name: 'Zoll Nordost - Kreuzlingen Autobahn',
  },
  CH002671: {
    country: 'Switzerland - CH (039)',
    city: 'Romanshorn',
    name: 'Zoll Nordost - Romanshorn',
  },
  CH002711: {
    country: 'Switzerland - CH (039)',
    city: 'Embrach',
    name: 'Zollamt Zoll Zürich, Embrach',
  },
  CH002751: {
    country: 'Switzerland - CH (039)',
    city: 'Embrach',
    name: 'Zoll Nordost - Zürich 1',
  },
  CH002752: {
    country: 'Switzerland - CH (039)',
    city: 'Embrach',
    name: 'Zoll Nordost - Zürich 2',
  },
  CH002753: {
    country: 'Switzerland - CH (039)',
    city: 'Embrach',
    name: 'Zoll Nordost - Zürich 3',
  },
  CH002754: {
    country: 'Switzerland - CH (039)',
    city: 'Embrach',
    name: 'Zoll Nordost - Zürich Kurier',
  },
  CH002755: {
    country: 'Switzerland - CH (039)',
    city: 'Embrach',
    name: 'Zoll Nordost - Zürich Messe',
  },
  CH002756: {
    country: 'Switzerland - CH (039)',
    city: 'Zürich-Mülligen',
    name: 'Zoll Nordost - Mülligen',
  },
  CH002771: {
    country: 'Switzerland - CH (039)',
    city: 'Zürich-Flughafen',
    name: 'Zoll Nordost - Zürich Flughafen',
  },
  CH003001: {
    country: 'Switzerland - CH (039)',
    city: 'Genève 26',
    name: 'Douane Ouest - Centre recherche TC',
  },
  CH003011: {
    country: 'Switzerland - CH (039)',
    city: 'St. Gallen',
    name: 'Zoll Ost - St. Gallen',
  },
  CH003031: {
    country: 'Switzerland - CH (039)',
    city: 'St. Margrethen',
    name: 'Zoll Ost - Wolfurt',
  },
  CH003041: {
    country: 'Switzerland - CH (039)',
    city: 'Altenrhein',
    name: 'Zoll Ost - Altenrhein-Flughafen',
  },
  CH003071: {
    country: 'Switzerland - CH (039)',
    city: 'St. Margrethen',
    name: 'Zoll Ost - St. Margrethen Strasse',
  },
  CH003081: {
    country: 'Switzerland - CH (039)',
    city: 'Au',
    name: 'Zoll Ost - Au',
  },
  CH003091: {
    country: 'Switzerland - CH (039)',
    city: 'St. Margrethen',
    name: 'Zoll Ost - St. Margrethen Freilager',
  },
  CH003121: {
    country: 'Switzerland - CH (039)',
    city: 'Kriessern',
    name: 'Zoll Ost - Kriessern',
  },
  CH003140: {
    country: 'Switzerland - CH (039)',
    city: 'Buchs',
    name: 'Zoll Ost - Buchs',
  },
  CH003151: {
    country: 'Switzerland - CH (039)',
    city: 'Oberriet',
    name: 'Zoll Ost - Oberriet',
  },
  CH003201: {
    country: 'Switzerland - CH (039)',
    city: 'Schaanwald FL',
    name: 'Zoll Ost - Schaanwald',
  },
  CH003261: {
    country: 'Switzerland - CH (039)',
    city: 'Martina',
    name: 'Zoll Ost - Martina',
  },
  CH003301: {
    country: 'Switzerland - CH (039)',
    city: 'Müstair',
    name: 'Zoll Ost - Müstair',
  },
  CH003331: {
    country: 'Switzerland - CH (039)',
    city: 'Zernez',
    name: 'Zoll Ost - La Drossa',
  },
  CH003361: {
    country: 'Switzerland - CH (039)',
    city: 'Campocologno',
    name: 'Zoll Ost - Campocologno',
  },
  CH003391: {
    country: 'Switzerland - CH (039)',
    city: 'Post Bernina Hospiz',
    name: 'Ufficio doganale La Motta',
  },
  CH003401: {
    country: 'Switzerland - CH (039)',
    city: 'Castasegna',
    name: 'Zoll Ost - Castasegna',
  },
  CH003451: {
    country: 'Switzerland - CH (039)',
    city: 'Diepoldsau',
    name: 'Zoll Ost - Diepoldsau',
  },
  CH004001: {
    country: 'Switzerland - CH (039)',
    city: 'Chiasso',
    name: 'Dogana Sud - Centrale PTC',
  },
  CH004002: {
    country: 'Switzerland - CH (039)',
    city: 'Lugano',
    name: 'Sezione Tariffa e regimi D IV',
  },
  CH004011: {
    country: 'Switzerland - CH (039)',
    city: 'Bioggio',
    name: 'Dogana Sud - Vedeggio',
  },
  CH004031: {
    country: 'Switzerland - CH (039)',
    city: 'Gandria',
    name: 'Dogana Sud - Gandria',
  },
  CH004101: {
    country: 'Switzerland - CH (039)',
    city: 'Ponte Tresa',
    name: 'Dogana Sud - Ponte Tresa',
  },
  CH004131: {
    country: 'Switzerland - CH (039)',
    city: 'Agno',
    name: 'Dogana Sud - Agno Aeroporto',
  },
  CH004162: {
    country: 'Switzerland - CH (039)',
    city: 'Chiasso',
    name: 'Dogana Sud - Mendrisio SDA DDA',
  },
  CH004163: {
    country: 'Switzerland - CH (039)',
    city: 'Stabio',
    name: 'Dogana Sud - Mendrisio Confine TC',
  },
  CH004164: {
    country: 'Switzerland - CH (039)',
    city: 'Balerna',
    name: 'Dogana Sud - Mendrisio DFD',
  },
  CH004181: {
    country: 'Switzerland - CH (039)',
    city: 'Chiasso',
    name: 'Dogana Sud - Chiasso-Strada',
  },
  CH004182: {
    country: 'Switzerland - CH (039)',
    city: 'Chiasso',
    name: 'Dogana Sud - Brogeda Autostrada',
  },
  CH004183: {
    country: 'Switzerland - CH (039)',
    city: 'Chiasso',
    name: 'Dogana Sud - Chiasso Viaggiatori',
  },
  CH004421: {
    country: 'Switzerland - CH (039)',
    city: 'Brissago',
    name: 'Dogana Sud - Madonna di Ponte',
  },
  CH004471: {
    country: 'Switzerland - CH (039)',
    city: 'Dirinella',
    name: 'Dogana Sud - Dirinella',
  },
  CH004491: {
    country: 'Switzerland - CH (039)',
    city: 'Bellinzona - Luino',
    name: 'Dogana Sud - Luino',
  },
  CH004581: {
    country: 'Switzerland - CH (039)',
    city: 'Chiasso',
    name: 'Dogana Sud - Bissone',
  },
  CH005031: {
    country: 'Switzerland - CH (039)',
    city: 'Chavornay',
    name: 'Douane Ouest - Chavornay Port Franc',
  },
  CH005040: {
    country: 'Switzerland - CH (039)',
    city: 'Brig-Glis',
    name: 'Zoll West - Domo 2',
  },
  CH005051: {
    country: 'Switzerland - CH (039)',
    city: 'Brig-Glis',
    name: 'Zoll West - Gamsen',
  },
  CH005081: {
    country: 'Switzerland - CH (039)',
    city: 'Brig-Glis',
    name: 'Zoll West - Gondo',
  },
  CH005121: {
    country: 'Switzerland - CH (039)',
    city: 'St. Gingolph',
    name: 'Douane Ouest - St. Gingolph',
  },
  CH005211: {
    country: 'Switzerland - CH (039)',
    city: 'VEVEY',
    name: 'Bureau de douane VEVEY PORT-FRANC',
  },
  CH005441: {
    country: 'Switzerland - CH (039)',
    city: 'Vallorbe',
    name: 'Douane Ouest - Vallorbe',
  },
  CH005491: {
    country: 'Switzerland - CH (039)',
    city: 'Les Verrières',
    name: 'Douane Centre - Les Verrières',
  },
  CH005551: {
    country: 'Switzerland - CH (039)',
    city: 'Le Locle',
    name: 'Douane Centre - Le Locle',
  },
  CH005561: {
    country: 'Switzerland - CH (039)',
    city: 'Le Locle',
    name: 'Douane Centre - Col France',
  },
  CH005691: {
    country: 'Switzerland - CH (039)',
    city: 'Martigny 1',
    name: 'Douane Ouest - Martigny',
  },
  CH005701: {
    country: 'Switzerland - CH (039)',
    city: 'Martigny 1',
    name: 'Douane Ouest - Gd-St-Bernard Tunnel',
  },
  CH006002: {
    country: 'Switzerland - CH (039)',
    city: 'Cointrin',
    name: 'Section Tarif et Régimes D III',
  },
  CH006021: {
    country: 'Switzerland - CH (039)',
    city: 'Genève 26',
    name: 'Douane Ouest - Genève Port Franc',
  },
  CH006221: {
    country: 'Switzerland - CH (039)',
    city: 'Thônex',
    name: 'Douane Ouest - Thônex-Vallard',
  },
  CH006251: {
    country: 'Switzerland - CH (039)',
    city: 'Bardonnex',
    name: 'Douane Ouest - Bardonnex',
  },
  CH006451: {
    country: 'Switzerland - CH (039)',
    city: 'Grand-Saconnex',
    name: 'Douane Ouest - Ferney-Voltaire',
  },
  CH006521: {
    country: 'Switzerland - CH (039)',
    city: "Genève 5 Office d'échange",
    name: 'Douane Ouest - Genève Aéroport',
  },
  CH009002: {
    country: 'Switzerland - CH (039)',
    city: 'Bern',
    name: 'Oberzolldirektion OBERZOLLDIREKTION',
  },
  CH009003: {
    country: 'Switzerland - CH (039)',
    city: 'Bern',
    name: 'Oberzolldirektion OBERZOLLDIREKTION SEKTION FIRE',
  },
  CH009007: {
    country: 'Switzerland - CH (039)',
    city: 'Bern',
    name: 'OBERZOLLDIREKTION S Ursprung',
  },
  CY000100: {
    country: 'Cyprus - CY (600)',
    city: 'NICOSIA',
    name: 'CUSTOMS HEADQUARTERS',
  },
  CY000130: {
    country: 'Cyprus - CY (600)',
    city: 'NICOSIA',
    name: 'NICOSIA CUSTOMS',
  },
  CY000150: {
    country: 'Cyprus - CY (600)',
    city: 'NICOSIA',
    name: 'NICOSIA PARCEL POST',
  },
  CY000311: {
    country: 'Cyprus - CY (600)',
    city: 'FAMAGUSTA',
    name: 'AYIA NAPA MARINA',
  },
  CY000312: {
    country: 'Cyprus - CY (600)',
    city: 'FAMAGUSTA',
    name: 'AYIA NAPA PORT',
  },
  CY000410: {
    country: 'Cyprus - CY (600)',
    city: 'LARNACA',
    name: 'LARNACA PORT',
  },
  CY000412: {
    country: 'Cyprus - CY (600)',
    city: 'LARNACA',
    name: 'VASILICO-ZYGI',
  },
  CY000431: {
    country: 'Cyprus - CY (600)',
    city: 'LARNACA',
    name: 'LARNACA FREE ZONE',
  },
  CY000440: {
    country: 'Cyprus - CY (600)',
    city: 'LARNACA',
    name: 'LARNACA AIRPORT',
  },
  CY000450: {
    country: 'Cyprus - CY (600)',
    city: 'LARNACA',
    name: 'LARNACA PARCEL POST',
  },
  CY000510: {
    country: 'Cyprus - CY (600)',
    city: 'LIMASSOL',
    name: 'LIMASSOL PORT',
  },
  CY000511: {
    country: 'Cyprus - CY (600)',
    city: 'LIMASSOL',
    name: 'LIMASSOL ANCHORAGE',
  },
  CY000514: {
    country: 'Cyprus - CY (600)',
    city: 'LIMASSOL',
    name: 'MONI',
  },
  CY000550: {
    country: 'Cyprus - CY (600)',
    city: 'LIMASSOL',
    name: 'LIMASSOL PARCEL POST',
  },
  CY000610: {
    country: 'Cyprus - CY (600)',
    city: 'PAPHOS',
    name: 'PAPHOS CUSTOMS',
  },
  CY000640: {
    country: 'Cyprus - CY (600)',
    city: 'PAPHOS',
    name: 'PAPHOS AIRPORT',
  },
  CY000650: {
    country: 'Cyprus - CY (600)',
    city: 'PAPHOS',
    name: 'PAPHOS PARCEL POST',
  },
  CZ510000: {
    country: 'Czechia - CZ (061)',
    city: 'Praha 1',
    name: 'Celní úřad pro hlavní město Prahu, Praha 1',
  },
  CZ510100: {
    country: 'Czechia - CZ (061)',
    city: 'Praha 1',
    name: 'Odbor CÚ Právní, Praha 1',
  },
  CZ510101: {
    country: 'Czechia - CZ (061)',
    city: 'Praha 1',
    name: 'Oddělení CÚ Právních a správních činností, Prah',
  },
  CZ510200: {
    country: 'Czechia - CZ (061)',
    city: 'Praha 10',
    name: 'Odbor CÚ Celní, Praha 10',
  },
  CZ510201: {
    country: 'Czechia - CZ (061)',
    city: 'Praha 10',
    name: 'Oddělení CÚ Celní, Praha 10',
  },
  CZ510202: {
    country: 'Czechia - CZ (061)',
    city: 'Praha 10',
    name: 'Oddělení CÚ Celní, Praha 10',
  },
  CZ510300: {
    country: 'Czechia - CZ (061)',
    city: 'Praha 1',
    name: 'Odbor CÚ Daňový, Praha 1',
  },
  CZ510301: {
    country: 'Czechia - CZ (061)',
    city: 'Praha 1',
    name: 'Oddělení CÚ Daňové, Praha 1',
  },
  CZ510302: {
    country: 'Czechia - CZ (061)',
    city: 'Praha 1',
    name: 'Oddělení CÚ Daňové, Praha 1',
  },
  CZ510303: {
    country: 'Czechia - CZ (061)',
    city: 'Praha 1',
    name: 'Oddělení CÚ Daňové, Praha 1',
  },
  CZ520000: {
    country: 'Czechia - CZ (061)',
    city: 'České Budějovice',
    name: 'Celní úřad pro Jihočeský kraj',
  },
  CZ520100: {
    country: 'Czechia - CZ (061)',
    city: 'České Budějovice',
    name: 'Odbor CÚ Právní, České Budějovice',
  },
  CZ520101: {
    country: 'Czechia - CZ (061)',
    city: 'České Budějovice',
    name: 'Oddělení CÚ Právních a správních činností',
  },
  CZ520200: {
    country: 'Czechia - CZ (061)',
    city: 'České Budějovice',
    name: 'Odbor CÚ Celní, České Budějovice',
  },
  CZ520201: {
    country: 'Czechia - CZ (061)',
    city: 'České Budějovice',
    name: 'Oddělení CÚ Celní, České Budějovice',
  },
  CZ520203: {
    country: 'Czechia - CZ (061)',
    city: 'Tábor',
    name: 'Oddělení CÚ Celní, Tábor',
  },
  CZ520300: {
    country: 'Czechia - CZ (061)',
    city: 'České Budějovice',
    name: 'Odbor CÚ Daňový, České Budějovice',
  },
  CZ520301: {
    country: 'Czechia - CZ (061)',
    city: 'České Budějovice',
    name: 'Oddělení CÚ Daňové, České Budějovice',
  },
  CZ520302: {
    country: 'Czechia - CZ (061)',
    city: 'Jindřichův Hradec',
    name: 'Oddělení CÚ Daňové, Jindřichův Hradec',
  },
  CZ520304: {
    country: 'Czechia - CZ (061)',
    city: 'Tábor',
    name: 'Oddělení CÚ Daňové, Tábor',
  },
  CZ529053: {
    country: 'Czechia - CZ (061)',
    city: 'České Budějovice',
    name: 'Referát CÚ Souborné jistoty, České Budějovice',
  },
  CZ529054: {
    country: 'Czechia - CZ (061)',
    city: 'České Budějovice',
    name: 'Referát CÚ AEO, APEO a EORI, České Budějovice',
  },
  CZ530000: {
    country: 'Czechia - CZ (061)',
    city: 'Brno',
    name: 'Celní úřad pro Jihomoravský kraj, Brno',
  },
  CZ530100: {
    country: 'Czechia - CZ (061)',
    city: 'Brno-Slatina',
    name: 'Odbor CÚ Právní, Brno-Slatina',
  },
  CZ530101: {
    country: 'Czechia - CZ (061)',
    city: 'Brno-Slatina',
    name: 'Oddělení CÚ Právních a správních činností, Brno',
  },
  CZ530200: {
    country: 'Czechia - CZ (061)',
    city: 'Brno-Slatina',
    name: 'Odbor CÚ Celní, Brno-Slatina',
  },
  CZ530201: {
    country: 'Czechia - CZ (061)',
    city: 'Brno-Slatina',
    name: 'Oddělení CÚ Celní, Brno-Slatina',
  },
  CZ530202: {
    country: 'Czechia - CZ (061)',
    city: 'Blansko',
    name: 'Oddělení CÚ Celní, Blansko',
  },
  CZ530204: {
    country: 'Czechia - CZ (061)',
    city: 'Lanžhot',
    name: 'Oddělení CÚ Celní, Lanžhot',
  },
  CZ530220: {
    country: 'Czechia - CZ (061)',
    city: 'Brno - Slatina',
    name: 'Oddělení CÚ Podpory celního řízení',
  },
  CZ530299: {
    country: 'Czechia - CZ (061)',
    city: 'Brno-Tuřany',
    name: 'Oddělení CÚ Letiště Tuřany, Brno-Tuřany',
  },
  CZ530300: {
    country: 'Czechia - CZ (061)',
    city: 'Brno-Slatina',
    name: 'Odbor CÚ Daňový, Brno-Slatina',
  },
  CZ530301: {
    country: 'Czechia - CZ (061)',
    city: 'Brno-Slatina',
    name: 'Oddělení CÚ Daňové, Brno-Slatina',
  },
  CZ530302: {
    country: 'Czechia - CZ (061)',
    city: 'Blansko',
    name: 'Oddělení CÚ Daňové, Blansko',
  },
  CZ530303: {
    country: 'Czechia - CZ (061)',
    city: 'Břeclav',
    name: 'Oddělení CÚ Daňové, Břeclav',
  },
  CZ530306: {
    country: 'Czechia - CZ (061)',
    city: 'Znojmo',
    name: 'Oddělení CÚ Daňové, Znojmo',
  },
  CZ530307: {
    country: 'Czechia - CZ (061)',
    city: 'Brno - Slatina',
    name: 'Oddělení CÚ Daňové, Brno - Slatina',
  },
  CZ530330: {
    country: 'Czechia - CZ (061)',
    city: 'Břeclav',
    name: 'Oddělení CÚ Podpory daňového řízení a vyhledává',
  },
  CZ540000: {
    country: 'Czechia - CZ (061)',
    city: 'Karlovy Vary',
    name: 'Celní úřad pro Karlovarský kraj, Karlovy Vary',
  },
  CZ540100: {
    country: 'Czechia - CZ (061)',
    city: 'Karlovy Vary',
    name: 'Odbor CÚ Právní, Karlovy Vary',
  },
  CZ540101: {
    country: 'Czechia - CZ (061)',
    city: 'Karlovy Vary',
    name: 'Oddělení CÚ Právních a správních činností, Karl',
  },
  CZ540200: {
    country: 'Czechia - CZ (061)',
    city: 'Karlovy Vary',
    name: 'Odbor CÚ Celní, Karlovy Vary',
  },
  CZ540201: {
    country: 'Czechia - CZ (061)',
    city: 'Karlovy Vary',
    name: 'Oddělení CÚ Celní, Karlovy Vary',
  },
  CZ540299: {
    country: 'Czechia - CZ (061)',
    city: 'Karlovy Vary',
    name: 'Oddělení CÚ Letiště, Karlovy Vary',
  },
  CZ540301: {
    country: 'Czechia - CZ (061)',
    city: 'Karlovy Vary',
    name: 'Oddělení CÚ Daňové, Karlovy Vary',
  },
  CZ550000: {
    country: 'Czechia - CZ (061)',
    city: 'Hradec Králové',
    name: 'Celní úřad pro Královéhradecký kraj',
  },
  CZ550100: {
    country: 'Czechia - CZ (061)',
    city: 'Hradec Králové',
    name: 'Odbor CÚ Právní, Hradec Králové',
  },
  CZ550101: {
    country: 'Czechia - CZ (061)',
    city: 'Hradec Králové',
    name: 'Oddělení CÚ Právních a správních činností, Hrad',
  },
  CZ550200: {
    country: 'Czechia - CZ (061)',
    city: 'Hradec Králové',
    name: 'Odbor CÚ Celní, Hradec Králové',
  },
  CZ550201: {
    country: 'Czechia - CZ (061)',
    city: 'Hradec Králové',
    name: 'Oddělení CÚ Celní, Hradec Králové',
  },
  CZ550203: {
    country: 'Czechia - CZ (061)',
    city: 'Náchod',
    name: 'Oddělení CÚ Celní, Náchod',
  },
  CZ550300: {
    country: 'Czechia - CZ (061)',
    city: 'Hradec Králové',
    name: 'Odbor CÚ Daňový, Hradec Králové',
  },
  CZ550301: {
    country: 'Czechia - CZ (061)',
    city: 'Hradec Králové',
    name: 'Oddělení CÚ Daňové, Hradec Králové',
  },
  CZ550303: {
    country: 'Czechia - CZ (061)',
    city: 'Náchod',
    name: 'Oddělení CÚ Daňové, Náchod',
  },
  CZ560000: {
    country: 'Czechia - CZ (061)',
    city: 'Liberec 6',
    name: 'Celní úřad pro Liberecký kraj, Liberec 6',
  },
  CZ560100: {
    country: 'Czechia - CZ (061)',
    city: 'Liberec 6',
    name: 'Odbor CÚ Právní, Liberec 6',
  },
  CZ560101: {
    country: 'Czechia - CZ (061)',
    city: 'Liberec 6',
    name: 'Oddělení CÚ Právních a správních činností, Libe',
  },
  CZ560201: {
    country: 'Czechia - CZ (061)',
    city: 'Liberec 6',
    name: 'Oddělení CÚ Celní, Liberec 6',
  },
  CZ560301: {
    country: 'Czechia - CZ (061)',
    city: 'Liberec 6',
    name: 'Oddělení CÚ Daňové, Liberec 6',
  },
  CZ570000: {
    country: 'Czechia - CZ (061)',
    city: 'Ostrava',
    name: 'Celní úřad pro Moravskoslezský kraj, Ostrava',
  },
  CZ570100: {
    country: 'Czechia - CZ (061)',
    city: 'Ostrava',
    name: 'Odbor CÚ Právní, Ostrava',
  },
  CZ570101: {
    country: 'Czechia - CZ (061)',
    city: 'Ostrava',
    name: 'Oddělení CÚ Právních a správních činností, Ostr',
  },
  CZ570200: {
    country: 'Czechia - CZ (061)',
    city: 'Paskov',
    name: 'Odbor CÚ Celní, Paskov',
  },
  CZ570201: {
    country: 'Czechia - CZ (061)',
    city: 'Paskov',
    name: 'Oddělení CÚ Celní, Paskov',
  },
  CZ570204: {
    country: 'Czechia - CZ (061)',
    city: 'Opava',
    name: 'Oddělení CÚ Celní, Opava',
  },
  CZ570205: {
    country: 'Czechia - CZ (061)',
    city: 'Třinec',
    name: 'Oddělení CÚ Celní, Třinec',
  },
  CZ570220: {
    country: 'Czechia - CZ (061)',
    city: 'Paskov',
    name: 'Oddělení CÚ Podpory celního řízení, Paskov',
  },
  CZ570299: {
    country: 'Czechia - CZ (061)',
    city: 'Mošnov',
    name: 'Oddělení CÚ Letiště, Mošnov',
  },
  CZ570300: {
    country: 'Czechia - CZ (061)',
    city: 'Ostrava',
    name: 'Odbor CÚ Daňový, Ostrava',
  },
  CZ570301: {
    country: 'Czechia - CZ (061)',
    city: 'Ostrava',
    name: 'Oddělení CÚ Daňové, Ostrava',
  },
  CZ570302: {
    country: 'Czechia - CZ (061)',
    city: 'Frýdek-Místek',
    name: 'Oddělení CÚ Daňové, Frýdek-Místek',
  },
  CZ570304: {
    country: 'Czechia - CZ (061)',
    city: 'Mošnov',
    name: 'Oddělení CÚ Daňové, Mošnov',
  },
  CZ570305: {
    country: 'Czechia - CZ (061)',
    city: 'Opava',
    name: 'Oddělení CÚ Daňové, Opava',
  },
  CZ580000: {
    country: 'Czechia - CZ (061)',
    city: 'Olomouc',
    name: 'pro Olomoucký kraj, Olomouc Celní úřad',
  },
  CZ580100: {
    country: 'Czechia - CZ (061)',
    city: 'Olomouc',
    name: 'Odbor CÚ Právní, Olomouc',
  },
  CZ580101: {
    country: 'Czechia - CZ (061)',
    city: 'Olomouc',
    name: 'Oddělení CÚ Právních a správních činností, Olom',
  },
  CZ580200: {
    country: 'Czechia - CZ (061)',
    city: 'Olomouc',
    name: 'Odbor CÚ Celní, Olomouc',
  },
  CZ580201: {
    country: 'Czechia - CZ (061)',
    city: 'Olomouc',
    name: 'Oddělení CÚ Celní, Olomouc',
  },
  CZ580202: {
    country: 'Czechia - CZ (061)',
    city: 'Přerov',
    name: 'Oddělení CÚ Celní, Přerov',
  },
  CZ580203: {
    country: 'Czechia - CZ (061)',
    city: 'Šumperk',
    name: 'Oddělení CÚ Celní, Šumperk',
  },
  CZ580300: {
    country: 'Czechia - CZ (061)',
    city: 'Olomouc',
    name: 'Odbor CÚ Daňový, Olomouc',
  },
  CZ580301: {
    country: 'Czechia - CZ (061)',
    city: 'Olomouc',
    name: 'Oddělení CÚ Daňové, Olomouc',
  },
  CZ580302: {
    country: 'Czechia - CZ (061)',
    city: 'Přerov',
    name: 'Oddělení CÚ Daňové, Přerov',
  },
  CZ580303: {
    country: 'Czechia - CZ (061)',
    city: 'Šumperk',
    name: 'Oddělení CÚ Daňové, Šumperk',
  },
  CZ590000: {
    country: 'Czechia - CZ (061)',
    city: 'Pardubice',
    name: 'Celní úřad pro Pardubický kraj, Pardubice',
  },
  CZ590100: {
    country: 'Czechia - CZ (061)',
    city: 'Pardubice',
    name: 'Odbor CÚ Právní, Pardubice',
  },
  CZ590101: {
    country: 'Czechia - CZ (061)',
    city: 'Pardubice',
    name: 'Oddělení CÚ Právních a správních činností, Pard',
  },
  CZ590200: {
    country: 'Czechia - CZ (061)',
    city: 'Pardubice',
    name: 'Odbor CÚ Celní, Pardubice',
  },
  CZ590201: {
    country: 'Czechia - CZ (061)',
    city: 'Pardubice',
    name: 'Oddělení CÚ Celní, Pardubice',
  },
  CZ590202: {
    country: 'Czechia - CZ (061)',
    city: 'Česká Třebová',
    name: 'Oddělení CÚ Celní, Česká Třebová',
  },
  CZ590299: {
    country: 'Czechia - CZ (061)',
    city: 'Popkovice',
    name: 'Oddělení CÚ Letiště, Popkovice',
  },
  CZ590300: {
    country: 'Czechia - CZ (061)',
    city: 'Pardubice',
    name: 'Odbor CÚ Daňový, Pardubice',
  },
  CZ590301: {
    country: 'Czechia - CZ (061)',
    city: 'Pardubice',
    name: 'Oddělení CÚ Daňové, Pardubice',
  },
  CZ590302: {
    country: 'Czechia - CZ (061)',
    city: 'Česká Třebová',
    name: 'Oddělení CÚ Daňové, Česká Třebová',
  },
  CZ600000: {
    country: 'Czechia - CZ (061)',
    city: 'Plzeň 1',
    name: 'Celní úřad pro Plzeňský kraj, Plzeň 1',
  },
  CZ600100: {
    country: 'Czechia - CZ (061)',
    city: 'Plzeň 1',
    name: 'Odbor CÚ Právní, Plzeň 1',
  },
  CZ600101: {
    country: 'Czechia - CZ (061)',
    city: 'Plzeň 1',
    name: 'Oddělení CÚ Právních a správních činností',
  },
  CZ600201: {
    country: 'Czechia - CZ (061)',
    city: 'Plzeň',
    name: 'Oddělení CÚ Celní, Plzeň',
  },
  CZ600300: {
    country: 'Czechia - CZ (061)',
    city: 'Plzeň',
    name: 'Odbor CÚ Daňový, Plzeň',
  },
  CZ600301: {
    country: 'Czechia - CZ (061)',
    city: 'Plzeň',
    name: 'Oddělení CÚ Daňové, Plzeň',
  },
  CZ600302: {
    country: 'Czechia - CZ (061)',
    city: 'Draženov',
    name: 'Oddělení CÚ Daňové, Draženov',
  },
  CZ610000: {
    country: 'Czechia - CZ (061)',
    city: 'Praha 1',
    name: 'Celní úřad pro Středočeský kraj, Praha 1',
  },
  CZ610100: {
    country: 'Czechia - CZ (061)',
    city: 'Praha 1',
    name: 'Odbor CÚ Právní, Praha 1',
  },
  CZ610101: {
    country: 'Czechia - CZ (061)',
    city: 'Praha 1',
    name: 'Oddělení CÚ Právních a správních činností, Prah',
  },
  CZ610200: {
    country: 'Czechia - CZ (061)',
    city: 'Zdiby',
    name: 'Odbor CÚ Celní, Zdiby',
  },
  CZ610201: {
    country: 'Czechia - CZ (061)',
    city: 'Zdiby',
    name: 'Oddělení CÚ Celní, Zdiby',
  },
  CZ610202: {
    country: 'Czechia - CZ (061)',
    city: 'Benešov u Prahy',
    name: 'Oddělení CÚ Celní, Benešov u Prahy',
  },
  CZ610203: {
    country: 'Czechia - CZ (061)',
    city: 'Kladno',
    name: 'Oddělení CÚ Celní, Kladno',
  },
  CZ610204: {
    country: 'Czechia - CZ (061)',
    city: 'Kolín 4',
    name: 'Oddělení CÚ Celní, Kolín 4',
  },
  CZ610205: {
    country: 'Czechia - CZ (061)',
    city: 'Kosmonosy',
    name: 'Oddělení CÚ Celní, Kosmonosy',
  },
  CZ610206: {
    country: 'Czechia - CZ (061)',
    city: 'Mělník',
    name: 'Oddělení CÚ Celní, Mělník',
  },
  CZ610207: {
    country: 'Czechia - CZ (061)',
    city: 'Říčany',
    name: 'Oddělení CÚ Celní, Říčany',
  },
  CZ610208: {
    country: 'Czechia - CZ (061)',
    city: 'Rudná',
    name: 'Oddělení CÚ Celní, Rudná',
  },
  CZ610220: {
    country: 'Czechia - CZ (061)',
    city: 'Zdiby',
    name: 'Oddělení CÚ Podpory celního řízení, Zdiby',
  },
  CZ610300: {
    country: 'Czechia - CZ (061)',
    city: 'Kladno',
    name: 'Odbor CÚ Daňový, Kladno',
  },
  CZ610301: {
    country: 'Czechia - CZ (061)',
    city: 'Kladno',
    name: 'Oddělení CÚ Daňové, Kladno',
  },
  CZ610302: {
    country: 'Czechia - CZ (061)',
    city: 'Benešov u Prahy',
    name: 'Oddělení CÚ Daňové, Benešov u Prahy',
  },
  CZ610303: {
    country: 'Czechia - CZ (061)',
    city: 'Kolín 4',
    name: 'Oddělení CÚ Daňové, Kolín 4',
  },
  CZ610304: {
    country: 'Czechia - CZ (061)',
    city: 'Kosmonosy',
    name: 'Oddělení CÚ Daňové, Kosmonosy',
  },
  CZ610305: {
    country: 'Czechia - CZ (061)',
    city: 'Kutná Hora',
    name: 'Oddělení CÚ Daňové, Kutná Hora',
  },
  CZ610306: {
    country: 'Czechia - CZ (061)',
    city: 'Mělník',
    name: 'Oddělení CÚ Daňové, Mělník',
  },
  CZ610399: {
    country: 'Czechia - CZ (061)',
    city: 'Kutná Hora',
    name: 'Oddělení CÚ TNKP - Kutná Hora, Kutná Hora',
  },
  CZ620000: {
    country: 'Czechia - CZ (061)',
    city: 'Ústí nad Labem 11',
    name: 'Celní úřad pro Ústecký kraj, Ústí nad Labem 11',
  },
  CZ620100: {
    country: 'Czechia - CZ (061)',
    city: 'Ústí nad Labem 11',
    name: 'Odbor CÚ Právní, Ústí nad Labem 11',
  },
  CZ620101: {
    country: 'Czechia - CZ (061)',
    city: 'Ústí nad Labem 11',
    name: 'Oddělení CÚ Právních a správních činností',
  },
  CZ620200: {
    country: 'Czechia - CZ (061)',
    city: 'Ústí nad Labem',
    name: 'Odbor CÚ Celní, Ústí nad Labem',
  },
  CZ620201: {
    country: 'Czechia - CZ (061)',
    city: 'Ústí nad Labem',
    name: 'Oddělení CÚ Celní, Ústí nad Labem',
  },
  CZ620202: {
    country: 'Czechia - CZ (061)',
    city: 'Chomutov',
    name: 'Oddělení CÚ Celní, Chomutov',
  },
  CZ620300: {
    country: 'Czechia - CZ (061)',
    city: 'Ústí nad Labem',
    name: 'Odbor CÚ Daňový, Ústí nad Labem',
  },
  CZ620301: {
    country: 'Czechia - CZ (061)',
    city: 'Ústí nad Labem 11',
    name: 'Oddělení CÚ Daňové, Ústí nad Labem 11',
  },
  CZ620302: {
    country: 'Czechia - CZ (061)',
    city: 'Chomutov',
    name: 'Oddělení CÚ Daňové, Chomutov',
  },
  CZ630000: {
    country: 'Czechia - CZ (061)',
    city: 'Střítež u Jihlavy',
    name: 'Celní úřad pro Kraj Vysočina',
  },
  CZ630100: {
    country: 'Czechia - CZ (061)',
    city: 'Střítež u Jihlavy',
    name: 'Odbor CÚ Právní, Střítež u Jihlavy',
  },
  CZ630101: {
    country: 'Czechia - CZ (061)',
    city: 'Střítež u Jihlavy',
    name: 'Oddělení CÚ Právních a správních činností, Stří',
  },
  CZ630200: {
    country: 'Czechia - CZ (061)',
    city: 'Střítež u Jihlavy',
    name: 'Odbor CÚ Celní, Střítež u Jihlavy',
  },
  CZ630201: {
    country: 'Czechia - CZ (061)',
    city: 'Střítež u Jihlavy',
    name: 'Oddělení CÚ Celní, Střítež u Jihlavy',
  },
  CZ630203: {
    country: 'Czechia - CZ (061)',
    city: 'Žďár nad Sázavou',
    name: 'Oddělení CÚ Celní, Žďár nad Sázavou',
  },
  CZ630300: {
    country: 'Czechia - CZ (061)',
    city: 'Střítež u Jihlavy',
    name: 'Odbor CÚ Daňový, Střítež u Jihlavy',
  },
  CZ630301: {
    country: 'Czechia - CZ (061)',
    city: 'Střítež u Jihlavy',
    name: 'Oddělení CÚ Daňové, Střítež u Jihlavy',
  },
  CZ630303: {
    country: 'Czechia - CZ (061)',
    city: 'Třebíč',
    name: 'Oddělení CÚ Daňové, Třebíč',
  },
  CZ630304: {
    country: 'Czechia - CZ (061)',
    city: 'Žďár nad Sázavou',
    name: 'Oddělení CÚ Daňové, Žďár nad Sázavou',
  },
  CZ640000: {
    country: 'Czechia - CZ (061)',
    city: 'Zlín',
    name: 'Celní úřad pro Zlínský kraj, Zlín',
  },
  CZ640100: {
    country: 'Czechia - CZ (061)',
    city: 'Zlín',
    name: 'Odbor CÚ Právní, Zlín',
  },
  CZ640101: {
    country: 'Czechia - CZ (061)',
    city: 'Zlín',
    name: 'Oddělení CÚ Právních a správních činností, Zlín',
  },
  CZ640200: {
    country: 'Czechia - CZ (061)',
    city: 'Zlín',
    name: 'Odbor CÚ Celní, Zlín',
  },
  CZ640201: {
    country: 'Czechia - CZ (061)',
    city: 'Lípa',
    name: 'Oddělení CÚ Celní, Lípa',
  },
  CZ640202: {
    country: 'Czechia - CZ (061)',
    city: 'Napajedla',
    name: 'Oddělení CÚ Celní, Napajedla',
  },
  CZ640203: {
    country: 'Czechia - CZ (061)',
    city: 'Uherské Hradiště',
    name: 'Oddělení CÚ Celní, Uherské Hradiště',
  },
  CZ640204: {
    country: 'Czechia - CZ (061)',
    city: 'Valašské Meziříčí',
    name: 'Oddělení CÚ Celní, Valašské Meziříčí',
  },
  CZ640300: {
    country: 'Czechia - CZ (061)',
    city: 'Zlín',
    name: 'Odbor CÚ Daňový, Zlín',
  },
  CZ640301: {
    country: 'Czechia - CZ (061)',
    city: 'Zlín',
    name: 'Oddělení CÚ Daňové, Zlín',
  },
  CZ640302: {
    country: 'Czechia - CZ (061)',
    city: 'Uherské Hradiště',
    name: 'Oddělení CÚ Daňové, Uherské Hradiště',
  },
  CZ640303: {
    country: 'Czechia - CZ (061)',
    city: 'Valašské Meziříčí',
    name: 'Oddělení CÚ Daňové, Valašské Meziříčí',
  },
  CZ650000: {
    country: 'Czechia - CZ (061)',
    city: 'Praha 6',
    name: 'Celní úřad Praha Ruzyně, Praha 6',
  },
  CZ650100: {
    country: 'Czechia - CZ (061)',
    city: 'Praha 6',
    name: 'Odbor CÚ Právní, Praha 6',
  },
  CZ650101: {
    country: 'Czechia - CZ (061)',
    city: 'Praha 6',
    name: 'Oddělení CÚ Právních a správních činností, Prah',
  },
  CZ650200: {
    country: 'Czechia - CZ (061)',
    city: 'Praha 6',
    name: 'Odbor CÚ Celní, Praha 6',
  },
  CZ650201: {
    country: 'Czechia - CZ (061)',
    city: 'Praha 6',
    name: 'Oddělení CÚ Celní, Praha 6',
  },
  CZ650202: {
    country: 'Czechia - CZ (061)',
    city: 'Praha 10',
    name: 'Oddělení CÚ Celní - Pošta Praha, Praha 10',
  },
  CZ650299: {
    country: 'Czechia - CZ (061)',
    city: 'Praha 6',
    name: 'Oddělení CÚ Letiště, Praha 6',
  },
  CZ650301: {
    country: 'Czechia - CZ (061)',
    city: 'Praha 6',
    name: 'Oddělení CÚ Daňové, Praha 6',
  },
  CZ900000: {
    country: 'Czechia - CZ (061)',
    city: 'Praha 4',
    name: 'GENERÁLNÍ ŘEDITELSTVÍ CEL, Praha 4',
  },
  CZ900020: {
    country: 'Czechia - CZ (061)',
    city: 'Praha 4',
    name: 'Sekce GŘC 02 - Výkonu služby, Praha 4',
  },
  CZ921000: {
    country: 'Czechia - CZ (061)',
    city: 'Praha 4',
    name: 'Odbor GŘC 21 - CELNÍ, Praha 4',
  },
  DE001482: {
    country: 'Germany - DE (004)',
    city: 'Hamburg',
    name: 'ITZBund Dienstsitz Hamburg',
  },
  DE001488: {
    country: 'Germany - DE (004)',
    city: 'Stuttgart',
    name: 'HZA Stuttgart - SG B - Zentrales Verbrauchsteuer-Verbindungsbüro ELO',
  },
  DE001501: {
    country: 'Germany - DE (004)',
    city: 'München',
    name: 'Generalzolldirektion Zentralstelle Gewerblicher Rechtsschutz',
  },
  DE001502: {
    country: 'Germany - DE (004)',
    city: 'Berlin',
    name: 'Zollfahndungsamt Berlin-Brandenburg',
  },
  DE001503: {
    country: 'Germany - DE (004)',
    city: 'Bremen',
    name: 'Zollfahndungsamt Hannover Dienstsitz Bremen',
  },
  DE001504: {
    country: 'Germany - DE (004)',
    city: 'Bremerhaven',
    name: 'Zollfahndungsamt Hamburg Dienstsitz Bremerhaven',
  },
  DE001505: {
    country: 'Germany - DE (004)',
    city: 'Essen',
    name: 'Zollfahndungsamt Essen',
  },
  DE001508: {
    country: 'Germany - DE (004)',
    city: 'Frankfurt am Main',
    name: 'Zollfahndungsamt Frankfurt am Main',
  },
  DE001509: {
    country: 'Germany - DE (004)',
    city: 'Kassel',
    name: 'Zollfahndungsamt Frankfurt am Main Dienstsitz Kassel',
  },
  DE001510: {
    country: 'Germany - DE (004)',
    city: 'Freiburg im Breisgau',
    name: 'Zollfahndungsamt Stuttgart Dienstsitz Freiburg i. Br.',
  },
  DE001512: {
    country: 'Germany - DE (004)',
    city: 'Radolfzell am Bodensee',
    name: 'Zollfahndungsamt Stuttgart Dienstsitz Radolfzell',
  },
  DE001513: {
    country: 'Germany - DE (004)',
    city: 'Hamburg',
    name: 'Zollfahndungsamt Hamburg',
  },
  DE001514: {
    country: 'Germany - DE (004)',
    city: 'Hannover',
    name: 'Zollfahndungsamt Hannover',
  },
  DE001516: {
    country: 'Germany - DE (004)',
    city: 'Karlsruhe',
    name: 'Zollfahndungsamt Stuttgart Dienstsitz Karlsruhe',
  },
  DE001518: {
    country: 'Germany - DE (004)',
    city: 'Kiel',
    name: 'Zollfahndungsamt Hamburg Dienstsitz Kiel',
  },
  DE001522: {
    country: 'Germany - DE (004)',
    city: 'Kaiserslautern',
    name: 'Zollfahndungsamt Frankfurt am Main Dienstsitz Kaiserslautern',
  },
  DE001524: {
    country: 'Germany - DE (004)',
    city: 'Köln',
    name: 'Zollfahndungsamt Essen Dienstsitz Köln',
  },
  DE001525: {
    country: 'Germany - DE (004)',
    city: 'Aachen',
    name: 'Zollfahndungsamt Essen Dienstsitz Aachen',
  },
  DE001526: {
    country: 'Germany - DE (004)',
    city: 'München',
    name: 'Zollfahndungsamt München',
  },
  DE001527: {
    country: 'Germany - DE (004)',
    city: 'Lindau (Bodensee)',
    name: 'Zollfahndungsamt München Dienstsitz Lindau',
  },
  DE001528: {
    country: 'Germany - DE (004)',
    city: 'Münster',
    name: 'Zollfahndungsamt Essen Dienstsitz Münster',
  },
  DE001529: {
    country: 'Germany - DE (004)',
    city: 'Bielefeld',
    name: 'Zollfahndungsamt Hannover Dienstsitz Bielefeld',
  },
  DE001531: {
    country: 'Germany - DE (004)',
    city: 'Nürnberg',
    name: 'Zollfahndungsamt München Dienstsitz Nürnberg',
  },
  DE001534: {
    country: 'Germany - DE (004)',
    city: 'Stuttgart',
    name: 'Zollfahndungsamt Stuttgart',
  },
  DE001535: {
    country: 'Germany - DE (004)',
    city: 'Düsseldorf',
    name: 'Zollfahndungsamt Essen Dienstort Flughafen Düsseldorf',
  },
  DE001536: {
    country: 'Germany - DE (004)',
    city: 'Frankfurt am Main',
    name: 'Zollfahndungsamt Frankfurt am Main Dienstort Flughafengebäude',
  },
  DE001537: {
    country: 'Germany - DE (004)',
    city: 'Saarbrücken',
    name: 'Zollfahndungsamt Frankfurt am Main Dienstsitz Saarbrücken',
  },
  DE001541: {
    country: 'Germany - DE (004)',
    city: 'Nordhorn',
    name: 'Zollfahndungsamt Essen Dienstsitz Nordhorn',
  },
  DE001542: {
    country: 'Germany - DE (004)',
    city: 'Kleve',
    name: 'Zollfahndungsamt Essen Dienstsitz Kleve',
  },
  DE001546: {
    country: 'Germany - DE (004)',
    city: 'Köln',
    name: 'Zollfahndungsamt Essen Dienstort Flughafen Köln',
  },
  DE001549: {
    country: 'Germany - DE (004)',
    city: 'München-Flughafen',
    name: 'Zollfahndungsamt München Dienstort München-Flughafen',
  },
  DE001555: {
    country: 'Germany - DE (004)',
    city: 'Dresden',
    name: 'Zollfahndungsamt Dresden',
  },
  DE001556: {
    country: 'Germany - DE (004)',
    city: 'Leipzig',
    name: 'Zollfahndungsamt Dresden Dienstsitz Leipzig',
  },
  DE001558: {
    country: 'Germany - DE (004)',
    city: 'Magdeburg',
    name: 'Zollfahndungsamt Hannover Dienstsitz Magdeburg',
  },
  DE001560: {
    country: 'Germany - DE (004)',
    city: 'Teltow',
    name: 'Zollfahndungsamt Berlin-Brandenburg Dienstsitz Teltow',
  },
  DE001561: {
    country: 'Germany - DE (004)',
    city: 'Frankfurt (Oder)',
    name: 'Zollfahndungsamt Berlin-Brandenburg Dienstsitz Frankfurt (Oder)',
  },
  DE001562: {
    country: 'Germany - DE (004)',
    city: 'Rostock',
    name: 'Zollfahndungsamt Hamburg Dienstsitz Rostock',
  },
  DE001565: {
    country: 'Germany - DE (004)',
    city: 'Görlitz',
    name: 'Zollfahndungsamt Dresden Dienstsitz Görlitz',
  },
  DE001566: {
    country: 'Germany - DE (004)',
    city: 'Dienstsitz Cottbus',
    name: 'Zollfahndungsamt Berlin-Brandenburg Dienstsitz Cottbus',
  },
  DE001570: {
    country: 'Germany - DE (004)',
    city: 'Weiden i.d.OPf.',
    name: 'Zollfahndungsamt München Dienstsitz Weiden',
  },
  DE001578: {
    country: 'Germany - DE (004)',
    city: 'Nadrensee',
    name: 'Zollfahndungsamt Berlin-Brandenburg Dienstsitz Pomellen',
  },
  DE001710: {
    country: 'Germany - DE (004)',
    city: 'Dresden',
    name: 'Generalzolldirektion DO Dresden - Stammdatenmanagement',
  },
  DE001800: {
    country: 'Germany - DE (004)',
    city: 'Bonn',
    name: 'Generalzolldirektion Bonn',
  },
  DE002101: {
    country: 'Germany - DE (004)',
    city: 'Berlin',
    name: 'Zollamt Berlin-Marzahn',
  },
  DE002150: {
    country: 'Germany - DE (004)',
    city: 'Berlin',
    name: 'Hauptzollamt Berlin',
  },
  DE002151: {
    country: 'Germany - DE (004)',
    city: 'Berlin',
    name: 'Zollamt Schöneberg',
  },
  DE002152: {
    country: 'Germany - DE (004)',
    city: 'Berlin',
    name: 'Zollamt Berlin-Dreilinden',
  },
  DE002170: {
    country: 'Germany - DE (004)',
    city: 'Berlin',
    name: 'Hauptzollamt Berlin Dienstort Kufsteiner Straße',
  },
  DE002181: {
    country: 'Germany - DE (004)',
    city: 'Berlin',
    name: 'Abfertigungsstelle Berlin-Messe',
  },
  DE002300: {
    country: 'Germany - DE (004)',
    city: 'Bremen',
    name: 'Hauptzollamt Bremen',
  },
  DE002301: {
    country: 'Germany - DE (004)',
    city: 'Bremen',
    name: 'Zollamt Bremen-Flughafen',
  },
  DE002307: {
    country: 'Germany - DE (004)',
    city: 'Bremen',
    name: 'Hauptzollamt Bremen, Kontrolleinheit Flughafen Bremen',
  },
  DE002312: {
    country: 'Germany - DE (004)',
    city: 'Bremen',
    name: 'Hauptzollamt Bremen Dienstort Südweststraße',
  },
  DE002321: {
    country: 'Germany - DE (004)',
    city: 'Bremen',
    name: 'Hauptzollamt Bremen Dienstort Große Sortillienstraße 60',
  },
  DE002325: {
    country: 'Germany - DE (004)',
    city: 'Bremen',
    name: 'Zollamt Bremen',
  },
  DE002326: {
    country: 'Germany - DE (004)',
    city: 'Bremen',
    name: 'Zollamt Bremen Dienstort Senator-Harmssen-Straße',
  },
  DE002452: {
    country: 'Germany - DE (004)',
    city: 'Bremerhaven',
    name: 'Zollamt Bremerhaven',
  },
  DE002457: {
    country: 'Germany - DE (004)',
    city: 'Bremerhaven',
    name: 'Zollamt Bremerhaven Dienstort Senator-Borttscheller-Straße 15/15a',
  },
  DE002458: {
    country: 'Germany - DE (004)',
    city: 'Bremerhaven',
    name: 'Zollamt Bremerhaven DO Senator-Borttscheller-Straße 8',
  },
  DE002600: {
    country: 'Germany - DE (004)',
    city: 'Düsseldorf',
    name: 'Hauptzollamt Düsseldorf',
  },
  DE002601: {
    country: 'Germany - DE (004)',
    city: 'Düsseldorf',
    name: 'Zollamt Flughafen',
  },
  DE002604: {
    country: 'Germany - DE (004)',
    city: 'Düsseldorf',
    name: 'Zollamt Düsseldorf Nord',
  },
  DE002605: {
    country: 'Germany - DE (004)',
    city: 'Düsseldorf',
    name: 'Hauptzollamt Düsseldorf, KE Flughafen Düsseldorf',
  },
  DE002607: {
    country: 'Germany - DE (004)',
    city: 'Düsseldorf',
    name: 'Zollamt Düsseldorf Reisholz',
  },
  DE002631: {
    country: 'Germany - DE (004)',
    city: 'Düsseldorf',
    name: 'Abfertigungsstelle Düsseldorf Messe',
  },
  DE002650: {
    country: 'Germany - DE (004)',
    city: 'Duisburg',
    name: 'Hauptzollamt Duisburg',
  },
  DE002651: {
    country: 'Germany - DE (004)',
    city: 'Essen',
    name: 'Zollamt Essen',
  },
  DE002656: {
    country: 'Germany - DE (004)',
    city: 'Duisburg',
    name: 'Zollamt Duisburg-Ruhrort',
  },
  DE002700: {
    country: 'Germany - DE (004)',
    city: 'Emmerich am Rhein',
    name: 'Hauptzollamt Duisburg DO Emmerich am Rhein Parkring',
  },
  DE002701: {
    country: 'Germany - DE (004)',
    city: 'Emmerich am Rhein',
    name: 'Zollamt Emmerich',
  },
  DE002702: {
    country: 'Germany - DE (004)',
    city: 'Straelen',
    name: 'Zollamt Straelen-Autobahn',
  },
  DE002704: {
    country: 'Germany - DE (004)',
    city: 'Weeze',
    name: 'Hauptzollamt Duisburg, Kontrolleinheit Flughafen Weeze',
  },
  DE002705: {
    country: 'Germany - DE (004)',
    city: 'Weeze',
    name: 'Abfertigungsstelle Weeze',
  },
  DE002900: {
    country: 'Germany - DE (004)',
    city: 'Krefeld',
    name: 'Hauptzollamt Krefeld',
  },
  DE002901: {
    country: 'Germany - DE (004)',
    city: 'Nettetal',
    name: 'Zollamt Schwanenhaus',
  },
  DE002903: {
    country: 'Germany - DE (004)',
    city: 'Krefeld',
    name: 'Zollamt Uerdingen',
  },
  DE002904: {
    country: 'Germany - DE (004)',
    city: 'Mönchengladbach',
    name: 'Zollamt Mönchengladbach',
  },
  DE002906: {
    country: 'Germany - DE (004)',
    city: 'Neuss',
    name: 'Zollamt Neuss',
  },
  DE002921: {
    country: 'Germany - DE (004)',
    city: 'Benrad-Süd',
    name: 'Hauptzollamt Krefeld FKS Krefeld',
  },
  DE002925: {
    country: 'Germany - DE (004)',
    city: 'Duisburg',
    name: 'Hauptzollamt Duisburg Dienstort Ruhrorter Straße',
  },
  DE002956: {
    country: 'Germany - DE (004)',
    city: 'Wuppertal',
    name: 'Zollamt Wuppertal',
  },
  DE003000: {
    country: 'Germany - DE (004)',
    city: 'Erfurt',
    name: 'Hauptzollamt Erfurt',
  },
  DE003002: {
    country: 'Germany - DE (004)',
    city: 'Eisenach',
    name: 'Zollamt Eisenach',
  },
  DE003003: {
    country: 'Germany - DE (004)',
    city: 'Erfurt',
    name: 'Zollamt Erfurt-Am Flughafen',
  },
  DE003004: {
    country: 'Germany - DE (004)',
    city: 'Jena',
    name: 'Zollamt Jena',
  },
  DE003009: {
    country: 'Germany - DE (004)',
    city: 'Erfurt',
    name: 'Hauptzollamt Erfurt, Kontrolleinheit Flughafen Erfurt',
  },
  DE003012: {
    country: 'Germany - DE (004)',
    city: 'Suhl',
    name: 'Zollamt Eisenach Zollservicepunkt Suhl',
  },
  DE003013: {
    country: 'Germany - DE (004)',
    city: 'Nordhausen',
    name: 'Zollamt Am Flughafen Zollservicepunkt Nordhausen',
  },
  DE003014: {
    country: 'Germany - DE (004)',
    city: 'Gera',
    name: 'Zollamt Jena Zollservicepunkt Gera',
  },
  DE003030: {
    country: 'Germany - DE (004)',
    city: 'Erfurt',
    name: 'Abfertigungsstelle Erfurt-Flughafen Luftverkehr',
  },
  DE003073: {
    country: 'Germany - DE (004)',
    city: 'Plauen',
    name: 'Hauptzollamt Erfurt Dienstort Plauen Europaratstraße 14',
  },
  DE003074: {
    country: 'Germany - DE (004)',
    city: 'Chemnitz',
    name: 'Hauptzollamt Erfurt Dienstort Chemnitz',
  },
  DE003075: {
    country: 'Germany - DE (004)',
    city: 'Gera',
    name: 'Hauptzollamt Erfurt Dienstort Gera',
  },
  DE003076: {
    country: 'Germany - DE (004)',
    city: 'Suhl',
    name: 'Hauptzollamt Erfurt Dienstort Suhl',
  },
  DE003200: {
    country: 'Germany - DE (004)',
    city: 'Darmstadt',
    name: 'Hauptzollamt Darmstadt',
  },
  DE003201: {
    country: 'Germany - DE (004)',
    city: 'Bensheim',
    name: 'Zollamt Bensheim',
  },
  DE003202: {
    country: 'Germany - DE (004)',
    city: 'Wiesbaden',
    name: 'Zollamt Wiesbaden',
  },
  DE003208: {
    country: 'Germany - DE (004)',
    city: 'Wiesbaden',
    name: 'Hauptzollamt Darmstadt Dienstort Wiesbaden',
  },
  DE003209: {
    country: 'Germany - DE (004)',
    city: 'Frankfurt am Main',
    name: 'Hauptzollamt Darmstadt Dienstort Frankfurt am Main',
  },
  DE003230: {
    country: 'Germany - DE (004)',
    city: 'Darmstadt',
    name: 'Zollamt Darmstadt',
  },
  DE003300: {
    country: 'Germany - DE (004)',
    city: 'Frankfurt am Main',
    name: 'Hauptzollamt Frankfurt am Main',
  },
  DE003302: {
    country: 'Germany - DE (004)',
    city: 'Frankfurt am Main',
    name: 'Zollamt Fracht',
  },
  DE003305: {
    country: 'Germany - DE (004)',
    city: 'Frankfurt am Main',
    name: 'Zollamt Fracht Abfertigungsstelle IPZ (Internationales Postzentrum)',
  },
  DE003306: {
    country: 'Germany - DE (004)',
    city: 'Frankfurt am Main',
    name: 'Hauptzollamt Frankfurt am Main KEFR Frankfurt am Main',
  },
  DE003307: {
    country: 'Germany - DE (004)',
    city: 'Frankfurt am Main',
    name: 'Hauptzollamt Frankfurt am Main KEF/Ü Frankfurt am Main',
  },
  DE003310: {
    country: 'Germany - DE (004)',
    city: 'Frankfurt am Main',
    name: 'Hauptzollamt Frankfurt am Main Dienstort Flughafen',
  },
  DE003311: {
    country: 'Germany - DE (004)',
    city: 'Frankfurt am Main',
    name: 'Zollamt Frankfurt am Main-Osthafen Abfertigungsstelle Messe',
  },
  DE003352: {
    country: 'Germany - DE (004)',
    city: 'Hanau',
    name: 'Zollamt Hanau',
  },
  DE003354: {
    country: 'Germany - DE (004)',
    city: 'Frankfurt am Main',
    name: 'Zollamt Höchst',
  },
  DE003356: {
    country: 'Germany - DE (004)',
    city: 'Oberursel (Taunus)',
    name: 'Zollamt Oberursel',
  },
  DE003358: {
    country: 'Germany - DE (004)',
    city: 'Frankfurt',
    name: 'Zollamt Frankfurt am Main-Osthafen',
  },
  DE003400: {
    country: 'Germany - DE (004)',
    city: 'Fulda',
    name: 'Hauptzollamt Gießen Dienstort Fulda',
  },
  DE003401: {
    country: 'Germany - DE (004)',
    city: 'Bad Hersfeld',
    name: 'Zollamt Bad Hersfeld',
  },
  DE003402: {
    country: 'Germany - DE (004)',
    city: 'Niederaula',
    name: 'Abfertigungsstelle IPZ Niederaula',
  },
  DE003430: {
    country: 'Germany - DE (004)',
    city: 'Eichenzell',
    name: 'Zollamt Fulda',
  },
  DE003450: {
    country: 'Germany - DE (004)',
    city: 'Gießen',
    name: 'Hauptzollamt Gießen',
  },
  DE003452: {
    country: 'Germany - DE (004)',
    city: 'Marburg',
    name: 'Zollamt Marburg',
  },
  DE003453: {
    country: 'Germany - DE (004)',
    city: 'Wetzlar',
    name: 'Zollamt Wetzlar',
  },
  DE003454: {
    country: 'Germany - DE (004)',
    city: 'Kassel',
    name: 'Zollamt Kassel',
  },
  DE003456: {
    country: 'Germany - DE (004)',
    city: 'Gießen',
    name: 'Hauptzollamt Gießen Dienstort An der Kaserne',
  },
  DE003460: {
    country: 'Germany - DE (004)',
    city: 'Kassel',
    name: 'Hauptzollamt Gießen Dienstort Kassel Hasselweg',
  },
  DE003463: {
    country: 'Germany - DE (004)',
    city: 'Gießen',
    name: 'Hauptzollamt Gießen Dienstort Erdkauter Weg',
  },
  DE003601: {
    country: 'Germany - DE (004)',
    city: 'Finsterwalde',
    name: 'Zollamt Finsterwalde',
  },
  DE003603: {
    country: 'Germany - DE (004)',
    city: 'Forst (Lausitz)',
    name: 'Zollamt Forst',
  },
  DE003650: {
    country: 'Germany - DE (004)',
    city: 'Frankfurt (Oder)',
    name: 'Hauptzollamt Frankfurt (Oder)',
  },
  DE003652: {
    country: 'Germany - DE (004)',
    city: 'Frankfurt (Oder)',
    name: 'Zollamt Frankfurt (Oder)-Autobahn',
  },
  DE003656: {
    country: 'Germany - DE (004)',
    city: 'Fürstenwalde',
    name: 'Zollamt Fürstenwalde',
  },
  DE003669: {
    country: 'Germany - DE (004)',
    city: 'Schwedt/Oder',
    name: 'Hauptzollamt Frankfurt (Oder) DO Schwedt/Oder Karthausstraße',
  },
  DE003670: {
    country: 'Germany - DE (004)',
    city: 'Cottbus',
    name: 'Hauptzollamt Frankfurt (Oder) DO Cottbus Karl-Liebknecht-Straße',
  },
  DE003688: {
    country: 'Germany - DE (004)',
    city: 'Schwedt/Oder',
    name: 'Zollamt Eberswalde Zollservicepunkt Schwedt/Oder',
  },
  DE003700: {
    country: 'Germany - DE (004)',
    city: 'Potsdam',
    name: 'Hauptzollamt Potsdam',
  },
  DE003701: {
    country: 'Germany - DE (004)',
    city: 'Ludwigsfelde',
    name: 'Zollamt Ludwigsfelde',
  },
  DE003702: {
    country: 'Germany - DE (004)',
    city: 'Velten',
    name: 'Zollamt Velten',
  },
  DE003703: {
    country: 'Germany - DE (004)',
    city: 'Schönefeld',
    name: 'Zollamt Flughafen Berlin Brandenburg',
  },
  DE003704: {
    country: 'Germany - DE (004)',
    city: 'Schönefeld',
    name: 'Hauptzollamt Potsdam, KE Flughafen Berlin Brandenburg',
  },
  DE003730: {
    country: 'Germany - DE (004)',
    city: 'Neuruppin',
    name: 'Abfertigungsstelle Neuruppin',
  },
  DE003731: {
    country: 'Germany - DE (004)',
    city: 'Perleberg',
    name: 'Abfertigungsstelle Perleberg',
  },
  DE003732: {
    country: 'Germany - DE (004)',
    city: 'Brandenburg an der Havel',
    name: 'Abfertigungsstelle Brandenburg',
  },
  DE003752: {
    country: 'Germany - DE (004)',
    city: 'Eberswalde',
    name: 'Zollamt Eberswalde',
  },
  DE003954: {
    country: 'Germany - DE (004)',
    city: 'Freiburg im Breisgau',
    name: 'Zollamt Freiburg',
  },
  DE003956: {
    country: 'Germany - DE (004)',
    city: 'Appenweier',
    name: 'Zollamt Appenweier',
  },
  DE003960: {
    country: 'Germany - DE (004)',
    city: 'Freiburg im Breisgau',
    name: 'Hauptzollamt Lörrach DO Freiburg i. Br. Sautierstraße 32',
  },
  DE004002: {
    country: 'Germany - DE (004)',
    city: 'Konstanz',
    name: 'Zollamt Konstanz-Güterbahnhof',
  },
  DE004005: {
    country: 'Germany - DE (004)',
    city: 'Konstanz',
    name: 'Zollamt Konstanz-Autobahn (schweiz. Gebiet)',
  },
  DE004020: {
    country: 'Germany - DE (004)',
    city: 'Konstanz',
    name: 'Hauptzollamt Singen Dienstort Konstanz Kreuzlinger Tor',
  },
  DE004045: {
    country: 'Germany - DE (004)',
    city: 'Waldshut-Tiengen',
    name: 'Hauptzollamt Singen Dienstort Waldshut-Tiengen Alfred-Nobel-Str.',
  },
  DE004050: {
    country: 'Germany - DE (004)',
    city: 'Lörrach',
    name: 'Hauptzollamt Lörrach',
  },
  DE004051: {
    country: 'Germany - DE (004)',
    city: 'Grenzach-Wyhlen',
    name: 'Zollamt Grenzacherhorn',
  },
  DE004053: {
    country: 'Germany - DE (004)',
    city: 'Lörrach',
    name: 'Zollamt Lörrach-Stetten',
  },
  DE004055: {
    country: 'Germany - DE (004)',
    city: 'Weil am Rhein',
    name: 'Zollamt Weil am Rhein-Autobahn',
  },
  DE004058: {
    country: 'Germany - DE (004)',
    city: 'Basel',
    name: 'Deutsches Zollamt Basel',
  },
  DE004062: {
    country: 'Germany - DE (004)',
    city: 'Rheinfelden (Baden)',
    name: 'Zollamt Rheinfelden-Autobahn',
  },
  DE004073: {
    country: 'Germany - DE (004)',
    city: 'Lörrach',
    name: 'Hauptzollamt Lörrach Dienstort Wiesentalstraße',
  },
  DE004081: {
    country: 'Germany - DE (004)',
    city: 'Basel',
    name: 'Abfertigungsstelle Weil am Rhein - Umschlagbahnhof',
  },
  DE004083: {
    country: 'Germany - DE (004)',
    city: 'Weil am Rhein',
    name: 'Zollamt Weil am Rhein',
  },
  DE004085: {
    country: 'Germany - DE (004)',
    city: 'Weil am Rhein',
    name: 'Deutsche Abfertigungsstelle Basler Häfen',
  },
  DE004086: {
    country: 'Germany - DE (004)',
    city: 'Weil am Rhein',
    name: 'Abfertigungsstelle Weil Rheinfelden/Rheinhafen',
  },
  DE004087: {
    country: 'Germany - DE (004)',
    city: 'Weil am Rhein',
    name: 'Abfertigungsstelle Rheinhafen',
  },
  DE004100: {
    country: 'Germany - DE (004)',
    city: 'Singen (Hohentwiel)',
    name: 'Hauptzollamt Singen',
  },
  DE004101: {
    country: 'Germany - DE (004)',
    city: 'Gottmadingen',
    name: 'Zollamt Bietingen',
  },
  DE004102: {
    country: 'Germany - DE (004)',
    city: 'Blumberg',
    name: 'Zollamt Neuhaus',
  },
  DE004103: {
    country: 'Germany - DE (004)',
    city: 'Rielasingen-Worblingen',
    name: 'Zollamt Rielasingen',
  },
  DE004105: {
    country: 'Germany - DE (004)',
    city: 'Singen',
    name: 'Zollamt Singen-Bahnhof',
  },
  DE004106: {
    country: 'Germany - DE (004)',
    city: 'Deißlingen',
    name: 'Zollamt Deißlingen',
  },
  DE004124: {
    country: 'Germany - DE (004)',
    city: 'Gottmadingen',
    name: 'Hauptzollamt Singen Dienstort Gottmadingen Zollstraße',
  },
  DE004126: {
    country: 'Germany - DE (004)',
    city: 'Tengen',
    name: 'Hauptzollamt Singen Dienstort Tengen',
  },
  DE004193: {
    country: 'Germany - DE (004)',
    city: 'Gottmadingen',
    name: 'Hauptzollamt Singen Dienstort Gottmadingen Erwin-Dietrich-Straße',
  },
  DE004201: {
    country: 'Germany - DE (004)',
    city: 'Klettgau',
    name: 'Zollamt Erzingen',
  },
  DE004203: {
    country: 'Germany - DE (004)',
    city: 'Jestetten',
    name: 'Zollamt Jestetten (schweiz. Gebiet)',
  },
  DE004204: {
    country: 'Germany - DE (004)',
    city: 'Laufenburg (Baden)',
    name: 'Zollamt Laufenburg',
  },
  DE004205: {
    country: 'Germany - DE (004)',
    city: 'Lottstetten',
    name: 'Zollamt Lottstetten',
  },
  DE004206: {
    country: 'Germany - DE (004)',
    city: 'Stühlingen',
    name: 'Zollamt Stühlingen',
  },
  DE004208: {
    country: 'Germany - DE (004)',
    city: 'Waldshut-Tiengen',
    name: 'Zollamt Waldshut',
  },
  DE004209: {
    country: 'Germany - DE (004)',
    city: 'Bad Säckingen',
    name: 'Zollamt Bad Säckingen',
  },
  DE004501: {
    country: 'Germany - DE (004)',
    city: 'Cuxhaven',
    name: 'Zollamt Cuxhaven',
  },
  DE004506: {
    country: 'Germany - DE (004)',
    city: 'Helgoland',
    name: 'Zollamt Helgoland',
  },
  DE004600: {
    country: 'Germany - DE (004)',
    city: 'Hamburg',
    name: 'Hauptzollamt Hamburg',
  },
  DE004603: {
    country: 'Germany - DE (004)',
    city: 'Hamburg',
    name: 'Zollamt Hamburg Dienstort Koreastraße',
  },
  DE004605: {
    country: 'Germany - DE (004)',
    city: 'Hamburg',
    name: 'Zollamt Hamburg Dienstort Pinkertweg',
  },
  DE004701: {
    country: 'Germany - DE (004)',
    city: 'Hamburg',
    name: 'Zollamt Hamburg-Flughafen',
  },
  DE004851: {
    country: 'Germany - DE (004)',
    city: 'Hamburg',
    name: 'Zollamt Hamburg',
  },
  DE004863: {
    country: 'Germany - DE (004)',
    city: 'Hamburg',
    name: 'Zollamt Hamburg Dienstort Indiastraße',
  },
  DE004900: {
    country: 'Germany - DE (004)',
    city: 'Braunschweig',
    name: 'Hauptzollamt Braunschweig',
  },
  DE004906: {
    country: 'Germany - DE (004)',
    city: 'Wolfsburg',
    name: 'Zollamt Wolfsburg',
  },
  DE004909: {
    country: 'Germany - DE (004)',
    city: 'Braunschweig',
    name: 'Hauptzollamt Braunschweig FKS Braunschweig',
  },
  DE004920: {
    country: 'Germany - DE (004)',
    city: 'Helmstedt',
    name: 'Hauptzollamt Braunschweig DO Helmstedt Schöninger Straße',
  },
  DE004930: {
    country: 'Germany - DE (004)',
    city: 'Braunschweig',
    name: 'Zollamt Braunschweig- Broitzem',
  },
  DE004961: {
    country: 'Germany - DE (004)',
    city: 'Helmstedt',
    name: 'Zollamt Helmstedt-Autobahn',
  },
  DE005004: {
    country: 'Germany - DE (004)',
    city: 'Emden',
    name: 'Zollamt Emden',
  },
  DE005008: {
    country: 'Germany - DE (004)',
    city: 'Papenburg',
    name: 'Zollamt Papenburg',
  },
  DE005050: {
    country: 'Germany - DE (004)',
    city: 'Göttingen',
    name: 'Hauptzollamt Braunschweig Dienstort Göttingen',
  },
  DE005052: {
    country: 'Germany - DE (004)',
    city: 'Goslar',
    name: 'Zollamt Goslar',
  },
  DE005053: {
    country: 'Germany - DE (004)',
    city: 'Hameln',
    name: 'Zollamt Hameln',
  },
  DE005055: {
    country: 'Germany - DE (004)',
    city: 'Hildesheim',
    name: 'Zollamt Hildesheim',
  },
  DE005056: {
    country: 'Germany - DE (004)',
    city: 'Holzminden',
    name: 'Abfertigungsstelle Holzminden',
  },
  DE005071: {
    country: 'Germany - DE (004)',
    city: 'Hildesheim',
    name: 'Hauptzollamt Braunschweig FKS Hildesheim',
  },
  DE005080: {
    country: 'Germany - DE (004)',
    city: 'Göttingen',
    name: 'Zollamt Göttingen',
  },
  DE005100: {
    country: 'Germany - DE (004)',
    city: 'Hannover',
    name: 'Hauptzollamt Hannover',
  },
  DE005102: {
    country: 'Germany - DE (004)',
    city: 'Hannover',
    name: 'Zollamt Hannover-Nord',
  },
  DE005103: {
    country: 'Germany - DE (004)',
    city: 'Hannover',
    name: 'Zollamt Hannover-Flughafen',
  },
  DE005104: {
    country: 'Germany - DE (004)',
    city: 'Hannover',
    name: 'Hauptzollamt Hannover, KE Flughafen Hannover Langenhagen',
  },
  DE005110: {
    country: 'Germany - DE (004)',
    city: 'Hannover',
    name: 'Zollamt Hannover Messe',
  },
  DE005111: {
    country: 'Germany - DE (004)',
    city: 'Celle',
    name: 'Zollamt Celle',
  },
  DE005202: {
    country: 'Germany - DE (004)',
    city: 'Soltau',
    name: 'Zollamt Soltau',
  },
  DE005203: {
    country: 'Germany - DE (004)',
    city: 'Stade',
    name: 'Zollamt Stade',
  },
  DE005204: {
    country: 'Germany - DE (004)',
    city: 'Verden (Aller)',
    name: 'Zollamt Verden',
  },
  DE005207: {
    country: 'Germany - DE (004)',
    city: 'Hannover',
    name: 'HZA Hannover, Verbindliche Zolltarif- und Ursprungsauskünfte',
  },
  DE005230: {
    country: 'Germany - DE (004)',
    city: 'Lüneburg',
    name: 'Zollamt Lüneburg',
  },
  DE005300: {
    country: 'Germany - DE (004)',
    city: 'Oldenburg',
    name: 'Hauptzollamt Oldenburg',
  },
  DE005301: {
    country: 'Germany - DE (004)',
    city: 'Brake',
    name: 'Zollamt Brake',
  },
  DE005310: {
    country: 'Germany - DE (004)',
    city: 'Wilhelmshaven',
    name: 'Zollamt Wilhelmshaven',
  },
  DE005311: {
    country: 'Germany - DE (004)',
    city: 'Wilhelmshaven',
    name: 'Zollamt Wilhelmshaven Dienstort Ozean-Pier',
  },
  DE005325: {
    country: 'Germany - DE (004)',
    city: 'Papenburg',
    name: 'Hauptzollamt Oldenburg Dienstort Papenburg',
  },
  DE005327: {
    country: 'Germany - DE (004)',
    city: 'Wilhelmshaven',
    name: 'Hauptzollamt Oldenburg Dienstort Wilhelmshaven Dodoweg',
  },
  DE005330: {
    country: 'Germany - DE (004)',
    city: 'Oldenburg',
    name: 'Zollamt Oldenburg-Kreyenbrück',
  },
  DE005350: {
    country: 'Germany - DE (004)',
    city: 'Osnabrück',
    name: 'Hauptzollamt Osnabrück',
  },
  DE005351: {
    country: 'Germany - DE (004)',
    city: 'Schüttorf',
    name: 'Zollamt Schüttorf',
  },
  DE005355: {
    country: 'Germany - DE (004)',
    city: 'Lohne (Oldenburg)',
    name: 'Zollamt Lohne',
  },
  DE005356: {
    country: 'Germany - DE (004)',
    city: 'Lingen (Ems)',
    name: 'Zollamt Lingen',
  },
  DE005371: {
    country: 'Germany - DE (004)',
    city: 'Nordhorn',
    name: 'Hauptzollamt Osnabrück Dienstort Nordhorn Stadtring',
  },
  DE005380: {
    country: 'Germany - DE (004)',
    city: 'Osnabrück',
    name: 'Zollamt Fledder',
  },
  DE005502: {
    country: 'Germany - DE (004)',
    city: 'Nossen',
    name: 'Zollamt Nossen',
  },
  DE005530: {
    country: 'Germany - DE (004)',
    city: 'Chemnitz',
    name: 'Zollamt Chemnitz',
  },
  DE005550: {
    country: 'Germany - DE (004)',
    city: 'Dresden',
    name: 'Hauptzollamt Dresden',
  },
  DE005552: {
    country: 'Germany - DE (004)',
    city: 'Dresden',
    name: 'Zollamt Dresden-Flughafen',
  },
  DE005555: {
    country: 'Germany - DE (004)',
    city: 'Dresden',
    name: 'Hauptzollamt Dresden, Kontrolleinheit Flughafen Dresden',
  },
  DE005557: {
    country: 'Germany - DE (004)',
    city: 'Schkeuditz',
    name: 'Hauptzollamt Dresden, Kontrolleinheit Flughafen Leipzig',
  },
  DE005560: {
    country: 'Germany - DE (004)',
    city: 'Görlitz',
    name: 'Hauptzollamt Dresden Dienstort Görlitz Schützenstraße',
  },
  DE005565: {
    country: 'Germany - DE (004)',
    city: 'Dresden',
    name: 'Hauptzollamt Dresden Dienstort Hartmut-Dost-Straße',
  },
  DE005572: {
    country: 'Germany - DE (004)',
    city: 'Leipzig',
    name: 'Hauptzollamt Dresden Dienstort Leipzig',
  },
  DE005574: {
    country: 'Germany - DE (004)',
    city: 'Pirna',
    name: 'Hauptzollamt Dresden Dienstort Pirna',
  },
  DE005580: {
    country: 'Germany - DE (004)',
    city: 'Dresden',
    name: 'Zollamt Dresden',
  },
  DE005603: {
    country: 'Germany - DE (004)',
    city: 'Taucha',
    name: 'Zollamt Taucha',
  },
  DE005604: {
    country: 'Germany - DE (004)',
    city: 'Schkeuditz',
    name: 'Zollamt Leipzig-Flughafen',
  },
  DE005605: {
    country: 'Germany - DE (004)',
    city: 'Schkeuditz',
    name: 'Zollamt Flughafen Leipzig Dienstort Industriestraße',
  },
  DE005633: {
    country: 'Germany - DE (004)',
    city: 'Leipzig',
    name: 'Abfertigungsstelle IFS Radefeld',
  },
  DE005687: {
    country: 'Germany - DE (004)',
    city: 'Löbau',
    name: 'Zollamt Löbau',
  },
  DE005756: {
    country: 'Germany - DE (004)',
    city: 'Reichenbach im Vogtland',
    name: 'Zollamt Reichenbach',
  },
  DE005850: {
    country: 'Germany - DE (004)',
    city: 'Karlsruhe',
    name: 'Hauptzollamt Karlsruhe',
  },
  DE005852: {
    country: 'Germany - DE (004)',
    city: 'Karlsdorf-Neuthard',
    name: 'Zollamt Bruchsal',
  },
  DE005853: {
    country: 'Germany - DE (004)',
    city: 'Rheinmünster',
    name: 'Zollamt Baden-Baden',
  },
  DE005855: {
    country: 'Germany - DE (004)',
    city: 'Rheinmünster',
    name: 'Hauptzollamt Karlsruhe, Kontrolleinheit Flughafen Karlruhe',
  },
  DE005857: {
    country: 'Germany - DE (004)',
    city: 'Nagold',
    name: 'Abfertigungsstelle Nagold',
  },
  DE005858: {
    country: 'Germany - DE (004)',
    city: 'Pforzheim',
    name: 'Zollamt Pforzheim',
  },
  DE005880: {
    country: 'Germany - DE (004)',
    city: 'Karlsruhe',
    name: 'Zollamt Karlsruhe',
  },
  DE005881: {
    country: 'Germany - DE (004)',
    city: 'Rheinmünster',
    name: 'AbfSt Flugh. Karlsruhe/Baden-Baden Zollamt Baden-Baden',
  },
  DE005900: {
    country: 'Germany - DE (004)',
    city: 'Mannheim',
    name: 'Hauptzollamt Karlsruhe Dienstort Mannheim',
  },
  DE005901: {
    country: 'Germany - DE (004)',
    city: 'Heidelberg',
    name: 'Zollamt Heidelberg',
  },
  DE005904: {
    country: 'Germany - DE (004)',
    city: 'Mannheim',
    name: 'Zollamt Mannheim',
  },
  DE005914: {
    country: 'Germany - DE (004)',
    city: 'Ludwigshafen am Rhein',
    name: 'Zollamt Mannheim Zollservicepunkt Ludwigshafen a Rh.',
  },
  DE006133: {
    country: 'Germany - DE (004)',
    city: 'Flensburg',
    name: 'Zollamt Flensburg',
  },
  DE006150: {
    country: 'Germany - DE (004)',
    city: 'Itzehoe',
    name: 'Hauptzollamt Itzehoe',
  },
  DE006151: {
    country: 'Germany - DE (004)',
    city: 'Brunsbüttel',
    name: 'Zollamt Brunsbüttel',
  },
  DE006155: {
    country: 'Germany - DE (004)',
    city: 'Husum',
    name: 'Zollamt Husum',
  },
  DE006156: {
    country: 'Germany - DE (004)',
    city: 'Pinneberg',
    name: 'Zollamt Pinneberg',
  },
  DE006179: {
    country: 'Germany - DE (004)',
    city: 'Hamburg',
    name: 'Hauptzollamt Itzehoe Dienstort Hamburg',
  },
  DE006183: {
    country: 'Germany - DE (004)',
    city: 'Itzehoe',
    name: 'Abfertigungsstelle Itzehoe (HZA)',
  },
  DE006200: {
    country: 'Germany - DE (004)',
    city: 'Kiel',
    name: 'Hauptzollamt Kiel',
  },
  DE006203: {
    country: 'Germany - DE (004)',
    city: 'Kiel',
    name: 'Zollamt Kiel-Wik',
  },
  DE006206: {
    country: 'Germany - DE (004)',
    city: 'Borgstedt',
    name: 'Zollamt Rendsburg',
  },
  DE006207: {
    country: 'Germany - DE (004)',
    city: 'Kiel',
    name: 'Zollamt Kiel',
  },
  DE006231: {
    country: 'Germany - DE (004)',
    city: 'Kiel',
    name: 'Abfertigungsstelle Kiel-Norwegenkai',
  },
  DE006302: {
    country: 'Germany - DE (004)',
    city: 'Heiligenhafen',
    name: 'Zollamt Heiligenhafen',
  },
  DE006310: {
    country: 'Germany - DE (004)',
    city: 'Mölln',
    name: 'Zollamt Mölln',
  },
  DE006332: {
    country: 'Germany - DE (004)',
    city: 'Lübeck',
    name: 'Abfertigungsstelle Hafen Zollamt Lübeck',
  },
  DE006333: {
    country: 'Germany - DE (004)',
    city: 'Lübeck',
    name: 'Zollamt Lübeck',
  },
  DE006334: {
    country: 'Germany - DE (004)',
    city: 'Lübeck',
    name: 'Zollamt Lübeck Travemünde Skandinavienkai',
  },
  DE006335: {
    country: 'Germany - DE (004)',
    city: 'Fehmarn',
    name: 'Hauptzollamt Kiel Puttgarden Fährhafen',
  },
  DE006501: {
    country: 'Germany - DE (004)',
    city: 'Kaiserslautern',
    name: 'Zollamt Kaiserslautern',
  },
  DE006502: {
    country: 'Germany - DE (004)',
    city: 'Pirmasens',
    name: 'Zollamt Pirmasens',
  },
  DE006504: {
    country: 'Germany - DE (004)',
    city: 'Mainz',
    name: 'Zollamt Mainz',
  },
  DE006550: {
    country: 'Germany - DE (004)',
    city: 'Koblenz',
    name: 'Hauptzollamt Koblenz',
  },
  DE006551: {
    country: 'Germany - DE (004)',
    city: 'Koblenz',
    name: 'Zollamt Koblenz-Rheinhafen',
  },
  DE006554: {
    country: 'Germany - DE (004)',
    city: 'Hahn-Flughafen',
    name: 'Hauptzollamt Koblenz, Kontrolleinheiten Flughafen Hahn',
  },
  DE006560: {
    country: 'Germany - DE (004)',
    city: 'Hachenburg',
    name: 'Zollamt Rheinhafen Zollservicepunkt Hachenburg',
  },
  DE006653: {
    country: 'Germany - DE (004)',
    city: 'Germersheim',
    name: 'Zollamt Germersheim',
  },
  DE006681: {
    country: 'Germany - DE (004)',
    city: 'Speyer',
    name: 'Zollamt Germersheim AbfSt IFS Speyer',
  },
  DE006752: {
    country: 'Germany - DE (004)',
    city: 'Idar-Oberstein',
    name: 'Zollamt Idar-Oberstein',
  },
  DE006753: {
    country: 'Germany - DE (004)',
    city: 'Trier',
    name: 'Zollamt Ehrang',
  },
  DE006756: {
    country: 'Germany - DE (004)',
    city: 'Hahn-Flughafen',
    name: 'Zollamt Hahn-Flughafen',
  },
  DE006757: {
    country: 'Germany - DE (004)',
    city: 'Wittlich',
    name: 'Zollamt Trier-Ehrang Dienstort Wittlich',
  },
  DE006758: {
    country: 'Germany - DE (004)',
    city: 'Bingen am Rhein',
    name: 'Zollamt Mainz Dienstort Bingen',
  },
  DE007050: {
    country: 'Germany - DE (004)',
    city: 'Aachen',
    name: 'Hauptzollamt Aachen',
  },
  DE007053: {
    country: 'Germany - DE (004)',
    city: 'Heinsberg',
    name: 'Zollamt Heinsberg',
  },
  DE007054: {
    country: 'Germany - DE (004)',
    city: 'Aachen',
    name: 'Zollamt Charlottenburger Allee',
  },
  DE007064: {
    country: 'Germany - DE (004)',
    city: 'Düren',
    name: 'Zollamt Düren',
  },
  DE007150: {
    country: 'Germany - DE (004)',
    city: 'Köln',
    name: 'Hauptzollamt Köln',
  },
  DE007152: {
    country: 'Germany - DE (004)',
    city: 'Bonn',
    name: 'Zollamt Bonn',
  },
  DE007153: {
    country: 'Germany - DE (004)',
    city: 'Gummersbach',
    name: 'Zollamt Gummersbach',
  },
  DE007154: {
    country: 'Germany - DE (004)',
    city: 'Köln',
    name: 'Zollamt Flughafen Köln/Bonn',
  },
  DE007157: {
    country: 'Germany - DE (004)',
    city: 'Köln',
    name: 'Zollamt Wahn',
  },
  DE007159: {
    country: 'Germany - DE (004)',
    city: 'Köln',
    name: 'Hauptzollamt Köln, KE Flughafen Köln/Bonn',
  },
  DE007181: {
    country: 'Germany - DE (004)',
    city: 'Köln',
    name: 'Abfertigungsstelle Köln-Messe',
  },
  DE007200: {
    country: 'Germany - DE (004)',
    city: 'Köln',
    name: 'Hauptzollamt Köln Dienstort Grüner Weg',
  },
  DE007202: {
    country: 'Germany - DE (004)',
    city: 'Köln',
    name: 'Zollamt Köln-West',
  },
  DE007350: {
    country: 'Germany - DE (004)',
    city: 'Magdeburg',
    name: 'Hauptzollamt Magdeburg',
  },
  DE007352: {
    country: 'Germany - DE (004)',
    city: 'Aschersleben',
    name: 'Zollamt Aschersleben',
  },
  DE007359: {
    country: 'Germany - DE (004)',
    city: 'Stendal',
    name: 'Zollamt Stendal',
  },
  DE007361: {
    country: 'Germany - DE (004)',
    city: 'Dessau-Roßlau',
    name: 'Zollamt Dessau-Ost',
  },
  DE007362: {
    country: 'Germany - DE (004)',
    city: 'Halle (Saale)',
    name: 'Zollamt Halle (Saale)',
  },
  DE007370: {
    country: 'Germany - DE (004)',
    city: 'Halle (Saale)',
    name: 'Hauptzollamt Magdeburg Dienstort Halle',
  },
  DE007380: {
    country: 'Germany - DE (004)',
    city: 'Magdeburg',
    name: 'Zollamt Magdeburg-Rothensee',
  },
  DE007400: {
    country: 'Germany - DE (004)',
    city: 'Augsburg',
    name: 'Hauptzollamt Augsburg',
  },
  DE007401: {
    country: 'Germany - DE (004)',
    city: 'Augsburg',
    name: 'Zollamt Göggingen',
  },
  DE007402: {
    country: 'Germany - DE (004)',
    city: 'Donauwörth',
    name: 'Zollamt Donauwörth',
  },
  DE007403: {
    country: 'Germany - DE (004)',
    city: 'Ingolstadt',
    name: 'Zollamt Ingolstadt',
  },
  DE007423: {
    country: 'Germany - DE (004)',
    city: 'Augsburg',
    name: 'Hauptzollamt Augsburg Kontrolleinheit Flughafen Augsburg',
  },
  DE007424: {
    country: 'Germany - DE (004)',
    city: 'Memmingerberg',
    name: 'Hauptzollamt Augsburg KE Flughafen Memmingerberg',
  },
  DE007430: {
    country: 'Germany - DE (004)',
    city: 'Augsburg',
    name: 'Zollamt Göggingen Abfertigungsstelle Flughafen',
  },
  DE007455: {
    country: 'Germany - DE (004)',
    city: 'Winhöring',
    name: 'Zollamt Altötting (Autobahn)',
  },
  DE007458: {
    country: 'Germany - DE (004)',
    city: 'Bad Reichenhall',
    name: 'Zollamt Bad Reichenhall-Autobahn',
  },
  DE007477: {
    country: 'Germany - DE (004)',
    city: 'Traunstein',
    name: 'Hauptzollamt Rosenheim Dienstort Traunstein',
  },
  DE007478: {
    country: 'Germany - DE (004)',
    city: 'Altdorf',
    name: 'Zollamt Hallbergmoos Dienstort Landshut',
  },
  DE007500: {
    country: 'Germany - DE (004)',
    city: 'Altdorf',
    name: 'Hauptzollamt Landshut',
  },
  DE007501: {
    country: 'Germany - DE (004)',
    city: 'Hallbergmoos',
    name: 'Zollamt Hallbergmoos',
  },
  DE007504: {
    country: 'Germany - DE (004)',
    city: 'Plattling',
    name: 'Zollamt Plattling',
  },
  DE007522: {
    country: 'Germany - DE (004)',
    city: 'Passau',
    name: 'Hauptzollamt Landshut Dienstort Passau Spitalhofstraße',
  },
  DE007525: {
    country: 'Germany - DE (004)',
    city: 'Plattling',
    name: 'Hauptzollamt Landshut Dienstort Plattling',
  },
  DE007533: {
    country: 'Germany - DE (004)',
    city: 'Landshut',
    name: 'Hauptzollamt Landshut Dienstort Siemensstraße',
  },
  DE007551: {
    country: 'Germany - DE (004)',
    city: 'Lindau (Bodensee)',
    name: 'Zollamt Hörbranz-Autobahn (österr. Gebiet)',
  },
  DE007553: {
    country: 'Germany - DE (004)',
    city: 'Kempten (Allgäu)',
    name: 'Zollamt Kempten',
  },
  DE007554: {
    country: 'Germany - DE (004)',
    city: 'Memmingerberg',
    name: 'Abfertigungsstelle Flughafen Memmingerberg',
  },
  DE007561: {
    country: 'Germany - DE (004)',
    city: 'Memmingen',
    name: 'Zollamt Memmingen',
  },
  DE007600: {
    country: 'Germany - DE (004)',
    city: 'München',
    name: 'Hauptzollamt München',
  },
  DE007602: {
    country: 'Germany - DE (004)',
    city: 'Garching b. München',
    name: 'Zollamt Garching-Hochbrück',
  },
  DE007611: {
    country: 'Germany - DE (004)',
    city: 'München-Flughafen',
    name: 'Hauptzollamt München, Kontrolleinheiten Flughafen München',
  },
  DE007622: {
    country: 'Germany - DE (004)',
    city: 'München',
    name: 'Hauptzollamt München FKS München - Links der Isar',
  },
  DE007650: {
    country: 'Germany - DE (004)',
    city: 'München-Flughafen',
    name: 'Zollamt München - Flughafen',
  },
  DE007679: {
    country: 'Germany - DE (004)',
    city: 'München-Flughafen',
    name: 'Hauptzollamt München Dienstort München-Flughafen',
  },
  DE007701: {
    country: 'Germany - DE (004)',
    city: 'Pocking Postfach 1454',
    name: 'Zollamt Suben-Autobahn (österr. Gebiet)',
  },
  DE007703: {
    country: 'Germany - DE (004)',
    city: 'Passau',
    name: 'Zollamt Passau',
  },
  DE007744: {
    country: 'Germany - DE (004)',
    city: 'Zwiesel',
    name: 'Hauptzollamt Landshut Dienstort Zwiesel',
  },
  DE007750: {
    country: 'Germany - DE (004)',
    city: 'Rosenheim',
    name: 'Hauptzollamt Rosenheim',
  },
  DE007751: {
    country: 'Germany - DE (004)',
    city: 'Weilheim i. OB',
    name: 'Zollamt Weilheim',
  },
  DE007755: {
    country: 'Germany - DE (004)',
    city: 'Raubling',
    name: 'Zollamt Reischenhart',
  },
  DE007756: {
    country: 'Germany - DE (004)',
    city: 'München',
    name: 'Hauptzollamt Rosenheim Dienstort München',
  },
  DE008000: {
    country: 'Germany - DE (004)',
    city: 'Bielefeld',
    name: 'Hauptzollamt Bielefeld',
  },
  DE008001: {
    country: 'Germany - DE (004)',
    city: 'Bielefeld',
    name: 'Zollamt Bielefeld-Eckendorfer Straße',
  },
  DE008002: {
    country: 'Germany - DE (004)',
    city: 'Büren',
    name: 'Hauptzollamt Bielefeld, KE Flughafen Paderborn/Lippstadt',
  },
  DE008004: {
    country: 'Germany - DE (004)',
    city: 'Lemgo',
    name: 'Zollamt Lemgo',
  },
  DE008008: {
    country: 'Germany - DE (004)',
    city: 'Lübbecke',
    name: 'Zollamt Lübbecke',
  },
  DE008027: {
    country: 'Germany - DE (004)',
    city: 'Anröchte',
    name: 'Hauptzollamt Bielefeld Dienstort Anröchte',
  },
  DE008051: {
    country: 'Germany - DE (004)',
    city: 'Bochum',
    name: 'Zollamt Bochum',
  },
  DE008052: {
    country: 'Germany - DE (004)',
    city: 'Gelsenkirchen',
    name: 'Zollamt Gelsenkirchen',
  },
  DE008100: {
    country: 'Germany - DE (004)',
    city: 'Dortmund',
    name: 'Hauptzollamt Dortmund',
  },
  DE008101: {
    country: 'Germany - DE (004)',
    city: 'Hagen',
    name: 'Zollamt Hagen',
  },
  DE008103: {
    country: 'Germany - DE (004)',
    city: 'Dortmund',
    name: 'Zollamt Dortmund-Ost',
  },
  DE008104: {
    country: 'Germany - DE (004)',
    city: 'Lüdenscheid',
    name: 'Zollamt Lüdenscheid',
  },
  DE008106: {
    country: 'Germany - DE (004)',
    city: 'Siegen',
    name: 'Zollamt Siegen',
  },
  DE008107: {
    country: 'Germany - DE (004)',
    city: 'Dortmund',
    name: 'Hauptzollamt Dortmund, KE Flughafen Dortmund',
  },
  DE008131: {
    country: 'Germany - DE (004)',
    city: 'Dortmund',
    name: 'Zollamt Dortmund - Flughafen',
  },
  DE008300: {
    country: 'Germany - DE (004)',
    city: 'Münster',
    name: 'Hauptzollamt Münster',
  },
  DE008301: {
    country: 'Germany - DE (004)',
    city: 'Greven',
    name: 'Hauptzollamt Münster, Kontrolleinheit Flughafen Münster',
  },
  DE008302: {
    country: 'Germany - DE (004)',
    city: 'Bocholt',
    name: 'Zollamt Bocholt',
  },
  DE008303: {
    country: 'Germany - DE (004)',
    city: 'Coesfeld',
    name: 'Zollamt Coesfeld',
  },
  DE008304: {
    country: 'Germany - DE (004)',
    city: 'Münster',
    name: 'Zollamt Münster-Loddenheide',
  },
  DE008305: {
    country: 'Germany - DE (004)',
    city: 'Rheine',
    name: 'Zollamt Rheine',
  },
  DE008306: {
    country: 'Germany - DE (004)',
    city: 'Greven',
    name: 'Zollamt Münster-Flughafen',
  },
  DE008322: {
    country: 'Germany - DE (004)',
    city: 'Coesfeld',
    name: 'Hauptzollamt Münster Dienstort Coesfeld',
  },
  DE008350: {
    country: 'Germany - DE (004)',
    city: 'Paderborn',
    name: 'Hauptzollamt Bielefeld Dienstort Paderborn Am Hoppenhof',
  },
  DE008351: {
    country: 'Germany - DE (004)',
    city: 'Anröchte',
    name: 'Zollamt Anröchte',
  },
  DE008352: {
    country: 'Germany - DE (004)',
    city: 'Arnsberg',
    name: 'Zollamt Arnsberg',
  },
  DE008355: {
    country: 'Germany - DE (004)',
    city: 'Paderborn',
    name: 'Zollamt Paderborn',
  },
  DE008356: {
    country: 'Germany - DE (004)',
    city: 'Beckum',
    name: 'Zollamt Beckum',
  },
  DE008380: {
    country: 'Germany - DE (004)',
    city: 'Büren',
    name: 'Zollamt Paderborn-Flughafen',
  },
  DE008601: {
    country: 'Germany - DE (004)',
    city: 'Bamberg',
    name: 'Zollamt Bamberg',
  },
  DE008602: {
    country: 'Germany - DE (004)',
    city: 'Bayreuth',
    name: 'Zollamt Bayreuth',
  },
  DE008605: {
    country: 'Germany - DE (004)',
    city: 'Coburg',
    name: 'Zollamt Coburg',
  },
  DE008711: {
    country: 'Germany - DE (004)',
    city: 'Marktredwitz',
    name: 'Zollamt Hof-Marktredwitz Dienstsitz Marktredwitz',
  },
  DE008715: {
    country: 'Germany - DE (004)',
    city: 'Hof',
    name: 'Zollamt Hof-Marktredwitz',
  },
  DE008750: {
    country: 'Germany - DE (004)',
    city: 'Nürnberg',
    name: 'Hauptzollamt Nürnberg',
  },
  DE008751: {
    country: 'Germany - DE (004)',
    city: 'Ansbach',
    name: 'Zollamt Ansbach-Weißenburg',
  },
  DE008752: {
    country: 'Germany - DE (004)',
    city: 'Erlangen',
    name: 'Zollamt Erlangen-Tennenlohe',
  },
  DE008755: {
    country: 'Germany - DE (004)',
    city: 'Nürnberg',
    name: 'Zollamt Nürnberg Flughafen',
  },
  DE008756: {
    country: 'Germany - DE (004)',
    city: 'Nürnberg',
    name: 'Zollamt Hafen',
  },
  DE008757: {
    country: 'Germany - DE (004)',
    city: 'Nürnberg',
    name: 'Hauptzollamt Nürnberg, KE Flughafen Nürnberg',
  },
  DE008761: {
    country: 'Germany - DE (004)',
    city: 'Weißenburg i.Bay.',
    name: 'Zollamt Ansbach-Weißenburg Dienstsitz Weißenburg',
  },
  DE008784: {
    country: 'Germany - DE (004)',
    city: 'Nürnberg',
    name: 'Hauptzollamt Nürnberg (Kontaktstelle AEO)',
  },
  DE008800: {
    country: 'Germany - DE (004)',
    city: 'Regensburg',
    name: 'Hauptzollamt Regensburg',
  },
  DE008801: {
    country: 'Germany - DE (004)',
    city: 'Amberg',
    name: 'Zollamt Amberg',
  },
  DE008804: {
    country: 'Germany - DE (004)',
    city: 'Regensburg',
    name: 'Zollamt Regensburg-Furth im Wald',
  },
  DE008822: {
    country: 'Germany - DE (004)',
    city: 'Hof',
    name: 'Hauptzollamt Regensburg Dienstort Hof Köditzer Straße',
  },
  DE008833: {
    country: 'Germany - DE (004)',
    city: 'Furth im Wald',
    name: 'Hauptzollamt Regensburg Dienstort Furth im Wald Böhmerstr.',
  },
  DE008835: {
    country: 'Germany - DE (004)',
    city: 'Selb',
    name: 'Hauptzollamt Regensburg Dienstort Selb Lessingstraße',
  },
  DE008840: {
    country: 'Germany - DE (004)',
    city: 'Wernberg-Köblitz',
    name: 'Hauptzollamt Regensburg Dienstort Wernberg-Köblitz',
  },
  DE008850: {
    country: 'Germany - DE (004)',
    city: 'Schweinfurt',
    name: 'Hauptzollamt Schweinfurt',
  },
  DE008851: {
    country: 'Germany - DE (004)',
    city: 'Aschaffenburg',
    name: 'Zollamt Aschaffenburg',
  },
  DE008853: {
    country: 'Germany - DE (004)',
    city: 'Schweinfurt',
    name: 'Zollamt Schweinfurt-Londonstraße',
  },
  DE008857: {
    country: 'Germany - DE (004)',
    city: 'Dettelbach',
    name: 'Zollamt Dettelbach-Mainfrankenpark',
  },
  DE008864: {
    country: 'Germany - DE (004)',
    city: 'Schweinfurt',
    name: 'Hauptzollamt Schweinfurt Dienstort Londonstraße',
  },
  DE008865: {
    country: 'Germany - DE (004)',
    city: 'Bamberg',
    name: 'Hauptzollamt Schweinfurt DO Bamberg Gutenbergstraße',
  },
  DE008904: {
    country: 'Germany - DE (004)',
    city: 'Weiden i.d.OPf.',
    name: 'Zollamt Weiden-Waidhaus',
  },
  DE008913: {
    country: 'Germany - DE (004)',
    city: 'Waidhaus',
    name: 'Zollamt Weiden-Waidhaus Dienstsitz Waidhaus',
  },
  DE008914: {
    country: 'Germany - DE (004)',
    city: 'Furth im Wald',
    name: 'Zollamt Regensburg-Furth im Wald Dienstsitz Furth im Wald',
  },
  DE008942: {
    country: 'Germany - DE (004)',
    city: 'Waidhaus',
    name: 'Hauptzollamt Regensburg Dienstort Waidhaus',
  },
  DE009004: {
    country: 'Germany - DE (004)',
    city: 'Nadrensee',
    name: 'Zollamt Pomellen',
  },
  DE009030: {
    country: 'Germany - DE (004)',
    city: 'Neubrandenburg',
    name: 'Zollamt Neubrandenburg',
  },
  DE009101: {
    country: 'Germany - DE (004)',
    city: 'Ludwigslust',
    name: 'Zollamt Ludwigslust',
  },
  DE009102: {
    country: 'Germany - DE (004)',
    city: 'Laage',
    name: 'Zollamt Laage',
  },
  DE009103: {
    country: 'Germany - DE (004)',
    city: 'Wismar',
    name: 'Zollamt Wismar',
  },
  DE009104: {
    country: 'Germany - DE (004)',
    city: 'Rostock',
    name: 'Zollamt Rostock',
  },
  DE009106: {
    country: 'Germany - DE (004)',
    city: 'Laage',
    name: 'Hauptzollamt Stralsund, Kontrolleinheit Flughafen Rostock',
  },
  DE009150: {
    country: 'Germany - DE (004)',
    city: 'Stralsund',
    name: 'Hauptzollamt Stralsund',
  },
  DE009152: {
    country: 'Germany - DE (004)',
    city: 'Wolgast',
    name: 'Zollamt Wolgast',
  },
  DE009154: {
    country: 'Germany - DE (004)',
    city: 'Sassnitz',
    name: 'Zollamt Mukran',
  },
  DE009156: {
    country: 'Germany - DE (004)',
    city: 'Seebad Ahlbeck',
    name: 'Hauptzollamt Stralsund Dienstort Seebad Ahlbeck',
  },
  DE009172: {
    country: 'Germany - DE (004)',
    city: 'Rostock',
    name: 'Hauptzollamt Stralsund Dienstort Rostock Pressentinstraße',
  },
  DE009180: {
    country: 'Germany - DE (004)',
    city: 'Stralsund',
    name: 'Zollamt Stralsund-Dänholm',
  },
  DE009300: {
    country: 'Germany - DE (004)',
    city: 'Saarbrücken',
    name: 'Hauptzollamt Saarbrücken',
  },
  DE009303: {
    country: 'Germany - DE (004)',
    city: 'Saarbrücken',
    name: 'Zollamt Saarbrücken',
  },
  DE009304: {
    country: 'Germany - DE (004)',
    city: 'Saarbrücken',
    name: 'Zollamt Saarbrücken Flughafen',
  },
  DE009305: {
    country: 'Germany - DE (004)',
    city: 'Saarbrücken',
    name: 'Hauptzollamt Saarbrücken, KE Flughafen Saarbrücken',
  },
  DE009344: {
    country: 'Germany - DE (004)',
    city: 'Kaiserslautern',
    name: 'Hauptzollamt Saarbrücken Dienstort Kaiserslautern',
  },
  DE009404: {
    country: 'Germany - DE (004)',
    city: 'Ravensburg',
    name: 'Zollamt Bodensee-Oberschwaben',
  },
  DE009412: {
    country: 'Germany - DE (004)',
    city: 'Wangen im Allgäu',
    name: 'Zollamt Bodensee-Oberschwaben Zollservicepunkt Wangen im Allgäu',
  },
  DE009420: {
    country: 'Germany - DE (004)',
    city: 'Friedrichshafen',
    name: 'Zollamt Bodensee-Oberschwaben Abfertigungsstelle Fähre',
  },
  DE009450: {
    country: 'Germany - DE (004)',
    city: 'Heilbronn',
    name: 'Hauptzollamt Heilbronn',
  },
  DE009452: {
    country: 'Germany - DE (004)',
    city: 'Heilbronn',
    name: 'Zollamt Heilbronn',
  },
  DE009453: {
    country: 'Germany - DE (004)',
    city: 'Ludwigsburg',
    name: 'Zollamt Ludwigsburg',
  },
  DE009456: {
    country: 'Germany - DE (004)',
    city: 'Tauberbischofsheim',
    name: 'Zollamt Tauberbischofsheim',
  },
  DE009459: {
    country: 'Germany - DE (004)',
    city: 'Untermünkheim',
    name: 'Zollamt Untermünkheim',
  },
  DE009500: {
    country: 'Germany - DE (004)',
    city: 'Reutlingen',
    name: 'Hauptzollamt Heilbronn Dienstort Reutlingen',
  },
  DE009501: {
    country: 'Germany - DE (004)',
    city: 'Albstadt',
    name: 'Zollamt Albstadt',
  },
  DE009520: {
    country: 'Germany - DE (004)',
    city: 'Pfullingen',
    name: 'Hauptzollamt Ulm Dienstort Pfullingen',
  },
  DE009550: {
    country: 'Germany - DE (004)',
    city: 'Stuttgart',
    name: 'Hauptzollamt Stuttgart',
  },
  DE009551: {
    country: 'Germany - DE (004)',
    city: 'Böblingen',
    name: 'Zollamt Böblingen',
  },
  DE009552: {
    country: 'Germany - DE (004)',
    city: 'Stuttgart',
    name: 'Zollamt Stuttgart-Hafen',
  },
  DE009554: {
    country: 'Germany - DE (004)',
    city: 'Stuttgart',
    name: 'Zollamt Stuttgart-Zuffenhausen',
  },
  DE009555: {
    country: 'Germany - DE (004)',
    city: 'Stuttgart',
    name: 'Zollamt Stuttgart-Flughafen',
  },
  DE009556: {
    country: 'Germany - DE (004)',
    city: 'Winnenden',
    name: 'Zollamt Winnenden',
  },
  DE009557: {
    country: 'Germany - DE (004)',
    city: 'Stuttgart',
    name: 'Hauptzollamt Stuttgart, KE Flughafen Stuttgart',
  },
  DE009589: {
    country: 'Germany - DE (004)',
    city: 'Stuttgart',
    name: 'Abfertigungsstelle Stuttgart-Landesmesse',
  },
  DE009650: {
    country: 'Germany - DE (004)',
    city: 'Ulm',
    name: 'Hauptzollamt Ulm',
  },
  DE009651: {
    country: 'Germany - DE (004)',
    city: 'Essingen',
    name: 'Zollamt Aalen',
  },
  DE009652: {
    country: 'Germany - DE (004)',
    city: 'Göppingen',
    name: 'Zollamt Göppingen',
  },
  DE009653: {
    country: 'Germany - DE (004)',
    city: 'Friedrichshafen',
    name: 'Hauptzollamt Ulm, KE Flughafen Friedrichshafen',
  },
  DE009656: {
    country: 'Germany - DE (004)',
    city: 'Ulm',
    name: 'Zollamt Donautal',
  },
  DE009657: {
    country: 'Germany - DE (004)',
    city: 'Biberach an der Riß',
    name: 'Zollamt Biberach',
  },
  DE009680: {
    country: 'Germany - DE (004)',
    city: 'Metzingen',
    name: 'Zollamt Metzingen',
  },
  DE012325: {
    country: 'Germany - DE (004)',
    city: 'Bremen',
    name: 'Zollamt Bremen',
  },
  DE012452: {
    country: 'Germany - DE (004)',
    city: 'Bremerhaven',
    name: 'Zollamt Bremerhaven',
  },
  DE013302: {
    country: 'Germany - DE (004)',
    city: 'Frankfurt am Main',
    name: 'Zollamt Fracht',
  },
  DE013703: {
    country: 'Germany - DE (004)',
    city: 'Schönefeld',
    name: 'Zollamt Flughafen Berlin Brandenburg',
  },
  DE014002: {
    country: 'Germany - DE (004)',
    city: 'Konstanz',
    name: 'Zollamt Konstanz-Güterbahnhof',
  },
  DE014105: {
    country: 'Germany - DE (004)',
    city: 'Singen',
    name: 'Zollamt Singen-Bahnhof',
  },
  DE014201: {
    country: 'Germany - DE (004)',
    city: 'Klettgau',
    name: 'Zollamt Erzingen',
  },
  DE014203: {
    country: 'Germany - DE (004)',
    city: 'Jestetten',
    name: 'Zollamt Jestetten (schweiz. Gebiet)',
  },
  DE014204: {
    country: 'Germany - DE (004)',
    city: 'Laufenburg (Baden)',
    name: 'Zollamt Laufenburg',
  },
  DE014205: {
    country: 'Germany - DE (004)',
    city: 'Lottstetten',
    name: 'Zollamt Lottstetten',
  },
  DE014206: {
    country: 'Germany - DE (004)',
    city: 'Stühlingen',
    name: 'Zollamt Stühlingen',
  },
  DE014208: {
    country: 'Germany - DE (004)',
    city: 'Waldshut-Tiengen',
    name: 'Zollamt Waldshut',
  },
  DE014209: {
    country: 'Germany - DE (004)',
    city: 'Bad Säckingen',
    name: 'Zollamt Bad Säckingen',
  },
  DE014501: {
    country: 'Germany - DE (004)',
    city: 'Cuxhaven',
    name: 'Zollamt Cuxhaven',
  },
  DE014701: {
    country: 'Germany - DE (004)',
    city: 'Hamburg',
    name: 'Zollamt Hamburg-Flughafen',
  },
  DE014851: {
    country: 'Germany - DE (004)',
    city: 'Hamburg',
    name: 'Zollamt Hamburg',
  },
  DE015004: {
    country: 'Germany - DE (004)',
    city: 'Emden',
    name: 'Zollamt Emden',
  },
  DE015008: {
    country: 'Germany - DE (004)',
    city: 'Papenburg',
    name: 'Zollamt Papenburg',
  },
  DE015203: {
    country: 'Germany - DE (004)',
    city: 'Stade',
    name: 'Zollamt Stade',
  },
  DE015301: {
    country: 'Germany - DE (004)',
    city: 'Brake',
    name: 'Zollamt Brake',
  },
  DE015310: {
    country: 'Germany - DE (004)',
    city: 'Wilhelmshaven',
    name: 'Zollamt Wilhelmshaven',
  },
  DE016133: {
    country: 'Germany - DE (004)',
    city: 'Flensburg',
    name: 'Zollamt Flensburg',
  },
  DE016151: {
    country: 'Germany - DE (004)',
    city: 'Brunsbüttel',
    name: 'Zollamt Brunsbüttel',
  },
  DE016155: {
    country: 'Germany - DE (004)',
    city: 'Husum',
    name: 'Zollamt Husum',
  },
  DE016203: {
    country: 'Germany - DE (004)',
    city: 'Kiel',
    name: 'Zollamt Kiel-Wik',
  },
  DE016206: {
    country: 'Germany - DE (004)',
    city: 'Borgstedt',
    name: 'Zollamt Rendsburg',
  },
  DE016302: {
    country: 'Germany - DE (004)',
    city: 'Heiligenhafen',
    name: 'Zollamt Heiligenhafen',
  },
  DE016332: {
    country: 'Germany - DE (004)',
    city: 'Lübeck',
    name: 'Abfertigungsstelle Hafen Zollamt Lübeck',
  },
  DE016756: {
    country: 'Germany - DE (004)',
    city: 'Hahn-Flughafen',
    name: 'Zollamt Hahn-Flughafen',
  },
  DE017154: {
    country: 'Germany - DE (004)',
    city: 'Köln',
    name: 'Zollamt Flughafen Köln/Bonn',
  },
  DE017352: {
    country: 'Germany - DE (004)',
    city: 'Aschersleben',
    name: 'Zollamt Aschersleben',
  },
  DE017650: {
    country: 'Germany - DE (004)',
    city: 'München-Flughafen',
    name: 'Zollamt München - Flughafen',
  },
  DE019004: {
    country: 'Germany - DE (004)',
    city: 'Nadrensee',
    name: 'Zollamt Pomellen',
  },
  DE019030: {
    country: 'Germany - DE (004)',
    city: 'Neubrandenburg',
    name: 'Zollamt Neubrandenburg',
  },
  DE019101: {
    country: 'Germany - DE (004)',
    city: 'Ludwigslust',
    name: 'Zollamt Ludwigslust',
  },
  DE019102: {
    country: 'Germany - DE (004)',
    city: 'Laage',
    name: 'Zollamt Laage',
  },
  DE019103: {
    country: 'Germany - DE (004)',
    city: 'Wismar',
    name: 'Zollamt Wismar',
  },
  DE019104: {
    country: 'Germany - DE (004)',
    city: 'Rostock',
    name: 'Zollamt Rostock',
  },
  DE019152: {
    country: 'Germany - DE (004)',
    city: 'Wolgast',
    name: 'Zollamt Wolgast',
  },
  DE019154: {
    country: 'Germany - DE (004)',
    city: 'Sassnitz',
    name: 'Zollamt Mukran',
  },
  DE019180: {
    country: 'Germany - DE (004)',
    city: 'Stralsund',
    name: 'Zollamt Stralsund-Dänholm',
  },
  DE019589: {
    country: 'Germany - DE (004)',
    city: 'Stuttgart',
    name: 'Abfertigungsstelle Stuttgart-Landesmesse',
  },
  DK000010: {
    country: 'Denmark - DK (008)',
    city: 'Kastrup',
    name: 'Post',
  },
  DK000020: {
    country: 'Denmark - DK (008)',
    city: 'Padborg',
    name: 'Bane',
  },
  DK000460: {
    country: 'Denmark - DK (008)',
    city: 'Køge',
    name: 'Toldstyrelsen',
  },
  DK000461: {
    country: 'Denmark - DK (008)',
    city: 'KØBENHAVN Ø',
    name: 'Færgeterminalen ved DFDS',
  },
  DK000462: {
    country: 'Denmark - DK (008)',
    city: 'Nordhavn',
    name: 'Told Frihavnen',
  },
  DK000463: {
    country: 'Denmark - DK (008)',
    city: 'KASTRUP',
    name: 'Told Kystvejen',
  },
  DK003100: {
    country: 'Denmark - DK (008)',
    city: 'Aalborg',
    name: 'Told Aalborg',
  },
  DK003101: {
    country: 'Denmark - DK (008)',
    city: 'Frederikshavn',
    name: 'Told Frederikshavn',
  },
  DK003102: {
    country: 'Denmark - DK (008)',
    city: 'Hirtshals',
    name: 'Told Hirtshals',
  },
  DK003103: {
    country: 'Denmark - DK (008)',
    city: 'Hanstholm',
    name: 'Hanstholm Toldekspedition',
  },
  DK003106: {
    country: 'Denmark - DK (008)',
    city: 'Hanstholm',
    name: 'Post Danmark',
  },
  DK003761: {
    country: 'Denmark - DK (008)',
    city: 'KVISTGAARD',
    name: 'Told Kvistgård',
  },
  DK003762: {
    country: 'Denmark - DK (008)',
    city: 'RØNNE',
    name: 'Told Bornholm',
  },
  DK003862: {
    country: 'Denmark - DK (008)',
    city: 'BILLUND',
    name: 'Told Billund Lufthavn',
  },
  DK003863: {
    country: 'Denmark - DK (008)',
    city: 'ESBJERG',
    name: 'Told Esbjerg Told Esbjerg',
  },
  DK004700: {
    country: 'Denmark - DK (008)',
    city: 'Tranbjerg',
    name: 'Toldstyrelsen',
  },
  DK004701: {
    country: 'Denmark - DK (008)',
    city: 'Århus',
    name: 'Told Aarhus',
  },
  DK004702: {
    country: 'Denmark - DK (008)',
    city: 'Grenå',
    name: 'Grenå Toldekspedition',
  },
  DK004706: {
    country: 'Denmark - DK (008)',
    city: 'Herning',
    name: 'Herning Toldekspedition',
  },
  DK005160: {
    country: 'Denmark - DK (008)',
    city: 'KØGE',
    name: 'Told Køge',
  },
  DK005162: {
    country: 'Denmark - DK (008)',
    city: 'NØRRE ALSLEV',
    name: 'Told Nørre Alslev',
  },
  DK005600: {
    country: 'Denmark - DK (008)',
    city: 'Middelfart',
    name: 'Skattecenter Middelfart',
  },
  DK005601: {
    country: 'Denmark - DK (008)',
    city: 'Middelfart',
    name: 'Sydjyllands postcenter',
  },
  DK005602: {
    country: 'Denmark - DK (008)',
    city: 'Fredericia',
    name: 'Fredericia Toldekspedition',
  },
  DK005603: {
    country: 'Denmark - DK (008)',
    city: 'Kolding',
    name: 'Kokholm Toldekspedition',
  },
  DK005604: {
    country: 'Denmark - DK (008)',
    city: 'Padborg',
    name: 'Told Padborg',
  },
  DK005606: {
    country: 'Denmark - DK (008)',
    city: 'Billund',
    name: 'Told Billund Lufthavn',
  },
  DK005607: {
    country: 'Denmark - DK (008)',
    city: 'Esbjerg',
    name: 'Esbjerg Toldekspedition',
  },
  DK005610: {
    country: 'Denmark - DK (008)',
    city: 'Odense',
    name: 'Odense Toldekspedition',
  },
  DK005612: {
    country: 'Denmark - DK (008)',
    city: 'Esbjerg',
    name: 'Esbjerg Lufthavn',
  },
  DK006600: {
    country: 'Denmark - DK (008)',
    city: 'Køge',
    name: 'Told Køge',
  },
  DK006602: {
    country: 'Denmark - DK (008)',
    city: 'Nørre Alslev',
    name: 'Told Nørre Alslev',
  },
  DK007900: {
    country: 'Denmark - DK (008)',
    city: 'Køge',
    name: 'Told Køge',
  },
  DK007901: {
    country: 'Denmark - DK (008)',
    city: 'København Ø',
    name: 'Færgeterminalen ved DFDS',
  },
  DK007902: {
    country: 'Denmark - DK (008)',
    city: 'Nordhaven',
    name: 'Told Frihavnen',
  },
  DK007903: {
    country: 'Denmark - DK (008)',
    city: 'Kastrup',
    name: 'Told Kystvejen',
  },
  DK007904: {
    country: 'Denmark - DK (008)',
    city: 'Kastrup',
    name: 'Told Københavns Lufthavn',
  },
  DK007906: {
    country: 'Denmark - DK (008)',
    city: 'Kastrup',
    name: 'København Internationalt Postcenter',
  },
  DK007908: {
    country: 'Denmark - DK (008)',
    city: 'Kvistgaard',
    name: 'Told Kvistgård',
  },
  DK007909: {
    country: 'Denmark - DK (008)',
    city: 'Rønne',
    name: 'Told Bornholm',
  },
  DK008034: {
    country: 'Denmark - DK (008)',
    city: 'Skive',
    name: 'Skattestyrelsen Skive',
  },
  DK008040: {
    country: 'Denmark - DK (008)',
    city: 'Grenaa',
    name: 'Skattestyrelsen Grenaa',
  },
  DK008047: {
    country: 'Denmark - DK (008)',
    city: 'Aarhus',
    name: 'Skattestyrelsen Aarhus',
  },
  DK008056: {
    country: 'Denmark - DK (008)',
    city: 'Middelfart',
    name: 'Skattestyrelsen Middelfart',
  },
  DK008066: {
    country: 'Denmark - DK (008)',
    city: 'Køge',
    name: 'Skattecenter Køge (historisk)',
  },
  DK008079: {
    country: 'Denmark - DK (008)',
    city: 'København SV',
    name: 'Skattestyrelsen København',
  },
  DK008085: {
    country: 'Denmark - DK (008)',
    city: 'Fredensborg',
    name: 'Skattestyrelsen Fredensborg',
  },
  DK009262: {
    country: 'Denmark - DK (008)',
    city: 'FREDERICIA',
    name: 'Centerhavn Toldekspedition',
  },
  DK009264: {
    country: 'Denmark - DK (008)',
    city: 'PADBORG',
    name: 'Told Padborg',
  },
  DK009760: {
    country: 'Denmark - DK (008)',
    city: 'AALBORG',
    name: 'Told Aalborg',
  },
  DK009761: {
    country: 'Denmark - DK (008)',
    city: 'FREDERIKSHAVN',
    name: 'Told Frederikshavn',
  },
  DK009762: {
    country: 'Denmark - DK (008)',
    city: 'HIRTSHALS',
    name: 'Told Hirtshals',
  },
  DK009860: {
    country: 'Denmark - DK (008)',
    city: 'Århus',
    name: 'Told Aarhus',
  },
  EE0001EE: {
    country: 'Estonia - EE (053)',
    city: 'Tallinn',
    name: 'Maksu- ja tolliamet',
  },
  EE1000EE: {
    country: 'Estonia - EE (053)',
    city: 'Tallinn',
    name: 'Tolliosakond',
  },
  EE1110EE: {
    country: 'Estonia - EE (053)',
    city: 'Tallinn',
    name: 'Tollideklaratsioonide kontroll',
  },
  EE1111EE: {
    country: 'Estonia - EE (053)',
    city: 'Tallinn',
    name: 'Lennujaama tollikontrolli üksus',
  },
  EE1112EE: {
    country: 'Estonia - EE (053)',
    city: 'Tallinn',
    name: 'Uurimisosakond',
  },
  EE1113EE: {
    country: 'Estonia - EE (053)',
    city: 'Tallinn',
    name: 'Sadamate tollikontrolli üksus',
  },
  EE1114EE: {
    country: 'Estonia - EE (053)',
    city: 'Tallinn',
    name: 'Posti tollipunkt',
  },
  EE1160EE: {
    country: 'Estonia - EE (053)',
    city: 'Paldiski',
    name: 'Paldiski piiripunkt',
  },
  EE1210EE: {
    country: 'Estonia - EE (053)',
    city: 'Maardu',
    name: 'Muuga tollipunkt',
  },
  EE1241EE: {
    country: 'Estonia - EE (053)',
    city: 'Maardu',
    name: 'Laevaliikluskeskus',
  },
  EE1310EE: {
    country: 'Estonia - EE (053)',
    city: 'Tallinn',
    name: 'Lennujaama tollipunkt',
  },
  EE3000EE: {
    country: 'Estonia - EE (053)',
    city: 'Tallinn',
    name: 'Teenuste osakond',
  },
  EE3300EE: {
    country: 'Estonia - EE (053)',
    city: 'Pärnu',
    name: 'Lääne regioon',
  },
  EE4000EE: {
    country: 'Estonia - EE (053)',
    city: 'Tallinn',
    name: 'Maksude osakond',
  },
  EE4100EE: {
    country: 'Estonia - EE (053)',
    city: 'Võru',
    name: 'Lõuna regioon',
  },
  EE4200EE: {
    country: 'Estonia - EE (053)',
    city: 'Tartu',
    name: 'Tolliinfo',
  },
  EE4700EE: {
    country: 'Estonia - EE (053)',
    city: 'Võru maakond',
    name: 'Luhamaa piiripunkt',
  },
  EE4800EE: {
    country: 'Estonia - EE (053)',
    city: 'Võru maakond',
    name: 'Koidula maantee piiripunkt',
  },
  EE4810EE: {
    country: 'Estonia - EE (053)',
    city: 'Võru maakond',
    name: 'Koidula raudtee piiripunkt',
  },
  EE5100EE: {
    country: 'Estonia - EE (053)',
    city: 'Narva',
    name: 'Ida regioon',
  },
  EE5130EE: {
    country: 'Estonia - EE (053)',
    city: 'Sillamäe',
    name: 'Sillamäe tollipunkt',
  },
  EE5131EE: {
    country: 'Estonia - EE (053)',
    city: 'Sillamäe',
    name: 'Sillamäe vabatsoon',
  },
  EE5500EE: {
    country: 'Estonia - EE (053)',
    city: 'Narva',
    name: 'Narva raudtee piiripunkt',
  },
  EE5600EE: {
    country: 'Estonia - EE (053)',
    city: 'Narva',
    name: 'Narva maantee piiripunkt',
  },
  EE8888EE: {
    country: 'Estonia - EE (053)',
    city: 'Tallinn',
    name: 'Saadetiste tolliteenindus',
  },
  ES0000DA: {
    country: 'Spain - ES (011)',
    city: 'MADRID',
    name: 'SERVICIO CENTRAL OEA',
  },
  ES000101: {
    country: 'Spain - ES (011)',
    city: 'FORONDA-VITORIA (ALAVA)',
    name: 'ADUANA AEROPUERTO VITORIA-FORONDA',
  },
  ES000141: {
    country: 'Spain - ES (011)',
    city: 'VITORIA (ALAVA)',
    name: 'ADUANA DE VITORIA - CARRETERA',
  },
  ES000150: {
    country: 'Spain - ES (011)',
    city: 'VITORIA - (ÁLAVA)',
    name: 'ADUANA DE VITORIA DOMICILIACIÓN',
  },
  ES000241: {
    country: 'Spain - ES (011)',
    city: 'ALBACETE',
    name: 'ADUANA DE ALBACETE DOMICILIACIÓN',
  },
  ES000301: {
    country: 'Spain - ES (011)',
    city: 'ALICANTE',
    name: 'ADUANA AEROPUERTO ALICANTE-ELCHE',
  },
  ES000311: {
    country: 'Spain - ES (011)',
    city: 'ALICANTE',
    name: 'ADUANA DE ALICANTE - MARÍTIMA',
  },
  ES000321: {
    country: 'Spain - ES (011)',
    city: 'TORREVIEJA (ALICANTE)',
    name: 'ADUANA DE TORREVIEJA',
  },
  ES000401: {
    country: 'Spain - ES (011)',
    city: 'ALMERÍA',
    name: 'ADUANA AEROPUERTO DE ALMERÍA',
  },
  ES000411: {
    country: 'Spain - ES (011)',
    city: 'ALMERIA',
    name: 'ADUANA DE ALMERIA-MARÍTIMA',
  },
  ES000415: {
    country: 'Spain - ES (011)',
    city: 'ALMERÍA',
    name: 'ALMERÍA-MARÍTIMA CARBONERAS',
  },
  ES000441: {
    country: 'Spain - ES (011)',
    city: 'ALMERIA',
    name: 'ADUANA DE ALMERIA-CARRETERA',
  },
  ES000641: {
    country: 'Spain - ES (011)',
    city: 'BADAJOZ',
    name: 'ADUANA DE BADAJOZ-CARRETERA.',
  },
  ES000671: {
    country: 'Spain - ES (011)',
    city: 'BADAJOZ',
    name: 'ADUANA DE BADAJOZ-FERROCARRIL',
  },
  ES000701: {
    country: 'Spain - ES (011)',
    city: 'PALMA DE MALLORCA',
    name: 'ADUANA AEROPUERTO PALMA MALLORCA',
  },
  ES000707: {
    country: 'Spain - ES (011)',
    city: 'IBIZA (BALEARES)',
    name: 'ADUANA AEROPUERTO DE IBIZA',
  },
  ES000708: {
    country: 'Spain - ES (011)',
    city: 'MAHÓN/MAÓ',
    name: 'ADUANA AEROPUERTO DE MAHÓN',
  },
  ES000711: {
    country: 'Spain - ES (011)',
    city: 'PALMA DE MALLORCA',
    name: 'ADUANA DE PALMA DE MALLORCA',
  },
  ES000717: {
    country: 'Spain - ES (011)',
    city: 'PALMA DE MALLORCA',
    name: 'ADUANA DE ALCUDIA',
  },
  ES000721: {
    country: 'Spain - ES (011)',
    city: 'IBIZA',
    name: 'ADUANA DE IBIZA MARITIMA',
  },
  ES000725: {
    country: 'Spain - ES (011)',
    city: 'IBIZA-ISLAS BALEARES',
    name: 'ADUANA DE FORMENTERA-LA SAVINA',
  },
  ES000731: {
    country: 'Spain - ES (011)',
    city: 'MAHON',
    name: 'ADUANA DE MAHON - MARITIMA',
  },
  ES000801: {
    country: 'Spain - ES (011)',
    city: 'EL PRAT DE LLOBREGAT (BARCELONA)',
    name: 'ADUANA AEROPUERTO BARCELONA EL PRAT',
  },
  ES000811: {
    country: 'Spain - ES (011)',
    city: 'BARCELONA',
    name: 'ADUANA DE BARCELONA - MARÍTIMA',
  },
  ES000812: {
    country: 'Spain - ES (011)',
    city: 'BARCELONA',
    name: 'ADUANA DE BARCELONA-MARÍTIMA EXPORT',
  },
  ES000821: {
    country: 'Spain - ES (011)',
    city: 'BARCELONA',
    name: 'ADUANA DE BARCELONA-ZONA FRANCA',
  },
  ES000841: {
    country: 'Spain - ES (011)',
    city: 'BARCELONA',
    name: 'ADUANA DE BARCELONA - CARRETERA',
  },
  ES000851: {
    country: 'Spain - ES (011)',
    city: 'BARCELONA',
    name: 'ADUANA DE BARCELONA FERIA MUESTRAS',
  },
  ES000855: {
    country: 'Spain - ES (011)',
    city: 'BARCELONA',
    name: 'ADUANA DE BARCELONA CENTRALIZADORA',
  },
  ES000860: {
    country: 'Spain - ES (011)',
    city: 'BARCELONA',
    name: 'ADUANA DE BARCELONA-DOMICILIACIÓN',
  },
  ES000881: {
    country: 'Spain - ES (011)',
    city: 'BARCELONA',
    name: 'ADUANA DE BARCELONA-FFCC.- MORROT',
  },
  ES000941: {
    country: 'Spain - ES (011)',
    city: 'BURGOS',
    name: 'ADUANA DE BURGOS CARRETERA',
  },
  ES001041: {
    country: 'Spain - ES (011)',
    city: 'CÁCERES',
    name: 'ADUANA DE CÁCERES-CARRETERA',
  },
  ES001101: {
    country: 'Spain - ES (011)',
    city: 'JEREZ DE LA FRONTERA (CADIZ)',
    name: 'ADUANA AEROPUERTO JEREZ DE LA FRONT',
  },
  ES001111: {
    country: 'Spain - ES (011)',
    city: 'CADIZ',
    name: 'ADUANA DE CADIZ - PRINCIPAL',
  },
  ES001115: {
    country: 'Spain - ES (011)',
    city: 'CADIZ',
    name: 'ADUANA DE CADIZ - ZONA FRANCA',
  },
  ES001130: {
    country: 'Spain - ES (011)',
    city: 'ALGECIRAS - (CÁDIZ)',
    name: 'ADUANA DE ALGECIRAS DOMICILIACIÓN',
  },
  ES001131: {
    country: 'Spain - ES (011)',
    city: 'ALGECIRAS (CADIZ)',
    name: 'ADUANA DE ALGECIRAS MARÍTIMA',
  },
  ES001133: {
    country: 'Spain - ES (011)',
    city: 'ALGECIRAS (CADIZ)',
    name: 'ADUANA DE ALGECIRAS- TARIFA',
  },
  ES001136: {
    country: 'Spain - ES (011)',
    city: 'ALGECIRAS (CADIZ)',
    name: 'ADUANA DE ALGECIRAS HELIPUERTO',
  },
  ES001141: {
    country: 'Spain - ES (011)',
    city: 'CADIZ',
    name: 'ADUANA DE CADIZ - CARRETERA',
  },
  ES001161: {
    country: 'Spain - ES (011)',
    city: 'LA LÍNEA DE LA CONCEPCIÓN (CÁDIZ)',
    name: 'ADUANA DE LÍNEA DE CONCEPCIÓN-MAR',
  },
  ES001165: {
    country: 'Spain - ES (011)',
    city: 'LA LÍNEA DE LA CONCEPCIÓN (CÁDIZ)',
    name: 'ADUANA DE LA LINEA DOMICILIACION',
  },
  ES001167: {
    country: 'Spain - ES (011)',
    city: 'LA LINEA DE LA CONCEPCIÓN (CADIZ)',
    name: 'ADUANA DE LA LINEA DE LA CONCEPCIÓN',
  },
  ES001175: {
    country: 'Spain - ES (011)',
    city: 'ALGECIRAS (CÁDIZ)',
    name: 'ADUANA DE ALGECIRAS CENTRALIZADORA',
  },
  ES001211: {
    country: 'Spain - ES (011)',
    city: 'CASTELLON DE LA PLANA',
    name: 'ADUANA DE CASTELLON DE LA PLANA',
  },
  ES001241: {
    country: 'Spain - ES (011)',
    city: 'GRAO-CASTELLON DE LA PLANA',
    name: 'ADUANA DE CASTELLON - CARRETERA',
  },
  ES001301: {
    country: 'Spain - ES (011)',
    city: 'CIUDAD REAL',
    name: 'ADUANA DE CIUDAD REAL AEROPUERTO',
  },
  ES001341: {
    country: 'Spain - ES (011)',
    city: 'CIUDAD REAL',
    name: 'ADUANA DE CIUDAD REAL CARRETERA',
  },
  ES001441: {
    country: 'Spain - ES (011)',
    city: 'CÓRDOBA',
    name: 'ADUANA DE CORDOBA CARRETERA',
  },
  ES001501: {
    country: 'Spain - ES (011)',
    city: 'A CORUÑA',
    name: 'ADUANA AEROPUERTO A CORUÑA-ALVEDRO',
  },
  ES001507: {
    country: 'Spain - ES (011)',
    city: 'LABACOLLA (A CORUÑA)',
    name: 'ADUANA AEROPUERTO SANTIAGO-LABACOLL',
  },
  ES001511: {
    country: 'Spain - ES (011)',
    city: 'A CORUÑA',
    name: 'ADUANA DE A CORUÑA',
  },
  ES001517: {
    country: 'Spain - ES (011)',
    city: 'A CORUÑA',
    name: 'ADUANA DE A CORUÑA-DEPOSITO FRANCO',
  },
  ES001521: {
    country: 'Spain - ES (011)',
    city: 'FERROL (A CORUÑA)',
    name: 'ADUANA DE EL FERROL-MARITIMA',
  },
  ES001541: {
    country: 'Spain - ES (011)',
    city: 'A CORUÑA',
    name: 'ADUANA DE A CORUÑA CARRETERA',
  },
  ES001551: {
    country: 'Spain - ES (011)',
    city: 'FERROL (A CORUÑA)',
    name: 'ADUANA DE EL FERROL - CARRETERA',
  },
  ES001641: {
    country: 'Spain - ES (011)',
    city: 'CUENCA',
    name: 'ADUANA DE CUENCA CARRETERA',
  },
  ES001701: {
    country: 'Spain - ES (011)',
    city: 'VILOBI D´ONYAR',
    name: 'ADUANA AEROPUERTO GIRONA-COSTA BRAV',
  },
  ES001711: {
    country: 'Spain - ES (011)',
    city: 'PALAMOS (GERONA)',
    name: 'ADUANA DE PALAMOS-MARITIMA',
  },
  ES001741: {
    country: 'Spain - ES (011)',
    city: 'LA JUNQUERA (GERONA)',
    name: 'ADUANA DE LA JUNQUERA',
  },
  ES001755: {
    country: 'Spain - ES (011)',
    city: 'LA JUNQUERA (GERONA)',
    name: 'ADUANA DE LA JUNQUERA-CENTRALIZADOR',
  },
  ES001761: {
    country: 'Spain - ES (011)',
    city: 'VILLAMALLA (GERONA)',
    name: 'ADUANA DE VILLAMALLA',
  },
  ES001771: {
    country: 'Spain - ES (011)',
    city: 'PORT BOU',
    name: 'ADUANA DE PORT BOU - FERROCARRIL',
  },
  ES001801: {
    country: 'Spain - ES (011)',
    city: 'GRANADA',
    name: 'ADUANA DE GRANADA-AEROPUERTO',
  },
  ES001811: {
    country: 'Spain - ES (011)',
    city: 'MOTRIL (GRANADA)',
    name: 'ADUANA DE MOTRIL',
  },
  ES001841: {
    country: 'Spain - ES (011)',
    city: 'MOTRIL (GRANADA)',
    name: 'ADUANA DE MOTRIL-CARRETERA',
  },
  ES001941: {
    country: 'Spain - ES (011)',
    city: 'GUADALAJARA',
    name: 'ADUANA DE GUADALAJARA CARRETERA',
  },
  ES001950: {
    country: 'Spain - ES (011)',
    city: 'GUADALAJARA',
    name: 'ADUANA DE GUADALAJARA DOMICILIACIÓN',
  },
  ES002001: {
    country: 'Spain - ES (011)',
    city: 'FUENTERRABÍA (GUIPÚZCOA)',
    name: 'ADUANA AEROPUERTO IRÚN',
  },
  ES002011: {
    country: 'Spain - ES (011)',
    city: 'PASAJES (GUIPUZCOA)',
    name: 'ADUANA DE PASAJES - MARÍTIMA',
  },
  ES002045: {
    country: 'Spain - ES (011)',
    city: 'IRUN (GUIPUZCOA)',
    name: 'ADUANA DE IRUN-ZAISA',
  },
  ES002051: {
    country: 'Spain - ES (011)',
    city: 'PASAJES (GUIPUZCOA)',
    name: 'ADUANA DE PASAJES CARRETERA',
  },
  ES002071: {
    country: 'Spain - ES (011)',
    city: 'IRÚN (GUIPÚZCOA)',
    name: 'ADUANA DE IRÚN FERROCARRIL',
  },
  ES002111: {
    country: 'Spain - ES (011)',
    city: 'HUELVA',
    name: 'ADUANA DE HUELVA - PRINCIPAL',
  },
  ES002141: {
    country: 'Spain - ES (011)',
    city: 'HUELVA',
    name: 'ADUANA DE HUELVA - CARRETERA',
  },
  ES002341: {
    country: 'Spain - ES (011)',
    city: 'JAEN',
    name: 'ADUANA DE JAÉN DOMICILIACIÓN',
  },
  ES002441: {
    country: 'Spain - ES (011)',
    city: 'LEÓN',
    name: 'ADUANA DE LEÓN CARRETERA',
  },
  ES002541: {
    country: 'Spain - ES (011)',
    city: 'LA FARGA DE MOLES (LLEIDA))',
    name: 'ADUANA DE LA FARGA DE MOLES',
  },
  ES002561: {
    country: 'Spain - ES (011)',
    city: 'LLEIDA',
    name: 'ADUANA DE LLEIDA CARRETERA',
  },
  ES002641: {
    country: 'Spain - ES (011)',
    city: 'LOGROÑO',
    name: 'ADUANA DE LOGROÑO',
  },
  ES002711: {
    country: 'Spain - ES (011)',
    city: 'RIBADEO (LUGO)',
    name: 'ADUANA DE RIBADEO',
  },
  ES002800: {
    country: 'Spain - ES (011)',
    city: 'MADRID',
    name: 'ADUANA DE MADRID-CENTRAL',
  },
  ES002801: {
    country: 'Spain - ES (011)',
    city: 'MADRID',
    name: 'ADUANA AEROPUERTO MADRID-BARAJAS',
  },
  ES002803: {
    country: 'Spain - ES (011)',
    city: 'MADRID',
    name: 'ADUANA POSTAL DE MADRID',
  },
  ES002855: {
    country: 'Spain - ES (011)',
    city: 'MADRID',
    name: 'ADUANA MADRID CENTRALIZADORA',
  },
  ES002861: {
    country: 'Spain - ES (011)',
    city: 'MADRID',
    name: 'ADUANA DE MADRID-DOMICILIACIÓN',
  },
  ES002901: {
    country: 'Spain - ES (011)',
    city: 'MALAGA',
    name: 'ADUANA AEROPUERTO MÁLAGA',
  },
  ES002911: {
    country: 'Spain - ES (011)',
    city: 'MALAGA',
    name: 'ADUANA DE MALAGA-PRINCIPAL',
  },
  ES002915: {
    country: 'Spain - ES (011)',
    city: 'MALAGA',
    name: 'ADUANA DE MALAGA- DOMICILIACIÓN',
  },
  ES002941: {
    country: 'Spain - ES (011)',
    city: 'MALAGA',
    name: 'ADUANA DE MALAGA-CARRETERA',
  },
  ES003001: {
    country: 'Spain - ES (011)',
    city: 'Lo Jurado - Murcia',
    name: 'ADUANA DE MURCIA AEROPUERTO INTERNA',
  },
  ES003011: {
    country: 'Spain - ES (011)',
    city: 'CARTAGENA (MURCIA)',
    name: 'ADUANA DE CARTAGENA - PRINCIPAL',
  },
  ES003051: {
    country: 'Spain - ES (011)',
    city: 'SAN GINES (MURCIA)',
    name: 'ADUANA DE MURCIA - CARRETERA',
  },
  ES003161: {
    country: 'Spain - ES (011)',
    city: 'CIUDAD DEL TRANSPORTE - IMARCOAIN',
    name: 'ADUANA DE PAMPLONA - IMARCOAÍN',
  },
  ES003171: {
    country: 'Spain - ES (011)',
    city: 'NOAÍN (PAMPLONA)',
    name: 'ADUANA DE PAMPLONA - NOAÍN FERROC.',
  },
  ES003241: {
    country: 'Spain - ES (011)',
    city: 'OURENSE',
    name: 'ADUANA DE OURENSE CARRETERA',
  },
  ES003301: {
    country: 'Spain - ES (011)',
    city: 'CASTRILLÓN - AVILES',
    name: 'ADUANA AEROPUERTO DE ASTURIAS',
  },
  ES003311: {
    country: 'Spain - ES (011)',
    city: 'GIJÓN (ASTURIAS)',
    name: 'ADUANA DE GIJON -MARITIMA',
  },
  ES003331: {
    country: 'Spain - ES (011)',
    city: 'AVILÉS (ASTURIAS)',
    name: 'ADUANA DE AVILÉS MARÍTIMA',
  },
  ES003341: {
    country: 'Spain - ES (011)',
    city: 'GIJÓN (ASTURIAS)',
    name: 'ADUANA DE GIJÓN CARRETERA',
  },
  ES003351: {
    country: 'Spain - ES (011)',
    city: 'AVILES (ASTURIAS)',
    name: 'ADUANA DE AVILES CARRETERA',
  },
  ES003371: {
    country: 'Spain - ES (011)',
    city: 'GIJON',
    name: 'ADUANA DE GIJON - FERROCARRIL',
  },
  ES003441: {
    country: 'Spain - ES (011)',
    city: 'PALENCIA',
    name: 'ADUANA DE PALENCIA-CARRETERA',
  },
  ES003541: {
    country: 'Spain - ES (011)',
    city: 'PUERTO DEL ROSARIO-FUERTEVENTURA',
    name: 'ADUANA DE FUERTEVENTURA-MAR. (T1)',
  },
  ES003551: {
    country: 'Spain - ES (011)',
    city: 'PUERTO DEL ROSARIO-FUERTEVENTURA',
    name: 'ADUANA AEROPUERTO FUERTEVENTURA',
  },
  ES003561: {
    country: 'Spain - ES (011)',
    city: 'LANZAROTE-ARRECIFE',
    name: 'ADUANA DE LANZAROTE-MARITIMA (T1)',
  },
  ES003571: {
    country: 'Spain - ES (011)',
    city: 'LANZAROTE',
    name: 'ADUANA AEROPUERTO LANZAROTE',
  },
  ES003581: {
    country: 'Spain - ES (011)',
    city: 'TELDE - GRAN CANARIA',
    name: 'ADUANA AEROPUERTO GRAN CANARIA',
  },
  ES003591: {
    country: 'Spain - ES (011)',
    city: 'LAS PALMAS DE GRAN CANARIA',
    name: 'ADUANA DE LAS PALMAS MARÍTIMA (T1)',
  },
  ES003593: {
    country: 'Spain - ES (011)',
    city: 'LAS PALMAS DE GRAN CANARIA',
    name: 'ADUANA DE GRAN CANARIA POSTAL',
  },
  ES003601: {
    country: 'Spain - ES (011)',
    city: 'VIGO',
    name: 'ADUANA AEROPUERTO VIGO',
  },
  ES003611: {
    country: 'Spain - ES (011)',
    city: 'VIGO',
    name: 'ADUANA DE VIGO-PRINCIPAL',
  },
  ES003621: {
    country: 'Spain - ES (011)',
    city: 'MARIN (PONTEVEDRA)',
    name: 'ADUANA DE MARIN',
  },
  ES003631: {
    country: 'Spain - ES (011)',
    city: 'VILAGARCIA DE AROUSA (PONTEVEDRA)',
    name: 'ADUANA DE VILAGARCIA DE AROUSA-MAR.',
  },
  ES003641: {
    country: 'Spain - ES (011)',
    city: 'VIGO',
    name: 'ADUANA DE VIGO CARRETERA',
  },
  ES003651: {
    country: 'Spain - ES (011)',
    city: 'VILAGARCIA DE AROUSA (PONTEVEDRA)',
    name: 'ADUANA DE VILAGARCIA DE AROUSA CAR.',
  },
  ES003681: {
    country: 'Spain - ES (011)',
    city: 'VIGO',
    name: 'ADUANA DE VIGO-ZONA FRANCA',
  },
  ES003691: {
    country: 'Spain - ES (011)',
    city: 'VIGO - (PONTEVEDRA)',
    name: 'ADUANA DE VIGO DOMICILIACIÓN',
  },
  ES003741: {
    country: 'Spain - ES (011)',
    city: 'SALAMANCA',
    name: 'ADUANA DE SALAMANCA-CARRETERA',
  },
  ES003861: {
    country: 'Spain - ES (011)',
    city: 'SANTA CRUZ DE LA PALMA',
    name: 'ADUANA DE STA CRUZ DE PALMA-MAR.T1',
  },
  ES003871: {
    country: 'Spain - ES (011)',
    city: 'SANTA CRUZ DE LA PALMA',
    name: 'ADUANA AEROPUERTO LA PALMA',
  },
  ES003881: {
    country: 'Spain - ES (011)',
    city: 'GRANADILLA DE ABONA',
    name: 'ADUANA AEROPUERTO TENERIFE SUR',
  },
  ES003883: {
    country: 'Spain - ES (011)',
    city: 'LA LAGUNA-TENERIFE',
    name: 'ADUANA AEROPUERTO TENERIFE NORTE',
  },
  ES003891: {
    country: 'Spain - ES (011)',
    city: 'SANTA CRUZ DE TENERIFE',
    name: 'ADUANA DE TENERIFE-MARÍTIMA',
  },
  ES003893: {
    country: 'Spain - ES (011)',
    city: 'SANTA CRUZ DE TENERIFE',
    name: 'ADUANA DE TENERIFE POSTALES',
  },
  ES003901: {
    country: 'Spain - ES (011)',
    city: 'SANTANDER',
    name: 'ADUANA AEROPUERTO SANTANDER',
  },
  ES003911: {
    country: 'Spain - ES (011)',
    city: 'SANTANDER',
    name: 'ADUANA DE SANTANDER-MARITIMA',
  },
  ES003921: {
    country: 'Spain - ES (011)',
    city: 'SANTANDER',
    name: 'ADUANA DE SANTANDER ZONA FRANCA',
  },
  ES003941: {
    country: 'Spain - ES (011)',
    city: 'SANTANDER',
    name: 'ADUANA DE SANTANDER CARRETERA',
  },
  ES003955: {
    country: 'Spain - ES (011)',
    city: 'SANTANDER',
    name: 'ADUANA DE SANTANDER-CENTRALIZADORA',
  },
  ES004101: {
    country: 'Spain - ES (011)',
    city: 'SEVILLA',
    name: 'ADUANA AEROPUERTO SEVILLA',
  },
  ES004111: {
    country: 'Spain - ES (011)',
    city: 'SEVILLA',
    name: 'ADUANA DE SEVILLA-PRINCIPAL',
  },
  ES004115: {
    country: 'Spain - ES (011)',
    city: 'SEVILLA',
    name: 'SEVILLA-ZONA FRANCA',
  },
  ES004141: {
    country: 'Spain - ES (011)',
    city: 'SEVILLA',
    name: 'ADUANA DE SEVILLA CARRETERA',
  },
  ES004241: {
    country: 'Spain - ES (011)',
    city: 'SORIA',
    name: 'ADUANA DE SORIA-CARRETERA',
  },
  ES004301: {
    country: 'Spain - ES (011)',
    city: 'TARRAGONA',
    name: 'ADUANA TARRAGONA AEROPUERTO (REUS)',
  },
  ES004311: {
    country: 'Spain - ES (011)',
    city: 'TARRAGONA',
    name: 'ADUANA DE TARRAGONA-MARITIMA',
  },
  ES004321: {
    country: 'Spain - ES (011)',
    city: 'TARRAGONA',
    name: 'ADUANA DE SAN CARLOS DE LA RÁPITA',
  },
  ES004341: {
    country: 'Spain - ES (011)',
    city: 'TARRAGONA',
    name: 'ADUANA DE TARRAGONA-CARRETERA',
  },
  ES004350: {
    country: 'Spain - ES (011)',
    city: 'TARRAGONA',
    name: 'ADUANA DE TARRAGONA-DOMICIL. Y DEP.',
  },
  ES004371: {
    country: 'Spain - ES (011)',
    city: 'TARRAGONA',
    name: 'ADUANA DE TARRAGONA-FEROCARRIL',
  },
  ES004541: {
    country: 'Spain - ES (011)',
    city: 'TOLEDO',
    name: 'ADUANA DE TOLEDO DOMICILIACIÓN',
  },
  ES004601: {
    country: 'Spain - ES (011)',
    city: 'MANISES (VALENCIA)',
    name: 'ADUANA AEROPUERTO VALENCIA',
  },
  ES004611: {
    country: 'Spain - ES (011)',
    city: 'VALENCIA',
    name: 'ADUANA DE VALENCIA MARÍTIMA',
  },
  ES004621: {
    country: 'Spain - ES (011)',
    city: 'PUERTO SAGUNTO (VALENCIA)',
    name: 'ADUANA DE SAGUNTO-MARITIMA',
  },
  ES004631: {
    country: 'Spain - ES (011)',
    city: 'GRAO DE GANDIA (VALENCIA)',
    name: 'ADUANA DE GANDIA-MARITIMA',
  },
  ES004641: {
    country: 'Spain - ES (011)',
    city: 'VALENCIA',
    name: 'ADUANA DE VALENCIA CARRETERA',
  },
  ES004647: {
    country: 'Spain - ES (011)',
    city: 'SILLA (VALENCIA)',
    name: 'ADUANA DE SILLA-CARRETERA',
  },
  ES004650: {
    country: 'Spain - ES (011)',
    city: 'VALENCIA',
    name: 'ADUANA DE VALENCIA-DOMICILIACIÓN',
  },
  ES004741: {
    country: 'Spain - ES (011)',
    city: 'VALLADOLID',
    name: 'ADUANA DE VALLADOLID',
  },
  ES004801: {
    country: 'Spain - ES (011)',
    city: 'SONDICA (VIZCAYA)',
    name: 'ADUANA AEROPUERTO BILBAO',
  },
  ES004811: {
    country: 'Spain - ES (011)',
    city: 'SANTURTZI',
    name: 'ADUANA DE BILBAO-MARITIMA',
  },
  ES004825: {
    country: 'Spain - ES (011)',
    city: 'BILBAO',
    name: 'ADUANA DE BILBAO-ZAL',
  },
  ES004842: {
    country: 'Spain - ES (011)',
    city: 'TRAPAGA (VIZCAYA)',
    name: 'ADUANA DE BILBAO - CARRETERA',
  },
  ES004850: {
    country: 'Spain - ES (011)',
    city: 'BILBAO',
    name: 'ADUANA DE BILBAO DOMICILIACIÓN',
  },
  ES005001: {
    country: 'Spain - ES (011)',
    city: 'ZARAGOZA',
    name: 'ADUANA AEROPUERTO ZARAGOZA',
  },
  ES005005: {
    country: 'Spain - ES (011)',
    city: 'ZARAGOZA',
    name: 'ADUANA DE ZARAGOZA - CENTRALIZADORA',
  },
  ES005041: {
    country: 'Spain - ES (011)',
    city: 'ZARAGOZA',
    name: 'ADUANA DE ZARAGOZA CARRETERA/TIR',
  },
  ES005060: {
    country: 'Spain - ES (011)',
    city: 'ZARAGOZA',
    name: 'ADUANA DE ZARAGOZA-DOMICILIACION',
  },
  ES006000: {
    country: 'Spain - ES (011)',
    city: 'MADRID',
    name: 'ADUANA DESPACHO PENÍNSULA-BALEARES',
  },
  ESC17200: {
    country: 'Spain - ES (011)',
    city: 'FIGUERES (GIRONA)',
    name: 'OF. GESTORA DE IMPUESTOS ESPECIALES',
  },
  ESC53200: {
    country: 'Spain - ES (011)',
    city: 'JEREZ DE LA FRONTERA',
    name: 'OF. GESTORA DE IMPUESTOS ESPECIALES',
  },
  ESD01200: {
    country: 'Spain - ES (011)',
    city: 'VITORIA - GASTEIZ',
    name: 'OF. GESTORA DE IMPUESTOS ESPECIALES',
  },
  ESD01600: {
    country: 'Spain - ES (011)',
    city: 'VITORIA (ALAVA)',
    name: 'DEP.ADUANAS E II.EE. ALAVA',
  },
  ESD02200: {
    country: 'Spain - ES (011)',
    city: 'ALBACETE',
    name: 'OF. GESTORA DE IMPUESTOS ESPECIALES',
  },
  ESD02600: {
    country: 'Spain - ES (011)',
    city: 'ALBACETE',
    name: 'DEP.ADUANAS E IIEE ALBACETE',
  },
  ESD03200: {
    country: 'Spain - ES (011)',
    city: 'ALICANTE',
    name: 'OF. GESTORA DE IMPUESTOS ESPECIALES',
  },
  ESD03600: {
    country: 'Spain - ES (011)',
    city: 'ALICANTE',
    name: 'DEP.ADUANAS E II.EE. ALICANTE',
  },
  ESD04200: {
    country: 'Spain - ES (011)',
    city: 'ALMERIA',
    name: 'OF. GESTORA DE IMPUESTOS ESPECIALES',
  },
  ESD04600: {
    country: 'Spain - ES (011)',
    city: 'ALMERIA',
    name: 'DEP.ADUANAS E II.EE. ALMERÍA',
  },
  ESD05200: {
    country: 'Spain - ES (011)',
    city: 'AVILA',
    name: 'OF. GESTORA DE IMPUESTOS ESPECIALES',
  },
  ESD05600: {
    country: 'Spain - ES (011)',
    city: 'AVILA',
    name: 'DEP.ADUANAS E II.EE. AVILA',
  },
  ESD06200: {
    country: 'Spain - ES (011)',
    city: 'BADAJOZ',
    name: 'OF. GESTORA DE IMPUESTOS ESPECIALES',
  },
  ESD06600: {
    country: 'Spain - ES (011)',
    city: 'BADAJOZ',
    name: 'DEP.REG.EXTREMADURA',
  },
  ESD07200: {
    country: 'Spain - ES (011)',
    city: 'PALMA DE MALLORCA',
    name: 'OF. GESTORA DE IMPUESTOS ESPECIALES',
  },
  ESD07600: {
    country: 'Spain - ES (011)',
    city: 'PALMA DE MALLORCA',
    name: 'DEP.REG.ISLAS BALEARES',
  },
  ESD08200: {
    country: 'Spain - ES (011)',
    city: 'BARCELONA',
    name: 'OF. GESTORA DE IMPUESTOS ESPECIALES',
  },
  ESD08600: {
    country: 'Spain - ES (011)',
    city: 'BARCELONA',
    name: 'DEP.REG.CATALUÑA',
  },
  ESD09200: {
    country: 'Spain - ES (011)',
    city: 'BURGOS',
    name: 'OF. GESTORA DE IMPUESTOS ESPECIALES',
  },
  ESD09600: {
    country: 'Spain - ES (011)',
    city: 'BURGOS',
    name: 'DEP.ADUANAS E II.EE. BURGOS',
  },
  ESD10200: {
    country: 'Spain - ES (011)',
    city: 'CACERES',
    name: 'OF. GESTORA DE IMPUESTOS ESPECIALES',
  },
  ESD10600: {
    country: 'Spain - ES (011)',
    city: 'CÁCERES',
    name: 'DEP. ADUANAS E II.EE.CÁCERES',
  },
  ESD11200: {
    country: 'Spain - ES (011)',
    city: 'CADIZ',
    name: 'OF. GESTORA DE IMPUESTOS ESPECIALES',
  },
  ESD11600: {
    country: 'Spain - ES (011)',
    city: 'CADIZ',
    name: 'DEP.ADUANAS E II.EE. CÁDIZ',
  },
  ESD11601: {
    country: 'Spain - ES (011)',
    city: 'ALGECIRAS (CADIZ)',
    name: 'DEP. ADUANAS E IIEE ALGECIRAS',
  },
  ESD12200: {
    country: 'Spain - ES (011)',
    city: 'CASTELLON DE LA PLANA',
    name: 'OF. GESTORA DE IMPUESTOS ESPECIALES',
  },
  ESD12600: {
    country: 'Spain - ES (011)',
    city: 'GRAO-CASTELLON DE LA PLANA',
    name: 'DEP ADUANAS E II.EE. CASTELLÓN',
  },
  ESD13200: {
    country: 'Spain - ES (011)',
    city: 'CIUDAD REAL',
    name: 'OF. GESTORA DE IMPUESTOS ESPECIALES',
  },
  ESD13600: {
    country: 'Spain - ES (011)',
    city: 'CIUDAD REAL',
    name: 'DEP.ADUANAS E II EE. CIUDAD REAL',
  },
  ESD14200: {
    country: 'Spain - ES (011)',
    city: 'CORDOBA',
    name: 'OF. GESTORA DE IMPUESTOS ESPECIALES',
  },
  ESD14600: {
    country: 'Spain - ES (011)',
    city: 'CORDOBA',
    name: 'DEP.ADUANAS E II.EE. CORDOBA',
  },
  ESD15200: {
    country: 'Spain - ES (011)',
    city: 'LA CORUÑA',
    name: 'OF. GESTORA DE IMPUESTOS ESPECIALES',
  },
  ESD15600: {
    country: 'Spain - ES (011)',
    city: 'A CORUÑA',
    name: 'DEP.REG.GALICIA',
  },
  ESD16200: {
    country: 'Spain - ES (011)',
    city: 'CUENCA',
    name: 'OF. GESTORA DE IMPUESTOS ESPECIALES',
  },
  ESD16600: {
    country: 'Spain - ES (011)',
    city: 'CUENCA',
    name: 'DEP ADUANAS E II EE CUENCA',
  },
  ESD17200: {
    country: 'Spain - ES (011)',
    city: 'FIGUERES (GIRONA)',
    name: 'OF. GESTORA DE IMPUESTOS ESPECIALES',
  },
  ESD17600: {
    country: 'Spain - ES (011)',
    city: 'LA JUNQUERA (GERONA)',
    name: 'DEP. ADUANAS E IIEE GERONA',
  },
  ESD18200: {
    country: 'Spain - ES (011)',
    city: 'GRANADA',
    name: 'OF. GESTORA DE IMPUESTOS ESPECIALES',
  },
  ESD18600: {
    country: 'Spain - ES (011)',
    city: 'GRANADA',
    name: 'DEP. ADUANAS E II.EE. GRANADA',
  },
  ESD19200: {
    country: 'Spain - ES (011)',
    city: 'GUADALAJARA',
    name: 'OF. GESTORA DE IMPUESTOS ESPECIALES',
  },
  ESD19600: {
    country: 'Spain - ES (011)',
    city: 'GUADALAJARA',
    name: 'DEP. ADUANAS E II .EE. GUADALAJARA',
  },
  ESD20200: {
    country: 'Spain - ES (011)',
    city: 'SAN SEBASTIAN',
    name: 'OF. GESTORA DE IMPUESTOS ESPECIALES',
  },
  ESD20600: {
    country: 'Spain - ES (011)',
    city: 'IRUN (GUIPUZCOA)',
    name: 'DEP.ADUANAS E II.EE. GIPUZKOA',
  },
  ESD21200: {
    country: 'Spain - ES (011)',
    city: 'HUELVA',
    name: 'OF. GESTORA DE IMPUESTOS ESPECIALES',
  },
  ESD21600: {
    country: 'Spain - ES (011)',
    city: 'HUELVA',
    name: 'DEP. ADUANAS E II.EE. HUELVA',
  },
  ESD22200: {
    country: 'Spain - ES (011)',
    city: 'HUESCA',
    name: 'OF. GESTORA DE IMPUESTOS ESPECIALES',
  },
  ESD22600: {
    country: 'Spain - ES (011)',
    city: 'HUESCA',
    name: 'DEP.ADUANAS E II.EE. HUESCA',
  },
  ESD23200: {
    country: 'Spain - ES (011)',
    city: 'JAEN',
    name: 'OF. GESTORA DE IMPUESTOS ESPECIALES',
  },
  ESD23600: {
    country: 'Spain - ES (011)',
    city: 'JAEN',
    name: 'DEP.ADUANAS E II.EE. JAEN',
  },
  ESD24200: {
    country: 'Spain - ES (011)',
    city: 'LEON',
    name: 'OF. GESTORA DE IMPUESTOS ESPECIALES',
  },
  ESD24600: {
    country: 'Spain - ES (011)',
    city: 'LEÓN',
    name: 'DEP.ADUANAS E II.EE. LEÓN',
  },
  ESD25200: {
    country: 'Spain - ES (011)',
    city: 'LLEIDA',
    name: 'OF. GESTORA DE IMPUESTOS ESPECIALES',
  },
  ESD25600: {
    country: 'Spain - ES (011)',
    city: 'LA FARGA DE MOLES (LLEIDA))',
    name: 'DEP. ADUANAS E IIEE LLEIDA',
  },
  ESD26200: {
    country: 'Spain - ES (011)',
    city: 'LOGROÑO',
    name: 'OF. GESTORA DE IMPUESTOS ESPECIALES',
  },
  ESD26600: {
    country: 'Spain - ES (011)',
    city: 'LOGROÑO',
    name: 'DEP.REG.LA RIOJA',
  },
  ESD27200: {
    country: 'Spain - ES (011)',
    city: 'LUGO',
    name: 'OF. GESTORA DE IMPUESTOS ESPECIALES',
  },
  ESD27600: {
    country: 'Spain - ES (011)',
    city: 'LUGO',
    name: 'DEP.ADUANAS E II.EE. LUGO',
  },
  ESD28200: {
    country: 'Spain - ES (011)',
    city: 'MADRID',
    name: 'OF. GESTORA DE IMPUESTOS ESPECIALES',
  },
  ESD28600: {
    country: 'Spain - ES (011)',
    city: 'MADRID',
    name: 'DEP.REG.MADRID',
  },
  ESD28920: {
    country: 'Spain - ES (011)',
    city: 'MADRID',
    name: 'S.G. II.EE. - DPTO. ADUANAS E IIEE',
  },
  ESD29200: {
    country: 'Spain - ES (011)',
    city: 'MALAGA',
    name: 'OF. GESTORA DE IMPUESTOS ESPECIALES',
  },
  ESD29600: {
    country: 'Spain - ES (011)',
    city: 'MALAGA',
    name: 'DEP. ADUANAS E IIEE MALAGA',
  },
  ESD30200: {
    country: 'Spain - ES (011)',
    city: 'MURCIA',
    name: 'OF. GESTORA DE IMPUESTOS ESPECIALES',
  },
  ESD30600: {
    country: 'Spain - ES (011)',
    city: 'MURCIA',
    name: 'DEP.REG.MURCIA',
  },
  ESD31200: {
    country: 'Spain - ES (011)',
    city: 'PAMPLONA',
    name: 'OF. GESTORA DE IMPUESTOS ESPECIALES',
  },
  ESD31600: {
    country: 'Spain - ES (011)',
    city: 'IMARCOAIN (PAMPLONA)',
    name: 'DEP.REG.NAVARRA',
  },
  ESD32200: {
    country: 'Spain - ES (011)',
    city: 'OURENSE',
    name: 'OF. GESTORA DE IMPUESTOS ESPECIALES',
  },
  ESD32600: {
    country: 'Spain - ES (011)',
    city: 'OURENSE',
    name: 'DEP.ADUANAS E II.EE. OURENSE',
  },
  ESD33200: {
    country: 'Spain - ES (011)',
    city: 'OVIEDO',
    name: 'OF. GESTORA DE IMPUESTOS ESPECIALES',
  },
  ESD33600: {
    country: 'Spain - ES (011)',
    city: 'OVIEDO',
    name: 'DEP.REG.ASTURIAS',
  },
  ESD34200: {
    country: 'Spain - ES (011)',
    city: 'PALENCIA',
    name: 'OF. GESTORA DE IMPUESTOS ESPECIALES',
  },
  ESD34600: {
    country: 'Spain - ES (011)',
    city: 'PALENCIA',
    name: 'DEP.ADUANAS E II.EE. PALENCIA',
  },
  ESD35600: {
    country: 'Spain - ES (011)',
    city: 'LAS PALMAS DE GRAN CANARIA',
    name: 'DEP.REG.ISLAS CANARIAS',
  },
  ESD36200: {
    country: 'Spain - ES (011)',
    city: 'MARIN (PONTEVEDRA)',
    name: 'OF. GESTORA DE IMPUESTOS ESPECIALES',
  },
  ESD36600: {
    country: 'Spain - ES (011)',
    city: 'PONTEVEDRA',
    name: 'DEP.ADUANAS E II.EE. PONTEVEDRA',
  },
  ESD37200: {
    country: 'Spain - ES (011)',
    city: 'SALAMANCA',
    name: 'OF. GESTORA DE IMPUESTOS ESPECIALES',
  },
  ESD37600: {
    country: 'Spain - ES (011)',
    city: 'SALAMANCA',
    name: 'DEP.ADUANAS E II.EE. SALAMANCA',
  },
  ESD38600: {
    country: 'Spain - ES (011)',
    city: 'SANTA CRUZ DE TENERIFE',
    name: 'DEP ADUANAS E II.EE. TENERIFE',
  },
  ESD39200: {
    country: 'Spain - ES (011)',
    city: 'SANTANDER',
    name: 'OF. GESTORA DE IMPUESTOS ESPECIALES',
  },
  ESD39600: {
    country: 'Spain - ES (011)',
    city: 'SANTANDER',
    name: 'DEP.REG.CANTABRIA',
  },
  ESD40200: {
    country: 'Spain - ES (011)',
    city: 'SEGOVIA',
    name: 'OF. GESTORA DE IMPUESTOS ESPECIALES',
  },
  ESD40600: {
    country: 'Spain - ES (011)',
    city: 'SEGOVIA',
    name: 'DEP.ADUANAS E II.EE. SEGOVIA',
  },
  ESD41200: {
    country: 'Spain - ES (011)',
    city: 'SEVILLA',
    name: 'OF. GESTORA DE IMPUESTOS ESPECIALES',
  },
  ESD41600: {
    country: 'Spain - ES (011)',
    city: 'SEVILLA',
    name: 'DEP.REG.ANDALUCÍA,CEUTA Y MELILLA',
  },
  ESD42200: {
    country: 'Spain - ES (011)',
    city: 'SORIA',
    name: 'OF. GESTORA DE IMPUESTOS ESPECIALES',
  },
  ESD42600: {
    country: 'Spain - ES (011)',
    city: 'SORIA',
    name: 'DEP.ADUANAS E II.EE. SORIA',
  },
  ESD43200: {
    country: 'Spain - ES (011)',
    city: 'TARRAGONA',
    name: 'OF. GESTORA DE IMPUESTOS ESPECIALES',
  },
  ESD43600: {
    country: 'Spain - ES (011)',
    city: 'TARRAGONA',
    name: 'DEP ADUANAS E II.EE. TARRAGONA',
  },
  ESD44200: {
    country: 'Spain - ES (011)',
    city: 'TERUEL',
    name: 'OF. GESTORA DE IMPUESTOS ESPECIALES',
  },
  ESD44600: {
    country: 'Spain - ES (011)',
    city: 'TERUEL',
    name: 'DEP.ADUANAS E II.EE. TERUEL',
  },
  ESD45200: {
    country: 'Spain - ES (011)',
    city: 'TOLEDO',
    name: 'OF. GESTORA DE IMPUESTOS ESPECIALES',
  },
  ESD45600: {
    country: 'Spain - ES (011)',
    city: 'TOLEDO',
    name: 'DEP.REG.CASTILLA LA MANCHA',
  },
  ESD46200: {
    country: 'Spain - ES (011)',
    city: 'VALENCIA',
    name: 'OF. GESTORA DE IMPUESTOS ESPECIALES',
  },
  ESD46600: {
    country: 'Spain - ES (011)',
    city: 'VALENCIA',
    name: 'DEP.REG.VALENCIA',
  },
  ESD47200: {
    country: 'Spain - ES (011)',
    city: 'VALLADOLID',
    name: 'OF. GESTORA DE IMPUESTOS ESPECIALES',
  },
  ESD47600: {
    country: 'Spain - ES (011)',
    city: 'VALLADOLID',
    name: 'DEP.REG.CASTILLA LEÓN',
  },
  ESD48200: {
    country: 'Spain - ES (011)',
    city: 'BILBAO',
    name: 'OF. GESTORA DE IMPUESTOS ESPECIALES',
  },
  ESD48600: {
    country: 'Spain - ES (011)',
    city: 'BILBAO',
    name: 'DEP.REG.PAIS VASCO',
  },
  ESD49200: {
    country: 'Spain - ES (011)',
    city: 'ZAMORA',
    name: 'OF. GESTORA DE IMPUESTOS ESPECIALES',
  },
  ESD49600: {
    country: 'Spain - ES (011)',
    city: 'ZAMORA',
    name: 'DEP.ADUANAS E II.EE. ZAMORA',
  },
  ESD50200: {
    country: 'Spain - ES (011)',
    city: 'ZARAGOZA',
    name: 'OF. GESTORA DE IMPUESTOS ESPECIALES',
  },
  ESD50600: {
    country: 'Spain - ES (011)',
    city: 'ZARAGOZA',
    name: 'DEP.REG.ARAGON',
  },
  ESD51200: {
    country: 'Spain - ES (011)',
    city: 'CARTAGENA (MURCIA)',
    name: 'OF. GESTORA DE IMPUESTOS ESPECIALES',
  },
  ESD51600: {
    country: 'Spain - ES (011)',
    city: 'CARTAGENA (MURCIA)',
    name: 'SEDE DESCONCENTRADA DE CARTAGENA',
  },
  ESD52200: {
    country: 'Spain - ES (011)',
    city: 'GIJON',
    name: 'OF. GESTORA DE IMPUESTOS ESPECIALES',
  },
  ESD52600: {
    country: 'Spain - ES (011)',
    city: 'GIJÓN (ASTURIAS)',
    name: 'DEP.ADUANAS E II.EE. GIJÓN',
  },
  ESD53200: {
    country: 'Spain - ES (011)',
    city: 'JEREZ DE LA FRONTERA (CADIZ)',
    name: 'OF. GESTORA DE IMPUESTOS ESPECIALES',
  },
  ESD53600: {
    country: 'Spain - ES (011)',
    city: 'JEREZ DE LA FRONTERA (CADIZ)',
    name: 'DEP. ADUANAS E IIEE JEREZ FRONTERA',
  },
  ESD54200: {
    country: 'Spain - ES (011)',
    city: 'VIGO (PONTEVEDRA)',
    name: 'OF. GESTORA DE IMPUESTOS ESPECIALES',
  },
  ESD54600: {
    country: 'Spain - ES (011)',
    city: 'VIGO',
    name: 'DEP.ADUANAS E II.EE. VIGO',
  },
  ESD57960: {
    country: 'Spain - ES (011)',
    city: 'MADRID',
    name: 'DEPARTAMENTO DE ADUANAS E II.EE.',
  },
  FI001000: {
    country: 'Finland - FI (032)',
    city: 'Helsinki',
    name: 'Tulli, Valvontaosasto Rajoitukset',
  },
  FI001200: {
    country: 'Finland - FI (032)',
    city: 'Helsinki',
    name: 'Tulli Hallinto',
  },
  FI001210: {
    country: 'Finland - FI (032)',
    city: 'Helsinki',
    name: 'Ulkomaankauppa- ja verotus Tulli',
  },
  FI001400: {
    country: 'Finland - FI (032)',
    city: 'Helsinki',
    name: 'HELSINKI Eteläsatama Tulli',
  },
  FI001602: {
    country: 'Finland - FI (032)',
    city: 'Helsinki',
    name: 'HELSINKI Katajanokka K7 Tulli',
  },
  FI001604: {
    country: 'Finland - FI (032)',
    city: 'Helsinki',
    name: 'Helsinki Tulli KatajanokkaK9',
  },
  FI001801: {
    country: 'Finland - FI (032)',
    city: 'Helsinki',
    name: 'Helsinki Länsisatama L4 Tulli',
  },
  FI001802: {
    country: 'Finland - FI (032)',
    city: 'Helsinki',
    name: 'Helsinki Tulli Länsisatama L5',
  },
  FI002000: {
    country: 'Finland - FI (032)',
    city: 'Helsinki',
    name: 'Sähköinen Palvelukeskus',
  },
  FI002001: {
    country: 'Finland - FI (032)',
    city: 'Helsinki',
    name: 'Sähköinen Passituskeskus',
  },
  FI002002: {
    country: 'Finland - FI (032)',
    city: 'Helsinki',
    name: 'Tulli, Tullauskeskus Vienti',
  },
  FI002006: {
    country: 'Finland - FI (032)',
    city: 'Tornio',
    name: 'Tulli, Sähköinen Palvelukeskus',
  },
  FI005000: {
    country: 'Finland - FI (032)',
    city: 'Helsinki',
    name: 'Helsinki Tulli',
  },
  FI009800: {
    country: 'Finland - FI (032)',
    city: 'Helsinki',
    name: 'Meritulli Vuosaari satama Gatehouse',
  },
  FI009801: {
    country: 'Finland - FI (032)',
    city: 'Helsinki',
    name: 'Meritulli Vuosaari satama V6',
  },
  FI009802: {
    country: 'Finland - FI (032)',
    city: 'Helsinki',
    name: 'Meritulli Vuosaari Hansaterminaali',
  },
  FI015300: {
    country: 'Finland - FI (032)',
    city: 'Vantaa',
    name: 'Lentotulli – Helsinki-Vantaa– Rahti',
  },
  FI015301: {
    country: 'Finland - FI (032)',
    city: 'Helsinki',
    name: 'Vantaa Postitulli',
  },
  FI015302: {
    country: 'Finland - FI (032)',
    city: 'Vantaa',
    name: 'Helsinki-Vantaa Lento MatkustajaT',
  },
  FI077099: {
    country: 'Finland - FI (032)',
    city: 'Helsinki',
    name: 'Verohallinto',
  },
  FI079000: {
    country: 'Finland - FI (032)',
    city: 'Valko',
    name: 'Loviisa Satama Tulli',
  },
  FI108200: {
    country: 'Finland - FI (032)',
    city: 'Lappohja',
    name: 'Lappohjan Satama',
  },
  FI109000: {
    country: 'Finland - FI (032)',
    city: 'Hanko',
    name: 'Hanko Tulli',
  },
  FI109001: {
    country: 'Finland - FI (032)',
    city: 'Hanko',
    name: 'Hanko Tulli Vapaasatama',
  },
  FI151000: {
    country: 'Finland - FI (032)',
    city: 'Lahti',
    name: 'Lahti Tulli TULLI',
  },
  FI201000: {
    country: 'Finland - FI (032)',
    city: 'Turku',
    name: 'Turku Tulli TULLI',
  },
  FI201003: {
    country: 'Finland - FI (032)',
    city: 'Turku',
    name: 'Turku Tulli',
  },
  FI203600: {
    country: 'Finland - FI (032)',
    city: 'Turku',
    name: 'Turku Lentotulli',
  },
  FI211000: {
    country: 'Finland - FI (032)',
    city: 'Naantali',
    name: 'Naantali TULLI',
  },
  FI221001: {
    country: 'Finland - FI (032)',
    city: 'Mariehamn',
    name: 'Mariehamn Tull Hamnterminal',
  },
  FI222700: {
    country: 'Finland - FI (032)',
    city: 'Eckerö',
    name: 'Eckerö Tulli TULL',
  },
  FI226300: {
    country: 'Finland - FI (032)',
    city: 'Lumparland',
    name: 'Långnäs Tulli',
  },
  FI235000: {
    country: 'Finland - FI (032)',
    city: 'Uusikaupunki',
    name: 'Uusikaupunki Tulli TULLI',
  },
  FI261000: {
    country: 'Finland - FI (032)',
    city: 'Rauma',
    name: 'Rauma TULLI',
  },
  FI288800: {
    country: 'Finland - FI (032)',
    city: 'Pori',
    name: 'Pori Tulli',
  },
  FI339000: {
    country: 'Finland - FI (032)',
    city: 'Tampere',
    name: 'Tampere Tulli',
  },
  FI403200: {
    country: 'Finland - FI (032)',
    city: 'Jyväskylä',
    name: 'Jyväskylä Tulli TULLI',
  },
  FI482000: {
    country: 'Finland - FI (032)',
    city: 'Kotka',
    name: 'Kotka Hietanen Tulli',
  },
  FI483100: {
    country: 'Finland - FI (032)',
    city: 'Kotka',
    name: 'Kotka Tulli TULLI',
  },
  FI494600: {
    country: 'Finland - FI (032)',
    city: 'Hamina',
    name: 'Hamina TULLI',
  },
  FI499300: {
    country: 'Finland - FI (032)',
    city: 'VAALIMAA',
    name: 'VAALIMAA TULLI',
  },
  FI542300: {
    country: 'Finland - FI (032)',
    city: 'Nuijamaa',
    name: 'Nuijamaa Tulli TULLI',
  },
  FI542700: {
    country: 'Finland - FI (032)',
    city: 'Vainikkala',
    name: 'Vainikkala Tulli TULLI',
  },
  FI556100: {
    country: 'Finland - FI (032)',
    city: 'Imatra',
    name: 'Imatra Tulli TULLI',
  },
  FI642600: {
    country: 'Finland - FI (032)',
    city: 'Kaskinen',
    name: 'Kaskinen Tulli TULLI',
  },
  FI651700: {
    country: 'Finland - FI (032)',
    city: 'Vaasa',
    name: 'Vaasa TULLI',
  },
  FI651704: {
    country: 'Finland - FI (032)',
    city: 'Vaasa',
    name: 'Vaasa Lupakeskus',
  },
  FI671000: {
    country: 'Finland - FI (032)',
    city: 'Kokkola',
    name: 'Kokkola Tulli TULLI',
  },
  FI686000: {
    country: 'Finland - FI (032)',
    city: 'Pietarsaari',
    name: 'Pietarsaari Satama Tulli',
  },
  FI826750: {
    country: 'Finland - FI (032)',
    city: 'NIIRALA',
    name: 'NIIRALA TULLI',
  },
  FI889300: {
    country: 'Finland - FI (032)',
    city: 'Lentiira',
    name: 'Vartius Tulli TULLI',
  },
  FI904000: {
    country: 'Finland - FI (032)',
    city: 'OULU',
    name: 'OULU TULLI',
  },
  FI904001: {
    country: 'Finland - FI (032)',
    city: 'OULUNSALO',
    name: 'OULU LENTOTULLI',
  },
  FI904006: {
    country: 'Finland - FI (032)',
    city: 'Oulu',
    name: 'Oulu, Lupakeskus',
  },
  FI939990: {
    country: 'Finland - FI (032)',
    city: 'Kuusamo',
    name: 'Kuusamo Tulli TULLI',
  },
  FI941000: {
    country: 'Finland - FI (032)',
    city: 'Kemi',
    name: 'Kemin Satama Tulli',
  },
  FI954000: {
    country: 'Finland - FI (032)',
    city: 'Tornio',
    name: 'Tornio Tulli TULLI',
  },
  FI954001: {
    country: 'Finland - FI (032)',
    city: 'Tornio',
    name: 'Tornio TULLI',
  },
  FI989200: {
    country: 'Finland - FI (032)',
    city: 'Kelloselkä',
    name: 'Salla Tulli TULLI',
  },
  FI994400: {
    country: 'Finland - FI (032)',
    city: 'Leppäjärvi',
    name: 'Kivilompolo Tulli TULLI',
  },
  FI994900: {
    country: 'Finland - FI (032)',
    city: 'Kilpisjärvi',
    name: 'Kilpisjärvi Tulli TULLI',
  },
  FI998001: {
    country: 'Finland - FI (032)',
    city: 'Ivalo',
    name: 'Raja-Jooseppi Tulli TULLI',
  },
  FI998002: {
    country: 'Finland - FI (032)',
    city: 'Näätämö',
    name: 'Näätämö tulli TULLI',
  },
  FI999500: {
    country: 'Finland - FI (032)',
    city: 'Karigasniemi',
    name: 'Karigasniemi Tulli TULLI',
  },
  FI999800: {
    country: 'Finland - FI (032)',
    city: 'Utsjoki',
    name: 'Utsjoki Tulli TULLI',
  },
  FI999801: {
    country: 'Finland - FI (032)',
    city: 'Utsjoki',
    name: 'Polmak Tulli TULLI',
  },
  FR000040: {
    country: 'France - FR (001)',
    city: 'AJACCIO',
    name: 'Ajaccio port',
  },
  FR000100: {
    country: 'France - FR (001)',
    city: 'AMIENS',
    name: 'Amiens bureau',
  },
  FR000120: {
    country: 'France - FR (001)',
    city: 'ANGERS',
    name: 'Angers bureau',
  },
  FR000130: {
    country: 'France - FR (001)',
    city: 'ANGOULEME',
    name: 'Angouleme bureau',
  },
  FR000140: {
    country: 'France - FR (001)',
    city: 'PRINGY',
    name: 'Annecy bureau',
  },
  FR000170: {
    country: 'France - FR (001)',
    city: 'DUNKERQUE',
    name: 'Dunkerque energies bureau',
  },
  FR000220: {
    country: 'France - FR (001)',
    city: 'ARCACHON',
    name: 'Arcachon port bureau',
  },
  FR000270: {
    country: 'France - FR (001)',
    city: 'AVIGNON',
    name: 'Avignon bureau',
  },
  FR000340: {
    country: 'France - FR (001)',
    city: 'SAINT LOUIS CEDEX',
    name: 'Bale mulhouse aero bureau',
  },
  FR000380: {
    country: 'France - FR (001)',
    city: 'VILLE DI PIETRABUGNO',
    name: 'Bastia port',
  },
  FR000390: {
    country: 'France - FR (001)',
    city: 'BAYONNE',
    name: 'Bayonne bureau',
  },
  FR000460: {
    country: 'France - FR (001)',
    city: 'ECOLE VALENTIN',
    name: 'Besancon bureau',
  },
  FR000600: {
    country: 'France - FR (001)',
    city: 'MERIGNAC',
    name: 'Bordeaux merignac bureau',
  },
  FR000610: {
    country: 'France - FR (001)',
    city: 'CARBON BLANC',
    name: 'Bordeaux bassens pt bureau',
  },
  FR000630: {
    country: 'France - FR (001)',
    city: 'OUTREAU',
    name: 'Boulogne bureau',
  },
  FR000690: {
    country: 'France - FR (001)',
    city: 'BREST',
    name: 'Brest bureau',
  },
  FR000720: {
    country: 'France - FR (001)',
    city: 'CAEN',
    name: 'Caen bureau',
  },
  FR000740: {
    country: 'France - FR (001)',
    city: 'CALAIS',
    name: 'Calais bureau',
  },
  FR000800: {
    country: 'France - FR (001)',
    city: 'CANNES',
    name: 'Cannes bureau',
  },
  FR000870: {
    country: 'France - FR (001)',
    city: 'CHAMBERY',
    name: 'Chambery bureau',
  },
  FR000910: {
    country: 'France - FR (001)',
    city: 'DEOLS CEDEX',
    name: 'Chateauroux bureau',
  },
  FR000950: {
    country: 'France - FR (001)',
    city: 'CHERBOURG',
    name: 'Cherbourg bureau',
  },
  FR000980: {
    country: 'France - FR (001)',
    city: 'CLERMONT-FERRAND',
    name: 'Clermont bureau',
  },
  FR001010: {
    country: 'France - FR (001)',
    city: 'COLMAR',
    name: 'Colmar bureau',
  },
  FR001140: {
    country: 'France - FR (001)',
    city: 'DELLE',
    name: 'Delle bureau',
  },
  FR001170: {
    country: 'France - FR (001)',
    city: 'DIJON',
    name: 'Dijon bureau',
  },
  FR001200: {
    country: 'France - FR (001)',
    city: 'DONGES',
    name: 'Donges raffinerie',
  },
  FR001260: {
    country: 'France - FR (001)',
    city: 'DUNKERQUE',
    name: 'Dunkerque port bureau',
  },
  FR001280: {
    country: 'France - FR (001)',
    city: 'ENNERY',
    name: 'Ennery bureau',
  },
  FR001290: {
    country: 'France - FR (001)',
    city: 'EPINAL',
    name: 'Epinal bureau',
  },
  FR001400: {
    country: 'France - FR (001)',
    city: 'FERNEY-VOLTAIRE',
    name: 'Ferney voltaire bureau',
  },
  FR001560: {
    country: 'France - FR (001)',
    city: "GONFREVILLE L'ORCHER",
    name: 'Gonfreville raffinerie',
  },
  FR001610: {
    country: 'France - FR (001)',
    city: 'Saint egreve Cedex',
    name: 'Grenoble bureau',
  },
  FR00201S: {
    country: 'France - FR (001)',
    city: 'JOUGNE',
    name: 'Ferriere sous jougne',
  },
  FR002030: {
    country: 'France - FR (001)',
    city: 'St quentin fallavier Cedex',
    name: "L'isle d'abeau bureau",
  },
  FR002090: {
    country: 'France - FR (001)',
    city: 'LA ROCHELLE',
    name: 'La rochelle pallice bureau',
  },
  FR002270: {
    country: 'France - FR (001)',
    city: 'LE HAVRE',
    name: 'Le havre antifer bureau',
  },
  FR002300: {
    country: 'France - FR (001)',
    city: 'LE HAVRE',
    name: 'Le Havre Port bureau',
  },
  FR002310: {
    country: 'France - FR (001)',
    city: 'PLERIN',
    name: 'St brieuc bureau',
  },
  FR002340: {
    country: 'France - FR (001)',
    city: 'LE MANS',
    name: 'Le mans bureau',
  },
  FR002430: {
    country: 'France - FR (001)',
    city: "LES SABLES D'OLONNE",
    name: "Les sables d'olonne bureau",
  },
  FR002510: {
    country: 'France - FR (001)',
    city: 'PORTA',
    name: 'Porta bureau',
  },
  FR002540: {
    country: 'France - FR (001)',
    city: 'LIMOGES',
    name: 'Limoges bureau',
  },
  FR002600: {
    country: 'France - FR (001)',
    city: 'LORIENT',
    name: 'Lorient bureau',
  },
  FR002630: {
    country: 'France - FR (001)',
    city: 'LYON',
    name: 'Lyon ville bureau',
  },
  FR002650: {
    country: 'France - FR (001)',
    city: 'LYON-SAINT-EXUPERY AEROPORT',
    name: 'Lyon St Exupery bureau',
  },
  FR002710: {
    country: 'France - FR (001)',
    city: 'MARIGNANE',
    name: 'Marignane aero bureau',
  },
  FR002730: {
    country: 'France - FR (001)',
    city: 'MARSEILLE',
    name: 'Marseille port bureau',
  },
  FR002860: {
    country: 'France - FR (001)',
    city: 'MONACO',
    name: 'Monaco bureau',
  },
  FR002970: {
    country: 'France - FR (001)',
    city: 'MORTEAU',
    name: 'Morteau bureau',
  },
  FR00297S: {
    country: 'France - FR (001)',
    city: 'VILLERS-LE-LAC',
    name: 'COL FRANCE BC',
  },
  FR002990: {
    country: 'France - FR (001)',
    city: 'MULHOUSE',
    name: 'Mulhouse bureau',
  },
  FR003000: {
    country: 'France - FR (001)',
    city: 'NANCY',
    name: 'Nancy bureau',
  },
  FR003030: {
    country: 'France - FR (001)',
    city: 'SAINT-AIGNAN DE GRAND LIEU',
    name: 'Nantes atlant. bureau',
  },
  FR003040: {
    country: 'France - FR (001)',
    city: 'NANTES',
    name: 'Nantes viti ci',
  },
  FR003080: {
    country: 'France - FR (001)',
    city: 'NICE',
    name: 'Nice aero bureau',
  },
  FR003100: {
    country: 'France - FR (001)',
    city: 'NICE',
    name: 'Nice port bureau',
  },
  FR003120: {
    country: 'France - FR (001)',
    city: 'NIMES',
    name: 'Nimes bureau',
  },
  FR003160: {
    country: 'France - FR (001)',
    city: 'Saran',
    name: 'Orleans bureau',
  },
  FR003230: {
    country: 'France - FR (001)',
    city: 'PARIS',
    name: 'Paris C.I.',
  },
  FR003260: {
    country: 'France - FR (001)',
    city: 'PARIS',
    name: 'Paris principal bureau',
  },
  FR003350: {
    country: 'France - FR (001)',
    city: 'LE BOURGET AEROPORT',
    name: 'Le Bourget bureau',
  },
  FR003370: {
    country: 'France - FR (001)',
    city: 'PARAY-VIEILLE-POSTE',
    name: 'Orly aero bureau',
  },
  FR003410: {
    country: 'France - FR (001)',
    city: 'GENNEVILLIERS',
    name: 'Gennevilliers bureau',
  },
  FR003440: {
    country: 'France - FR (001)',
    city: 'PAU',
    name: 'Pau bureau',
  },
  FR003480: {
    country: 'France - FR (001)',
    city: 'PERPIGNAN',
    name: 'Perpignan bureau',
  },
  FR003500: {
    country: 'France - FR (001)',
    city: 'ROUEN',
    name: 'Rouen Energies',
  },
  FR003540: {
    country: 'France - FR (001)',
    city: 'POITIERS',
    name: 'Poitiers bureau',
  },
  FR003550: {
    country: 'France - FR (001)',
    city: 'PONTARLIER',
    name: 'Pontarlier bureau',
  },
  FR003620: {
    country: 'France - FR (001)',
    city: 'PORT DE BOUC',
    name: 'Port de Bouc Energies',
  },
  FR003630: {
    country: 'France - FR (001)',
    city: 'NOTRE DAME DE GRAVENCHON',
    name: 'Port jerome raffinerie',
  },
  FR003650: {
    country: 'France - FR (001)',
    city: 'PORT LA NOUVELLE',
    name: 'Port la nouvelle bureau',
  },
  FR003680: {
    country: 'France - FR (001)',
    city: 'PORT SAINT LOUIS DU RHONE',
    name: 'Fos port st louis pt bureau',
  },
  FR003690: {
    country: 'France - FR (001)',
    city: 'PORT VENDRES',
    name: 'Port Vendres port bureau',
  },
  FR003790: {
    country: 'France - FR (001)',
    city: 'REIMS',
    name: 'Reims bureau',
  },
  FR003800: {
    country: 'France - FR (001)',
    city: 'RENNES CEDEX',
    name: 'Rennes bureau',
  },
  FR003920: {
    country: 'France - FR (001)',
    city: 'ROUEN',
    name: 'Rouen port bureau',
  },
  FR003930: {
    country: 'France - FR (001)',
    city: 'ROUEN',
    name: 'Rouen fiscalités bureau',
  },
  FR003940: {
    country: 'France - FR (001)',
    city: 'st avold Cedex',
    name: 'Saint Avold bureau',
  },
  FR003970: {
    country: 'France - FR (001)',
    city: 'Saint etienne Cedex 01',
    name: 'St Etienne bureau',
  },
  FR004020: {
    country: 'France - FR (001)',
    city: 'Saint julien en genevois Cedex',
    name: 'St julien bardon. bureau',
  },
  FR004050: {
    country: 'France - FR (001)',
    city: 'Saint louis Cedex',
    name: 'St louis autoroute',
  },
  FR004060: {
    country: 'France - FR (001)',
    city: 'SAINT MALO',
    name: 'St malo bureau',
  },
  FR004080: {
    country: 'France - FR (001)',
    city: 'MONTOIR DE BRETAGNE',
    name: 'St nazaire montoir bureau',
  },
  FR004250: {
    country: 'France - FR (001)',
    city: 'SETE',
    name: 'Sete bureau',
  },
  FR004310: {
    country: 'France - FR (001)',
    city: 'TANNERIES CEDEX',
    name: 'Strasbourg-Entzheim bureau',
  },
  FR004320: {
    country: 'France - FR (001)',
    city: 'STRASBOURG',
    name: 'Strasbourg energies grand est',
  },
  FR00439A: {
    country: 'France - FR (001)',
    city: 'SAINT-GINGOLPH',
    name: 'SAINT-GINGOLPH BC',
  },
  FR004430: {
    country: 'France - FR (001)',
    city: 'TOULON',
    name: 'Toulon la seyne bureau',
  },
  FR004440: {
    country: 'France - FR (001)',
    city: 'BLAGNAC',
    name: 'Toulouse blagnac aero bureau',
  },
  FR004450: {
    country: 'France - FR (001)',
    city: 'PORTET SUR GARONNE',
    name: 'Toulouse portet bureau',
  },
  FR004490: {
    country: 'France - FR (001)',
    city: 'Tours Cedex 2',
    name: 'Tours bureau',
  },
  FR004520: {
    country: 'France - FR (001)',
    city: 'LA CHAPELLE SAINT LUC',
    name: 'Troyes bureau',
  },
  FR004550: {
    country: 'France - FR (001)',
    city: 'PORTES LES VALENCE',
    name: 'Valence bureau',
  },
  FR004560: {
    country: 'France - FR (001)',
    city: 'Valenciennes Cedex',
    name: 'Valenciennes bureau',
  },
  FR004850: {
    country: 'France - FR (001)',
    city: 'LYON',
    name: 'Lyon energies inter',
  },
  FR004870: {
    country: 'France - FR (001)',
    city: 'LILLE',
    name: 'Lille bureau',
  },
  FR004960: {
    country: 'France - FR (001)',
    city: 'BRIVE',
    name: 'Brive bureau',
  },
  FR004990: {
    country: 'France - FR (001)',
    city: 'COMPIEGNE',
    name: 'Compiegne bureau',
  },
  FR005000: {
    country: 'France - FR (001)',
    city: 'LAVAL',
    name: 'Laval bureau',
  },
  FR005040: {
    country: 'France - FR (001)',
    city: 'Saint quentin cedex',
    name: 'St quentin bureau',
  },
  FR005070: {
    country: 'France - FR (001)',
    city: 'NIORT',
    name: 'Niort bureau',
  },
  FR005090: {
    country: 'France - FR (001)',
    city: 'Vesoul Cedex',
    name: 'Vesoul bureau',
  },
  FR005130: {
    country: 'France - FR (001)',
    city: 'LE PASSAGE',
    name: 'Agen bureau',
  },
  FR005180: {
    country: 'France - FR (001)',
    city: 'NEVERS',
    name: 'Nevers bureau',
  },
  FR005190: {
    country: 'France - FR (001)',
    city: 'NANGIS',
    name: 'Grandpuits raffinerie',
  },
  FR005260: {
    country: 'France - FR (001)',
    city: 'LONS LE SAUNIER',
    name: 'Lons le saunier bureau',
  },
  FR005340: {
    country: 'France - FR (001)',
    city: 'AULNAY SOUS BOIS',
    name: 'Aulnay-sous-Bois bureau',
  },
  FR005350: {
    country: 'France - FR (001)',
    city: 'LESQUIN',
    name: 'Lesquin bureau',
  },
  FR005440: {
    country: 'France - FR (001)',
    city: 'EVRY',
    name: 'Corbeil evry bureau',
  },
  FR005470: {
    country: 'France - FR (001)',
    city: 'CAHORS',
    name: 'Cahors bureau',
  },
  FR005480: {
    country: 'France - FR (001)',
    city: 'ONET LE CHATEAU',
    name: 'Rodez bureau',
  },
  FR005580: {
    country: 'France - FR (001)',
    city: 'HAGUENAU',
    name: 'Haguenau bureau',
  },
  FR005590: {
    country: 'France - FR (001)',
    city: 'Trappes Cedex',
    name: 'Trappes bureau',
  },
  FR005620: {
    country: 'France - FR (001)',
    city: 'ARRAS',
    name: 'Arras bureau',
  },
  FR005680: {
    country: 'France - FR (001)',
    city: 'MONTAUBAN',
    name: 'Montauban bureau',
  },
  FR005800: {
    country: 'France - FR (001)',
    city: 'ALBI',
    name: 'Albi bureau',
  },
  FR005810: {
    country: 'France - FR (001)',
    city: 'AUCH',
    name: 'Auch bureau',
  },
  FR005830: {
    country: 'France - FR (001)',
    city: 'GAP',
    name: 'Gap bureau',
  },
  FR005850: {
    country: 'France - FR (001)',
    city: 'MONT DE MARSAN',
    name: 'Mont de marsan bureau',
  },
  FR005880: {
    country: 'France - FR (001)',
    city: 'GUERET',
    name: 'Gueret bureau',
  },
  FR006130: {
    country: 'France - FR (001)',
    city: 'RUNGIS',
    name: 'Rungis bureau',
  },
  FR006150: {
    country: 'France - FR (001)',
    city: 'MARSEILLE',
    name: 'Marseille transports bureau',
  },
  FR00617C: {
    country: 'France - FR (001)',
    city: 'Roissy',
    name: 'Roissy France handling bureau',
  },
  FR00619C: {
    country: 'France - FR (001)',
    city: 'Roissy CDG',
    name: 'Roissy Fedex bureau',
  },
  FR006240: {
    country: 'France - FR (001)',
    city: 'LA ROCHE SUR YON',
    name: 'La roche sur yon bureau',
  },
  FR006270: {
    country: 'France - FR (001)',
    city: 'CROISSY-BEAUBOURG',
    name: 'Marne la vallee bureau',
  },
  FR006320: {
    country: 'France - FR (001)',
    city: 'JARRY',
    name: 'Pointe a pitre port',
  },
  FR006330: {
    country: 'France - FR (001)',
    city: 'ABYMES',
    name: 'Le raizet bureau',
  },
  FR006340: {
    country: 'France - FR (001)',
    city: 'FORT DE FRANCE',
    name: 'Fort de france port bureau',
  },
  FR006370: {
    country: 'France - FR (001)',
    city: 'REMIRE MONTJOLY',
    name: 'Degrad d cannes port bureau',
  },
  FR006380: {
    country: 'France - FR (001)',
    city: 'matoury Cedex',
    name: 'Cayenne aero bureau',
  },
  FR006390: {
    country: 'France - FR (001)',
    city: 'ST LAURENT DU MARONI',
    name: 'St laurent maroni bureau',
  },
  FR006420: {
    country: 'France - FR (001)',
    city: 'LE PORT',
    name: 'Le port bureau',
  },
  FR006430: {
    country: 'France - FR (001)',
    city: 'Saint pierre Cedex',
    name: 'Saint Pierre bureau',
  },
  FR006440: {
    country: 'France - FR (001)',
    city: 'LE LAMENTIN',
    name: 'Le lamentin aero bureau',
  },
  FR006460: {
    country: 'France - FR (001)',
    city: 'SAINTE MARIE',
    name: 'St denis aero',
  },
  FR006500: {
    country: 'France - FR (001)',
    city: 'FORT DE FRANCE',
    name: 'Fort de france mess. bureau',
  },
  FR006530: {
    country: 'France - FR (001)',
    city: 'CAYENNE',
    name: 'Cayenne CDP',
  },
  FR006610: {
    country: 'France - FR (001)',
    city: 'BAIE MAHAUT',
    name: 'La pointe-jarry port',
  },
  FR006660: {
    country: 'France - FR (001)',
    city: 'FOIX',
    name: 'Foix bureau',
  },
  FR006670: {
    country: 'France - FR (001)',
    city: 'ANNEMASSE',
    name: 'Vallard thonex bureau',
  },
  FR006710: {
    country: 'France - FR (001)',
    city: 'MAUGUIO',
    name: 'Montpellier Mediterranee aero bur',
  },
  FR006750: {
    country: 'France - FR (001)',
    city: "St georges de l'oyapock",
    name: "St georges de l'oyapok bureau",
  },
  FR00677A: {
    country: 'France - FR (001)',
    city: 'ROISSY',
    name: 'Roissy prise en charge',
  },
  FR006790: {
    country: 'France - FR (001)',
    city: 'WISSOUS',
    name: 'Wissous BDP',
  },
  FR006800: {
    country: 'France - FR (001)',
    city: 'LONGONI',
    name: 'Longoni port bureau',
  },
  FR006810: {
    country: 'France - FR (001)',
    city: 'PAMANDZI',
    name: 'Pamandzi bureau',
  },
  FR007730: {
    country: 'France - FR (001)',
    city: 'PARIS',
    name: 'Paris recette interregionale',
  },
  FR007740: {
    country: 'France - FR (001)',
    city: 'PARIS',
    name: 'Tresorerie generale douane (TGD)',
  },
  FR007830: {
    country: 'France - FR (001)',
    city: 'ROISSY',
    name: 'Roissy recette interregionale',
  },
  FR008010: {
    country: 'France - FR (001)',
    city: 'DUNKERQUE',
    name: 'Dunkerque recette interregionale',
  },
  FR008380: {
    country: 'France - FR (001)',
    city: 'METZ',
    name: 'Metz recette interregionale',
  },
  FR008620: {
    country: 'France - FR (001)',
    city: 'DIJON',
    name: 'Dijon recette interregionale',
  },
  FR008650: {
    country: 'France - FR (001)',
    city: 'VILLEURBANNE',
    name: 'Lyon recette interregionale',
  },
  FR008980: {
    country: 'France - FR (001)',
    city: 'MARSEILLE',
    name: 'Marseille recette interregionale',
  },
  FR009030: {
    country: 'France - FR (001)',
    city: 'MONTPELLIER',
    name: 'Montpellier recette interregionale',
  },
  FR009270: {
    country: 'France - FR (001)',
    city: 'BORDEAUX',
    name: 'Bordeaux recette interregionale',
  },
  FR009410: {
    country: 'France - FR (001)',
    city: 'NANTES',
    name: 'Nantes recette interregionale',
  },
  FR009620: {
    country: 'France - FR (001)',
    city: 'LE HAVRE',
    name: 'Le havre recette interregionale',
  },
  FR009710: {
    country: 'France - FR (001)',
    city: 'BASSE TERRE',
    name: 'Guadeloupe recette regionale',
  },
  FR009720: {
    country: 'France - FR (001)',
    city: 'FORT DE FRANCE',
    name: 'Martinique recette regionale',
  },
  FR009730: {
    country: 'France - FR (001)',
    city: 'CAYENNE',
    name: 'Guyane recette regionale',
  },
  FR009740: {
    country: 'France - FR (001)',
    city: 'SAINT DENIS',
    name: 'Reunion recette regionale',
  },
  FR009760: {
    country: 'France - FR (001)',
    city: 'LONGONI',
    name: 'Mayotte recette regionale',
  },
  FR038201: {
    country: 'France - FR (001)',
    city: 'ville di pietrabugno Cedex',
    name: 'Bastia viti',
  },
  FR060001: {
    country: 'France - FR (001)',
    city: 'NICE',
    name: 'Nice PAE',
  },
  FR130002: {
    country: 'France - FR (001)',
    city: 'MARSEILLE',
    name: 'Marseille PAE',
  },
  FR130003: {
    country: 'France - FR (001)',
    city: 'AIX EN PROVENCE',
    name: 'Provence PAE',
  },
  FR140001: {
    country: 'France - FR (001)',
    city: 'CAEN',
    name: 'Caen PAE',
  },
  FR200001: {
    country: 'France - FR (001)',
    city: 'AJACCIO',
    name: 'Corse PAE',
  },
  FR210002: {
    country: 'France - FR (001)',
    city: 'DIJON',
    name: 'Dijon PAE',
  },
  FR250001: {
    country: 'France - FR (001)',
    city: 'BESANCON',
    name: 'Besancon PAE',
  },
  FR310001: {
    country: 'France - FR (001)',
    city: 'TOULOUSE',
    name: 'Toulouse PAE',
  },
  FR330002: {
    country: 'France - FR (001)',
    city: 'BORDEAUX',
    name: 'Bordeaux PAE',
  },
  FR340002: {
    country: 'France - FR (001)',
    city: 'MAUGUIO',
    name: 'Montpellier mediterranee bur dedoua',
  },
  FR340003: {
    country: 'France - FR (001)',
    city: 'MONTPELLIER',
    name: 'Montpellier PAE',
  },
  FR350001: {
    country: 'France - FR (001)',
    city: 'RENNES',
    name: 'Bretagne PAE',
  },
  FR440002: {
    country: 'France - FR (001)',
    city: 'NANTES',
    name: 'Nantes PAE',
  },
  FR450001: {
    country: 'France - FR (001)',
    city: 'ORLEANS',
    name: 'Centre PAE',
  },
  FR510001: {
    country: 'France - FR (001)',
    city: 'REIMS',
    name: 'Reims PAE',
  },
  FR540001: {
    country: 'France - FR (001)',
    city: 'NANCY',
    name: 'Lorraine PAE',
  },
  FR590001: {
    country: 'France - FR (001)',
    city: 'LILLE',
    name: 'DI Hauts -de-France',
  },
  FR590002: {
    country: 'France - FR (001)',
    city: 'DUNKERQUE LOON PLAGE',
    name: 'Dunkerque ferry bureau',
  },
  FR590003: {
    country: 'France - FR (001)',
    city: 'DUNKERQUE',
    name: 'Dunkerque PAE',
  },
  FR590004: {
    country: 'France - FR (001)',
    city: 'LILLE',
    name: 'Lille PAE',
  },
  FR620001: {
    country: 'France - FR (001)',
    city: 'CALAIS',
    name: 'Calais port tunnel bureau',
  },
  FR630001: {
    country: 'France - FR (001)',
    city: 'CLERMONT FERRAND',
    name: 'Auvergne PAE',
  },
  FR640001: {
    country: 'France - FR (001)',
    city: 'BAYONNE',
    name: 'Bayonne PAE',
  },
  FR660001: {
    country: 'France - FR (001)',
    city: 'PERPIGNAN',
    name: 'Perpignan PAE',
  },
  FR670001: {
    country: 'France - FR (001)',
    city: 'STRASBOURG',
    name: 'Strasbourg PAE',
  },
  FR680001: {
    country: 'France - FR (001)',
    city: 'MULHOUSE',
    name: 'Mulhouse PAE',
  },
  FR690002: {
    country: 'France - FR (001)',
    city: 'LYON',
    name: 'Lyon PAE',
  },
  FR730001: {
    country: 'France - FR (001)',
    city: 'CHAMBERY',
    name: 'Chambery PAE',
  },
  FR740001: {
    country: 'France - FR (001)',
    city: 'ANNECY',
    name: 'Leman PAE',
  },
  FR750001: {
    country: 'France - FR (001)',
    city: 'MONTREUIL',
    name: 'Service grands comptes (SGC)',
  },
  FR750004: {
    country: 'France - FR (001)',
    city: 'PARIS',
    name: 'Paris PAE',
  },
  FR750681: {
    country: 'France - FR (001)',
    city: 'MONTREUIL',
    name: 'DG-COMINT1-STATUT DES OPERATEURS',
  },
  FR750771: {
    country: 'France - FR (001)',
    city: 'MONTREUIL',
    name: 'Fiscalite des carburants',
  },
  FR760001: {
    country: 'France - FR (001)',
    city: 'ROUEN',
    name: 'DI Normandie',
  },
  FR760002: {
    country: 'France - FR (001)',
    city: 'LE HAVRE',
    name: 'Le havre.port logist',
  },
  FR760003: {
    country: 'France - FR (001)',
    city: 'ROUEN',
    name: 'Rouen PAE',
  },
  FR770001: {
    country: 'France - FR (001)',
    city: 'MARNE LA VALLEE',
    name: 'Paris Est PAE',
  },
  FR772001: {
    country: 'France - FR (001)',
    city: 'PARIS',
    name: 'DR Paris',
  },
  FR776001: {
    country: 'France - FR (001)',
    city: 'PARAY-VIEILLE-POSTE',
    name: 'DR Orly',
  },
  FR777001: {
    country: 'France - FR (001)',
    city: 'Torcy Cedex 01',
    name: 'DR Paris Est',
  },
  FR780001: {
    country: 'France - FR (001)',
    city: 'SAINT GERMAIN EN LAYE',
    name: 'Paris Ouest PAE',
  },
  FR782001: {
    country: 'France - FR (001)',
    city: 'Roissy cdg cedex',
    name: 'DR Roissy Fret',
  },
  FR787001: {
    country: 'France - FR (001)',
    city: 'SAINT GERMAIN-EN-LAYE',
    name: 'DR Paris Ouest',
  },
  FR792001: {
    country: 'France - FR (001)',
    city: 'ORLEANS',
    name: 'DR Centre - Val de Loire',
  },
  FR792201: {
    country: 'France - FR (001)',
    city: 'TOURS',
    name: 'Tours viti',
  },
  FR792211: {
    country: 'France - FR (001)',
    city: 'BLOIS',
    name: 'Blois viti - CI',
  },
  FR792221: {
    country: 'France - FR (001)',
    city: 'BOURGES',
    name: 'Bourges viti - CI',
  },
  FR800001: {
    country: 'France - FR (001)',
    city: 'DUNKERQUE',
    name: 'DR Dunkerque',
  },
  FR800002: {
    country: 'France - FR (001)',
    city: 'AMIENS',
    name: 'Picardie PAE',
  },
  FR817001: {
    country: 'France - FR (001)',
    city: 'AMIENS',
    name: 'DR Amiens',
  },
  FR823001: {
    country: 'France - FR (001)',
    city: 'Reims Cedex',
    name: 'DR Champagne Ardenne',
  },
  FR823201: {
    country: 'France - FR (001)',
    city: 'EPERNAY',
    name: 'Epernay viti',
  },
  FR823221: {
    country: 'France - FR (001)',
    city: 'BAR SUR SEINE',
    name: 'Bar sur Seine viti',
  },
  FR830001: {
    country: 'France - FR (001)',
    city: 'NANCY',
    name: 'DR Nancy',
  },
  FR843001: {
    country: 'France - FR (001)',
    city: 'Strasbourg Cedex',
    name: 'DR Strasbourg',
  },
  FR848001: {
    country: 'France - FR (001)',
    city: 'MULHOUSE',
    name: 'DR Mulhouse',
  },
  FR853001: {
    country: 'France - FR (001)',
    city: 'BESANCON',
    name: 'DR Besancon',
  },
  FR859001: {
    country: 'France - FR (001)',
    city: 'DIJON',
    name: 'DR Dijon',
  },
  FR859201: {
    country: 'France - FR (001)',
    city: 'MACON',
    name: 'Macon viti - CI',
  },
  FR859211: {
    country: 'France - FR (001)',
    city: 'CHALON SUR SAONE',
    name: 'Chalon s-saone viti - CI',
  },
  FR859221: {
    country: 'France - FR (001)',
    city: 'DIJON',
    name: 'Dijon viti - CI',
  },
  FR859231: {
    country: 'France - FR (001)',
    city: 'MONETEAU',
    name: 'Auxerre viti - CI',
  },
  FR859241: {
    country: 'France - FR (001)',
    city: 'BEAUNE',
    name: 'Beaune viti',
  },
  FR860001: {
    country: 'France - FR (001)',
    city: 'POITIERS',
    name: 'Poitiers PAE',
  },
  FR864001: {
    country: 'France - FR (001)',
    city: 'LYON',
    name: 'DR Lyon',
  },
  FR864201: {
    country: 'France - FR (001)',
    city: 'VILLEFRANCHE SUR SAONE CEDEX',
    name: 'Villefranche viti ci',
  },
  FR864221: {
    country: 'France - FR (001)',
    city: 'Privas Cedex',
    name: 'Privas viti ci',
  },
  FR864241: {
    country: 'France - FR (001)',
    city: 'Tournon s-rhone Cedex',
    name: "Tain l'hermitage viti ci",
  },
  FR864251: {
    country: 'France - FR (001)',
    city: 'Valence Cedex 9',
    name: 'Valence viti ci',
  },
  FR869001: {
    country: 'France - FR (001)',
    city: 'CLERMONT-FERRAND',
    name: 'DR Clermont-Ferrand',
  },
  FR869211: {
    country: 'France - FR (001)',
    city: 'CLERMONT-FERRAND',
    name: 'Clermont viti',
  },
  FR873001: {
    country: 'France - FR (001)',
    city: 'ANNECY',
    name: 'DR Leman',
  },
  FR873201: {
    country: 'France - FR (001)',
    city: 'PERONNAS',
    name: 'Bourg en bresse viti',
  },
  FR874001: {
    country: 'France - FR (001)',
    city: 'CHAMBERY',
    name: 'DR Chambery',
  },
  FR874201: {
    country: 'France - FR (001)',
    city: 'CHAMBERY',
    name: 'Chambery viti',
  },
  FR874211: {
    country: 'France - FR (001)',
    city: 'Saint egreve',
    name: 'Grenoble viti',
  },
  FR880001: {
    country: 'France - FR (001)',
    city: 'NICE',
    name: 'DR Nice',
  },
  FR885001: {
    country: 'France - FR (001)',
    city: 'AJACCIO',
    name: 'DR Corse',
  },
  FR885201: {
    country: 'France - FR (001)',
    city: 'AJACCIO',
    name: 'Ajaccio viti',
  },
  FR889001: {
    country: 'France - FR (001)',
    city: 'AIX EN PROVENCE',
    name: 'DR Aix-en-Provence',
  },
  FR889201: {
    country: 'France - FR (001)',
    city: 'AVIGNON',
    name: 'Avignon viti',
  },
  FR889211: {
    country: 'France - FR (001)',
    city: 'DRAGUIGNAN',
    name: 'Draguignan viti ci',
  },
  FR889251: {
    country: 'France - FR (001)',
    city: 'AIX EN PROVENCE',
    name: 'Aix en provence viti CI',
  },
  FR897001: {
    country: 'France - FR (001)',
    city: 'MARSEILLE',
    name: 'DR Marseille',
  },
  FR902001: {
    country: 'France - FR (001)',
    city: 'MONTPELLIER',
    name: 'DR Montpellier',
  },
  FR902211: {
    country: 'France - FR (001)',
    city: 'VILLENEUVE LES BEZIERS',
    name: 'Beziers viti ci',
  },
  FR902261: {
    country: 'France - FR (001)',
    city: 'BAGNOLS-SUR-CEZE',
    name: 'Bagnols s/ceze viti ci',
  },
  FR907001: {
    country: 'France - FR (001)',
    city: 'PERPIGNAN',
    name: 'DR Perpignan',
  },
  FR907201: {
    country: 'France - FR (001)',
    city: 'PERPIGNAN',
    name: 'Perpignan viti ci',
  },
  FR907211: {
    country: 'France - FR (001)',
    city: 'NARBONNE',
    name: 'Narbonne viti ci',
  },
  FR907221: {
    country: 'France - FR (001)',
    city: 'CARCASSONNE',
    name: 'Carcassonne viti ci',
  },
  FR912001: {
    country: 'France - FR (001)',
    city: 'TOULOUSE',
    name: 'DR Toulouse',
  },
  FR912261: {
    country: 'France - FR (001)',
    city: 'GAILLAC',
    name: 'Gaillac viti',
  },
  FR912701: {
    country: 'France - FR (001)',
    city: 'EAUZE',
    name: 'Eauze c.v.a',
  },
  FR919001: {
    country: 'France - FR (001)',
    city: 'BAYONNE',
    name: 'DR Bayonne',
  },
  FR920001: {
    country: 'France - FR (001)',
    city: 'PARIS LA DÉFENSE',
    name: 'Service du Reseau des oleoducs',
  },
  FR926001: {
    country: 'France - FR (001)',
    city: 'BORDEAUX',
    name: 'DR Bordeaux',
  },
  FR926211: {
    country: 'France - FR (001)',
    city: 'LANGON',
    name: 'Langon viti',
  },
  FR926221: {
    country: 'France - FR (001)',
    city: 'LIBOURNE',
    name: 'Libourne - St Emilion viti',
  },
  FR926241: {
    country: 'France - FR (001)',
    city: 'BERGERAC',
    name: 'Bergerac viti',
  },
  FR926251: {
    country: 'France - FR (001)',
    city: 'BLAYE',
    name: 'Blaye viti',
  },
  FR926261: {
    country: 'France - FR (001)',
    city: 'PAUILLAC',
    name: 'Pauillac viti',
  },
  FR930004: {
    country: 'France - FR (001)',
    city: 'MONTREUIL',
    name: 'Entrepots douaniers',
  },
  FR930005: {
    country: 'France - FR (001)',
    city: 'MONTREUIL',
    name: 'Dest. part. - dedoua. vehic. - SIV',
  },
  FR930007: {
    country: 'France - FR (001)',
    city: 'MONTREUIL',
    name: 'Valeur',
  },
  FR930009: {
    country: 'France - FR (001)',
    city: 'MONTREUIL',
    name: 'DG-COMINT1-TRANSIT',
  },
  FR930010: {
    country: 'France - FR (001)',
    city: 'MONTREUIL',
    name: 'Plan ctrl expost, FEAGA, POSEI, suc',
  },
  FR930011: {
    country: 'France - FR (001)',
    city: 'MONTREUIL',
    name: 'Proc. simpl.',
  },
  FR930012: {
    country: 'France - FR (001)',
    city: 'MONTREUIL',
    name: 'Garantie et contributions indirecte',
  },
  FR930013: {
    country: 'France - FR (001)',
    city: 'Montreuil',
    name: 'DG-COMINT1-AVANT DEDOUANEMENT',
  },
  FR930014: {
    country: 'France - FR (001)',
    city: 'Montreuil',
    name: 'DG-COMINT1-E-COMMERCE',
  },
  FR930015: {
    country: 'France - FR (001)',
    city: 'Montreuil',
    name: 'DG-COMINT1-PROCESSUS EXPORT',
  },
  FR930016: {
    country: 'France - FR (001)',
    city: 'Montreuil',
    name: 'DG-COMINT1-PROCESSUS IMPORT',
  },
  FR930018: {
    country: 'France - FR (001)',
    city: 'Montreuil',
    name: 'DG-COMINT1-REGIMES PARTICULIERS',
  },
  FR932001: {
    country: 'France - FR (001)',
    city: 'POITIERS',
    name: 'DR Poitiers',
  },
  FR932221: {
    country: 'France - FR (001)',
    city: 'JONZAC',
    name: 'Jonzac viti',
  },
  FR932231: {
    country: 'France - FR (001)',
    city: 'Saintes',
    name: 'Saintes viti',
  },
  FR936701: {
    country: 'France - FR (001)',
    city: 'COGNAC',
    name: 'Cognac viti',
  },
  FR940001: {
    country: 'France - FR (001)',
    city: 'NANTES',
    name: 'DR Pays de la Loire',
  },
  FR940002: {
    country: 'France - FR (001)',
    city: 'IVRY-SUR-SEINE',
    name: 'BCRE',
  },
  FR940003: {
    country: 'France - FR (001)',
    city: 'IVRY-SUR-SEINE',
    name: 'DNRED',
  },
  FR940004: {
    country: 'France - FR (001)',
    city: 'PARAY-VIEILLE-POSTE',
    name: 'Orly PAE',
  },
  FR940211: {
    country: 'France - FR (001)',
    city: 'LA ROCHE SUR YON',
    name: 'La roche s-yon centre viti',
  },
  FR940221: {
    country: 'France - FR (001)',
    city: 'ANGERS',
    name: 'Angers centre viti',
  },
  FR940321: {
    country: 'France - FR (001)',
    city: 'Saumur',
    name: 'Saumur centre viti',
  },
  FR945001: {
    country: 'France - FR (001)',
    city: 'Rennes',
    name: 'DR Bretagne',
  },
  FR950001: {
    country: 'France - FR (001)',
    city: 'CAEN',
    name: 'DR Basse Normandie',
  },
  FR950003: {
    country: 'France - FR (001)',
    city: 'ROISSY',
    name: 'Roissy PAE',
  },
  FR950161: {
    country: 'France - FR (001)',
    city: 'CAEN',
    name: 'Caen alcools et cidriculture',
  },
  FR956001: {
    country: 'France - FR (001)',
    city: 'ROUEN',
    name: 'DR Rouen',
  },
  FR960001: {
    country: 'France - FR (001)',
    city: 'LE HAVRE',
    name: 'Le Havre PAE',
  },
  FR961001: {
    country: 'France - FR (001)',
    city: 'LE HAVRE',
    name: 'DR Le Havre',
  },
  FR970001: {
    country: 'France - FR (001)',
    city: 'BASSE TERRE',
    name: 'Guadeloupe PAE',
  },
  FR970002: {
    country: 'France - FR (001)',
    city: 'CAYENNE',
    name: 'Guyane PAE',
  },
  FR970003: {
    country: 'France - FR (001)',
    city: 'FORT DE FRANCE',
    name: 'Martinique PAE',
  },
  FR970004: {
    country: 'France - FR (001)',
    city: 'MAMOUDZOU',
    name: 'Mayotte PAE',
  },
  FR970005: {
    country: 'France - FR (001)',
    city: 'SAINT-DENIS',
    name: 'Reunion PAE',
  },
  FR971011: {
    country: 'France - FR (001)',
    city: 'BASSE TERRE',
    name: 'DR Guadeloupe',
  },
  FR972021: {
    country: 'France - FR (001)',
    city: 'FORT DE FRANCE',
    name: 'DR Martinique',
  },
  FR973011: {
    country: 'France - FR (001)',
    city: 'CAYENNE',
    name: 'DR Guyane',
  },
  FR974011: {
    country: 'France - FR (001)',
    city: 'SAINT DENIS',
    name: 'DR Reunion',
  },
  FR985001: {
    country: 'France - FR (001)',
    city: 'MAMOUDZOU',
    name: 'DR Mayotte',
  },
  FR986001: {
    country: 'France - FR (001)',
    city: 'LILLE',
    name: 'DR Lille',
  },
  FRA02300: {
    country: 'France - FR (001)',
    city: 'LE HAVRE',
    name: 'Le Havre Port - Visite Ocean',
  },
  FRA0617B: {
    country: 'France - FR (001)',
    city: 'ROISSY CDG Cedex',
    name: 'Roissy Sogaris bureau',
  },
  FRA0617D: {
    country: 'France - FR (001)',
    city: 'ROISSY CDG Cedex',
    name: 'Roissy CDP bureau',
  },
  FRA0619A: {
    country: 'France - FR (001)',
    city: 'ROISSY CDG',
    name: 'Roissy Air France bureau',
  },
  FRA0619B: {
    country: 'France - FR (001)',
    city: 'ROISSY CDG',
    name: 'Roissy Cargo centre bureau',
  },
  FRB02300: {
    country: 'France - FR (001)',
    city: 'LE HAVRE',
    name: 'Le Havre Port Visite Europe Atlan.',
  },
  FRB0617A: {
    country: 'France - FR (001)',
    city: 'ROISSY CDG Cedex',
    name: 'Roissy Sodexi bureau',
  },
  FRB0617D: {
    country: 'France - FR (001)',
    city: 'ROISSY CDG Cedex',
    name: 'Roissy Chronopost bureau',
  },
  FRB0617E: {
    country: 'France - FR (001)',
    city: 'ROISSY CDG',
    name: 'Roissy Cargo 7 bureau',
  },
  FRB0619A: {
    country: 'France - FR (001)',
    city: 'ROISSY CDG Cedex',
    name: 'Roissy Banale bureau',
  },
  FRB0677B: {
    country: 'France - FR (001)',
    city: 'ROISSY CDG Cedex',
    name: 'Roissy avitaillement',
  },
  FRC02300: {
    country: 'France - FR (001)',
    city: 'LE HAVRE',
    name: 'Le Havre Port Magasins Entrepots',
  },
  FRC0619A: {
    country: 'France - FR (001)',
    city: 'ROISSY CDG Cedex',
    name: 'Roissy Nord Gestion des procedures',
  },
  FRD02300: {
    country: 'France - FR (001)',
    city: 'LE HAVRE HARFLEUR',
    name: 'Le Havre Port - CREPS',
  },
  FRD0619A: {
    country: 'France - FR (001)',
    city: 'ROISSY CDG',
    name: 'Roissy OPCO Aerogares',
  },
  GB000001: {
    country: 'United Kingdom - GB (006)',
    city: 'LIVERPOOL',
    name: 'Central Community Transit Office',
  },
  GB000003: {
    country: 'United Kingdom - GB (006)',
    city: 'DERBY SQUARE,LIVERPOOL',
    name: 'HMRC',
  },
  GB000011: {
    country: 'United Kingdom - GB (006)',
    city: 'BIRMINGHAM',
    name: 'Birmingham Airport',
  },
  GB000029: {
    country: 'United Kingdom - GB (006)',
    city: 'Bristol',
    name: 'UKBF, Bristol International Airport',
  },
  GB000047: {
    country: 'United Kingdom - GB (006)',
    city: 'RAMSGATE',
    name: 'Ramsgate',
  },
  GB000051: {
    country: 'United Kingdom - GB (006)',
    city: 'FELIXSTOWE',
    name: 'Felixstowe',
  },
  GB000052: {
    country: 'United Kingdom - GB (006)',
    city: 'Harwich, Essex',
    name: 'Harwich',
  },
  GB000053: {
    country: 'United Kingdom - GB (006)',
    city: 'Stranraer, Dumfries and Galloway.',
    name: 'Cairnryan',
  },
  GB000054: {
    country: 'United Kingdom - GB (006)',
    city: 'Paisley, Renfrewshire',
    name: 'Glasgow Airport',
  },
  GB000055: {
    country: 'United Kingdom - GB (006)',
    city: 'Aberdeen.',
    name: 'Aberdeen + Highlands & Island Ports',
  },
  GB000058: {
    country: 'United Kingdom - GB (006)',
    city: 'Edinburgh Airport, Edinburgh',
    name: 'Edinburgh Airport',
  },
  GB000059: {
    country: 'United Kingdom - GB (006)',
    city: 'Grangemouth Port.',
    name: 'Grangemouth',
  },
  GB000060: {
    country: 'United Kingdom - GB (006)',
    city: 'DOVER',
    name: 'Dover/Folkestone Eurotunnel Freight',
  },
  GB000072: {
    country: 'United Kingdom - GB (006)',
    city: 'HULL',
    name: 'Hull',
  },
  GB000074: {
    country: 'United Kingdom - GB (006)',
    city: 'IMMINGHAM',
    name: 'Immingham',
  },
  GB000080: {
    country: 'United Kingdom - GB (006)',
    city: 'LIVERPOOL',
    name: 'Liverpool, Seaforth S2 Berth',
  },
  GB000081: {
    country: 'United Kingdom - GB (006)',
    city: 'Salford, Manchester',
    name: 'UK North Auth Consignor/nees',
  },
  GB000084: {
    country: 'United Kingdom - GB (006)',
    city: 'HOUNSLOW',
    name: 'London Heathrow Cargo',
  },
  GB000085: {
    country: 'United Kingdom - GB (006)',
    city: 'GATWICK AIRPORT',
    name: 'London Gatwick Airport Cargo Dist.',
  },
  GB000087: {
    country: 'United Kingdom - GB (006)',
    city: 'IMMINGHAM',
    name: 'Leeds Bradford',
  },
  GB000093: {
    country: 'United Kingdom - GB (006)',
    city: 'TILBURY',
    name: 'Tilbury',
  },
  GB000102: {
    country: 'United Kingdom - GB (006)',
    city: 'LUTON',
    name: 'Luton Airport FCT',
  },
  GB000120: {
    country: 'United Kingdom - GB (006)',
    city: 'London Heathrow, HOUNSLOW',
    name: 'London Heathrow Airport (Baggage)',
  },
  GB000121: {
    country: 'United Kingdom - GB (006)',
    city: 'STANSTED',
    name: 'Stansted Airport FCT',
  },
  GB000122: {
    country: 'United Kingdom - GB (006)',
    city: 'BARRY, Nr. Cardiff',
    name: 'Cardiff International Airport',
  },
  GB000124: {
    country: 'United Kingdom - GB (006)',
    city: 'Holyhead',
    name: 'HOLYHEAD',
  },
  GB000126: {
    country: 'United Kingdom - GB (006)',
    city: 'Pembrokeshire',
    name: 'PEMBROKE DOCK',
  },
  GB000149: {
    country: 'United Kingdom - GB (006)',
    city: '.',
    name: 'Purfleet Thames Terminal',
  },
  GB000150: {
    country: 'United Kingdom - GB (006)',
    city: '.Rochester',
    name: 'Thamesport',
  },
  GB000155: {
    country: 'United Kingdom - GB (006)',
    city: 'READING',
    name: 'Reading',
  },
  GB000170: {
    country: 'United Kingdom - GB (006)',
    city: 'Stanford-le-Hope, Essex',
    name: 'London Gateway Port',
  },
  GB000191: {
    country: 'United Kingdom - GB (006)',
    city: 'MANCHESTER AIRPORT',
    name: 'Manchester',
  },
  GB000218: {
    country: 'United Kingdom - GB (006)',
    city: 'Coble Dene, North Shields',
    name: 'Border Force, Port of Tyne',
  },
  GB000219: {
    country: 'United Kingdom - GB (006)',
    city: 'MIDDLESBROUGH',
    name: 'Teesport',
  },
  GB000245: {
    country: 'United Kingdom - GB (006)',
    city: 'Castle Donington, DERBY',
    name: 'UKBA East Midlands Airport',
  },
  GB000246: {
    country: 'United Kingdom - GB (006)',
    city: 'Salford, Manchester',
    name: 'UK South Auth Consignor/nees',
  },
  GB000290: {
    country: 'United Kingdom - GB (006)',
    city: 'Southampton',
    name: 'Southampton',
  },
  GB000291: {
    country: 'United Kingdom - GB (006)',
    city: 'Poole, Dorset',
    name: 'Poole Ferry Terminal',
  },
  GB000292: {
    country: 'United Kingdom - GB (006)',
    city: 'George Byng Way, Portsmouth',
    name: 'Portsmouth',
  },
  GB000328: {
    country: 'United Kingdom - GB (006)',
    city: 'Llanishen, Cardiff',
    name: 'Cardiff',
  },
  GB000450: {
    country: 'United Kingdom - GB (006)',
    city: 'DOUGLAS, ISLE OF MAN',
    name: 'Isle of Man Customs & Excise',
  },
  GB000461: {
    country: 'United Kingdom - GB (006)',
    city: 'St Peter Port, GUERNSEY',
    name: 'Guernsey Customs & Excise',
  },
  GB000465: {
    country: 'United Kingdom - GB (006)',
    city: 'JERSEY, CHANNEL ISLANDS',
    name: 'Jersey Customs & Immigration',
  },
  GB003050: {
    country: 'United Kingdom - GB (006)',
    city: 'Plymouth PL4 9LT',
    name: 'Plymouth Docks',
  },
  GB003250: {
    country: 'United Kingdom - GB (006)',
    city: 'Newhaven',
    name: 'Newhaven',
  },
  GB004098: {
    country: 'United Kingdom - GB (006)',
    city: 'GLASGOW',
    name: 'GLASGOW',
  },
  GB005170: {
    country: 'United Kingdom - GB (006)',
    city: 'NORTH KILLINGHOLME LINCOLNSHIRE',
    name: 'Killingholme',
  },
  GB005200: {
    country: 'United Kingdom - GB (006)',
    city: 'Fishguard, Pembrokeshire',
    name: 'Fishguard',
  },
  GB005210: {
    country: 'United Kingdom - GB (006)',
    city: 'Liverpool',
    name: 'Heysham',
  },
  GR000102: {
    country: 'Greece - GR (009)',
    city: 'ΑΘΗΝΑ - ΜΕΤΑΜΟΡΦΩΣΗ',
    name: 'ΑΘΗΝΩΝ',
  },
  GR000232: {
    country: 'Greece - GR (009)',
    city: 'ΑΣΤΑΚΟΣ',
    name: 'ΑΣΤΑΚΟΥ',
  },
  GR000234: {
    country: 'Greece - GR (009)',
    city: 'ΜΕΣΟΛΟΓΓΙ',
    name: 'ΜΕΣΟΛΟΓΓΙΟΥ',
  },
  GR000303: {
    country: 'Greece - GR (009)',
    city: 'ΛΑΥΡΙΟ',
    name: 'ΛΑΥΡΙΟΥ',
  },
  GR000304: {
    country: 'Greece - GR (009)',
    city: 'ΣΠΑΤΑ',
    name: 'Δ.Α.Α. "ΕΛΕΥΘΕΡΙΟΣ ΒΕΝΙΖΕΛΟΣ"',
  },
  GR000331: {
    country: 'Greece - GR (009)',
    city: 'ΡΑΦΗΝΑ',
    name: 'ΡΑΦΗΝΑΣ',
  },
  GR000402: {
    country: 'Greece - GR (009)',
    city: 'ΝΑΥΠΛΙΟ',
    name: 'ΝΑΥΠΛΙΟΥ',
  },
  GR000631: {
    country: 'Greece - GR (009)',
    city: 'ΑΡΤΑ',
    name: 'ΑΡΤΑΣ',
  },
  GR000700: {
    country: 'Greece - GR (009)',
    city: 'PATRA',
    name: 'ACHAIA CUSTOMS REGION',
  },
  GR000701: {
    country: 'Greece - GR (009)',
    city: 'ΠΑΤΡΑ',
    name: 'ΠΑΤΡΩΝ',
  },
  GR000731: {
    country: 'Greece - GR (009)',
    city: 'ΑΙΓΙΟ',
    name: 'ΑΙΓΙΟΥ',
  },
  GR000831: {
    country: 'Greece - GR (009)',
    city: 'ΑΝΤΙΚΥΡΑ',
    name: 'ΑΝΤΙΚΥΡΑΣ',
  },
  GR000832: {
    country: 'Greece - GR (009)',
    city: 'ΣΧΗΜΑΤΑΡΙ ΒΟΙΩΤΙΑΣ',
    name: 'ΟΙΝΟΗΣ',
  },
  GR001002: {
    country: 'Greece - GR (009)',
    city: 'ΔΡΑΜΑ',
    name: 'ΔΡΑΜΑΣ',
  },
  GR001102: {
    country: 'Greece - GR (009)',
    city: 'ΕΛΕΥΣΙΝΑ',
    name: 'ΕΛΕΥΣΙΝΑΣ',
  },
  GR001202: {
    country: 'Greece - GR (009)',
    city: 'ΡΟΔΟΣ',
    name: 'ΡΟΔΟΥ',
  },
  GR001232: {
    country: 'Greece - GR (009)',
    city: 'ΚΑΛΥΜΝΟΣ',
    name: 'ΚΑΛΥΜΝΟΥ',
  },
  GR001233: {
    country: 'Greece - GR (009)',
    city: 'ΚΑΡΠΑΘΟΣ',
    name: 'ΚΑΡΠΑΘΟΥ',
  },
  GR001234: {
    country: 'Greece - GR (009)',
    city: 'ΛΑΚΚΙ ΛΕΡΟΥ',
    name: 'ΛΕΡΟΥ',
  },
  GR001235: {
    country: 'Greece - GR (009)',
    city: 'ΚΩΣ',
    name: 'ΚΩΣ',
  },
  GR001236: {
    country: 'Greece - GR (009)',
    city: 'ΠΑΤΜΟΣ',
    name: 'ΠΑΤΜΟΥ',
  },
  GR001237: {
    country: 'Greece - GR (009)',
    city: 'ΣΥΜΗ',
    name: 'ΣΥΜΗΣ',
  },
  GR001272: {
    country: 'Greece - GR (009)',
    city: 'ΜΕΓΙΣΤΗ',
    name: 'ΜΕΓΙΣΤΗΣ',
  },
  GR001302: {
    country: 'Greece - GR (009)',
    city: 'ΑΛΕΞΑΝΔΡΟΥΠΟΛΗ',
    name: 'ΑΛΕΞΑΝΔΡΟΥΠΟΛΗΣ',
  },
  GR001331: {
    country: 'Greece - GR (009)',
    city: 'ΟΡΕΣΤΙΑΔΑ',
    name: 'ΝΕΑΣ ΟΡΕΣΤΙΑΔΑΣ',
  },
  GR001332: {
    country: 'Greece - GR (009)',
    city: 'ΚΗΠΟΙ - ΕΒΡΟΥ',
    name: 'ΚΗΠΩΝ ΕΒΡΟΥ',
  },
  GR001336: {
    country: 'Greece - GR (009)',
    city: 'ΚΑΣΤΑΝΙΕΣ ΕΒΡΟΥ',
    name: 'ΚΑΣΤΑΝΕΩΝ',
  },
  GR001402: {
    country: 'Greece - GR (009)',
    city: 'ΧΑΛΚΙΔΑ',
    name: 'ΧΑΛΚΙΔΑΣ',
  },
  GR001431: {
    country: 'Greece - GR (009)',
    city: 'ΩΡΕΟΙ',
    name: 'ΩΡΕΩΝ',
  },
  GR001631: {
    country: 'Greece - GR (009)',
    city: 'ΖΑΚΥΝΘΟΣ',
    name: 'ΖΑΚΥΝΘΟΥ',
  },
  GR001732: {
    country: 'Greece - GR (009)',
    city: 'ΚΥΛΛΗΝΗ ΗΛΕΙΑΣ',
    name: 'ΚΥΛΛΗΝΗΣ',
  },
  GR001802: {
    country: 'Greece - GR (009)',
    city: 'ΒΕΡΟΙΑ',
    name: 'ΒΕΡΟΙΑΣ',
  },
  GR001902: {
    country: 'Greece - GR (009)',
    city: 'ΗΡΑΚΛΕΙΟ',
    name: 'ΗΡΑΚΛΕΙΟΥ',
  },
  GR002001: {
    country: 'Greece - GR (009)',
    city: 'ΘΕΣΣΑΛΟΝΙΚΗ',
    name: "Α' ΘΕΣΣΑΛΟΝΙΚΗΣ",
  },
  GR002005: {
    country: 'Greece - GR (009)',
    city: 'ΘΕΣΣΑΛΟΝΙΚΗ',
    name: 'B ΘΕΣΣΑΛΟΝΙΚΗΣ',
  },
  GR002006: {
    country: 'Greece - GR (009)',
    city: 'ΘΕΣΣΑΛΟΝΙΚΗ',
    name: 'Γ΄ ΘΕΣΣΑΛΟΝΙΚΗΣ',
  },
  GR002015: {
    country: 'Greece - GR (009)',
    city: 'ΘΕΣΣΑΛΟΝΙΚΗ',
    name: 'ΤΕΛΩΝΕΙΑΚΗ ΠΕΡΙΦΕΡΕΙΑ ΘΕΣΣΑΛΟΝΙΚΗΣ',
  },
  GR002051: {
    country: 'Greece - GR (009)',
    city: 'ΣΤΑΥΡΟΣ',
    name: 'ΣΤΑΥΡΟΥ',
  },
  GR002102: {
    country: 'Greece - GR (009)',
    city: 'ΗΓΟΥΜΕΝΙΤΣΑ',
    name: 'ΗΓΟΥΜΕΝΙΤΣΑΣ',
  },
  GR002132: {
    country: 'Greece - GR (009)',
    city: 'ΣΑΓΙΑΔΑ ΘΕΣΠΡΩΤΙΑΣ',
    name: 'ΜΑΥΡΟΜΑΤΙΟΥ',
  },
  GR002202: {
    country: 'Greece - GR (009)',
    city: 'ΙΩΑΝΝΙΝΑ',
    name: 'ΙΩΑΝΝΙΝΩΝ',
  },
  GR002203: {
    country: 'Greece - GR (009)',
    city: 'ΚΑΚΑΒΙΑ - ΙΩΑΝΝΙΝΩΝ',
    name: 'ΚΑΚΑΒΙΑΣ',
  },
  GR002233: {
    country: 'Greece - GR (009)',
    city: 'ΚΟΝΙΤΣΑ',
    name: 'ΜΕΡΤΖΑΝΗΣ',
  },
  GR002302: {
    country: 'Greece - GR (009)',
    city: 'ΚΑΒΑΛΑ',
    name: 'ΚΑΒΑΛΑΣ',
  },
  GR002501: {
    country: 'Greece - GR (009)',
    city: 'ΚΑΣΤΟΡΙΑ',
    name: 'ΚΑΣΤΟΡΙΑΣ',
  },
  GR002602: {
    country: 'Greece - GR (009)',
    city: 'ΚΕΡΚΥΡΑ',
    name: 'ΚΕΡΚΥΡΑΣ',
  },
  GR002731: {
    country: 'Greece - GR (009)',
    city: 'ΑΡΓΟΣΤΟΛΙ',
    name: 'ΑΡΓΟΣΤΟΛΙΟΥ',
  },
  GR002802: {
    country: 'Greece - GR (009)',
    city: 'ΕΥΖΩΝΟΙ',
    name: 'ΕΥΖΩΝΩΝ',
  },
  GR002803: {
    country: 'Greece - GR (009)',
    city: 'ΔΟΙΡΑΝΗ',
    name: 'ΔΟΙΡΑΝΗΣ',
  },
  GR002805: {
    country: 'Greece - GR (009)',
    city: 'ΚΙΛΚΙΣ',
    name: 'ΚΙΛΚΙΣ',
  },
  GR002931: {
    country: 'Greece - GR (009)',
    city: 'ΠΤΟΛΕΜΑΪΔΑ',
    name: 'ΠΤΟΛΕΜΑΪΔΑΣ',
  },
  GR003002: {
    country: 'Greece - GR (009)',
    city: 'ΚΟΡΙΝΘΟΣ',
    name: 'ΚΟΡΙΝΘΟΥ',
  },
  GR003102: {
    country: 'Greece - GR (009)',
    city: 'ΕΡΜΟΥΠΟΛΗ',
    name: 'ΣΥΡΟΥ',
  },
  GR003131: {
    country: 'Greece - GR (009)',
    city: 'ΑΔΑΜΑΝΤΑΣ ΜΗΛΟΥ',
    name: 'ΜΗΛΟΥ',
  },
  GR003132: {
    country: 'Greece - GR (009)',
    city: 'ΠΑΡΟΣ',
    name: 'ΠΑΡΟΥ',
  },
  GR003133: {
    country: 'Greece - GR (009)',
    city: 'ΜΥΚΟΝΟΣ',
    name: 'ΜΥΚΟΝΟΥ',
  },
  GR003134: {
    country: 'Greece - GR (009)',
    city: 'ΝΑΞΟΣ',
    name: 'ΝΑΞΟΥ',
  },
  GR003135: {
    country: 'Greece - GR (009)',
    city: 'ΘΗΡΑ',
    name: 'ΘΗΡΑΣ',
  },
  GR003201: {
    country: 'Greece - GR (009)',
    city: 'ΓΥΘΕΙΟ',
    name: 'ΓΥΘΕΙΟΥ',
  },
  GR003232: {
    country: 'Greece - GR (009)',
    city: 'NEAPOLI LAKONIAS',
    name: 'NEAPOLIS VION',
  },
  GR003302: {
    country: 'Greece - GR (009)',
    city: 'ΛΑΡΙΣΣΑ',
    name: 'ΛΑΡΙΣΣΑΣ',
  },
  GR003431: {
    country: 'Greece - GR (009)',
    city: 'ΑΓΙΟΣ ΝΙΚΟΛΑΟΣ',
    name: 'ΑΓΙΟΥ ΝΙΚΟΛΑΟΥ',
  },
  GR003433: {
    country: 'Greece - GR (009)',
    city: 'ΣΗΤΕΙΑ',
    name: 'ΣΗΤΕΙΑΣ',
  },
  GR003502: {
    country: 'Greece - GR (009)',
    city: 'ΜΥΤΙΛΗΝΗ',
    name: 'ΜΥΤΙΛΗΝΗΣ',
  },
  GR003532: {
    country: 'Greece - GR (009)',
    city: 'ΜΥΡΙΝΑ ΛΗΜΝΟΥ',
    name: 'ΜΥΡΙΝΑΣ',
  },
  GR003575: {
    country: 'Greece - GR (009)',
    city: 'ΠΛΩΜΑΡΙ',
    name: 'ΠΛΩΜΑΡΙΟΥ',
  },
  GR003631: {
    country: 'Greece - GR (009)',
    city: 'ΛΕΥΚΑΔΑ',
    name: 'ΛΕΥΚΑΔΑΣ',
  },
  GR003702: {
    country: 'Greece - GR (009)',
    city: 'ΒΟΛΟΣ',
    name: 'ΒΟΛΟΥ',
  },
  GR003751: {
    country: 'Greece - GR (009)',
    city: 'ΣΚΙΑΘΟΣ',
    name: 'ΣΚΙΑΘΟΥ',
  },
  GR003802: {
    country: 'Greece - GR (009)',
    city: 'ΚΑΛΑΜΑΤΑ',
    name: 'ΚΑΛΑΜΑΤΑΣ',
  },
  GR003931: {
    country: 'Greece - GR (009)',
    city: 'ΞΑΝΘΗ',
    name: 'ΞΑΝΘΗΣ',
  },
  GR004001: {
    country: 'Greece - GR (009)',
    city: 'ΠΕΙΡΑΙΑΣ',
    name: "Α' ΠΕΙΡΑΙΑ",
  },
  GR004005: {
    country: 'Greece - GR (009)',
    city: 'ΠΕΙΡΑΙΑΣ',
    name: 'Γ΄ ΠΕΙΡΑΙΑ',
  },
  GR004006: {
    country: 'Greece - GR (009)',
    city: 'ΠΕΙΡΑΙΑΣ',
    name: 'B΄ ΠΕΙΡΑΙΑ',
  },
  GR004007: {
    country: 'Greece - GR (009)',
    city: 'ΠΕΙΡΑΙΑΣ',
    name: "Δ' ΠΕΙΡΑΙΑ",
  },
  GR004019: {
    country: 'Greece - GR (009)',
    city: 'ΠΕΙΡΑΙΑΣ',
    name: 'ΤΕΛΩΝΕΙΑΚΗ ΠΕΡΙΦΕΡΕΙΑ ΑΤΤΙΚΗΣ',
  },
  GR004031: {
    country: 'Greece - GR (009)',
    city: 'ΠΟΡΟΣ',
    name: 'ΠΟΡΟΥ',
  },
  GR004076: {
    country: 'Greece - GR (009)',
    city: 'KAPSALI KITHIRA',
    name: 'KITHIRA',
  },
  GR004101: {
    country: 'Greece - GR (009)',
    city: 'ΣΚΥΔΡΑ',
    name: 'ΣΚΥΔΡΑΣ',
  },
  GR004201: {
    country: 'Greece - GR (009)',
    city: 'ΚΑΤΕΡΙΝΗ',
    name: 'ΚΑΤΕΡΙΝΗΣ',
  },
  GR004302: {
    country: 'Greece - GR (009)',
    city: 'ΠΡΕΒΕΖΑ',
    name: 'ΠΡΕΒΕΖΑΣ',
  },
  GR004431: {
    country: 'Greece - GR (009)',
    city: 'ΡΕΘΥΜΝΟ',
    name: 'ΡΕΘΥΜΝΗΣ',
  },
  GR004531: {
    country: 'Greece - GR (009)',
    city: 'ΚΟΜΟΤΗΝΗ',
    name: 'ΚΟΜΟΤΗΝΗΣ',
  },
  GR004631: {
    country: 'Greece - GR (009)',
    city: 'ΣΑΜΟΣ',
    name: 'ΣΑΜΟΥ',
  },
  GR004671: {
    country: 'Greece - GR (009)',
    city: 'ΑΓΙΟΣ ΚΗΡΥΚΟΣ',
    name: 'ΑΓΙΟΥ ΚΗΡΥΚΟΥ',
  },
  GR004701: {
    country: 'Greece - GR (009)',
    city: 'ΣΕΡΡΕΣ',
    name: 'ΣΕΡΡΩΝ',
  },
  GR004831: {
    country: 'Greece - GR (009)',
    city: 'ΤΡΙΚΑΛΑ',
    name: 'ΤΡΙΚΑΛΩΝ',
  },
  GR004902: {
    country: 'Greece - GR (009)',
    city: 'ΣΤΥΛΙΔΑ',
    name: 'ΣΤΥΛΙΔΑΣ',
  },
  GR005001: {
    country: 'Greece - GR (009)',
    city: 'ΚΡΥΣΤΑΛΛΟΠΗΓΗ',
    name: 'ΚΡΥΣΤΑΛΛΟΠΗΓΗΣ',
  },
  GR005031: {
    country: 'Greece - GR (009)',
    city: 'ΦΛΩΡΙΝΑ',
    name: 'ΦΛΩΡΙΝΑΣ',
  },
  GR005032: {
    country: 'Greece - GR (009)',
    city: 'ΝΙΚΗ - ΦΛΩΡΙΝΑΣ',
    name: 'ΝΙΚΗΣ',
  },
  GR005131: {
    country: 'Greece - GR (009)',
    city: 'ΙΤΕΑ',
    name: 'ΙΤΕΑΣ',
  },
  GR005201: {
    country: 'Greece - GR (009)',
    city: 'ΔΑΦΝΗ',
    name: 'ΔΑΦΝΗΣ',
  },
  GR005233: {
    country: 'Greece - GR (009)',
    city: 'ΙΕΡΙΣΣΟΣ',
    name: 'ΙΕΡΙΣΣΟΥ',
  },
  GR005234: {
    country: 'Greece - GR (009)',
    city: 'ΝΕΑ ΜΟΥΔΑΝΙΑ',
    name: 'ΝΕΩΝ ΜΟΥΔΑΝΙΩΝ',
  },
  GR005302: {
    country: 'Greece - GR (009)',
    city: 'ΣΟΥΔΑ',
    name: 'ΧΑΝΙΩΝ',
  },
  GR005401: {
    country: 'Greece - GR (009)',
    city: 'ΧΙΟΣ',
    name: 'ΧΙΟΥ',
  },
  GR009901: {
    country: 'Greece - GR (009)',
    city: 'ΑΘΗΝΑ',
    name: 'ΔΙΕΥΘΥΝΣΗ ΤΕΛΩΝΕΙΑΚΩΝ ΔΙΑΔΙΚΑΣΙΩΝ',
  },
  GR009917: {
    country: 'Greece - GR (009)',
    city: 'ΑΘΗΝΑ',
    name: 'ΔΙΕΥΘΥΝΣΗ ΔΑΣΜΟΛΟΓΙΚΩΝ ΘΕΜΑΤΩΝ',
  },
  GR009934: {
    country: 'Greece - GR (009)',
    city: 'ΑΘΗΝΑ',
    name: 'ΔΙΕΥΘΥΝΣΗ ΕΙΔΙΚΩΝ ΦΟΡΩΝ ΚΑΤΑΝΑΛΩΣΗΣ',
  },
  GR009991: {
    country: 'Greece - GR (009)',
    city: 'Piraeus',
    name: 'Customs Audit Service of Attica',
  },
  HR011002: {
    country: 'Croatia - HR (092)',
    city: 'ZAGREB',
    name: 'CARINSKA UPRAVA RH SREDIŠNJI URED',
  },
  HR012351: {
    country: 'Croatia - HR (092)',
    city: 'ZAGREB',
    name: 'ODJEL ZA POTRAGE I ZAKLJ. POSTUPAKA',
  },
  HR017701: {
    country: 'Croatia - HR (092)',
    city: 'ZAGREB',
    name: 'SLUŽBA - TROŠARINSKI URED ZA VEZU ELO',
  },
  HR030007: {
    country: 'Croatia - HR (092)',
    city: 'ZAGREB',
    name: 'PODRUČNI CARINSKI URED ZAGREB PCU',
  },
  HR030147: {
    country: 'Croatia - HR (092)',
    city: 'ZAGREB',
    name: 'CARINSKI URED ZAGREB CU',
  },
  HR030171: {
    country: 'Croatia - HR (092)',
    city: 'ZAGREB',
    name: 'SLUŽBA ZA RGP JANKOMIR I SL.ZONA SLUŽBA ZA RGP',
  },
  HR030228: {
    country: 'Croatia - HR (092)',
    city: 'ZAGREB',
    name: 'SLUŽBA ZA RGP ŽITNJAK SLUŽBA ZA RGP',
  },
  HR030236: {
    country: 'Croatia - HR (092)',
    city: 'VELIKA GORICA',
    name: 'RGP ZRAČNA LUKA ZAGREB RGP',
  },
  HR030244: {
    country: 'Croatia - HR (092)',
    city: 'VELIKA GORICA',
    name: 'RGP POŠTA RGP',
  },
  HR030252: {
    country: 'Croatia - HR (092)',
    city: 'KRAPINA',
    name: 'CARINSKI URED KRAPINA CU',
  },
  HR030287: {
    country: 'Croatia - HR (092)',
    city: 'KRAPINA',
    name: 'SLUŽBA ZA RGP KRAPINA I SL.ZONA RGP',
  },
  HR030295: {
    country: 'Croatia - HR (092)',
    city: 'VARAŽDIN',
    name: 'CARINSKI URED VARAŽDIN CU',
  },
  HR030325: {
    country: 'Croatia - HR (092)',
    city: 'VARAŽDIN',
    name: 'SLUŽBA ZA RGP VARAŽDIN I SL.ZONA SLUŽBA ZA RGP',
  },
  HR030333: {
    country: 'Croatia - HR (092)',
    city: 'ČAKOVEC',
    name: 'SLUŽBA ZA RGP ČAKOVEC SLUŽBA ZA RGP',
  },
  HR030341: {
    country: 'Croatia - HR (092)',
    city: 'KOPRIVNICA',
    name: 'CARINSKI URED KOPRIVNICA CU',
  },
  HR030376: {
    country: 'Croatia - HR (092)',
    city: 'KOPRIVNICA',
    name: 'SLUŽBA ZA RGP KOPRIVNICA SLUŽBA ZA RGP',
  },
  HR030384: {
    country: 'Croatia - HR (092)',
    city: 'BJELOVAR',
    name: 'SLUŽBA ZA RGP BJELOVAR SLUŽBA ZA RGP',
  },
  HR030392: {
    country: 'Croatia - HR (092)',
    city: 'SISAK',
    name: 'CARINSKI URED SISAK CU',
  },
  HR030422: {
    country: 'Croatia - HR (092)',
    city: 'SISAK',
    name: 'SLUŽBA ZA RGP SISAK SLUŽBA ZA RGP',
  },
  HR030430: {
    country: 'Croatia - HR (092)',
    city: 'KUTINA',
    name: 'KONTROLNO MJESTO KUTINA KM',
  },
  HR030449: {
    country: 'Croatia - HR (092)',
    city: 'SISAK',
    name: 'KONTROLNO MJESTO CRNAC KM',
  },
  HR030457: {
    country: 'Croatia - HR (092)',
    city: 'KARLOVAC',
    name: 'CARINSKI URED KARLOVAC CU',
  },
  HR030481: {
    country: 'Croatia - HR (092)',
    city: 'KARLOVAC',
    name: 'SLUŽBA ZA RGP KARLOVAC SLUŽBA ZA RGP',
  },
  HR030503: {
    country: 'Croatia - HR (092)',
    city: 'UŠTICA',
    name: 'RGP JASENOVAC RGP',
  },
  HR030538: {
    country: 'Croatia - HR (092)',
    city: 'DIVUŠA',
    name: 'KONTROLNO MJESTO VOLINJA KM',
  },
  HR030546: {
    country: 'Croatia - HR (092)',
    city: 'HRVATSKA KOSTAJNICA',
    name: 'KONTROLNO MJESTO HRV. KOSTAJNICA KM',
  },
  HR030562: {
    country: 'Croatia - HR (092)',
    city: 'ZAGREB',
    name: 'SLUŽBA ZA RGP ZAPADNI KOLODVOR SLUŽBA ZA RGP',
  },
  HR030597: {
    country: 'Croatia - HR (092)',
    city: 'UŠTICA',
    name: 'GCU JASENOVAC GCU',
  },
  HR030660: {
    country: 'Croatia - HR (092)',
    city: 'Đurmanec',
    name: 'KONTROLNO MJESTO MACELJ KM',
  },
  HR030686: {
    country: 'Croatia - HR (092)',
    city: 'VELIKA GORICA',
    name: 'GCU ZRAČNA LUKA I POŠTA GCU',
  },
  HR030716: {
    country: 'Croatia - HR (092)',
    city: 'ZAGREB',
    name: 'KONTROLNO MJESTO PODSUSED KONTROLNO MJESTO',
  },
  HR030724: {
    country: 'Croatia - HR (092)',
    city: 'BUZIN',
    name: 'KM I (RHEA-UPS) KONTROLNO MJESTO',
  },
  HR030732: {
    country: 'Croatia - HR (092)',
    city: 'NOVI ZAGREB',
    name: 'KM II (IN TIME-FEDEX I TNT) KONTROLNO MJESTO',
  },
  HR030740: {
    country: 'Croatia - HR (092)',
    city: 'NOVI ZAGREB',
    name: 'KM III (DHL) KONTROLNO MJESTO',
  },
  HR030759: {
    country: 'Croatia - HR (092)',
    city: 'HRVATSKI LESKOVAC',
    name: 'KM IV (LAGERMAX - DPD) KONTROLNO MJESTO',
  },
  HR030767: {
    country: 'Croatia - HR (092)',
    city: 'KOTORIBA',
    name: 'KONTROLNO MJESTO KOTORIBA KONTROLNO MJESTO',
  },
  HR060003: {
    country: 'Croatia - HR (092)',
    city: 'RIJEKA',
    name: 'PODRUČNI CARINSKI URED RIJEKA PCU',
  },
  HR060143: {
    country: 'Croatia - HR (092)',
    city: 'RIJEKA',
    name: 'CARINSKI URED RIJEKA CU',
  },
  HR060178: {
    country: 'Croatia - HR (092)',
    city: 'RIJEKA',
    name: 'SLUŽBA ZA RGP LUKA RIJEKA SLUŽBA ZA RGP',
  },
  HR060186: {
    country: 'Croatia - HR (092)',
    city: 'OMIŠALJ',
    name: 'KONTROLNO MJESTO ZRAČNA LUKA RIJEKA KM',
  },
  HR060208: {
    country: 'Croatia - HR (092)',
    city: 'OMIŠALJ',
    name: 'KONTROLNO MJESTO OMIŠALJ KM',
  },
  HR060216: {
    country: 'Croatia - HR (092)',
    city: 'MALI LOŠINJ',
    name: 'KONTROLNO MJESTO MALI LOŠINJ KM',
  },
  HR060224: {
    country: 'Croatia - HR (092)',
    city: 'KUKULJANOVO',
    name: 'SLUŽBA ZA RGP ŠKRLJEVO SLUŽBA ZA RGP',
  },
  HR060232: {
    country: 'Croatia - HR (092)',
    city: 'PULA',
    name: 'CARINSKI URED PULA - POLA CU',
  },
  HR060267: {
    country: 'Croatia - HR (092)',
    city: 'PULA',
    name: 'SLUŽBA ZA RGP LUKA PULA - POLA SLUŽBA ZA RGP',
  },
  HR060275: {
    country: 'Croatia - HR (092)',
    city: 'LABIN',
    name: 'KONTROLNO MJESTO LABIN KM',
  },
  HR060283: {
    country: 'Croatia - HR (092)',
    city: 'PULA',
    name: 'KONTOLNO MJESTO ZRAČNA LUKA PULA KM',
  },
  HR060291: {
    country: 'Croatia - HR (092)',
    city: 'BUJE',
    name: 'KONTROLNO MJESTO BUJE KM',
  },
  HR060305: {
    country: 'Croatia - HR (092)',
    city: 'TRGET',
    name: 'KONTROLNO MJESTO BRŠICA KM',
  },
  HR060313: {
    country: 'Croatia - HR (092)',
    city: 'UMAG',
    name: 'KONTROLNO MJESTO UMAG KM',
  },
  HR060356: {
    country: 'Croatia - HR (092)',
    city: 'PAZIN',
    name: 'SLUŽBA ZA RGP PAZIN SLUŽBA ZA RGP',
  },
  HR060364: {
    country: 'Croatia - HR (092)',
    city: 'GOSPIĆ',
    name: 'CARINSKI URED GOSPIĆ CU',
  },
  HR060399: {
    country: 'Croatia - HR (092)',
    city: 'GOSPIĆ',
    name: 'SLUŽBA ZA RGP GOSPIĆ SLUŽBA ZA RGP',
  },
  HR060402: {
    country: 'Croatia - HR (092)',
    city: 'LIČKO PETROVO SELO',
    name: 'GCU LIČKO PETROVO SELO GCU',
  },
  HR060410: {
    country: 'Croatia - HR (092)',
    city: 'LIČKO PETROVO SELO',
    name: 'RGP LIČKO PETROVO SELO RGP',
  },
  HR060461: {
    country: 'Croatia - HR (092)',
    city: 'CETINGRAD',
    name: 'RGP MALJEVAC RGP',
  },
  HR070009: {
    country: 'Croatia - HR (092)',
    city: 'OSIJEK',
    name: 'PODRUČNI CARINSKI URED OSIJEK PCU',
  },
  HR070149: {
    country: 'Croatia - HR (092)',
    city: 'OSIJEK',
    name: 'CARINSKI URED OSIJEK CU',
  },
  HR070173: {
    country: 'Croatia - HR (092)',
    city: 'OSIJEK',
    name: 'SLUŽBA ZA RGP OSIJEK I SL. ZONA SLUŽBA ZA RGP',
  },
  HR070181: {
    country: 'Croatia - HR (092)',
    city: 'OSIJEK',
    name: 'KONTROLNO MJESTO ZRAČNA LUKA OSIJEK KM',
  },
  HR070203: {
    country: 'Croatia - HR (092)',
    city: 'VINKOVCI',
    name: 'SLUŽBA ZA RGP VINKOVCI SLUŽBA ZA RGP',
  },
  HR070211: {
    country: 'Croatia - HR (092)',
    city: 'SLAVONSKI BROD',
    name: 'CARINSKI URED SLAVONSKI BROD CU',
  },
  HR070246: {
    country: 'Croatia - HR (092)',
    city: 'SLAVONSKI BROD',
    name: 'SLUŽBA ZA RGP SL. BROD I SL. ZONA SLUŽBA ZA RGP',
  },
  HR070254: {
    country: 'Croatia - HR (092)',
    city: 'OSIJEK',
    name: 'SLUŽBA ZA RGP POŽEGA KM',
  },
  HR070262: {
    country: 'Croatia - HR (092)',
    city: 'VIROVITICA',
    name: 'CARINSKI URED VIROVITICA CU',
  },
  HR070297: {
    country: 'Croatia - HR (092)',
    city: 'VIROVITICA',
    name: 'SLUŽBA ZA RGP VIROVITICA SLUŽBA ZA RGP',
  },
  HR070300: {
    country: 'Croatia - HR (092)',
    city: 'BAJAKOVO',
    name: 'GCU BAJAKOVO GCU',
  },
  HR070319: {
    country: 'Croatia - HR (092)',
    city: 'BAJAKOVO',
    name: 'RGP BAJAKOVO RGP',
  },
  HR070327: {
    country: 'Croatia - HR (092)',
    city: 'TOVARNIK',
    name: 'RGP RGP TOVARNIK I RGP Ž.KOL. TOVARNIK',
  },
  HR070343: {
    country: 'Croatia - HR (092)',
    city: 'VUKOVAR',
    name: 'SLUŽBA ZA RGP VUKOVAR I SL ZONA RGP',
  },
  HR070378: {
    country: 'Croatia - HR (092)',
    city: 'BATINA',
    name: 'RGP BATINA RGP',
  },
  HR070386: {
    country: 'Croatia - HR (092)',
    city: 'ILOK',
    name: 'RGP ILOK RGP',
  },
  HR070394: {
    country: 'Croatia - HR (092)',
    city: 'ILOK',
    name: 'KONTROLNO MJESTO PRINCIPOVAC I KM',
  },
  HR070408: {
    country: 'Croatia - HR (092)',
    city: 'ILOK',
    name: 'KONTROLNO MJESTO PRINCIPOVAC II KM',
  },
  HR070416: {
    country: 'Croatia - HR (092)',
    city: 'ILOK',
    name: 'KONTROLNO MJESTO ILOK I KM',
  },
  HR070432: {
    country: 'Croatia - HR (092)',
    city: 'ŽUPANJA',
    name: 'GCU ŽUPANJA GCU',
  },
  HR070440: {
    country: 'Croatia - HR (092)',
    city: 'ŽUPANJA',
    name: 'RGP ŽUPANJA MOST RGP',
  },
  HR070467: {
    country: 'Croatia - HR (092)',
    city: 'GUNJA',
    name: 'RGP GUNJA RGP',
  },
  HR070483: {
    country: 'Croatia - HR (092)',
    city: 'DRENOVCI',
    name: 'KONTROLNO MJESTO Ž. KOL. DRENOVCI KM',
  },
  HR070491: {
    country: 'Croatia - HR (092)',
    city: 'SLAVONSKI ŠAMAC',
    name: 'RGP SL. ŠAMAC I ŽELJ.KOL. SL. ŠAMAC RGP',
  },
  HR070513: {
    country: 'Croatia - HR (092)',
    city: 'STARA GRADIŠKA',
    name: 'GCU STARA GRADIŠKA GCU',
  },
  HR070521: {
    country: 'Croatia - HR (092)',
    city: 'STARA GRADIŠKA',
    name: 'RGP STARA GRADIŠKA RGP',
  },
  HR070530: {
    country: 'Croatia - HR (092)',
    city: 'VUKOVAR',
    name: 'CARINSKI URED VUKOVAR CU',
  },
  HR070548: {
    country: 'Croatia - HR (092)',
    city: 'SLAVONSKI BROD',
    name: 'RGP SLAVONSKI BROD I LUKA SL.BROD RGP',
  },
  HR070556: {
    country: 'Croatia - HR (092)',
    city: 'SVILAJ',
    name: 'RGP SVILAJ RGP',
  },
  HR070572: {
    country: 'Croatia - HR (092)',
    city: 'OSIJEK',
    name: 'KONTROLNO MJESTO LUKA DRAVA KM',
  },
  HR070599: {
    country: 'Croatia - HR (092)',
    city: 'ERDUT',
    name: 'RGP ERDUT I Ž.KOLODVOR RGP',
  },
  HR070661: {
    country: 'Croatia - HR (092)',
    city: 'Županja',
    name: 'SLUŽBA ZA RGP ŽUPANJA SLUŽBA ZA RGP',
  },
  HR070688: {
    country: 'Croatia - HR (092)',
    city: 'ERDUT',
    name: 'GCU ERDUT GCU',
  },
  HR070696: {
    country: 'Croatia - HR (092)',
    city: 'ILOK',
    name: 'GCU ILOK GCU',
  },
  HR070700: {
    country: 'Croatia - HR (092)',
    city: 'SLAVONSKI BROD',
    name: 'GCU SLAVONSKI BROD GCU',
  },
  HR080004: {
    country: 'Croatia - HR (092)',
    city: 'SPLIT',
    name: 'PODRUČNI CARINSKI URED SPLIT PCU',
  },
  HR080144: {
    country: 'Croatia - HR (092)',
    city: 'SPLIT',
    name: 'CARINSKI URED SPLIT CU',
  },
  HR080179: {
    country: 'Croatia - HR (092)',
    city: 'SPLIT',
    name: 'SLUŽBA ZA RGP SPLIT SLUŽBA ZA RGP',
  },
  HR080209: {
    country: 'Croatia - HR (092)',
    city: 'SPLIT',
    name: 'SLUŽBA ZA RGP ZRAČNA LUKA SPLIT KONTROLNO MJESTO',
  },
  HR080225: {
    country: 'Croatia - HR (092)',
    city: 'ŠIBENIK',
    name: 'CARINSKI URED ŠIBENIK CU',
  },
  HR080284: {
    country: 'Croatia - HR (092)',
    city: 'ZADAR',
    name: 'CARINSKI URED ZADAR CU',
  },
  HR080314: {
    country: 'Croatia - HR (092)',
    city: 'ZADAR',
    name: 'SLUŽBA ZA RGP ZADAR SLUŽBA ZA RGP',
  },
  HR080322: {
    country: 'Croatia - HR (092)',
    city: 'ZADAR',
    name: 'KONTROLNO MJESTO ZRAČNA LUKA ZADAR KM',
  },
  HR080357: {
    country: 'Croatia - HR (092)',
    city: 'PLOČE',
    name: 'CARINSKI URED PLOČE CU',
  },
  HR080381: {
    country: 'Croatia - HR (092)',
    city: 'PLOČE',
    name: 'SLUŽBA ZA RGP LUKA PLOČE SLUŽBA ZA RGP',
  },
  HR080438: {
    country: 'Croatia - HR (092)',
    city: 'DUBROVNIK',
    name: 'KONTROLNO MJESTO LUKA DUBROVNIK KM',
  },
  HR080446: {
    country: 'Croatia - HR (092)',
    city: 'KORČULA',
    name: 'SLUŽBA ZA RGP KORČULA SLUŽBA ZA RGP',
  },
  HR080454: {
    country: 'Croatia - HR (092)',
    city: 'VELA LUKA',
    name: 'KONTROLNO MJESTO VELA LUKA KM',
  },
  HR080470: {
    country: 'Croatia - HR (092)',
    city: 'DUBROVNIK',
    name: 'KONT. MJESTO ZRAČNA LUKA DUBROVNIK KONTROLNO MJESTO',
  },
  HR080497: {
    country: 'Croatia - HR (092)',
    city: 'KAMENSKO',
    name: 'GCU KAMENSKO GCU',
  },
  HR080500: {
    country: 'Croatia - HR (092)',
    city: 'KAMENSKO',
    name: 'RGP KAMENSKO RGP',
  },
  HR080551: {
    country: 'Croatia - HR (092)',
    city: 'GOLUBIĆ',
    name: 'RGP STRMICA RGP',
  },
  HR080586: {
    country: 'Croatia - HR (092)',
    city: 'VINJANI DONJI',
    name: 'GCU VINJANI DONJI GCU',
  },
  HR080594: {
    country: 'Croatia - HR (092)',
    city: 'VINJANI DONJI',
    name: 'RGP VINJANI DONJI RGP',
  },
  HR080632: {
    country: 'Croatia - HR (092)',
    city: 'VINJANI GORNJI',
    name: 'RGP VINJANI GORNJI RGP',
  },
  HR080640: {
    country: 'Croatia - HR (092)',
    city: 'NOVA SELA',
    name: 'GCU METKOVIĆ GCU',
  },
  HR080659: {
    country: 'Croatia - HR (092)',
    city: 'NOVA SELA',
    name: 'RGP METKOVIĆ - NOVA SELA RGP',
  },
  HR080683: {
    country: 'Croatia - HR (092)',
    city: 'METKOVIĆ',
    name: 'RGP Ž. KOLODVOR I LUKA METKOVIĆ RGP',
  },
  HR080756: {
    country: 'Croatia - HR (092)',
    city: 'KLEK',
    name: 'RGP KLEK RGP',
  },
  HR080772: {
    country: 'Croatia - HR (092)',
    city: 'KARASOVIĆI',
    name: 'GCU KARASOVIĆI GCU',
  },
  HR080780: {
    country: 'Croatia - HR (092)',
    city: 'KARASOVIĆI',
    name: 'RGP KARASOVIĆI RGP',
  },
  HR080802: {
    country: 'Croatia - HR (092)',
    city: 'GORNJI BRGAT',
    name: 'KM GORNJI BRGAT KM',
  },
  HR080810: {
    country: 'Croatia - HR (092)',
    city: 'ZATON DOLI',
    name: 'RGP ZATON DOLI RGP',
  },
  HR080853: {
    country: 'Croatia - HR (092)',
    city: 'STARI GRAD',
    name: 'KONTROLNO MJESTO STARI GRAD KONTROLNO MJESTO',
  },
  HR080896: {
    country: 'Croatia - HR (092)',
    city: 'ŠIBENIK',
    name: 'SLUŽBA ZA RGP ŠIBENIK SLUŽBA ZA RGP',
  },
  HR080918: {
    country: 'Croatia - HR (092)',
    city: 'DUBROVNIK',
    name: 'SLUŽBA ZA RGP DUBROVNIK SLUŽBA ZA RGP',
  },
  HR080950: {
    country: 'Croatia - HR (092)',
    city: 'DUBROVNIK',
    name: 'CARINSKI URED DUBROVNIK CU',
  },
  HU001100: {
    country: 'Hungary - HU (064)',
    city: 'Budapest',
    name: 'NAV Központi Irányítás Vám Főo',
  },
  HU001200: {
    country: 'Hungary - HU (064)',
    city: 'Budapest',
    name: 'NAV Központi Irányítás Jövedéki Főo',
  },
  HU002900: {
    country: 'Hungary - HU (064)',
    city: 'Budapest',
    name: 'NAV Közp.Irányítás Ellenőrzési Főo.',
  },
  HU013000: {
    country: 'Hungary - HU (064)',
    city: 'Budapest, VI. ker.',
    name: 'NAV KH Kock.kez. és Kock.elem. Főo.',
  },
  HU015000: {
    country: 'Hungary - HU (064)',
    city: 'Budapest, XIV. ker.',
    name: 'NAV Informatikai Intézet',
  },
  HU050000: {
    country: 'Hungary - HU (064)',
    city: 'Budapest',
    name: 'NAV Fellebbviteli Igazgatósága',
  },
  HU101000: {
    country: 'Hungary - HU (064)',
    city: 'Budapest, IX. ker.',
    name: 'NAV Dél-Budapesti AVI',
  },
  HU101150: {
    country: 'Hungary - HU (064)',
    city: 'Budapest',
    name: 'NAV Dél-budapesti AVI Metrans Sz.h.',
  },
  HU101220: {
    country: 'Hungary - HU (064)',
    city: 'Budapest, XXIII. ker.',
    name: 'NAV Dél-budapesti AVI Bilk Log. SZH',
  },
  HU101280: {
    country: 'Hungary - HU (064)',
    city: 'Budapest, XXI. ker.',
    name: 'NAV Dél-budapesti AVI BSZL Zrt. SZH',
  },
  HU102000: {
    country: 'Hungary - HU (064)',
    city: 'Budapest, VII. ker.',
    name: 'NAV KAVIG',
  },
  HU106200: {
    country: 'Hungary - HU (064)',
    city: 'Fót',
    name: 'NAV Pest Megyei AVI Masped Rt. SZH',
  },
  HU121000: {
    country: 'Hungary - HU (064)',
    city: 'Budapest, XVIII. ker.',
    name: 'NAV Repülőtéri Igazgatósága',
  },
  HU160000: {
    country: 'Hungary - HU (064)',
    city: 'Budapest, XIV. ker.',
    name: 'NAV Kelet-Budapesti AVI',
  },
  HU170000: {
    country: 'Hungary - HU (064)',
    city: 'Budapest, XIII ker.',
    name: 'NAV Észak-Budapesti AVI',
  },
  HU190000: {
    country: 'Hungary - HU (064)',
    city: 'Budapest',
    name: 'NAV Szakértői Intézete',
  },
  HU211000: {
    country: 'Hungary - HU (064)',
    city: 'Pécs',
    name: 'NAV Baranya Megyei AVI',
  },
  HU213000: {
    country: 'Hungary - HU (064)',
    city: 'Mohács',
    name: 'NAV Baranya Megyei AVI Mohácsi Hk.',
  },
  HU221000: {
    country: 'Hungary - HU (064)',
    city: 'Kaposvár',
    name: 'NAV Somogy Megyei AVI',
  },
  HU231000: {
    country: 'Hungary - HU (064)',
    city: 'Szekszárd',
    name: 'NAV Tolna Megyei AVI',
  },
  HU311040: {
    country: 'Hungary - HU (064)',
    city: 'Kecskemét',
    name: 'NAV BÁKM AVI Royal Sped Zrt.Sz.Hely',
  },
  HU311500: {
    country: 'Hungary - HU (064)',
    city: 'Kecskemét',
    name: 'NAV Bács-Kiskun Megyei AVI',
  },
  HU315000: {
    country: 'Hungary - HU (064)',
    city: 'Hercegszántó',
    name: 'NAV BÁKM AVI Hercegszántói Hk.',
  },
  HU316000: {
    country: 'Hungary - HU (064)',
    city: 'Kelebia',
    name: 'NAV BÁKM AVI Szolg. Hely Kelebia',
  },
  HU317000: {
    country: 'Hungary - HU (064)',
    city: 'Tompa',
    name: 'NAV BÁKM AVI Tompai Hk.',
  },
  HU321000: {
    country: 'Hungary - HU (064)',
    city: 'Szolnok',
    name: 'NAV Jász-Nagykun-Szolnok Megyei AVI',
  },
  HU321010: {
    country: 'Hungary - HU (064)',
    city: 'Szolnok',
    name: 'NAV JNSZM AVI MB 2001 Kft. Sz. Hely',
  },
  HU331000: {
    country: 'Hungary - HU (064)',
    city: 'Baja',
    name: 'NAV BÁKM AVI Bajai Szolg. Hely I.',
  },
  HU331010: {
    country: 'Hungary - HU (064)',
    city: 'Baja',
    name: 'NAV BÁKM AVI Bajai Szolg. Hely II.',
  },
  HU332000: {
    country: 'Hungary - HU (064)',
    city: 'Kiskőrös',
    name: 'NAV BÁKM AVI Kiskőrösi Szolg. Hely',
  },
  HU411000: {
    country: 'Hungary - HU (064)',
    city: 'Miskolc',
    name: 'NAV Borsod-Abaúj-Zemplén Megyei AVI',
  },
  HU411020: {
    country: 'Hungary - HU (064)',
    city: 'Miskolc',
    name: 'NAV BAZM AVI ÁTI Depó Szolg. Hely',
  },
  HU421000: {
    country: 'Hungary - HU (064)',
    city: 'Eger',
    name: 'NAV Heves Megyei AVI',
  },
  HU422000: {
    country: 'Hungary - HU (064)',
    city: 'Gyöngyös',
    name: 'NAV Heves Megyei AVI Gyöngyös Sz.H',
  },
  HU511000: {
    country: 'Hungary - HU (064)',
    city: 'Szeged',
    name: 'NAV Csongrád-Csanád Megyei AVI',
  },
  HU511010: {
    country: 'Hungary - HU (064)',
    city: 'Szeged',
    name: 'NAV CSO-CS M AVI Zoll-PlatzKft.SzH.',
  },
  HU515000: {
    country: 'Hungary - HU (064)',
    city: 'Röszke',
    name: 'NAV Csongrád-Csanád m.AVI Röszke Hk',
  },
  HU515030: {
    country: 'Hungary - HU (064)',
    city: 'Röszke',
    name: 'NAV CS.CS.AVI Röszke HK Vasúti SZH',
  },
  HU517000: {
    country: 'Hungary - HU (064)',
    city: 'Tiszasziget',
    name: 'NAV CSO-CS M. AVI Tiszaszigeti SzH',
  },
  HU521000: {
    country: 'Hungary - HU (064)',
    city: 'Békéscsaba',
    name: 'NAV Békés Megyei AVI',
  },
  HU521050: {
    country: 'Hungary - HU (064)',
    city: 'Békéscsaba',
    name: 'NAV BÉM AVI Pintér Vám Szolg. Hely',
  },
  HU611000: {
    country: 'Hungary - HU (064)',
    city: 'Győr',
    name: 'NAV Győr-Moson-Sopron Megyei AVI',
  },
  HU611040: {
    country: 'Hungary - HU (064)',
    city: 'Győr',
    name: 'NAVGYMSAVI QuehenbergerLogisticsKft',
  },
  HU618000: {
    country: 'Hungary - HU (064)',
    city: 'Sopron',
    name: 'NAV GYMSM AVI Soproni Szolg. Hely',
  },
  HU618010: {
    country: 'Hungary - HU (064)',
    city: 'Sopron',
    name: 'NAV GYMSM AVI Gysev Cargo Szolg. H.',
  },
  HU621000: {
    country: 'Hungary - HU (064)',
    city: 'Tatabánya',
    name: 'NAV Komárom-Esztergom Megyei AVI',
  },
  HU621030: {
    country: 'Hungary - HU (064)',
    city: 'Tatabánya',
    name: 'NAV KEM AVI ZEBRA NEO2014 SzolgHely',
  },
  HU624000: {
    country: 'Hungary - HU (064)',
    city: 'Esztergom',
    name: 'NAV KEM AVI Esztergomi Szolg. Hely',
  },
  HU624100: {
    country: 'Hungary - HU (064)',
    city: 'Esztergom',
    name: 'NAV KEM AVI Weskamp Bt. Szolg. Hely',
  },
  HU631000: {
    country: 'Hungary - HU (064)',
    city: 'Veszprém',
    name: 'NAV Veszprém Megyei AVI',
  },
  HU634000: {
    country: 'Hungary - HU (064)',
    city: 'Pápa',
    name: 'NAV VEM AVI Pápai Sz. Hely',
  },
  HU634100: {
    country: 'Hungary - HU (064)',
    city: 'Pápa',
    name: 'NAV VEM AVI Pápa Repülőtér',
  },
  HU711000: {
    country: 'Hungary - HU (064)',
    city: 'Debrecen',
    name: 'NAV Hajdú-Bihar Megyei AVI',
  },
  HU721000: {
    country: 'Hungary - HU (064)',
    city: 'Nyíregyháza',
    name: 'NAV Szabolcs-Szatmár-Bereg M. AVI',
  },
  HU724000: {
    country: 'Hungary - HU (064)',
    city: 'Záhony',
    name: 'NAV SZSZBM AVI Záhonyi Hk.',
  },
  HU724010: {
    country: 'Hungary - HU (064)',
    city: 'Záhony',
    name: 'NAV SZSZBM AVI Eperjeskei Sz. Hely',
  },
  HU726020: {
    country: 'Hungary - HU (064)',
    city: 'Barabás',
    name: 'NAV SZSZBM AVI Barabás Sz. Hely',
  },
  HU726030: {
    country: 'Hungary - HU (064)',
    city: 'Lónya',
    name: 'NAV SZSZBM AVI Lónyai Sz. Hely',
  },
  HU726040: {
    country: 'Hungary - HU (064)',
    city: 'Beregsurány',
    name: 'NAV SZSZBM AVI Beregsurányi Hk.',
  },
  HU727000: {
    country: 'Hungary - HU (064)',
    city: 'Tiszabecs',
    name: 'NAV SZSZBM AVI Tiszabecsi Sz. Hely',
  },
  HU727010: {
    country: 'Hungary - HU (064)',
    city: 'Nagyhódos',
    name: 'NAV SZSZBM AVI Nagyhódosi Szolg. H.',
  },
  HU811000: {
    country: 'Hungary - HU (064)',
    city: 'Budapest',
    name: 'NAV PMAVI',
  },
  HU811010: {
    country: 'Hungary - HU (064)',
    city: 'Törökbálint',
    name: 'NAV PMAVI Depo-Zoll Kft. Sz. Hely',
  },
  HU811020: {
    country: 'Hungary - HU (064)',
    city: 'Szigetszentmiklós',
    name: 'NAV Pest Megyei AVI Schenker Kft.',
  },
  HU811190: {
    country: 'Hungary - HU (064)',
    city: 'Budapest, XXII. ker.',
    name: 'NAV DBP AVI Trimex Kft. Sz. Hely',
  },
  HU821000: {
    country: 'Hungary - HU (064)',
    city: 'Székesfehérvár',
    name: 'NAV Fejér Megyei AVI',
  },
  HU821060: {
    country: 'Hungary - HU (064)',
    city: 'Székesfehérvár',
    name: 'NAV FEM AVI LOGALBA Kft. Sz. Hely',
  },
  HU831000: {
    country: 'Hungary - HU (064)',
    city: 'Salgótarján',
    name: 'NAV NÓM AVI',
  },
  HU911000: {
    country: 'Hungary - HU (064)',
    city: 'Szombathely',
    name: 'NAV VAM AVI',
  },
  HU911010: {
    country: 'Hungary - HU (064)',
    city: 'Szombathely',
    name: 'NAV VAM AVI VÁMCO Szolgálati Hely',
  },
  HU921000: {
    country: 'Hungary - HU (064)',
    city: 'Zalaegerszeg',
    name: 'NAV ZAM AVI',
  },
  HU921060: {
    country: 'Hungary - HU (064)',
    city: 'Sármellék',
    name: 'NAV ZAM AVI Sármelléki Repülőtér',
  },
  HU922000: {
    country: 'Hungary - HU (064)',
    city: 'Nagykanizsa',
    name: 'NAV ZAMAVI Nagykanizsai Szolg. Hely',
  },
  HU922050: {
    country: 'Hungary - HU (064)',
    city: 'Nagykanizsa',
    name: 'NAV ZAM AVI Nagykanizsa VSZ Zrt.',
  },
  IE003151: {
    country: 'Ireland - IE (007)',
    city: 'Nenagh, Co Tipperary',
    name: 'Central Transit Office,',
  },
  IEARK100: {
    country: 'Ireland - IE (007)',
    city: 'N/A',
    name: 'ARKLOW',
  },
  IEASH900: {
    country: 'Ireland - IE (007)',
    city: 'Dublin 15',
    name: 'Customs Drug Enforcement & IBD',
  },
  IEATH200: {
    country: 'Ireland - IE (007)',
    city: 'Athlone, Co Westmeath',
    name: 'ACE/ACR ATHLONE',
  },
  IEATH800: {
    country: 'Ireland - IE (007)',
    city: 'Athlone',
    name: 'Athlone',
  },
  IEATH900: {
    country: 'Ireland - IE (007)',
    city: 'Athlone',
    name: 'Athlone Mail Centre',
  },
  IEATY200: {
    country: 'Ireland - IE (007)',
    city: 'ATHY',
    name: 'ACE/ACR Athy',
  },
  IEATY604: {
    country: 'Ireland - IE (007)',
    city: 'Kildare',
    name: 'Aldi Stores Ltd',
  },
  IEATY800: {
    country: 'Ireland - IE (007)',
    city: 'Athy, Co. Kildare',
    name: 'Athy',
  },
  IEAUU000: {
    country: 'Ireland - IE (007)',
    city: 'CO MEATH',
    name: 'NAVAN',
  },
  IEBLM401: {
    country: 'Ireland - IE (007)',
    city: 'Ballycoolin, Dublin 16',
    name: 'Masterlink Solutions Ltd',
  },
  IEBTP100: {
    country: 'Ireland - IE (007)',
    city: 'Co. Donegal',
    name: 'Burtonport',
  },
  IEBYT100: {
    country: 'Ireland - IE (007)',
    city: 'N/A',
    name: 'BANTRY, CORK',
  },
  IECAV100: {
    country: 'Ireland - IE (007)',
    city: 'MONAGHAN CO CAVAN',
    name: 'lMD CUSTOMS HUB',
  },
  IECAV800: {
    country: 'Ireland - IE (007)',
    city: 'Church street, Cavan',
    name: 'Cavan',
  },
  IECAW800: {
    country: 'Ireland - IE (007)',
    city: 'Carlow',
    name: 'Carlow',
  },
  IECBP100: {
    country: 'Ireland - IE (007)',
    city: 'Co. Cork',
    name: 'Castletownbere Port',
  },
  IECKC900: {
    country: 'Ireland - IE (007)',
    city: 'Cork',
    name: 'Cork Cast',
  },
  IECKM900: {
    country: 'Ireland - IE (007)',
    city: 'CORK',
    name: 'CORK MARITIME UNIT',
  },
  IECKR900: {
    country: 'Ireland - IE (007)',
    city: 'CORK',
    name: 'CORK RAET',
  },
  IECLB800: {
    country: 'Ireland - IE (007)',
    city: 'Castlebar, County Mayo',
    name: 'Castlebar',
  },
  IECLL800: {
    country: 'Ireland - IE (007)',
    city: 'Clobnmel',
    name: 'Clonmel',
  },
  IEDDK100: {
    country: 'Ireland - IE (007)',
    city: 'CO LOUTH',
    name: 'DUNDALK',
  },
  IEDDK800: {
    country: 'Ireland - IE (007)',
    city: 'Dundalk, Co. Louth',
    name: 'Dundalk Excise',
  },
  IEDDK899: {
    country: 'Ireland - IE (007)',
    city: 'DUNDALK, CO LOUTH',
    name: 'CLO (EXCISE)',
  },
  IEDLG100: {
    country: 'Ireland - IE (007)',
    city: 'DUBLIN',
    name: 'DUN LAOGHAIRE QUAYSIDE(CO DUBLIN)',
  },
  IEDON100: {
    country: 'Ireland - IE (007)',
    city: 'DONEGAL',
    name: 'LETTERKENNY',
  },
  IEDON800: {
    country: 'Ireland - IE (007)',
    city: 'Drunlonagher, Donegal',
    name: 'Donegal',
  },
  IEDPE900: {
    country: 'Ireland - IE (007)',
    city: 'DUBLIN 3',
    name: 'NEW CUSTOMS HOUSE ENFORCEMENT',
  },
  IEDPL900: {
    country: 'Ireland - IE (007)',
    city: 'DUBLIN 3',
    name: 'NEW CUSTOM HSE LANDING STATION',
  },
  IEDRO100: {
    country: 'Ireland - IE (007)',
    city: 'N/A',
    name: 'DROGHEDA PORT',
  },
  IEDRO800: {
    country: 'Ireland - IE (007)',
    city: 'Drogheda, County Louth',
    name: 'Drogheda Excise',
  },
  IEDUB100: {
    country: 'Ireland - IE (007)',
    city: 'DUBLIN',
    name: 'DUBLIN PORT',
  },
  IEDUB200: {
    country: 'Ireland - IE (007)',
    city: 'DUBLIN',
    name: 'ACE/ACR DUBLIN',
  },
  IEDUB400: {
    country: 'Ireland - IE (007)',
    city: 'DUBLIN',
    name: 'DUBLIN AIRPORT',
  },
  IEDUB413: {
    country: 'Ireland - IE (007)',
    city: 'SWORDS CO DUBLIN',
    name: 'RYANAIR DAC',
  },
  IEDUB416: {
    country: 'Ireland - IE (007)',
    city: 'Santry Dublin 9 D09 FT22',
    name: 'Gilmac Ltd',
  },
  IEDUB500: {
    country: 'Ireland - IE (007)',
    city: 'DUBLIN',
    name: 'DUBLIN MAIL CENTRE',
  },
  IEDUB700: {
    country: 'Ireland - IE (007)',
    city: 'Dublin 2',
    name: 'LCD Mount ST',
  },
  IEDUB800: {
    country: 'Ireland - IE (007)',
    city: 'Dublin 1',
    name: 'Oil Wharf Dublin',
  },
  IEDUB801: {
    country: 'Ireland - IE (007)',
    city: 'Belgard Road, Tallaght, Dublin 24',
    name: 'Dublin South County',
  },
  IEDUB802: {
    country: 'Ireland - IE (007)',
    city: 'Ashtown gate, Navan Road, Dublin 15',
    name: 'Fingal',
  },
  IEDUB803: {
    country: 'Ireland - IE (007)',
    city: 'Dublin 2',
    name: 'Dublin South City',
  },
  IEDUB804: {
    country: 'Ireland - IE (007)',
    city: 'Dublin 4',
    name: 'Dun Laoghaire-Rathdown',
  },
  IEDUB805: {
    country: 'Ireland - IE (007)',
    city: "9-10 Upper O'Connell St, Dublin 1",
    name: 'Dublin City Centre',
  },
  IEDUB806: {
    country: 'Ireland - IE (007)',
    city: 'TALLAGHT, DUBLIN 24',
    name: 'LCD (DTM) TALLAGHT',
  },
  IEDUB807: {
    country: 'Ireland - IE (007)',
    city: 'NASSAU STREET, DUBLIN 2',
    name: 'LCD (DTM) SETANTA CENTRE',
  },
  IEDUB808: {
    country: 'Ireland - IE (007)',
    city: 'NASSAU STREET, DUBLIN 2',
    name: 'LCD (HIM) SETANTA CENTRE,',
  },
  IEDUB809: {
    country: 'Ireland - IE (007)',
    city: 'DUBLIN 1',
    name: 'DUBLIN NORTH CITY DISTRICT',
  },
  IEDUB900: {
    country: 'Ireland - IE (007)',
    city: 'Dublin',
    name: 'Dublin Mail Centre',
  },
  IEENI800: {
    country: 'Ireland - IE (007)',
    city: 'Ennis, County Clare',
    name: 'Clare',
  },
  IEFEN100: {
    country: 'Ireland - IE (007)',
    city: 'CO KERRY',
    name: 'FENIT CUSTOMS AND EXCISE',
  },
  IEFOV100: {
    country: 'Ireland - IE (007)',
    city: 'N/A',
    name: 'FOYNES',
  },
  IEFOV200: {
    country: 'Ireland - IE (007)',
    city: 'CO LIMERICK',
    name: 'ACE/ACR FOYNES',
  },
  IEGCP100: {
    country: 'Ireland - IE (007)',
    city: 'Co. Donegal',
    name: 'Greencastle Port',
  },
  IEGRN100: {
    country: 'Ireland - IE (007)',
    city: 'N/A',
    name: 'GREENORE PORT, GOVT BLDGS,',
  },
  IEGRN800: {
    country: 'Ireland - IE (007)',
    city: 'County Louth',
    name: 'Greenore Excise',
  },
  IEGWY100: {
    country: 'Ireland - IE (007)',
    city: 'N/A',
    name: 'GALWAY',
  },
  IEGWY200: {
    country: 'Ireland - IE (007)',
    city: 'GALWAY',
    name: 'ACE/ACR GALWAY',
  },
  IEGWY800: {
    country: 'Ireland - IE (007)',
    city: 'Galway',
    name: 'Galway County',
  },
  IEGWY801: {
    country: 'Ireland - IE (007)',
    city: 'Eyre square, Galway',
    name: 'Galway Roscommon',
  },
  IEHWP100: {
    country: 'Ireland - IE (007)',
    city: 'Howth, Ireland',
    name: 'HOWTH PORT',
  },
  IEKBS100: {
    country: 'Ireland - IE (007)',
    city: 'N/A',
    name: 'KILLYBEGS',
  },
  IEKIL800: {
    country: 'Ireland - IE (007)',
    city: 'MOUNT ST, DUBLIN 2',
    name: 'KILDARE DISTRICT-MOUNT ST',
  },
  IEKIL801: {
    country: 'Ireland - IE (007)',
    city: 'COUNTY KILDARE',
    name: 'KILDARE DISTRICT - NAAS',
  },
  IEKLK100: {
    country: 'Ireland - IE (007)',
    city: 'KILKENNY',
    name: 'KILKENNY',
  },
  IEKLK200: {
    country: 'Ireland - IE (007)',
    city: 'KILKENNY',
    name: 'ACE/ACR KILKENNY',
  },
  IEKLK800: {
    country: 'Ireland - IE (007)',
    city: 'Kilkenny',
    name: 'LCD (DTM) Kilkenny',
  },
  IEKMW900: {
    country: 'Ireland - IE (007)',
    city: 'DUBLIN 24',
    name: 'KMW ENFORCEMENT',
  },
  IEKRY400: {
    country: 'Ireland - IE (007)',
    city: 'CO. KERRY',
    name: 'KERRY AIRPORT',
  },
  IELIF300: {
    country: 'Ireland - IE (007)',
    city: 'Co. Donegal.',
    name: 'Lifford C & E & VRO,',
  },
  IELMK100: {
    country: 'Ireland - IE (007)',
    city: 'Limerick',
    name: 'Limerick Docks,',
  },
  IELMK200: {
    country: 'Ireland - IE (007)',
    city: 'LIMERICK',
    name: 'ACE/ACR LIMERICK',
  },
  IELMK800: {
    country: 'Ireland - IE (007)',
    city: 'LIMERICK',
    name: 'LCD (FOOD INDUSTRY) LIMERICK',
  },
  IELMK801: {
    country: 'Ireland - IE (007)',
    city: 'LIMERICK',
    name: 'LIMERICK EXCISE',
  },
  IELTR300: {
    country: 'Ireland - IE (007)',
    city: 'N/A',
    name: 'LETTERKENNY',
  },
  IEMON300: {
    country: 'Ireland - IE (007)',
    city: 'Co. Monaghan',
    name: 'Monaghan Station',
  },
  IENEN001: {
    country: 'Ireland - IE (007)',
    city: 'NENAGH, CO TIPPERARY',
    name: 'AEO',
  },
  IENEN002: {
    country: 'Ireland - IE (007)',
    city: 'Co Tipperary',
    name: 'Origin Unit',
  },
  IENEN003: {
    country: 'Ireland - IE (007)',
    city: 'Co Tipperary',
    name: 'Customs Decisions',
  },
  IENEN004: {
    country: 'Ireland - IE (007)',
    city: 'Co Tipperary',
    name: 'Classification Unit (BTI)',
  },
  IENEN101: {
    country: 'Ireland - IE (007)',
    city: 'Co Tipperary',
    name: 'ECS/NCTS/ICS Project Team',
  },
  IENRS100: {
    country: 'Ireland - IE (007)',
    city: 'Co. Wexford.',
    name: 'New Ross',
  },
  IEORK400: {
    country: 'Ireland - IE (007)',
    city: 'N/A',
    name: 'CORK AIRPORT',
  },
  IEORK800: {
    country: 'Ireland - IE (007)',
    city: 'BLACKPOOL, CORK',
    name: 'CORK NORTH WEST DISTRICT',
  },
  IEORK801: {
    country: 'Ireland - IE (007)',
    city: 'BLACKPOOL, CORK',
    name: 'LCD (DTMM) CORK',
  },
  IEORK802: {
    country: 'Ireland - IE (007)',
    city: 'BLACKPOOL, CORK',
    name: 'CORK SOUTH WEST DISTRICT',
  },
  IEORK803: {
    country: 'Ireland - IE (007)',
    city: 'BLACKPOOL, CORK',
    name: 'TRADE FACILITATION & CASH OFFICE',
  },
  IEORK804: {
    country: 'Ireland - IE (007)',
    city: 'BLACKPOOL, CORK',
    name: 'CORK EAST DISTRICT',
  },
  IEPOR200: {
    country: 'Ireland - IE (007)',
    city: 'Portarlington Co Offaly',
    name: 'ACE/ACR PORTARLINGTON',
  },
  IEPTL500: {
    country: 'Ireland - IE (007)',
    city: 'PORTLAOISE, CO LAOIS',
    name: 'PORTLAOISE MAIL CENTRE',
  },
  IERMP100: {
    country: 'Ireland - IE (007)',
    city: 'Co. Donegal',
    name: 'Rathmullen Port',
  },
  IERON800: {
    country: 'Ireland - IE (007)',
    city: 'ROSCOMMON',
    name: 'ROSCOMMON',
  },
  IEROS100: {
    country: 'Ireland - IE (007)',
    city: 'Co. Wexford',
    name: 'Rosslare Harbour',
  },
  IEROS200: {
    country: 'Ireland - IE (007)',
    city: 'Co Wexford',
    name: 'ACE/ACR Rosslare',
  },
  IERSP100: {
    country: 'Ireland - IE (007)',
    city: 'Co. Galway',
    name: 'Rosamhil Port',
  },
  IESLI100: {
    country: 'Ireland - IE (007)',
    city: 'Sligo',
    name: 'Sligo',
  },
  IESNN400: {
    country: 'Ireland - IE (007)',
    city: 'Co. Clare.',
    name: 'Shannon Airport',
  },
  IETHR800: {
    country: 'Ireland - IE (007)',
    city: 'THURLES, CO TIPPERARY',
    name: 'THURLES',
  },
  IETIV100: {
    country: 'Ireland - IE (007)',
    city: 'N/A',
    name: 'TIVOLI',
  },
  IETIV200: {
    country: 'Ireland - IE (007)',
    city: 'Cork',
    name: 'ACE/ACR Tvoli Cork',
  },
  IETLR301: {
    country: 'Ireland - IE (007)',
    city: 'Co. Donegal',
    name: 'Comlucht Iascaireactha Fanad Teo',
  },
  IETRA100: {
    country: 'Ireland - IE (007)',
    city: 'Co. Kerry',
    name: 'Tralee Government Buildings',
  },
  IEWAT100: {
    country: 'Ireland - IE (007)',
    city: 'Waterford',
    name: 'Waterford Government Buildings',
  },
  IEWAT101: {
    country: 'Ireland - IE (007)',
    city: 'Waterford',
    name: 'Belview, Waterford',
  },
  IEWAT200: {
    country: 'Ireland - IE (007)',
    city: 'waterford',
    name: 'ACE/ACR Waterford',
  },
  IEWEX800: {
    country: 'Ireland - IE (007)',
    city: 'WEXFORD',
    name: 'WEXFORD',
  },
  IEWEX900: {
    country: 'Ireland - IE (007)',
    city: 'Wexford',
    name: 'Wexford',
  },
  IEWHI100: {
    country: 'Ireland - IE (007)',
    city: 'Co. Cork.',
    name: 'Whitegate',
  },
  IEWIC100: {
    country: 'Ireland - IE (007)',
    city: 'Co. Wicklow.',
    name: 'Wicklow',
  },
  IEWSE100: {
    country: 'Ireland - IE (007)',
    city: 'Dublin',
    name: 'Ireland West East',
  },
  IEWSE200: {
    country: 'Ireland - IE (007)',
    city: 'Dublin',
    name: 'ACE/ACR Ireland West East',
  },
  IS000100: {
    country: 'Iceland - IS (024)',
    city: 'REYKJAVIK',
    name: 'Reykjavik toll',
  },
  IT014000: {
    country: 'Italy - IT (005)',
    city: 'FOGGIA',
    name: 'Direzione Ufficio delle Dogane di FOGGIA',
  },
  IT014100: {
    country: 'Italy - IT (005)',
    city: 'FOGGIA',
    name: 'Ufficio delle Dogane di FOGGIA',
  },
  IT014101: {
    country: 'Italy - IT (005)',
    city: 'Manfredonia (FG)',
    name: 'Sezione Operativa Territoriale di MANFREDONIA',
  },
  IT014199: {
    country: 'Italy - IT (005)',
    city: 'FOGGIA',
    name: "Area Gestione Tributi dell'UD di FOGGIA",
  },
  IT015000: {
    country: 'Italy - IT (005)',
    city: 'BRINDISI',
    name: 'Direzione Ufficio delle Dogane di BRINDISI',
  },
  IT015100: {
    country: 'Italy - IT (005)',
    city: 'BRINDISI',
    name: 'Ufficio delle Dogane di BRINDISI',
  },
  IT015101: {
    country: 'Italy - IT (005)',
    city: 'Casale (BR)',
    name: 'Sezione Operativa Territoriale di Aeroporto Casale',
  },
  IT015102: {
    country: 'Italy - IT (005)',
    city: 'Brindisi',
    name: 'Sezione Operativa Territoriale COSTA MORENA',
  },
  IT015199: {
    country: 'Italy - IT (005)',
    city: 'BRINDISI',
    name: "Area Gestione Tributi dell'UD di BRINDISI",
  },
  IT016000: {
    country: 'Italy - IT (005)',
    city: 'LECCE',
    name: 'Direzione Ufficio delle Dogane di LECCE',
  },
  IT016100: {
    country: 'Italy - IT (005)',
    city: 'LECCE',
    name: 'Ufficio delle Dogane di LECCE',
  },
  IT016101: {
    country: 'Italy - IT (005)',
    city: 'Gallipoli (LE)',
    name: 'Sezione Operativa Territoriale di GALLIPOLI',
  },
  IT016102: {
    country: 'Italy - IT (005)',
    city: 'Otranto (LE)',
    name: 'Sezione Operativa Territoriale di OTRANTO',
  },
  IT016199: {
    country: 'Italy - IT (005)',
    city: 'LECCE',
    name: "Area Gestione Tributi dell'UD di LECCE",
  },
  IT017000: {
    country: 'Italy - IT (005)',
    city: 'TARANTO',
    name: 'Direzione Ufficio delle Dogane di TARANTO',
  },
  IT017100: {
    country: 'Italy - IT (005)',
    city: 'TARANTO',
    name: 'Ufficio delle Dogane di TARANTO',
  },
  IT017199: {
    country: 'Italy - IT (005)',
    city: 'TARANTO',
    name: "Area Gestione Tributi dell'UD di TARANTO",
  },
  IT018000: {
    country: 'Italy - IT (005)',
    city: 'BARI',
    name: 'Direzione Ufficio delle Dogane di BARI',
  },
  IT018100: {
    country: 'Italy - IT (005)',
    city: 'BARI',
    name: 'Ufficio delle Dogane di BARI',
  },
  IT018101: {
    country: 'Italy - IT (005)',
    city: 'Palese (BA)',
    name: 'Sezione Operativa Territoriale di Aeroporto Bari - Palese',
  },
  IT018102: {
    country: 'Italy - IT (005)',
    city: 'Barletta (BA)',
    name: 'Sezione Operativa Territoriale di BAT - BARLETTA',
  },
  IT018104: {
    country: 'Italy - IT (005)',
    city: 'Molfetta (BA)',
    name: 'Sezione Operativa Territoriale di MOLFETTA',
  },
  IT018105: {
    country: 'Italy - IT (005)',
    city: 'Monopoli (BA)',
    name: 'Sezione Operativa Territoriale di MONOPOLI',
  },
  IT018106: {
    country: 'Italy - IT (005)',
    city: 'BARI',
    name: 'Sezione Operativa Territoriale Interporto Bari',
  },
  IT018199: {
    country: 'Italy - IT (005)',
    city: 'BARI',
    name: "Area Gestione Tributi dell'UD di BARI",
  },
  IT019000: {
    country: 'Italy - IT (005)',
    city: 'POTENZA',
    name: 'Direzione Ufficio delle Dogane di POTENZA',
  },
  IT019100: {
    country: 'Italy - IT (005)',
    city: 'POTENZA',
    name: 'Ufficio delle Dogane di POTENZA',
  },
  IT019101: {
    country: 'Italy - IT (005)',
    city: 'Ferrandina (MT)',
    name: 'Sezione Operativa Territoriale di FERRANDINA',
  },
  IT019102: {
    country: 'Italy - IT (005)',
    city: 'MATERA',
    name: 'Sezione Operativa Territoriale di MATERA',
  },
  IT019199: {
    country: 'Italy - IT (005)',
    city: 'POTENZA',
    name: "Area Gestione Tributi dell'UD di POTENZA",
  },
  IT022300: {
    country: 'Italy - IT (005)',
    city: 'REGGIO EMILIA',
    name: 'Ufficio delle Dogane di REGGIO EMILIA',
  },
  IT022399: {
    country: 'Italy - IT (005)',
    city: 'REGGIO EMILIA',
    name: "Area Gestione Tributi dell'UD di REGGIO EMILIA",
  },
  IT022999: {
    country: 'Italy - IT (005)',
    city: 'REGGIO EMILIA',
    name: 'Direzione Ufficio delle Dogane di REGGIO EMILIA',
  },
  IT024000: {
    country: 'Italy - IT (005)',
    city: "FORLI'",
    name: "Direzione Ufficio delle Dogane di FORLI'-CESENA",
  },
  IT024100: {
    country: 'Italy - IT (005)',
    city: "FORLI'",
    name: "Ufficio delle Dogane di FORLI'-CESENA",
  },
  IT024101: {
    country: 'Italy - IT (005)',
    city: 'Cesena (FO)',
    name: 'Sezione Operativa Territoriale di CESENA',
  },
  IT024102: {
    country: 'Italy - IT (005)',
    city: 'Forlì',
    name: 'Sezione Operativa Territoriale AEROPORTO RIDOLFI',
  },
  IT024199: {
    country: 'Italy - IT (005)',
    city: "FORLI'",
    name: "Area Gestione Tributi dell'UD di FORLI'-CESENA",
  },
  IT025000: {
    country: 'Italy - IT (005)',
    city: 'MODENA',
    name: 'Direzione Ufficio delle Dogane di MODENA',
  },
  IT025100: {
    country: 'Italy - IT (005)',
    city: 'CAMPOGALLIANO (MO)',
    name: 'Ufficio delle Dogane di MODENA',
  },
  IT025199: {
    country: 'Italy - IT (005)',
    city: 'CAMPOGALLIANO (MO)',
    name: "Area Gestione Tributi dell'UD di MODENA",
  },
  IT026000: {
    country: 'Italy - IT (005)',
    city: 'RIMINI',
    name: 'Direzione Ufficio delle Dogane di RIMINI',
  },
  IT026100: {
    country: 'Italy - IT (005)',
    city: 'RIMINI',
    name: 'Ufficio delle Dogane di RIMINI',
  },
  IT026101: {
    country: 'Italy - IT (005)',
    city: 'Rimini Miramare (RN)',
    name: 'Sezione Operativa Territoriale AEROPORTO MIRAMARE "F. FELLINI"',
  },
  IT026199: {
    country: 'Italy - IT (005)',
    city: 'RIMINI',
    name: "Area Gestione Tributi dell'UD di RIMINI",
  },
  IT027000: {
    country: 'Italy - IT (005)',
    city: 'FERRARA',
    name: 'Direzione Ufficio delle Dogane di FERRARA',
  },
  IT027100: {
    country: 'Italy - IT (005)',
    city: 'FERRARA',
    name: 'Ufficio delle Dogane di FERRARA',
  },
  IT027199: {
    country: 'Italy - IT (005)',
    city: 'FERRARA',
    name: "Area Gestione Tributi dell'UD di FERRARA",
  },
  IT028000: {
    country: 'Italy - IT (005)',
    city: 'FONTEVIVO (PR)',
    name: 'Direzione Ufficio delle Dogane di PARMA',
  },
  IT028100: {
    country: 'Italy - IT (005)',
    city: 'FONTEVIVO (PR)',
    name: 'Ufficio delle Dogane di PARMA',
  },
  IT028101: {
    country: 'Italy - IT (005)',
    city: 'Parma',
    name: 'AEROPORTO G. VERDI',
  },
  IT028199: {
    country: 'Italy - IT (005)',
    city: 'FONTEVIVO (PR)',
    name: "Area Gestione Tributi dell'UD di PARMA",
  },
  IT029000: {
    country: 'Italy - IT (005)',
    city: 'PIACENZA',
    name: 'Direzione Ufficio delle Dogane di PIACENZA',
  },
  IT029100: {
    country: 'Italy - IT (005)',
    city: 'PIACENZA',
    name: 'Ufficio delle Dogane di PIACENZA',
  },
  IT029199: {
    country: 'Italy - IT (005)',
    city: 'PIACENZA',
    name: "Area Gestione Tributi dell'UD di PIACENZA",
  },
  IT034000: {
    country: 'Italy - IT (005)',
    city: 'BOLZANO',
    name: 'Direzione Ufficio delle Dogane di BOLZANO',
  },
  IT034100: {
    country: 'Italy - IT (005)',
    city: 'BOLZANO',
    name: 'Ufficio delle Dogane di BOLZANO',
  },
  IT034101: {
    country: 'Italy - IT (005)',
    city: 'Valico di Resia (BZ)',
    name: 'Sezione Operativa Territoriale di RESIA',
  },
  IT034102: {
    country: 'Italy - IT (005)',
    city: 'Bolzano',
    name: 'Sezione Operativa Territoriale AEROPORTO SAN GIACOMO',
  },
  IT034103: {
    country: 'Italy - IT (005)',
    city: 'BOLZANO',
    name: 'Sezione Operativa Territoriale MM.GG. BOLZANO',
  },
  IT034104: {
    country: 'Italy - IT (005)',
    city: 'Bressanone (BZ)',
    name: 'Sezione Operativa Territoriale di BRESSANONE',
  },
  IT034105: {
    country: 'Italy - IT (005)',
    city: 'Brunico (BZ)',
    name: 'Sezione Operativa Territoriale di BRUNICO',
  },
  IT034106: {
    country: 'Italy - IT (005)',
    city: 'Vipiteno (BZ)',
    name: 'Sezione Operativa Territoriale di CAMPO DI TRENS',
  },
  IT034107: {
    country: 'Italy - IT (005)',
    city: 'Merano (BZ)',
    name: 'Sezione Operativa Territoriale di MERANO',
  },
  IT034108: {
    country: 'Italy - IT (005)',
    city: 'Tubre (BZ)',
    name: 'Sezione Operativa Territoriale di TUBRE',
  },
  IT034199: {
    country: 'Italy - IT (005)',
    city: 'BOLZANO',
    name: "Area Gestione Tributi dell'UD di BOLZANO",
  },
  IT035000: {
    country: 'Italy - IT (005)',
    city: 'TRENTO',
    name: 'Direzione Ufficio delle Dogane di TRENTO',
  },
  IT035100: {
    country: 'Italy - IT (005)',
    city: 'TRENTO',
    name: 'Ufficio delle Dogane di TRENTO',
  },
  IT035101: {
    country: 'Italy - IT (005)',
    city: 'Gardolo (TN)',
    name: 'Sezione Operativa Territoriale di RONCAFORT',
  },
  IT035199: {
    country: 'Italy - IT (005)',
    city: 'TRENTO',
    name: "Area Gestione Tributi dell'UD di TRENTO",
  },
  IT043000: {
    country: 'Italy - IT (005)',
    city: 'SASSARI',
    name: 'Direzione Ufficio delle Dogane di SASSARI',
  },
  IT043100: {
    country: 'Italy - IT (005)',
    city: 'SASSARI',
    name: 'Ufficio delle Dogane di SASSARI',
  },
  IT043101: {
    country: 'Italy - IT (005)',
    city: 'Porto Torres (SS)',
    name: 'Sezione Operativa Territoriale di PORTO TORRES',
  },
  IT043102: {
    country: 'Italy - IT (005)',
    city: 'Alghero (SS)',
    name: 'Sezione Operativa Territoriale di ALGHERO',
  },
  IT043103: {
    country: 'Italy - IT (005)',
    city: 'La Maddalena (SS)',
    name: 'Sezione Operativa Territoriale di LA MADDALENA - S. TERESA DI GALLURA',
  },
  IT043104: {
    country: 'Italy - IT (005)',
    city: 'Olbia (SS)',
    name: 'Sezione Operativa Territoriale di OLBIA',
  },
  IT043199: {
    country: 'Italy - IT (005)',
    city: 'SASSARI',
    name: "Area Gestione Tributi dell'UD di SASSARI",
  },
  IT044000: {
    country: 'Italy - IT (005)',
    city: 'CAGLIARI',
    name: 'Direzione Ufficio delle Dogane di CAGLIARI',
  },
  IT044100: {
    country: 'Italy - IT (005)',
    city: 'CAGLIARI',
    name: 'Ufficio delle Dogane di CAGLIARI',
  },
  IT044101: {
    country: 'Italy - IT (005)',
    city: 'Cagliari-Elmas (CA)',
    name: 'Sezione Operativa Territoriale di AEROPORTO ELMAS',
  },
  IT044103: {
    country: 'Italy - IT (005)',
    city: 'Oristano',
    name: 'Sezione Operativa Territoriale di ORISTANO',
  },
  IT044104: {
    country: 'Italy - IT (005)',
    city: 'Portovesme (CA)',
    name: 'Sezione Operativa Territoriale di PORTOVESME',
  },
  IT044106: {
    country: 'Italy - IT (005)',
    city: 'Cagliari',
    name: 'Sezione Operativa Territoriale di PORTO CANALE DI CAGLIARI',
  },
  IT044199: {
    country: 'Italy - IT (005)',
    city: 'CAGLIARI',
    name: "Area Gestione Tributi dell'UD di CAGLIARI",
  },
  IT051200: {
    country: 'Italy - IT (005)',
    city: 'AREZZO',
    name: 'Ufficio delle Dogane di AREZZO',
  },
  IT051201: {
    country: 'Italy - IT (005)',
    city: 'Siena',
    name: 'Sezione Operativa Territoriale di SIENA',
  },
  IT051299: {
    country: 'Italy - IT (005)',
    city: 'AREZZO',
    name: "Area Gestione Tributi dell'UD di AREZZO",
  },
  IT051999: {
    country: 'Italy - IT (005)',
    city: 'AREZZO',
    name: 'Direzione Ufficio delle Dogane di AREZZO',
  },
  IT054000: {
    country: 'Italy - IT (005)',
    city: 'PISA',
    name: 'Direzione Ufficio delle Dogane di PISA',
  },
  IT054100: {
    country: 'Italy - IT (005)',
    city: 'PISA',
    name: 'Ufficio delle Dogane di PISA',
  },
  IT054101: {
    country: 'Italy - IT (005)',
    city: 'Pisa',
    name: 'Sezione Operativa Territoriale AEROPORTO DI PISA',
  },
  IT054102: {
    country: 'Italy - IT (005)',
    city: 'Viareggio (LU)',
    name: 'Sezione Operativa Territoriale di VIAREGGIO',
  },
  IT054103: {
    country: 'Italy - IT (005)',
    city: 'Lucca',
    name: 'Sezione Operativa Territoriale di LUCCA',
  },
  IT054104: {
    country: 'Italy - IT (005)',
    city: 'Marina di Carrara (MS)',
    name: 'Sezione Operativa Territoriale di MARINA DI CARRARA',
  },
  IT054199: {
    country: 'Italy - IT (005)',
    city: 'PISA',
    name: "Area Gestione Tributi dell'UD di PISA",
  },
  IT055000: {
    country: 'Italy - IT (005)',
    city: 'LIVORNO',
    name: 'Direzione Ufficio delle Dogane di LIVORNO',
  },
  IT055100: {
    country: 'Italy - IT (005)',
    city: 'LIVORNO',
    name: 'Ufficio delle Dogane di LIVORNO',
  },
  IT055101: {
    country: 'Italy - IT (005)',
    city: 'Piombino (LI)',
    name: 'Sezione Operativa Territoriale di PIOMBINO',
  },
  IT055102: {
    country: 'Italy - IT (005)',
    city: 'Portoferraio (LI)',
    name: 'Sezione Operativa Territoriale di PORTOFERRAIO',
  },
  IT055103: {
    country: 'Italy - IT (005)',
    city: 'Grosseto',
    name: 'Sezione Operativa Territoriale di GROSSETO',
  },
  IT055199: {
    country: 'Italy - IT (005)',
    city: 'LIVORNO',
    name: "Area Gestione Tributi dell'UD di LIVORNO",
  },
  IT056000: {
    country: 'Italy - IT (005)',
    city: 'PRATO',
    name: "Direzione Ufficio dell'UD di PRATO E PISTOIA",
  },
  IT056100: {
    country: 'Italy - IT (005)',
    city: 'PRATO',
    name: 'Ufficio delle Dogane di PRATO E PISTOIA',
  },
  IT056199: {
    country: 'Italy - IT (005)',
    city: 'PRATO',
    name: "Area Gestione Tributi dell'UD di PRATO E PISTOIA",
  },
  IT057000: {
    country: 'Italy - IT (005)',
    city: 'FIRENZE',
    name: 'Direzione Ufficio delle Dogane di FIRENZE',
  },
  IT057100: {
    country: 'Italy - IT (005)',
    city: 'FIRENZE',
    name: 'Ufficio delle Dogane di FIRENZE',
  },
  IT057101: {
    country: 'Italy - IT (005)',
    city: 'Peretola (FI)',
    name: 'Sezione Operativa Territoriale AEROPORTO A. VESPUCCI',
  },
  IT057199: {
    country: 'Italy - IT (005)',
    city: 'FIRENZE',
    name: "Area Gestione Tributi dell'UD di FIRENZE",
  },
  IT058000: {
    country: 'Italy - IT (005)',
    city: 'Narni (TR)',
    name: 'Direzione Ufficio delle Dogane di TERNI',
  },
  IT058100: {
    country: 'Italy - IT (005)',
    city: 'Narni (TR)',
    name: 'Ufficio delle Dogane di TERNI',
  },
  IT058199: {
    country: 'Italy - IT (005)',
    city: 'NARNI (TR)',
    name: "Area Gestione Tributi dell'UD di TERNI",
  },
  IT066000: {
    country: 'Italy - IT (005)',
    city: 'SAVONA',
    name: 'Direzione Ufficio delle Dogane di SAVONA',
  },
  IT066100: {
    country: 'Italy - IT (005)',
    city: 'SAVONA',
    name: 'Ufficio delle Dogane di SAVONA',
  },
  IT066101: {
    country: 'Italy - IT (005)',
    city: 'Bergeggi (SV)',
    name: 'Sezione Operativa Territoriale di VADO LIGURE',
  },
  IT066199: {
    country: 'Italy - IT (005)',
    city: 'SAVONA',
    name: "Area Gestione Tributi dell'UD di SAVONA",
  },
  IT067000: {
    country: 'Italy - IT (005)',
    city: 'IMPERIA',
    name: 'Direzione Ufficio delle Dogane di IMPERIA',
  },
  IT067100: {
    country: 'Italy - IT (005)',
    city: 'IMPERIA',
    name: 'Ufficio delle Dogane di IMPERIA',
  },
  IT067101: {
    country: 'Italy - IT (005)',
    city: 'Sanremo (IM)',
    name: 'Sezione Operativa Territoriale di SANREMO',
  },
  IT067199: {
    country: 'Italy - IT (005)',
    city: 'IMPERIA',
    name: "Area Gestione Tributi dell'UD di IMPERIA",
  },
  IT068000: {
    country: 'Italy - IT (005)',
    city: 'LA SPEZIA',
    name: 'Direzione Ufficio delle Dogane di LA SPEZIA',
  },
  IT068100: {
    country: 'Italy - IT (005)',
    city: 'LA SPEZIA',
    name: 'Ufficio delle Dogane di LA SPEZIA',
  },
  IT068199: {
    country: 'Italy - IT (005)',
    city: 'LA SPEZIA',
    name: "Area Gestione Tributi dell'UD di LA SPEZIA",
  },
  IT079000: {
    country: 'Italy - IT (005)',
    city: 'MANTOVA',
    name: 'Direzione Ufficio delle Dogane di MANTOVA',
  },
  IT079100: {
    country: 'Italy - IT (005)',
    city: 'MANTOVA',
    name: 'Ufficio delle Dogane di MANTOVA',
  },
  IT079199: {
    country: 'Italy - IT (005)',
    city: 'MANTOVA',
    name: "Area Gestione Tributi dell'UD di MANTOVA",
  },
  IT084000: {
    country: 'Italy - IT (005)',
    city: 'SALERNO',
    name: 'Direzione Ufficio delle Dogane di SALERNO',
  },
  IT084100: {
    country: 'Italy - IT (005)',
    city: 'SALERNO',
    name: 'Ufficio delle Dogane di SALERNO',
  },
  IT084199: {
    country: 'Italy - IT (005)',
    city: 'SALERNO',
    name: "Area Gestione Tributi dell'UD di SALERNO",
  },
  IT085000: {
    country: 'Italy - IT (005)',
    city: 'CATANZARO',
    name: 'Direzione Ufficio delle Dogane di CATANZARO',
  },
  IT085100: {
    country: 'Italy - IT (005)',
    city: 'CATANZARO',
    name: 'Ufficio delle Dogane di CATANZARO',
  },
  IT085101: {
    country: 'Italy - IT (005)',
    city: 'Lamezia Terme (CZ)',
    name: 'Sezione Operativa Territoriale di AEROPORTO DI LAMEZIA TERME',
  },
  IT085102: {
    country: 'Italy - IT (005)',
    city: 'Cosenza',
    name: 'Sezione Operativa Territoriale di COSENZA',
  },
  IT085103: {
    country: 'Italy - IT (005)',
    city: 'Crotone',
    name: 'Sezione Operativa Territoriale di CROTONE',
  },
  IT085104: {
    country: 'Italy - IT (005)',
    city: 'Corigliano Calabro (CS)',
    name: 'Sezione Operativa Territoriale di CORIGLIANO CALABRO',
  },
  IT085199: {
    country: 'Italy - IT (005)',
    city: 'CATANZARO',
    name: "Area Gestione Tributi dell'UD di CATANZARO",
  },
  IT086000: {
    country: 'Italy - IT (005)',
    city: 'GIOIA TAURO',
    name: 'Direzione Ufficio delle Dogane di GIOIA TAURO',
  },
  IT086100: {
    country: 'Italy - IT (005)',
    city: 'GIOIA TAURO',
    name: 'Ufficio delle Dogane di GIOIA TAURO',
  },
  IT086199: {
    country: 'Italy - IT (005)',
    city: 'GIOIA TAURO',
    name: "Area Gestione Tributi dell'UD di GIOIA TAURO",
  },
  IT087000: {
    country: 'Italy - IT (005)',
    city: 'REGGIO CALABRIA',
    name: 'Direzione Ufficio delle Dogane di REGGIO CALABRIA',
  },
  IT087100: {
    country: 'Italy - IT (005)',
    city: 'REGGIO CALABRIA',
    name: 'Ufficio delle Dogane di REGGIO CALABRIA',
  },
  IT087101: {
    country: 'Italy - IT (005)',
    city: 'Reggio Calabria',
    name: 'Sezione Operativa Territoriale AEROPORTO DELLO STRETTO',
  },
  IT087102: {
    country: 'Italy - IT (005)',
    city: 'Vibo Valentia',
    name: 'Sezione Operativa Territoriale di VIBO VALENTIA',
  },
  IT087199: {
    country: 'Italy - IT (005)',
    city: 'REGGIO CALABRIA',
    name: "Area Gestione Tributi dell'UD di REGGIO CALABRIA",
  },
  IT088000: {
    country: 'Italy - IT (005)',
    city: 'MADDALONI (CE)',
    name: 'Direzione Ufficio delle Dogane di CASERTA',
  },
  IT088100: {
    country: 'Italy - IT (005)',
    city: 'MADDALONI (CE)',
    name: 'Ufficio delle Dogane di CASERTA',
  },
  IT088199: {
    country: 'Italy - IT (005)',
    city: 'MADDALONI (CE)',
    name: "Area Gestione Tributi dell'UD di CASERTA",
  },
  IT089000: {
    country: 'Italy - IT (005)',
    city: 'BENEVENTO',
    name: 'Direzione Ufficio delle Dogane di BENEVENTO',
  },
  IT089100: {
    country: 'Italy - IT (005)',
    city: 'BENEVENTO',
    name: 'Ufficio delle Dogane di BENEVENTO',
  },
  IT089199: {
    country: 'Italy - IT (005)',
    city: 'BENEVENTO',
    name: "Area Gestione Tributi dell'UD di BENEVENTO",
  },
  IT096000: {
    country: 'Italy - IT (005)',
    city: 'PORTO EMPEDOCLE (AG)',
    name: 'Direzione Ufficio delle Dogane di PORTO EMPEDOCLE',
  },
  IT096100: {
    country: 'Italy - IT (005)',
    city: 'PORTO EMPEDOCLE (AG)',
    name: 'Ufficio delle Dogane di PORTO EMPEDOCLE',
  },
  IT096101: {
    country: 'Italy - IT (005)',
    city: 'Gela (CL)',
    name: 'Sezione Operativa Territoriale di GELA',
  },
  IT096102: {
    country: 'Italy - IT (005)',
    city: 'Sciacca (AG)',
    name: 'Sezione Operativa Territoriale di SCIACCA',
  },
  IT096103: {
    country: 'Italy - IT (005)',
    city: 'Licata (AG)',
    name: 'Sezione Operativa Territoriale di LICATA',
  },
  IT096199: {
    country: 'Italy - IT (005)',
    city: 'PORTO EMPEDOCLE (AG)',
    name: "Area Gestione Tributi dell'UD di PORTO EMPEDOCLE",
  },
  IT097000: {
    country: 'Italy - IT (005)',
    city: 'TRAPANI',
    name: 'Direzione Ufficio delle Dogane di TRAPANI',
  },
  IT097100: {
    country: 'Italy - IT (005)',
    city: 'TRAPANI',
    name: 'Ufficio delle Dogane di TRAPANI',
  },
  IT097101: {
    country: 'Italy - IT (005)',
    city: 'Marsala (TP)',
    name: 'Sezione Operativa Territoriale di MARSALA',
  },
  IT097102: {
    country: 'Italy - IT (005)',
    city: 'Mazara del Vallo (TP)',
    name: 'Sezione Operativa Territoriale di MAZARA DEL VALLO',
  },
  IT097103: {
    country: 'Italy - IT (005)',
    city: 'Castellammare del Golfo (TP)',
    name: 'Sezione Operativa Territoriale di CASTELLAMMARE DEL GOLFO',
  },
  IT097199: {
    country: 'Italy - IT (005)',
    city: 'TRAPANI',
    name: "Area Gestione Tributi dell'UD di TRAPANI",
  },
  IT098000: {
    country: 'Italy - IT (005)',
    city: 'MESSINA',
    name: 'Direzione Ufficio delle Dogane di MESSINA',
  },
  IT098100: {
    country: 'Italy - IT (005)',
    city: 'MESSINA',
    name: 'Ufficio delle Dogane di MESSINA',
  },
  IT098101: {
    country: 'Italy - IT (005)',
    city: 'Milazzo (ME)',
    name: 'Sezione Operativa Territoriale di MILAZZO',
  },
  IT098102: {
    country: 'Italy - IT (005)',
    city: 'Giardini (ME)',
    name: 'Sezione Operativa Territoriale di GIARDINI NAXOS',
  },
  IT098103: {
    country: 'Italy - IT (005)',
    city: 'Lipari (ME)',
    name: 'Sezione Operativa Territoriale di LIPARI',
  },
  IT098104: {
    country: 'Italy - IT (005)',
    city: "Sant'Agata di Militello (ME)",
    name: "Sezione Operativa Territoriale di SANT'AGATA DI MILITELLO",
  },
  IT098199: {
    country: 'Italy - IT (005)',
    city: 'MESSINA',
    name: "Area Gestione Tributi dell'UD di MESSINA",
  },
  IT099000: {
    country: 'Italy - IT (005)',
    city: 'SIRACUSA',
    name: 'Direzione Ufficio delle Dogane di SIRACUSA',
  },
  IT099100: {
    country: 'Italy - IT (005)',
    city: 'SIRACUSA',
    name: 'Ufficio delle Dogane di SIRACUSA',
  },
  IT099101: {
    country: 'Italy - IT (005)',
    city: 'Augusta (SR)',
    name: 'Sezione Operativa Territoriale di AUGUSTA',
  },
  IT099102: {
    country: 'Italy - IT (005)',
    city: 'Pozzallo (RG)',
    name: 'Sezione Operativa Territoriale di POZZALLO',
  },
  IT099103: {
    country: 'Italy - IT (005)',
    city: 'Ragusa',
    name: 'Sezione Operativa Territoriale di RAGUSA',
  },
  IT099199: {
    country: 'Italy - IT (005)',
    city: 'SIRACUSA',
    name: "Area Gestione Tributi dell'UD di SIRACUSA",
  },
  IT105100: {
    country: 'Italy - IT (005)',
    city: 'GAETA (LT)',
    name: 'Ufficio delle Dogane di GAETA',
  },
  IT105101: {
    country: 'Italy - IT (005)',
    city: 'Aprilia (LT)',
    name: 'Sezione Operativa Territoriale di APRILIA',
  },
  IT105104: {
    country: 'Italy - IT (005)',
    city: 'Latina Scalo',
    name: 'Sezione Operativa Territoriale di LATINA',
  },
  IT105199: {
    country: 'Italy - IT (005)',
    city: 'GAETA (LT)',
    name: "Area Gestione Tributi dell'UD di GAETA",
  },
  IT105999: {
    country: 'Italy - IT (005)',
    city: 'GAETA (LT)',
    name: 'Direzione Ufficio delle Dogane di GAETA',
  },
  IT107000: {
    country: 'Italy - IT (005)',
    city: 'S. ANDREA DELLE FRATTE (PG)',
    name: 'Direzione Ufficio delle Dogane di PERUGIA',
  },
  IT107100: {
    country: 'Italy - IT (005)',
    city: 'S. ANDREA DELLE FRATTE (PG)',
    name: 'Ufficio delle Dogane di PERUGIA',
  },
  IT107199: {
    country: 'Italy - IT (005)',
    city: 'S. ANDREA DELLE FRATTE (PG)',
    name: "Area Gestione Tributi dell'UD di PERUGIA",
  },
  IT116000: {
    country: 'Italy - IT (005)',
    city: 'BIELLA',
    name: 'Direzione Ufficio delle Dogane di BIELLA',
  },
  IT116100: {
    country: 'Italy - IT (005)',
    city: 'BIELLA',
    name: 'Ufficio delle Dogane di BIELLA',
  },
  IT116101: {
    country: 'Italy - IT (005)',
    city: 'Biella',
    name: 'Sezione Operativa Territoriale MM.GG. Saima Avandero S.p.A.',
  },
  IT116199: {
    country: 'Italy - IT (005)',
    city: 'BIELLA',
    name: "Area Gestione Tributi dell'UD di BIELLA",
  },
  IT117000: {
    country: 'Italy - IT (005)',
    city: 'VERCELLI',
    name: 'Direzione Ufficio delle Dogane di VERCELLI',
  },
  IT117100: {
    country: 'Italy - IT (005)',
    city: 'VERCELLI',
    name: 'Ufficio delle Dogane di VERCELLI',
  },
  IT117102: {
    country: 'Italy - IT (005)',
    city: 'Vercelli',
    name: 'Sezione Operativa Territoriale MM.GG. dei Trafori S.p.A.',
  },
  IT117199: {
    country: 'Italy - IT (005)',
    city: 'VERCELLI',
    name: "Area Gestione Tributi dell'UD di VERCELLI",
  },
  IT118000: {
    country: 'Italy - IT (005)',
    city: 'POLLEIN (AO)',
    name: 'Direzione Ufficio delle Dogane di AOSTA',
  },
  IT118100: {
    country: 'Italy - IT (005)',
    city: 'POLLEIN (AO)',
    name: 'Ufficio delle Dogane di AOSTA',
  },
  IT118101: {
    country: 'Italy - IT (005)',
    city: 'Saint Rhemy (AO)',
    name: 'Sezione Operativa Territoriale TRAFORO GRAN SAN BERNARDO',
  },
  IT118199: {
    country: 'Italy - IT (005)',
    city: 'POLLEIN (AO)',
    name: "AOSTA Area Gestione Tributi dell'UD di",
  },
  IT119000: {
    country: 'Italy - IT (005)',
    city: 'BEURA CARDEZZA (VB)',
    name: 'Direzione Ufficio delle Dogane di VERBANO-CUSIO-OSSOLA',
  },
  IT119100: {
    country: 'Italy - IT (005)',
    city: 'BEURA CARDEZZA (VB)',
    name: 'Ufficio delle Dogane di VERBANO-CUSIO-OSSOLA',
  },
  IT119101: {
    country: 'Italy - IT (005)',
    city: 'Cannobio (VB)',
    name: 'Sezione Operativa Territoriale di PIAGGIO VALMARA',
  },
  IT119102: {
    country: 'Italy - IT (005)',
    city: 'Iselle Trasquera (VB)',
    name: 'Sezione Operativa Territoriale di ISELLE TRASQUERA',
  },
  IT119199: {
    country: 'Italy - IT (005)',
    city: 'BEURA CARDEZZA (VB)',
    name: "Area Gestione Tributi dell'UD di VERBANO-CUSIO-OSSOLA",
  },
  IT126000: {
    country: 'Italy - IT (005)',
    city: 'PORDENONE',
    name: 'Direzione Ufficio delle Dogane di PORDENONE',
  },
  IT126100: {
    country: 'Italy - IT (005)',
    city: 'PORDENONE',
    name: 'Ufficio delle Dogane di PORDENONE',
  },
  IT126199: {
    country: 'Italy - IT (005)',
    city: 'PORDENONE',
    name: "Area Gestione Tributi dell'UD di PORDENONE",
  },
  IT127000: {
    country: 'Italy - IT (005)',
    city: 'GORIZIA',
    name: 'Direzione Ufficio delle Dogane di GORIZIA',
  },
  IT127100: {
    country: 'Italy - IT (005)',
    city: 'GORIZIA',
    name: 'Ufficio delle Dogane di GORIZIA',
  },
  IT127101: {
    country: 'Italy - IT (005)',
    city: 'Monfalcone (GO)',
    name: 'Sezione Operativa Territoriale di MONFALCONE',
  },
  IT127102: {
    country: 'Italy - IT (005)',
    city: 'Ronchi dei Legionari (GO)',
    name: 'Sezione Operativa Territoriale RONCHI D. LEGIONARI - AER. GIULIANO',
  },
  IT127199: {
    country: 'Italy - IT (005)',
    city: 'GORIZIA',
    name: "Area Gestione Tributi dell'UD di GORIZIA",
  },
  IT128000: {
    country: 'Italy - IT (005)',
    city: 'UDINE',
    name: 'Direzione Ufficio delle Dogane di UDINE',
  },
  IT128100: {
    country: 'Italy - IT (005)',
    city: 'UDINE',
    name: 'Ufficio delle Dogane di UDINE',
  },
  IT128101: {
    country: 'Italy - IT (005)',
    city: 'Pontebba (UD)',
    name: 'Sezione Operativa Territoriale di PONTEBBA',
  },
  IT128102: {
    country: 'Italy - IT (005)',
    city: 'San Giorgio di Nogaro (UD)',
    name: 'Sezione Operativa Territoriale di PORTO NOGARO',
  },
  IT128103: {
    country: 'Italy - IT (005)',
    city: 'Udine',
    name: 'Sezione Operativa Territoriale Z.A.U. (Zona Annonaria Udinese)',
  },
  IT128199: {
    country: 'Italy - IT (005)',
    city: 'UDINE',
    name: "Area Gestione Tributi dell'UD di UDINE",
  },
  IT129000: {
    country: 'Italy - IT (005)',
    city: 'TRIESTE',
    name: 'Direzione Ufficio delle Dogane di TRIESTE',
  },
  IT129100: {
    country: 'Italy - IT (005)',
    city: 'TRIESTE',
    name: 'Ufficio delle Dogane di TRIESTE',
  },
  IT129102: {
    country: 'Italy - IT (005)',
    city: 'Trieste',
    name: 'Sezione Operativa Territoriale PORTO INDUSTRIALE',
  },
  IT129103: {
    country: 'Italy - IT (005)',
    city: 'Trieste',
    name: 'Sezione Operativa Territoriale PUNTO FRANCO NUOVO',
  },
  IT129104: {
    country: 'Italy - IT (005)',
    city: 'Trieste',
    name: 'Sezione Operativa Territoriale PUNTO FRANCO VECCHIO',
  },
  IT129199: {
    country: 'Italy - IT (005)',
    city: 'TRIESTE',
    name: "Area Gestione Tributi dell'UD di TRIESTE",
  },
  IT134000: {
    country: 'Italy - IT (005)',
    city: 'TREVISO',
    name: 'Direzione Ufficio delle Dogane di TREVISO',
  },
  IT134100: {
    country: 'Italy - IT (005)',
    city: 'TREVISO',
    name: 'Ufficio delle Dogane di TREVISO',
  },
  IT134101: {
    country: 'Italy - IT (005)',
    city: 'Treviso',
    name: 'Sezione Operativa Territoriale AEROPORTO S. GIUSEPPE',
  },
  IT134102: {
    country: 'Italy - IT (005)',
    city: 'Sedico (BL)',
    name: 'Sezione Operativa Territoriale di SEDICO',
  },
  IT134199: {
    country: 'Italy - IT (005)',
    city: 'TREVISO',
    name: "Area Gestione Tributi dell'UD di TREVISO",
  },
  IT135000: {
    country: 'Italy - IT (005)',
    city: 'VICENZA',
    name: 'Direzione Ufficio delle Dogane di VICENZA',
  },
  IT135100: {
    country: 'Italy - IT (005)',
    city: 'VICENZA',
    name: 'Ufficio delle Dogane di VICENZA',
  },
  IT135199: {
    country: 'Italy - IT (005)',
    city: 'VICENZA',
    name: "Area Gestione Tributi dell'UD di VICENZA",
  },
  IT136000: {
    country: 'Italy - IT (005)',
    city: 'VERONA',
    name: 'Direzione Ufficio delle Dogane di VERONA',
  },
  IT136100: {
    country: 'Italy - IT (005)',
    city: 'VERONA',
    name: 'Ufficio delle Dogane di VERONA',
  },
  IT136101: {
    country: 'Italy - IT (005)',
    city: 'Sommacampagna (VR)',
    name: 'Sezione Operativa Territoriale AEROPORTO VALERIO CATULLO',
  },
  IT136102: {
    country: 'Italy - IT (005)',
    city: 'Verona',
    name: 'Sezione Operativa Territoriale MAGAZZINI GENERALI',
  },
  IT136199: {
    country: 'Italy - IT (005)',
    city: 'VERONA',
    name: "Area Gestione Tributi dell'UD di VERONA",
  },
  IT137000: {
    country: 'Italy - IT (005)',
    city: 'MARGHERA (VE)',
    name: 'Direzione Ufficio delle Dogane di VENEZIA',
  },
  IT137100: {
    country: 'Italy - IT (005)',
    city: 'MARGHERA (VE)',
    name: 'Ufficio delle Dogane di VENEZIA',
  },
  IT137101: {
    country: 'Italy - IT (005)',
    city: 'Tessera (VE)',
    name: 'Sezione Operativa Territoriale AEROPORTO "MARCO POLO" DI TESSERA',
  },
  IT137102: {
    country: 'Italy - IT (005)',
    city: 'Chioggia (VE)',
    name: 'Sezione Operativa Territoriale di CHIOGGIA',
  },
  IT137103: {
    country: 'Italy - IT (005)',
    city: 'Marghera (VE)',
    name: 'Sezione Operativa Territoriale INTERPORTO',
  },
  IT137104: {
    country: 'Italy - IT (005)',
    city: 'Frazione Summaga - Portogruaro (VE)',
    name: 'Sezione Operativa Territoriale di PORTOGRUARO',
  },
  IT137105: {
    country: 'Italy - IT (005)',
    city: 'Venezia',
    name: 'Sezione Operativa Territoriale MARITTIMA',
  },
  IT137199: {
    country: 'Italy - IT (005)',
    city: 'MARGHERA (VE)',
    name: "Area Gestione Tributi dell'UD di VENEZIA",
  },
  IT138000: {
    country: 'Italy - IT (005)',
    city: 'PADOVA',
    name: 'Direzione Ufficio delle Dogane di PADOVA',
  },
  IT138100: {
    country: 'Italy - IT (005)',
    city: 'PADOVA',
    name: 'Ufficio delle Dogane di PADOVA',
  },
  IT138101: {
    country: 'Italy - IT (005)',
    city: 'Frazione Borsea (RO)',
    name: 'Sezione Operativa Territoriale di ROVIGO',
  },
  IT138199: {
    country: 'Italy - IT (005)',
    city: 'PADOVA',
    name: "Area Gestione Tributi dell'UD di PADOVA",
  },
  IT171000: {
    country: 'Italy - IT (005)',
    city: 'Roccella Jonica',
    name: 'Direzione Ufficio delle Dogane di ROCCELLA JONICA',
  },
  IT171100: {
    country: 'Italy - IT (005)',
    city: 'Roccella Jonica',
    name: 'Ufficio delle Dogane di ROCCELLA JONICA',
  },
  IT171199: {
    country: 'Italy - IT (005)',
    city: 'Roccella Jonica',
    name: "Area Gestione Tributi dell'UD di ROCCELLA JONICA",
  },
  IT181000: {
    country: 'Italy - IT (005)',
    city: 'AVELLINO (AV)',
    name: 'Direzione Ufficio delle Dogane di AVELLINO',
  },
  IT181100: {
    country: 'Italy - IT (005)',
    city: 'AVELLINO (AV)',
    name: 'Ufficio delle Dogane di AVELLLINO',
  },
  IT181199: {
    country: 'Italy - IT (005)',
    city: 'AVELLINO (AV)',
    name: "Area gestione Tributi dell'UD di AVELLINO",
  },
  IT221000: {
    country: 'Italy - IT (005)',
    city: 'RAVENNA',
    name: 'Direzione Ufficio delle Dogane di RAVENNA',
  },
  IT221100: {
    country: 'Italy - IT (005)',
    city: 'RAVENNA',
    name: 'Ufficio delle Dogane di RAVENNA',
  },
  IT221199: {
    country: 'Italy - IT (005)',
    city: 'RAVENNA',
    name: "Area Gestione Tributi dell'UD di RAVENNA",
  },
  IT222000: {
    country: 'Italy - IT (005)',
    city: 'BOLOGNA',
    name: 'Direzione Ufficio delle Dogane di BOLOGNA',
  },
  IT222100: {
    country: 'Italy - IT (005)',
    city: 'BOLOGNA',
    name: 'Ufficio delle Dogane di BOLOGNA',
  },
  IT222101: {
    country: 'Italy - IT (005)',
    city: 'Borgo Panigale (BO)',
    name: 'Sezione Operativa Territoriale AEROPORTO "G. MARCONI"',
  },
  IT222102: {
    country: 'Italy - IT (005)',
    city: 'Bentivoglio (BO)',
    name: 'Sezione Operativa Territoriale INTERPORTO',
  },
  IT222199: {
    country: 'Italy - IT (005)',
    city: 'Bentivoglio (BO)',
    name: "Area Gestione Tributi dell'UD di BOLOGNA",
  },
  IT261000: {
    country: 'Italy - IT (005)',
    city: 'GENOVA',
    name: 'Direzione Ufficio delle Dogane di GENOVA 1',
  },
  IT261101: {
    country: 'Italy - IT (005)',
    city: 'Genova',
    name: 'Sezione Operativa Territoriale PASSO NUOVO',
  },
  IT261199: {
    country: 'Italy - IT (005)',
    city: 'GENOVA',
    name: "Area Gestione Tributi dell'UD di GENOVA 1",
  },
  IT262000: {
    country: 'Italy - IT (005)',
    city: 'GENOVA',
    name: 'Direzione Ufficio delle Dogane di GENOVA 2',
  },
  IT262101: {
    country: 'Italy - IT (005)',
    city: 'Genova',
    name: 'Sezione Operativa Territoriale AEROPORTO',
  },
  IT262102: {
    country: 'Italy - IT (005)',
    city: 'Genova',
    name: "Sezione Operativa Territoriale PRA'",
  },
  IT262199: {
    country: 'Italy - IT (005)',
    city: 'GENOVA',
    name: "Area Gestione Tributi dell'UD di GENOVA 2",
  },
  IT263000: {
    country: 'Italy - IT (005)',
    city: 'TORTONA (AL)',
    name: 'Direzione Ufficio delle Dogane di RIVALTA SCRIVIA - RETROPORTO GENOVA',
  },
  IT263100: {
    country: 'Italy - IT (005)',
    city: 'TORTONA (AL)',
    name: 'Ufficio delle Dogane di RIVALTA SCRIVIA - RETROPORTO GENOVA',
  },
  IT263101: {
    country: 'Italy - IT (005)',
    city: 'Pozzolo Formigaro (AL)',
    name: 'Sezione Operativa Territoriale Pozzolo Formigaro',
  },
  IT263199: {
    country: 'Italy - IT (005)',
    city: 'TORTONA (AL)',
    name: "Area Gestione Tributi dell'UD di RIVALTA SCRIVIA - RETROPORTO GENOVA",
  },
  IT271000: {
    country: 'Italy - IT (005)',
    city: 'PAVIA',
    name: 'Direzione Ufficio delle Dogane di PAVIA',
  },
  IT271100: {
    country: 'Italy - IT (005)',
    city: 'PAVIA',
    name: 'Ufficio delle Dogane di PAVIA',
  },
  IT271101: {
    country: 'Italy - IT (005)',
    city: 'Sannazzaro dé Burgondi (PV)',
    name: "Sezione Operativa Territoriale di SANNAZZARO DE' BURGONDI",
  },
  IT271102: {
    country: 'Italy - IT (005)',
    city: 'Vigevano (PV)',
    name: 'Sezione Operativa Territoriale di VIGEVANO',
  },
  IT271103: {
    country: 'Italy - IT (005)',
    city: 'Voghera (PV)',
    name: 'Sezione Operativa Territoriale di VOGHERA',
  },
  IT271199: {
    country: 'Italy - IT (005)',
    city: 'PAVIA',
    name: "Area Gestione Tributi dell'UD di PAVIA",
  },
  IT272000: {
    country: 'Italy - IT (005)',
    city: 'TIRANO (SO)',
    name: 'Direzione Ufficio delle Dogane di TIRANO',
  },
  IT272100: {
    country: 'Italy - IT (005)',
    city: 'TIRANO (SO)',
    name: 'Ufficio delle Dogane di TIRANO',
  },
  IT272101: {
    country: 'Italy - IT (005)',
    city: 'LIVIGNO (SO)',
    name: 'Sezione Operativa Territoriale di PASSO DEL FOSCAGNO',
  },
  IT272103: {
    country: 'Italy - IT (005)',
    city: 'VILLA DI CHIAVENNA (SO)',
    name: 'Sezione Operativa Territoriale di VILLA DI CHIAVENNA',
  },
  IT272199: {
    country: 'Italy - IT (005)',
    city: 'TIRANO (SO)',
    name: "Area Gestione Tributi dell'UD di TIRANO",
  },
  IT273000: {
    country: 'Italy - IT (005)',
    city: 'VARESE',
    name: 'Direzione Ufficio delle Dogane di VARESE',
  },
  IT273100: {
    country: 'Italy - IT (005)',
    city: 'VARESE',
    name: 'Ufficio delle Dogane di VARESE',
  },
  IT273101: {
    country: 'Italy - IT (005)',
    city: 'BUSTO ARSIZIO (VA)',
    name: 'Sezione Operativa Territoriale di BUSTO ARSIZIO',
  },
  IT273102: {
    country: 'Italy - IT (005)',
    city: 'CANTELLO (VA)',
    name: 'Sezione Operativa Territoriale di GAGGIOLO',
  },
  IT273103: {
    country: 'Italy - IT (005)',
    city: 'ISPRA (VA)',
    name: 'Sezione Operativa Territoriale di ISPRA',
  },
  IT273104: {
    country: 'Italy - IT (005)',
    city: 'LUINO (VA)',
    name: 'Sezione Operativa Territoriale di LUINO',
  },
  IT273105: {
    country: 'Italy - IT (005)',
    city: 'PONTE TRESA (VA)',
    name: 'Sezione Operativa Territoriale di PONTE TRESA',
  },
  IT273199: {
    country: 'Italy - IT (005)',
    city: 'VARESE',
    name: "Area Gestione Tributi dell'UD di VARESE",
  },
  IT274000: {
    country: 'Italy - IT (005)',
    city: 'BRESCIA',
    name: 'Direzione Ufficio delle Dogane di BRESCIA',
  },
  IT274100: {
    country: 'Italy - IT (005)',
    city: 'BRESCIA',
    name: 'Ufficio delle Dogane di BRESCIA',
  },
  IT274101: {
    country: 'Italy - IT (005)',
    city: 'CREMONA',
    name: 'Sezione Operativa Territoriale di CREMONA',
  },
  IT274102: {
    country: 'Italy - IT (005)',
    city: 'Montichiari (BS)',
    name: 'Sezione Operativa Territoriale di MONTICHIARI',
  },
  IT274199: {
    country: 'Italy - IT (005)',
    city: 'BRESCIA',
    name: "Area Gestione Tributi dell'UD di BRESCIA",
  },
  IT275000: {
    country: 'Italy - IT (005)',
    city: 'COMO',
    name: 'Direzione Ufficio delle Dogane di COMO',
  },
  IT275100: {
    country: 'Italy - IT (005)',
    city: 'COMO',
    name: 'Ufficio delle Dogane di COMO',
  },
  IT275101: {
    country: 'Italy - IT (005)',
    city: 'Chiasso (CH)',
    name: 'Sezione Operativa Territoriale di CHIASSO',
  },
  IT275102: {
    country: 'Italy - IT (005)',
    city: 'LECCO',
    name: 'Sezione Operativa Territoriale di LECCO',
  },
  IT275103: {
    country: 'Italy - IT (005)',
    city: 'Montano Lucino (CO)',
    name: 'Sezione Operativa Territoriale di MONTANO LUCINO',
  },
  IT275104: {
    country: 'Italy - IT (005)',
    city: 'Oria Valsolda (CO)',
    name: 'Sezione Operativa Territoriale di ORIA VALSOLDA',
  },
  IT275105: {
    country: 'Italy - IT (005)',
    city: 'Ponte Chiasso (CO)',
    name: 'Sezione Operativa Territoriale di PONTE CHIASSO',
  },
  IT275106: {
    country: 'Italy - IT (005)',
    city: "Campione d'Italia (CO)",
    name: "Sezione Operativa Territoriale di CAMPIONE D'ITALIA",
  },
  IT275199: {
    country: 'Italy - IT (005)',
    city: 'COMO',
    name: "Area Gestione Tributi dell'UD di COMO",
  },
  IT276000: {
    country: 'Italy - IT (005)',
    city: 'BERGAMO',
    name: 'Direzione Ufficio delle Dogane di BERGAMO',
  },
  IT276100: {
    country: 'Italy - IT (005)',
    city: 'BERGAMO',
    name: 'Ufficio delle Dogane di BERGAMO',
  },
  IT276101: {
    country: 'Italy - IT (005)',
    city: 'Orio al Serio (BG)',
    name: 'Sezione Operativa Territoriale di ORIO AL SERIO',
  },
  IT276199: {
    country: 'Italy - IT (005)',
    city: 'BERGAMO',
    name: "Area Gestione Tributi dell'UD di BERGAMO",
  },
  IT277000: {
    country: 'Italy - IT (005)',
    city: 'MILANO',
    name: 'Direzione Ufficio delle Dogane di MILANO 1',
  },
  IT277100: {
    country: 'Italy - IT (005)',
    city: 'MILANO',
    name: 'Ufficio delle Dogane di MILANO 1',
  },
  IT277102: {
    country: 'Italy - IT (005)',
    city: 'Milano',
    name: 'Sezione Operativa Territoriale di O.N.T.',
  },
  IT277199: {
    country: 'Italy - IT (005)',
    city: 'MILANO',
    name: "Area Gestione Tributi dell'UD di MILANO 1",
  },
  IT278000: {
    country: 'Italy - IT (005)',
    city: 'MILANO',
    name: 'Direzione Ufficio delle Dogane di MILANO 2',
  },
  IT278100: {
    country: 'Italy - IT (005)',
    city: 'MILANO',
    name: 'Ufficio delle Dogane di MILANO 2',
  },
  IT278101: {
    country: 'Italy - IT (005)',
    city: 'Concorezzo (MI)',
    name: 'Sezione Operativa Territoriale di CONCOREZZO',
  },
  IT278105: {
    country: 'Italy - IT (005)',
    city: 'Rho (MI)',
    name: 'Sezione Operativa Territoriale di RHO',
  },
  IT278106: {
    country: 'Italy - IT (005)',
    city: 'Turbigo (MI)',
    name: 'Sezione Operativa Territoriale di TURBIGO',
  },
  IT278107: {
    country: 'Italy - IT (005)',
    city: 'Vittuone (MI)',
    name: 'Sezione Operativa Territoriale di VITTUONE',
  },
  IT278199: {
    country: 'Italy - IT (005)',
    city: 'MILANO',
    name: "Area Gestione Tributi dell'UD di MILANO 2",
  },
  IT279000: {
    country: 'Italy - IT (005)',
    city: 'Malpensa (VA)',
    name: 'Direzione Ufficio delle Dogane di MALPENSA',
  },
  IT279100: {
    country: 'Italy - IT (005)',
    city: 'Malpensa (VA)',
    name: 'Ufficio delle Dogane di MALPENSA',
  },
  IT279199: {
    country: 'Italy - IT (005)',
    city: 'Malpensa (VA)',
    name: "Area Gestione Tributi dell'UD di MALPENSA",
  },
  IT281000: {
    country: 'Italy - IT (005)',
    city: 'NAPOLI',
    name: 'Direzione Ufficio delle Dogane di NAPOLI 1',
  },
  IT281100: {
    country: 'Italy - IT (005)',
    city: 'NAPOLI',
    name: 'Ufficio delle Dogane di NAPOLI 1',
  },
  IT281101: {
    country: 'Italy - IT (005)',
    city: 'Napoli',
    name: 'Sezione Operativa Territoriale PETROLI',
  },
  IT281103: {
    country: 'Italy - IT (005)',
    city: 'Napoli',
    name: 'Sezione Operativa Territoriale AEROPORTO DI CAPODICHINO',
  },
  IT281199: {
    country: 'Italy - IT (005)',
    city: 'NAPOLI',
    name: "Area Gestione Tributi dell'UD di NAPOLI 1",
  },
  IT282000: {
    country: 'Italy - IT (005)',
    city: 'NAPOLI',
    name: 'Direzione Ufficio delle Dogane di NAPOLI 2',
  },
  IT282100: {
    country: 'Italy - IT (005)',
    city: 'NAPOLI',
    name: 'Ufficio delle Dogane di NAPOLI 2',
  },
  IT282102: {
    country: 'Italy - IT (005)',
    city: 'Capri (NA)',
    name: 'Sezione Operativa Territoriale di CAPRI',
  },
  IT282103: {
    country: 'Italy - IT (005)',
    city: 'Nola (NA)',
    name: 'Sezione Operativa Territoriale di NOLA',
  },
  IT282104: {
    country: 'Italy - IT (005)',
    city: 'Porto Baia (NA)',
    name: 'Sezione Operativa Territoriale di POZZUOLI',
  },
  IT282105: {
    country: 'Italy - IT (005)',
    city: 'Torre Annunziata (NA)',
    name: 'Sezione Operativa Territoriale di TORRE ANNUNZIATA-CASTELL. DI STABIA',
  },
  IT282199: {
    country: 'Italy - IT (005)',
    city: 'NAPOLI',
    name: "Area Gestione Tributi dell'UD di NAPOLI 2",
  },
  IT291000: {
    country: 'Italy - IT (005)',
    city: 'CATANIA',
    name: 'Direzione Ufficio delle Dogane di CATANIA',
  },
  IT291100: {
    country: 'Italy - IT (005)',
    city: 'CATANIA',
    name: 'Ufficio delle Dogane di CATANIA',
  },
  IT291101: {
    country: 'Italy - IT (005)',
    city: 'Catania',
    name: 'Sezione Operativa Territoriale AEROPORTO FONTANAROSSA',
  },
  IT291102: {
    country: 'Italy - IT (005)',
    city: 'Riposto (CT)',
    name: 'Sezione Operativa Territoriale di RIPOSTO',
  },
  IT291199: {
    country: 'Italy - IT (005)',
    city: 'CATANIA',
    name: "Area Gestione Tributi dell'UD di CATANIA",
  },
  IT292000: {
    country: 'Italy - IT (005)',
    city: 'PALERMO',
    name: 'Direzione Ufficio delle Dogane di PALERMO',
  },
  IT292100: {
    country: 'Italy - IT (005)',
    city: 'PALERMO',
    name: 'Ufficio delle Dogane di PALERMO',
  },
  IT292101: {
    country: 'Italy - IT (005)',
    city: 'Cinisi (PA)',
    name: 'Sezione Operativa Territoriale AEROPORTO DI PUNTA RAISI',
  },
  IT292102: {
    country: 'Italy - IT (005)',
    city: 'Termini Imerese (PA)',
    name: 'Sezione Operativa Territoriale di TERMINI IMERESE',
  },
  IT292199: {
    country: 'Italy - IT (005)',
    city: 'PALERMO',
    name: "Area Gestione Tributi dell'UD di PALERMO",
  },
  IT293000: {
    country: 'Italy - IT (005)',
    city: 'LAMPEDUSA (AG)',
    name: 'Direzione Ufficio delle Dogane di CANALE DI SICILIA',
  },
  IT293100: {
    country: 'Italy - IT (005)',
    city: 'Lampedusa (AG)',
    name: 'CANALE DI SICILIA',
  },
  IT293101: {
    country: 'Italy - IT (005)',
    city: 'Pantelleria (TP)',
    name: 'PANTELLERIA',
  },
  IT293199: {
    country: 'Italy - IT (005)',
    city: 'Lampedusa (AG)',
    name: "Sezione Tributi dell'UD di CANALE DI SICILIA",
  },
  IT301000: {
    country: 'Italy - IT (005)',
    city: 'CIVITAVECCHIA (RM)',
    name: 'Direzione Ufficio delle Dogane di CIVITAVECCHIA',
  },
  IT301100: {
    country: 'Italy - IT (005)',
    city: 'CIVITAVECCHIA (RM)',
    name: 'Ufficio delle Dogane di CIVITAVECCHIA',
  },
  IT301199: {
    country: 'Italy - IT (005)',
    city: 'CIVITAVECCHIA (RM)',
    name: "Area Gestione Tributi dell'UD di CIVITAVECCHIA",
  },
  IT302000: {
    country: 'Italy - IT (005)',
    city: 'FROSINONE',
    name: 'Direzione Ufficio delle Dogane di FROSINONE',
  },
  IT302100: {
    country: 'Italy - IT (005)',
    city: 'FROSINONE',
    name: 'Ufficio delle Dogane di FROSINONE',
  },
  IT302199: {
    country: 'Italy - IT (005)',
    city: 'FROSINONE',
    name: "Area Gestione Tributi dell'UD di FROSINONE",
  },
  IT303000: {
    country: 'Italy - IT (005)',
    city: 'CAMPOBASSO',
    name: 'Direzione Ufficio delle Dogane di CAMPOBASSO',
  },
  IT303100: {
    country: 'Italy - IT (005)',
    city: 'CAMPOBASSO',
    name: 'Ufficio delle Dogane di CAMPOBASSO',
  },
  IT303101: {
    country: 'Italy - IT (005)',
    city: 'Termoli (CB)',
    name: 'Sezione Operativa Territoriale di TERMOLI',
  },
  IT303199: {
    country: 'Italy - IT (005)',
    city: 'CAMPOBASSO',
    name: "Area Gestione Tributi dell'UD di CAMPOBASSO",
  },
  IT304000: {
    country: 'Italy - IT (005)',
    city: 'PESCARA',
    name: 'Direzione Ufficio delle Dogane di PESCARA',
  },
  IT304100: {
    country: 'Italy - IT (005)',
    city: 'PESCARA',
    name: 'Ufficio delle Dogane di PESCARA',
  },
  IT304102: {
    country: 'Italy - IT (005)',
    city: 'Giulianova (TE)',
    name: 'Sezione Operativa Territoriale di GIULIANOVA',
  },
  IT304103: {
    country: 'Italy - IT (005)',
    city: 'Ortona a Mare (CH)',
    name: 'Sezione Operativa Territoriale di ORTONA',
  },
  IT304104: {
    country: 'Italy - IT (005)',
    city: 'Vasto (CH)',
    name: 'Sezione Operativa Territoriale di VASTO',
  },
  IT304199: {
    country: 'Italy - IT (005)',
    city: 'PESCARA',
    name: "Area Gestione Tributi dell'UD di PESCARA",
  },
  IT305000: {
    country: 'Italy - IT (005)',
    city: 'ANCONA',
    name: 'Direzione Ufficio delle Dogane di ANCONA',
  },
  IT305100: {
    country: 'Italy - IT (005)',
    city: 'ANCONA',
    name: 'Ufficio delle Dogane di ANCONA',
  },
  IT305101: {
    country: 'Italy - IT (005)',
    city: 'Castelferretti (AN)',
    name: 'Sezione Operativa Territoriale di FALCONARA AEROPORTO',
  },
  IT305103: {
    country: 'Italy - IT (005)',
    city: 'Fabriano (AN)',
    name: 'Sezione Operativa Territoriale di FABRIANO',
  },
  IT305104: {
    country: 'Italy - IT (005)',
    city: 'Fano (PU)',
    name: 'Sezione Operativa Territoriale di FANO',
  },
  IT305105: {
    country: 'Italy - IT (005)',
    city: 'Pesaro',
    name: 'Sezione Operativa Territoriale di PESARO',
  },
  IT305199: {
    country: 'Italy - IT (005)',
    city: 'ANCONA',
    name: "Area Gestione Tributi dell'UD di ANCONA",
  },
  IT306000: {
    country: 'Italy - IT (005)',
    city: 'ROMA',
    name: 'Direzione Ufficio delle Dogane di ROMA 1',
  },
  IT306100: {
    country: 'Italy - IT (005)',
    city: 'ROMA',
    name: 'Ufficio delle Dogane di ROMA 1',
  },
  IT306101: {
    country: 'Italy - IT (005)',
    city: 'Ciampino (RM)',
    name: 'Sezione Operativa Territoriale di CIAMPINO',
  },
  IT306102: {
    country: 'Italy - IT (005)',
    city: 'S. Palomba - Pomezia (RM)',
    name: 'Sezione Operativa Territoriale di POMEZIA',
  },
  IT306103: {
    country: 'Italy - IT (005)',
    city: 'Guidonia Montecelio (RM)',
    name: 'Sezione Operativa Territoriale di ROMA EST',
  },
  IT306199: {
    country: 'Italy - IT (005)',
    city: 'ROMA',
    name: "Area Gestione Tributi dell'UD di ROMA 1",
  },
  IT307000: {
    country: 'Italy - IT (005)',
    city: 'FIUMICINO (RM)',
    name: 'Direzione Ufficio delle Dogane di ROMA 2',
  },
  IT307100: {
    country: 'Italy - IT (005)',
    city: 'FIUMICINO (RM)',
    name: 'Ufficio delle Dogane di ROMA 2',
  },
  IT307102: {
    country: 'Italy - IT (005)',
    city: 'Fiumicino (RM)',
    name: 'Sezione Operativa Territoriale di VIAGGIATORI',
  },
  IT307199: {
    country: 'Italy - IT (005)',
    city: 'FIUMICINO (RM)',
    name: "Area Gestione Tributi dell'UD di ROMA 2",
  },
  IT308000: {
    country: 'Italy - IT (005)',
    city: 'CIVITANOVA MARCHE (MC)',
    name: 'Direzione Ufficio delle Dogane di CIVITANOVA MARCHE',
  },
  IT308100: {
    country: 'Italy - IT (005)',
    city: 'CIVITANOVA MARCHE (MC)',
    name: 'Ufficio delle Dogane di CIVITANOVA MARCHE',
  },
  IT308101: {
    country: 'Italy - IT (005)',
    city: 'San Benedetto del Tronto (AP)',
    name: 'Sezione Operativa Territoriale di SAN BENEDETTO DEL TRONTO',
  },
  IT308199: {
    country: 'Italy - IT (005)',
    city: 'CIVITANOVA MARCHE (MC)',
    name: "Area Gestione Tributi dell'UD di CIVITANOVA MARCHE",
  },
  IT309000: {
    country: 'Italy - IT (005)',
    city: "L'AQUILA",
    name: "Direzione Ufficio delle Dogane L'AQUILA",
  },
  IT309100: {
    country: 'Italy - IT (005)',
    city: "L'AQUILA",
    name: "Ufficio delle Dogane L'AQUILA",
  },
  IT309101: {
    country: 'Italy - IT (005)',
    city: 'Avezzano (AQ)',
    name: 'Sezione Operativa Territoriale di AVEZZANO',
  },
  IT309199: {
    country: 'Italy - IT (005)',
    city: "L'AQUILA",
    name: "Area Gestione Tributi dell'UD L'AQUILA",
  },
  IT311000: {
    country: 'Italy - IT (005)',
    city: 'CUNEO',
    name: 'Direzione Ufficio delle Dogane di CUNEO',
  },
  IT311100: {
    country: 'Italy - IT (005)',
    city: 'CUNEO',
    name: 'Ufficio delle Dogane di CUNEO',
  },
  IT311101: {
    country: 'Italy - IT (005)',
    city: "Diano d'Alba (CN)",
    name: 'Sezione Operativa Territoriale di ALBA',
  },
  IT311199: {
    country: 'Italy - IT (005)',
    city: 'CUNEO',
    name: "Area Gestione Tributi dell'UD di CUNEO",
  },
  IT312000: {
    country: 'Italy - IT (005)',
    city: 'NOVARA',
    name: 'Direzione Ufficio delle Dogane di NOVARA',
  },
  IT312100: {
    country: 'Italy - IT (005)',
    city: 'NOVARA',
    name: 'Ufficio delle Dogane di NOVARA',
  },
  IT312101: {
    country: 'Italy - IT (005)',
    city: 'Arona (NO)',
    name: 'Sezione Operativa Territoriale di ARONA',
  },
  IT312102: {
    country: 'Italy - IT (005)',
    city: 'Novara',
    name: 'Sezione Operativa Territoriale di NOVARA BOSCHETTO',
  },
  IT312199: {
    country: 'Italy - IT (005)',
    city: 'NOVARA',
    name: "Area Gestione Tributi dell'UD di NOVARA",
  },
  IT313000: {
    country: 'Italy - IT (005)',
    city: 'ALESSANDRIA',
    name: 'Direzione Ufficio delle Dogane di ALESSANDRIA',
  },
  IT313100: {
    country: 'Italy - IT (005)',
    city: 'ALESSANDRIA',
    name: 'Ufficio delle Dogane di ALESSANDRIA',
  },
  IT313101: {
    country: 'Italy - IT (005)',
    city: 'Asti',
    name: 'Sezione Operativa Territoriale di ASTI',
  },
  IT313199: {
    country: 'Italy - IT (005)',
    city: 'ALESSANDRIA',
    name: "Area Gestione Tributi dell'UD di ALESSANDRIA",
  },
  IT314000: {
    country: 'Italy - IT (005)',
    city: 'ORBASSANO (TO)',
    name: 'Direzione Ufficio delle Dogane di TORINO',
  },
  IT314100: {
    country: 'Italy - IT (005)',
    city: 'ORBASSANO (TO)',
    name: 'Ufficio delle Dogane di TORINO',
  },
  IT314101: {
    country: 'Italy - IT (005)',
    city: 'Caselle Torinese (TO)',
    name: 'Sezione Operativa Territoriale di CASELLE TORINESE',
  },
  IT314102: {
    country: 'Italy - IT (005)',
    city: 'ORBASSANO (TO)',
    name: 'Sezione Operativa Territoriale di ORBASSANO',
  },
  IT314199: {
    country: 'Italy - IT (005)',
    city: 'ORBASSANO (TO)',
    name: "Area Gestione Tributi dell'UD di TORINO",
  },
  IT321000: {
    country: 'Italy - IT (005)',
    city: 'MONRUPINO (TS)',
    name: 'Direzione Ufficio delle Dogane di FERNETTI - RETROPORTO DI TRIESTE',
  },
  IT321100: {
    country: 'Italy - IT (005)',
    city: 'MONRUPINO (TS)',
    name: 'Ufficio delle Dogane di FERNETTI - RETROPORTO DI TRIESTE',
  },
  IT321101: {
    country: 'Italy - IT (005)',
    city: 'Sgonico (TS)',
    name: 'Sezione Operativa Territoriale di PROSECCO',
  },
  IT321199: {
    country: 'Italy - IT (005)',
    city: 'MONRUPINO (TS)',
    name: "Area Gestione Tributi dell'UD di FERNETTI - RETROPORTO DI TRIESTE",
  },
  IT371000: {
    country: 'Italy - IT (005)',
    city: 'LINATE (MI)',
    name: 'Direzione Ufficio delle Dogane di MILANO 3',
  },
  IT371100: {
    country: 'Italy - IT (005)',
    city: 'LINATE (MI)',
    name: 'Ufficio delle Dogane di MILANO 3',
  },
  IT371101: {
    country: 'Italy - IT (005)',
    city: 'Melzo (MI)',
    name: 'Sezione Operativa Territoriale di MELZO',
  },
  IT371102: {
    country: 'Italy - IT (005)',
    city: 'Limito di Pioltello (MI)',
    name: 'Sezione Operativa Territoriale di PIOLTELLO',
  },
  IT371199: {
    country: 'Italy - IT (005)',
    city: 'LINATE (MI)',
    name: "Area Gestione Tributi dell'UD di MILANO 3",
  },
  IT401000: {
    country: 'Italy - IT (005)',
    city: 'Viterbo',
    name: 'Ufficio delle Dogane di VITERBO',
  },
  IT401100: {
    country: 'Italy - IT (005)',
    city: 'Viterbo',
    name: 'Ufficio delle Dogane di VITERBO',
  },
  IT401101: {
    country: 'Italy - IT (005)',
    city: 'Cittaducale (RI)',
    name: 'Sezione Operativa Territoriale di RIETI',
  },
  IT401199: {
    country: 'Italy - IT (005)',
    city: 'VITERBO (VT)',
    name: "Area Gestione Tributi dell'UD di VITERBO",
  },
  IT444444: {
    country: 'Italy - IT (005)',
    city: 'ROMA',
    name: 'MONOPOLI DI STATO',
  },
  IT922007: {
    country: 'Italy - IT (005)',
    city: 'ROMA',
    name: 'UFF. MUTUA ASS.ZA E COOP.NE AMM.VA DC ANTIFRODE E CONTROLLI',
  },
  IT922008: {
    country: 'Italy - IT (005)',
    city: 'ROMA',
    name: 'UFFICIO ANALISI STATISTICA DC ANTIFRODE E CONTROLLI',
  },
  IT922103: {
    country: 'Italy - IT (005)',
    city: 'ROMA',
    name: 'AEO COMPLIANCE E GRANDI IMPRESE DIREZIONE DOGANE',
  },
  IT922104: {
    country: 'Italy - IT (005)',
    city: 'ROMA',
    name: 'TARIFFA E CLASSIFICAZIONE DIREZIONE DOGANE',
  },
  IT922105: {
    country: 'Italy - IT (005)',
    city: 'ROMA',
    name: 'ORIGINE E VALORE DIREZIONE DOGANE',
  },
  IT922106: {
    country: 'Italy - IT (005)',
    city: 'ROMA',
    name: 'REGIMI E PROCEDURE DOGANALI DIREZIONE DOGANE',
  },
  IT923209: {
    country: 'Italy - IT (005)',
    city: 'ROMA',
    name: 'RISORSE PROPRIE DIREZIONE DOGANE',
  },
  ITP00001: {
    country: 'Italy - IT (005)',
    city: 'BARI',
    name: 'D.I. PUGLIA MOLISE E BASILICATA',
  },
  ITP00002: {
    country: 'Italy - IT (005)',
    city: 'BOLOGNA',
    name: 'D.I. EMILIA ROMAGNA E MARCHE',
  },
  ITP00006: {
    country: 'Italy - IT (005)',
    city: 'GENOVA',
    name: "D.I. PIEMONTE LIGURIA VALLE D'AOSTA",
  },
  ITP00007: {
    country: 'Italy - IT (005)',
    city: 'MILANO',
    name: 'DIREZIONE REGIONALE LOMBARDIA',
  },
  ITP00009: {
    country: 'Italy - IT (005)',
    city: 'PALERMO',
    name: 'DIREZIONE REGIONALE SICILIA',
  },
  ITP00010: {
    country: 'Italy - IT (005)',
    city: 'ROMA',
    name: 'D.I. LAZIO E ABRUZZO',
  },
  ITP00013: {
    country: 'Italy - IT (005)',
    city: 'VENEZIA MESTRE',
    name: 'D.I. VENETO E FRIULI VENEZIA GIULIA',
  },
  ITP00015: {
    country: 'Italy - IT (005)',
    city: 'BOLZANO',
    name: 'DIR. INTERPROV.LE BOLZANO TRENTO',
  },
  ITP00017: {
    country: 'Italy - IT (005)',
    city: 'Gioia Tauro',
    name: 'DR CALABRIA',
  },
  ITP00018: {
    country: 'Italy - IT (005)',
    city: 'Napoli',
    name: 'DR CAMPANIA',
  },
  ITP00019: {
    country: 'Italy - IT (005)',
    city: 'Cagliari',
    name: 'D.R. SARDEGNA',
  },
  ITP00020: {
    country: 'Italy - IT (005)',
    city: 'Firenze',
    name: 'D.I. TOSCANA E UMBRIA',
  },
  LT00E001: {
    country: 'Lithuania - LT (055)',
    city: 'Vilnius',
    name: 'VMI Akcizų administravimo skyrius',
  },
  LT00E059: {
    country: 'Lithuania - LT (055)',
    city: 'Vilnius',
    name: 'Vilniaus AVMI Akcizų admin. skyrius',
  },
  LT00E060: {
    country: 'Lithuania - LT (055)',
    city: 'Kaunas',
    name: 'Kauno AVMI Akcizų admin. skyrius',
  },
  LT00E061: {
    country: 'Lithuania - LT (055)',
    city: 'Klaipėda',
    name: 'Klaipėdos AVMI Akcizų admin. skyr.',
  },
  LT00E062: {
    country: 'Lithuania - LT (055)',
    city: 'Panevėžys',
    name: 'Panevėžio AVMI Akcizų admin. sk.',
  },
  LT00E063: {
    country: 'Lithuania - LT (055)',
    city: 'Šiauliai',
    name: 'Šiaulių AVMI Akcizų admin. skyrius',
  },
  LT00E064: {
    country: 'Lithuania - LT (055)',
    city: 'Alytus',
    name: 'Alytaus AVMI Akcizų admin. skyrius',
  },
  LT00E065: {
    country: 'Lithuania - LT (055)',
    city: 'Marijampolė',
    name: 'Marijampolės AVMI Akcizų admin. sk.',
  },
  LT00E066: {
    country: 'Lithuania - LT (055)',
    city: 'Mažeikiai',
    name: 'Mažeikių AVMI Akcizų admin. skyrius',
  },
  LT00E067: {
    country: 'Lithuania - LT (055)',
    city: 'Utena',
    name: 'Utenos AVMI Akcizų admin. skyrius',
  },
  LT00E068: {
    country: 'Lithuania - LT (055)',
    city: 'Tauragė',
    name: 'Tauragės AVMI Akcizų admin. skyrius',
  },
  LTKA1000: {
    country: 'Lithuania - LT (055)',
    city: 'Kauno r.',
    name: 'Kauno oro uosto postas',
  },
  LTKC0100: {
    country: 'Lithuania - LT (055)',
    city: 'Kaunas',
    name: 'Kauno krovinių postas "Centras"',
  },
  LTKG3000: {
    country: 'Lithuania - LT (055)',
    city: 'Kybartai, Vilkaviškio r.',
    name: 'Kybartų geležinkelio postas',
  },
  LTKK2000: {
    country: 'Lithuania - LT (055)',
    city: 'Vilkaviškio r.',
    name: 'Kybartų kelio postas',
  },
  LTKK3000: {
    country: 'Lithuania - LT (055)',
    city: 'Šakių r.',
    name: 'Ramoniškių kelio postas',
  },
  LTKM0000: {
    country: 'Lithuania - LT (055)',
    city: 'Kaunas',
    name: 'Kauno teritorinė muitinė',
  },
  LTKMB100: {
    country: 'Lithuania - LT (055)',
    city: 'Vilnius',
    name: 'Mobiliųjų grupių postas',
  },
  LTKR2000: {
    country: 'Lithuania - LT (055)',
    city: 'Kaunas',
    name: 'Krovinių postas "Tiekimas"',
  },
  LTKR2002: {
    country: 'Lithuania - LT (055)',
    city: 'Kaunas',
    name: 'MTV „Ad Verbum“',
  },
  LTKR2004: {
    country: 'Lithuania - LT (055)',
    city: 'Kaunas',
    name: 'MTV „KIT“',
  },
  LTKR2006: {
    country: 'Lithuania - LT (055)',
    city: 'Kaunas',
    name: 'MTV „Lavisa“',
  },
  LTKR2008: {
    country: 'Lithuania - LT (055)',
    city: 'Kauno r.',
    name: 'MTV „Lavisos LEZ“',
  },
  LTKR2009: {
    country: 'Lithuania - LT (055)',
    city: 'Kaunas',
    name: 'MTV „Jovarai“',
  },
  LTKR5000: {
    country: 'Lithuania - LT (055)',
    city: 'Marijampolė',
    name: 'Marijampolės krovinių postas',
  },
  LTLA1000: {
    country: 'Lithuania - LT (055)',
    city: 'Palanga',
    name: 'Palangos oro uosto postas',
  },
  LTLA1001: {
    country: 'Lithuania - LT (055)',
    city: 'Palanga',
    name: 'MTV „Būtingė“',
  },
  LTLC0100: {
    country: 'Lithuania - LT (055)',
    city: 'Klaipėda',
    name: 'Klaipėdos krovinių postas "Centras"',
  },
  LTLG3000: {
    country: 'Lithuania - LT (055)',
    city: 'Pagėgių sav.',
    name: 'Pagėgių geležinkelio postas',
  },
  LTLK1000: {
    country: 'Lithuania - LT (055)',
    city: 'Neringa',
    name: 'Nidos kelio postas',
  },
  LTLK4000: {
    country: 'Lithuania - LT (055)',
    city: 'Pagėgių sav.',
    name: 'Panemunės kelio postas',
  },
  LTLM0000: {
    country: 'Lithuania - LT (055)',
    city: 'Klaipėda',
    name: 'Klaipėdos teritorinė muitinė',
  },
  LTLMB100: {
    country: 'Lithuania - LT (055)',
    city: 'Klaipėda',
    name: 'Mobiliųjų grupių postas',
  },
  LTLR1000: {
    country: 'Lithuania - LT (055)',
    city: 'Klaipėda',
    name: 'Klaipėdos krovinių postas',
  },
  LTLR1006: {
    country: 'Lithuania - LT (055)',
    city: 'Klaipėda',
    name: 'MTV „Gollner Spedition“',
  },
  LTLU4000: {
    country: 'Lithuania - LT (055)',
    city: 'Nida',
    name: 'Nidos upių uosto postas',
  },
  LTLU9000: {
    country: 'Lithuania - LT (055)',
    city: 'Klaipėda',
    name: 'Malkų įlankos jūrų uosto postas',
  },
  LTLU9001: {
    country: 'Lithuania - LT (055)',
    city: 'Klaipėda',
    name: 'MTV „Kuršiai“',
  },
  LTLUA000: {
    country: 'Lithuania - LT (055)',
    city: 'Klaipėda',
    name: 'Molo jūrų uosto postas',
  },
  LTLUA001: {
    country: 'Lithuania - LT (055)',
    city: 'Klaipėda',
    name: 'MTV „Burės“',
  },
  LTLUB000: {
    country: 'Lithuania - LT (055)',
    city: 'Klaipėda',
    name: 'Pilies jūrų uosto postas',
  },
  LTLUC000: {
    country: 'Lithuania - LT (055)',
    city: 'Jurbarkas',
    name: 'Nemuno upių uosto postas',
  },
  LTLUD000: {
    country: 'Lithuania - LT (055)',
    city: 'Šilutės r.',
    name: 'Rusnės upių uosto postas',
  },
  LTMM0000: {
    country: 'Lithuania - LT (055)',
    city: 'Vilnius',
    name: 'Muitinės departamentas',
  },
  LTMM00G0: {
    country: 'Lithuania - LT (055)',
    city: 'Vilnius',
    name: 'Garantijos įstaiga',
  },
  LTPR2000: {
    country: 'Lithuania - LT (055)',
    city: 'Panevėžys',
    name: 'Panevėžio 1 kat. krovinių postas',
  },
  LTPR4000: {
    country: 'Lithuania - LT (055)',
    city: 'Utena',
    name: 'Utenos krovinių postas',
  },
  LTSA1000: {
    country: 'Lithuania - LT (055)',
    city: 'Šiauliai',
    name: 'Šiaulių oro uosto postas',
  },
  LTSG3000: {
    country: 'Lithuania - LT (055)',
    city: 'Radviliškis',
    name: 'Radviliškio geležinkelio postas',
  },
  LTSG3001: {
    country: 'Lithuania - LT (055)',
    city: 'Radviliškis',
    name: 'MTV „Aloyzo Grigaliūno transportas“',
  },
  LTSG4000: {
    country: 'Lithuania - LT (055)',
    city: 'Dapšių k. Mažeikių r. sav.',
    name: 'Bugenių geležinkelio postas',
  },
  LTSG4001: {
    country: 'Lithuania - LT (055)',
    city: 'Plungė',
    name: 'MTV „Plungės kooperatinė prekyba“',
  },
  LTSR1000: {
    country: 'Lithuania - LT (055)',
    city: 'Šiauliai',
    name: 'Šiaulių 1 kat. krovinių postas',
  },
  LTSR1001: {
    country: 'Lithuania - LT (055)',
    city: 'Šiauliai',
    name: 'MTV „Šiaulių tiekimo bazė“',
  },
  LTVA1000: {
    country: 'Lithuania - LT (055)',
    city: 'Vilnius',
    name: 'Vilniaus oro uosto postas',
  },
  LTVC0100: {
    country: 'Lithuania - LT (055)',
    city: 'Vilnius',
    name: 'Vilniaus krovinių postas "Centras"',
  },
  LTVG1000: {
    country: 'Lithuania - LT (055)',
    city: 'Vilniaus r.',
    name: 'Kenos geležinkelio postas',
  },
  LTVG2000: {
    country: 'Lithuania - LT (055)',
    city: 'Vilnius',
    name: 'Vaidotų geležinkelio postas',
  },
  LTVG3000: {
    country: 'Lithuania - LT (055)',
    city: 'Šalčininkų r.',
    name: 'Stasylų geležinkelio postas',
  },
  LTVK1000: {
    country: 'Lithuania - LT (055)',
    city: 'Vilniaus r.',
    name: 'Lavoriškių kelio postas',
  },
  LTVK2000: {
    country: 'Lithuania - LT (055)',
    city: 'Vilniaus r.',
    name: 'Medininkų kelio postas',
  },
  LTVK3000: {
    country: 'Lithuania - LT (055)',
    city: 'Šalčininkų r.',
    name: 'Šalčininkų kelio postas',
  },
  LTVK8000: {
    country: 'Lithuania - LT (055)',
    city: 'Druskininkų r. sav.',
    name: 'Raigardo kelio postas',
  },
  LTVKB000: {
    country: 'Lithuania - LT (055)',
    city: 'Vilniaus r. sav.',
    name: 'Šumsko kelio postas',
  },
  LTVKB100: {
    country: 'Lithuania - LT (055)',
    city: 'Ignalinos r. sav.',
    name: 'Tverečiaus kelio postas',
  },
  LTVM0000: {
    country: 'Lithuania - LT (055)',
    city: 'Vilnius',
    name: 'Vilniaus teritorinė muitinė',
  },
  LTVMB100: {
    country: 'Lithuania - LT (055)',
    city: 'Vilnius',
    name: 'Mobiliųjų grupių postas',
  },
  LTVP1000: {
    country: 'Lithuania - LT (055)',
    city: 'Vilnius',
    name: 'Vilniaus pašto postas',
  },
  LTVR1000: {
    country: 'Lithuania - LT (055)',
    city: 'Vilniaus r.',
    name: 'Krovinių postas "Savanoriai"',
  },
  LTVR3000: {
    country: 'Lithuania - LT (055)',
    city: 'Vilnius',
    name: 'Krovinių postas "Kirtimai"',
  },
  LTVR4000: {
    country: 'Lithuania - LT (055)',
    city: 'Vilniaus r.',
    name: 'Krovinių postas "Žirmūnai"',
  },
  LTVR5000: {
    country: 'Lithuania - LT (055)',
    city: 'Vilnius',
    name: 'Krovinių postas "Paneriai"',
  },
  LTVU0100: {
    country: 'Lithuania - LT (055)',
    city: 'Druskininkų sav.',
    name: 'Švendubrės upių uosto postas',
  },
  LU700000: {
    country: 'Luxembourg - LU (018)',
    city: 'LUXEMBOURG',
    name: 'Direction des Douanes et Accises',
  },
  LU701000: {
    country: 'Luxembourg - LU (018)',
    city: 'DUDELANGE',
    name: 'Bureau des Douanes et Accises Bettembourg',
  },
  LU704000: {
    country: 'Luxembourg - LU (018)',
    city: 'Luxembourg',
    name: 'Bureau des Douanes et Accises Centre Douanier',
  },
  LU708000: {
    country: 'Luxembourg - LU (018)',
    city: 'Diekirch',
    name: 'Bureau des Douanes et Accises Centre douanier Nord',
  },
  LU711000: {
    country: 'Luxembourg - LU (018)',
    city: 'Mersch',
    name: 'Caisse Centrale des Douanes et Accises',
  },
  LU715000: {
    country: 'Luxembourg - LU (018)',
    city: 'Niederanven',
    name: 'Bureau des Douanes et Accises Luxembourg-Aeroport',
  },
  LV000097: {
    country: 'Latvia - LV (054)',
    city: 'Rīga',
    name: 'VID Akcīzes preču aprites daļa',
  },
  LV000207: {
    country: 'Latvia - LV (054)',
    city: 'Rīga',
    name: 'ŠĶIROTAVAS MKP',
  },
  LV000210: {
    country: 'Latvia - LV (054)',
    city: 'Rīga',
    name: 'RĪGAS BRĪVOSTAS MKP',
  },
  LV000240: {
    country: 'Latvia - LV (054)',
    city: 'Mārupes nov., Rīgas raj.',
    name: 'LIDOSTAS MKP',
  },
  LV000311: {
    country: 'Latvia - LV (054)',
    city: 'Ventspils',
    name: 'VENTSPILS OSTAS MKP',
  },
  LV000411: {
    country: 'Latvia - LV (054)',
    city: 'Liepāja',
    name: 'LIEPĀJAS OSTAS MKP',
  },
  LV000512: {
    country: 'Latvia - LV (054)',
    city: 'Jelgava',
    name: 'JELGAVAS MKP',
  },
  LV000626: {
    country: 'Latvia - LV (054)',
    city: 'Valmiera',
    name: 'VALMIERAS MKP',
  },
  LV000712: {
    country: 'Latvia - LV (054)',
    city: 'Balvu novads',
    name: 'VIENTUĻU MKP',
  },
  LV000721: {
    country: 'Latvia - LV (054)',
    city: 'Ludzas novads',
    name: 'GREBŅEVAS MKP',
  },
  LV000722: {
    country: 'Latvia - LV (054)',
    city: 'Ludzas novads',
    name: 'TEREHOVAS MKP',
  },
  LV000723: {
    country: 'Latvia - LV (054)',
    city: 'Ludzas novads',
    name: 'ZILUPES MKP',
  },
  LV000724: {
    country: 'Latvia - LV (054)',
    city: 'Ludzas novads',
    name: 'KĀRSAVAS MKP',
  },
  LV000731: {
    country: 'Latvia - LV (054)',
    city: 'Krāslavas nov.',
    name: 'PĀTERNIEKU MKP',
  },
  LV000742: {
    country: 'Latvia - LV (054)',
    city: 'Rēzekne',
    name: 'RĒZEKNES 2 MKP',
  },
  LV000814: {
    country: 'Latvia - LV (054)',
    city: 'Augšdaugavas novads',
    name: 'SILENES MKP',
  },
  LV000816: {
    country: 'Latvia - LV (054)',
    city: 'Daugavpils',
    name: 'DAUGAVPILS PREČU STACIJAS MKP',
  },
  LV000817: {
    country: 'Latvia - LV (054)',
    city: 'Krāslavas nov.',
    name: 'INDRAS MKP',
  },
  LV001514: {
    country: 'Latvia - LV (054)',
    city: 'Rīga',
    name: 'VID Nodokļu pārvaldes CIAD',
  },
  LV002200: {
    country: 'Latvia - LV (054)',
    city: 'Rīga',
    name: 'VID MP RĪGAS MKP DAĻA',
  },
  LV002400: {
    country: 'Latvia - LV (054)',
    city: 'Ventspils',
    name: 'VID MP KURZEMES MKP DAĻA',
  },
  LV002600: {
    country: 'Latvia - LV (054)',
    city: 'Daugavpils',
    name: 'VID MP LATGALES MKP DAĻA',
  },
  LV009999: {
    country: 'Latvia - LV (054)',
    city: 'Rīga',
    name: 'VID MUITAS PĀRVALDE',
  },
  MK001000: {
    country: 'North Macedonia - MK (096)',
    city: 'Skopje',
    name: 'Carinarnica Skopje',
  },
  MK001010: {
    country: 'North Macedonia - MK (096)',
    city: 'Skopje',
    name: 'Skopje 1',
  },
  MK001013: {
    country: 'North Macedonia - MK (096)',
    city: 'Skopje',
    name: 'Skopje 3',
  },
  MK001014: {
    country: 'North Macedonia - MK (096)',
    city: 'Skopje',
    name: 'Skopje 4',
  },
  MK001015: {
    country: 'North Macedonia - MK (096)',
    city: 'Skopje',
    name: 'Skopje 2',
  },
  MK001020: {
    country: 'North Macedonia - MK (096)',
    city: 'Skopje',
    name: 'Slobodna zona Bunardzik',
  },
  MK001025: {
    country: 'North Macedonia - MK (096)',
    city: 'Skopje',
    name: 'Carinska ispostava Posta',
  },
  MK001050: {
    country: 'North Macedonia - MK (096)',
    city: 'Skopje',
    name: 'CI Aerodrom Skopje stokovo',
  },
  MK001051: {
    country: 'North Macedonia - MK (096)',
    city: 'Skopje',
    name: 'CI Aerodrom Skopje Patnicki',
  },
  MK001071: {
    country: 'North Macedonia - MK (096)',
    city: 'Skopje',
    name: 'CI Blace patnicki',
  },
  MK001075: {
    country: 'North Macedonia - MK (096)',
    city: 'Skopje',
    name: 'CI Blace stokovo',
  },
  MK001080: {
    country: 'North Macedonia - MK (096)',
    city: 'Tetovo',
    name: 'CI Tetovo',
  },
  MK001091: {
    country: 'North Macedonia - MK (096)',
    city: 'Tetovo',
    name: 'CI Jazince',
  },
  MK002000: {
    country: 'North Macedonia - MK (096)',
    city: 'Kumanovo',
    name: 'Carinarnica Kumanovo',
  },
  MK002010: {
    country: 'North Macedonia - MK (096)',
    city: 'Kumanovo',
    name: 'CI Kumanovo',
  },
  MK002021: {
    country: 'North Macedonia - MK (096)',
    city: 'Kumanovo',
    name: 'CI Zeleznicka stanicaTabanovci',
  },
  MK002030: {
    country: 'North Macedonia - MK (096)',
    city: 'Kumanovo',
    name: 'CI Tabanovce stokovo',
  },
  MK002031: {
    country: 'North Macedonia - MK (096)',
    city: 'Kumanovo',
    name: 'CI Tabanovce patnicki',
  },
  MK002050: {
    country: 'North Macedonia - MK (096)',
    city: 'Kriva Palanka',
    name: 'CI Deve Bair stokovo',
  },
  MK002051: {
    country: 'North Macedonia - MK (096)',
    city: 'Kriva Palanka',
    name: 'CI Deve Bair- Patnicki',
  },
  MK003000: {
    country: 'North Macedonia - MK (096)',
    city: 'Stip',
    name: 'Carinarnica Stip',
  },
  MK003010: {
    country: 'North Macedonia - MK (096)',
    city: 'Stip',
    name: 'CI Stip',
  },
  MK003030: {
    country: 'North Macedonia - MK (096)',
    city: 'Delcevo',
    name: 'CI Delcevo stokovo',
  },
  MK003031: {
    country: 'North Macedonia - MK (096)',
    city: 'Delcevo',
    name: 'CI Delcevo patnicki',
  },
  MK003050: {
    country: 'North Macedonia - MK (096)',
    city: 'Veles',
    name: 'CI Veles',
  },
  MK004000: {
    country: 'North Macedonia - MK (096)',
    city: 'Gevgelija',
    name: 'Carinarnica Gevgelija',
  },
  MK004010: {
    country: 'North Macedonia - MK (096)',
    city: 'Gevgelija',
    name: 'CI Bogorodica patnicki',
  },
  MK004015: {
    country: 'North Macedonia - MK (096)',
    city: 'Gevgelija',
    name: 'CI Bogorodica stokovo',
  },
  MK004020: {
    country: 'North Macedonia - MK (096)',
    city: 'Gevgelija',
    name: 'CI Gevgelija',
  },
  MK004021: {
    country: 'North Macedonia - MK (096)',
    city: 'Gevgelija',
    name: 'CI Zeleznicka stanica Gevgelija',
  },
  MK004031: {
    country: 'North Macedonia - MK (096)',
    city: 'Star Dojran',
    name: 'CI Dojran',
  },
  MK004040: {
    country: 'North Macedonia - MK (096)',
    city: 'Kavadarci',
    name: 'CI Kavadarci',
  },
  MK004050: {
    country: 'North Macedonia - MK (096)',
    city: 'Strumica',
    name: 'CI Ctrumica',
  },
  MK004060: {
    country: 'North Macedonia - MK (096)',
    city: 'Novo Selo',
    name: 'CI Novo Selo stokovo',
  },
  MK004061: {
    country: 'North Macedonia - MK (096)',
    city: 'Novo Selo',
    name: 'CI Novo Selo patnicki',
  },
  MK005000: {
    country: 'North Macedonia - MK (096)',
    city: 'Bitola',
    name: 'Carinarnica Bitola',
  },
  MK005010: {
    country: 'North Macedonia - MK (096)',
    city: 'Bitola',
    name: 'CI Bitola',
  },
  MK005020: {
    country: 'North Macedonia - MK (096)',
    city: 'Bitola',
    name: 'CI Medzitlija stokovo',
  },
  MK005021: {
    country: 'North Macedonia - MK (096)',
    city: 'Bitola',
    name: 'CI Medzitlija patnicki',
  },
  MK005030: {
    country: 'North Macedonia - MK (096)',
    city: 'Prilep',
    name: 'CI Prilep',
  },
  MK005040: {
    country: 'North Macedonia - MK (096)',
    city: 'Ohrid',
    name: 'CI Ohrid',
  },
  MK005042: {
    country: 'North Macedonia - MK (096)',
    city: 'Ohrid',
    name: 'CI Aerodrom Ohrid',
  },
  MK005051: {
    country: 'North Macedonia - MK (096)',
    city: 'Ohrid',
    name: 'CI Sveti Naum',
  },
  MK005060: {
    country: 'North Macedonia - MK (096)',
    city: 'Struga',
    name: 'CI Struga',
  },
  MK005070: {
    country: 'North Macedonia - MK (096)',
    city: 'Struga',
    name: 'CI Kafasan stokovo',
  },
  MK005071: {
    country: 'North Macedonia - MK (096)',
    city: 'Struga',
    name: 'CI Kafasan patnicki',
  },
  MK005081: {
    country: 'North Macedonia - MK (096)',
    city: 'Resen',
    name: 'CI Stenje',
  },
  MK005091: {
    country: 'North Macedonia - MK (096)',
    city: 'Debar',
    name: 'CI Blato',
  },
  MK009000: {
    country: 'North Macedonia - MK (096)',
    city: 'Skopje',
    name: 'Centralna uprava',
  },
  MT000100: {
    country: 'Malta - MT (046)',
    city: "B'Bugia",
    name: 'Malta Freeport West Quay Gate',
  },
  MT000102: {
    country: 'Malta - MT (046)',
    city: 'Luqa',
    name: 'Airfreight',
  },
  MT000103: {
    country: 'Malta - MT (046)',
    city: 'Marsa',
    name: 'Grand Harbour Seaport',
  },
  MT000104: {
    country: 'Malta - MT (046)',
    city: 'Gudja',
    name: 'MIA Arrivals',
  },
  MT000107: {
    country: 'Malta - MT (046)',
    city: 'Marsa',
    name: 'Deep Water Quay',
  },
  MT000108: {
    country: 'Malta - MT (046)',
    city: 'VALLETTA',
    name: 'Energy Products Control Unit',
  },
  MT000109: {
    country: 'Malta - MT (046)',
    city: 'Kordin',
    name: 'Laboratory Wharf',
  },
  MT000112: {
    country: 'Malta - MT (046)',
    city: "Ta' Xbiex",
    name: 'Msida Yacht Marina',
  },
  MT000113: {
    country: 'Malta - MT (046)',
    city: 'Birzebbugia',
    name: 'Malta Freeport Main Gate',
  },
  MT000114: {
    country: 'Malta - MT (046)',
    city: 'Marsa',
    name: 'Parcel Post Office - Malta',
  },
  MT000117: {
    country: 'Malta - MT (046)',
    city: 'Luqa',
    name: 'Customs Express Freight Office',
  },
  MT000118: {
    country: 'Malta - MT (046)',
    city: 'Valletta',
    name: 'Economic Procedures Unit',
  },
  MT000120: {
    country: 'Malta - MT (046)',
    city: 'VALLETTA',
    name: 'Excise Section',
  },
  MT000122: {
    country: 'Malta - MT (046)',
    city: 'VALLETTA',
    name: 'Alcohol Products Unit',
  },
  MT000123: {
    country: 'Malta - MT (046)',
    city: 'Gudja',
    name: 'MIA Departures',
  },
  MT000124: {
    country: 'Malta - MT (046)',
    city: 'Valletta',
    name: 'Special Procedures Unit',
  },
  MT000400: {
    country: 'Malta - MT (046)',
    city: 'Kalafrana',
    name: 'Pre-Arrival Control Section',
  },
  MT000600: {
    country: 'Malta - MT (046)',
    city: 'Birzebbuga',
    name: 'Containers & Groupage Section',
  },
  MT000702: {
    country: 'Malta - MT (046)',
    city: 'Valletta',
    name: 'Customs Warehousing Unit',
  },
  MT000900: {
    country: 'Malta - MT (046)',
    city: 'Valletta',
    name: 'Customs Debt Unit',
  },
  MT000910: {
    country: 'Malta - MT (046)',
    city: 'Luqa',
    name: 'Central Transit Office',
  },
  MT000920: {
    country: 'Malta - MT (046)',
    city: 'Valletta',
    name: 'EOS and Databases Unit',
  },
  MT000930: {
    country: 'Malta - MT (046)',
    city: 'Valletta',
    name: 'Binding Tariff Information Unit',
  },
  MT000940: {
    country: 'Malta - MT (046)',
    city: 'Valletta',
    name: 'Taric & Quotas Office',
  },
  NL000074: {
    country: 'Netherlands - NL (003)',
    city: 'Rotterdam',
    name: 'Douane/Landelijk kantoor',
  },
  NL000100: {
    country: 'Netherlands - NL (003)',
    city: 'Apeldoorn',
    name: 'Nationale Helpdesk Douane',
  },
  NL000120: {
    country: 'Netherlands - NL (003)',
    city: 'Rotterdam',
    name: 'Douane Breda / LTT',
  },
  NL000144: {
    country: 'Netherlands - NL (003)',
    city: 'Eindhoven',
    name: 'Koeriers simplified proc.',
  },
  NL000155: {
    country: 'Netherlands - NL (003)',
    city: 'Eindhoven',
    name: 'Koeriers Normal proc.',
  },
  NL000158: {
    country: 'Netherlands - NL (003)',
    city: 'Eindhoven',
    name: 'Kantoor Eindhoven Airport',
  },
  NL000184: {
    country: 'Netherlands - NL (003)',
    city: 'Veendam',
    name: 'Kantoor Groningen (TIR)',
  },
  NL000187: {
    country: 'Netherlands - NL (003)',
    city: 'Brunssum',
    name: 'JFC HQ Brunssum',
  },
  NL000204: {
    country: 'Netherlands - NL (003)',
    city: 'Maastricht',
    name: 'Maastricht Aachen Airport',
  },
  NL000205: {
    country: 'Netherlands - NL (003)',
    city: 'Venlo',
    name: 'Kantoor Venlo',
  },
  NL000210: {
    country: 'Netherlands - NL (003)',
    city: 'Arnhem',
    name: 'Landelijk Oorsprong Team (LOT)',
  },
  NL000282: {
    country: 'Netherlands - NL (003)',
    city: 'Eemshaven',
    name: 'Kantoor Eemshaven',
  },
  NL000396: {
    country: 'Netherlands - NL (003)',
    city: 'Rotterdam Maasvlakte',
    name: 'Rotterdam Maasvlakte',
  },
  NL000432: {
    country: 'Netherlands - NL (003)',
    city: 'Schiphol Zuid-Oost',
    name: 'Schiphol Cargo',
  },
  NL000476: {
    country: 'Netherlands - NL (003)',
    city: 'Eelde',
    name: 'Kantoor Groningen Airport',
  },
  NL000510: {
    country: 'Netherlands - NL (003)',
    city: 'Rotterdam',
    name: 'Reeweg simplified proc.',
  },
  NL000511: {
    country: 'Netherlands - NL (003)',
    city: 'Rotterdam',
    name: 'Reeweg normal proc.',
  },
  NL000563: {
    country: 'Netherlands - NL (003)',
    city: 'Groningen',
    name: 'Groningen',
  },
  NL000566: {
    country: 'Netherlands - NL (003)',
    city: 'Breda',
    name: 'Breda',
  },
  NL000567: {
    country: 'Netherlands - NL (003)',
    city: 'Schiphol-Centrum',
    name: 'Schiphol Cargo',
  },
  NL000568: {
    country: 'Netherlands - NL (003)',
    city: 'Eindhoven',
    name: 'Eindhoven',
  },
  NL000702: {
    country: 'Netherlands - NL (003)',
    city: 'Rotterdam',
    name: 'Rotterdam Haven',
  },
  NL000703: {
    country: 'Netherlands - NL (003)',
    city: 'Amsterdam',
    name: 'Amsterdam',
  },
  NL000705: {
    country: 'Netherlands - NL (003)',
    city: 'Schiphol',
    name: 'Schiphol passagiers',
  },
  NL000707: {
    country: 'Netherlands - NL (003)',
    city: 'Arnhem',
    name: 'Arnhem',
  },
  NL000850: {
    country: 'Netherlands - NL (003)',
    city: 'Duiven',
    name: 'Kantoor Duiven',
  },
  NL000851: {
    country: 'Netherlands - NL (003)',
    city: 'De Lutte',
    name: 'Kantoor De Lutte',
  },
  NL000853: {
    country: 'Netherlands - NL (003)',
    city: 'Rotterdam',
    name: 'Kantoor Rotterdam The Hague Airport',
  },
  NL000854: {
    country: 'Netherlands - NL (003)',
    city: 'Amsterdam',
    name: 'Kantoor Amsterdam',
  },
  NL000855: {
    country: 'Netherlands - NL (003)',
    city: 'Moerdijk',
    name: 'Kantoor Moerdijk',
  },
  NL000856: {
    country: 'Netherlands - NL (003)',
    city: 'Ritthem',
    name: 'Kantoor Vlissingen',
  },
  NL563415: {
    country: 'Netherlands - NL (003)',
    city: 'Groningen',
    name: 'Kantoor Groningen/Arnhem',
  },
  NL566379: {
    country: 'Netherlands - NL (003)',
    city: 'Rotterdam',
    name: 'Rotterdam Haven',
  },
  NL566383: {
    country: 'Netherlands - NL (003)',
    city: 'Rotterdam',
    name: 'Dutch Centre of AEO',
  },
  NL567452: {
    country: 'Netherlands - NL (003)',
    city: 'Schiphol Centrum',
    name: 'Schiphol Cargo',
  },
  NL568067: {
    country: 'Netherlands - NL (003)',
    city: 'Heerlen',
    name: 'DOUANE NEDERLAND',
  },
  NL568355: {
    country: 'Netherlands - NL (003)',
    city: 'Roermond',
    name: 'Kantoor Eindhoven',
  },
  NO01011A: {
    country: 'Norway - NO (028)',
    city: 'OSLO',
    name: 'Oslo toll - Tollbugata',
  },
  NO01013C: {
    country: 'Norway - NO (028)',
    city: 'OSLO',
    name: 'Oslo toll - Alnabru',
  },
  NO01016C: {
    country: 'Norway - NO (028)',
    city: 'OSLO',
    name: 'Oslo toll - Utstikker 2',
  },
  NO01017C: {
    country: 'Norway - NO (028)',
    city: 'OSLO',
    name: 'Oslo toll - Hjortnes',
  },
  NO01018C: {
    country: 'Norway - NO (028)',
    city: 'OSLO',
    name: 'Oslo toll - Gardermoen',
  },
  NO02011A: {
    country: 'Norway - NO (028)',
    city: 'HALDEN',
    name: 'Fredrikstad tollsted',
  },
  NO02011B: {
    country: 'Norway - NO (028)',
    city: 'Sponvika',
    name: 'Svinesund N tollsted',
  },
  NO02061B: {
    country: 'Norway - NO (028)',
    city: 'ØRJE',
    name: 'Ørje tollsted',
  },
  NO02081D: {
    country: 'Norway - NO (028)',
    city: 'TÖCKSFORS',
    name: 'Hån',
  },
  NO02091D: {
    country: 'Norway - NO (028)',
    city: 'STRÖMSTAD',
    name: 'Svinesund Tullverket',
  },
  NO02101B: {
    country: 'Norway - NO (028)',
    city: 'KONGSVINGER',
    name: 'Kongsvinger tollsted',
  },
  NO02121B: {
    country: 'Norway - NO (028)',
    city: 'ØSTBY',
    name: 'Østby tollsted',
  },
  NO02141D: {
    country: 'Norway - NO (028)',
    city: 'CHARLOTTENBERG',
    name: 'Eda tullexpedition',
  },
  NO06011A: {
    country: 'Norway - NO (028)',
    city: 'KRISTIANSAND',
    name: 'Kristiansand tollsted',
  },
  NO06111B: {
    country: 'Norway - NO (028)',
    city: '3950 BREVIK',
    name: 'Grenland tollsted',
  },
  NO06121B: {
    country: 'Norway - NO (028)',
    city: 'SANDEFJORD',
    name: 'Sandefjord tollsted',
  },
  NO08011A: {
    country: 'Norway - NO (028)',
    city: 'BERGEN',
    name: 'Bergen tollsted',
  },
  NO08071B: {
    country: 'Norway - NO (028)',
    city: 'TANANGER',
    name: 'Stavanger tollsted',
  },
  NO08091B: {
    country: 'Norway - NO (028)',
    city: 'ÅLESUND',
    name: 'Ålesund tollsted',
  },
  NO09011A: {
    country: 'Norway - NO (028)',
    city: 'TRONDHEIM',
    name: 'Trondheim tollsted',
  },
  NO09101B: {
    country: 'Norway - NO (028)',
    city: 'BREKKEBYGD',
    name: 'Vauldalen tollsted',
  },
  NO09161D: {
    country: 'Norway - NO (028)',
    city: 'STORLIEN',
    name: 'Storlien tullstation',
  },
  NO09181B: {
    country: 'Norway - NO (028)',
    city: 'BODØ',
    name: 'Bodø tollsted',
  },
  NO09191B: {
    country: 'Norway - NO (028)',
    city: 'RØKLAND',
    name: 'Junkerdal tollsted',
  },
  NO09211B: {
    country: 'Norway - NO (028)',
    city: 'NARVIK',
    name: 'Narvik tollsted',
  },
  NO09211C: {
    country: 'Norway - NO (028)',
    city: 'NARVIK',
    name: 'Bjørnfjell - Tolletaten',
  },
  NO09231D: {
    country: 'Norway - NO (028)',
    city: 'TÄRNABY',
    name: 'Tärnaby tullstation',
  },
  NO11051B: {
    country: 'Norway - NO (028)',
    city: 'Kilpisjärvi, Finland',
    name: 'Helligskogen tollsted',
  },
  NO11121E: {
    country: 'Norway - NO (028)',
    city: 'Leppäjärvi',
    name: 'Kivilompolo tulli',
  },
  NO321001: {
    country: 'Norway - NO (028)',
    city: 'TROMSØ',
    name: 'Tromsø GNTR',
  },
  NO321002: {
    country: 'Norway - NO (028)',
    city: 'KILPISJÄRVI, FI',
    name: 'Helligskogen GNTR',
  },
  NO322001: {
    country: 'Norway - NO (028)',
    city: 'HAMMERFEST',
    name: 'Hammerfest GNVF',
  },
  NO323001: {
    country: 'Norway - NO (028)',
    city: 'NEIDEN',
    name: 'Neiden GNNE',
  },
  NO324001: {
    country: 'Norway - NO (028)',
    city: 'JARFJORD',
    name: 'Storskog GNST',
  },
  NO324002: {
    country: 'Norway - NO (028)',
    city: 'KIRKENES',
    name: 'Kirkenes simpl. procedure GNST',
  },
  NO325001: {
    country: 'Norway - NO (028)',
    city: 'TANA',
    name: 'Polmak GNPO',
  },
  NO329901: {
    country: 'Norway - NO (028)',
    city: 'KARIGASNIEMI, FI',
    name: 'Karigasniemi tulli GDN',
  },
  NO329902: {
    country: 'Norway - NO (028)',
    city: 'LEPPÄJÄRVI, FI',
    name: 'Kivilompolo GDN',
  },
  NO329903: {
    country: 'Norway - NO (028)',
    city: 'SIRMA',
    name: 'Utsjok tulli GDN',
  },
  NO331001: {
    country: 'Norway - NO (028)',
    city: 'NARVIK',
    name: 'Bjørnfjell GMBJ',
  },
  NO331002: {
    country: 'Norway - NO (028)',
    city: 'NARVIK',
    name: 'Narvik Simplified proc. GMBJ',
  },
  NO332001: {
    country: 'Norway - NO (028)',
    city: 'RØKLAND',
    name: 'Junkerdal GMHS',
  },
  NO332002: {
    country: 'Norway - NO (028)',
    city: 'BODØ',
    name: 'Bodø GMHS',
  },
  NO332003: {
    country: 'Norway - NO (028)',
    city: 'MO I RANA',
    name: 'Mo GMHS',
  },
  NO333001: {
    country: 'Norway - NO (028)',
    city: 'STJØRDAL',
    name: 'Værnes GDM',
  },
  NO333002: {
    country: 'Norway - NO (028)',
    city: 'TRONDHEIM',
    name: 'Trondheim GDM',
  },
  NO334001: {
    country: 'Norway - NO (028)',
    city: 'BREKKEBYGD',
    name: 'Vauldalen GMRØ',
  },
  NO339901: {
    country: 'Norway - NO (028)',
    city: 'STORLIEN',
    name: 'Storlien tullexpedisjon GMD',
  },
  NO339902: {
    country: 'Norway - NO (028)',
    city: 'TÄRNABY, SE',
    name: 'Tärnaby tullexpedition GMD',
  },
  NO341001: {
    country: 'Norway - NO (028)',
    city: 'ÅLESUND',
    name: 'Ålesund GVÅL',
  },
  NO342001: {
    country: 'Norway - NO (028)',
    city: 'BERGEN',
    name: 'Bergen GVMI Maritim innsatsseksjon',
  },
  NO344001: {
    country: 'Norway - NO (028)',
    city: 'Haugesund',
    name: 'Haugesund GVHA',
  },
  NO345001: {
    country: 'Norway - NO (028)',
    city: 'SOLA',
    name: 'Sola GVSO',
  },
  NO351001: {
    country: 'Norway - NO (028)',
    city: 'OSLO',
    name: 'Oslo Gardermoen GDG',
  },
  NO361001: {
    country: 'Norway - NO (028)',
    city: 'KRISTIANSAND',
    name: 'Kristiansand GDO',
  },
  NO362001: {
    country: 'Norway - NO (028)',
    city: 'BREVIK',
    name: 'Grenland GDO',
  },
  NO364001: {
    country: 'Norway - NO (028)',
    city: 'SANDEFJORD',
    name: 'Sandefjord GDO',
  },
  NO365001: {
    country: 'Norway - NO (028)',
    city: 'OSLO',
    name: 'Oslo Helsfyr Simpl. proc. GDO',
  },
  NO365002: {
    country: 'Norway - NO (028)',
    city: 'OSLO',
    name: 'Oslo Utstikker 2 GDO',
  },
  NO365003: {
    country: 'Norway - NO (028)',
    city: 'OSLO',
    name: 'Oslo-Hjortnes GDO',
  },
  NO368001: {
    country: 'Norway - NO (028)',
    city: 'DRAMMEN',
    name: 'Drammen GDO',
  },
  NO371001: {
    country: 'Norway - NO (028)',
    city: 'SPONVIKA',
    name: 'Svinesund GDØ',
  },
  NO372001: {
    country: 'Norway - NO (028)',
    city: 'ØRJE',
    name: 'Ørje GDØ',
  },
  NO373001: {
    country: 'Norway - NO (028)',
    city: 'KONGSVINGER',
    name: 'Kongsvinger GDØ',
  },
  NO373002: {
    country: 'Norway - NO (028)',
    city: 'ÅSNES',
    name: 'Åsnes GDØ',
  },
  NO374001: {
    country: 'Norway - NO (028)',
    city: 'ØSTBY',
    name: 'Østby GØTR',
  },
  NO379901: {
    country: 'Norway - NO (028)',
    city: 'STRÖMSTAD, SE',
    name: 'Svinesund tullexpedition GDØ',
  },
  NO379902: {
    country: 'Norway - NO (028)',
    city: 'TÖCKSFORS, SE',
    name: 'Hån tullexpedition GDØ',
  },
  NO379903: {
    country: 'Norway - NO (028)',
    city: 'CHARLOTTENBERG, SE',
    name: 'Eda tullexpedition GDØ',
  },
  NO379904: {
    country: 'Norway - NO (028)',
    city: 'IDRE, SE',
    name: 'Idre tullexpedition GDØ',
  },
  NO950TOD: {
    country: 'Norway - NO (028)',
    city: 'OSLO',
    name: 'Tolletaten',
  },
  PL021045: {
    country: 'Poland - PL (060)',
    city: 'Legnica',
    name: 'US w Legnicy',
  },
  PL022245: {
    country: 'Poland - PL (060)',
    city: 'Wałbrzych',
    name: 'US w Wałbrzychu',
  },
  PL022845: {
    country: 'Poland - PL (060)',
    city: 'Wrocław',
    name: 'US Wrocław-Śródmieście',
  },
  PL040643: {
    country: 'Poland - PL (060)',
    city: 'Bydgoszcz',
    name: 'Trzeci US w Bydgoszczy',
  },
  PL041743: {
    country: 'Poland - PL (060)',
    city: 'Toruń',
    name: 'Drugi US w Toruniu',
  },
  PL060230: {
    country: 'Poland - PL (060)',
    city: 'Biała Podlaska',
    name: 'US w Białej Podlaskiej',
  },
  PL061030: {
    country: 'Poland - PL (060)',
    city: 'Lublin',
    name: 'Pierwszy US w Lublinie',
  },
  PL062030: {
    country: 'Poland - PL (060)',
    city: 'Zamość',
    name: 'US w Zamościu',
  },
  PL080241: {
    country: 'Poland - PL (060)',
    city: 'Gorzów Wielkopolski',
    name: 'US w Gorzowie Wielkopolskim',
  },
  PL080841: {
    country: 'Poland - PL (060)',
    city: 'Zielona Góra',
    name: 'Pierwszy US w Zielonej Górze',
  },
  PL100936: {
    country: 'Poland - PL (060)',
    city: 'Łódź',
    name: 'Drugi US Łódź-Bałuty',
  },
  PL101036: {
    country: 'Poland - PL (060)',
    city: 'Łódź',
    name: 'Pierwszy US Łódź-Górna',
  },
  PL101736: {
    country: 'Poland - PL (060)',
    city: 'Piotrków Trybunalski',
    name: 'US w Piotrkowie Trybunalskim',
  },
  PL120935: {
    country: 'Poland - PL (060)',
    city: 'Kraków',
    name: 'US Kraków-Nowa Huta',
  },
  PL121735: {
    country: 'Poland - PL (060)',
    city: 'Nowy Sącz',
    name: 'US w Nowym Sączu',
  },
  PL140344: {
    country: 'Poland - PL (060)',
    city: 'Ciechanów',
    name: 'US w Ciechanowie',
  },
  PL142144: {
    country: 'Poland - PL (060)',
    city: 'Pruszków',
    name: 'US w Pruszkowie',
  },
  PL142444: {
    country: 'Poland - PL (060)',
    city: 'Radom',
    name: 'Pierwszy US w Radomiu',
  },
  PL142644: {
    country: 'Poland - PL (060)',
    city: 'Siedlce',
    name: 'US w Siedlcach',
  },
  PL143444: {
    country: 'Poland - PL (060)',
    city: 'Warszawa',
    name: 'US Warszawa-Praga',
  },
  PL143844: {
    country: 'Poland - PL (060)',
    city: 'Warszawa',
    name: 'US Warszawa-Ursynów',
  },
  PL161038: {
    country: 'Poland - PL (060)',
    city: 'Opole',
    name: 'Drugi US w Opolu',
  },
  PL180740: {
    country: 'Poland - PL (060)',
    city: 'Krosno',
    name: 'US w Krośnie',
  },
  PL181340: {
    country: 'Poland - PL (060)',
    city: 'Przemyśl',
    name: 'US w Przemyślu',
  },
  PL181640: {
    country: 'Poland - PL (060)',
    city: 'Rzeszów',
    name: 'Pierwszy US w Rzeszowie',
  },
  PL200331: {
    country: 'Poland - PL (060)',
    city: 'Białystok',
    name: 'Pierwszy US w Białymstoku',
  },
  PL200831: {
    country: 'Poland - PL (060)',
    city: 'Łomża',
    name: 'US w Łomży',
  },
  PL201231: {
    country: 'Poland - PL (060)',
    city: 'Suwałki',
    name: 'US w Suwałkach',
  },
  PL220532: {
    country: 'Poland - PL (060)',
    city: 'Gdańsk',
    name: 'Pierwszy US w Gdańsku',
  },
  PL220932: {
    country: 'Poland - PL (060)',
    city: 'Gdynia',
    name: 'Drugi US w Gdyni',
  },
  PL221632: {
    country: 'Poland - PL (060)',
    city: 'Słupsk',
    name: 'US w Słupsku',
  },
  PL240433: {
    country: 'Poland - PL (060)',
    city: 'Bielsko-Biała',
    name: 'Drugi US w Bielsku-Białej',
  },
  PL240933: {
    country: 'Poland - PL (060)',
    city: 'Częstochowa',
    name: 'Pierwszy US w Częstochowie',
  },
  PL241733: {
    country: 'Poland - PL (060)',
    city: 'Katowice',
    name: 'Drugi US w Katowicach',
  },
  PL242733: {
    country: 'Poland - PL (060)',
    city: 'Rybnik',
    name: 'US w Rybniku',
  },
  PL260534: {
    country: 'Poland - PL (060)',
    city: 'Kielce',
    name: 'Drugi US w Kielcach',
  },
  PL280537: {
    country: 'Poland - PL (060)',
    city: 'Elbląg',
    name: 'US w Elblągu',
  },
  PL281337: {
    country: 'Poland - PL (060)',
    city: 'Olsztyn',
    name: 'US w Olsztynie',
  },
  PL300000: {
    country: 'Poland - PL (060)',
    city: 'Lublin',
    name: 'IAS w Lublinie',
  },
  PL300839: {
    country: 'Poland - PL (060)',
    city: 'Kalisz',
    name: 'Drugi US w Kaliszu',
  },
  PL301000: {
    country: 'Poland - PL (060)',
    city: 'BIAŁA PODLASKA',
    name: 'Delegatura UCS w Białej Podlaskiej',
  },
  PL301010: {
    country: 'Poland - PL (060)',
    city: 'BIAŁA PODLASKA',
    name: 'BIAŁA PODLASKA OC',
  },
  PL301020: {
    country: 'Poland - PL (060)',
    city: 'MAŁASZEWICZE',
    name: 'MALASZEWICZE OC',
  },
  PL301040: {
    country: 'Poland - PL (060)',
    city: 'TERESPOL',
    name: 'KOROSZCZYN OC',
  },
  PL301050: {
    country: 'Poland - PL (060)',
    city: 'TERESPOL',
    name: 'TERESPOL OC',
  },
  PL301060: {
    country: 'Poland - PL (060)',
    city: 'TERESPOL',
    name: 'TERESPOL OC DROGOWY',
  },
  PL301070: {
    country: 'Poland - PL (060)',
    city: 'SŁAWATYCZE',
    name: 'SŁAWATYCZE OC',
  },
  PL301439: {
    country: 'Poland - PL (060)',
    city: 'Leszno',
    name: 'US w Lesznie',
  },
  PL301939: {
    country: 'Poland - PL (060)',
    city: 'Piła',
    name: 'US w Pile',
  },
  PL302000: {
    country: 'Poland - PL (060)',
    city: 'LUBLIN',
    name: 'Delegatura UCS w Lublinie',
  },
  PL302010: {
    country: 'Poland - PL (060)',
    city: 'LUBLIN',
    name: 'LUBLIN OC',
  },
  PL302040: {
    country: 'Poland - PL (060)',
    city: 'CHEŁM',
    name: 'CHEŁM OC',
  },
  PL302050: {
    country: 'Poland - PL (060)',
    city: 'DOROHUSK',
    name: 'DOROHUSK OC',
  },
  PL302060: {
    country: 'Poland - PL (060)',
    city: 'DOROHUSK',
    name: 'DOROHUSK OC DROGOWY',
  },
  PL302070: {
    country: 'Poland - PL (060)',
    city: 'ŚWIDNIK',
    name: 'OC PORT LOTNICZY LUBLIN-ŚWIDNIK',
  },
  PL302080: {
    country: 'Poland - PL (060)',
    city: 'Lublin',
    name: 'Lublin OC Pocztowy',
  },
  PL302239: {
    country: 'Poland - PL (060)',
    city: 'Poznań',
    name: 'US Poznań-Nowe Miasto',
  },
  PL303000: {
    country: 'Poland - PL (060)',
    city: 'ZAMOŚĆ',
    name: 'Delegatura UCS w Zamościu',
  },
  PL303010: {
    country: 'Poland - PL (060)',
    city: 'ZAMOŚĆ',
    name: 'ZAMOŚĆ OC',
  },
  PL303020: {
    country: 'Poland - PL (060)',
    city: 'LUBYCZA KRÓLEWSKA',
    name: 'HREBENNE OC',
  },
  PL303030: {
    country: 'Poland - PL (060)',
    city: 'HRUBIESZÓW',
    name: 'HRUBIESZÓW OC',
  },
  PL303040: {
    country: 'Poland - PL (060)',
    city: 'HORODŁO',
    name: 'ZOSIN OC',
  },
  PL303080: {
    country: 'Poland - PL (060)',
    city: 'TOMASZÓW LUBELSKI',
    name: 'TOMASZÓW LUBELSKI OC',
  },
  PL303091: {
    country: 'Poland - PL (060)',
    city: 'DOŁHOBYCZÓW',
    name: 'DOŁHOBYCZÓW OC',
  },
  PL308000: {
    country: 'Poland - PL (060)',
    city: 'Biała Podlaska',
    name: 'Lubelski UCS w Białej Podlaskiej',
  },
  PL310000: {
    country: 'Poland - PL (060)',
    city: 'Białystok',
    name: 'IAS w Białymstoku',
  },
  PL311000: {
    country: 'Poland - PL (060)',
    city: 'BIAŁYSTOK',
    name: 'Delegatura UCS w Białymstoku',
  },
  PL311010: {
    country: 'Poland - PL (060)',
    city: 'BIAŁYSTOK',
    name: 'BIAŁYSTOK OC',
  },
  PL311020: {
    country: 'Poland - PL (060)',
    city: 'KUŹNICA BIAŁOSTOCKA',
    name: 'KUŹNICA OC KOLEJOWY',
  },
  PL311030: {
    country: 'Poland - PL (060)',
    city: 'KUŹNICA BIAŁOSTOCKA',
    name: 'KUŹNICA OC DROGOWY',
  },
  PL311050: {
    country: 'Poland - PL (060)',
    city: 'NAREWKA',
    name: 'SIEMIANÓWKA OC',
  },
  PL311060: {
    country: 'Poland - PL (060)',
    city: 'CZEREMCHA',
    name: 'POŁOWCE OC',
  },
  PL311070: {
    country: 'Poland - PL (060)',
    city: 'GRÓDEK',
    name: 'BOBROWNIKI OC',
  },
  PL312000: {
    country: 'Poland - PL (060)',
    city: 'ŁOMŻA',
    name: 'Delegatura UCS w Łomży',
  },
  PL312010: {
    country: 'Poland - PL (060)',
    city: 'ŁOMŻA',
    name: 'ŁOMŻA OC',
  },
  PL313000: {
    country: 'Poland - PL (060)',
    city: 'SUWAŁKI',
    name: 'Delegatura UCS w Suwałkach',
  },
  PL313010: {
    country: 'Poland - PL (060)',
    city: 'SUWAŁKI',
    name: 'SUWAŁKI OC',
  },
  PL313020: {
    country: 'Poland - PL (060)',
    city: 'AUGUSTÓW',
    name: 'AUGUSTÓW OC',
  },
  PL318000: {
    country: 'Poland - PL (060)',
    city: 'Białystok',
    name: 'Podlaski UCS w Białymstoku',
  },
  PL320000: {
    country: 'Poland - PL (060)',
    city: 'Gdańsk',
    name: 'IAS w Gdańsku',
  },
  PL321000: {
    country: 'Poland - PL (060)',
    city: 'GDYNIA',
    name: 'Delegatura UCS w Gdyni',
  },
  PL321010: {
    country: 'Poland - PL (060)',
    city: 'GDYNIA',
    name: 'GDYNIA OC "BASEN V"',
  },
  PL321030: {
    country: 'Poland - PL (060)',
    city: 'GDYNIA',
    name: 'GDYNIA OC "BAZA KONTENEROWA"',
  },
  PL321050: {
    country: 'Poland - PL (060)',
    city: 'GDYNIA',
    name: 'GDYNIA OC "BASEN IV"',
  },
  PL321070: {
    country: 'Poland - PL (060)',
    city: 'GDYNIA',
    name: 'GDYNIA OC "NABRZEŻE BUŁGARSKIE"',
  },
  PL321142: {
    country: 'Poland - PL (060)',
    city: 'Koszalin',
    name: 'Drugi US w Koszalinie',
  },
  PL321542: {
    country: 'Poland - PL (060)',
    city: 'Szczecin',
    name: 'Pierwszy US w Szczecinie',
  },
  PL322000: {
    country: 'Poland - PL (060)',
    city: 'GDAŃSK',
    name: 'Delegatura UCS w Gdańsku',
  },
  PL322010: {
    country: 'Poland - PL (060)',
    city: 'GDAŃSK',
    name: 'GDAŃSK OC "OPŁOTKI"',
  },
  PL322020: {
    country: 'Poland - PL (060)',
    city: 'GDAŃSK',
    name: 'GDAŃSK OC "NABRZEŻE WIŚLANE"',
  },
  PL322030: {
    country: 'Poland - PL (060)',
    city: 'GDAŃSK',
    name: 'GDAŃSK OC "BASEN WŁADYSŁAWA IV"',
  },
  PL322050: {
    country: 'Poland - PL (060)',
    city: 'GDAŃSK',
    name: 'GDAŃSK-RĘBIECHOWO OC PORT LOTNICZY',
  },
  PL322060: {
    country: 'Poland - PL (060)',
    city: 'TCZEW',
    name: 'TCZEW OC',
  },
  PL322070: {
    country: 'Poland - PL (060)',
    city: 'KWIDZYN',
    name: 'KWIDZYN OC',
  },
  PL322080: {
    country: 'Poland - PL (060)',
    city: 'GDAŃSK',
    name: 'GDAŃSK OC "TERMINAL KONTENEROWY"',
  },
  PL322090: {
    country: 'Poland - PL (060)',
    city: 'PRUSZCZ GDAŃSKI',
    name: 'PRUSZCZ GDAŃSKI OC POCZTOWY',
  },
  PL323000: {
    country: 'Poland - PL (060)',
    city: 'SŁUPSK',
    name: 'Delegatura UCS w Słupsku',
  },
  PL323010: {
    country: 'Poland - PL (060)',
    city: 'SŁUPSK',
    name: 'SŁUPSK OC',
  },
  PL328000: {
    country: 'Poland - PL (060)',
    city: 'Gdynia',
    name: 'Pomorski UCS w Gdyni',
  },
  PL330000: {
    country: 'Poland - PL (060)',
    city: 'Katowice',
    name: 'IAS w Katowicach',
  },
  PL331000: {
    country: 'Poland - PL (060)',
    city: 'KATOWICE',
    name: 'Delegatura UCS w Katowicach',
  },
  PL331020: {
    country: 'Poland - PL (060)',
    city: 'TYCHY',
    name: 'TYCHY OC',
  },
  PL331030: {
    country: 'Poland - PL (060)',
    city: 'SŁAWKÓW',
    name: 'SŁAWKÓW OC',
  },
  PL331040: {
    country: 'Poland - PL (060)',
    city: 'OŻAROWICE',
    name: 'OC TOWAROWY PORT LOTNICZY PYRZOWICE',
  },
  PL331050: {
    country: 'Poland - PL (060)',
    city: 'OŻAROWICE',
    name: 'OC OSOBOWY PORT LOTNICZY PYRZOWICE',
  },
  PL332000: {
    country: 'Poland - PL (060)',
    city: 'RYBNIK',
    name: 'Delegatura UCS w Rybniku',
  },
  PL332010: {
    country: 'Poland - PL (060)',
    city: 'GLIWICE',
    name: 'GLIWICE OC',
  },
  PL332040: {
    country: 'Poland - PL (060)',
    city: 'ZABRZE',
    name: 'ZABRZE OC POCZTOWY',
  },
  PL333000: {
    country: 'Poland - PL (060)',
    city: 'CZĘSTOCHOWA',
    name: 'Delegatura UCS w Częstochowie',
  },
  PL333010: {
    country: 'Poland - PL (060)',
    city: 'CZĘSTOCHOWA',
    name: 'CZĘSTOCHOWA OC',
  },
  PL335000: {
    country: 'Poland - PL (060)',
    city: 'BIELSKO-BIAŁA',
    name: 'Delegatura UCS w Bielsku-Białej',
  },
  PL335010: {
    country: 'Poland - PL (060)',
    city: 'CZECHOWICE-DZIEDZICE',
    name: 'CZECHOWICE-DZIEDZICE OC',
  },
  PL338000: {
    country: 'Poland - PL (060)',
    city: 'Katowice',
    name: 'Śląski UCS w Katowicach',
  },
  PL340000: {
    country: 'Poland - PL (060)',
    city: 'Kielce',
    name: 'IAS w Kielcach',
  },
  PL341000: {
    country: 'Poland - PL (060)',
    city: 'KIELCE',
    name: 'Delegatura UCS w Kielcach',
  },
  PL341010: {
    country: 'Poland - PL (060)',
    city: 'KIELCE',
    name: 'KIELCE OC',
  },
  PL341020: {
    country: 'Poland - PL (060)',
    city: 'STARACHOWICE',
    name: 'STARACHOWICE OC',
  },
  PL341030: {
    country: 'Poland - PL (060)',
    city: 'SANDOMIERZ',
    name: 'SANDOMIERZ OC',
  },
  PL348000: {
    country: 'Poland - PL (060)',
    city: 'Kielce',
    name: 'Świętokrzyski UCS w Kielcach',
  },
  PL350000: {
    country: 'Poland - PL (060)',
    city: 'Kraków',
    name: 'IAS w Krakowie',
  },
  PL351000: {
    country: 'Poland - PL (060)',
    city: 'KRAKÓW',
    name: 'Delegatura UCS w Krakowie',
  },
  PL351020: {
    country: 'Poland - PL (060)',
    city: 'KRAKÓW',
    name: 'KRAKÓW OC II',
  },
  PL351030: {
    country: 'Poland - PL (060)',
    city: 'BALICE',
    name: 'KRAKÓW-BALICE OC PORT LOTNICZY',
  },
  PL351050: {
    country: 'Poland - PL (060)',
    city: 'CHYŻNE',
    name: 'CHYŻNE OC',
  },
  PL351060: {
    country: 'Poland - PL (060)',
    city: 'ANDRYCHÓW',
    name: 'ANDRYCHÓW OC',
  },
  PL353000: {
    country: 'Poland - PL (060)',
    city: 'NOWY SĄCZ',
    name: 'Delegatura UCS w Nowym Sączu',
  },
  PL353010: {
    country: 'Poland - PL (060)',
    city: 'NOWY SĄCZ',
    name: 'NOWY SĄCZ OC',
  },
  PL353030: {
    country: 'Poland - PL (060)',
    city: 'TARNÓW',
    name: 'TARNÓW OC',
  },
  PL358000: {
    country: 'Poland - PL (060)',
    city: 'Kraków',
    name: 'Małopolski UCS w Krakowie',
  },
  PL360000: {
    country: 'Poland - PL (060)',
    city: 'Łódź',
    name: 'IAS w Łodzi',
  },
  PL360001: {
    country: 'Poland - PL (060)',
    city: 'ŁÓDŹ',
    name: 'CENTRALNE BIURO TRANZYTU',
  },
  PL361000: {
    country: 'Poland - PL (060)',
    city: 'ŁÓDŹ',
    name: 'Delegatura UCS I w Łodzi',
  },
  PL361010: {
    country: 'Poland - PL (060)',
    city: 'ŁÓDŹ',
    name: 'ŁÓDŹ OC I',
  },
  PL361030: {
    country: 'Poland - PL (060)',
    city: 'SIERADZ',
    name: 'SIERADZ OC',
  },
  PL362000: {
    country: 'Poland - PL (060)',
    city: 'ŁÓDŹ',
    name: 'Delegatura UCS II w Łodzi',
  },
  PL362010: {
    country: 'Poland - PL (060)',
    city: 'ŁÓDŹ',
    name: 'ŁÓDŹ OC II',
  },
  PL362030: {
    country: 'Poland - PL (060)',
    city: 'KUTNO',
    name: 'KUTNO OC',
  },
  PL362040: {
    country: 'Poland - PL (060)',
    city: 'ŁÓDŹ',
    name: 'LUBLINEK - OC PORT LOTNICZY',
  },
  PL363000: {
    country: 'Poland - PL (060)',
    city: 'PIOTRKÓW TRABUNALSKI',
    name: 'Delegatura UCS w Piotrkowie Trybun.',
  },
  PL363010: {
    country: 'Poland - PL (060)',
    city: 'PIOTRKÓW TRYBUNALSKI',
    name: 'PIOTRKÓW TRYBUNALSKI OC',
  },
  PL368000: {
    country: 'Poland - PL (060)',
    city: 'Łódź',
    name: 'Łódzki UCS w Łodzi',
  },
  PL370000: {
    country: 'Poland - PL (060)',
    city: 'Olsztyn',
    name: 'IAS w Olsztynie',
  },
  PL371000: {
    country: 'Poland - PL (060)',
    city: 'OLSZTYN',
    name: 'Delegatura UCS w Olsztynie',
  },
  PL371010: {
    country: 'Poland - PL (060)',
    city: 'OLSZTYN',
    name: 'OLSZTYN OC',
  },
  PL371020: {
    country: 'Poland - PL (060)',
    city: 'KORSZE',
    name: 'KORSZE OC',
  },
  PL371030: {
    country: 'Poland - PL (060)',
    city: 'BEZLEDY',
    name: 'BEZLEDY OC',
  },
  PL371040: {
    country: 'Poland - PL (060)',
    city: 'BARTOSZYCE',
    name: 'BARTOSZYCE OC',
  },
  PL371050: {
    country: 'Poland - PL (060)',
    city: 'EŁK',
    name: 'EŁK OC',
  },
  PL371060: {
    country: 'Poland - PL (060)',
    city: 'GOŁDAP',
    name: 'GOŁDAP OC',
  },
  PL372000: {
    country: 'Poland - PL (060)',
    city: 'ELBLĄG',
    name: 'Delegatura UCS w Elblągu',
  },
  PL372010: {
    country: 'Poland - PL (060)',
    city: 'ELBLĄG',
    name: 'ELBLĄG OC',
  },
  PL372020: {
    country: 'Poland - PL (060)',
    city: 'BRANIEWO',
    name: 'BRANIEWO OC',
  },
  PL372030: {
    country: 'Poland - PL (060)',
    city: 'BRANIEWO',
    name: 'GRONOWO OC',
  },
  PL372040: {
    country: 'Poland - PL (060)',
    city: 'IŁAWA',
    name: 'IŁAWA OC',
  },
  PL372050: {
    country: 'Poland - PL (060)',
    city: 'BRANIEWO',
    name: 'GRZECHOTKI OC',
  },
  PL378000: {
    country: 'Poland - PL (060)',
    city: 'Olsztyn',
    name: 'Warmińsko-Mazurski UCS w Olsztynie',
  },
  PL380000: {
    country: 'Poland - PL (060)',
    city: 'Opole',
    name: 'IAS w Opolu',
  },
  PL381000: {
    country: 'Poland - PL (060)',
    city: 'OPOLE',
    name: 'Delegatura UCS w Opolu',
  },
  PL381010: {
    country: 'Poland - PL (060)',
    city: 'OPOLE',
    name: 'OPOLE OC',
  },
  PL381040: {
    country: 'Poland - PL (060)',
    city: 'NYSA',
    name: 'NYSA OC',
  },
  PL388000: {
    country: 'Poland - PL (060)',
    city: 'Opole',
    name: 'Opolski UCS w Opolu',
  },
  PL390000: {
    country: 'Poland - PL (060)',
    city: 'Poznań',
    name: 'IAS w Poznaniu',
  },
  PL391000: {
    country: 'Poland - PL (060)',
    city: 'POZNAŃ',
    name: 'Delegatura UCS w Poznaniu',
  },
  PL391010: {
    country: 'Poland - PL (060)',
    city: 'POZNAŃ',
    name: 'POZNAŃ OC',
  },
  PL391020: {
    country: 'Poland - PL (060)',
    city: 'POZNAŃ',
    name: 'POZNAŃ OC MTP',
  },
  PL391030: {
    country: 'Poland - PL (060)',
    city: 'POZNAŃ',
    name: 'POZNAŃ-ŁAWICA OC PORT LOTNICZY',
  },
  PL391040: {
    country: 'Poland - PL (060)',
    city: 'GĄDKI',
    name: 'GĄDKI OC',
  },
  PL392000: {
    country: 'Poland - PL (060)',
    city: 'PIŁA',
    name: 'Delegatura UCS w Pile',
  },
  PL392010: {
    country: 'Poland - PL (060)',
    city: 'PIŁA',
    name: 'PIŁA OC',
  },
  PL393000: {
    country: 'Poland - PL (060)',
    city: 'LESZNO',
    name: 'Delegatura UCS w Lesznie',
  },
  PL393010: {
    country: 'Poland - PL (060)',
    city: 'LESZNO',
    name: 'LESZNO OC',
  },
  PL393020: {
    country: 'Poland - PL (060)',
    city: 'NOWY TOMYŚL',
    name: 'NOWY TOMYŚL OC',
  },
  PL394000: {
    country: 'Poland - PL (060)',
    city: 'KALISZ',
    name: 'Delegatura UCS w Kaliszu',
  },
  PL394010: {
    country: 'Poland - PL (060)',
    city: 'KALISZ',
    name: 'KALISZ OC',
  },
  PL394020: {
    country: 'Poland - PL (060)',
    city: 'STARE MIASTO',
    name: 'KONIN OC',
  },
  PL398000: {
    country: 'Poland - PL (060)',
    city: 'Poznań',
    name: 'Wielkopolski UCS w Poznaniu',
  },
  PL400000: {
    country: 'Poland - PL (060)',
    city: 'Rzeszów',
    name: 'IAS w Rzeszowie',
  },
  PL401000: {
    country: 'Poland - PL (060)',
    city: 'PRZEMYŚL',
    name: 'Delegatura UCS w Przemyślu',
  },
  PL401010: {
    country: 'Poland - PL (060)',
    city: 'PRZEMYŚL',
    name: 'PRZEMYŚL OC',
  },
  PL401030: {
    country: 'Poland - PL (060)',
    city: 'MEDYKA',
    name: 'MEDYKA OC',
  },
  PL401040: {
    country: 'Poland - PL (060)',
    city: 'MEDYKA',
    name: 'PRZEMYŚL-MEDYKA OC KOLEJOWY',
  },
  PL401050: {
    country: 'Poland - PL (060)',
    city: 'JAROSŁAW',
    name: 'JAROSŁAW OC',
  },
  PL401060: {
    country: 'Poland - PL (060)',
    city: 'MŁYNY',
    name: 'KORCZOWA OC',
  },
  PL401080: {
    country: 'Poland - PL (060)',
    city: 'BUDOMIERZ',
    name: 'BUDOMIERZ OC',
  },
  PL401090: {
    country: 'Poland - PL (060)',
    city: 'PRZEMYŚL',
    name: 'PRZEMYŚL OC POCZTOWY',
  },
  PL402000: {
    country: 'Poland - PL (060)',
    city: 'RZESZÓW',
    name: 'Delegatura UCS w Rzeszowie',
  },
  PL402010: {
    country: 'Poland - PL (060)',
    city: 'RZESZÓW',
    name: 'RZESZÓW OC',
  },
  PL402020: {
    country: 'Poland - PL (060)',
    city: 'JASIONKA',
    name: 'RZESZÓW-JASIONKA OC PORT LOTNICZY',
  },
  PL402040: {
    country: 'Poland - PL (060)',
    city: 'DĘBICA',
    name: 'DĘBICA OC',
  },
  PL402050: {
    country: 'Poland - PL (060)',
    city: 'STALOWA WOLA',
    name: 'STALOWA WOLA OC',
  },
  PL402060: {
    country: 'Poland - PL (060)',
    city: 'MIELEC',
    name: 'MIELEC OC',
  },
  PL404000: {
    country: 'Poland - PL (060)',
    city: 'KROSNO',
    name: 'Delegatura UCS w Krośnie',
  },
  PL404010: {
    country: 'Poland - PL (060)',
    city: 'KROSNO',
    name: 'KROSNO OC',
  },
  PL404030: {
    country: 'Poland - PL (060)',
    city: 'USTRZYKI DOLNE',
    name: 'KROŚCIENKO OC',
  },
  PL408000: {
    country: 'Poland - PL (060)',
    city: 'Przemyśl',
    name: 'Podkarpacki UCS w Przemyślu',
  },
  PL410000: {
    country: 'Poland - PL (060)',
    city: 'Zielona Góra',
    name: 'IAS w Zielonej Górze',
  },
  PL411000: {
    country: 'Poland - PL (060)',
    city: 'Zielona Góra',
    name: 'Delegatura UCS w Zielonej Górze',
  },
  PL411010: {
    country: 'Poland - PL (060)',
    city: 'Zielona Góra',
    name: 'ZIELONA GÓRA OC',
  },
  PL411020: {
    country: 'Poland - PL (060)',
    city: 'OLSZYNA',
    name: 'OLSZYNA OC',
  },
  PL412000: {
    country: 'Poland - PL (060)',
    city: 'GORZÓW WIELKOPOLSKI',
    name: 'Delegatura UCS w Rzepinie',
  },
  PL412010: {
    country: 'Poland - PL (060)',
    city: 'GORZÓW WIELKOPOLSKI',
    name: 'GORZÓW WIELKOPOLSKI OC',
  },
  PL412020: {
    country: 'Poland - PL (060)',
    city: 'Słubice',
    name: 'ŚWIECKO OC',
  },
  PL418000: {
    country: 'Poland - PL (060)',
    city: 'Gorzów Wielkopolski',
    name: 'Lubuski UCS w Gorzowie Wielkop.',
  },
  PL420000: {
    country: 'Poland - PL (060)',
    city: 'Szczecin',
    name: 'IAS w Szczecinie',
  },
  PL421000: {
    country: 'Poland - PL (060)',
    city: 'SZCZECIN',
    name: 'Delegatura UCS w Szczecinie',
  },
  PL421010: {
    country: 'Poland - PL (060)',
    city: 'SZCZECIN',
    name: 'SZCZECIN OC',
  },
  PL421030: {
    country: 'Poland - PL (060)',
    city: 'SZCZECIN',
    name: 'SZCZECIN OC "NABRZEŻE ŁASZTOWNIA"',
  },
  PL421050: {
    country: 'Poland - PL (060)',
    city: 'GOLENIÓW',
    name: 'SZCZECIN-GOLENIÓW OC PORT LOTNICZY',
  },
  PL421080: {
    country: 'Poland - PL (060)',
    city: 'ŚWINOUJŚCIE',
    name: 'SWINOUJŚCIE OC',
  },
  PL422000: {
    country: 'Poland - PL (060)',
    city: 'KOSZALIN',
    name: 'Delegatura UCS w Koszalinie',
  },
  PL422010: {
    country: 'Poland - PL (060)',
    city: 'KOSZALIN',
    name: 'KOSZALIN OC',
  },
  PL422020: {
    country: 'Poland - PL (060)',
    city: 'KOŁOBRZEG',
    name: 'KOŁOBRZEG OC',
  },
  PL422030: {
    country: 'Poland - PL (060)',
    city: 'SZCZECINEK',
    name: 'SZCZECINEK OC',
  },
  PL428000: {
    country: 'Poland - PL (060)',
    city: 'Szczecin',
    name: 'Zachodniopomorski UCS w Szczecinie',
  },
  PL430000: {
    country: 'Poland - PL (060)',
    city: 'Bydgoszcz',
    name: 'IAS w Bydgoszczy',
  },
  PL431000: {
    country: 'Poland - PL (060)',
    city: 'BYDGOSZCZ',
    name: 'Delegatura UCS w Bydgoszczy',
  },
  PL431020: {
    country: 'Poland - PL (060)',
    city: 'BYDGOSZCZ',
    name: 'BYDGOSZCZ OC II',
  },
  PL431030: {
    country: 'Poland - PL (060)',
    city: 'INOWROCŁAW',
    name: 'INOWROCŁAW OC',
  },
  PL431040: {
    country: 'Poland - PL (060)',
    city: 'Białe Błota',
    name: 'OC Port Lotniczy Bydgoszcz',
  },
  PL432000: {
    country: 'Poland - PL (060)',
    city: 'TORUŃ',
    name: 'Delegatura UCS w Toruniu',
  },
  PL432010: {
    country: 'Poland - PL (060)',
    city: 'TORUŃ',
    name: 'TORUŃ OC',
  },
  PL432030: {
    country: 'Poland - PL (060)',
    city: 'WŁOCŁAWEK',
    name: 'WŁOCŁAWEK OC',
  },
  PL432040: {
    country: 'Poland - PL (060)',
    city: 'GRUDZIĄDZ',
    name: 'GRUDZIĄDZ OC',
  },
  PL438000: {
    country: 'Poland - PL (060)',
    city: 'Toruń',
    name: 'Kujawsko-Pomorski UCS w Toruniu',
  },
  PL440000: {
    country: 'Poland - PL (060)',
    city: 'Warszawa',
    name: 'IAS w Warszawie',
  },
  PL441000: {
    country: 'Poland - PL (060)',
    city: 'WARSZAWA',
    name: 'Delegatura UCS I w Warszawie',
  },
  PL441010: {
    country: 'Poland - PL (060)',
    city: 'WARSZAWA',
    name: 'WARSZAWA OC I POCZTOWY',
  },
  PL441040: {
    country: 'Poland - PL (060)',
    city: 'WARSZAWA',
    name: 'WARSZAWA OC IV',
  },
  PL442000: {
    country: 'Poland - PL (060)',
    city: 'WARSZAWA',
    name: 'Delegatura UCS II w Warszawie',
  },
  PL442010: {
    country: 'Poland - PL (060)',
    city: 'WARSZAWA',
    name: 'WARSZAWA OC V',
  },
  PL442020: {
    country: 'Poland - PL (060)',
    city: 'WARSZAWA',
    name: 'WARSZAWA OC VI',
  },
  PL443000: {
    country: 'Poland - PL (060)',
    city: 'WARSZAWA',
    name: 'Deleg UCS III „P.Lotn” w Warszawie',
  },
  PL443010: {
    country: 'Poland - PL (060)',
    city: 'WARSZAWA',
    name: 'WARSZAWA OC OSOBOWY',
  },
  PL443020: {
    country: 'Poland - PL (060)',
    city: 'WARSZAWA',
    name: 'WARSZAWA OC TOWAROWY I',
  },
  PL443050: {
    country: 'Poland - PL (060)',
    city: 'NOWY DWÓR MAZOWIECKI',
    name: 'WARSZAWA OC PORT LOTNICZY MODLIN',
  },
  PL444000: {
    country: 'Poland - PL (060)',
    city: 'RADOM',
    name: 'Delegatura UCS w Radomiu',
  },
  PL444010: {
    country: 'Poland - PL (060)',
    city: 'RADOM',
    name: 'RADOM OC',
  },
  PL444020: {
    country: 'Poland - PL (060)',
    city: 'GRÓJEC',
    name: 'GRÓJEC OC',
  },
  PL445000: {
    country: 'Poland - PL (060)',
    city: 'PRUSZKÓW',
    name: 'Delegatura UCS w Pruszkowie',
  },
  PL445010: {
    country: 'Poland - PL (060)',
    city: 'PRUSZKÓW',
    name: 'PRUSZKÓW OC I',
  },
  PL445050: {
    country: 'Poland - PL (060)',
    city: 'PŁOCK',
    name: 'PŁOCK OC',
  },
  PL446000: {
    country: 'Poland - PL (060)',
    city: 'SIEDLCE',
    name: 'Delegatura UCS w Siedlcach',
  },
  PL446010: {
    country: 'Poland - PL (060)',
    city: 'SIEDLCE',
    name: 'SIEDLCE OC',
  },
  PL446020: {
    country: 'Poland - PL (060)',
    city: 'WOLA RĘBKOWSKA',
    name: 'GARWOLIN OC',
  },
  PL447000: {
    country: 'Poland - PL (060)',
    city: 'CIECHANÓW',
    name: 'Delegatura UCS w Ciechanowie',
  },
  PL447010: {
    country: 'Poland - PL (060)',
    city: 'CIECHANÓW',
    name: 'CIECHANÓW OC',
  },
  PL447020: {
    country: 'Poland - PL (060)',
    city: 'OSTROŁĘKA',
    name: 'OSTROŁĘKA OC',
  },
  PL448000: {
    country: 'Poland - PL (060)',
    city: 'Warszawa',
    name: 'Mazowiecki UCS w Warszawie',
  },
  PL450000: {
    country: 'Poland - PL (060)',
    city: 'Wrocław',
    name: 'IAS we Wrocławiu',
  },
  PL451000: {
    country: 'Poland - PL (060)',
    city: 'Wrocław',
    name: 'Delegatura UCS we Wrocławiu',
  },
  PL451010: {
    country: 'Poland - PL (060)',
    city: 'WROCŁAW',
    name: 'WROCŁAW OC I',
  },
  PL451030: {
    country: 'Poland - PL (060)',
    city: 'WROCŁAW',
    name: 'WROCŁAW-STRACHOWICE OC TOWAROWY',
  },
  PL451040: {
    country: 'Poland - PL (060)',
    city: 'WROCŁAW',
    name: 'WROCŁAW-STRACHOWICE OC OSOBOWY',
  },
  PL452000: {
    country: 'Poland - PL (060)',
    city: 'LEGNICA',
    name: 'Delegatura UCS w Legnicy',
  },
  PL452010: {
    country: 'Poland - PL (060)',
    city: 'LEGNICA',
    name: 'LEGNICA OC',
  },
  PL452020: {
    country: 'Poland - PL (060)',
    city: 'POLKOWICE',
    name: 'POLKOWICE OC',
  },
  PL452030: {
    country: 'Poland - PL (060)',
    city: 'ZGORZELEC',
    name: 'ŻARSKA WIEŚ OC',
  },
  PL454000: {
    country: 'Poland - PL (060)',
    city: 'WAŁBRZYCH',
    name: 'Delegatura UCS w Wałbrzychu',
  },
  PL454010: {
    country: 'Poland - PL (060)',
    city: 'WAŁBRZYCH',
    name: 'WAŁBRZYCH OC',
  },
  PL458000: {
    country: 'Poland - PL (060)',
    city: 'Wrocław',
    name: 'Dolnośląski UCS we Wrocławiu',
  },
  PT000005: {
    country: 'Portugal - PT (010)',
    city: 'FARO',
    name: 'Delegação Aduaneira Aeroporto Faro FARO',
  },
  PT000006: {
    country: 'Portugal - PT (010)',
    city: 'Beja',
    name: 'Posto Aduaneiro Aeroporto de Beja Beja',
  },
  PT000010: {
    country: 'Portugal - PT (010)',
    city: 'SANTA CRUZ',
    name: 'Delegação Adu. Aeroporto da Madeira FUNCHAL',
  },
  PT000015: {
    country: 'Portugal - PT (010)',
    city: 'Lisboa',
    name: 'Alfândega do Aeroporto de Lisboa LISBOA',
  },
  PT000020: {
    country: 'Portugal - PT (010)',
    city: 'Porto',
    name: 'Alfândega do Aeroporto do Porto PORTO',
  },
  PT000025: {
    country: 'Portugal - PT (010)',
    city: 'Porto Santo',
    name: 'Delegação Aduaneira de Porto Santo MADEIRA',
  },
  PT000029: {
    country: 'Portugal - PT (010)',
    city: 'Ponta Delgada',
    name: 'Posto Aduaneiro Aeroporto P.Delgada Ponta Delgada',
  },
  PT000030: {
    country: 'Portugal - PT (010)',
    city: 'VILA DO PORTO',
    name: 'Delegação Ad. Aeroporto Santa Maria AÇORES',
  },
  PT000040: {
    country: 'Portugal - PT (010)',
    city: 'LISBOA',
    name: 'Alfândega Marítima de Lisboa LISBOA',
  },
  PT000070: {
    country: 'Portugal - PT (010)',
    city: 'Funchal',
    name: 'Alfândega do Funchal MADEIRA',
  },
  PT000080: {
    country: 'Portugal - PT (010)',
    city: 'Ponta Delgada',
    name: 'Alfândega de Ponta Delgada AÇORES',
  },
  PT000088: {
    country: 'Portugal - PT (010)',
    city: 'Alverca do Ribatejo',
    name: 'Alfândega de Alverca LISBOA',
  },
  PT000095: {
    country: 'Portugal - PT (010)',
    city: 'Angra do Heroísmo',
    name: 'Delegação Adua. Angra do Heroísmo AÇORES',
  },
  PT000098: {
    country: 'Portugal - PT (010)',
    city: 'Bobadela',
    name: 'Posto Aduaneiro da Bobadela LISBOA',
  },
  PT000115: {
    country: 'Portugal - PT (010)',
    city: 'Aveiro',
    name: 'Alfândega de Aveiro AVEIRO',
  },
  PT000164: {
    country: 'Portugal - PT (010)',
    city: 'Braga',
    name: 'Alfândega de Braga BRAGA',
  },
  PT000167: {
    country: 'Portugal - PT (010)',
    city: 'Bragança',
    name: 'Delegação Aduaneira de Bragança',
  },
  PT000170: {
    country: 'Portugal - PT (010)',
    city: 'Elvas',
    name: 'Delegação Aduaneira de Elvas ELVAS',
  },
  PT000227: {
    country: 'Portugal - PT (010)',
    city: 'Covilhã',
    name: 'Delegação Aduaneira da Covilhã COVILHÃ',
  },
  PT000236: {
    country: 'Portugal - PT (010)',
    city: 'Lisboa',
    name: 'Delegação Aduan. Encomendas Postais',
  },
  PT000265: {
    country: 'Portugal - PT (010)',
    city: 'Faro',
    name: 'Alfândega de Faro FARO',
  },
  PT000275: {
    country: 'Portugal - PT (010)',
    city: 'Figueira da Foz',
    name: 'Delegação Aduaneira Figueira da Foz',
  },
  PT000284: {
    country: 'Portugal - PT (010)',
    city: 'Matosinhos',
    name: 'Alfândega do Freixieiro PORTO',
  },
  PT000295: {
    country: 'Portugal - PT (010)',
    city: 'Horta',
    name: 'Delegação Aduaneira da Horta AÇORES',
  },
  PT000305: {
    country: 'Portugal - PT (010)',
    city: 'Lisboa',
    name: 'Alfândega do Jardim do Tabaco LISBOA',
  },
  PT000340: {
    country: 'Portugal - PT (010)',
    city: 'Matosinhos',
    name: 'Alfândega de Leixões PORTO',
  },
  PT000455: {
    country: 'Portugal - PT (010)',
    city: 'Peniche',
    name: 'Alfândega de Peniche PENICHE',
  },
  PT000459: {
    country: 'Portugal - PT (010)',
    city: 'Peso da Régua',
    name: 'Delegação Aduaneira Peso da Régua',
  },
  PT000461: {
    country: 'Portugal - PT (010)',
    city: 'Riachos',
    name: 'Posto Aduaneiro de Riachos PENICHE',
  },
  PT000500: {
    country: 'Portugal - PT (010)',
    city: 'Portimão',
    name: 'Delegação Aduaneira de Portimão',
  },
  PT000510: {
    country: 'Portugal - PT (010)',
    city: 'SANTA CRUZ DA GRACIOSA',
    name: 'Posto Aduaneiro Praia da Graciosa AÇORES',
  },
  PT000560: {
    country: 'Portugal - PT (010)',
    city: 'Lisboa',
    name: 'Xabregas LISBOA',
  },
  PT000574: {
    country: 'Portugal - PT (010)',
    city: 'FLORES',
    name: 'Posto Aduaneiro Lajes das Flores AÇORES',
  },
  PT000650: {
    country: 'Portugal - PT (010)',
    city: 'S. ROQUE DO PICO',
    name: 'Posto Aduaneiro S. Roque do Pico AÇORES',
  },
  PT000665: {
    country: 'Portugal - PT (010)',
    city: 'Setúbal',
    name: 'Alfândega de Setúbal',
  },
  PT000670: {
    country: 'Portugal - PT (010)',
    city: 'Sines',
    name: 'Delegação Aduaneira de Sines',
  },
  PT000745: {
    country: 'Portugal - PT (010)',
    city: 'VELAS',
    name: 'Posto Aduaneiro Velas de S. Jorge AÇORES',
  },
  PT000750: {
    country: 'Portugal - PT (010)',
    city: 'Viana do Castelo',
    name: 'Alfândega de Viana do Castelo',
  },
  PT000830: {
    country: 'Portugal - PT (010)',
    city: 'Vilar Formoso',
    name: 'Delegação Aduaneira Vilar Formoso',
  },
  PT000855: {
    country: 'Portugal - PT (010)',
    city: 'Caniçal',
    name: 'Delegação Ad. Zona Franca Madeira MADEIRA',
  },
  PT000900: {
    country: 'Portugal - PT (010)',
    city: 'Lisboa',
    name: 'Autoridade Tributária e Aduaneira',
  },
  PT000908: {
    country: 'Portugal - PT (010)',
    city: 'Lisboa',
    name: 'DS. de Tributação Aduaneira (AT)',
  },
  PT000909: {
    country: 'Portugal - PT (010)',
    city: 'Lisboa',
    name: 'DS de Regulação Aduaneira (AT)',
  },
  PT000910: {
    country: 'Portugal - PT (010)',
    city: 'Lisboa',
    name: 'DS Antifraude Aduaneira',
  },
  PT000930: {
    country: 'Portugal - PT (010)',
    city: 'Lisboa',
    name: 'DS Antifraude Adua- Div Informações',
  },
  RO010001: {
    country: 'Romania - RO (066)',
    city: 'Bucuresti',
    name: 'Excise Liaison Office',
  },
  ROANV001: {
    country: 'Romania - RO (066)',
    city: 'BUCURESTI',
    name: 'AUTORITATEA VAMALA ROMANA',
  },
  ROANV002: {
    country: 'Romania - RO (066)',
    city: 'BUCHAREST',
    name: 'CENTRAL TRANSIT OFFICE',
  },
  ROANV003: {
    country: 'Romania - RO (066)',
    city: 'BUCURESTI',
    name: 'BIROUL GARANTII TRANZIT',
  },
  ROANV004: {
    country: 'Romania - RO (066)',
    city: 'BUCURESTI',
    name: 'AUTORITATEA COMPETENTA AEO',
  },
  ROANV005: {
    country: 'Romania - RO (066)',
    city: 'Bucuresti',
    name: 'Biroul Garantii',
  },
  ROBU1030: {
    country: 'Romania - RO (066)',
    city: 'LOC.OTOPENI,JUD.ILFOV',
    name: 'OTOPENI CALATORI',
  },
  ROBU1040: {
    country: 'Romania - RO (066)',
    city: 'TUNARI, JUD. ILFOV',
    name: 'BANEASA',
  },
  ROBU1200: {
    country: 'Romania - RO (066)',
    city: 'BUCURESTI',
    name: 'ILFOV',
  },
  ROBU1380: {
    country: 'Romania - RO (066)',
    city: 'BUCURESTI',
    name: 'BUCURESTI POSTA',
  },
  ROBU1400: {
    country: 'Romania - RO (066)',
    city: 'BUCURESTI',
    name: 'BUCURESTI',
  },
  ROBU3910: {
    country: 'Romania - RO (066)',
    city: 'GIURGIU',
    name: 'GIURGIU',
  },
  ROBU3980: {
    country: 'Romania - RO (066)',
    city: 'GIURGIU',
    name: 'ZONA LIBERA GIURGIU',
  },
  ROBU7100: {
    country: 'Romania - RO (066)',
    city: 'LOC.ARICESTI,JUD.PRAHOVA',
    name: 'PLOIESTI',
  },
  ROBU8600: {
    country: 'Romania - RO (066)',
    city: 'TARGOVISTE, JUD.DAMBOVITA',
    name: 'DAMBOVITA',
  },
  ROBV0300: {
    country: 'Romania - RO (066)',
    city: 'ALBA IULIA',
    name: 'ALBA IULIA',
  },
  ROBV0900: {
    country: 'Romania - RO (066)',
    city: 'BRASOV',
    name: 'BRASOV',
  },
  ROBV5600: {
    country: 'Romania - RO (066)',
    city: 'MIERCUREA-CIUC, JUD.HARGHITA',
    name: 'HARGHITA',
  },
  ROBV7820: {
    country: 'Romania - RO (066)',
    city: 'SFANTU GHEORGHE, JUD.COVASNA',
    name: 'COVASNA',
  },
  ROBV7900: {
    country: 'Romania - RO (066)',
    city: 'SIBIU',
    name: 'SIBIU',
  },
  ROBV7910: {
    country: 'Romania - RO (066)',
    city: 'SIBIU, jud. SIBIU',
    name: 'SIBIU AEROPORT',
  },
  ROBV8800: {
    country: 'Romania - RO (066)',
    city: 'CRISTESTI, JUD. MURES',
    name: 'MURES',
  },
  ROBV8820: {
    country: 'Romania - RO (066)',
    city: 'Jud.MURES',
    name: 'TARGU-MURES AEROPORT',
  },
  ROCJ0400: {
    country: 'Romania - RO (066)',
    city: 'BISTRITA NASAUD',
    name: 'BISTRITA NASAUD',
  },
  ROCJ0500: {
    country: 'Romania - RO (066)',
    city: 'COM. RECEA, JUD. MARAMURES',
    name: 'MARAMURES',
  },
  ROCJ0510: {
    country: 'Romania - RO (066)',
    city: 'TAUTII MAGHERAUS',
    name: 'AEROPORT BAIA MARE',
  },
  ROCJ1800: {
    country: 'Romania - RO (066)',
    city: 'CLUJ NAPOCA',
    name: 'CLUJ',
  },
  ROCJ1810: {
    country: 'Romania - RO (066)',
    city: 'CLUJ NAPOCA jud.CLUJ',
    name: 'CLUJ NAPOCA AEROPORT',
  },
  ROCJ4310: {
    country: 'Romania - RO (066)',
    city: 'HALMEU JUD.SATU MARE',
    name: 'HALMEU',
  },
  ROCJ6570: {
    country: 'Romania - RO (066)',
    city: 'ORADEA',
    name: 'BIHOR',
  },
  ROCJ6580: {
    country: 'Romania - RO (066)',
    city: 'ORADEA',
    name: 'ORADEA AEROPORT',
  },
  ROCJ7810: {
    country: 'Romania - RO (066)',
    city: 'SATU MARE',
    name: 'SATU MARE',
  },
  ROCJ7830: {
    country: 'Romania - RO (066)',
    city: 'SATU MARE',
    name: 'AEROPORT SATU MARE',
  },
  ROCJ8000: {
    country: 'Romania - RO (066)',
    city: 'SIGHETUL MARMATIEI, JUD. MARAMURES',
    name: 'SIGHET',
  },
  ROCJ9700: {
    country: 'Romania - RO (066)',
    city: 'ZALAU, JUD. SALAJ',
    name: 'SALAJ',
  },
  ROCR0310: {
    country: 'Romania - RO (066)',
    city: 'ALEXANDRIA jud.TELEORMAN',
    name: 'ALEXANDRIA',
  },
  ROCR1700: {
    country: 'Romania - RO (066)',
    city: 'LOC. CALAFAT, JUD. DOLJ',
    name: 'CALAFAT',
  },
  ROCR1720: {
    country: 'Romania - RO (066)',
    city: 'LOC. BECHET, JUD. DOLJ',
    name: 'BECHET',
  },
  ROCR2000: {
    country: 'Romania - RO (066)',
    city: 'LOC. CORABIA, JUD.OLT',
    name: 'CORABIA',
  },
  ROCR2100: {
    country: 'Romania - RO (066)',
    city: 'CRAIOVA, JUD.DOLJ',
    name: 'DOLJ',
  },
  ROCR2110: {
    country: 'Romania - RO (066)',
    city: 'CRAIOVA, JUD. DOLJ',
    name: 'CRAIOVA AEROPORT',
  },
  ROCR5800: {
    country: 'Romania - RO (066)',
    city: 'ZIMNICEA',
    name: 'ZIMNICEA',
  },
  ROCR7000: {
    country: 'Romania - RO (066)',
    city: 'PITESTI, JUD. ARGES',
    name: 'ARGES',
  },
  ROCR7200: {
    country: 'Romania - RO (066)',
    city: 'JUD. MEHEDINTI',
    name: 'PORTILE DE FIER II',
  },
  ROCR7270: {
    country: 'Romania - RO (066)',
    city: 'LOC. GURA VAII, JUD.MEHEDINTI',
    name: 'PORTILE DE FIER I',
  },
  ROCR7280: {
    country: 'Romania - RO (066)',
    city: 'ORSOVA jud MEHEDINTI',
    name: 'ORSOVA',
  },
  ROCR7700: {
    country: 'Romania - RO (066)',
    city: 'RAMNICU VALCEA, JUD.VALCEA',
    name: 'RAMNICU VALCEA',
  },
  ROCR8210: {
    country: 'Romania - RO (066)',
    city: 'LOC. SLATINA, JUD. OLT',
    name: 'OLT',
  },
  ROCR8810: {
    country: 'Romania - RO (066)',
    city: 'TARGU JIU jud.GORJ',
    name: 'GORJ',
  },
  ROCR9000: {
    country: 'Romania - RO (066)',
    city: 'DROBETA TR.SEVERIN, JUD.MEHEDINTI',
    name: 'MEHEDINTI',
  },
  ROCR9100: {
    country: 'Romania - RO (066)',
    city: 'TURNU MAGURELE, JUD.TELEORMAN',
    name: 'TURNU MAGURELE',
  },
  ROCT1710: {
    country: 'Romania - RO (066)',
    city: 'CALARASI',
    name: 'CALARASI',
  },
  ROCT1900: {
    country: 'Romania - RO (066)',
    city: 'AGIGEA, JUD. CONSTANTA',
    name: 'CONSTANTA SUD AGIGEA',
  },
  ROCT1970: {
    country: 'Romania - RO (066)',
    city: 'CONSTANTA',
    name: 'CONSTANTA PORT',
  },
  ROCT5100: {
    country: 'Romania - RO (066)',
    city: 'Jud.CONSTANTA',
    name: 'MIHAIL KOGALNICEANU AEROPORT',
  },
  ROCT5400: {
    country: 'Romania - RO (066)',
    city: 'MANGALIA jud.CONSTANTA',
    name: 'MANGALIA',
  },
  ROCT8220: {
    country: 'Romania - RO (066)',
    city: 'SLOBOZIA, JUD. IALOMITA',
    name: 'IALOMITA',
  },
  ROCT8300: {
    country: 'Romania - RO (066)',
    city: 'SULINA, JUD. TULCEA',
    name: 'SULINA',
  },
  RODRVBU0: {
    country: 'Romania - RO (066)',
    city: 'BUCURESTI',
    name: 'D.R.A.O.V. BUCURESTI',
  },
  RODRVBV0: {
    country: 'Romania - RO (066)',
    city: 'BRASOV',
    name: 'D.R.A.O.V. BRASOV',
  },
  RODRVCJ0: {
    country: 'Romania - RO (066)',
    city: 'ORADEA',
    name: 'D.R.A.O.V. CLUJ',
  },
  RODRVCR0: {
    country: 'Romania - RO (066)',
    city: 'CRAIOVA',
    name: 'D.R.A.O.V. CRAIOVA',
  },
  RODRVGL0: {
    country: 'Romania - RO (066)',
    city: 'GALATI',
    name: 'D.R.A.O.V. GALATI',
  },
  RODRVIS0: {
    country: 'Romania - RO (066)',
    city: 'IASI',
    name: 'D.R.A.O.V. IASI',
  },
  RODRVPL0: {
    country: 'Romania - RO (066)',
    city: 'LOC.ARICESTI,JUD.PRAHOVA',
    name: 'DIRECTIA REGIONALA VAMALA PLOIESTI',
  },
  RODRVTM0: {
    country: 'Romania - RO (066)',
    city: 'TIMIS',
    name: 'D.R.A.O.V. TIMISOARA',
  },
  ROGL0700: {
    country: 'Romania - RO (066)',
    city: 'BRAILA',
    name: 'BRAILA',
  },
  ROGL0710: {
    country: 'Romania - RO (066)',
    city: 'BRAILA, jud.BRAILA',
    name: 'ZONA LIBERA BRAILA',
  },
  ROGL1500: {
    country: 'Romania - RO (066)',
    city: 'BUZAU',
    name: 'BUZAU',
  },
  ROGL3600: {
    country: 'Romania - RO (066)',
    city: 'FOCSANI, JUD.VRANCEA',
    name: 'VRANCEA',
  },
  ROGL3610: {
    country: 'Romania - RO (066)',
    city: 'jud.GALATI',
    name: 'OANCEA',
  },
  ROGL3800: {
    country: 'Romania - RO (066)',
    city: 'GALATI',
    name: 'GALATI',
  },
  ROGL3810: {
    country: 'Romania - RO (066)',
    city: 'GALATI jud.GALATI',
    name: 'GALATI ZONA LIBERA',
  },
  ROGL3850: {
    country: 'Romania - RO (066)',
    city: 'GIURGIULESTI, JUD.GALATI',
    name: 'GIURGIULESTI',
  },
  ROGL8900: {
    country: 'Romania - RO (066)',
    city: 'TULCEA',
    name: 'TULCEA',
  },
  ROGL8910: {
    country: 'Romania - RO (066)',
    city: 'Tulcea',
    name: 'Aeroport Delta Dunarii Tulcea',
  },
  ROGL8920: {
    country: 'Romania - RO (066)',
    city: 'TULCEA',
    name: 'ISACCEA',
  },
  ROIS0100: {
    country: 'Romania - RO (066)',
    city: 'COM.DRANCENI,JUD.VASLUI',
    name: 'ALBITA',
  },
  ROIS0600: {
    country: 'Romania - RO (066)',
    city: 'BACAU',
    name: 'BACAU',
  },
  ROIS0620: {
    country: 'Romania - RO (066)',
    city: 'BACAU',
    name: 'BACAU AEROPORT',
  },
  ROIS1600: {
    country: 'Romania - RO (066)',
    city: 'BOTOSANI',
    name: 'BOTOSANI',
  },
  ROIS1610: {
    country: 'Romania - RO (066)',
    city: 'JUD. BOTOSANI',
    name: 'STANCA COSTESTI',
  },
  ROIS1620: {
    country: 'Romania - RO (066)',
    city: 'JUD. BOTOSANI',
    name: 'RADAUTI PRUT',
  },
  ROIS2700: {
    country: 'Romania - RO (066)',
    city: 'COM.DORNESTI,JUD.SUCEAVA',
    name: 'DORNESTI',
  },
  ROIS4650: {
    country: 'Romania - RO (066)',
    city: 'IASI',
    name: 'IASI',
  },
  ROIS4660: {
    country: 'Romania - RO (066)',
    city: 'IASI',
    name: 'IASI AEROPORT',
  },
  ROIS4990: {
    country: 'Romania - RO (066)',
    city: 'COM.VICTORIA, JUD.IASI',
    name: 'SCULENI',
  },
  ROIS7400: {
    country: 'Romania - RO (066)',
    city: 'COM.ALEX.CEL BUN,JUD.NEAMT',
    name: 'PIATRA NEAMT',
  },
  ROIS8200: {
    country: 'Romania - RO (066)',
    city: 'SIRET, jud SUCEAVA',
    name: 'SIRET',
  },
  ROIS8230: {
    country: 'Romania - RO (066)',
    city: 'LOC.SCHEIA, JUD.SUCEAVA',
    name: 'SUCEAVA',
  },
  ROIS8250: {
    country: 'Romania - RO (066)',
    city: 'JUD. SUCEAVA',
    name: 'SUCEAVA AEROPORT',
  },
  ROIS9610: {
    country: 'Romania - RO (066)',
    city: 'VASLUI',
    name: 'VASLUI',
  },
  ROIS9620: {
    country: 'Romania - RO (066)',
    city: 'VICOVU DE SUS',
    name: 'VICOVU DE SUS',
  },
  ROTM0200: {
    country: 'Romania - RO (066)',
    city: 'ARAD, jud.Arad',
    name: 'ARAD',
  },
  ROTM0230: {
    country: 'Romania - RO (066)',
    city: 'ARAD, JUD.ARAD',
    name: 'ARAD AEROPORT',
  },
  ROTM2300: {
    country: 'Romania - RO (066)',
    city: 'CURTICI, JUD.ARAD',
    name: 'ZONA LIBERA CURTICI',
  },
  ROTM5010: {
    country: 'Romania - RO (066)',
    city: 'JIMBOLIA,JUD.TIMIS',
    name: 'JIMBOLIA',
  },
  ROTM5510: {
    country: 'Romania - RO (066)',
    city: 'LOC.MORAVITA,JUD.TIMIS',
    name: 'MORAVITA',
  },
  ROTM6100: {
    country: 'Romania - RO (066)',
    city: 'Jud.CARAS SEVERIN',
    name: 'NAIDAS',
  },
  ROTM7600: {
    country: 'Romania - RO (066)',
    city: 'RESITA,JUD.CARAS-SEVERIN',
    name: 'CARAS-SEVERIN',
  },
  ROTM8100: {
    country: 'Romania - RO (066)',
    city: 'DEVA, JUD. HUNEDOARA',
    name: 'HUNEDOARA',
  },
  ROTM8720: {
    country: 'Romania - RO (066)',
    city: 'TIMISOARA',
    name: 'TIMIS',
  },
  ROTM8730: {
    country: 'Romania - RO (066)',
    city: 'JUD. TIMIS,',
    name: 'TIMISOARA AEROPORT',
  },
  RS011002: {
    country: 'Serbia - RS (094)',
    city: 'BEOGRAD',
    name: 'CARINARNICA BEOGRAD',
  },
  RS011029: {
    country: 'Serbia - RS (094)',
    city: 'BEOGRAD',
    name: 'CI LUKA BEOGRAD',
  },
  RS011037: {
    country: 'Serbia - RS (094)',
    city: 'PANČEVO',
    name: 'CI PANČEVO',
  },
  RS011045: {
    country: 'Serbia - RS (094)',
    city: 'BEOGRAD',
    name: 'CI POŠTA BEOGRAD',
  },
  RS011096: {
    country: 'Serbia - RS (094)',
    city: 'BEOGRAD',
    name: 'CI RANŽIRNA ŽEL.STANICA MAKIŠ-BEOG',
  },
  RS011118: {
    country: 'Serbia - RS (094)',
    city: 'BEOGRAD',
    name: 'CI OSTRUŽNICA',
  },
  RS011126: {
    country: 'Serbia - RS (094)',
    city: 'DOBANOVCI',
    name: 'CI TERMINAL II',
  },
  RS011134: {
    country: 'Serbia - RS (094)',
    city: 'ŠIMANOVCI',
    name: 'CI SKLADIŠTA',
  },
  RS011207: {
    country: 'Serbia - RS (094)',
    city: 'KRNJEŠEVCI',
    name: 'CI ZA POSLOVE CAR. NADZORA-BEOGRAD',
  },
  RS011240: {
    country: 'Serbia - RS (094)',
    city: 'BEOGRAD',
    name: 'CR SAVSKO PRISTANIŠTE',
  },
  RS011274: {
    country: 'Serbia - RS (094)',
    city: 'BEOGRAD-SURČIN',
    name: 'CI AERODROM NIKOLA TESLA',
  },
  RS011282: {
    country: 'Serbia - RS (094)',
    city: 'NOVI BANOVCI',
    name: 'CI ZA KURIRSKE POŠILJKE-DHL',
  },
  RS011347: {
    country: 'Serbia - RS (094)',
    city: 'BEOGRAD',
    name: 'CI BEOGRAD',
  },
  RS011410: {
    country: 'Serbia - RS (094)',
    city: 'BEOGRAD-SURČIN',
    name: 'CI AERODROM BEOGRAD',
  },
  RS011568: {
    country: 'Serbia - RS (094)',
    city: 'BEOGRAD',
    name: 'CI TERMINAL BEOGRAD',
  },
  RS012009: {
    country: 'Serbia - RS (094)',
    city: 'KLADOVO',
    name: 'CARINARNICA KLADOVO',
  },
  RS012033: {
    country: 'Serbia - RS (094)',
    city: 'NEGOTIN',
    name: 'CI MOKRANJE',
  },
  RS012041: {
    country: 'Serbia - RS (094)',
    city: 'PRAHOVO',
    name: 'CI PRAHOVO',
  },
  RS012068: {
    country: 'Serbia - RS (094)',
    city: 'VELIKO GRADIŠTE',
    name: 'CI VELIKO GRADIŠTE',
  },
  RS012084: {
    country: 'Serbia - RS (094)',
    city: 'NEGOTIN',
    name: 'CR ĐERDAP 2',
  },
  RS012092: {
    country: 'Serbia - RS (094)',
    city: 'MAJDANPEK',
    name: 'CR MAJDANPEK',
  },
  RS012289: {
    country: 'Serbia - RS (094)',
    city: 'KLADOVO',
    name: 'CI ĐERDAP',
  },
  RS012297: {
    country: 'Serbia - RS (094)',
    city: 'BOR',
    name: 'CR BOR',
  },
  RS012319: {
    country: 'Serbia - RS (094)',
    city: 'ZAJEČAR',
    name: 'CI VRŠKA ČUKA',
  },
  RS012327: {
    country: 'Serbia - RS (094)',
    city: 'ZAJEČAR',
    name: 'CR ZAJEČAR',
  },
  RS013005: {
    country: 'Serbia - RS (094)',
    city: 'DIMITROVGRAD',
    name: 'CARINARNICA DIMITROVGRAD',
  },
  RS013013: {
    country: 'Serbia - RS (094)',
    city: 'DIMITROVGRAD',
    name: 'CI TERMINAL GRADINA',
  },
  RS013021: {
    country: 'Serbia - RS (094)',
    city: 'DIMITROVGRAD',
    name: 'CI ŽELEZNIČKA STANICA DIMITROVGRAD',
  },
  RS013030: {
    country: 'Serbia - RS (094)',
    city: 'PIROT',
    name: 'CI SLOBODNA ZONA PIROT',
  },
  RS013048: {
    country: 'Serbia - RS (094)',
    city: 'PIROT',
    name: 'CR PIROT',
  },
  RS013277: {
    country: 'Serbia - RS (094)',
    city: 'DIMITROVGRAD',
    name: 'CI GRADINA',
  },
  RS014001: {
    country: 'Serbia - RS (094)',
    city: 'KRALJEVO',
    name: 'CARINARNICA KRALJEVO',
  },
  RS014010: {
    country: 'Serbia - RS (094)',
    city: 'RAŠKA',
    name: 'CR RAŠKA',
  },
  RS014052: {
    country: 'Serbia - RS (094)',
    city: 'KRALJEVO',
    name: 'CI KRALJEVO',
  },
  RS014060: {
    country: 'Serbia - RS (094)',
    city: 'ČAČAK',
    name: 'CI ČAČAK',
  },
  RS014109: {
    country: 'Serbia - RS (094)',
    city: 'NOVI PAZAR',
    name: 'CI NOVI PAZAR',
  },
  RS014176: {
    country: 'Serbia - RS (094)',
    city: 'GORNJI MILANOVAC',
    name: 'CI GORNJI MILANOVAC',
  },
  RS014320: {
    country: 'Serbia - RS (094)',
    city: 'RAŠKA',
    name: 'CP RUDNICA',
  },
  RS014338: {
    country: 'Serbia - RS (094)',
    city: 'TUTIN',
    name: 'CI ŠPILJANI',
  },
  RS014346: {
    country: 'Serbia - RS (094)',
    city: 'NOVI PAZAR',
    name: 'CP BRNJAČKI MOST',
  },
  RS014460: {
    country: 'Serbia - RS (094)',
    city: 'TUTIN',
    name: 'CR TUTIN',
  },
  RS015008: {
    country: 'Serbia - RS (094)',
    city: 'NIŠ',
    name: 'CARINARNICA NIŠ',
  },
  RS015024: {
    country: 'Serbia - RS (094)',
    city: 'LESKOVAC',
    name: 'CI LESKOVAC',
  },
  RS015032: {
    country: 'Serbia - RS (094)',
    city: 'BOSILEGRAD',
    name: 'CR RIBARCI',
  },
  RS015040: {
    country: 'Serbia - RS (094)',
    city: 'KLISURA',
    name: 'CR STREZIMIROVCI',
  },
  RS015075: {
    country: 'Serbia - RS (094)',
    city: 'NIŠ',
    name: 'CR ŽELEZNIČKA STANICA NIŠ',
  },
  RS015083: {
    country: 'Serbia - RS (094)',
    city: 'NIŠ',
    name: 'CR POŠTA NIŠ',
  },
  RS015113: {
    country: 'Serbia - RS (094)',
    city: 'KNJAŽEVAC',
    name: 'CR KNJAŽEVAC',
  },
  RS015121: {
    country: 'Serbia - RS (094)',
    city: 'VRANJE',
    name: 'CI VRANJE',
  },
  RS015156: {
    country: 'Serbia - RS (094)',
    city: 'NIŠ',
    name: 'CI TERMINAL NIŠ',
  },
  RS015172: {
    country: 'Serbia - RS (094)',
    city: 'PREŠEVO',
    name: 'CI PREŠEVO',
  },
  RS015288: {
    country: 'Serbia - RS (094)',
    city: 'RISTOVAC',
    name: 'CR ŽELEZNIČKA STANICA RISTOVAC',
  },
  RS015407: {
    country: 'Serbia - RS (094)',
    city: 'PROKUPLJE',
    name: 'CI PROKUPLJE',
  },
  RS015423: {
    country: 'Serbia - RS (094)',
    city: 'NIŠ',
    name: 'CR DUVANSKA NIŠ',
  },
  RS015431: {
    country: 'Serbia - RS (094)',
    city: 'NIŠ',
    name: 'CR AERODROM KONSTANTIN VELIKI',
  },
  RS016128: {
    country: 'Serbia - RS (094)',
    city: 'NOVI BEOGRAD',
    name: 'UPRAVA CARINA',
  },
  RS017000: {
    country: 'Serbia - RS (094)',
    city: 'KRAGUJEVAC',
    name: 'CARINARNICA KRAGUJEVAC',
  },
  RS017019: {
    country: 'Serbia - RS (094)',
    city: 'KRAGUJEVAC',
    name: 'CI KRAGUJEVAC',
  },
  RS017027: {
    country: 'Serbia - RS (094)',
    city: 'JAGODINA',
    name: 'CR JAGODINA',
  },
  RS017035: {
    country: 'Serbia - RS (094)',
    city: 'SMEDEREVSKA PALANKA',
    name: 'CR SMEDEREVSKA PALANKA',
  },
  RS017043: {
    country: 'Serbia - RS (094)',
    city: 'KRAGUJEVAC',
    name: 'CR ŽELEZNIČKA STANICA KRAGUJEVAC',
  },
  RS017078: {
    country: 'Serbia - RS (094)',
    city: 'LAPOVO',
    name: 'CR LAPOVO',
  },
  RS017094: {
    country: 'Serbia - RS (094)',
    city: 'VELIKA PLANA',
    name: 'CARINSKI REFERAT VELIKA PLANA',
  },
  RS017108: {
    country: 'Serbia - RS (094)',
    city: 'SVILAJNAC',
    name: 'CARINSKI REFERAT SVILAJNAC',
  },
  RS017256: {
    country: 'Serbia - RS (094)',
    city: 'ARANĐELOVAC',
    name: 'CR ARANĐELOVAC',
  },
  RS017329: {
    country: 'Serbia - RS (094)',
    city: 'MLADENOVAC',
    name: 'CR MLADENOVAC',
  },
  RS017337: {
    country: 'Serbia - RS (094)',
    city: 'POŽAREVAC',
    name: 'CR POŽAREVAC',
  },
  RS017345: {
    country: 'Serbia - RS (094)',
    city: 'SMEDEREVO',
    name: 'CI SMEDEREVO',
  },
  RS017353: {
    country: 'Serbia - RS (094)',
    city: 'RADINAC',
    name: 'CR ŽELEZARA SMEDEREVO',
  },
  RS017370: {
    country: 'Serbia - RS (094)',
    city: 'KRAGUJEVAC',
    name: 'CR SLOBODNA ZONA KRAGUJEVAC',
  },
  RS017388: {
    country: 'Serbia - RS (094)',
    city: 'SMEDEREVO',
    name: 'CR SLOBODNA ZONA SMEDEREVO',
  },
  RS021008: {
    country: 'Serbia - RS (094)',
    city: 'NOVI SAD',
    name: 'CARINARNICA NOVI SAD',
  },
  RS021016: {
    country: 'Serbia - RS (094)',
    city: 'NOVI SAD',
    name: 'CI LUKA I SKLADIŠTA NOVI SAD',
  },
  RS021024: {
    country: 'Serbia - RS (094)',
    city: 'BAČKA PALANKA',
    name: 'CI BAČKA PALANKA',
  },
  RS021040: {
    country: 'Serbia - RS (094)',
    city: 'VRBAS',
    name: 'CR VRBAS',
  },
  RS021059: {
    country: 'Serbia - RS (094)',
    city: 'NOVI SAD',
    name: 'CR POŠTA NOVI SAD',
  },
  RS021067: {
    country: 'Serbia - RS (094)',
    city: 'NOVI SAD',
    name: 'CI RANŽIRNA ŽELEZ. STANICA NOVI SAD',
  },
  RS021083: {
    country: 'Serbia - RS (094)',
    city: 'ŠID',
    name: 'CI ŽELEZNIČKA STANICA ŠID',
  },
  RS021091: {
    country: 'Serbia - RS (094)',
    city: 'BATROVCI',
    name: 'CI BATROVCI',
  },
  RS021105: {
    country: 'Serbia - RS (094)',
    city: 'BAČKA PALANKA',
    name: 'CI MOST BAČKA PALANKA',
  },
  RS021156: {
    country: 'Serbia - RS (094)',
    city: 'NEŠTIN',
    name: 'CR NEŠTIN',
  },
  RS021202: {
    country: 'Serbia - RS (094)',
    city: 'NOVI SAD',
    name: 'CR ZA POSLOVE CAR. NADZORA NOVI SAD',
  },
  RS021270: {
    country: 'Serbia - RS (094)',
    city: 'SOT',
    name: 'CR SOT',
  },
  RS021300: {
    country: 'Serbia - RS (094)',
    city: 'LJUBA',
    name: 'CR LJUBA',
  },
  RS021342: {
    country: 'Serbia - RS (094)',
    city: 'ŠID',
    name: 'CI ŠID',
  },
  RS021369: {
    country: 'Serbia - RS (094)',
    city: 'BEČEJ',
    name: 'CR BEČEJ',
  },
  RS021377: {
    country: 'Serbia - RS (094)',
    city: 'NOVI SAD',
    name: 'CR SLOBODNA ZONA NOVI SAD',
  },
  RS021385: {
    country: 'Serbia - RS (094)',
    city: 'INĐIJA',
    name: 'CARINSKI REFERAT INĐIJA',
  },
  RS021440: {
    country: 'Serbia - RS (094)',
    city: 'NOVI SAD',
    name: 'CR ZA REČNI SAOBRAĆAJ NOVI SAD',
  },
  RS021458: {
    country: 'Serbia - RS (094)',
    city: 'NOVI SAD',
    name: 'CR NOVI SAD',
  },
  RS022004: {
    country: 'Serbia - RS (094)',
    city: 'SOMBOR',
    name: 'CARINARNICA SOMBOR',
  },
  RS022012: {
    country: 'Serbia - RS (094)',
    city: 'APATIN',
    name: 'CR APATIN',
  },
  RS022020: {
    country: 'Serbia - RS (094)',
    city: 'BAČKI BREG',
    name: 'CI BAČKI BREG',
  },
  RS022039: {
    country: 'Serbia - RS (094)',
    city: 'BEZDAN',
    name: 'CI BEZDAN-MOHAČ',
  },
  RS022047: {
    country: 'Serbia - RS (094)',
    city: 'SOMBOR',
    name: 'CR SOMBOR',
  },
  RS022128: {
    country: 'Serbia - RS (094)',
    city: 'BOGOJEVO',
    name: 'CI BOGOJEVO',
  },
  RS022144: {
    country: 'Serbia - RS (094)',
    city: 'BEZDAN',
    name: 'CR BEZDAN',
  },
  RS022292: {
    country: 'Serbia - RS (094)',
    city: 'BOGOJEVO',
    name: 'CR ŽELEZNIČKA STANICA BOGOJEVO',
  },
  RS023000: {
    country: 'Serbia - RS (094)',
    city: 'VRŠAC',
    name: 'CARINARNICA VRŠAC',
  },
  RS023019: {
    country: 'Serbia - RS (094)',
    city: 'KALUĐEROVO',
    name: 'CI KALUĐEROVO',
  },
  RS023027: {
    country: 'Serbia - RS (094)',
    city: 'VRŠAC',
    name: 'CI VATIN',
  },
  RS023035: {
    country: 'Serbia - RS (094)',
    city: 'VRŠAC',
    name: 'CI ŽELEZNIČKA STANICA VRŠAC',
  },
  RS023043: {
    country: 'Serbia - RS (094)',
    city: 'VRŠAC',
    name: 'CI HEMOFARM',
  },
  RS024007: {
    country: 'Serbia - RS (094)',
    city: 'ZRENJANIN',
    name: 'CARINARNICA ZRENJANIN',
  },
  RS024015: {
    country: 'Serbia - RS (094)',
    city: 'KIKINDA',
    name: 'CI KIKINDA',
  },
  RS024023: {
    country: 'Serbia - RS (094)',
    city: 'ZRENJANIN',
    name: 'CI ZRENJANIN',
  },
  RS024031: {
    country: 'Serbia - RS (094)',
    city: 'SRPSKA CRNJA',
    name: 'CI SRPSKA CRNJA',
  },
  RS024287: {
    country: 'Serbia - RS (094)',
    city: 'ZRENJANIN',
    name: 'CR SLOBODNA ZONA ZRENJANIN',
  },
  RS025003: {
    country: 'Serbia - RS (094)',
    city: 'SUBOTICA',
    name: 'CARINARNICA SUBOTICA',
  },
  RS025011: {
    country: 'Serbia - RS (094)',
    city: 'HORGOŠ',
    name: 'CI TERMINAL HORGOŠ',
  },
  RS025038: {
    country: 'Serbia - RS (094)',
    city: 'SUBOTICA',
    name: 'JAVNA SKLADIŠTA SUBOTICA',
  },
  RS025046: {
    country: 'Serbia - RS (094)',
    city: 'SUBOTICA',
    name: 'CI ŽELEZNIČKA STANICA SUBOTICA',
  },
  RS025054: {
    country: 'Serbia - RS (094)',
    city: 'KELEBIJA',
    name: 'CI TERMINAL KELEBIJA',
  },
  RS025062: {
    country: 'Serbia - RS (094)',
    city: 'SENTA',
    name: 'CI SENTA',
  },
  RS025151: {
    country: 'Serbia - RS (094)',
    city: 'HORGOŠ',
    name: 'CI HORGOŠ',
  },
  RS025160: {
    country: 'Serbia - RS (094)',
    city: 'SUBOTICA',
    name: 'CI SLOBODNA ZONA SUBOTICA',
  },
  RS025267: {
    country: 'Serbia - RS (094)',
    city: 'KELEBIJA',
    name: 'CI KELEBIJA',
  },
  RS031003: {
    country: 'Serbia - RS (094)',
    city: 'NIŠ',
    name: 'CARINARNICA PRIŠTINA',
  },
  RS031305: {
    country: 'Serbia - RS (094)',
    city: 'KURŠUMLIJA',
    name: 'CP MERDARE',
  },
  RS031321: {
    country: 'Serbia - RS (094)',
    city: 'PREŠEVO',
    name: 'CP DEPCE',
  },
  RS031330: {
    country: 'Serbia - RS (094)',
    city: 'BUJANOVAC',
    name: 'CP KONČULJ',
  },
  RS042005: {
    country: 'Serbia - RS (094)',
    city: 'ŠABAC',
    name: 'CARINARNICA ŠABAC',
  },
  RS042056: {
    country: 'Serbia - RS (094)',
    city: 'ŠABAC',
    name: 'CI ŠABAC',
  },
  RS042072: {
    country: 'Serbia - RS (094)',
    city: 'SREMSKA MITROVICA',
    name: 'CI SREMSKA MITROVICA',
  },
  RS042102: {
    country: 'Serbia - RS (094)',
    city: 'SREMSKA RAČA',
    name: 'CI SREMSKA RAČA',
  },
  RS042145: {
    country: 'Serbia - RS (094)',
    city: 'VALJEVO',
    name: 'CI VALJEVO',
  },
  RS042153: {
    country: 'Serbia - RS (094)',
    city: 'MALI ZVORNIK',
    name: 'CI MALI ZVORNIK',
  },
  RS042188: {
    country: 'Serbia - RS (094)',
    city: 'BADOVINCI',
    name: 'CI BADOVINCI',
  },
  RS042196: {
    country: 'Serbia - RS (094)',
    city: 'LOZNICA',
    name: 'CI TRBUŠNICA',
  },
  RS042200: {
    country: 'Serbia - RS (094)',
    city: 'LJUBOVIJA',
    name: 'CI LJUBOVIJA',
  },
  RS042277: {
    country: 'Serbia - RS (094)',
    city: 'ŠABAC',
    name: 'CR SLOBODNA ZONA ŠABAC',
  },
  RS045004: {
    country: 'Serbia - RS (094)',
    city: 'KRUŠEVAC',
    name: 'CARINARNICA KRUŠEVAC',
  },
  RS045047: {
    country: 'Serbia - RS (094)',
    city: 'KRUŠEVAC',
    name: 'CI KRUŠEVAC',
  },
  RS045055: {
    country: 'Serbia - RS (094)',
    city: 'PARAĆIN',
    name: 'CR PARAĆIN',
  },
  RS045063: {
    country: 'Serbia - RS (094)',
    city: 'TRSTENIK',
    name: 'CR TRSTENIK',
  },
  RS045110: {
    country: 'Serbia - RS (094)',
    city: 'KRUŠEVAC',
    name: 'CR SLOBODNA ZONA KRUŠEVAC',
  },
  RS046000: {
    country: 'Serbia - RS (094)',
    city: 'UŽICE',
    name: 'CARINARNICA UŽICE',
  },
  RS046027: {
    country: 'Serbia - RS (094)',
    city: 'UŽICE',
    name: 'CI UŽICE',
  },
  RS046035: {
    country: 'Serbia - RS (094)',
    city: 'POŽEGA',
    name: 'CR POŽEGA',
  },
  RS046043: {
    country: 'Serbia - RS (094)',
    city: 'PRIJEPOLJE',
    name: 'CI PRIJEPOLJE',
  },
  RS046051: {
    country: 'Serbia - RS (094)',
    city: 'PRIJEPOLJE',
    name: 'CR JABUKA',
  },
  RS046060: {
    country: 'Serbia - RS (094)',
    city: 'PRIJEPOLJE',
    name: 'CR ŽELEZ. STAN. PRIJEPOLJE KOLOVRAT',
  },
  RS046078: {
    country: 'Serbia - RS (094)',
    city: 'PRIJEPOLJE',
    name: 'CI GOSTUN',
  },
  RS046086: {
    country: 'Serbia - RS (094)',
    city: 'BAJINA BAŠTA',
    name: 'CR BAJINA BAŠTA',
  },
  RS046094: {
    country: 'Serbia - RS (094)',
    city: 'UŽICE',
    name: 'CI KOTROMAN',
  },
  RS046108: {
    country: 'Serbia - RS (094)',
    city: 'PRIBOJ',
    name: 'CI UVAC',
  },
  RS046124: {
    country: 'Serbia - RS (094)',
    city: 'SEVOJNO',
    name: 'CR SLOBODNA ZONA UŽICE',
  },
  RS046159: {
    country: 'Serbia - RS (094)',
    city: 'PRIBOJ',
    name: 'CR SLOBODNA ZONA PRIBOJ',
  },
  SE000050: {
    country: 'Sweden - SE (030)',
    city: 'MALMÖ',
    name: 'MALMÖ TULLVERKET',
  },
  SE001033: {
    country: 'Sweden - SE (030)',
    city: 'HAPARANDA',
    name: 'KONTROLLAVDELNING NORD HAPARANDA',
  },
  SE003033: {
    country: 'Sweden - SE (030)',
    city: 'STOCKHOLM-ARLANDA',
    name: 'ARLANDA TULLVERKET',
  },
  SE004134: {
    country: 'Sweden - SE (030)',
    city: 'NORRKÖPING',
    name: 'NORRKÖPING TULLVERKET',
  },
  SE009251: {
    country: 'Sweden - SE (030)',
    city: 'Stockholm',
    name: 'Tullverket',
  },
  SE010332: {
    country: 'Sweden - SE (030)',
    city: 'TÄRNABY',
    name: 'TÄRNABY TULLVERKET',
  },
  SE010333: {
    country: 'Sweden - SE (030)',
    city: 'N8503 Narvik NO',
    name: 'BJÖRNFJELL TOLLSTED',
  },
  SE010334: {
    country: 'Sweden - SE (030)',
    city: 'RÖKLAND',
    name: 'JUNKERDAL TOLLSTED',
  },
  SE020342: {
    country: 'Sweden - SE (030)',
    city: 'STORLIEN',
    name: 'STORLIEN TULLVERKET',
  },
  SE020344: {
    country: 'Sweden - SE (030)',
    city: 'BREKKEBYGD',
    name: 'VAULDALEN TOLLSTED',
  },
  SE020423: {
    country: 'Sweden - SE (030)',
    city: 'IDRE',
    name: 'IDRE',
  },
  SE060335: {
    country: 'Sweden - SE (030)',
    city: 'TÖCKSFORS',
    name: 'HÅN TULLVERKET',
  },
  SE060340: {
    country: 'Sweden - SE (030)',
    city: 'SPONVIKA',
    name: 'SVINESUND TOLLSTED',
  },
  SE060341: {
    country: 'Sweden - SE (030)',
    city: 'ÖRJE',
    name: 'ÖRJE TOLLSTED',
  },
  SE092714: {
    country: 'Sweden - SE (030)',
    city: 'Stockholm-Arlanda',
    name: 'Stockholm-Arlanda Tullverket',
  },
  SE303400: {
    country: 'Sweden - SE (030)',
    city: 'STOCKHOLM',
    name: 'STOCKHOLM TULLVERKET',
  },
  SE603303: {
    country: 'Sweden - SE (030)',
    city: 'GÖTEBORG',
    name: 'GÖTEBORG TULLVERKET',
  },
  SE603340: {
    country: 'Sweden - SE (030)',
    city: 'Strömstad',
    name: 'Tullverket Svinesund',
  },
  SE603342: {
    country: 'Sweden - SE (030)',
    city: 'STRÖMSTAD',
    name: 'STRÖMSTAD TULLVERKET',
  },
  SE603360: {
    country: 'Sweden - SE (030)',
    city: 'CHARLOTTENBERG',
    name: 'EDA TULLVERKET',
  },
  SE603362: {
    country: 'Sweden - SE (030)',
    city: 'ÖSTBY',
    name: 'ÖSTBY TOLLSTED',
  },
  SE603363: {
    country: 'Sweden - SE (030)',
    city: 'FLISA',
    name: 'ÅSNES TOLLSTED',
  },
  SE900001: {
    country: 'Sweden - SE (030)',
    city: 'LUDVIKA',
    name: 'SKATTEVERKET STORFÖRETAGSSKATTEKONT LUDVIKAENHETEN',
  },
  SEBGP203: {
    country: 'Sweden - SE (030)',
    city: 'Stockholm',
    name: 'Wallhamn',
  },
  SEGBG200: {
    country: 'Sweden - SE (030)',
    city: 'Stockholm',
    name: 'Göteborg',
  },
  SEGBP201: {
    country: 'Sweden - SE (030)',
    city: 'Stockholm',
    name: 'Halmstad',
  },
  SEGBP202: {
    country: 'Sweden - SE (030)',
    city: 'Stockholm',
    name: 'Kristinehamn',
  },
  SEGBP205: {
    country: 'Sweden - SE (030)',
    city: 'Stockholm',
    name: 'Vargön-Vänersborg',
  },
  SEGBP206: {
    country: 'Sweden - SE (030)',
    city: 'Stockholm',
    name: 'Vänersborg',
  },
  SEGBP207: {
    country: 'Sweden - SE (030)',
    city: 'Stockholm',
    name: 'Uddevalla',
  },
  SEMAO300: {
    country: 'Sweden - SE (030)',
    city: 'Stockholm',
    name: 'Malmö',
  },
  SEMAP301: {
    country: 'Sweden - SE (030)',
    city: 'Stockholm',
    name: 'Helsingborg',
  },
  SEMAP302: {
    country: 'Sweden - SE (030)',
    city: 'Stockholm',
    name: 'Trelleborg',
  },
  SEMAP303: {
    country: 'Sweden - SE (030)',
    city: 'Stockholm',
    name: 'Ystad',
  },
  SEMAP304: {
    country: 'Sweden - SE (030)',
    city: 'Stockholm',
    name: 'Simrishamn',
  },
  SEMAP305: {
    country: 'Sweden - SE (030)',
    city: 'Stockholm',
    name: 'Åhus',
  },
  SEMAP306: {
    country: 'Sweden - SE (030)',
    city: 'Stockholm',
    name: 'Karlshamn',
  },
  SEMAP307: {
    country: 'Sweden - SE (030)',
    city: 'Stockholm',
    name: 'Karlskrona',
  },
  SEMAP308: {
    country: 'Sweden - SE (030)',
    city: 'Stockholm',
    name: 'Kalmar',
  },
  SEMAP309: {
    country: 'Sweden - SE (030)',
    city: 'Stockholm',
    name: 'Oskarshamn',
  },
  SEMAP310: {
    country: 'Sweden - SE (030)',
    city: 'Stockholm',
    name: 'Sölvesborg',
  },
  SEMAP311: {
    country: 'Sweden - SE (030)',
    city: 'Stockholm',
    name: 'Västervik',
  },
  SESGP204: {
    country: 'Sweden - SE (030)',
    city: 'Stockholm',
    name: 'Varberg',
  },
  SESTH100: {
    country: 'Sweden - SE (030)',
    city: 'Stockholm',
    name: 'Stockholm',
  },
  SESTP012: {
    country: 'Sweden - SE (030)',
    city: 'Stockholm',
    name: 'Kapellskär',
  },
  SESTP101: {
    country: 'Sweden - SE (030)',
    city: 'Stockholm',
    name: 'Norrköping',
  },
  SESTP102: {
    country: 'Sweden - SE (030)',
    city: 'Stockholm',
    name: 'Kappelskär',
  },
  SESTP103: {
    country: 'Sweden - SE (030)',
    city: 'Stockholm',
    name: 'Södertälje',
  },
  SESTP104: {
    country: 'Sweden - SE (030)',
    city: 'Stockholm',
    name: 'Nynäshamn',
  },
  SESTP105: {
    country: 'Sweden - SE (030)',
    city: 'Stockholm',
    name: 'Sundsvall',
  },
  SESTP106: {
    country: 'Sweden - SE (030)',
    city: 'Stockholm',
    name: 'Grisslehamn-Norrtälje',
  },
  SESTP107: {
    country: 'Sweden - SE (030)',
    city: 'Stockholm',
    name: 'Hallstavik-Norrtälje',
  },
  SESTP108: {
    country: 'Sweden - SE (030)',
    city: 'Stockholm',
    name: 'Gävle',
  },
  SESTP109: {
    country: 'Sweden - SE (030)',
    city: 'Stockholm',
    name: 'Husum-Örnsköldsvik',
  },
  SESTP110: {
    country: 'Sweden - SE (030)',
    city: 'Stockholm',
    name: 'Härnösand',
  },
  SESTP111: {
    country: 'Sweden - SE (030)',
    city: 'Stockholm',
    name: 'Iggesund-Hudiksvall',
  },
  SESTP112: {
    country: 'Sweden - SE (030)',
    city: 'Stockholm',
    name: 'Oxelösund',
  },
  SESTP113: {
    country: 'Sweden - SE (030)',
    city: 'Stockholm',
    name: 'Piteå',
  },
  SESTP114: {
    country: 'Sweden - SE (030)',
    city: 'Stockholm',
    name: 'Skelleftehamn',
  },
  SESTP115: {
    country: 'Sweden - SE (030)',
    city: 'Stockholm',
    name: 'Umeå/Holmsund',
  },
  SESTP116: {
    country: 'Sweden - SE (030)',
    city: 'Stockholm',
    name: 'Lugnvik-Kramfors',
  },
  SESTP117: {
    country: 'Sweden - SE (030)',
    city: 'Stockholm',
    name: 'Västerås',
  },
  SESTP118: {
    country: 'Sweden - SE (030)',
    city: 'Stockholm',
    name: 'Domsjö-Örnsköldsvik',
  },
  SESTP122: {
    country: 'Sweden - SE (030)',
    city: 'Stockholm',
    name: 'Ala',
  },
  SESTP123: {
    country: 'Sweden - SE (030)',
    city: 'Stockholm',
    name: 'Rönnskär',
  },
  SESTP124: {
    country: 'Sweden - SE (030)',
    city: 'Stockholm',
    name: 'Tunadal',
  },
  SESTP125: {
    country: 'Sweden - SE (030)',
    city: 'Visby',
    name: 'Visby',
  },
  SESTP126: {
    country: 'Sweden - SE (030)',
    city: 'Norvik',
    name: 'Norvik',
  },
  SI000010: {
    country: 'Slovenia - SI (091)',
    city: 'LJUBLJANA',
    name: 'GENERALNI FINANČNI URAD',
  },
  SI001000: {
    country: 'Slovenia - SI (091)',
    city: 'LJUBLJANA',
    name: 'FINANČNI URAD LJUBLJANA',
  },
  SI001026: {
    country: 'Slovenia - SI (091)',
    city: 'KRANJ',
    name: 'ODDELEK ZA CARINJENJE KRANJ',
  },
  SI001034: {
    country: 'Slovenia - SI (091)',
    city: 'BRNIK AERODROM',
    name: 'ODDELEK ZA CARINJENJE LETALIŠČE BRNIK',
  },
  SI001042: {
    country: 'Slovenia - SI (091)',
    city: 'LJUBLJANA',
    name: 'ODDELEK ZA CARINJENJE POŠTNIH POŠILJK',
  },
  SI001050: {
    country: 'Slovenia - SI (091)',
    city: 'NOVO MESTO',
    name: 'ODDELEK ZA CARINJENJE NOVO MESTO',
  },
  SI001115: {
    country: 'Slovenia - SI (091)',
    city: 'METLIKA',
    name: 'CARINSKA PISARNA METLIKA',
  },
  SI001123: {
    country: 'Slovenia - SI (091)',
    city: 'JESENICE NA DOLENJSKEM',
    name: 'ODDELEK ZA CARINJENJE OBREŽJE',
  },
  SI001800: {
    country: 'Slovenia - SI (091)',
    city: 'LJUBLJANA',
    name: 'ODDELEK ZA TROŠARINE LJUBLJANA',
  },
  SI001850: {
    country: 'Slovenia - SI (091)',
    city: 'NOVO MESTO',
    name: 'ODDELEK ZA TROŠARINE NOVO MESTO',
  },
  SI001913: {
    country: 'Slovenia - SI (091)',
    city: 'LJUBLJANA',
    name: 'ODDELEK ZA CARINJENJE LJUBLJANA',
  },
  SI002006: {
    country: 'Slovenia - SI (091)',
    city: 'CELJE',
    name: 'FINANČNI URAD CELJE',
  },
  SI002022: {
    country: 'Slovenia - SI (091)',
    city: 'CELJE',
    name: 'ODDELEK ZA CARINJENJE CELJE',
  },
  SI002800: {
    country: 'Slovenia - SI (091)',
    city: 'CELJE',
    name: 'ODDELEK ZA TROŠARINE CELJE',
  },
  SI003001: {
    country: 'Slovenia - SI (091)',
    city: 'NOVO MESTO',
    name: 'FINANČNI URAD NOVO MESTO',
  },
  SI003053: {
    country: 'Slovenia - SI (091)',
    city: 'ŠENTJANŽ PRI DRAVOGRADU',
    name: 'CARINSKA PISARNA DRAVOGRAD',
  },
  SI003850: {
    country: 'Slovenia - SI (091)',
    city: 'JESENICE NA DOLENJSKEM',
    name: 'ODDELEK ZA TROŠARINE OBREŽJE',
  },
  SI004009: {
    country: 'Slovenia - SI (091)',
    city: 'MURSKA SOBOTA',
    name: 'FINANČNI URAD MURSKA SOBOTA',
  },
  SI004017: {
    country: 'Slovenia - SI (091)',
    city: 'MURSKA SOBOTA',
    name: 'ODDELEK ZA CARINJENJE MURSKA SOBOTA',
  },
  SI004800: {
    country: 'Slovenia - SI (091)',
    city: 'MURSKA SOBOTA',
    name: 'ODDELEK ZA TROŠARINE MURSKA SOBOTA',
  },
  SI005001: {
    country: 'Slovenia - SI (091)',
    city: 'KRANJ',
    name: 'FINANČNI URAD KRANJ',
  },
  SI005080: {
    country: 'Slovenia - SI (091)',
    city: 'JESENICE',
    name: 'CARINSKA PISARNA JESENICE',
  },
  SI005800: {
    country: 'Slovenia - SI (091)',
    city: 'JESENICE',
    name: 'ODDELEK ZA TROŠARINE IN OD JESENICE',
  },
  SI006001: {
    country: 'Slovenia - SI (091)',
    city: 'KOPER',
    name: 'FINANČNI URAD KOPER',
  },
  SI006036: {
    country: 'Slovenia - SI (091)',
    city: 'KOPER',
    name: 'ODDELEK ZA CARINJENJE KOPER',
  },
  SI006044: {
    country: 'Slovenia - SI (091)',
    city: 'KOPER',
    name: 'ODDELEK ZA MEJNO KONTROLO LUKA KOPER',
  },
  SI006090: {
    country: 'Slovenia - SI (091)',
    city: 'PIRAN',
    name: 'CARINSKA PISARNA OBMORSKI MEJNI PREHOD PIRAN',
  },
  SI006170: {
    country: 'Slovenia - SI (091)',
    city: 'SEČOVLJE',
    name: 'CARINSKA PISARNA MEJNI PREHOD LETALIŠČE PORTOROŽ',
  },
  SI006800: {
    country: 'Slovenia - SI (091)',
    city: 'KOPER',
    name: 'ODDELEK ZA TROŠARINE KOPER',
  },
  SI007008: {
    country: 'Slovenia - SI (091)',
    city: 'MARIBOR',
    name: 'FINANČNI URAD MARIBOR',
  },
  SI007040: {
    country: 'Slovenia - SI (091)',
    city: 'OREHOVA VAS',
    name: 'CARINSKA PISARNA MEJNI PREHOD LETALIŠČE MARIBOR',
  },
  SI007067: {
    country: 'Slovenia - SI (091)',
    city: 'MARIBOR',
    name: 'ODDELEK ZA CARINJENJE MARIBOR',
  },
  SI007075: {
    country: 'Slovenia - SI (091)',
    city: 'PODLEHNIK',
    name: 'CARINSKA PISARNA GRUŠKOVJE',
  },
  SI007800: {
    country: 'Slovenia - SI (091)',
    city: 'MARIBOR',
    name: 'ODDELEK ZA TROŠARINE MARIBOR',
  },
  SI008004: {
    country: 'Slovenia - SI (091)',
    city: 'NOVA GORICA',
    name: 'FINANČNI URAD NOVA GORICA',
  },
  SI008005: {
    country: 'Slovenia - SI (091)',
    city: 'ŠEMPETER PRI GORICI',
    name: 'CENTRAL TRANSIT OFFICE',
  },
  SI008071: {
    country: 'Slovenia - SI (091)',
    city: 'SPODNJA IDRIJA',
    name: 'CARINSKA PISARNA IDRIJA',
  },
  SI008128: {
    country: 'Slovenia - SI (091)',
    city: 'ŠEMPETER PRI GORICI',
    name: 'ODDELEK ZA CARINJENJE VRTOJBA',
  },
  SI008800: {
    country: 'Slovenia - SI (091)',
    city: 'ŠEMPETER PRI GORICI',
    name: 'ODDELEK ZA TROŠARINE NOVA GORICA',
  },
  SI009043: {
    country: 'Slovenia - SI (091)',
    city: 'SEŽANA',
    name: 'ODDELEK ZA CARINJENJE SEŽANA',
  },
  SK100000: {
    country: 'Slovakia - SK (063)',
    city: 'BRATISLAVA',
    name: 'FINANČNÉ RIADITEĽSTVO SR',
  },
  SK500000: {
    country: 'Slovakia - SK (063)',
    city: 'Bratislava',
    name: 'Colný kriminálny úrad SR',
  },
  SK510000: {
    country: 'Slovakia - SK (063)',
    city: 'BANSKÁ BYSTRICA',
    name: 'Colný úrad BANSKÁ BYSTRICA',
  },
  SK515200: {
    country: 'Slovakia - SK (063)',
    city: 'Banská Bystrica',
    name: 'Pobočka colného úradu Banská Bystrica Pošta',
  },
  SK516400: {
    country: 'Slovakia - SK (063)',
    city: 'BANSKÁ BYSTRICA',
    name: 'Pobočka colného úradu BANSKÁ BYSTRICA',
  },
  SK517200: {
    country: 'Slovakia - SK (063)',
    city: 'LUČENEC',
    name: 'Pobočka colného úradu LUČENEC NS',
  },
  SK517500: {
    country: 'Slovakia - SK (063)',
    city: 'JESENSKÉ',
    name: 'Pobočka colného úradu JESENSKÉ',
  },
  SK517700: {
    country: 'Slovakia - SK (063)',
    city: 'Zvolen',
    name: 'Zvolen NS Pobočka colného úradu',
  },
  SK520000: {
    country: 'Slovakia - SK (063)',
    city: 'BRATISLAVA',
    name: 'Colný úrad CÚ - BRATISLAVA',
  },
  SK523400: {
    country: 'Slovakia - SK (063)',
    city: 'Bratislava',
    name: 'SCÚ Oddelenie špecialných technológií',
  },
  SK524100: {
    country: 'Slovakia - SK (063)',
    city: 'BRATISLAVA',
    name: 'Bratislava Letisko-cestovný styk',
  },
  SK524200: {
    country: 'Slovakia - SK (063)',
    city: 'BRATISLAVA',
    name: 'Pobočka colného úradu BRATISLAVA - LETISKO',
  },
  SK525100: {
    country: 'Slovakia - SK (063)',
    city: 'Bratislava',
    name: 'Pobočka colného úradu Bratislava - Pošta',
  },
  SK526400: {
    country: 'Slovakia - SK (063)',
    city: 'BRATISLAVA',
    name: 'Pobočka colného úradu BRATISLAVA - PRÍSTAV',
  },
  SK526500: {
    country: 'Slovakia - SK (063)',
    city: 'Bratislava',
    name: 'Pobočka colného úradu PCÚ Bratislava - Staviteľská',
  },
  SK526700: {
    country: 'Slovakia - SK (063)',
    city: 'BRATISLAVA',
    name: 'Pobočka colného úradu BRATISLAVA Jonáša',
  },
  SK527400: {
    country: 'Slovakia - SK (063)',
    city: 'BRATISLAVA',
    name: 'Pobočka colného úradu BRATISLAVA MILETIČOVA',
  },
  SK528400: {
    country: 'Slovakia - SK (063)',
    city: 'Bratislava',
    name: 'SCÚ Bratislava - centrála',
  },
  SK530000: {
    country: 'Slovakia - SK (063)',
    city: 'MICHALOVCE',
    name: 'Colný úrad CÚ - MICHALOVCE',
  },
  SK531100: {
    country: 'Slovakia - SK (063)',
    city: 'ČIERNA NAD TISOU',
    name: 'Pobočka colného úradu ČIERNA NAD TISOU -ŽELEZNICA "A"',
  },
  SK531102: {
    country: 'Slovakia - SK (063)',
    city: 'ČIERNA NAD TISOU',
    name: 'Pobočka colného úradu ČIERNA NAD TISOU-ŠIROKÝ ROZCHOD "C"',
  },
  SK531103: {
    country: 'Slovakia - SK (063)',
    city: 'ČIERNA NAD TISOU',
    name: 'Pracovisko pobočky colného úradu ČIERNA NAD TISOU - TKD DOBRÁ',
  },
  SK531400: {
    country: 'Slovakia - SK (063)',
    city: 'MAŤOVCE',
    name: 'Pobočka colného úradu MAŤOVCE - ŽELEZNICA',
  },
  SK532100: {
    country: 'Slovakia - SK (063)',
    city: 'VYŠNÉ NEMECKÉ',
    name: 'Pobočka colného úradu VYŠNÉ NEMECKÉ',
  },
  SK532300: {
    country: 'Slovakia - SK (063)',
    city: 'UBĽA',
    name: 'Pobočka colného úradu UBĽA',
  },
  SK536100: {
    country: 'Slovakia - SK (063)',
    city: 'MICHALOVCE',
    name: 'Pobočka colného úradu MICHALOVCE',
  },
  SK536400: {
    country: 'Slovakia - SK (063)',
    city: 'TREBIŠOV',
    name: 'TREBIŠOV',
  },
  SK536700: {
    country: 'Slovakia - SK (063)',
    city: 'SNINA',
    name: 'Pobočka colného úradu SNINA',
  },
  SK560000: {
    country: 'Slovakia - SK (063)',
    city: 'KOŠICE',
    name: 'Colný úrad CÚ - KOŠICE',
  },
  SK564100: {
    country: 'Slovakia - SK (063)',
    city: 'KOŠICE',
    name: 'Pobočka colného úradu KOŠICE - LETISKO',
  },
  SK565100: {
    country: 'Slovakia - SK (063)',
    city: 'Košice',
    name: 'Pobočka colného úradu KOŠICE POŠTA',
  },
  SK565200: {
    country: 'Slovakia - SK (063)',
    city: 'KOŠICE',
    name: 'Pobočka colného úradu KOŠICE',
  },
  SK565300: {
    country: 'Slovakia - SK (063)',
    city: 'Košice Haniska',
    name: 'Pobočka colného úradu Košice - Haniska',
  },
  SK565301: {
    country: 'Slovakia - SK (063)',
    city: 'Haniska pri Košiciach',
    name: 'pobočka colného úradu PCÚ Košice Haniska - pracovisko ŠRT',
  },
  SK566500: {
    country: 'Slovakia - SK (063)',
    city: 'ROŽŇAVA',
    name: 'Pobočka colného úradu ROŽŇAVA',
  },
  SK567100: {
    country: 'Slovakia - SK (063)',
    city: 'SPIŠSKÁ NOVÁ VES',
    name: 'Pobočka colného úradu SPIŠSKÁ NOVÁ VES',
  },
  SK580000: {
    country: 'Slovakia - SK (063)',
    city: 'Trnava',
    name: 'Colný úrad Colný úrad Trnava',
  },
  SK586100: {
    country: 'Slovakia - SK (063)',
    city: 'DUNAJSKÁ STREDA',
    name: 'Pobočka colného úradu DUNAJSKÁ STREDA',
  },
  SK586101: {
    country: 'Slovakia - SK (063)',
    city: 'Dunajská Streda',
    name: 'Pobočka colného úradu DUNAJSKÁ STREDA - METRANS',
  },
  SK586400: {
    country: 'Slovakia - SK (063)',
    city: 'TRNAVA',
    name: 'Pobočka colného úradu TRNAVA',
  },
  SK586500: {
    country: 'Slovakia - SK (063)',
    city: 'SENICA',
    name: 'Pobočka colného úradu SENICA',
  },
  SK586501: {
    country: 'Slovakia - SK (063)',
    city: 'Skalica',
    name: 'Pobočka colného úradu Senica - Skalica',
  },
  SK587100: {
    country: 'Slovakia - SK (063)',
    city: 'GALANTA',
    name: 'Pobočka colného úradu GALANTA',
  },
  SK587300: {
    country: 'Slovakia - SK (063)',
    city: 'SKALICA',
    name: 'Pobočka colného úradu SKALICA',
  },
  SK587400: {
    country: 'Slovakia - SK (063)',
    city: 'TRNAVA',
    name: 'Pobočka colného úradu TRNAVA',
  },
  SK600000: {
    country: 'Slovakia - SK (063)',
    city: 'ŽILINA',
    name: 'Colný úrad CÚ - ŽILINA',
  },
  SK605200: {
    country: 'Slovakia - SK (063)',
    city: 'Žilina',
    name: 'Pobočka colného úradu Žilina Pošta',
  },
  SK606300: {
    country: 'Slovakia - SK (063)',
    city: 'MARTIN',
    name: 'Pobočka colného úradu MARTIN',
  },
  SK606500: {
    country: 'Slovakia - SK (063)',
    city: 'ČADCA',
    name: 'Pobočka colného úradu ČADCA',
  },
  SK606900: {
    country: 'Slovakia - SK (063)',
    city: 'ŽILINA',
    name: 'Pobočka colného úradu ŽILINA - NS',
  },
  SK607300: {
    country: 'Slovakia - SK (063)',
    city: 'LIPTOVSKÝ MIKULÁŠ',
    name: 'Pobočka colného úradu LIPTOVSKÝ MIKULÁŠ',
  },
  SK607600: {
    country: 'Slovakia - SK (063)',
    city: 'TRSTENÁ',
    name: 'Pobočka colného úradu TRSTENÁ',
  },
  SK607700: {
    country: 'Slovakia - SK (063)',
    city: 'RUŽOMBEROK',
    name: 'Pobočka colného úradu RUŽOMBEROK',
  },
  SK607800: {
    country: 'Slovakia - SK (063)',
    city: 'ŽILINA',
    name: 'Pobočka colného úradu ŽILINA',
  },
  SK610000: {
    country: 'Slovakia - SK (063)',
    city: 'NITRA',
    name: 'Colný úrad CÚ - NITRA',
  },
  SK615500: {
    country: 'Slovakia - SK (063)',
    city: 'KOMÁRNO',
    name: 'Pobočka colného úradu KOMÁRNO',
  },
  SK615700: {
    country: 'Slovakia - SK (063)',
    city: 'LEVICE',
    name: 'Pobočka colného úradu LEVICE',
  },
  SK615800: {
    country: 'Slovakia - SK (063)',
    city: 'NOVÉ ZÁMKY',
    name: 'Pobočka colného úradu NOVÉ ZÁMKY - NS',
  },
  SK615900: {
    country: 'Slovakia - SK (063)',
    city: 'ŠTÚROVO',
    name: 'Pobočka colného úradu ŠTÚROVO',
  },
  SK616300: {
    country: 'Slovakia - SK (063)',
    city: 'TOPOĽČANY',
    name: 'Pobočka colného úradu TOPOĽČANY',
  },
  SK616500: {
    country: 'Slovakia - SK (063)',
    city: 'NITRA -Kynek',
    name: 'Pobočka colného úradu NITRA',
  },
  SK618200: {
    country: 'Slovakia - SK (063)',
    city: 'NITRA',
    name: 'Pobočka colného úradu NITRA PRIEMYSELNÁ',
  },
  SK620000: {
    country: 'Slovakia - SK (063)',
    city: 'PREŠOV',
    name: 'Colný úrad CÚ - PREŠOV',
  },
  SK624600: {
    country: 'Slovakia - SK (063)',
    city: 'POPRAD',
    name: 'Miestna jednotka analýzy rizika POPRAD',
  },
  SK626200: {
    country: 'Slovakia - SK (063)',
    city: 'HUMENNÉ',
    name: 'Pobočka colného úradu HUMENNÉ',
  },
  SK626300: {
    country: 'Slovakia - SK (063)',
    city: 'PREŠOV',
    name: 'Pobočka colného úradu PREŠOV NS',
  },
  SK626500: {
    country: 'Slovakia - SK (063)',
    city: 'SVIDNÍK',
    name: 'Pobočka colného úradu SVIDNÍK',
  },
  SK627200: {
    country: 'Slovakia - SK (063)',
    city: 'STARÁ ĽUBOVŇA',
    name: 'Pobočka colného úradu STARÁ ĽUBOVŇA',
  },
  SK627300: {
    country: 'Slovakia - SK (063)',
    city: 'POPRAD',
    name: 'Pobočka colného úradu POPRAD',
  },
  SK660000: {
    country: 'Slovakia - SK (063)',
    city: 'TRENČÍN',
    name: 'Colný úrad CÚ - TRENČÍN',
  },
  SK666100: {
    country: 'Slovakia - SK (063)',
    city: 'TRENČÍN',
    name: 'Pobočka colného úradu TRENČÍN KASÁRENSKÁ',
  },
  SK666300: {
    country: 'Slovakia - SK (063)',
    city: 'NOVÉ MESTO NAD VÁHOM',
    name: 'Pobočka colného úradu NOVÉ MESTO NAD VÁHOM',
  },
  SK666500: {
    country: 'Slovakia - SK (063)',
    city: 'PÚCHOV',
    name: 'Pobočka colného úradu PÚCHOV',
  },
  SK666700: {
    country: 'Slovakia - SK (063)',
    city: 'PRIEVIDZA',
    name: 'Pobočka colného úradu PRIEVIDZA',
  },
  SK667100: {
    country: 'Slovakia - SK (063)',
    city: 'ILAVA',
    name: 'Pobočka colného úradu ILAVA',
  },
  SK667200: {
    country: 'Slovakia - SK (063)',
    city: 'Bánovce nad Bebravou',
    name: 'Pobočka colného úradu Bánovce nad Bebravou',
  },
  SK667300: {
    country: 'Slovakia - SK (063)',
    city: 'TRENČÍN',
    name: 'Pobočka colného úradu TRENČÍN K VÝSTAVISKU',
  },
  SK990000: {
    country: 'Slovakia - SK (063)',
    city: 'Bratislava',
    name: 'Úrad pre VHS Úrad pre vybrané hosp. subjekty',
  },
  SM000001: {
    country: 'San Marino - SM (047)',
    city: 'Borgo Maggiore RSM',
    name: 'Ufficio Tributario Rep. San Marino',
  },
  SM000002: {
    country: 'San Marino - SM (047)',
    city: 'Dogana RSM',
    name: 'Ufficio Tributario Rep. San Marino',
  },
  SM000008: {
    country: 'San Marino - SM (047)',
    city: 'Dogana RSM',
    name: 'Ufficio Tributario Rep. San Marino',
  },
  SM000009: {
    country: 'San Marino - SM (047)',
    city: 'Dogana RSM',
    name: 'Ufficio Tributario Rep. San Marino',
  },
  TR000400: {
    country: 'Türkiye - TR (052)',
    city: 'Ankara',
    name: 'Gümrükler Genel Müdürlüğü',
  },
  TR040001: {
    country: 'Türkiye - TR (052)',
    city: 'DOĞUBAYAZIT / AĞRI',
    name: 'Gürbulak Güm. Tic. Bölge Müdürlüğü',
  },
  TR041500: {
    country: 'Türkiye - TR (052)',
    city: 'AĞRI',
    name: 'DOĞUBEYAZIT GÜMRÜK MÜDÜRLÜĞÜ',
  },
  TR041600: {
    country: 'Türkiye - TR (052)',
    city: 'AĞRI',
    name: 'GÜRBULAK GÜMRÜK MÜDÜRLÜĞÜ',
  },
  TR041700: {
    country: 'Türkiye - TR (052)',
    city: 'ERZURUM',
    name: 'ERZURUM GÜMRÜK MÜDÜRLÜĞÜ',
  },
  TR041800: {
    country: 'Türkiye - TR (052)',
    city: 'IĞDIR',
    name: 'DİLUCU GÜMRÜK MÜDÜRLÜĞÜ',
  },
  TR041900: {
    country: 'Türkiye - TR (052)',
    city: 'KARS',
    name: 'KARS GÜMRÜK MÜDÜRLÜĞÜ',
  },
  TR042200: {
    country: 'Türkiye - TR (052)',
    city: 'IĞDIR',
    name: 'IĞDIR GÜMRÜK MÜDÜRLÜĞÜ',
  },
  TR060000: {
    country: 'Türkiye - TR (052)',
    city: 'Behiçbey Yenimahalle / ANKARA',
    name: 'ORTA ANADOLU GÜMRÜK VE TİCARET BM',
  },
  TR060200: {
    country: 'Türkiye - TR (052)',
    city: 'ANKARA',
    name: 'ESENBOĞA GÜMRÜK MÜDÜRLÜĞÜ',
  },
  TR060500: {
    country: 'Türkiye - TR (052)',
    city: 'KONYA',
    name: 'KONYA GÜMRÜK MÜDÜRLÜĞÜ',
  },
  TR060600: {
    country: 'Türkiye - TR (052)',
    city: 'KAYSERİ',
    name: 'KAYSERİ GÜMRÜK MÜDÜRLÜĞÜ',
  },
  TR060700: {
    country: 'Türkiye - TR (052)',
    city: 'NEVŞEHİR',
    name: 'ÜRGÜP GÜMRÜK MÜDÜRLÜĞÜ',
  },
  TR061000: {
    country: 'Türkiye - TR (052)',
    city: 'AKSARAY',
    name: 'AKSARAY GÜMRÜK MÜDÜRLÜĞÜ',
  },
  TR061300: {
    country: 'Türkiye - TR (052)',
    city: 'KIRIKKALE',
    name: 'KIRIKKALE O.A.R. GÜMRÜK MÜDÜRLÜĞÜ',
  },
  TR061500: {
    country: 'Türkiye - TR (052)',
    city: 'ANKARA',
    name: 'ANKARA GAR GÜMRÜK MÜDÜRLÜĞÜ',
  },
  TR061600: {
    country: 'Türkiye - TR (052)',
    city: 'ANKARA',
    name: 'ANKARA GÜMRÜK MÜDÜRLÜĞÜ',
  },
  TR062100: {
    country: 'Türkiye - TR (052)',
    city: 'KARAMAN',
    name: 'KARAMAN GÜMRÜK MÜDÜRLÜĞÜ',
  },
  TR062200: {
    country: 'Türkiye - TR (052)',
    city: 'KIRŞEHİR',
    name: 'KIRŞEHİR GÜMRÜK MÜDÜRLÜĞÜ',
  },
  TR062300: {
    country: 'Türkiye - TR (052)',
    city: 'NİĞDE',
    name: 'NİĞDE GÜMRÜK MÜDÜRLÜĞÜ',
  },
  TR062400: {
    country: 'Türkiye - TR (052)',
    city: 'KAYSERİ',
    name: 'KAYSERİ SERBEST BÖLGE MÜDÜRLÜĞÜ',
  },
  TR070000: {
    country: 'Türkiye - TR (052)',
    city: 'ANTALYA',
    name: 'BATI AKDENİZ GÜMRÜK VE TİCARET BM',
  },
  TR070100: {
    country: 'Türkiye - TR (052)',
    city: 'ANTALYA',
    name: 'ANTALYA GÜMRÜK MÜDÜRLÜĞÜ',
  },
  TR070200: {
    country: 'Türkiye - TR (052)',
    city: 'ANTALYA',
    name: 'ANTALYA HAVALİMANI GÜMRÜK MÜDÜRLÜĞÜ',
  },
  TR070300: {
    country: 'Türkiye - TR (052)',
    city: 'ANTALYA',
    name: 'ANTALYA SERBEST BÖLGE GÜMRÜK MDR',
  },
  TR070400: {
    country: 'Türkiye - TR (052)',
    city: 'ALANYA',
    name: 'ALANYA GÜMRÜK MÜDÜRLÜĞÜ',
  },
  TR070500: {
    country: 'Türkiye - TR (052)',
    city: 'ANTALYA',
    name: 'KAŞ GÜMRÜK MÜDÜRLÜĞÜ',
  },
  TR070600: {
    country: 'Türkiye - TR (052)',
    city: 'ANTALYA',
    name: 'FİNİKE GÜMRÜK MÜDÜRLÜĞÜ',
  },
  TR070700: {
    country: 'Türkiye - TR (052)',
    city: 'ISPARTA',
    name: 'ISPARTA GÜMRÜK MÜDÜRLÜĞÜ',
  },
  TR070800: {
    country: 'Türkiye - TR (052)',
    city: 'MUĞLA',
    name: 'DALAMAN HAVALİMANI GÜMRÜK MÜDÜRLÜĞÜ',
  },
  TR070900: {
    country: 'Türkiye - TR (052)',
    city: 'MUĞLA',
    name: 'FETHİYE GÜMRÜK MÜDÜRLÜĞÜ',
  },
  TR071000: {
    country: 'Türkiye - TR (052)',
    city: 'MUĞLA',
    name: 'BODRUM GÜMRÜK MÜDÜRLÜĞÜ',
  },
  TR071300: {
    country: 'Türkiye - TR (052)',
    city: 'MUĞLA',
    name: 'MİLAS HAVALİMANI GÜMRÜK MÜDÜRLÜĞÜ',
  },
  TR071400: {
    country: 'Türkiye - TR (052)',
    city: 'MUĞLA',
    name: 'MARMARİS GÜMRÜK MÜDÜRLÜĞÜ',
  },
  TR080000: {
    country: 'Türkiye - TR (052)',
    city: 'Artvin',
    name: 'KAÇKAR GÜMRÜK VE TİCARET BÖLGE MÜD.',
  },
  TR080100: {
    country: 'Türkiye - TR (052)',
    city: 'ARTVİN',
    name: 'HOPA GÜMRÜK MÜDÜRLÜĞÜ',
  },
  TR080200: {
    country: 'Türkiye - TR (052)',
    city: 'ARTVİN',
    name: 'SARP GÜMRÜK MÜDÜRLÜĞÜ',
  },
  TR080300: {
    country: 'Türkiye - TR (052)',
    city: 'ARDAHAN',
    name: 'AKTAŞ GÜMRÜK MÜDÜRLÜĞÜ',
  },
  TR080400: {
    country: 'Türkiye - TR (052)',
    city: 'ARDAHAN',
    name: 'TÜRKGÖZÜ GÜMRÜK MÜDÜRLÜĞÜ',
  },
  TR080500: {
    country: 'Türkiye - TR (052)',
    city: 'RİZE',
    name: 'RİZE GÜMRÜK MÜDÜRLÜĞÜ',
  },
  TR160000: {
    country: 'Türkiye - TR (052)',
    city: 'Nilüfer / BURSA',
    name: 'ULUDAĞ GÜMRÜK VE TİCARET BM',
  },
  TR160100: {
    country: 'Türkiye - TR (052)',
    city: 'BURSA',
    name: 'BURSA GÜMRÜK MÜDÜRLÜĞÜ',
  },
  TR160200: {
    country: 'Türkiye - TR (052)',
    city: 'BURSA',
    name: 'MUDANYA GÜMRÜK MÜDÜRLÜĞÜ',
  },
  TR160400: {
    country: 'Türkiye - TR (052)',
    city: 'BURSA',
    name: 'GEMLİK GÜMRÜK MÜDÜRLÜĞÜ',
  },
  TR160600: {
    country: 'Türkiye - TR (052)',
    city: 'BALIKESİR',
    name: 'BANDIRMA GÜMRÜK MÜDÜRLÜĞÜ',
  },
  TR160700: {
    country: 'Türkiye - TR (052)',
    city: 'ESKİŞEHİR',
    name: 'ESKİŞEHİR GÜMRÜK MÜDÜRLÜĞÜ',
  },
  TR161200: {
    country: 'Türkiye - TR (052)',
    city: 'BALIKESİR',
    name: 'BALIKESİR GÜMRÜK MÜDÜRLÜĞÜ',
  },
  TR161500: {
    country: 'Türkiye - TR (052)',
    city: 'YALOVA',
    name: 'YALOVA GÜMRÜK MÜDÜRLÜĞÜ',
  },
  TR161600: {
    country: 'Türkiye - TR (052)',
    city: 'BALIKESİR',
    name: 'AYVALIK GÜMRÜK MÜDÜRLÜĞÜ',
  },
  TR161700: {
    country: 'Türkiye - TR (052)',
    city: 'BURSA',
    name: 'İNEGÖL GÜMRÜK MÜDÜRLÜĞÜ',
  },
  TR161900: {
    country: 'Türkiye - TR (052)',
    city: 'KÜTAHYA',
    name: 'KÜTAHYA GÜMRÜK MÜDÜRLÜĞÜ',
  },
  TR210000: {
    country: 'Türkiye - TR (052)',
    city: 'Silopi / ŞIRNAK',
    name: 'İPEKYOLU GÜMRÜK VE TİCARET BM',
  },
  TR210100: {
    country: 'Türkiye - TR (052)',
    city: 'DİYARBAKIR',
    name: 'DİYARBAKIR GÜMRÜK MÜDÜRLÜĞÜ',
  },
  TR210200: {
    country: 'Türkiye - TR (052)',
    city: 'MARDİN',
    name: 'MARDİN GÜMRÜK MÜDÜRLÜĞÜ',
  },
  TR210300: {
    country: 'Türkiye - TR (052)',
    city: 'MARDİN',
    name: 'NUSAYBİN GÜMRÜK MÜDÜRLÜĞÜ',
  },
  TR210400: {
    country: 'Türkiye - TR (052)',
    city: 'ŞIRNAK',
    name: 'HABUR GÜMRÜK MÜDÜRLÜĞÜ',
  },
  TR210500: {
    country: 'Türkiye - TR (052)',
    city: 'BATMAN',
    name: 'BATMAN GÜMRÜK MÜDÜRLÜĞÜ',
  },
  TR210600: {
    country: 'Türkiye - TR (052)',
    city: 'ŞIRNAK',
    name: 'İPEKYOLU GÜMRÜK MÜDÜRLÜĞÜ',
  },
  TR220000: {
    country: 'Türkiye - TR (052)',
    city: 'EDİRNE',
    name: 'TRAKYA GÜMRÜK VE TİCARET BM',
  },
  TR220100: {
    country: 'Türkiye - TR (052)',
    city: 'EDİRNE',
    name: 'KAPIKULE YOLCU SALONU GÜMRÜK MÜD',
  },
  TR220200: {
    country: 'Türkiye - TR (052)',
    city: 'EDİRNE',
    name: 'KAPIKULE TIR GÜMRÜK MÜDÜRLÜĞÜ',
  },
  TR220400: {
    country: 'Türkiye - TR (052)',
    city: 'EDİRNE',
    name: 'KAPIKULE GAR GÜMRÜK MÜDÜRLÜĞÜ',
  },
  TR220500: {
    country: 'Türkiye - TR (052)',
    city: 'EDİRNE',
    name: 'İPSALA GÜMRÜK MÜDÜRLÜĞÜ',
  },
  TR220600: {
    country: 'Türkiye - TR (052)',
    city: 'EDİRNE',
    name: 'UZUNKÖPRÜ GÜMRÜK MÜDÜRLÜĞÜ',
  },
  TR220700: {
    country: 'Türkiye - TR (052)',
    city: 'EDİRNE',
    name: 'PAZARKULE GÜMRÜK MÜDÜRLÜĞÜ',
  },
  TR220800: {
    country: 'Türkiye - TR (052)',
    city: 'KIRKLARELİ',
    name: 'DEREKÖY GÜMRÜK MÜDÜRLÜĞÜ',
  },
  TR221300: {
    country: 'Türkiye - TR (052)',
    city: 'EDİRNE',
    name: 'HAMZABEYLİ GÜMRÜK MÜDÜRLÜĞÜ',
  },
  TR270000: {
    country: 'Türkiye - TR (052)',
    city: 'Şahinbey / GAZİANTEP',
    name: 'GAP GÜMRÜK VE TİCARET BM',
  },
  TR270100: {
    country: 'Türkiye - TR (052)',
    city: 'GAZİANTEP',
    name: 'GAZİANTEP GÜMRÜK MÜDÜRLÜĞÜ',
  },
  TR270200: {
    country: 'Türkiye - TR (052)',
    city: 'GAZİANTEP',
    name: 'İSLAHİYE GÜMRÜK MÜDÜRLÜĞÜ',
  },
  TR270300: {
    country: 'Türkiye - TR (052)',
    city: 'GAZİANTEP',
    name: 'ÖNCÜPINAR GÜMRÜK MÜDÜRLÜĞÜ',
  },
  TR270400: {
    country: 'Türkiye - TR (052)',
    city: 'KİLİS',
    name: 'ÇOBANBEY GÜMRÜK MÜDÜRLÜĞÜ',
  },
  TR270600: {
    country: 'Türkiye - TR (052)',
    city: 'ŞANLIURFA',
    name: 'AKÇAKALE GÜMRÜK MÜDÜRLÜĞÜ',
  },
  TR270900: {
    country: 'Türkiye - TR (052)',
    city: 'ŞANLIURFA',
    name: 'ŞANLIURFA GÜMRÜK MÜDÜRLÜĞÜ',
  },
  TR271000: {
    country: 'Türkiye - TR (052)',
    city: 'GAZİANTEP',
    name: 'KARKAMIŞ GÜMRÜK MÜDÜRLÜĞÜ',
  },
  TR271100: {
    country: 'Türkiye - TR (052)',
    city: 'GAZİANTEP',
    name: 'GAZİANTEP HAVALİMANI GÜMRÜK MDR',
  },
  TR271200: {
    country: 'Türkiye - TR (052)',
    city: 'ADIYAMAN',
    name: 'ADIYAMAN GÜMRÜK MÜDÜRLÜĞÜ',
  },
  TR271300: {
    country: 'Türkiye - TR (052)',
    city: 'GAZİANTEP',
    name: 'NİZİP GÜMRÜK MÜDÜRLÜĞÜ',
  },
  TR310000: {
    country: 'Türkiye - TR (052)',
    city: 'HATAY',
    name: 'DOĞU AKDENİZ GÜMRÜK VE TİCARET BM',
  },
  TR310100: {
    country: 'Türkiye - TR (052)',
    city: 'HATAY',
    name: 'İSKENDERUN GÜMRÜK MÜDÜRLÜĞÜ',
  },
  TR310400: {
    country: 'Türkiye - TR (052)',
    city: 'HATAY',
    name: 'CİLVEGÖZÜ GÜMRÜK MÜDÜRLÜĞÜ',
  },
  TR310500: {
    country: 'Türkiye - TR (052)',
    city: 'HATAY',
    name: 'YAYLADAĞI GÜMRÜK MÜDÜRLÜĞÜ',
  },
  TR310600: {
    country: 'Türkiye - TR (052)',
    city: 'HATAY',
    name: 'İSDEMİR GÜMRÜK MÜDÜRLÜĞÜ',
  },
  TR310800: {
    country: 'Türkiye - TR (052)',
    city: 'ANTAKYA',
    name: 'ANTAKYA GÜMRÜK MÜDÜRLÜĞÜ',
  },
  TR311200: {
    country: 'Türkiye - TR (052)',
    city: 'OSMANİYE',
    name: 'OSMANİYE GÜMRÜK MÜDÜRLÜĞÜ',
  },
  TR311300: {
    country: 'Türkiye - TR (052)',
    city: 'HATAY',
    name: 'ZEYTİN DALI GÜMRÜK MÜDÜRLÜĞÜ',
  },
  TR330000: {
    country: 'Türkiye - TR (052)',
    city: 'Akdeniz / MERSİN',
    name: 'ORTA AKDENİZ GÜMRÜK VE TİCARET BM',
  },
  TR330100: {
    country: 'Türkiye - TR (052)',
    city: 'MERSİN',
    name: 'MERSİN GÜMRÜK MÜDÜRLÜĞÜ',
  },
  TR330500: {
    country: 'Türkiye - TR (052)',
    city: 'MERSİN',
    name: 'MERSİN SERBEST BÖLGE GÜMRÜK MDR',
  },
  TR330600: {
    country: 'Türkiye - TR (052)',
    city: 'MERSİN',
    name: 'MERSİN AKARYAKIT GÜMRÜK MÜDÜRLÜĞÜ',
  },
  TR330700: {
    country: 'Türkiye - TR (052)',
    city: 'MERSİN',
    name: 'TAŞUCU GÜMRÜK MÜDÜRLÜĞÜ',
  },
  TR330900: {
    country: 'Türkiye - TR (052)',
    city: 'ADANA',
    name: 'ADANA GÜMRÜK MÜDÜRLÜĞÜ',
  },
  TR331000: {
    country: 'Türkiye - TR (052)',
    city: 'ADANA',
    name: 'İNCİRLİK GÜMRÜK MÜDÜRLÜĞÜ',
  },
  TR331400: {
    country: 'Türkiye - TR (052)',
    city: 'ADANA',
    name: 'BOTAŞ GÜMRÜK MÜDÜRLÜĞÜ',
  },
  TR331500: {
    country: 'Türkiye - TR (052)',
    city: 'ADANA',
    name: 'YUMURTALIK SERBEST BÖLGE GÜMRÜK MDR',
  },
  TR340000: {
    country: 'Türkiye - TR (052)',
    city: 'Bakırköy / İSTANBUL',
    name: 'İSTANBUL GÜMRÜK VE TİCARET BM',
  },
  TR340100: {
    country: 'Türkiye - TR (052)',
    city: 'İSTANBUL',
    name: 'ATATURK HAVA LİM. YOL. SAL. GÜM.MÜD',
  },
  TR340300: {
    country: 'Türkiye - TR (052)',
    city: 'İSTANBUL',
    name: 'ATATÜRK HAVALİMANI KARGO GÜMRÜK MDR',
  },
  TR340600: {
    country: 'Türkiye - TR (052)',
    city: 'İSTANBUL',
    name: 'İSTANBUL POSTA GÜMRÜK MÜDÜRLÜĞÜ',
  },
  TR340700: {
    country: 'Türkiye - TR (052)',
    city: 'İSTANBUL',
    name: 'BEYLİKDÜZÜ AKARYAKIT GÜMRÜK MÜDÜR.',
  },
  TR340800: {
    country: 'Türkiye - TR (052)',
    city: 'İSTANBUL',
    name: 'KARAKÖY YOLCU SALONU GÜMRÜK MÜD',
  },
  TR340900: {
    country: 'Türkiye - TR (052)',
    city: 'İSTANBUL',
    name: 'HAYDARPAŞA GÜMRÜK MÜDÜRLÜĞÜ',
  },
  TR341200: {
    country: 'Türkiye - TR (052)',
    city: 'İSTANBUL',
    name: 'ERENKÖY GÜMRÜK MÜDÜRLÜĞÜ',
  },
  TR341300: {
    country: 'Türkiye - TR (052)',
    city: 'İSTANBUL',
    name: 'MURATBEY GÜMRÜK MÜDÜRLÜĞÜ',
  },
  TR341453: {
    country: 'Türkiye - TR (052)',
    city: 'ARNAVUTKÖY / İSTANBUL',
    name: 'İSTANBUL HAVALİMANI GÜMRÜK MÜDÜRLÜĞ',
  },
  TR341454: {
    country: 'Türkiye - TR (052)',
    city: 'ARNAVUTKÖY / İSTANBUL',
    name: 'İSTANBUL HAVALİMANI YOLCU SALONU GM',
  },
  TR342000: {
    country: 'Türkiye - TR (052)',
    city: 'İSTANBUL',
    name: 'TRAKYA SERBEST BÖLGE GÜMRÜK MDR',
  },
  TR342200: {
    country: 'Türkiye - TR (052)',
    city: 'İSTANBUL',
    name: 'İSTANBUL DERİ SERBEST BÖLGE GÜMRÜK',
  },
  TR342400: {
    country: 'Türkiye - TR (052)',
    city: 'İSTANBUL',
    name: 'YEŞİLKÖY GÜMRÜK MÜDÜRLÜĞÜ',
  },
  TR342800: {
    country: 'Türkiye - TR (052)',
    city: 'İSTANBUL',
    name: 'ATATÜRK HAVALİMANI SERBEST BÖLGE',
  },
  TR343100: {
    country: 'Türkiye - TR (052)',
    city: 'İSTANBUL',
    name: 'AMBARLI GÜMRÜK MÜDÜRLÜĞÜ',
  },
  TR343200: {
    country: 'Türkiye - TR (052)',
    city: 'İSTANBUL',
    name: 'SABİHA GÖKÇEN GÜMRÜK MÜDÜRLÜĞÜ',
  },
  TR343300: {
    country: 'Türkiye - TR (052)',
    city: 'İSTANBUL',
    name: 'ZEYTİNBURNU GÜMRÜK MÜDÜRLÜĞÜ',
  },
  TR343400: {
    country: 'Türkiye - TR (052)',
    city: 'İSTANBUL',
    name: 'PENDİK GÜMRÜK MÜDÜRLÜĞÜ',
  },
  TR343500: {
    country: 'Türkiye - TR (052)',
    city: 'İSTANBUL',
    name: 'HALKALI GAR GÜMRÜK MÜDÜRLÜĞÜ',
  },
  TR350000: {
    country: 'Türkiye - TR (052)',
    city: 'İZMİR',
    name: 'EGE GÜMRÜK VE TİCARET BM',
  },
  TR350300: {
    country: 'Türkiye - TR (052)',
    city: 'İZMİR',
    name: 'İZMİR GÜMRÜK MÜDÜRLÜĞÜ',
  },
  TR350500: {
    country: 'Türkiye - TR (052)',
    city: 'İZMİR',
    name: 'İZMİR YOLCU SALONU GÜMRÜK MÜDÜRLÜĞÜ',
  },
  TR350700: {
    country: 'Türkiye - TR (052)',
    city: 'İZMİR',
    name: 'ADNAN MENDERES GÜMRÜK MÜDÜRLÜĞÜ',
  },
  TR350900: {
    country: 'Türkiye - TR (052)',
    city: 'İZMİR',
    name: 'DİKİLİ GÜMRÜK MÜDÜRLÜĞÜ',
  },
  TR351000: {
    country: 'Türkiye - TR (052)',
    city: 'İZMİR',
    name: 'ÇEŞME GÜMRÜK MÜDÜRLÜĞÜ',
  },
  TR351100: {
    country: 'Türkiye - TR (052)',
    city: 'İZMİR',
    name: 'FOÇA GÜMRÜK MÜDÜRLÜĞÜ',
  },
  TR351700: {
    country: 'Türkiye - TR (052)',
    city: 'MANİSA',
    name: 'MANİSA GÜMRÜK MÜDÜRLÜĞÜ',
  },
  TR351800: {
    country: 'Türkiye - TR (052)',
    city: 'AYDIN',
    name: 'KUŞADASI GÜMRÜK MÜDÜRLÜĞÜ',
  },
  TR351900: {
    country: 'Türkiye - TR (052)',
    city: 'İZMİR',
    name: 'ALİAĞA GÜMRÜK MÜDÜRLÜĞÜ',
  },
  TR352100: {
    country: 'Türkiye - TR (052)',
    city: 'DENİZLİ',
    name: 'DENİZLİ GÜMRÜK MÜDÜRLÜĞÜ',
  },
  TR352200: {
    country: 'Türkiye - TR (052)',
    city: 'İZMİR',
    name: 'EGE SERBEST BÖLGE GÜMRÜK MDR',
  },
  TR352800: {
    country: 'Türkiye - TR (052)',
    city: 'UŞAK',
    name: 'UŞAK GÜMRÜK MÜDÜRLÜĞÜ',
  },
  TR352900: {
    country: 'Türkiye - TR (052)',
    city: 'AFYONKARAHİSAR',
    name: 'AFYONKARAHİSAR GÜMRÜK MÜDÜRLÜĞÜ',
  },
  TR353100: {
    country: 'Türkiye - TR (052)',
    city: 'İZMİR',
    name: 'İZMİR SERBEST BÖLGE GÜMRÜK MDR',
  },
  TR353200: {
    country: 'Türkiye - TR (052)',
    city: 'İZMİR',
    name: 'İZMİR TIR GÜMRÜK MÜDÜRLÜĞÜ',
  },
  TR353300: {
    country: 'Türkiye - TR (052)',
    city: 'MANİSA',
    name: 'ALAŞEHİR GÜMRÜK MÜDÜRLÜĞÜ',
  },
  TR353400: {
    country: 'Türkiye - TR (052)',
    city: 'AYDIN',
    name: 'AYDIN GÜMRÜK MÜDÜRLÜĞÜ',
  },
  TR410000: {
    country: 'Türkiye - TR (052)',
    city: 'Yarımca Körfez / KOCAELİ',
    name: 'DOĞU MARMARA GÜMRÜK VE TİCARET BM',
  },
  TR410100: {
    country: 'Türkiye - TR (052)',
    city: 'İZMİT',
    name: 'İZMİT GÜMRÜK MÜDÜRLÜĞÜ',
  },
  TR410300: {
    country: 'Türkiye - TR (052)',
    city: 'İZMİT',
    name: 'DERİNCE GÜMRÜK MÜDÜRLÜĞÜ',
  },
  TR410500: {
    country: 'Türkiye - TR (052)',
    city: 'KOCAELİ',
    name: 'DİLOVASI GÜMRÜK MÜDÜRLÜĞÜ',
  },
  TR410700: {
    country: 'Türkiye - TR (052)',
    city: 'SAKARYA',
    name: 'SAKARYA GÜMRÜK MÜDÜRLÜĞÜ',
  },
  TR411300: {
    country: 'Türkiye - TR (052)',
    city: 'İZMİT',
    name: 'GEBZE GÜMRÜK MÜDÜRLÜĞÜ',
  },
  TR411400: {
    country: 'Türkiye - TR (052)',
    city: 'İZMİT',
    name: 'KÖRFEZ PETROKİMYA GÜMRÜK MÜDÜRLÜĞÜ',
  },
  TR411500: {
    country: 'Türkiye - TR (052)',
    city: 'ZONGULDAK',
    name: 'KARADENİZ EREĞLİ GÜMRÜK MÜDÜRLÜĞÜ',
  },
  TR411600: {
    country: 'Türkiye - TR (052)',
    city: 'ZONGULDAK',
    name: 'ZONGULDAK GÜMRÜK MÜDÜRLÜĞÜ',
  },
  TR411700: {
    country: 'Türkiye - TR (052)',
    city: 'KARABÜK',
    name: 'KARABÜK GÜMRÜK MÜDÜRLÜĞÜ',
  },
  TR411800: {
    country: 'Türkiye - TR (052)',
    city: 'BARTIN',
    name: 'BARTIN GÜMRÜK MÜDÜRLÜĞÜ',
  },
  TR411900: {
    country: 'Türkiye - TR (052)',
    city: 'BOLU',
    name: 'BOLU GÜMRÜK MÜDÜRLÜĞÜ',
  },
  TR440000: {
    country: 'Türkiye - TR (052)',
    city: 'MALATYA',
    name: 'FIRAT GÜMRÜK VE TİCARET BM',
  },
  TR440100: {
    country: 'Türkiye - TR (052)',
    city: 'MALATYA',
    name: 'MALATYA GÜMRÜK MÜDÜRLÜĞÜ',
  },
  TR440200: {
    country: 'Türkiye - TR (052)',
    city: 'KAHRAMANMARAŞ',
    name: 'KAHRAMANMARAŞ GÜMRÜK MÜDÜRLÜĞÜ',
  },
  TR440300: {
    country: 'Türkiye - TR (052)',
    city: 'ELAZIĞ',
    name: 'ELAZIĞ GÜMRÜK MÜDÜRLÜĞÜ',
  },
  TR440400: {
    country: 'Türkiye - TR (052)',
    city: 'SİVAS',
    name: 'SİVAS GÜMRÜK MÜDÜRLÜĞÜ',
  },
  TR550000: {
    country: 'Türkiye - TR (052)',
    city: 'Limaniçi SAMSUN',
    name: 'ORTA KARADENİZ GÜMRÜK VE TİCARET BM',
  },
  TR550100: {
    country: 'Türkiye - TR (052)',
    city: 'SAMSUN',
    name: 'SAMSUN GÜMRÜK MÜDÜRLÜĞÜ',
  },
  TR550200: {
    country: 'Türkiye - TR (052)',
    city: 'ORDU',
    name: 'ORDU GÜMRÜK MÜDÜRLÜĞÜ',
  },
  TR550300: {
    country: 'Türkiye - TR (052)',
    city: 'ORDU',
    name: 'ÜNYE GÜMRÜK MÜDÜRLÜĞÜ',
  },
  TR550700: {
    country: 'Türkiye - TR (052)',
    city: 'ÇORUM',
    name: 'ÇORUM GÜMRÜK MÜDÜRLÜĞÜ',
  },
  TR551000: {
    country: 'Türkiye - TR (052)',
    city: 'AMASYA',
    name: 'AMASYA GÜMRÜK MÜDÜRLÜĞÜ',
  },
  TR551100: {
    country: 'Türkiye - TR (052)',
    city: 'KASTAMONU',
    name: 'İNEBOLU GÜMRÜK MÜDÜRLÜĞÜ',
  },
  TR551200: {
    country: 'Türkiye - TR (052)',
    city: 'SİNOP',
    name: 'SİNOP GÜMRÜK MÜDÜRLÜĞÜ',
  },
  TR551300: {
    country: 'Türkiye - TR (052)',
    city: 'ORDU',
    name: 'ORDU-GİRESUN HAVALİMANI GÜM. MÜD.',
  },
  TR590000: {
    country: 'Türkiye - TR (052)',
    city: 'Süleymanpaşa / TEKİRDAĞ',
    name: 'BATI MARMARA GÜMRÜK VE TİCARET BM',
  },
  TR590100: {
    country: 'Türkiye - TR (052)',
    city: 'TEKİRDAĞ',
    name: 'TEKİRDAĞ GÜMRÜK MÜDÜRLÜĞÜ',
  },
  TR590200: {
    country: 'Türkiye - TR (052)',
    city: 'TEKİRDAĞ',
    name: 'ÇORLU HAVALİMANI GÜMRÜK MÜDÜRLÜĞÜ',
  },
  TR590300: {
    country: 'Türkiye - TR (052)',
    city: 'TEKİRDAĞ',
    name: 'AVRUPA SERBEST BÖLGE GÜMRÜK MDR',
  },
  TR590400: {
    country: 'Türkiye - TR (052)',
    city: 'TEKİRDAĞ',
    name: 'ÇERKEZKÖY GÜMRÜK MÜDÜRLÜĞÜ',
  },
  TR590500: {
    country: 'Türkiye - TR (052)',
    city: 'ÇANAKKALE',
    name: 'ÇANAKKALE GÜMRÜK MÜDÜRLÜĞÜ',
  },
  TR590800: {
    country: 'Türkiye - TR (052)',
    city: 'ÇANAKKALE',
    name: 'BİGA GÜMRÜK MÜDÜRLÜĞÜ',
  },
  TR610000: {
    country: 'Türkiye - TR (052)',
    city: 'TRABZON',
    name: 'DOĞU KARADENİZ GÜMRÜK VE TİCARET BM',
  },
  TR610100: {
    country: 'Türkiye - TR (052)',
    city: 'TRABZON',
    name: 'TRABZON GÜMRÜK MÜDÜRLÜĞÜ',
  },
  TR610400: {
    country: 'Türkiye - TR (052)',
    city: 'GİRESUN',
    name: 'GİRESUN GÜMRÜK MÜDÜRLÜĞÜ',
  },
  TR650000: {
    country: 'Türkiye - TR (052)',
    city: 'Tuşba / VAN',
    name: 'DOĞU ANADOLU GÜMRÜK VE TİCARET BM',
  },
  TR650800: {
    country: 'Türkiye - TR (052)',
    city: 'VAN',
    name: 'VAN GÜMRÜK MÜDÜRLÜĞÜ',
  },
  TR650900: {
    country: 'Türkiye - TR (052)',
    city: 'HAKKARİ',
    name: 'ESENDERE GÜMRÜK MÜDÜRLÜĞÜ',
  },
  TR651000: {
    country: 'Türkiye - TR (052)',
    city: 'VAN',
    name: 'KAPIKÖY GÜMRÜK MÜDÜRLÜĞÜ',
  },
  TR651300: {
    country: 'Türkiye - TR (052)',
    city: 'HAKKARİ',
    name: 'YÜKSEKOVA GÜMRÜK MÜDÜRLÜĞÜ',
  },
  TR651500: {
    country: 'Türkiye - TR (052)',
    city: 'HAKKARİ',
    name: 'ÜZÜMLÜ GÜMRÜK MÜDÜRLÜĞÜ',
  },
  UA100000: {
    country: 'Ukraine - UA (072)',
    city: 'Kyiv',
    name: 'Kyiv Customs Office',
  },
  UA100060: {
    country: 'Ukraine - UA (072)',
    city: 'Vyshneve',
    name: 'CCD 1 CP Vіshneve',
  },
  UA100100: {
    country: 'Ukraine - UA (072)',
    city: 'Kyiv',
    name: 'CCD 1 CP Zakhidnyi',
  },
  UA100110: {
    country: 'Ukraine - UA (072)',
    city: 'Kyiv',
    name: 'CCD 2 CP Zakhidnyi',
  },
  UA100120: {
    country: 'Ukraine - UA (072)',
    city: 'Kyiv',
    name: 'CCD 3 CP Zakhidnyi',
  },
  UA100130: {
    country: 'Ukraine - UA (072)',
    city: 'Kyiv',
    name: 'CCD 4 CP Zakhidnyi',
  },
  UA100140: {
    country: 'Ukraine - UA (072)',
    city: 'Kyiv',
    name: 'CCD 1 CP Kyiv-tsentralnyi',
  },
  UA100160: {
    country: 'Ukraine - UA (072)',
    city: 'Kyiv',
    name: 'CCD 3 CP Kyiv-tsentralnyi',
  },
  UA100180: {
    country: 'Ukraine - UA (072)',
    city: 'Kyiv',
    name: 'CCD 1 CP Lisky',
  },
  UA100190: {
    country: 'Ukraine - UA (072)',
    city: 'Prolisky',
    name: 'CCD 2 CP Lisky',
  },
  UA100200: {
    country: 'Ukraine - UA (072)',
    city: 'Velyka Oleksandrivka',
    name: 'CCD 3 CP Lisky',
  },
  UA100210: {
    country: 'Ukraine - UA (072)',
    city: 'Velyka Dymerka',
    name: 'CCD 4 CP Lisky',
  },
  UA100220: {
    country: 'Ukraine - UA (072)',
    city: 'Yahotyn',
    name: 'CCS Yahotyn CP Lisky',
  },
  UA100230: {
    country: 'Ukraine - UA (072)',
    city: 'Kalynivka',
    name: 'CCD 1 CP Рivdennyi',
  },
  UA100240: {
    country: 'Ukraine - UA (072)',
    city: 'Obukhiv',
    name: 'CCD 2 CP Рivdennyi',
  },
  UA100250: {
    country: 'Ukraine - UA (072)',
    city: 'Bila Tserkva',
    name: 'CCD 3 CP Рivdennyi',
  },
  UA100260: {
    country: 'Ukraine - UA (072)',
    city: 'Zdorivka',
    name: 'CCS Zdorivka CP Рivdennyi',
  },
  UA100270: {
    country: 'Ukraine - UA (072)',
    city: 'Fastiv',
    name: 'CCS Fastiv CP Рivdennyi',
  },
  UA100280: {
    country: 'Ukraine - UA (072)',
    city: 'Kyiv',
    name: 'CP Spetsializovanyi',
  },
  UA100290: {
    country: 'Ukraine - UA (072)',
    city: 'Kyiv',
    name: 'CCD 1 CP Spetsializovanyi',
  },
  UA100300: {
    country: 'Ukraine - UA (072)',
    city: 'Bilohorodka',
    name: 'CCD 2 CP Spetsializovanyi',
  },
  UA100310: {
    country: 'Ukraine - UA (072)',
    city: 'Hora',
    name: 'CCD 3 CP Spetsializovanyi',
  },
  UA100320: {
    country: 'Ukraine - UA (072)',
    city: 'Kyiv',
    name: 'CCD 1 CP Stolychnyi',
  },
  UA100330: {
    country: 'Ukraine - UA (072)',
    city: 'Kyiv',
    name: 'CCD 2 CP Stolychnyi',
  },
  UA100340: {
    country: 'Ukraine - UA (072)',
    city: 'Martusivka',
    name: 'CCD 1 CP Skhidnyi',
  },
  UA100350: {
    country: 'Ukraine - UA (072)',
    city: 'Kvitneve',
    name: 'CCD 2 CP Skhidnyi',
  },
  UA100360: {
    country: 'Ukraine - UA (072)',
    city: 'Kyiv',
    name: 'CCD 3 CP Skhidnyi',
  },
  UA100370: {
    country: 'Ukraine - UA (072)',
    city: 'Kyiv',
    name: 'CP UkrРoshta',
  },
  UA100380: {
    country: 'Ukraine - UA (072)',
    city: 'Chaiky',
    name: 'CCD 1 CP Chaiky',
  },
  UA100390: {
    country: 'Ukraine - UA (072)',
    city: 'Kopyliv',
    name: 'CCD 2 Chaiky',
  },
  UA100400: {
    country: 'Ukraine - UA (072)',
    city: 'Kapitanivka',
    name: 'CCS Kapitanivka CP Chaiky',
  },
  UA100410: {
    country: 'Ukraine - UA (072)',
    city: 'Pylypovychi',
    name: 'CCS Pylypovychi CP Chaiky',
  },
  UA100430: {
    country: 'Ukraine - UA (072)',
    city: 'Kyiv',
    name: 'CCD 4 CP Vishneve',
  },
  UA101000: {
    country: 'Ukraine - UA (072)',
    city: 'Zhytomyr',
    name: 'Zhytomyr Customs Office',
  },
  UA101020: {
    country: 'Ukraine - UA (072)',
    city: 'Oliivka',
    name: 'CCD Oliyvka CP Zhytomyr-tsentralnyi',
  },
  UA101030: {
    country: 'Ukraine - UA (072)',
    city: 'Korostyshiv',
    name: 'CCS Korostyshiv',
  },
  UA101040: {
    country: 'Ukraine - UA (072)',
    city: 'Berdychiv',
    name: 'CCD Berdychiv',
  },
  UA101070: {
    country: 'Ukraine - UA (072)',
    city: 'Korosten',
    name: 'CCD Warshavka CP Korosten',
  },
  UA101080: {
    country: 'Ukraine - UA (072)',
    city: 'Malyn',
    name: 'CCS Malyn CP Korosten',
  },
  UA101090: {
    country: 'Ukraine - UA (072)',
    city: 'Natalivka',
    name: 'CP Novohrad-Volynskyi',
  },
  UA101130: {
    country: 'Ukraine - UA (072)',
    city: 'Stashky',
    name: 'CCS Stashki CP Ovruch',
  },
  UA101150: {
    country: 'Ukraine - UA (072)',
    city: 'Olevsk',
    name: 'CCS Olevsk-vantazhniy CP Olevsk',
  },
  UA102000: {
    country: 'Ukraine - UA (072)',
    city: 'Chernihiv',
    name: 'Chernihiv Customs Office',
  },
  UA102020: {
    country: 'Ukraine - UA (072)',
    city: 'Kozelets',
    name: 'CP Kozelets',
  },
  UA102030: {
    country: 'Ukraine - UA (072)',
    city: 'Nizhyn',
    name: 'CCS CP Kozelets',
  },
  UA102080: {
    country: 'Ukraine - UA (072)',
    city: 'Pryluky',
    name: 'CCD 1 CP Pryluky',
  },
  UA102090: {
    country: 'Ukraine - UA (072)',
    city: 'Pryluky',
    name: 'CCD 2 CP Pryluky',
  },
  UA102100: {
    country: 'Ukraine - UA (072)',
    city: 'Ichnia',
    name: 'CCS CP Pryluky',
  },
  UA102110: {
    country: 'Ukraine - UA (072)',
    city: 'Bakhmach',
    name: 'CCD 3 CP Pryluky',
  },
  UA102160: {
    country: 'Ukraine - UA (072)',
    city: 'Koriukivka',
    name: 'CCD 2 CP Snovsk',
  },
  UA102170: {
    country: 'Ukraine - UA (072)',
    city: 'Chernihiv',
    name: 'ContrP Chernihiv Station',
  },
  UA110000: {
    country: 'Ukraine - UA (072)',
    city: 'Dnipro',
    name: 'Dnipro Customs Office',
  },
  UA110020: {
    country: 'Ukraine - UA (072)',
    city: 'Kamianske',
    name: 'CP Kamianske',
  },
  UA110030: {
    country: 'Ukraine - UA (072)',
    city: 'Vilnohirsk',
    name: 'CCS Vilnohirsk CP Kamianske',
  },
  UA110040: {
    country: 'Ukraine - UA (072)',
    city: 'Kamianske',
    name: 'CCS DMK CP Kamianske',
  },
  UA110050: {
    country: 'Ukraine - UA (072)',
    city: 'Novomoskovsk',
    name: 'CP Novomoskovsk',
  },
  UA110060: {
    country: 'Ukraine - UA (072)',
    city: 'Pavlohrad',
    name: 'CCS Pavlohrad CP Novomoskovsk',
  },
  UA110070: {
    country: 'Ukraine - UA (072)',
    city: 'Kryvyi Rih',
    name: 'CCD 1 CP Kryvyi Rih',
  },
  UA110080: {
    country: 'Ukraine - UA (072)',
    city: 'Radushne',
    name: 'CCD 2 CP Kryvyi Rih',
  },
  UA110090: {
    country: 'Ukraine - UA (072)',
    city: 'Kryvyi Rih',
    name: 'CCD 3 CP Kryvyi Rih',
  },
  UA110110: {
    country: 'Ukraine - UA (072)',
    city: 'Dnipro',
    name: 'CP Lisky',
  },
  UA110120: {
    country: 'Ukraine - UA (072)',
    city: 'Nikopol',
    name: 'CP Nikopol',
  },
  UA110130: {
    country: 'Ukraine - UA (072)',
    city: 'Slobozhanske',
    name: "CP Slobozhans'kyi",
  },
  UA110140: {
    country: 'Ukraine - UA (072)',
    city: 'Dnipro',
    name: 'CP Dnipro-Livoberezhnyi',
  },
  UA110150: {
    country: 'Ukraine - UA (072)',
    city: 'Dnipro',
    name: 'CP Dnipro-Pravoberezhnyi',
  },
  UA110170: {
    country: 'Ukraine - UA (072)',
    city: 'Dnipro',
    name: 'CCD 2 CP Spetsializovanyi',
  },
  UA112000: {
    country: 'Ukraine - UA (072)',
    city: 'Zaporizhzhia',
    name: 'Zaporizhzhia Customs Office',
  },
  UA112030: {
    country: 'Ukraine - UA (072)',
    city: 'Zaporizhzhia',
    name: 'CCD 2 CP Zaporizhzhia-Aeroport',
  },
  UA112070: {
    country: 'Ukraine - UA (072)',
    city: 'Zaporizhzhia',
    name: 'CP Zaporizhstal',
  },
  UA112080: {
    country: 'Ukraine - UA (072)',
    city: 'Zaporizhzhia',
    name: 'CP Zaporizhzhia',
  },
  UA120000: {
    country: 'Ukraine - UA (072)',
    city: 'Kyiv',
    name: 'State Customs Service of Ukraine',
  },
  UA204000: {
    country: 'Ukraine - UA (072)',
    city: 'Rivne',
    name: 'Rivne Customs Office',
  },
  UA204020: {
    country: 'Ukraine - UA (072)',
    city: 'Rivne',
    name: 'CCD 1 CP Rivne',
  },
  UA204030: {
    country: 'Ukraine - UA (072)',
    city: 'Kostopil',
    name: 'CCD 2 CP Rivne',
  },
  UA204040: {
    country: 'Ukraine - UA (072)',
    city: 'Bila Krynytsia',
    name: 'CCS CP Rivne',
  },
  UA204060: {
    country: 'Ukraine - UA (072)',
    city: 'Sarny',
    name: 'CCD 1 CP Sarny',
  },
  UA204080: {
    country: 'Ukraine - UA (072)',
    city: 'Dubno',
    name: 'CP Dubno',
  },
  UA205000: {
    country: 'Ukraine - UA (072)',
    city: 'Rymachi',
    name: 'Volyn Customs Office',
  },
  UA205020: {
    country: 'Ukraine - UA (072)',
    city: 'Starovoitove',
    name: 'BCP Yahodyn-Dorohusk CCD 3-6',
  },
  UA205030: {
    country: 'Ukraine - UA (072)',
    city: 'Starovoitove',
    name: 'CCD 1 CP Yahodyn',
  },
  UA205040: {
    country: 'Ukraine - UA (072)',
    city: 'Rymachi',
    name: 'BCP Yahodyn-Dorohusk CCD 2',
  },
  UA205080: {
    country: 'Ukraine - UA (072)',
    city: 'Volodymyr',
    name: 'CCD 1 CP Volodymyr-Volynskyi',
  },
  UA205090: {
    country: 'Ukraine - UA (072)',
    city: 'Rokytnytsia',
    name: 'BCP Volodymyr-Volynskyi-Khrubeshuv',
  },
  UA205100: {
    country: 'Ukraine - UA (072)',
    city: 'Osmylovychi',
    name: 'CCD 3 CP Volodymyr-Volynskyi',
  },
  UA205110: {
    country: 'Ukraine - UA (072)',
    city: 'Ustyluh',
    name: 'BCP Ustyluh-Zosin CCD 1-4',
  },
  UA205140: {
    country: 'Ukraine - UA (072)',
    city: 'Strumivka',
    name: 'CCD 1 CP Lutsk',
  },
  UA205150: {
    country: 'Ukraine - UA (072)',
    city: 'Strumivka',
    name: 'CCD 2 CP Lutsk',
  },
  UA205170: {
    country: 'Ukraine - UA (072)',
    city: 'Zmiinets',
    name: 'CCD 3 CP Lutsk',
  },
  UA206000: {
    country: 'Ukraine - UA (072)',
    city: 'Ivano-Frankivsk',
    name: 'Ivano-Frankivsk Customs Office',
  },
  UA206020: {
    country: 'Ukraine - UA (072)',
    city: 'Ivano-Frankivsk',
    name: 'CCD 1 CP Prykarpattia',
  },
  UA206030: {
    country: 'Ukraine - UA (072)',
    city: 'Микитинці',
    name: 'CCD 2 CP Prykarpattia',
  },
  UA206050: {
    country: 'Ukraine - UA (072)',
    city: 'Yamnytsia',
    name: 'CCD 4 CP Prykarpattia',
  },
  UA206060: {
    country: 'Ukraine - UA (072)',
    city: 'Kalush',
    name: 'CP Kalush',
  },
  UA206070: {
    country: 'Ukraine - UA (072)',
    city: 'Dolyna',
    name: 'CP Dolyna',
  },
  UA206080: {
    country: 'Ukraine - UA (072)',
    city: 'Kolomyia',
    name: 'CP Kolomyia',
  },
  UA209000: {
    country: 'Ukraine - UA (072)',
    city: 'Lviv',
    name: 'Lviv Customs Office',
  },
  UA209030: {
    country: 'Ukraine - UA (072)',
    city: 'Krakovets',
    name: 'BCP Krakivets-Korchova CCD 1-4',
  },
  UA209040: {
    country: 'Ukraine - UA (072)',
    city: 'Shehyni',
    name: 'BCP Shehyni-Medyka CP Mostyska',
  },
  UA209050: {
    country: 'Ukraine - UA (072)',
    city: 'Mostyska Druha',
    name: 'BCP Mostyska-Pshemysl',
  },
  UA209060: {
    country: 'Ukraine - UA (072)',
    city: 'Rata',
    name: 'BCP Rava-Ruska-Khrebenne',
  },
  UA209070: {
    country: 'Ukraine - UA (072)',
    city: 'Rava-Ruska',
    name: 'BCP Rava-Ruska-Khrebenne, Werchrata',
  },
  UA209080: {
    country: 'Ukraine - UA (072)',
    city: 'Terlo',
    name: 'BCP Smilnytsia-Krostsenko',
  },
  UA209090: {
    country: 'Ukraine - UA (072)',
    city: 'Hrushiv',
    name: 'BCP Hrushiv-Budomiezh CCD 6-9',
  },
  UA209100: {
    country: 'Ukraine - UA (072)',
    city: 'Uhryniv',
    name: 'BCP Uhryniv-Dolhobychuv CP Uhryniv',
  },
  UA209110: {
    country: 'Ukraine - UA (072)',
    city: 'Drohobych',
    name: 'CCD Drohobych CP Lviv-Pivdennyi',
  },
  UA209120: {
    country: 'Ukraine - UA (072)',
    city: 'Stryi',
    name: 'CCD Stryi CP Lviv-Pivdennyi',
  },
  UA209130: {
    country: 'Ukraine - UA (072)',
    city: 'Brody',
    name: 'CCD Brody CP Lviv-Pivnichnyi',
  },
  UA209140: {
    country: 'Ukraine - UA (072)',
    city: 'Malekhiv',
    name: 'CCD Malekhiv CP Lviv-Pivnichnyi',
  },
  UA209150: {
    country: 'Ukraine - UA (072)',
    city: 'Zapytiv',
    name: 'CCD Zapytiv CP Lviv-Pivnichnyi',
  },
  UA209160: {
    country: 'Ukraine - UA (072)',
    city: 'Chervonohrad',
    name: 'CCD Sokal CP Lviv-Pivnichnyi',
  },
  UA209170: {
    country: 'Ukraine - UA (072)',
    city: 'Horodok',
    name: 'CCD 1 CP Horodok',
  },
  UA209180: {
    country: 'Ukraine - UA (072)',
    city: 'Horodok',
    name: 'CCD 2 CP Horodok',
  },
  UA209190: {
    country: 'Ukraine - UA (072)',
    city: 'Krakovets',
    name: 'CCD 3 CP Horodok',
  },
  UA209200: {
    country: 'Ukraine - UA (072)',
    city: 'Zymna Voda',
    name: 'CCD 4 CP Horodok',
  },
  UA209210: {
    country: 'Ukraine - UA (072)',
    city: 'Lviv',
    name: 'CP Lviv-Poshtovyi',
  },
  UA209230: {
    country: 'Ukraine - UA (072)',
    city: 'Murovane',
    name: 'CCD Murovane CP Lviv-Pivnichnyi',
  },
  UA305000: {
    country: 'Ukraine - UA (072)',
    city: 'Uzhhorod',
    name: 'Zakarpattia Customs Office',
  },
  UA305020: {
    country: 'Ukraine - UA (072)',
    city: 'Chop',
    name: 'BCP Chop-Zakhon',
  },
  UA305030: {
    country: 'Ukraine - UA (072)',
    city: 'Chop',
    name: 'BCP Chop - Zakhon',
  },
  UA305040: {
    country: 'Ukraine - UA (072)',
    city: 'Chop',
    name: 'CCD 3 CP Chop-Zaliznychnyi',
  },
  UA305050: {
    country: 'Ukraine - UA (072)',
    city: 'Svoboda',
    name: 'ContrP Batieve BCP Salovka-Eperjes.',
  },
  UA305060: {
    country: 'Ukraine - UA (072)',
    city: 'Solomonovo',
    name: 'BCP Chop-Zakhon CP Tysa',
  },
  UA305090: {
    country: 'Ukraine - UA (072)',
    city: 'Uzhhorod',
    name: 'BCP Uzhhorod-Vyshnie-Niemetske',
  },
  UA305110: {
    country: 'Ukraine - UA (072)',
    city: 'Vynohradiv',
    name: 'CP Vynohradiv',
  },
  UA305120: {
    country: 'Ukraine - UA (072)',
    city: 'Khust',
    name: 'CCD 2 (Khust) CP Vynohradiv',
  },
  UA305130: {
    country: 'Ukraine - UA (072)',
    city: 'Velyki Lazy',
    name: 'CCD 1 CP Uzhhorod-Vantazhnyi',
  },
  UA305140: {
    country: 'Ukraine - UA (072)',
    city: 'Mynai',
    name: 'CCD 3 CP Uzhhorod-Vantazhnyi',
  },
  UA305150: {
    country: 'Ukraine - UA (072)',
    city: 'Rozivka',
    name: 'CCD 2 CP Uzhhorod-Vantazhnyi',
  },
  UA305160: {
    country: 'Ukraine - UA (072)',
    city: 'Mukachevo',
    name: 'CCD 1 CP Mukachevo',
  },
  UA305170: {
    country: 'Ukraine - UA (072)',
    city: 'Berehovo',
    name: 'CCD 2 (Berehovo) CP Mukachevo',
  },
  UA305180: {
    country: 'Ukraine - UA (072)',
    city: 'Svaliava',
    name: 'CCS (Svaliava) CP Mukachevo',
  },
  UA305190: {
    country: 'Ukraine - UA (072)',
    city: 'Pavlovo',
    name: 'BCP Pavlove-Matiovtse',
  },
  UA305200: {
    country: 'Ukraine - UA (072)',
    city: 'Astei',
    name: 'BCP Luzhanka-Berehshuran CP Astei',
  },
  UA305230: {
    country: 'Ukraine - UA (072)',
    city: 'Vylok',
    name: 'BCP Vylok-Tisabech CP Vylok',
  },
  UA305240: {
    country: 'Ukraine - UA (072)',
    city: 'Nevetlenfolu',
    name: 'BCP Diakove-Halmeu CCD 1-4',
  },
  UA305250: {
    country: 'Ukraine - UA (072)',
    city: 'Nevetlenfolu',
    name: 'BCP Diakove-Halmeu CCD 5 CP Diakove',
  },
  UA305270: {
    country: 'Ukraine - UA (072)',
    city: 'Tiachiv',
    name: 'CCD 3 (Tyachiv) CP Vynohradiv',
  },
  UA400000: {
    country: 'Ukraine - UA (072)',
    city: 'Khmelnytskyi',
    name: 'Khmelnytskyi Customs Office',
  },
  UA400020: {
    country: 'Ukraine - UA (072)',
    city: 'Muksha Kytaihorodska',
    name: 'CP Kamianets-Podilskyi',
  },
  UA400030: {
    country: 'Ukraine - UA (072)',
    city: 'Slavuta',
    name: 'CP Slavuta',
  },
  UA400040: {
    country: 'Ukraine - UA (072)',
    city: 'Khmelnytskyi',
    name: 'CP Khmelnytskyi',
  },
  UA400050: {
    country: 'Ukraine - UA (072)',
    city: 'Krasyliv',
    name: 'CCS CP Khmelnytskyi',
  },
  UA401000: {
    country: 'Ukraine - UA (072)',
    city: 'Vinnytsia',
    name: 'Vinnytsia Customs Office',
  },
  UA401020: {
    country: 'Ukraine - UA (072)',
    city: 'Zarvantsi',
    name: 'CCD 1 CP Vinnytsia',
  },
  UA401030: {
    country: 'Ukraine - UA (072)',
    city: 'Zhmerynka',
    name: 'CCD 2 CP Vinnytsia',
  },
  UA401050: {
    country: 'Ukraine - UA (072)',
    city: 'Vinnytsia',
    name: 'CCS CP Vinnytsia',
  },
  UA401060: {
    country: 'Ukraine - UA (072)',
    city: 'Haisyn',
    name: 'CP Haisyn',
  },
  UA401070: {
    country: 'Ukraine - UA (072)',
    city: 'Mohyliv-Podilskyi',
    name: 'CCD 1 CP Dnister',
  },
  UA401080: {
    country: 'Ukraine - UA (072)',
    city: 'Mohyliv-Podilskyi',
    name: 'BCP Mohyliv-Podilskyi-Otach',
  },
  UA401090: {
    country: 'Ukraine - UA (072)',
    city: 'Mohyliv-Podilskyi',
    name: 'BCP Mohyliv-Podilskyi-Volchynets',
  },
  UA401100: {
    country: 'Ukraine - UA (072)',
    city: 'Bronnytsia',
    name: 'BCP Bronnytsia-Ungur CCD Bronnytsia',
  },
  UA401110: {
    country: 'Ukraine - UA (072)',
    city: 'Pishchanka',
    name: 'CP Pishchanka',
  },
  UA403000: {
    country: 'Ukraine - UA (072)',
    city: 'Ternopil',
    name: 'Ternopil Customs Office',
  },
  UA403020: {
    country: 'Ukraine - UA (072)',
    city: 'Ternopil',
    name: 'CCD 1 CP Ternopil',
  },
  UA403030: {
    country: 'Ukraine - UA (072)',
    city: 'Ternopil',
    name: 'CCD No 2 CP Ternopil',
  },
  UA403040: {
    country: 'Ukraine - UA (072)',
    city: 'Zbarazh',
    name: 'CCS CP Ternopil',
  },
  UA403060: {
    country: 'Ukraine - UA (072)',
    city: 'Chortkiv',
    name: 'CP Chortkiv',
  },
  UA408000: {
    country: 'Ukraine - UA (072)',
    city: 'Chernivtsi',
    name: 'Chernivtsi Customs Office',
  },
  UA408020: {
    country: 'Ukraine - UA (072)',
    city: 'Chernivtsi',
    name: 'CCD 1 CP Chernivtsi',
  },
  UA408040: {
    country: 'Ukraine - UA (072)',
    city: 'Chernivtsi',
    name: 'CCD 3 CP Chernivtsi',
  },
  UA408050: {
    country: 'Ukraine - UA (072)',
    city: 'Terebleche',
    name: 'BCP Porubne-Siret CCD 1-2',
  },
  UA408060: {
    country: 'Ukraine - UA (072)',
    city: 'Cherepkivtsi',
    name: 'BCP Vadyl-Siret-Vikshany CCD 3',
  },
  UA408070: {
    country: 'Ukraine - UA (072)',
    city: 'Mamalyha',
    name: 'BCP Mamalyha-Кryva CCD 1',
  },
  UA408080: {
    country: 'Ukraine - UA (072)',
    city: 'Mamalyha',
    name: 'BCP Mamalyha-Кryva CCD 2',
  },
  UA408090: {
    country: 'Ukraine - UA (072)',
    city: 'Kelmentsi',
    name: 'BCP Кelmentsi-Larha CCD 1',
  },
  UA408100: {
    country: 'Ukraine - UA (072)',
    city: 'Kelmentsi',
    name: 'BCP Larha BCP Кеlmetsi-Larha CCD 2',
  },
  UA408110: {
    country: 'Ukraine - UA (072)',
    city: 'Rososhany',
    name: 'BCP Rossoshany-Brichen CCD 3',
  },
  UA408120: {
    country: 'Ukraine - UA (072)',
    city: 'Sokyriany',
    name: 'BCP Sokyriany-Oknytsia CCD 4',
  },
  UA408130: {
    country: 'Ukraine - UA (072)',
    city: 'Sokyriany',
    name: 'BCP Sokyriany-Oknytsia CCD 5',
  },
  UA408140: {
    country: 'Ukraine - UA (072)',
    city: 'Vashkivtsi',
    name: 'BCP Vashkivtsi-Hrimenkeuts CCD 6',
  },
  UA500000: {
    country: 'Ukraine - UA (072)',
    city: 'Odesa',
    name: 'Odesa Customs Office',
  },
  UA500020: {
    country: 'Ukraine - UA (072)',
    city: 'Odesa',
    name: 'BCP Odeskyi Morskyi Port CCD 1',
  },
  UA500040: {
    country: 'Ukraine - UA (072)',
    city: 'Odesa',
    name: 'BCP Odeskyi Morskyi Port CCD 3',
  },
  UA500050: {
    country: 'Ukraine - UA (072)',
    city: 'Odesa',
    name: 'BCP Odesa Morskyi Port CCD 4',
  },
  UA500060: {
    country: 'Ukraine - UA (072)',
    city: 'Odesa',
    name: 'BCP Odeskyi Morskyi Port CCD 2',
  },
  UA500080: {
    country: 'Ukraine - UA (072)',
    city: 'Chornomorsk',
    name: 'CCD 1 CP Chornomorsk-port',
  },
  UA500090: {
    country: 'Ukraine - UA (072)',
    city: 'Chornomorsk',
    name: 'BCP Chornomorskyi Port CCD 2',
  },
  UA500100: {
    country: 'Ukraine - UA (072)',
    city: 'Burlacha Balka',
    name: 'BCP Chornomorskyi Port CCD 3',
  },
  UA500110: {
    country: 'Ukraine - UA (072)',
    city: 'Chornomorsk',
    name: 'BCP Chornomorskyi Rybnyi Port',
  },
  UA500120: {
    country: 'Ukraine - UA (072)',
    city: 'Malodolynske',
    name: 'BCP Chornomorskyi Sudnorem. Zavod',
  },
  UA500130: {
    country: 'Ukraine - UA (072)',
    city: 'Vyzyrka',
    name: 'ContrP Vyzyrka CCD 1',
  },
  UA500140: {
    country: 'Ukraine - UA (072)',
    city: 'Hryhorivka',
    name: 'BCP Morskyi port Pivdennyi CCD 2',
  },
  UA500160: {
    country: 'Ukraine - UA (072)',
    city: 'Starokozache',
    name: 'BCP Starokozache-Tudora',
  },
  UA500170: {
    country: 'Ukraine - UA (072)',
    city: 'Palanka',
    name: 'BCP Maiaky-Udobne-Palanka CCD 1',
  },
  UA500180: {
    country: 'Ukraine - UA (072)',
    city: 'Serpneve',
    name: 'BCP Serpneve 1-Basarabiaska CCD 2',
  },
  UA500190: {
    country: 'Ukraine - UA (072)',
    city: 'Bilhorod-Dnistrovskyi',
    name: 'BCP Bilhorod-Dnistrovskyi Port',
  },
  UA500200: {
    country: 'Ukraine - UA (072)',
    city: 'Maloiaroslavets',
    name: 'BCP Maloiaroslavets 1-Chadur-Lunha',
  },
  UA500210: {
    country: 'Ukraine - UA (072)',
    city: 'Lisne',
    name: 'BCP Lisne-Seits CCD 2',
  },
  UA500220: {
    country: 'Ukraine - UA (072)',
    city: 'Bilhorod-Dnistrovskyi',
    name: 'CCD 4 CP Bilgorod-Dnistrovsky',
  },
  UA500350: {
    country: 'Ukraine - UA (072)',
    city: 'Rozdilna',
    name: 'CCS 1 CP Kuchurhan',
  },
  UA500370: {
    country: 'Ukraine - UA (072)',
    city: 'Izmail',
    name: 'BCP Izmailskyi Morskyi Port CCD 1',
  },
  UA500380: {
    country: 'Ukraine - UA (072)',
    city: 'Reni',
    name: 'BCP Reni-Dzhiurdzhiulesht CCD 2',
  },
  UA500390: {
    country: 'Ukraine - UA (072)',
    city: 'Reni',
    name: 'CCD 1 CP Reni',
  },
  UA500400: {
    country: 'Ukraine - UA (072)',
    city: 'Tabaky',
    name: 'BCP Tabaky-Myrne CCD 2 CP Izmail',
  },
  UA500410: {
    country: 'Ukraine - UA (072)',
    city: 'Vylkove',
    name: 'BCP Ust-Dunaiskyi Morskyi Port',
  },
  UA500420: {
    country: 'Ukraine - UA (072)',
    city: 'Orlivka',
    name: 'BCP Orlivka CP Orlivka',
  },
  UA500430: {
    country: 'Ukraine - UA (072)',
    city: 'Vynohradivka',
    name: 'BCP Vynohradivka-Vulcanesti CCD 2',
  },
  UA500440: {
    country: 'Ukraine - UA (072)',
    city: 'Dolynske',
    name: 'BCP Dolynske-Chishmikioi CCD 2',
  },
  UA500450: {
    country: 'Ukraine - UA (072)',
    city: 'Reni',
    name: 'BCP Reni-Dzhiurdzhiulesht CCD 1',
  },
  UA500460: {
    country: 'Ukraine - UA (072)',
    city: 'Reni',
    name: 'BCP Reniiskyi Morskyi Port CCD 1',
  },
  UA500470: {
    country: 'Ukraine - UA (072)',
    city: 'Novi Troiany',
    name: 'BCP Novi Troiany-Chadyr-Lunga CCD 2',
  },
  UA500480: {
    country: 'Ukraine - UA (072)',
    city: 'Lymanske',
    name: 'BCP Frikatsey-Etulia CCD 1 CP Reni',
  },
  UA500490: {
    country: 'Ukraine - UA (072)',
    city: 'Usatove-1',
    name: 'ContrP Odesa-Lisky CCD 1 CP',
  },
  UA500500: {
    country: 'Ukraine - UA (072)',
    city: 'Usatove',
    name: 'CCD 2 CP Odesa-vnutrishnyi',
  },
  UA500520: {
    country: 'Ukraine - UA (072)',
    city: 'Odesa',
    name: 'CCD No 3 CP Odesa-vnutrishnyi',
  },
  UA500530: {
    country: 'Ukraine - UA (072)',
    city: 'Izmai',
    name: 'CCS 1 CP Izmail',
  },
  UA504000: {
    country: 'Ukraine - UA (072)',
    city: 'Mykolaiv',
    name: 'Mykolaiv Customs Office',
  },
  UA504020: {
    country: 'Ukraine - UA (072)',
    city: 'Mykolaiv',
    name: 'BCP Specialized sea port Olvia',
  },
  UA504040: {
    country: 'Ukraine - UA (072)',
    city: 'Mykolaiv',
    name: 'ContrP Sudnobudivnyi Zavod Okean',
  },
  UA504070: {
    country: 'Ukraine - UA (072)',
    city: 'Voznesensk',
    name: 'CP Pervomaisk',
  },
  UA504080: {
    country: 'Ukraine - UA (072)',
    city: 'Pervomaisk',
    name: 'CCS 1 CP Pervomaisk',
  },
  UA504100: {
    country: 'Ukraine - UA (072)',
    city: 'Mykolaiv',
    name: 'BCP Mykolaivkyi Richkovyi Port',
  },
  UA504110: {
    country: 'Ukraine - UA (072)',
    city: 'Mykolaiv',
    name: 'ContrP Chhornomorskyi Zavod',
  },
  UA504130: {
    country: 'Ukraine - UA (072)',
    city: 'Mykolaiv',
    name: 'BCP Mykolaivskyi Morskyi Port',
  },
  UA504140: {
    country: 'Ukraine - UA (072)',
    city: 'Mykolaiv',
    name: 'ContrP Mykolaivske Morske Ahenstwo',
  },
  UA504150: {
    country: 'Ukraine - UA (072)',
    city: 'Kutsurub',
    name: 'BCP Ochakiv Port',
  },
  UA805000: {
    country: 'Ukraine - UA (072)',
    city: 'Sumy',
    name: 'Sumy Customs Office',
  },
  UA805020: {
    country: 'Ukraine - UA (072)',
    city: 'Sumy',
    name: 'CCD Tsentralnyi CP Sumy',
  },
  UA805030: {
    country: 'Ukraine - UA (072)',
    city: 'Trostianets',
    name: 'CCD Trostianets CP Sumy',
  },
  UA805090: {
    country: 'Ukraine - UA (072)',
    city: 'Romny',
    name: 'CP Romny',
  },
  UA805170: {
    country: 'Ukraine - UA (072)',
    city: 'Shostka',
    name: 'CCD 2 CP Druzhba',
  },
  UA805200: {
    country: 'Ukraine - UA (072)',
    city: 'Konotop',
    name: 'CP Konotop',
  },
  UA806000: {
    country: 'Ukraine - UA (072)',
    city: 'Poltava',
    name: 'Poltava Customs Office',
  },
  UA806020: {
    country: 'Ukraine - UA (072)',
    city: 'Poltava',
    name: 'CCD 1 CP Poltava',
  },
  UA806030: {
    country: 'Ukraine - UA (072)',
    city: 'Poltava',
    name: 'CCD 2 CP Poltava',
  },
  UA806040: {
    country: 'Ukraine - UA (072)',
    city: 'Myrhorod',
    name: 'CCS 1 CP Poltava',
  },
  UA806050: {
    country: 'Ukraine - UA (072)',
    city: 'Hadiach',
    name: 'CCS 2 CP Poltava',
  },
  UA806070: {
    country: 'Ukraine - UA (072)',
    city: 'Kremenchuk',
    name: 'CCD 1 CP Kremenchuk',
  },
  UA806080: {
    country: 'Ukraine - UA (072)',
    city: 'Horishni Plavni',
    name: 'CCD 2 CP Kremenchuk',
  },
  UA901000: {
    country: 'Ukraine - UA (072)',
    city: 'Kropyvnytskyi',
    name: 'Kropyvnytskyi Customs Office',
  },
  UA901020: {
    country: 'Ukraine - UA (072)',
    city: 'Kropyvnytskyi',
    name: 'CCD 1 CP Kropyvnytskyi',
  },
  UA901030: {
    country: 'Ukraine - UA (072)',
    city: 'Kropyvnytskyi',
    name: 'CCD 2 CP Kropyvnytskyi',
  },
  UA901040: {
    country: 'Ukraine - UA (072)',
    city: 'Pobuzke',
    name: 'CCS Pobuzke CP Kropyvnytskyi',
  },
  UA901050: {
    country: 'Ukraine - UA (072)',
    city: 'Oleksandriia',
    name: 'CP Oleksandriia',
  },
  UA901060: {
    country: 'Ukraine - UA (072)',
    city: 'Svitlovodsk',
    name: 'CCS Svitlovodsk CP Oleksandriia',
  },
  UA902000: {
    country: 'Ukraine - UA (072)',
    city: 'Cherkasy',
    name: 'Cherkasy Customs Office',
  },
  UA902020: {
    country: 'Ukraine - UA (072)',
    city: 'Cherkasy',
    name: 'CCD 1 CP Cherkasy',
  },
  UA902040: {
    country: 'Ukraine - UA (072)',
    city: 'Cherkasy',
    name: 'CCD 2 CP Cherkasy',
  },
  UA902050: {
    country: 'Ukraine - UA (072)',
    city: 'Smila',
    name: 'CCS Smila CP Cherkasy',
  },
  UA902060: {
    country: 'Ukraine - UA (072)',
    city: 'Cherkasy',
    name: 'CCS Bogdan Logystyc CP Cherkasy',
  },
  UA902070: {
    country: 'Ukraine - UA (072)',
    city: 'Samoridnia',
    name: 'CCS Korsun-Shevchenkyvskyi',
  },
  UA902080: {
    country: 'Ukraine - UA (072)',
    city: 'Uman',
    name: 'CP Uman',
  },
  UA902090: {
    country: 'Ukraine - UA (072)',
    city: 'Zvenyhorodka',
    name: 'CCS Zvenygorodka CP Uman',
  },
  UA903000: {
    country: 'Ukraine - UA (072)',
    city: 'Kyiv',
    name: 'Enerhetychna Customs Office',
  },
  UA903020: {
    country: 'Ukraine - UA (072)',
    city: 'Kyiv',
    name: 'CCD Kyiv CP Tsentralnyi',
  },
  UA903030: {
    country: 'Ukraine - UA (072)',
    city: 'Rivnopillia',
    name: 'CCD Chernihiv CP Tsentralnyi',
  },
  UA903040: {
    country: 'Ukraine - UA (072)',
    city: 'Korosten',
    name: 'CCD Korosten CP Tsentralnyi',
  },
  UA903050: {
    country: 'Ukraine - UA (072)',
    city: 'Horodok',
    name: 'CCD Lviv CP Zakhidnyi',
  },
  UA903060: {
    country: 'Ukraine - UA (072)',
    city: 'Kalush',
    name: 'CCD Kalush CP Zakhidnyi',
  },
  UA903070: {
    country: 'Ukraine - UA (072)',
    city: 'Pavshyno',
    name: 'CCD Mukachevo CP Zahidnyi',
  },
  UA903080: {
    country: 'Ukraine - UA (072)',
    city: 'Maistrova Volia',
    name: 'CCD Novohrad-Volynskyi CP Zakhidnyi',
  },
  UA903090: {
    country: 'Ukraine - UA (072)',
    city: 'Sarny',
    name: 'CCD Sarny CP Zakhidnyi',
  },
  UA903110: {
    country: 'Ukraine - UA (072)',
    city: 'Odesa',
    name: 'CCD Odesa CP Pivdennyi',
  },
  UA903120: {
    country: 'Ukraine - UA (072)',
    city: 'Odesa',
    name: 'BCP Odeskyi Port CP Pivdennyi',
  },
  UA903130: {
    country: 'Ukraine - UA (072)',
    city: 'Yuzhne',
    name: 'CCS Seaport Yuzhny CP Pivdennyi',
  },
  UA903140: {
    country: 'Ukraine - UA (072)',
    city: 'Chornomorsk',
    name: 'CCS Chornomorsk CP Pivdennyi',
  },
  UA903150: {
    country: 'Ukraine - UA (072)',
    city: 'Izmail',
    name: 'CCS Izmail CP Pivdennyi',
  },
  UA903160: {
    country: 'Ukraine - UA (072)',
    city: 'Mykolaiv',
    name: 'CCD Mykolaiv CP Pivdennyi',
  },
  UA903170: {
    country: 'Ukraine - UA (072)',
    city: 'Mykolaiv',
    name: 'BCP Mykolaivskyi Port CP Pivdennyi',
  },
  UA903180: {
    country: 'Ukraine - UA (072)',
    city: 'Poltava',
    name: 'CCD Poltava CP Skhidnyi',
  },
  UA903200: {
    country: 'Ukraine - UA (072)',
    city: 'Chernivtsi',
    name: 'CCD Chernivtsy CP Zahidnyi',
  },
  XI000001: {
    country: 'United Kingdom (Northern Ireland) - XI (006)',
    city: 'SALFORD',
    name: 'NI Central Community Transit Office',
  },
  XI000002: {
    country: 'United Kingdom (Northern Ireland) - XI (006)',
    city: 'LEEDS',
    name: 'NI PBH',
  },
  XI000003: {
    country: 'United Kingdom (Northern Ireland) - XI (006)',
    city: 'DERBY SQUARE,LIVERPOOL',
    name: 'NI AEO HMRC',
  },
  XI000014: {
    country: 'United Kingdom (Northern Ireland) - XI (006)',
    city: '.',
    name: 'Belfast International Airport',
  },
  XI000081: {
    country: 'United Kingdom (Northern Ireland) - XI (006)',
    city: 'Salford, Manchester',
    name: 'NI Auth Consignor/nees',
  },
  XI000140: {
    country: 'United Kingdom (Northern Ireland) - XI (006)',
    city: '.',
    name: 'George Best (Belfast City)',
  },
  XI000142: {
    country: 'United Kingdom (Northern Ireland) - XI (006)',
    city: 'Belfast',
    name: 'Belfast',
  },
  XI000600: {
    country: 'United Kingdom (Northern Ireland) - XI (006)',
    city: 'Southend-on-Sea',
    name: 'NI BTI',
  },
  XI003210: {
    country: 'United Kingdom (Northern Ireland) - XI (006)',
    city: '.',
    name: 'Londonderry',
  },
  XI004098: {
    country: 'United Kingdom (Northern Ireland) - XI (006)',
    city: 'GLASGOW',
    name: 'NI Excise Liaison Office',
  },
  XI005160: {
    country: 'United Kingdom (Northern Ireland) - XI (006)',
    city: '.',
    name: 'Warrenpoint',
  },
  XI005220: {
    country: 'United Kingdom (Northern Ireland) - XI (006)',
    city: 'Larne',
    name: 'Larne',
  },
  XIEORI01: {
    country: 'United Kingdom (Northern Ireland) - XI (006)',
    city: 'CARDIFF',
    name: 'NI EORI TEAM HMRC',
  },
  XIREX001: {
    country: 'United Kingdom (Northern Ireland) - XI (006)',
    city: 'Belfast',
    name: 'NI REX DH',
  },
  XIREX002: {
    country: 'United Kingdom (Northern Ireland) - XI (006)',
    city: 'Leeds',
    name: 'NI REX PBH',
  },
  XISOU001: {
    country: 'United Kingdom (Northern Ireland) - XI (006)',
    city: 'SALFORD',
    name: 'NI DIRECT EXPORTS',
  },
};

export const customsOptions: TDefaultSelectOption<string>[] = Object.keys(customs).map((key: string) => ({
  value: key,
  label: customs[key].name,
}));
