import React, { useRef } from 'react';

import type { Booking } from 'generated/api';

import { useOnClickOutside } from 'utils/hooks/onClickOutside';
import { BadgeItem } from './badgeItem';

import styles from './styles.module.scss';

type TProps = {
  bookings: Booking[];
  closePopup: () => void;
};

export const MapPopup: React.FC<TProps> = ({ bookings, closePopup }) => {
  const ref = useRef(null);

  useOnClickOutside(ref, closePopup);

  return (
    <div className={styles.mapPopupRoot} ref={ref} data-testid="MapPopup">
      {bookings.map((booking, index) => (
        <React.Fragment key={`${booking.id} ${index}`}>
          <BadgeItem booking={booking} />
        </React.Fragment>
      ))}
    </div>
  );
};
