import React from 'react';
import styles from './styles.module.scss';

export const Loader: React.FC = () => (
  <div className={styles.root} data-testid="loaderRoot">
    <div className={styles.animation}>
      <div className={styles.dot} />
      <div className={styles.dot} />
      <div className={styles.dot} />
      <div className={styles.dot} />
    </div>
  </div>
);
