import React, { useEffect, useMemo, useRef, useState } from 'react';
import mapboxgl from 'mapbox-gl';
import clsx from 'clsx';
import bbox from '@turf/bbox';
import * as ReactDOMServer from 'react-dom/server';
import distance from '@turf/distance';
import { point } from '@turf/helpers';
import { MinusIcon, MyLocationIcon, PlusIcon } from '@rouvia/icons';
import type { Map as MapType } from 'mapbox-gl';
import type { BBox2d } from '@turf/helpers/dist/js/lib/geojson';
import type { Position } from '@turf/helpers';

import { Quote, Booking } from 'generated/api';
import { getAddressFromOnD } from 'utils/misc';

import {
  convertBookingToGroup,
  DISTANCES,
  generateFeatureCollection,
  getBulletMarker,
  getGroupMarker,
  pointsAreEqual,
  ROUTE_SIZES,
  type SGroupType,
} from 'components/map/utils';
import { UncoordinatedBadge } from './uncoordinatedBadge';
import { MapPopup } from './mapPopup';

import 'mapbox-gl/dist/mapbox-gl.css';
import styles from './styles.module.scss';

mapboxgl.accessToken = import.meta.env.NODE_ENV === 'test' ? '' : import.meta.env.VITE_MAPBOX || '';

type TProps = {
  bookings?: Booking[];
  quoteEntity?: Quote | Booking;
  mapConfig: {
    zoom: number;
  };
  className?: string;
};

export const Map: React.FC<TProps> = ({ bookings, quoteEntity, mapConfig, className }) => {
  const mapContainer = useRef(null);
  const map = useRef<MapType | null>(null);
  const [lat, setLat] = useState(51.1657);
  const [lng, setLng] = useState(10.4515);
  const [zoom, setZoom] = useState(mapConfig.zoom);
  const [uncoordinatedBookings, setUncoordinatedBookings] = useState<Booking[]>([]);
  const [hasInvalidQuoteCoordinates, setHasInvalidQuoteCoordinates] = useState<boolean>(false);
  const [hideClass, setHideClassList] = useState<string | null>(null);
  const [bounds, setBounds] = useState<BBox2d | null>(null);

  const handleFitBounds = () => {
    if (bounds === null) {
      map?.current?.flyTo({
        center: [lng, lat],
        zoom: 5,
      });
    } else {
      map?.current?.fitBounds(bounds, { padding: 50 });
    }
  };

  const handleZoomIn = () => {
    map?.current?.zoomIn();
  };

  const handleZoomOut = () => {
    map?.current?.zoomOut();
  };

  const listOfBookings: SGroupType[] = useMemo(() => {
    const tempList: SGroupType[] = [];
    const tempUncoordinated: Booking[] = [];

    if (bookings) {
      bookings.forEach((booking) => {
        const rawQuote = booking;
        const rawOriginPoint = getAddressFromOnD(rawQuote.origin).point as [number, number];
        const rawDestinationPoint = getAddressFromOnD(rawQuote.destination).point as [number, number];

        // Check if Booking has invalid coordinates
        if (
          pointsAreEqual(rawOriginPoint, rawDestinationPoint) ||
          pointsAreEqual(rawOriginPoint, [0, 0]) ||
          pointsAreEqual(rawDestinationPoint, [0, 0])
        ) {
          tempUncoordinated.push(booking);

          return;
        }

        if (tempList.length === 0) {
          tempList.push(convertBookingToGroup(booking));

          return;
        }

        // Check if tempList has already a group where Origin/Destination are identical (even when reverted)
        const foundGroupItem = tempList.find(
          (tempGroupItem) =>
            (pointsAreEqual(tempGroupItem.origin, rawOriginPoint) &&
              pointsAreEqual(tempGroupItem.destination, rawDestinationPoint)) ||
            (pointsAreEqual(tempGroupItem.origin, rawDestinationPoint) &&
              pointsAreEqual(tempGroupItem.destination, rawOriginPoint)),
        );

        if (foundGroupItem) {
          foundGroupItem.bookings.push(booking);

          return;
        } else {
          tempList.push(convertBookingToGroup(booking));

          return;
        }
      });
    }

    if (quoteEntity) {
      const rawOriginPoint = getAddressFromOnD(quoteEntity.origin).point as [number, number];
      const rawDestinationPoint = getAddressFromOnD(quoteEntity.destination).point as [number, number];

      // Check if Booking has invalid coordinates
      if (
        pointsAreEqual(rawOriginPoint, rawDestinationPoint) ||
        pointsAreEqual(rawOriginPoint, [0, 0]) ||
        pointsAreEqual(rawDestinationPoint, [0, 0])
      ) {
        //DO NOTHING
        setHasInvalidQuoteCoordinates(true);
      } else {
        setHasInvalidQuoteCoordinates(false);
        tempList.push({
          origin: rawOriginPoint,
          destination: rawDestinationPoint,
          bookings: [],
          quoteEntity,
        });
      }
    }

    setUncoordinatedBookings(tempUncoordinated);

    return tempList;
  }, [quoteEntity, bookings]);

  useEffect(() => {
    if (map.current || !mapContainer.current) {
      return;
    }

    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/mapbox/streets-v12',
      center: [lng, lat],
      zoom,
      attributionControl: false,
      testMode: import.meta.env.NODE_ENV === 'test',
    });

    map.current.on('load', () => {
      if (listOfBookings.length) {
        if (!map.current) {
          return;
        }

        map.current.on('move', () => {
          if (map.current) {
            const mapLng = Number(map.current.getCenter().lng.toFixed(4));
            const mapLat = Number(map.current.getCenter().lat.toFixed(4));
            const mapZoom = Number(map.current.getZoom().toFixed(2));

            if (mapZoom >= 0 && mapZoom < 1) {
              map.current?.setLayoutProperty(ROUTE_SIZES.routeXs, 'visibility', 'none');
              map.current?.setLayoutProperty(ROUTE_SIZES.routeM, 'visibility', 'none');
              map.current?.setLayoutProperty(ROUTE_SIZES.routeL, 'visibility', 'none');
              setHideClassList(styles.hideL);
            } else if (mapZoom >= 1 && mapZoom < 2) {
              map.current?.setLayoutProperty(ROUTE_SIZES.routeXs, 'visibility', 'none');
              map.current?.setLayoutProperty(ROUTE_SIZES.routeM, 'visibility', 'none');
              map.current?.setLayoutProperty(ROUTE_SIZES.routeL, 'visibility', 'visible');
              setHideClassList(styles.hideM);
            } else if (mapZoom >= 2 && mapZoom < 3) {
              map.current?.setLayoutProperty(ROUTE_SIZES.routeXs, 'visibility', 'none');
              map.current?.setLayoutProperty(ROUTE_SIZES.routeM, 'visibility', 'visible');
              map.current?.setLayoutProperty(ROUTE_SIZES.routeL, 'visibility', 'visible');
              setHideClassList(styles.hideXs);
            } else {
              map.current?.setLayoutProperty(ROUTE_SIZES.routeXs, 'visibility', 'visible');
              map.current?.setLayoutProperty(ROUTE_SIZES.routeM, 'visibility', 'visible');
              map.current?.setLayoutProperty(ROUTE_SIZES.routeL, 'visibility', 'visible');
              setHideClassList(null);
            }

            setLng(mapLng);
            setLat(mapLat);
            setZoom(mapZoom);
          }
        });

        const mapRoute = generateFeatureCollection(listOfBookings);

        map.current?.addSource(ROUTE_SIZES.routeXs, {
          type: 'geojson',
          data: mapRoute[0],
        });
        map.current?.addSource(ROUTE_SIZES.routeM, {
          type: 'geojson',
          data: mapRoute[1],
        });
        map.current?.addSource(ROUTE_SIZES.routeL, {
          type: 'geojson',
          data: mapRoute[2],
        });
        map.current?.addSource(ROUTE_SIZES.routeXl, {
          type: 'geojson',
          data: mapRoute[3],
        });

        const LAYER_DEFAULTS: Omit<mapboxgl.LineLayer, 'id' | 'source'> = {
          type: 'line',
          paint: {
            'line-width': 1,
            'line-color': '#000',
            'line-dasharray': [4, 2],
          },
        };

        map.current?.addLayer({
          id: ROUTE_SIZES.routeXs,
          source: ROUTE_SIZES.routeXs,
          ...LAYER_DEFAULTS,
        });
        map.current?.addLayer({
          id: ROUTE_SIZES.routeM,
          source: ROUTE_SIZES.routeM,
          ...LAYER_DEFAULTS,
        });
        map.current?.addLayer({
          id: ROUTE_SIZES.routeL,
          source: ROUTE_SIZES.routeL,
          ...LAYER_DEFAULTS,
        });
        map.current?.addLayer({
          id: ROUTE_SIZES.routeXl,
          source: ROUTE_SIZES.routeXl,
          ...LAYER_DEFAULTS,
        });

        const bboxCoordinates = bbox(mapRoute[4]) as BBox2d;

        bboxCoordinates[0] = bboxCoordinates[0] - 0.5;
        bboxCoordinates[1] = bboxCoordinates[1] - 0.5;
        bboxCoordinates[2] = bboxCoordinates[2] + 0.5;
        bboxCoordinates[3] = bboxCoordinates[3] + 0.5;

        setBounds(bboxCoordinates);
        map.current.fitBounds(bboxCoordinates, { padding: 50 });

        listOfBookings.forEach((group: SGroupType, index) => {
          if (map.current === null) {
            return;
          }

          const distanceBetween = distance(point(group.origin), point(group.destination));
          let classDistance = '';

          if (distanceBetween >= DISTANCES.M && distanceBetween < DISTANCES.L) {
            classDistance = styles.distanceL;
          } else if (distanceBetween >= DISTANCES.XS && distanceBetween < DISTANCES.M) {
            classDistance = styles.distanceM;
          } else if (distanceBetween < DISTANCES.XS) {
            classDistance = styles.distanceXs;
          }

          if (group.bookings.length) {
            const popup = new mapboxgl.Popup({
              offset: 25,
              closeButton: false,
              className: styles.popupContainer,
            });

            const closePopup = () => {
              popup.remove();
            };

            popup.setHTML(
              ReactDOMServer.renderToStaticMarkup(<MapPopup bookings={group.bookings} closePopup={closePopup} />),
            );

            const curvePoints = mapRoute[4].features[0].geometry.coordinates[index];
            let lngLat: Position;

            if (group.bookings.length === 1 && group.bookings[0].events.length) {
              const events = group.bookings[0].events;

              // Find the accurate progress of the Booking
              const pointProgression = events.filter((e) => e.isCompleted).length / events.length;

              // Put MIN and MAX to that progress
              const MIN_PROGRESS = 0.2;
              const MAX_PROGRESS = 0.8;
              const limitPointProgression = Math.min(Math.max(pointProgression, MIN_PROGRESS), MAX_PROGRESS);

              const curvePointIndex = Math.trunc(limitPointProgression * curvePoints.length);

              lngLat = curvePoints[curvePointIndex];
            } else {
              const curvePointIndex = Math.ceil(curvePoints.length / 2);

              lngLat = curvePoints[curvePointIndex];
            }

            new mapboxgl.Marker({ element: getGroupMarker(group) })
              .setLngLat(lngLat as [number, number])
              .setPopup(popup)
              .addTo(map.current);
          }

          new mapboxgl.Marker({ element: getBulletMarker(classDistance) }).setLngLat(group.origin).addTo(map.current);
          new mapboxgl.Marker({ element: getBulletMarker(classDistance) })
            .setLngLat(group.destination)
            .addTo(map.current);
        });
      }
    });

    map.current.on('render', () => {
      map.current?.resize();
    });
  }, [lat, listOfBookings, lng, zoom]);

  return (
    <div className={styles.root}>
      <div className={clsx(styles.rootMap, className, hideClass)} ref={mapContainer} />

      <div className={clsx(styles.mapIcon, styles.myLocationBtn)} onClick={handleFitBounds}>
        <MyLocationIcon />
      </div>

      <div className={clsx(styles.mapIcon, styles.plusBtn)} onClick={handleZoomIn}>
        <PlusIcon />
      </div>
      <div className={clsx(styles.mapIcon, styles.minusBtn)} onClick={handleZoomOut}>
        <MinusIcon />
      </div>
      {(Boolean(uncoordinatedBookings.length) || hasInvalidQuoteCoordinates) && (
        <div className={styles.uncoordinated}>
          <UncoordinatedBadge bookings={uncoordinatedBookings} />
        </div>
      )}
    </div>
  );
};
