import { useMemo } from 'react';
import type { InfiniteData } from '@tanstack/react-query';
import { PageInfo } from 'generated/api';

type PaginatedResponse<T> = {
  data: T[];
  pagination: PageInfo;
};

export const extractedItemsInfinite = <T>(infiniteData: InfiniteData<PaginatedResponse<T>> | undefined) => {
  const tempArray: T[] = [];

  if (infiniteData) {
    infiniteData.pages.forEach((page) => {
      if (page.data) {
        tempArray.push(...page.data);
      }
    });
  }

  return tempArray;
};

export const useExtractedItemsInfinite = <T>(infiniteData: InfiniteData<PaginatedResponse<T>> | undefined) =>
  useMemo(() => extractedItemsInfinite(infiniteData), [infiniteData]);
