import { useQuery } from '@tanstack/react-query';
import {
  AssignUserRolesRequest,
  InviteUserRequest,
  UpdatePasswordRequest,
  UpdateUserRequest,
  User,
} from 'generated/api';
import { api } from 'api/request';

export const userInfoQueryKey = 'personalInfoQueryKey';
export const organisationUsersQueryKey = 'organisationUsersQueryKey';

export const getUserInfo = async (userId: string): Promise<User> => {
  const { data } = await api.getUserById(userId);

  return data;
};

export const updateUserInfo = async (userId: string, user: UpdateUserRequest): Promise<User> => {
  const { data } = await api.updateUser(userId, user);

  return data;
};

export const updatePassword = async (userId: string, passwordObj: UpdatePasswordRequest): Promise<void> => {
  await api.resetPassword(userId, passwordObj);
};

export const updateUserRoles = async (userId: string, data: AssignUserRolesRequest): Promise<void> => {
  await api.editRoles(userId, data);
};

export const inviteUsers = async (data: InviteUserRequest): Promise<void> => {
  await api.inviteUsers(data);
};

const getOrganisationUsers = async (): Promise<User[]> => {
  const { data } = await api.getUsersByOrganisationId();

  return data;
};

export const deleteUser = async (userId: string): Promise<void> => {
  await api.deleteUser(userId);
};

export const useGetUserInfo = (userId: string) => useQuery([userInfoQueryKey, userId], () => getUserInfo(userId));
export const useGetOrganisationUsers = () => useQuery([organisationUsersQueryKey], getOrganisationUsers);
