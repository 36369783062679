import clsx from 'clsx';
import React from 'react';
import type { FunctionComponent } from 'react';
import styles from './styles.module.scss';

type TProps = {
  As: typeof React.Component | FunctionComponent;
  className?: string;
  size?: 'large' | 'medium' | 'small';
  onClick?: () => void;
};

export const Icon: React.FC<TProps> = ({ As, size = 'large', className, ...rest }) => (
  <span data-testid="IconRoot" className={clsx(styles.iconRoot, className)} {...rest}>
    <As data-testid="IconAs" className={clsx(styles.icon, styles[size])} />
  </span>
);
