import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { PlusIcon } from '@rouvia/icons';
import { ApiError, InviteUserRequest } from 'generated/api';
import { inviteUsers, organisationUsersQueryKey, useGetOrganisationUsers } from 'api/user';
import { queryClient } from 'constants/api';
import { Icon } from 'components/icon';
import ToastContext from 'components/toast';
import { Loader } from 'components/loader';
import { Button } from 'components/button';
import { Table } from 'components/dynamicTable';
import { Layout } from 'components/layout';
import { SCREEN_SIZE } from 'constants/screenSize';
import { Modal } from 'components/modal';
import { TagInput } from 'components/tagInput';
import { useGetUser } from 'utils/hooks/useGetUser';
import { validateEmail } from 'utils/misc';
import { getTableColumns } from './getTableColumns';
import styles from './styles.module.scss';

export const OrganisationManagementPage = () => {
  const { t } = useTranslation('default', { keyPrefix: 'pages/orgManagement' });
  const { t: globalT } = useTranslation('default');
  const [isModalVisible, setModalVisible] = useState(false);
  const [emails, setEmails] = useState<string[]>([]);
  const [isError, setError] = useState(false);
  const [currentUser] = useGetUser();
  const columns = useMemo(() => getTableColumns(globalT, currentUser.id), [currentUser, globalT]);
  const { data: users, isLoading: isLoadingUsers } = useGetOrganisationUsers();
  const { playErrorToast, playSuccessToast } = useContext(ToastContext);

  const handleModalClose = () => {
    setModalVisible(false);
    setEmails([]);
    setError(false);
  };

  const { mutate: inviteUsersMutation, isLoading } = useMutation<void, ApiError, InviteUserRequest>(
    (data: InviteUserRequest) => inviteUsers(data),
    {
      onSettled: (_data, error) => {
        if (error) {
          playErrorToast();
        } else {
          playSuccessToast();
        }

        queryClient.invalidateQueries([organisationUsersQueryKey]);
        handleModalClose();
      },
    },
  );

  const handleInviteUsers = () => {
    if (!emails.length) {
      setError(true);
    } else {
      inviteUsersMutation({ emails });
      setError(false);
    }
  };

  useEffect(() => {
    if (isError && emails.length) {
      setError(false);
    }
  }, [isError, emails]);

  return (
    <Layout
      contentMaxWidth={SCREEN_SIZE.MD}
      title={t('pageTitle')}
      cta={
        <Button theme="accent" onClick={() => setModalVisible(true)}>
          <Icon As={PlusIcon} />
          {t('ctaText')}
        </Button>
      }
    >
      <div className={styles.orgManagementRoot}>
        {users?.length && <Table data={users} columns={columns} className={styles.table} />}
        {isLoadingUsers && <Loader />}
      </div>

      {isModalVisible && (
        <Modal title={t('inviteModal.title')} onClose={handleModalClose}>
          <div className={styles.modalContent}>
            <div className={styles.description}>{t('inviteModal.text')}</div>
            <div className={styles.form}>
              <TagInput
                tags={emails}
                setTags={(items) => setEmails(items)}
                className={styles.tags}
                validationFn={validateEmail}
              />{' '}
              <Button theme="accent" onClick={handleInviteUsers} className={styles.button} isLoading={isLoading}>
                {t('inviteModal.button')}
              </Button>
            </div>

            {isError && <div className={styles.error}>{t('inviteModal.validationError')}</div>}
          </div>
        </Modal>
      )}
    </Layout>
  );
};
