/* eslint-disable import/no-named-as-default-member */

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-locize-backend';
import LastUsed from 'locize-lastused';
import { isExperimentalEnv, isTestExecution } from 'constants/config';
import { EN, DE } from 'translations';

export const defaultNS = 'default';
export const locizeProjectId = '4b6b0897-1e1e-46c0-888d-9e91122c2501';

const locizeOptions = {
  projectId: locizeProjectId,
  apiKey: import.meta.env.VITE_LOCIZE_API_KEY,
  referenceLng: 'en',
  debounceSubmit: 10000,
};

export const resources = {
  en: {
    default: EN,
  },
  de: {
    default: DE,
  },
};

// In non-production-like environments use locize CDN
if (isExperimentalEnv && !isTestExecution && !import.meta.env.VITE_LOCIZE_DISABLE_CDN) {
  i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .use(Backend)
    .use(LastUsed)
    .init({
      fallbackLng: 'en',
      ns: 'default',
      lng: 'en',
      interpolation: {
        escapeValue: false,
      },
      load: 'languageOnly',
      backend: locizeOptions,
      locizeLastUsed: locizeOptions,
    });
} else {
  // In Production-like environments use locally downloaded translation resources
  i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
      fallbackLng: 'en',
      ns: 'default',
      lng: 'en',
      resources,
      interpolation: {
        escapeValue: false,
      },
      load: 'languageOnly',
    });
}

export default i18n;
