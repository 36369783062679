/* eslint-disable no-console */

import { env } from 'constants/config';

const isDev = env === 'local' || env === 'dev';

export const devConsoleLog = (message?: any, ...optionalParams: any[]) => {
  if (isDev) {
    console.log(
      '%cDev Console Log 💭\n',
      'background: #3A6B35; color: white; font-size: 14px; border-radius: 4px; margin-bottom: 8px; padding: 0 4px',
      message,
      ...optionalParams,
    );
  }
};

export const devConsoleError = (message?: any, ...optionalParams: any[]) => {
  if (isDev) {
    console.error(
      '%cDev Console Error 💥\n',
      'background: #FCE77D; color: #990011FF; font-size: 14px; border-radius: 4px; margin-bottom: 8px; padding: 0 4px',
      message,
      ...optionalParams,
    );
  }
};

export const devConsoleWarn = (message?: any, ...optionalParams: any[]) => {
  if (isDev) {
    console.warn(
      '%cDev Console Warning ⚠️️\n',
      'background: #949398FF; color: #F4DF4EFF; font-size: 14px; border-radius: 4px; margin-bottom: 8px; padding: 0 4px',
      message,
      ...optionalParams,
    );
  }
};
