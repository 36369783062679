export type TEnvs = 'local' | 'dev' | 'staging' | 'demo' | 'production';

type IConfig = {
  [key in TEnvs]: {
    host: string;
    domain: string;
    apiURL: string;
    org60FeetId?: string;
    oidc: {
      authority: string;
    };
    hotJarId?: number;
  };
};

const config: IConfig = {
  local: {
    oidc: {
      authority: import.meta.env.VITE_AUTHORITY || 'http://localhost:8080',
    },
    host: 'http://localhost:3000',
    domain: 'localhost:3000',
    apiURL: import.meta.env.VITE_API_URL || 'http://localhost:8000',
  },
  dev: {
    oidc: {
      authority: 'https://dev-auth.rouvia.com',
    },
    host: 'https://dev.rouvia.com',
    domain: 'dev.rouvia.com',
    apiURL: 'https://dev-api.rouvia.com',
    org60FeetId: 'be2a3f16-104c-4703-a0bb-ab2f3517769c',
  },
  staging: {
    host: 'https://staging.rouvia.com',
    domain: 'staging.rouvia.com',
    apiURL: 'https://staging-api.rouvia.com',
    oidc: {
      authority: 'https://staging-auth.rouvia.com',
    },
    org60FeetId: '33192e3b-bc4b-48bf-81ac-e51f83a2953a',
  },
  demo: {
    host: 'https://demo.rouvia.com',
    domain: 'demo.rouvia.com',
    apiURL: 'https://demo-api.rouvia.com',
    oidc: {
      authority: 'https://demo-auth.rouvia.com',
    },
    hotJarId: 3625969,
    org60FeetId: '18d0afb9-63de-4f02-bdc8-b1ee516ca598',
  },
  production: {
    host: 'https://app.rouvia.com',
    domain: 'app.rouvia.com',
    apiURL: 'https://api.rouvia.com',
    oidc: {
      authority: 'https://auth.rouvia.com',
    },
    hotJarId: 3317950,
    org60FeetId: '040ad99a-253d-4076-8742-b426cd034975',
  },
};

export const env: TEnvs = import.meta.env.VITE_ENV || 'local';
export const isTestExecution = import.meta.env.VITEST;

// List of NON-production environments with unlocked additional\experimental functionality
export const experimentalEnvs: TEnvs[] = ['local', 'dev'];
export const isExperimentalEnv = experimentalEnvs.includes(env);
export const isProduction = env === 'production';

export default config[env] || config.local;
