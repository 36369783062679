import React, { useContext, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@tanstack/react-query';
import { useForm, Controller, useFormState } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { DateTime } from 'luxon';
import clsx from 'clsx';
import { SearchIcon } from '@rouvia/icons';
import { ApiError, QuoteSearch, QuoteSearchRequest, Terminal } from 'generated/api';
import { searchQuotes } from 'api/quotes';
import { ISearchForm, SearchFormDefaultValues } from 'constants/planning';
import { Link } from 'components/link';
import { Button } from 'components/button';
import { getSelectIcon, handleMappingData, prepareQuotesSearch } from 'utils/planning';
import ToastContext from 'components/toast';
import { ModalityLoader } from 'components/modalityLoader';
import { CustomDatePicker } from 'components/customDatePicker';
import { Input } from 'components/input';
import { ContentBox } from 'components/contentBox';
import styles from './styles.module.scss';

type TProps = {
  searchRequest: QuoteSearchRequest;
  terminals?: Terminal[];
};

export const QuotesFilters: React.FC<TProps> = ({ searchRequest, terminals = [] }) => {
  const { t } = useTranslation('default', {
    keyPrefix: 'pages/quotes/components/quotesFilters',
  });
  const { t: tCustomerForm } = useTranslation('default', {
    keyPrefix: 'pages/planning/components/customerForm',
  });
  const { t: commonT } = useTranslation('default', { keyPrefix: 'common' });
  const { playErrorToast } = useContext(ToastContext);
  const { id } = useParams();

  const navigate = useNavigate();
  const { mutate: quotesMutation, isLoading } = useMutation<QuoteSearch, ApiError, QuoteSearchRequest>(
    (formData: QuoteSearchRequest) => searchQuotes(formData),
    {
      onSuccess: (dataSearch: QuoteSearch) => {
        if (dataSearch.id) {
          navigate(`/quotes/search/${dataSearch.id}`);
        }
      },
      onError: () => {
        playErrorToast();
      },
    },
  );

  const { getValues, trigger, control, handleSubmit, watch, setValue, reset } = useForm<ISearchForm>({
    defaultValues: SearchFormDefaultValues,
  });

  const { isValid: isFormValid } = useFormState({ control });

  useEffect(() => {
    handleMappingData(searchRequest, setValue, reset, terminals);
  }, [reset, searchRequest, setValue, terminals]);

  const watchCollectionDate = watch('collectionDate');
  const watchDeliveryDate = watch('deliveryDate');
  const watchOrigin = watch('originAddress');
  const watchDestination = watch('destinationAddress');

  const closestCollectionDateString = useMemo(() => DateTime.now().plus({ days: 3 }).toFormat('yyyy-MM-dd'), []);
  const closestCollectionDate = new Date();

  closestCollectionDate.setDate(closestCollectionDate.getDate() + 4);

  const onSubmit = async (dataForm: ISearchForm) => {
    const requestData = prepareQuotesSearch(dataForm);

    if (requestData) {
      quotesMutation(requestData);
    } else {
      playErrorToast();
    }
  };

  const handleChangeFilters = () => {
    navigate(`/planning/${id}`);
  };

  useEffect(() => {
    if (watchCollectionDate && watchDeliveryDate) {
      trigger(['collectionDate', 'deliveryDate']);
    }
  }, [watchCollectionDate, watchDeliveryDate, trigger]);

  const OriginIcon = getSelectIcon(watchOrigin);
  const DestinationIcon = getSelectIcon(watchDestination);

  return (
    <>
      <ContentBox
        roundedBtm
        wrapperClassName={styles.quotesFiltersWrapper}
        header={
          <div className={styles.quotesFiltersRootHeader}>
            <div className={styles.header}>
              <div className={styles.flexNoWrap}>
                <div className={styles.headerBold}>{t('origin')}:</div>
                {OriginIcon && (
                  <div className={styles.defaultIcon}>
                    <OriginIcon />
                  </div>
                )}
                <div className={styles.headerRegular}>{watchOrigin !== null && watchOrigin.label}</div>
              </div>
              <div className={styles.flexNoWrap}>
                <div className={styles.headerBold}>{t('destination')}:</div>
                {DestinationIcon && (
                  <div className={styles.defaultIcon}>
                    <DestinationIcon />
                  </div>
                )}
                <div className={styles.headerRegular}>{watchDestination !== null && watchDestination.label}</div>
              </div>
            </div>
            <div className={styles.buttonContainer}>
              <Link theme="accent" onClick={handleChangeFilters}>
                {t('changeSearch')}
              </Link>
            </div>
          </div>
        }
      >
        <div className={clsx(styles.quotesFiltersRoot, { [styles.extraPadding]: !isFormValid })}>
          <form className={styles.filterContainer} onSubmit={handleSubmit(onSubmit)} noValidate>
            <div className={styles.filtersFlexGroup}>
              <Controller
                name="collectionDate"
                control={control}
                rules={{
                  required: commonT('validation.required'),
                  min: {
                    value: closestCollectionDateString,
                    message: t('collectionDateMinError'),
                  },
                }}
                render={({ field: { ref, ...restField }, fieldState: { error } }) => (
                  <CustomDatePicker
                    {...restField}
                    showRequired
                    label={t('collectionDate')}
                    error={error?.message}
                    minDate={closestCollectionDate}
                    style={{ width: 150 }}
                  />
                )}
              />
              <Controller
                name="collectionDaysOffset"
                control={control}
                rules={{
                  required: true,
                  min: {
                    value: 0,
                    message: tCustomerForm('fields.daysErrorMsg'),
                  },
                }}
                render={({ field }) => (
                  <Input
                    testId="collectionDaysOffset"
                    label={tCustomerForm('fields.days')}
                    min={0}
                    type="number"
                    required
                    placeholder={tCustomerForm('fields.daysPlaceholder')}
                    style={{ width: 44 }}
                    {...field}
                  />
                )}
              />
              <Controller
                name="deliveryDate"
                control={control}
                rules={{
                  validate: (value) => {
                    const collectionDateValue = DateTime.fromISO(getValues('collectionDate'));
                    const latestDeliveryDate = DateTime.fromISO(value);

                    const isValid = latestDeliveryDate.startOf('day') >= collectionDateValue.startOf('day');

                    return isValid || t('latestDeliveryDateError');
                  },
                }}
                render={({ field: { ref, ...restField }, fieldState: { error } }) => (
                  <CustomDatePicker
                    {...restField}
                    showRequired
                    label={t('latestDeliveryDate')}
                    error={error?.message}
                    minDate={new Date(String(watchCollectionDate))}
                    style={{ width: 150 }}
                  />
                )}
              />
              <Button type="submit" theme="accent" className={styles.submit} disabled={isLoading}>
                <SearchIcon /> {t('search')}
              </Button>
            </div>
          </form>
        </div>
      </ContentBox>
      {isLoading && <ModalityLoader />}
    </>
  );
};
