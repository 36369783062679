import React, { useEffect, useMemo, useRef, useState } from 'react';
import { BellIcon } from '@rouvia/icons';
import { NOTIFICATION_QUERY_KEY, useGetNotifications } from 'api/notifications';
import { queryClient } from 'constants/api';
import { bookingQueryKey, bookingsQueryKey } from 'api/bookings';
import { extractedItemsInfinite } from 'utils/hooks/useExtractedItemsInfinite';
import { Notification } from 'generated/api';
import styles from './style.module.scss';
import { NotificationPopup } from './notificationPopup';

const NotificationWidgetComponent: React.FC<unknown> = () => {
  const [init] = useState(Math.random);
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const popupRef = useRef<HTMLDivElement>(null);
  const [localNotifications, setLocalNotifications] = useState<Notification[]>([]);

  const { data: notificationsData } = useGetNotifications({ page: 1, key: NOTIFICATION_QUERY_KEY.NOTIFICATION_WIDGET });

  useEffect(() => {
    setLocalNotifications(extractedItemsInfinite(notificationsData));
  }, [init, notificationsData]);

  useEffect(() => {
    const interval = setInterval(() => {
      queryClient.invalidateQueries([NOTIFICATION_QUERY_KEY.NOTIFICATION_PAGE]);
      queryClient.invalidateQueries([NOTIFICATION_QUERY_KEY.NOTIFICATION_WIDGET]);
    }, 60000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (!localNotifications) {
      return;
    }

    localNotifications.every((notification) => {
      if (notification.read || notification.content.data.type !== 'EVENT') {
        return true;
      }

      queryClient.invalidateQueries([bookingQueryKey], { refetchType: 'all' });
      queryClient.invalidateQueries([bookingsQueryKey], { refetchType: 'all' });

      return false;
    });
  }, [localNotifications]);

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const unreadCounter = useMemo(
    () => localNotifications.filter((notification) => !notification.read).length,
    [localNotifications],
  );

  return (
    <div className={styles.root} ref={popupRef}>
      <div className={styles.icon} data-testid="NotificationWidgetClickable" onClick={() => setShowPopup(!showPopup)}>
        {Boolean(unreadCounter) && <div className={styles.counter}>{unreadCounter}</div>}
        <BellIcon />
      </div>

      {showPopup && (
        <NotificationPopup notifications={localNotifications} onClose={handleClosePopup} parentRef={popupRef} />
      )}
    </div>
  );
};

export const NotificationWidget = React.memo(NotificationWidgetComponent);
