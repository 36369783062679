import qs from 'qs';
import { API_URL } from 'constants/api';
import { Api, HttpClient } from 'generated/api';

const localHttpClient = new HttpClient({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  paramsSerializer: {
    serialize: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
  },
});

export const localAxiosInstance = localHttpClient.instance;

export const { api } = new Api(localHttpClient);
