import React from 'react';
import { FallbackRender } from '@sentry/react/types/errorboundary';
import { t } from 'i18next';
import { SpaceSeparator } from 'components/spaceSeparator';
import styles from './styles.module.scss';

export const ErrorBoundaryFallback: FallbackRender = (errorData) => (
  <div className={styles.root}>
    <div className={styles.content}>
      <h1 className={styles.title}>{t('default:components/errorBoundary.title')}</h1>
      <div className={styles.description}>
        <p>{t('default:components/errorBoundary.p1')}</p>
        <p>
          <SpaceSeparator>
            {t('default:components/errorBoundary.p2')}
            <button type="button" onClick={errorData.resetError} className={styles.button}>
              {t('default:components/errorBoundary.button')}
            </button>
            {t('default:components/errorBoundary.p3')}
          </SpaceSeparator>
        </p>
        <p>
          <SpaceSeparator>
            {t('default:components/errorBoundary.p4')}
            <a href="mailto:support@rouvia.com?subject=Emergency">support@rouvia.com</a>
            {t('default:components/errorBoundary.p5')}
          </SpaceSeparator>
        </p>
      </div>

      <pre className={styles.error}>
        <code data-testid="errorBoundaryError">
          {'Error: '}
          {errorData.error.message}
        </code>
      </pre>
    </div>
  </div>
);
