import React, { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import { CaretLeftIcon } from '@rouvia/icons';
import styles from './styles.module.scss';

type TProps = {
  title?: string;
  adornment?: ReactNode;
  cta?: JSX.Element;
  backLink?: boolean | string;
};

export const PageHeader: React.FC<TProps> = ({ title, adornment, cta, backLink }) => {
  const navigate = useNavigate();

  const handleBackLink = () => {
    if (typeof backLink === 'string') {
      navigate(backLink);
    } else if (backLink) {
      navigate(-1);
    }
  };

  return (
    <header className={styles.root}>
      <div className={styles.main}>
        {backLink && (
          <div className={styles.backLink} onClick={handleBackLink} data-testid="PageHeaderBackLink">
            <CaretLeftIcon />
          </div>
        )}

        <div className={styles.title} data-testid="PageHeaderTitle">
          {title}
        </div>
        {adornment && <div className={styles.adornment}>{adornment}</div>}
      </div>

      {cta && <div className={styles.cta}>{cta}</div>}
    </header>
  );
};
