import React from 'react';
import clsx from 'clsx';

import { Loader } from 'components/loader';
import styles from './styles.module.scss';

type TProps = {
  isLoading?: boolean;
  value: string | number;
  description: string;
  testId?: string;
  icon?: React.ReactNode;
  highlightValue?: boolean;
};

export const MetricWidget: React.FC<TProps> = ({ value, highlightValue, icon, description, isLoading, testId }) => (
  <div className={styles.MetricWidgetRoot} data-testid={testId}>
    {isLoading ? (
      <Loader />
    ) : (
      <div className={styles.container}>
        <div className={styles.description} data-testid="metricWidgetDescription">
          {description}
        </div>
        <div className={clsx(styles.value, { [styles.highlighted]: highlightValue })} data-testid="metricWidgetValue">
          {value}
        </div>

        {icon && <div className={styles.iconWrapper}>{icon}</div>}
      </div>
    )}
  </div>
);
