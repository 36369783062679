export const Colors = {
  // Dark
  black01: '#000',
  black02: '#272937',
  black03: '#595959',
  black04: '#c4c4c4',
  darkBg: '#e0e0e0',

  // Light
  white01: '#fff',
  white02: '#eaeeee',
  white03: '#ededed',
  white04: '#f7f6f6',
  lightBg: '#fafafa',

  // Blue
  blue01: '#638df7',
  blueBg: '#fafbff',

  // Accent
  accent01: '#ec5b5e',
  accent02: '#e96c6e',
  accent03: '#cc474a',

  // Special
  special01: '#088d39',
  special02: '#ffb649',
  special03: '#d71e1e',
  special04: '#5c2ee8',
  special05: '#a64762',
  special06: '#2a3466',
  special07: '#84919a',
};
