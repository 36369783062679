import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AlertIcon, CheckIcon } from '@rouvia/icons';
import { Button } from 'components/button';
import styles from './styles.module.scss';

type TProps = {
  isDisabled?: boolean;
  submitCallback?: () => void;
  errorMessage?: string;
  isSuccess?: boolean;
  isLoading?: boolean;
  textLabel?: string;
};

export const SavingButtonSection: React.FC<TProps> = ({
  submitCallback,
  isLoading,
  isSuccess,
  errorMessage,
  textLabel,
}) => {
  const { t } = useTranslation('default', { keyPrefix: 'common' });
  const [showSaved, setShowShaved] = useState<boolean>(false);

  useEffect(() => {
    if (isSuccess) {
      setShowShaved(true);

      const timer = setTimeout(() => {
        setShowShaved(false);
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [isSuccess]);

  const handleSubmit = () => {
    if (submitCallback) {
      submitCallback();
    }
  };

  return (
    <div className={styles.root}>
      <Button
        theme="accent"
        type="submit"
        onClick={handleSubmit}
        isLoading={isLoading}
        testId="SavingButtonSectionClickable"
      >
        {textLabel || t('saveChanges')}
      </Button>
      {showSaved && !errorMessage && (
        <div className={styles.saved}>
          <CheckIcon />
          <div>{t('saved')}</div>
        </div>
      )}
      {errorMessage && (
        <div className={styles.error}>
          <AlertIcon />
          <div data-testid="SavingButtonSectionErrorMsg">{errorMessage}</div>
        </div>
      )}
    </div>
  );
};
