import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { WidgetContainer } from 'components/widgetContainer';
import { WidgetItem } from 'components/widgetContainer/item';
import { formatDate, DATE_FORMAT } from 'utils/date';
import { EmptyList } from 'components/emptyList';
import { useGetBookingsInfinite } from 'api/bookings';
import { bookingRequestQuery } from 'pages/dashboard/constants';
import { useExtractedItemsInfinite } from 'utils/hooks/useExtractedItemsInfinite';
import { CustomInfiniteScroll } from 'components/customInfityScroll';
import { extractTasks } from './utils';
import styles from './styles.module.scss';

type TProps = {
  className?: string;
};

const scrollDivId = 'OpenTasksWidget';

export const OpenTasksWidget: React.FC<TProps> = ({ className }) => {
  const { t } = useTranslation('default', {
    keyPrefix: 'components/openTasksWidget',
  });
  const {
    data: paginatedBookings,
    fetchNextPage,
    hasNextPage,
    isLoading,
  } = useGetBookingsInfinite({ query: { ...bookingRequestQuery, hasOpenTasks: true }, key: 'OpenTasksWidget' });
  const bookings = useExtractedItemsInfinite(paginatedBookings);

  const tasks = useMemo(() => extractTasks(bookings), [bookings]);

  return (
    <WidgetContainer isLoading={isLoading} title={t('title')} className={className} id={scrollDivId}>
      {tasks.length ? (
        <CustomInfiniteScroll
          scrollableTargetId={scrollDivId}
          hasMore={Boolean(hasNextPage)}
          next={fetchNextPage}
          height={460}
          items={tasks.map((task) => {
            const { id, type, content, dueDate, bookingId, fwdId } = task;

            return (
              <Link to={`/bookings/${bookingId}`} className={styles.openTasksWidgetItem} key={id}>
                <WidgetItem>
                  <div className={styles.main}>
                    <div className={styles.title}>
                      {bookingId} {fwdId && <span className={styles.refId}>({fwdId})</span>}
                    </div>
                    <div className={styles.taskInfo}>{`${t(type || 'DOC_UPLOAD')}: ${t(`types.${content}`)}`}</div>
                    <div className={styles.footer}>
                      <div className={styles.due}>
                        {t('due')}: {formatDate(dueDate, DATE_FORMAT.DATE_MONTH_FULL)}
                      </div>
                    </div>
                  </div>
                </WidgetItem>
              </Link>
            );
          })}
        />
      ) : (
        <EmptyList fixedHeight="300px" title={t('emptyTaskTitle')} subtitle={t('emptyTaskSubtitle')} />
      )}
    </WidgetContainer>
  );
};
