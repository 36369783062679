import { useMutation } from '@tanstack/react-query';
import React, { useContext, useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { queryClient } from 'constants/api';
import ToastContext from 'components/toast';
import { assignUsersToBooking, bookingQueryKey } from 'api/bookings';
import { Button } from 'components/button';
import { CustomSelect } from 'components/select';
import { ApiError, BookingAssignmentRequest, User } from 'generated/api';
import { useGetOrganisationUsers } from 'api/user';
import { Modal } from 'components/modal';
import { getFullName } from 'utils/misc';
import styles from './styles.module.scss';

interface IFormValues {
  stakeholders: IStakeholderOption[];
}

interface IStakeholderOption {
  label: string;
  value: string;
}

type TProps = {
  bookingId: string;
  onClose: () => void;
  defaultStakeholders: User[];
};

export const StakeholdersModal: React.FC<TProps> = ({ defaultStakeholders, bookingId, onClose }) => {
  const { playErrorToast, playSuccessToast } = useContext(ToastContext);
  const { control, handleSubmit, setValue } = useForm<IFormValues>();
  const { t } = useTranslation('default', {
    keyPrefix: 'components/orderOverview/overviewTable',
  });
  const { t: commonT } = useTranslation('default', { keyPrefix: 'common' });
  const { data: users } = useGetOrganisationUsers();

  const { mutate: assignUsers, isLoading } = useMutation<void, ApiError, BookingAssignmentRequest>(
    (data) => assignUsersToBooking(bookingId, data),
    {
      onSuccess: () => {
        playSuccessToast();
        queryClient.invalidateQueries([bookingQueryKey, bookingId.toString()]);
        onClose();
      },
      onError: (apiError: ApiError) => {
        playErrorToast(apiError.errorType);
      },
    },
  );

  const usersToUsersOption = (data: User): IStakeholderOption => ({
    label: getFullName(data),
    value: data.id,
  });

  const usersOptions = useMemo(() => {
    if (Array.isArray(users)) {
      return users.map(usersToUsersOption);
    } else {
      return [];
    }
  }, [users]);

  const onSubmit = (data: IFormValues) => {
    assignUsers({
      userIds: data.stakeholders.map((item) => item.value),
    });
  };

  useEffect(() => {
    if (defaultStakeholders) {
      const preselected = defaultStakeholders.map(usersToUsersOption);

      setValue('stakeholders', preselected);
    }
  }, [defaultStakeholders, setValue]);

  return (
    <Modal onClose={onClose} title={t('editStakeholders')} classBody={styles.modal}>
      <form className={styles.form} onSubmit={handleSubmit(onSubmit)} noValidate>
        <Controller
          name="stakeholders"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <CustomSelect options={usersOptions} error={error?.message} isMulti {...field} />
          )}
        />

        <div className={styles.footer}>
          <Button theme="accent" className={styles.submit} isLoading={isLoading}>
            {commonT('save')}
          </Button>
          <Button theme="neutral" onClick={onClose} testId="StakeHoldersModalCancelBtn">
            {commonT('cancel')}
          </Button>
        </div>
      </form>
    </Modal>
  );
};
