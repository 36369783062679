import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Layout } from 'components/layout';
import { Loader } from 'components/loader';
import { extractError } from 'utils/misc';
import styles from './styles.module.scss';

export type TProps = {
  isLoading?: boolean;
  isError?: boolean;
  error?: any;
  title: string;
  testId?: string;
};

export const NoContent: React.FC<TProps> = ({ title, isLoading, isError, error, testId }) => {
  const { t } = useTranslation('default', {
    keyPrefix: 'components/noContent',
  });

  return (
    <Layout title={title} testId={testId}>
      <div className={styles.root}>
        {isLoading && <Loader />}
        {isError && (
          <div className={styles.error} data-testid="noContentError">
            <div>{extractError(error)}</div>

            <div className={styles.home}>
              <Link to="/">{t('goHomePage')}</Link>
            </div>
          </div>
        )}
      </div>
    </Layout>
  );
};
