import clsx from 'clsx';
import { Duration } from 'luxon';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { TruckIcon } from '@rouvia/icons';
import { Button } from 'components/button';
import { ProgressTrack } from 'components/progressTrack';
import { formatDate, DATE_FORMAT } from 'utils/date';
import { Quote as IQuote, QuoteCosts } from 'generated/api';
import { getMainOperator } from 'utils/shipment';
import { QuotePrice } from 'components/quotePrice';

import { getPriceLabel } from './utils';
import styles from './styles.module.scss';

type TProps = {
  quote: IQuote;
  terminalToTerminal?: boolean;
};

export const Quote: React.FC<TProps> = ({ quote, terminalToTerminal }) => {
  const { t } = useTranslation('default', {
    keyPrefix: 'pages/quotes/components/quote',
  });
  const { id, route } = quote;

  const isRailQuote = route.type === 'RAIL';
  const isTruckQuote = route.type === 'TRUCK';
  const { name: operatorName, logo: operatorIcon } = getMainOperator(route) || {};

  const costs: QuoteCosts = useMemo(() => {
    if (terminalToTerminal && isRailQuote) {
      return quote.costsTerminalToTerminal;
    }

    return quote.costsDoorToDoor;
  }, [isRailQuote, quote.costsDoorToDoor, quote.costsTerminalToTerminal, terminalToTerminal]);

  return (
    <div className={clsx(styles.root, { [styles.truckOnly]: isTruckQuote })} data-testid="QuoteRoot">
      <div className={styles.top}>
        <div className={styles.operatorName}>{operatorName}</div>
        <div className={styles.timing}>
          {isRailQuote && (
            <div>
              {t('earliest')}
              :&nbsp;
              <span className={styles.strong}>
                {'ETA '}
                {formatDate(route.deliveryTime, DATE_FORMAT.COMPLETE)}
              </span>
            </div>
          )}

          <div>
            {t('totalDeliveryTime')}
            :&nbsp;
            {Duration.fromMillis(route.duration * 1000).toFormat("hh'h' mm'm'")}
          </div>
        </div>
      </div>

      <div className={styles.main}>
        <aside className={styles.meta}>
          <img className={styles.logo} src={`/images/operators/${operatorIcon}`} alt={operatorName} />
        </aside>
        <div className={styles.track}>
          <ProgressTrack entityWithQuote={quote} />
        </div>
        <div className={styles.priceBlock}>
          <QuotePrice label={t(getPriceLabel(terminalToTerminal))} price={costs.price} />

          <Button as={Link} to={`/quote/${id}`} className={styles.button} theme="accent">
            {t('viewBtn')}
          </Button>

          <div className={styles.allInLabel}>{t('allInRateLabel')}</div>
        </div>
      </div>

      <div className={styles.bottom}>
        <div className={styles.emissionWrapper}>
          {t(isRailQuote ? 'emissionSaving' : 'emission')}
          :&nbsp;
          <span className={clsx(styles.emission, { [styles.saving]: isRailQuote })}>
            {isRailQuote ? quote.emissionsSaved : quote.emissions} kg
          </span>
        </div>
      </div>

      {isTruckQuote && (
        <div className={styles.badge}>
          <TruckIcon className={styles.truckIcon} /> {t('truckOnlyBadge')}
        </div>
      )}
    </div>
  );
};
