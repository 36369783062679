import React from 'react';
import { Tooltip } from 'components/tooltip';
import styles from './styles.module.scss';

type TProps = {
  label?: string;
  price: number;
  tooltip?: string;
  className?: string;
};

export const QuotePrice: React.FC<TProps> = ({ label, price, tooltip, className }) => (
  <div className={className}>
    {label && (
      <div className={styles.priceLabel} data-testid="QuotePriceLabel">
        {label}
      </div>
    )}

    <div className={styles.price} data-testid="QuotePrice">
      {'€ '}
      {price.toFixed(2)}
      {tooltip && <Tooltip text={tooltip} position="right" />}
    </div>
  </div>
);
