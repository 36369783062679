import { useTranslation } from 'react-i18next';
import { ReactComponent as EmptyStateIcon } from './icon.svg';
import styles from './styles.module.scss';

export const EmptyState = () => {
  const { t } = useTranslation('default', {
    keyPrefix: 'pages/quotes/components/emptyState',
  });

  return (
    <div className={styles.root} data-testid="EmptyStateRoot">
      <EmptyStateIcon className={styles.icon} />
      <h1 className={styles.title}>{t('title')}</h1>
      <div className={styles.description}>{t('description')}</div>
    </div>
  );
};
