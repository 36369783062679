import { useTranslation } from 'react-i18next';
import React from 'react';
import { WidgetContainer } from 'components/widgetContainer';
import { EmptyList } from 'components/emptyList';

import { useGetBookingsInfinite } from 'api/bookings';
import { useExtractedItemsInfinite } from 'utils/hooks/useExtractedItemsInfinite';
import { bookingRequestQuery } from 'pages/dashboard/constants';
import { CustomInfiniteScroll } from 'components/customInfityScroll';
import { BookingListItem } from './BookingListItem';

type TProps = {
  children?: React.ReactNode;
};

const scrollDivId = 'BookingsWidgetListId';

export const BookingsListWidget: React.FC<TProps> = () => {
  const {
    data: paginatedBookings,
    fetchNextPage,
    hasNextPage,
    isLoading,
  } = useGetBookingsInfinite({ query: { ...bookingRequestQuery, perPage: 5 }, key: 'BookingsWidgetList' });
  const bookings = useExtractedItemsInfinite(paginatedBookings);

  const { t } = useTranslation('default', {
    keyPrefix: 'components/bookingsListWidget',
  });

  return (
    <WidgetContainer id={scrollDivId} title={t('title')} isLoading={isLoading}>
      {paginatedBookings && bookings.length ? (
        <CustomInfiniteScroll
          scrollableTargetId={scrollDivId}
          hasMore={Boolean(hasNextPage)}
          next={fetchNextPage}
          height={340}
          items={bookings.map((booking, index) => (
            <BookingListItem booking={booking} key={booking.id} testId={`BookingListItem-${index}`} />
          ))}
        />
      ) : (
        <EmptyList fixedHeight="300px" title={t('emptyBookingTitle')} subtitle={t('emptyBookingSubtitle')} />
      )}
    </WidgetContainer>
  );
};
