import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { SearchIcon } from '@rouvia/icons';
import clsx from 'clsx';
import { Input } from 'components/input';
import { CustomSelect } from 'components/select';
import { FILTER_TYPE, TFilterType, TSelectData } from 'components/filtersGenerator/types';
import { MultiSelectCheckbox } from 'components/multiSelectCheckbox';
import { Link } from 'components/link';
import styles from './styles.module.scss';

type TProps = {
  filters: TFilterType[];
  className?: string;
};

export const FiltersGenerator: React.FC<TProps> = ({ filters, className }) => {
  const { t } = useTranslation('default', { keyPrefix: 'common' });

  const isFiltersActive = useMemo(() => {
    for (const filter of filters) {
      const filterType = filter.controls.type;

      if (filterType === FILTER_TYPE.SEARCH && Boolean(filter.controls.data)) {
        return true;
      } else if (
        filterType === FILTER_TYPE.MULTI_SELECT &&
        Boolean(filter.controls.data.find((checkbox) => checkbox.selected))
      ) {
        return true;
      } else if (filterType === FILTER_TYPE.SINGLE_SELECT && Boolean(filter.controls.data)) {
        return true;
      }
    }

    return false;
  }, [filters]);

  const reset = () => {
    for (const filter of filters) {
      const filterType = filter.controls.type;

      switch (filterType) {
        case FILTER_TYPE.SEARCH: {
          filter.controls.setData('');
          break;
        }

        case FILTER_TYPE.MULTI_SELECT: {
          filter.controls.setData(filter.controls.data.map((checkbox) => ({ ...checkbox, selected: false })));
          break;
        }

        case FILTER_TYPE.SINGLE_SELECT: {
          filter.controls.setData(null);
          break;
        }
      }
    }
  };

  const generateFilter = (filter: TFilterType, index: number) => {
    if (filter.hide) {
      return;
    }

    const filterType = filter.controls.type;

    switch (filterType) {
      case FILTER_TYPE.SEARCH: {
        return (
          <div key={index}>
            <Input
              value={filter.controls.data}
              onChange={(val) => filterType === FILTER_TYPE.SEARCH && filter.controls.setData(val)}
              placeholder={filter.name}
              attachmentRight={<SearchIcon style={{ color: '#CECECE' }} />}
            />
          </div>
        );
      }

      case FILTER_TYPE.MULTI_SELECT: {
        return (
          <div key={index}>
            <MultiSelectCheckbox title={filter.name} data={filter.controls.data} setData={filter.controls.setData} />
          </div>
        );
      }

      case FILTER_TYPE.SINGLE_SELECT: {
        return (
          <div key={index} style={{ minWidth: 160 }}>
            <CustomSelect
              options={filter.controls.options}
              value={filter.controls.data}
              placeholder={filter.name}
              onChange={(values) =>
                filterType === FILTER_TYPE.SINGLE_SELECT && filter.controls.setData(values as TSelectData)
              }
            />
          </div>
        );
      }
    }
  };

  return (
    <div className={clsx(styles.root, className)}>
      {filters.map((filter, index) => generateFilter(filter, index))}
      {isFiltersActive && (
        <Link onClick={reset} testId="filtersGeneratorClearBtn" theme="accent" underline="none" size="large">
          <div>{t('resetFilters')}</div>
        </Link>
      )}
    </div>
  );
};
