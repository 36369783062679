import { captureMessage } from '@sentry/react';
import { SeaportIcon, TrainIcon, WarehouseIcon } from '@rouvia/icons';
import type { UseFormReset, UseFormSetValue } from 'react-hook-form';
import type { FunctionComponent } from 'react';
import {
  Address,
  CargoItem,
  EmptyContainerDepot,
  EquipmentType,
  QuoteSearchRequest,
  Terminal,
  TerminalType,
} from 'generated/api';
import { ISearchForm, TAddressType, TLocationOption, TShipmentInfoFormValues } from 'constants/planning';
import { IGeocodeFeature } from 'types/geo';
import { extractAddress, getAddressString } from 'utils/misc';
import { geocode } from 'utils/geo';
import { equipment as shipmentEquipment, loadingUnits, sizes } from 'constants/equipments';

const getLocationRequestValue = (data: string | Terminal | IGeocodeFeature) => {
  if (typeof data === 'string') {
    return data;
  }

  // Address
  if (data.type === 'Feature') {
    return extractAddress(data);
  }

  // Terminal
  return data.id;
};

export const isUnitProfileRequired = (equipment?: EquipmentType) =>
  equipment === EquipmentType.SWAPBODY || equipment === EquipmentType.SEMITRAILER;

export const isUnitProfileValid = (equipment?: EquipmentType, unitProfile?: string) => {
  if (!isUnitProfileRequired(equipment)) {
    return true;
  }

  return Boolean(unitProfile);
};

const prepareQuoteCargo = (formData: TShipmentInfoFormValues[]): CargoItem[] => {
  const cargo: CargoItem[] = [];

  formData.forEach((item: TShipmentInfoFormValues) => {
    const { equipment, loadingUnit, size, netWeight, tareWeight, amount, unitProfile } = item;

    if (
      equipment?.value &&
      loadingUnit?.value &&
      size?.value &&
      netWeight >= 0 &&
      tareWeight &&
      isUnitProfileValid(equipment?.value, unitProfile?.value) &&
      amount
    ) {
      cargo.push({
        equipmentType: equipment.value,
        loadingUnit: loadingUnit.value,
        size: size.value,
        unitProfile: isUnitProfileRequired(equipment.value) ? unitProfile?.value : undefined,
        amount,
        netWeight,
        tareWeight,
      });
    } else {
      captureMessage(`Invalid form was passed to create a cargo item => ${JSON.stringify(item)}`);
    }
  });

  return cargo;
};

export const prepareQuotesSearch = (data: ISearchForm): QuoteSearchRequest | undefined => {
  const {
    originAddress,
    destinationAddress,
    shipmentInformation,
    containerPickupAddress,
    containerDropOffAddress,
    ...restData
  } = data;
  const cargo: CargoItem[] = prepareQuoteCargo(shipmentInformation);
  const origin = originAddress?.value;
  const destination = destinationAddress?.value;

  if (!cargo.length || !origin || !destination) {
    return;
  }

  return {
    ...restData,
    origin: getLocationRequestValue(origin),
    destination: getLocationRequestValue(destination),
    originContainerDepot: containerPickupAddress?.value && getLocationRequestValue(containerPickupAddress.value),
    destinationContainerDepot: containerDropOffAddress?.value && getLocationRequestValue(containerDropOffAddress.value),
    cargo,
  };
};

export const getTerminalById = (terminals: Terminal[], id: string) =>
  terminals.find((terminal: Terminal) => terminal.id === id);

export const getDepotById = (depots: EmptyContainerDepot[], id: string) =>
  depots.find((depot: EmptyContainerDepot) => depot.id === id);

export const getAddressType = (address: Address | Terminal | string | IGeocodeFeature): TAddressType => {
  if (typeof address !== 'string' && 'type' in address) {
    if (address.type === TerminalType.RAIL) {
      return 'RAIL';
    } else if (address.type === TerminalType.MARITIME) {
      return 'MARITIME';
    }
  }

  return 'WAREHOUSE';
};

export const getAddressIcon = (address: Address | Terminal): FunctionComponent => {
  if ('type' in address) {
    if (address.type === TerminalType.RAIL) {
      return TrainIcon;
    } else if (address.type === TerminalType.MARITIME) {
      return SeaportIcon;
    }
  }

  return WarehouseIcon;
};

export const getSelectIcon = (prop: TLocationOption | null): undefined | FunctionComponent => {
  const option = prop?.value;

  if (!option || typeof option === 'string') {
    return;
  }

  if ('type' in option) {
    if (option.type === TerminalType.RAIL) {
      return TrainIcon;
    } else if (option.type === TerminalType.MARITIME) {
      return SeaportIcon;
    } else {
      return WarehouseIcon;
    }
  }
};

export const mapSearchQueryToFormData = (
  searchRequest: QuoteSearchRequest,
  address: {
    originAddress: TLocationOption | null;
    destinationAddress: TLocationOption | null;
    containerDropOffAddress: TLocationOption | null;
    containerPickupAddress: TLocationOption | null;
  },
): ISearchForm => {
  const {
    collectionDate,
    deliveryDate,
    earliestCollectionTime,
    latestCollectionTime,
    latestDeliveryTime,
    earliestDeliveryTime,
    collectionDaysOffset,
    cargo,
  } = searchRequest;

  return {
    collectionDate,
    deliveryDate,
    containerDropOffAddress: address.containerDropOffAddress,
    containerPickupAddress: address.containerPickupAddress,
    destinationAddress: address.destinationAddress,
    originAddress: address.originAddress,
    collectionDaysOffset: collectionDaysOffset || 0,
    earliestCollectionTime: earliestCollectionTime ? earliestCollectionTime.slice(0, 5) : '07:00',
    earliestDeliveryTime: earliestDeliveryTime ? earliestDeliveryTime.slice(0, 5) : '22:00',
    latestDeliveryTime: latestDeliveryTime ? latestDeliveryTime.slice(0, 5) : '22:00',
    latestCollectionTime: latestCollectionTime ? latestCollectionTime.slice(0, 5) : '07:00',
    shipmentInformation: cargo.map((c) => ({
      equipment: {
        value: c.equipmentType,
        label: shipmentEquipment.find((e) => e.value === c.equipmentType)?.label || '',
      },
      loadingUnit: {
        value: c.loadingUnit,
        label: loadingUnits.find((e) => e.value === c.loadingUnit)?.label || '',
      },
      unitProfile: isUnitProfileRequired(c.equipmentType)
        ? { value: c.unitProfile || '', label: c.unitProfile || '' }
        : undefined,
      size: {
        value: c.size,
        label: sizes.find((e) => e.value === c.size)?.label || '',
      },
      netWeight: c.netWeight,
      tareWeight: c.tareWeight,
      amount: c.amount,
    })),
  };
};

export const handleMappingData = (
  searchRequest: QuoteSearchRequest | undefined,
  setFormValue: UseFormSetValue<ISearchForm>,
  resetFormValue: UseFormReset<ISearchForm>,
  terminals: Terminal[] | undefined,
  depots?: EmptyContainerDepot[] | undefined,
) => {
  if (searchRequest) {
    let originAddress: TLocationOption | null = null;
    let destinationAddress: TLocationOption | null = null;
    let originContainerDepot: TLocationOption | null = null;
    let destinationContainerDepot: TLocationOption | null = null;

    if (searchRequest.originContainerDepot) {
      if (typeof searchRequest.originContainerDepot !== 'string') {
        geocode({
          query: getAddressString(searchRequest.originContainerDepot),
          callback: (options) => {
            if (options.length) {
              setFormValue('containerPickupAddress', options[0]);
            }
          },
        });
      } else if (depots) {
        const originDepot = getDepotById(depots, searchRequest.originContainerDepot);

        if (originDepot) {
          originContainerDepot = {
            label: originDepot.name,
            value: originDepot.id,
          };
        }
      }
    }

    if (searchRequest.destinationContainerDepot) {
      if (typeof searchRequest.destinationContainerDepot !== 'string') {
        geocode({
          query: getAddressString(searchRequest.destinationContainerDepot),
          callback: (options) => {
            if (options.length) {
              setFormValue('containerDropOffAddress', options[0]);
            }
          },
        });
      } else if (depots) {
        const destinationDepot = getDepotById(depots, searchRequest.destinationContainerDepot);

        if (destinationDepot) {
          destinationContainerDepot = {
            label: destinationDepot.name,
            value: destinationDepot.id,
          };
        }
      }
    }

    if (typeof searchRequest.origin !== 'string') {
      geocode({
        query: getAddressString(searchRequest.origin),
        callback: (options) => {
          if (options.length) {
            setFormValue('originAddress', options[0]);
          }
        },
      });
    } else if (terminals) {
      const originTerminal = getTerminalById(terminals, searchRequest.origin);

      if (originTerminal) {
        originAddress = {
          label: originTerminal.name,
          value: originTerminal,
        };
      }
    }

    if (typeof searchRequest.destination !== 'string') {
      geocode({
        query: getAddressString(searchRequest.destination),
        callback: (options) => {
          if (options.length) {
            setFormValue('destinationAddress', options[0]);
          }
        },
      });
    } else if (terminals) {
      const destinationTerminal = getTerminalById(terminals, searchRequest.destination);

      if (destinationTerminal) {
        destinationAddress = {
          label: destinationTerminal.name,
          value: destinationTerminal,
        };
      }
    }

    resetFormValue(
      mapSearchQueryToFormData(searchRequest, {
        originAddress,
        destinationAddress,
        containerDropOffAddress: destinationContainerDepot,
        containerPickupAddress: originContainerDepot,
      }),
    );
  }
};

const TERMINAL_TYPES_ARRAY = Object.keys(TerminalType);

export const isTerminal = (
  address: Terminal | Address | IGeocodeFeature | string | null | undefined,
  terminalType?: TerminalType,
) => {
  if (!address || typeof address === 'string') {
    return false;
  }

  if (terminalType && 'type' in address) {
    return address.type === terminalType;
  }

  return 'type' in address && TERMINAL_TYPES_ARRAY.includes(address.type);
};

export const testExports = {
  getLocationRequestValue,
  prepareQuoteCargo,
};
