import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { NoContent } from 'components/noContent';
import { Layout } from 'components/layout';
import { useSearchQuotesById } from 'api/quotes';
import { QuotesFilters } from 'pages/quotes/components/quotesFilters';
import { useGetTerminals } from 'api/terminal';
import { QuotesList } from './components/quotesList';
import styles from './index.module.scss';

export const QuotesSearchPage = () => {
  const { t } = useTranslation('default', { keyPrefix: 'pages/quotes' });
  const { id } = useParams();

  const { data, refetch, isError, isLoading, error } = useSearchQuotesById(id || '');
  const { data: terminals } = useGetTerminals();

  useEffect(() => {
    if (id && !data) {
      refetch();
    }
  }, [id, data, refetch]);

  if (isLoading || isError || !data) {
    return (
      <NoContent
        testId="QuotesSearchPageNoContent"
        isLoading={isLoading}
        isError={isError}
        error={error}
        title={t('title')}
      />
    );
  }

  return (
    <Layout title={t('title')} testId="QuotesSearchPage">
      <div className={styles.root}>
        <div className={styles.mainContainer}>
          <QuotesFilters searchRequest={data.searchRequest} terminals={terminals} />
          <QuotesList data={data} />
        </div>
      </div>
    </Layout>
  );
};
