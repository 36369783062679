import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import type { NotificationSettings, PaginatedResponseNotification } from 'generated/api';
import { api } from 'api/request';

export enum NOTIFICATION_QUERY_KEY {
  NOTIFICATION_PAGE = 'NOTIFICATION_PAGE',
  NOTIFICATION_WIDGET = 'NOTIFICATION_WIDGET',
}

export const getNotificationSettingsQueryKey = 'getNotificationSettingsQueryKey';

const getNotifications = async (query: { page: number; perPage: number }): Promise<PaginatedResponseNotification> => {
  const { data } = await api.getNotifications(query);

  return data;
};

const getNotificationSettings = async (): Promise<NotificationSettings> => {
  const { data } = await api.getNotificationSettings();

  return data;
};

export const updateNotificationSettings = async (params: NotificationSettings): Promise<NotificationSettings> => {
  const { data } = await api.updateNotificationSettings(params);

  return data;
};

export const markNotificationAsRead = (bookingId: string) => api.markNotificationsAsRead(bookingId);

export const markAllNotificationsAsRead = () => api.markAllNotificationsAsRead();

export const useGetNotifications = (defaultQuery: { page: number; key: NOTIFICATION_QUERY_KEY }) => {
  let perPage = 1;

  if (defaultQuery.key === NOTIFICATION_QUERY_KEY.NOTIFICATION_PAGE) {
    perPage = 10;
  } else if (defaultQuery.key === NOTIFICATION_QUERY_KEY.NOTIFICATION_WIDGET) {
    perPage = 5;
  }

  return useInfiniteQuery(
    [defaultQuery.key],
    ({ pageParam }) => getNotifications(pageParam ? pageParam : { page: defaultQuery.page, perPage }),
    {
      getNextPageParam: (lastPage: PaginatedResponseNotification) => {
        if (!lastPage.pagination?.nextPage) {
          return undefined;
        }

        return { page: lastPage.pagination.page + 1, perPage };
      },
    },
  );
};

export const useGetNotificationSettings = () =>
  useQuery([getNotificationSettingsQueryKey], () => getNotificationSettings());
