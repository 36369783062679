import React, { useRef } from 'react';
import clsx from 'clsx';
import { CloseIcon } from '@rouvia/icons';
import { Icon } from 'components/icon';
import { Button } from 'components/button';
import { useOnClickOutside } from 'utils/hooks/onClickOutside';
import { LoaderBackdrop } from '../loaderBackdrop';
import styles from './styles.module.scss';

export type TProps = {
  title?: string;
  onClose: () => void;
  primaryAction?: {
    text: string;
    callback: () => void;
  };
  secondaryAction?: {
    text: string;
    callback: () => void;
  };
  classBody?: string;
  children?: React.ReactNode;
  hide?: boolean;
  isBtnLoading?: boolean;
  isBackdropLoading?: boolean;
  testId?: string;
};

export const Modal: React.FC<TProps> = ({
  title,
  classBody,
  children,
  onClose,
  primaryAction,
  secondaryAction,
  hide,
  isBtnLoading,
  isBackdropLoading,
  testId = 'overlay',
}) => {
  const modalRef = useRef(null);

  useOnClickOutside(modalRef, () => {
    if (hide === undefined) {
      onClose();
    }
  });

  return (
    <div className={clsx(styles.overlay, { [styles.hidden]: hide, activeModal: !hide })} data-testid={testId}>
      <section className={clsx(styles.modalRoot, classBody)} ref={modalRef} data-testid="modal">
        {title && <header className={styles.header}>{title}</header>}
        <article className={styles.content}>{children}</article>
        {(primaryAction || secondaryAction) && (
          <div className={styles.footer}>
            {primaryAction && (
              <Button onClick={primaryAction.callback} theme="accent" isLoading={isBtnLoading}>
                {primaryAction.text}
              </Button>
            )}
            {secondaryAction && (
              <Button theme="neutral" onClick={secondaryAction.callback} isLoading={isBtnLoading}>
                {secondaryAction.text}
              </Button>
            )}
          </div>
        )}
        <button type="button" onClick={onClose} className={styles.close} data-testid="ModalCloseBtn">
          <Icon As={CloseIcon} />
        </button>
        <LoaderBackdrop loading={isBackdropLoading} light rounded />
      </section>
    </div>
  );
};
