import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { WidgetItem } from 'components/widgetContainer/item';
import { WidgetContainer } from 'components/widgetContainer';
import { Booking } from 'generated/api';
import { formatDate, DATE_FORMAT } from 'utils/date';

import { EmptyList } from 'components/emptyList';
import { ProgressTrack } from 'components/progressTrack';
import styles from './styles.module.scss';

type TProps = {
  isLoading?: boolean;
  bookings?: Booking[];
  className?: string;
};

export const ShipmentListWidget: React.FC<TProps> = ({ bookings, isLoading, className }) => {
  const { t } = useTranslation('default', {
    keyPrefix: 'components/shipmentListWidget',
  });

  return (
    <WidgetContainer
      isLoading={isLoading}
      title={t('title')}
      className={className}
      bodyClassName={styles.scrollableContent}
    >
      {bookings?.length ? (
        bookings.map((booking: Booking, index) => {
          const { id, fwdId, route } = booking;
          const isRailQuote = route.type === 'RAIL';
          const isTruckQuote = route.type === 'TRUCK';

          let mainLeg;

          if (isRailQuote) {
            mainLeg = route.railLeg;
          } else if (isTruckQuote) {
            mainLeg = route.truckLeg;
          }

          return (
            <Link
              to={`/bookings/${id}`}
              className={styles.shipmentListWidgetRoot}
              key={id}
              data-testid={`ShipmentListWidgetItem-${index}`}
            >
              <WidgetItem>
                <div className={styles.main}>
                  <div className={styles.shipmentId}>
                    {booking.id} {fwdId && <span className={styles.refId}>({fwdId})</span>}
                  </div>
                  <div className={styles.shipmentDates}>
                    <div>{mainLeg && formatDate(mainLeg.departureDatetime, DATE_FORMAT.DATE_MONTH_FULL)}</div>
                    <div> {mainLeg && formatDate(mainLeg.arrivalDatetime, DATE_FORMAT.DATE_MONTH_FULL)}</div>
                  </div>
                  <div className={styles.shipmentTimes}>
                    <div>{mainLeg && formatDate(mainLeg.departureDatetime, DATE_FORMAT.TIME_SIMPLE)}</div>
                    <div> {mainLeg && formatDate(mainLeg.arrivalDatetime, DATE_FORMAT.TIME_SIMPLE)}</div>
                  </div>
                  <div className={styles.progress}>
                    <ProgressTrack entityWithQuote={booking} isAllDetailsHidden />
                  </div>
                </div>
              </WidgetItem>
            </Link>
          );
        })
      ) : (
        <EmptyList fixedHeight="300px" title={t('emptyShipmentTitle')} subtitle={t('emptyShipmentSubtitle')} />
      )}
    </WidgetContainer>
  );
};
