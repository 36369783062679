import React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { TrainIcon, TruckIcon } from '@rouvia/icons';
import { Address, RailServicesRequest, Terminal } from 'generated/api';
import { DATE_FORMAT, formatDate } from 'utils/date';
import { ReactComponent as DotIcon } from 'assets/images/icons/dot.svg';
import { SpaceSeparator } from 'components/spaceSeparator';
import { Switch } from 'components/switch';
import {
  COLOR,
  NODE_TYPE,
  NodeContainer,
  NodeLeg,
  NodeLoad,
  NodeMile,
  NodeRoad,
  ServicesState,
} from 'components/routeInformation/types';
import { addressStringBuilder } from 'utils/misc';
import { Colors } from 'scss/colors';
import { NODE_ADORNMENT } from './utils';
import styles from './styles.module.scss';

const IconsMap: { [key in keyof typeof NODE_ADORNMENT]: JSX.Element } = {
  POINT: (
    <div className={styles.dot}>
      <DotIcon className={styles.iconCircle} />
    </div>
  ),
  RAIL: (
    <div className={styles.iconZ}>
      <TrainIcon style={{ color: Colors.special04, width: 28, height: 28 }} />
    </div>
  ),
  TRUCK: (
    <div className={styles.iconZ}>
      <TruckIcon style={{ color: Colors.blue01, width: 22, height: 22 }} />
    </div>
  ),
};

type TProps = {
  nodeData: NodeRoad | NodeMile | NodeLoad | NodeLeg | NodeContainer;
  color?: COLOR;
  adornmentColor?: COLOR;
  startingAdornment?: NODE_ADORNMENT;
  middleAdornment?: NODE_ADORNMENT;
  shortLine?: boolean;
  isLineDisabled?: boolean;
  services?: ServicesState<RailServicesRequest>;
  truckOnly?: boolean;
  testId?: string;
};

const RouteNodeGenerator: React.FC<TProps> = ({
  nodeData,
  color,
  adornmentColor,
  startingAdornment,
  middleAdornment,
  shortLine,
  isLineDisabled,
  services,
  truckOnly,
  testId,
}) => {
  const { t } = useTranslation('default', { keyPrefix: 'pages/quotes/singleQuotePage' });

  let actualLineColor = color || COLOR.BLUE;
  let actualAdornmentColor = adornmentColor || actualLineColor;
  let actualStartingAdornment = startingAdornment || NODE_ADORNMENT.POINT;
  let actualMiddleAdornment = middleAdornment;
  let isActualLineDisabled = isLineDisabled !== undefined ? isLineDisabled : false;
  let isActualShortLine = shortLine !== undefined ? shortLine : false;
  let content;
  let secondaryContent;
  let optionalContent;

  const handleToggleLastMile = () => {
    if (services) {
      services.setRequestedServices((prev) => ({ ...prev, lastMile: !prev.lastMile }));
    }
  };
  const handleToggleFirstMile = () => {
    if (services) {
      services.setRequestedServices((prev) => ({ ...prev, firstMile: !prev.firstMile }));
    }
  };

  const getAddressBlock = (address?: Address | Terminal) => {
    let terminalName;
    let openingHours;
    let localAddress;

    if (!address) {
      return;
    }

    if ('name' in address) {
      terminalName = address.name;
      openingHours = address.openingHours;
      localAddress = address.address;
    } else {
      localAddress = address;
    }

    return (
      <div className={styles.section}>
        <div className={styles.sublabel}>{terminalName ? t('routeInfo.terminal') : t('routeInfo.address')}:</div>
        <div className={styles.subtitle}>{terminalName || localAddress.city}</div>
        <div className={styles.faded}>
          <SpaceSeparator>
            {addressStringBuilder(localAddress, { keys: ['street', 'zip', 'city', 'country'] })}
          </SpaceSeparator>
        </div>
        {openingHours && (
          <div className={styles.faded}>
            {t('routeInfo.openingHours')}
            {': '}
            {openingHours}
          </div>
        )}
      </div>
    );
  };

  const calculateMainContent = () => {
    if (nodeData.type === NODE_TYPE.ORIGIN || nodeData.type === NODE_TYPE.DESTINATION) {
      actualLineColor = color || COLOR.BLUE;
      if (nodeData.type === NODE_TYPE.ORIGIN) {
        isActualShortLine = shortLine !== undefined ? shortLine : true;
      } else if (nodeData.type === NODE_TYPE.DESTINATION) {
        isActualLineDisabled = isLineDisabled !== undefined ? isLineDisabled : true;
      }

      content = (
        <div className={styles.content}>
          <div className={styles.sectionGroup}>
            <div className={styles.section}>
              <div className={styles.title}>
                {nodeData.type === NODE_TYPE.ORIGIN ? t('routeInfo.origin') : t('routeInfo.destination')}
              </div>
              {getAddressBlock(nodeData.addressObj)}
            </div>
          </div>
        </div>
      );
    } else if (nodeData.type === NODE_TYPE.CONTAINER_PICKUP || nodeData.type === NODE_TYPE.CONTAINER_DROPOFF) {
      actualLineColor = color || COLOR.BLUE;

      let containerProcurementLeg: 'lastMile' | 'firstMile' = 'lastMile';

      if (nodeData.type === NODE_TYPE.CONTAINER_PICKUP) {
        containerProcurementLeg = 'firstMile';
        isActualShortLine = shortLine !== undefined ? shortLine : true;
      } else if (nodeData.type === NODE_TYPE.CONTAINER_DROPOFF) {
        isActualLineDisabled = isLineDisabled !== undefined ? isLineDisabled : true;
      }

      content = (
        <div className={styles.content}>
          <div className={styles.sectionGroup}>
            <div className={styles.section}>
              <div className={styles.title}>
                {nodeData.type === NODE_TYPE.CONTAINER_PICKUP
                  ? t('routeInfo.containerPickup')
                  : t('routeInfo.containerDropOff')}
              </div>
              <div className={styles.sublabel}>
                {nodeData.type === NODE_TYPE.CONTAINER_PICKUP ? t('routeInfo.pickupBy') : t('routeInfo.handoverBy')}:
              </div>
              <div className={styles.subtitle}>
                {truckOnly || services?.requestedServices[containerProcurementLeg] || nodeData.serviceRequested
                  ? nodeData.carrier
                  : t('routeInfo.selfOrganised')}
              </div>
            </div>
          </div>
        </div>
      );
    } else if (nodeData.type === NODE_TYPE.FIRST_MILE || nodeData.type === NODE_TYPE.LAST_MILE) {
      let mileKey: 'firstMile' | 'lastMile' = 'firstMile';

      actualLineColor = color || COLOR.BLUE;
      actualStartingAdornment = startingAdornment || NODE_ADORNMENT.POINT;

      if (nodeData.type === NODE_TYPE.FIRST_MILE) {
        actualMiddleAdornment = middleAdornment || NODE_ADORNMENT.TRUCK;
      }

      if (nodeData.type === NODE_TYPE.LAST_MILE) {
        mileKey = 'lastMile';
        isActualLineDisabled = isLineDisabled !== undefined ? isLineDisabled : true;
      }

      content = (
        <div className={styles.content}>
          <div className={styles.sectionGroup}>
            <div className={styles.section}>
              <div className={styles.title}>
                {nodeData.type === NODE_TYPE.FIRST_MILE
                  ? t('routeInfo.collectionAndFirstMile')
                  : t('routeInfo.lastMile')}
              </div>
              <div className={styles.sublabel}>
                {nodeData.type === NODE_TYPE.FIRST_MILE ? t('routeInfo.handoverBy') : t('routeInfo.pickupBy')}:
              </div>
              <div className={styles.subtitle}>
                {truckOnly || services?.requestedServices?.[mileKey] || nodeData.serviceRequested
                  ? nodeData.mileLeg?.operator.name
                  : t('routeInfo.selfOrganised')}
              </div>
              {nodeData.mileLeg && nodeData.serviceRequested && (
                <div style={{ paddingTop: 4 }}>
                  {nodeData.mileLeg.departureDatetime && (
                    <div className={styles.subLabelTime}>
                      {nodeData.type === NODE_TYPE.FIRST_MILE
                        ? t('routeInfo.latestPickupTimeConsignor')
                        : t('routeInfo.estimatedDepartureDatetime')}
                      {': '}
                      <span className={styles.subLabelTimeBold}>
                        {formatDate(nodeData.mileLeg.departureDatetime, DATE_FORMAT.COMPLETE)}
                      </span>
                    </div>
                  )}
                  {nodeData.mileLeg.arrivalDatetime && (
                    <div className={styles.subLabelTime}>
                      {nodeData.type === NODE_TYPE.FIRST_MILE
                        ? t('routeInfo.earliestHandoverTimeConsignee')
                        : t('routeInfo.estimatedArrivalDatetime')}
                      {': '}
                      <span className={styles.subLabelTimeBold}>
                        {formatDate(nodeData.mileLeg.arrivalDatetime, DATE_FORMAT.COMPLETE)}
                      </span>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      );
    } else if (nodeData.type === NODE_TYPE.LOADING || nodeData.type === NODE_TYPE.UNLOADING) {
      actualLineColor = color || (nodeData.type === NODE_TYPE.LOADING ? COLOR.PURPLE : COLOR.BLUE);
      actualAdornmentColor = adornmentColor || color || COLOR.PURPLE;
      if (nodeData.type === NODE_TYPE.LOADING) {
        isActualShortLine = shortLine !== undefined ? shortLine : true;
      }

      if (nodeData.type === NODE_TYPE.UNLOADING) {
        actualMiddleAdornment = middleAdornment || NODE_ADORNMENT.TRUCK;
      }

      content = (
        <div className={styles.section}>
          <div className={styles.title}>
            {t(nodeData.type === NODE_TYPE.LOADING ? 'routeInfo.loading' : 'routeInfo.unloading')}
          </div>
          <div className={styles.sublabel}>{t('routeInfo.operatedBy')}</div>
          <div className={styles.subtitle}>{nodeData.operator}</div>

          {nodeData.departureDate && (
            <div className={styles.sectionData}>
              <div>
                {t('routeInfo.date')}
                {': '}
                <span className={styles.strong}>{formatDate(nodeData.departureDate, DATE_FORMAT.DATE_MONTH_FULL)}</span>
              </div>
            </div>
          )}
          {nodeData.earliestOrLatestTime && nodeData.serviceRequested && (
            <div className={styles.subLabelTime}>
              {nodeData.type === NODE_TYPE.LOADING ? t('routeInfo.latestHandoverTime') : t('routeInfo.earliestPickUp')}
              {': '}
              <span className={styles.subLabelTimeBold}>
                {formatDate(nodeData.earliestOrLatestTime, DATE_FORMAT.COMPLETE)}
              </span>
            </div>
          )}
        </div>
      );
    } else if (nodeData.type === NODE_TYPE.LONG_LEG || nodeData.type === NODE_TYPE.DELIVERY) {
      actualLineColor = color || (nodeData.type === NODE_TYPE.LONG_LEG ? COLOR.PURPLE : COLOR.BLUE);
      actualAdornmentColor = adornmentColor || color || actualLineColor;
      if (nodeData.type === NODE_TYPE.LONG_LEG) {
        actualStartingAdornment = startingAdornment || NODE_ADORNMENT.RAIL;
      } else if (nodeData.type === NODE_TYPE.DELIVERY) {
        actualStartingAdornment = startingAdornment || NODE_ADORNMENT.TRUCK;
      }

      content = (
        <div className={styles.section}>
          <div className={styles.title}>
            {nodeData.type === NODE_TYPE.LONG_LEG ? t('routeInfo.longLeg') : t('routeInfo.delivery')}
          </div>
          <div className={styles.sublabel}>
            {nodeData.type === NODE_TYPE.LONG_LEG ? t('routeInfo.operatedBy') : t('routeInfo.carrier')}
          </div>
          <div className={styles.subtitle}>{nodeData.carrier}</div>
          {nodeData.trainNumber && (
            <div className={styles.sectionData}>
              {t('routeInfo.trainNumber')}
              {': '}
              {nodeData.trainNumber}
            </div>
          )}
        </div>
      );
    }
  };

  const calculateSecondaryContent = () => {
    if (nodeData.type === NODE_TYPE.LONG_LEG || nodeData.type === NODE_TYPE.DELIVERY) {
      secondaryContent = (
        <div className={styles.section}>
          {nodeData.departureDatetime && (
            <div className={styles.faded}>
              {t('routeInfo.departure')}
              {': '}
              <span className={styles.strong}>{formatDate(nodeData.departureDatetime, DATE_FORMAT.COMPLETE)}</span>
            </div>
          )}
          {nodeData.arrivalDatetime && (
            <div className={styles.faded}>
              {t('routeInfo.arrival')}
              {': '}
              <span className={styles.strong}>{formatDate(nodeData.arrivalDatetime, DATE_FORMAT.COMPLETE)}</span>
            </div>
          )}
        </div>
      );
    } else if (nodeData.type !== NODE_TYPE.ORIGIN && nodeData.type !== NODE_TYPE.DESTINATION) {
      if ('addressObj' in nodeData) {
        if ('address' in nodeData.addressObj) {
          secondaryContent = getAddressBlock(nodeData.addressObj.address);
        } else if ('terminal' in nodeData) {
          secondaryContent = getAddressBlock(nodeData.terminal);
        } else {
          secondaryContent = getAddressBlock(nodeData.addressObj);
        }
      }
    }
  };

  const calculateOptionalContent = () => {
    if ((nodeData.type === NODE_TYPE.FIRST_MILE || nodeData.type === NODE_TYPE.LAST_MILE) && services) {
      const isFirstMile = nodeData.type === NODE_TYPE.FIRST_MILE;
      const isServiceMileRequested = isFirstMile
        ? services.requestedServices.firstMile
        : services.requestedServices.lastMile;

      optionalContent = (
        <div className={clsx(styles.section, styles.outsourcing)}>
          <>
            <div className={styles.sublabel}>{t('routeInfo.outsourcingOption')}:</div>
            <Switch
              label={isFirstMile ? t('routeInfo.firstMileSwitchLabel') : t('routeInfo.lastMileSwitchLabel')}
              className={styles.quoteSwitch}
              isActive={isServiceMileRequested}
              onClick={isFirstMile ? handleToggleFirstMile : handleToggleLastMile}
            />
          </>

          <div
            className={clsx(styles.sectionData, {
              [styles.disabled]: !isServiceMileRequested,
            })}
          >
            <div className={styles.sublabel}>{t('routeInfo.sender')}:</div>
            <div className={styles.subtitle} style={{ paddingBottom: 4 }}>
              {nodeData.mileLeg?.operator.name}
            </div>
            {nodeData.mileLeg && (
              <>
                <div>
                  {nodeData.type === NODE_TYPE.FIRST_MILE
                    ? t('routeInfo.latestPickupTimeConsignor')
                    : t('routeInfo.estimatedDepartureDatetime')}
                  {': '}
                  <span className={styles.strong}>
                    {formatDate(nodeData.mileLeg.departureDatetime, DATE_FORMAT.COMPLETE)}
                  </span>
                </div>
                <div>
                  {nodeData.type === NODE_TYPE.FIRST_MILE
                    ? t('routeInfo.earliestHandoverTimeConsignee')
                    : t('routeInfo.estimatedArrivalDatetime')}
                  {': '}
                  <span className={styles.strong}>
                    {formatDate(nodeData.mileLeg.arrivalDatetime, DATE_FORMAT.COMPLETE)}
                  </span>
                </div>

                {nodeData.cost && (
                  <div>
                    {t('routeInfo.price')}
                    {': '}
                    <span className={styles.strong}>
                      {'€ '}
                      {nodeData.cost}
                    </span>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      );
    } else if (
      (nodeData.type === NODE_TYPE.CONTAINER_PICKUP || nodeData.type === NODE_TYPE.CONTAINER_DROPOFF) &&
      services?.requestedServices
    ) {
      const isPickup = nodeData.type === NODE_TYPE.CONTAINER_PICKUP;
      const isServiceMileRequested = isPickup
        ? services.requestedServices.firstMile
        : services.requestedServices.lastMile;

      optionalContent = (
        <div className={styles.section}>
          <div className={styles.sublabel}>{t('routeInfo.outsourcingOption')}:</div>
          <Switch
            isActive={Boolean(isServiceMileRequested)}
            label={t('routeInfo.containerPickup')}
            tooltip={
              isPickup ? t('routeInfo.tooltipPickupEmptyContainer') : t('routeInfo.tooltipDropoffEmptyContainer')
            }
            className={styles.quoteSwitch}
            onClick={isPickup ? handleToggleFirstMile : handleToggleLastMile}
          />
        </div>
      );
    }
  };

  calculateMainContent();
  calculateSecondaryContent();
  calculateOptionalContent();

  return (
    <div className={clsx(styles.nodeRoot)} data-testid={testId}>
      <div
        className={clsx(styles.lineWrapper, styles[actualAdornmentColor], { [styles.shortLine]: isActualShortLine })}
      >
        {IconsMap[actualStartingAdornment]}
        {!isActualLineDisabled && (
          <div
            className={clsx(styles.connectingLine, styles[actualLineColor], {
              [styles.middleAdornmentEnabled]: Boolean(actualMiddleAdornment),
            })}
          />
        )}
        {actualMiddleAdornment && !isActualLineDisabled && (
          <>
            <div className={clsx(styles.middleAdornment, styles[actualLineColor])}>
              {IconsMap[actualMiddleAdornment]}
            </div>
            <div className={clsx(styles.middleConnectingLine, styles[actualLineColor], {})} />
          </>
        )}
      </div>
      <div className={clsx(styles.nodeBody, { [styles.servicesEnabled]: Boolean(services?.requestedServices) })}>
        <div>{content}</div>
        <div>{secondaryContent}</div>
        {optionalContent && <div>{optionalContent}</div>}
      </div>
    </div>
  );
};

export default React.memo(RouteNodeGenerator);
