import geocodingService from '@mapbox/mapbox-sdk/services/geocoding';
import type { GroupBase } from 'react-select';
import type { EmptyContainerDepot, Terminal } from 'generated/api';
import { searchCountries } from 'constants/countries';
import { MapBoxAccessToken } from 'constants/map';
import {
  MAPBOX_SEARCH_MIN_CHARACTERS,
  MAPBOX_SEARCH_RESULTS_LIMIT,
  type TGeoOption,
  type TSelectOption,
} from 'constants/planning';
import i18n from 'i18n';
import { addressStringBuilder, getAddressFromOnD } from 'utils/misc';

const geocoding = geocodingService({ accessToken: MapBoxAccessToken });

type TGeocode = {
  selectOption?: TSelectOption<string>;
  query: string;
  callback?: (options: TGeoOption[]) => void;
};

export const geocode = ({ query, callback, selectOption }: TGeocode) => {
  if (query.length < MAPBOX_SEARCH_MIN_CHARACTERS || typeof selectOption === 'string') {
    return;
  }

  return geocoding
    .forwardGeocode({
      query,
      countries: searchCountries,
      autocomplete: true,
      limit: MAPBOX_SEARCH_RESULTS_LIMIT,
      types: ['address', 'poi'],
      routing: true,
    })
    .send()
    .then(
      (response) => {
        const result = response.body.features.map((item) => ({
          value: item,
          label: item.place_name,
        }));

        if (callback) {
          callback(result as unknown as TGeoOption[]);
        }

        return result;
      },
      (error) => {
        console.error(error);
      },
    );
};

export const loadSelectGeoOptions = (inputValue: string, callback: (options: GroupBase<unknown>[]) => void) => {
  geocode({
    query: inputValue,
  })?.then((data) => {
    if (data) {
      callback([
        {
          label: i18n.t('default:common.addresses'),
          options: data,
        },
      ]);
    }
  });
};

export const searchTerminals = (terminals: Terminal[], query: string): TSelectOption<Terminal>[] => {
  const search = query.trim().toLowerCase();

  return terminals
    .filter((terminal: Terminal) =>
      [terminal.name, terminal.operator, terminal.address.city, terminal.address.country, terminal.address.street].some(
        (str: string) => str.toLowerCase().includes(search),
      ),
    )
    .map((terminal: Terminal) => ({
      label: terminal.name,
      secondaryText: addressStringBuilder(getAddressFromOnD(terminal.address), {
        keys: ['street', 'zip', 'country'],
      }),
      value: terminal,
    }));
};

export const loadSelectGeoOptionsTerminals =
  (terminals: Terminal[]) => (inputValue: string, callback: (options: GroupBase<unknown>[]) => void) => {
    const terminalGroup = [
      {
        label: i18n.t('default:common.terminals'),
        options: searchTerminals(terminals, inputValue),
      },
    ];

    geocode({
      query: inputValue,
    })?.then((data) => {
      if (data) {
        callback([
          ...terminalGroup,
          {
            label: i18n.t('default:common.warehouses'),
            options: data,
          },
        ]);
      } else {
        callback(terminalGroup);
      }
    });
  };

export const searchDepots = (depots: EmptyContainerDepot[], query: string): TSelectOption<EmptyContainerDepot>[] => {
  const search = query.trim().toLowerCase();

  return depots
    .filter((depot: EmptyContainerDepot) =>
      [depot.name, depot.address.city, depot.address.country, depot.address.street, depot.address.zip].some(
        (str: string) => str.toLowerCase().includes(search),
      ),
    )
    .map((depot: EmptyContainerDepot) => ({
      label: depot.name,
      value: depot,
    }));
};

export const loadSelectGeoOptionsDepots =
  (depots: EmptyContainerDepot[]) => (inputValue: string, callback: (options: GroupBase<unknown>[]) => void) => {
    const terminalGroup = [
      {
        label: i18n.t('default:common.depots'),
        options: searchDepots(depots, inputValue),
      },
    ];

    geocode({
      query: inputValue,
    })?.then((data) => {
      if (data) {
        callback([
          ...terminalGroup,
          {
            label: i18n.t('default:common.customDepots'),
            options: data,
          },
        ]);
      } else {
        callback(terminalGroup);
      }
    });
  };
