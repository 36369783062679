import React, { useEffect, useState, useMemo, useContext } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useMutation } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';
import clsx from 'clsx';

import type { TLocationPoints } from 'constants/planning';
import { Modal } from 'components/modal';
import ToastContext from 'components/toast';
import { useGetOrganisationUsers, useGetUserInfo } from 'api/user';
import { bookingsQueryKey, createBooking } from 'api/bookings';
import { CustomSelect } from 'components/select';
import { Button } from 'components/button';
import { ContentBox } from 'components/contentBox';
import { Input } from 'components/input';
import { Layout } from 'components/layout';
import { Map } from 'components/map';
import { NoContent } from 'components/noContent';
import { Tooltip } from 'components/tooltip';
import {
  ApiError,
  Booking,
  BookingCreationRequest,
  CargoItem,
  ErrorType,
  RailServicesRequest,
  RailRoute,
  TruckRoute,
  User,
} from 'generated/api';
import { useGetQuote } from 'api/quotes';
import { loadingUnitsObject, sizesObject } from 'constants/equipments';
import { formatDate, DATE_FORMAT, getDurationInHours, getUnixTime } from 'utils/date';
import { useGetUser } from 'utils/hooks/useGetUser';
import { addressStringBuilder, getAddressFromOnD, getFullName } from 'utils/misc';
import { RouteInformation } from 'components/routeInformation';
import { isTerminal } from 'utils/planning';
import { ServicesTypeRequest } from 'types/common';
import { PriceSheet } from 'components/priceSheet';
import { BOOKING_TAB } from 'pages/booking/utils';
import config from 'constants/config';
import { ServicesState } from 'components/routeInformation/types';
import { queryClient } from 'constants/api';
import { AdditionalQuoteInformation } from './components/additionalQuoteInformation';
import { EquipmentInformation } from './components/equipmentInformation';
import { GeneralTermsDocuments } from './components/GeneralTermsDocuments';
import { IForm } from './constants';
import { mapQuoteFormToBookingCreationRequest, isProcurementIsAutomated } from './utils';
import styles from './singlequote.module.scss';

export const SingleQuotePage = () => {
  const { t } = useTranslation('default', { keyPrefix: 'pages/quotes/singleQuotePage' });
  const { t: commonT } = useTranslation();
  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    watch,
    trigger,
    formState: { isValid },
  } = useForm<IForm>({ mode: 'onChange', reValidateMode: 'onChange' });
  const { id } = useParams();
  const navigate = useNavigate();
  const [currentUser] = useGetUser();
  const { data: quote, isError, isLoading, error: fetchError } = useGetQuote(id || '');
  const { data: users } = useGetOrganisationUsers();
  const [requestedServices, setRequestedServices] = useState<ServicesTypeRequest | null>(null);
  const [formError, setFormError] = useState<ErrorType>();
  const [isEquipmentValid, setIsEquipmentValid] = useState(false);
  const [isModalVisible, setModalVisible] = useState(false);
  const { playErrorToast } = useContext(ToastContext);

  const { data: userData } = useGetUserInfo(currentUser.id);

  const [is60Feet, setIs60Feet] = useState<boolean>(false);

  useEffect(() => {
    if (userData && config.org60FeetId) {
      const is60FeetTemp = config.org60FeetId === userData.organisation?.id;

      setIs60Feet(is60FeetTemp);
      if (is60FeetTemp) {
        setIsEquipmentValid(true);
      }
    }
  }, [userData]);

  useEffect(() => {
    let hasFirstMile = false;
    let hasLastMile = false;

    if (quote) {
      if ('firstMileLeg' in quote.route) {
        hasFirstMile = Boolean(quote.route.firstMileLeg);
      }

      if ('lastMileLeg' in quote.route) {
        hasLastMile = Boolean(quote.route.lastMileLeg);
      }

      setRequestedServices({ firstMile: hasFirstMile, lastMile: hasLastMile });
    } else {
      setRequestedServices(null);
    }
  }, [quote]);

  const {
    data: creationResponse,
    mutate: bookingCreationAction,
    isLoading: isBookingCreationLoading,
  } = useMutation<Booking, ApiError, BookingCreationRequest>((data: BookingCreationRequest) => createBooking(data), {
    onSuccess: ({ id: bookingId, route, priceBreakdown }) => {
      const isRailRoute = route.type === 'RAIL';
      const mainOperator = isRailRoute
        ? (route as RailRoute).railLeg.operator
        : (route as TruckRoute).truckLeg.operator;

      const isAutomated = isProcurementIsAutomated(route, mainOperator, isRailRoute, priceBreakdown);

      if (isAutomated) {
        queryClient.invalidateQueries([bookingsQueryKey], { refetchType: 'all' });
        navigate(`/bookings/${bookingId}/${BOOKING_TAB.OVERVIEW}`);
      } else {
        setModalVisible(true);
      }
    },
    onError: (apiError: ApiError) => {
      playErrorToast();
      setFormError(apiError.errorType);
    },
  });

  const usersOptions = useMemo(
    () =>
      users?.map((user: User) => ({
        label: getFullName(user),
        value: user.id,
      })),
    [users],
  );

  const handleRedirectToBooking = () => {
    if (creationResponse?.id) {
      navigate(`/bookings/${creationResponse.id}/${BOOKING_TAB.OVERVIEW}`);
    }
  };

  const closeModal = () => {
    setModalVisible(false);
    handleRedirectToBooking();
  };

  const onSubmit = (formData: IForm) => {
    setFormError(undefined);
    if (quote?.id) {
      bookingCreationAction(
        mapQuoteFormToBookingCreationRequest(
          formData,
          quote.id,
          requestedServices && quote.type === 'RAIL' ? requestedServices : undefined,
        ),
      );
    }
  };

  useEffect(() => {
    if (currentUser && !getValues('assignees')) {
      setValue('assignees', [
        {
          label: getFullName(currentUser),
          value: currentUser.id,
        },
      ]);
    }
  }, [currentUser, getValues, setValue]);

  if (isError || isLoading || !quote) {
    return <NoContent error={fetchError} isError={isError} isLoading={isLoading} title={t('page.title')} />;
  }

  const { route, emissionsSaved, priceBreakdown, cargo, searchId } = quote;
  const isRailRoute = route.type === 'RAIL';
  const isTruckRoute = route.type === 'TRUCK';
  const mainOperator = isRailRoute ? route.railLeg.operator : (route as TruckRoute).truckLeg.operator;
  const isWarehouseOrigin = !isTerminal(quote.origin);
  const isWarehouseDestination = !isTerminal(quote.destination);
  const isOriginCorrespondentRequired =
    isWarehouseOrigin && (isTruckRoute || (isRailRoute && (requestedServices as RailServicesRequest)?.firstMile));
  const isDestinationCorrespondentRequired =
    isWarehouseDestination && (isTruckRoute || (isRailRoute && (requestedServices as RailServicesRequest)?.lastMile));

  const isExpired = getUnixTime() > getUnixTime(quote.expiredAt);

  const handleSetEquipmentValidity = (state: boolean[]) => {
    setIsEquipmentValid(state.every((i) => Boolean(i)));
  };

  const getContactsFields = (location: TLocationPoints) => (
    <Controller
      name={`additionalInformation.${location}.companyName`}
      control={control}
      rules={{ required: commonT('common.validation.required'), validate: (value) => value.trim().length > 0 }}
      shouldUnregister={true}
      render={({ field, fieldState: { error: err } }) => (
        <Input
          className={styles.input}
          label={t(`summaryBlock.form.${location}Participant`)}
          error={err?.message}
          showRequired
          {...field}
        />
      )}
    />
  );

  return (
    <Layout title={t('page.title')} backLink={`/quotes/search/${searchId}`}>
      <div className={styles.root}>
        <div className={styles.main}>
          <ContentBox title={t('generalInformation')} contentClassName={styles.generalInfo}>
            <div className={styles.section}>
              <img
                className={styles.operatorLogo}
                alt={mainOperator.name}
                src={`/images/operators/${mainOperator.logo}`}
              />
              <div className={styles.operatorName}>{mainOperator.name}</div>

              <div className={styles.dataGroup}>
                <div className={styles.item}>
                  {t('general.totalDeliveryTime')}
                  {': '}
                  {getDurationInHours(route.duration)}
                </div>

                <div className={clsx(styles.item, styles.flex)}>
                  {t('general.co2Savings')}
                  :&nbsp;
                  <span className={styles.special}>
                    {emissionsSaved}
                    {' Kg'}
                  </span>
                  &nbsp;
                  <Tooltip text={t('general.co2tooltip')} position="bottom" />
                </div>
              </div>

              <div className={styles.dataGroup}>
                <div className={styles.equipmentTitle}>{t('general.equipment')}:</div>
                {cargo.map((item: CargoItem) => (
                  <div key={item.loadingUnit + item.amount}>
                    <div className={styles.equipment}>
                      {item.amount}
                      {'x '}
                      {loadingUnitsObject[item.loadingUnit]}
                      {' ('}
                      {sizesObject[item.size]})
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div className={styles.section}>
              <div className={styles.title}>{t('general.origin')}</div>
              {isRailRoute && (
                <>
                  <div className={styles.sublabel}>{t('general.terminal')}:</div>
                  <div className={styles.subtitle}>{route.railLeg.origin.name}</div>
                </>
              )}

              {isTruckRoute && <div className={styles.subtitle}>{getAddressFromOnD(route.truckLeg.origin).city}</div>}

              <div className={styles.sectionData}>
                {isRailRoute &&
                  addressStringBuilder(route.railLeg.origin.address, { keys: ['street', 'zip', 'country'] })}

                {isTruckRoute &&
                  addressStringBuilder(getAddressFromOnD(route.truckLeg.origin), {
                    keys: ['street', 'zip', 'country'],
                  })}

                {isRailRoute && route.railLeg.origin.openingHours && (
                  <div>
                    {t('general.openingHours')}
                    {': '}
                    {route.railLeg.origin.openingHours}
                  </div>
                )}
              </div>

              <div className={styles.dataGroup}>
                {isRailRoute && (
                  <>
                    <div>{t('general.latestHandoverTime')}:</div>
                    <div className={styles.time}>
                      {formatDate(route.railLeg.latestHandoverTime, DATE_FORMAT.WEEKDAY_DATE_MONTH_TIME)}
                    </div>
                  </>
                )}

                {isTruckRoute && (
                  <>
                    <div>{t('general.collectionTime')}:</div>
                    <div className={styles.time}>
                      {formatDate(route.truckLeg.departureDatetime, DATE_FORMAT.WEEKDAY_DATE_MONTH_TIME)}
                    </div>
                  </>
                )}
              </div>
            </div>

            <div className={styles.section}>
              <div className={styles.title}>{t('general.destination')}</div>

              {isRailRoute && (
                <>
                  <div className={styles.sublabel}>{t('general.terminal')}:</div>
                  <div className={styles.subtitle}>{route.railLeg.destination.name}</div>
                </>
              )}

              {isTruckRoute && (
                <div className={styles.subtitle}>{getAddressFromOnD(route.truckLeg.destination).city}</div>
              )}

              <div className={styles.sectionData}>
                {isRailRoute && (
                  <>
                    {addressStringBuilder(route.railLeg.destination.address, { keys: ['street', 'zip', 'country'] })}

                    {route.railLeg.destination.openingHours && (
                      <div>
                        {t('general.openingHours')}
                        {': '}
                        {route.railLeg.destination.openingHours}
                      </div>
                    )}
                  </>
                )}

                {isTruckRoute &&
                  addressStringBuilder(getAddressFromOnD(route.truckLeg.destination), {
                    keys: ['street', 'zip', 'country'],
                  })}
              </div>

              <div className={styles.dataGroup}>
                {isRailRoute && (
                  <>
                    <div>{t('general.earliestPickupTime')}:</div>
                    <div className={styles.time}>
                      {formatDate(route.railLeg.earliestPickupTime, DATE_FORMAT.WEEKDAY_DATE_MONTH_TIME)}
                    </div>
                  </>
                )}

                {isTruckRoute && (
                  <>
                    <div>{t('general.deliveryTime')}:</div>
                    <div className={styles.time}>
                      {formatDate(route.truckLeg.arrivalDatetime, DATE_FORMAT.WEEKDAY_DATE_MONTH_TIME)}
                    </div>
                  </>
                )}
              </div>
            </div>
          </ContentBox>

          {!isExpired && (
            <>
              <AdditionalQuoteInformation quote={quote} formControl={control} requestedServices={requestedServices} />
              {!is60Feet && (
                <EquipmentInformation
                  quote={quote}
                  formControl={control}
                  formWatch={watch}
                  formTrigger={trigger}
                  setFormValue={setValue}
                  setEquipmentValidity={handleSetEquipmentValidity}
                />
              )}
            </>
          )}

          <RouteInformation
            entity={quote}
            mutableLocalServices={
              requestedServices === null
                ? undefined
                : ({ requestedServices, setRequestedServices } as ServicesState<RailServicesRequest>)
            }
          />
          <ContentBox title={t('map')} contentClassName={styles.map}>
            <Map quoteEntity={quote} mapConfig={{ zoom: 2 }} />
          </ContentBox>
        </div>

        <ContentBox title={t('summary')} wrapperClassName={styles.summary} contentClassName={styles.content}>
          <form className={styles.form} onSubmit={handleSubmit(onSubmit)} noValidate>
            <div className={styles.formItems}>
              <Controller
                name="fwdId"
                control={control}
                render={({ field, fieldState: { error: err } }) => (
                  <Input
                    className={styles.input}
                    label={t('summaryBlock.form.referenceNumberLabel')}
                    error={err?.message}
                    {...field}
                  />
                )}
              />

              <Controller
                name="assignees"
                control={control}
                rules={{ required: commonT('common.validation.required') }}
                render={({ field, fieldState: { error } }) => (
                  <CustomSelect options={usersOptions} error={error?.message} showRequired isMulti {...field} />
                )}
              />

              {/*Injection in Booking Information*/}
              {isOriginCorrespondentRequired && getContactsFields('origin')}
              {isDestinationCorrespondentRequired && getContactsFields('destination')}
            </div>

            <div className={styles.priceWrapper}>
              <div className={styles.title}>{t('summaryBlock.price')}</div>

              {isRailRoute && (
                <>
                  {'railLeg' in priceBreakdown && (
                    <PriceSheet
                      servicesClassName={styles.servicesPrices}
                      requestedServices={requestedServices}
                      priceBreakdown={priceBreakdown}
                    />
                  )}
                </>
              )}

              {isTruckRoute && (
                <>
                  {'allTruckLegs' in priceBreakdown && (
                    <PriceSheet
                      servicesClassName={styles.servicesPrices}
                      requestedServices={requestedServices}
                      priceBreakdown={priceBreakdown}
                    />
                  )}
                </>
              )}
            </div>
            {formError && <div className={styles.error}>{commonT(`errors.${formError}`)}</div>}
            <Button
              className={styles.submitButton}
              type="submit"
              theme="accent"
              size="large"
              isLoading={isBookingCreationLoading}
              disabled={!isValid || !isEquipmentValid || isExpired}
            >
              {isProcurementIsAutomated(route, mainOperator, isRailRoute, requestedServices)
                ? t('summaryBlock.ctaDirectButton')
                : t('summaryBlock.ctaRequestButton')}
            </Button>
            {!isExpired && <div className={styles.allInLabel}>{t('summaryBlock.allInRateLabel')}</div>}
          </form>

          {isExpired ? (
            <div className={clsx(styles.note, styles.expired)}>{t('summaryBlock.expired')}</div>
          ) : (
            <div>
              <div className={clsx(styles.note)}>
                <Trans
                  t={t}
                  i18nKey="summaryBlock.note"
                  components={[
                    <a key="0" target="_blank" rel="noreferrer noopener" />,
                    <a key="1" target="_blank" rel="noreferrer noopener" />,
                  ]}
                />
              </div>
              <GeneralTermsDocuments route={quote.route} />
            </div>
          )}
        </ContentBox>
      </div>
      {isModalVisible && (
        <Modal
          onClose={closeModal}
          title={t('modal.title')}
          primaryAction={{
            text: t('modal.button'),
            callback: handleRedirectToBooking,
          }}
        >
          {t('modal.text')}
        </Modal>
      )}
    </Layout>
  );
};
