import clsx from 'clsx';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FilterIcon } from '@rouvia/icons';
import { Quote as IQuote, QuoteSearch } from 'generated/api';
import { Button } from 'components/button';
import { Switcher } from 'components/switcher';
import { Switch } from 'components/switch';
import { useOnClickOutside } from 'utils/hooks/onClickOutside';

import { EmptyState } from '../emptyState';
import { SORT_MAP } from '../../constants';
import { sortQuotes } from '../../utils';
import { Quote } from '../quote';
import styles from './index.module.scss';

type TProps = {
  data: QuoteSearch;
};

export const QuotesList: React.FC<TProps> = ({ data }) => {
  const { t } = useTranslation('default', {
    keyPrefix: 'pages/quotes/components/quotesList',
  });
  const [activeSorting, setActiveSorting] = useState(SORT_MAP.PRICE);
  const [isPinTruckOnly, setIsPinTruckOnly] = useState(false);
  const [showTtTPrice, setShowTtTPrice] = useState<boolean | undefined>(false);
  const [showFilter, setShowFilter] = useState(false);
  const [isTerminalLocation, setIsTerminalLocation] = useState(false);
  const filterRef = useRef<HTMLDivElement>(null);
  const isTruckQuotePresented = Boolean(data.quotes.length > 1 && data.quotes[0].type === 'TRUCK');

  useOnClickOutside(filterRef, () => setShowFilter(false));

  const quotes = useMemo(() => {
    if (!data.quotes.length) {
      return [];
    }

    if ((showTtTPrice || isPinTruckOnly) && isTruckQuotePresented) {
      return data.quotes.slice(1);
    }

    return data.quotes;
  }, [showTtTPrice, isPinTruckOnly, isTruckQuotePresented, data.quotes]);

  const sortedQuotes = useMemo(() => {
    if (!quotes?.length) {
      return [];
    }

    const sortFn = sortQuotes(activeSorting, showTtTPrice);
    const sorted = quotes.slice().sort(sortFn);

    if ((showTtTPrice || isPinTruckOnly) && isTruckQuotePresented) {
      sorted.unshift(data.quotes[0]);
    }

    return sorted;
  }, [quotes, activeSorting, showTtTPrice, isPinTruckOnly, isTruckQuotePresented, data.quotes]);

  useEffect(() => {
    if (showTtTPrice) {
      setIsPinTruckOnly(false);
    }
  }, [showTtTPrice]);

  useEffect(() => {
    const { origin, destination } = data.searchRequest;

    if (typeof origin === 'string' || typeof destination === 'string') {
      setIsTerminalLocation(true);
      setShowTtTPrice(undefined);
    }
  }, [data.searchRequest]);

  useEffect(() => {
    if (!isTruckQuotePresented) {
      setIsPinTruckOnly(false);
      setIsTerminalLocation(true);
    }
  }, [isTruckQuotePresented]);

  const handleSorting = (value: SORT_MAP) => {
    setActiveSorting(value);
  };

  const sortItems = [
    {
      label: t('sorting.cheap'),
      value: SORT_MAP.PRICE,
    },
    {
      label: t('sorting.fast'),
      value: SORT_MAP.SPEED,
    },
    {
      label: t('sorting.emission'),
      value: SORT_MAP.CO2,
    },
  ];

  return (
    <>
      <div className={styles.sort}>
        <Switcher items={sortItems} active={activeSorting} onClick={handleSorting} />

        {isTruckQuotePresented && (
          <div className={styles.toggles}>
            <Button className={styles.filterButton} theme="neutral" onClick={() => setShowFilter(true)}>
              <FilterIcon className={styles.filterIcon} />
              <span>{t('filter')}</span>
            </Button>

            <div className={clsx(styles.content, { [styles.active]: showFilter })} ref={filterRef}>
              <div className={styles.toggleWrapper}>
                <Switch
                  bold
                  className={styles.switch}
                  label={t('pinTruckOnly')}
                  isActive={isPinTruckOnly}
                  onClick={() => setIsPinTruckOnly(!isPinTruckOnly)}
                  disabled={showTtTPrice}
                />
              </div>

              <div className={clsx(styles.toggleWrapper, styles.terminalSwitch)}>
                <Switch
                  bold
                  className={styles.switch}
                  disabled={isTerminalLocation}
                  label={t(showTtTPrice ? 'terminalToTerminal' : 'doorToDoor')}
                  isActive={Boolean(showTtTPrice)}
                  onClick={() => setShowTtTPrice(!showTtTPrice)}
                />
              </div>
            </div>
          </div>
        )}
      </div>
      {sortedQuotes.length ? (
        <>
          {sortedQuotes.map((quote: IQuote) => (
            <Quote key={quote.id} quote={quote} terminalToTerminal={showTtTPrice} />
          ))}
        </>
      ) : (
        <EmptyState />
      )}
    </>
  );
};
