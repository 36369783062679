import { captureException } from '@sentry/react';
import React, { useEffect } from 'react';
import { useAuth, hasAuthParams } from 'react-oidc-context';
import { Loader } from 'components/loader';
import { setAxiosInterceptors } from 'utils/hooks/axiosInterceptor';

type TProps = {
  children: React.ReactNode;
};

export const AuthWall: React.FC<TProps> = ({ children }) => {
  const auth = useAuth();
  const { error, signinRedirect, isAuthenticated, isLoading, user } = auth;

  useEffect(() => {
    if (!hasAuthParams() && !isAuthenticated && !isLoading) {
      signinRedirect();
    }
  }, [isAuthenticated, isLoading, signinRedirect]);

  useEffect(() => {
    if (auth.user?.access_token) {
      setAxiosInterceptors(auth, true);
    }
  }, [user?.access_token, auth]);

  if (error) {
    captureException(error);

    throw error;
  }

  if (isAuthenticated) {
    return <>{children}</>;
  }

  // isLoading and all other cases
  return <Loader />;
};
