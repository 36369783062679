import type { Booking, ShipmentException } from 'generated/api';

const LIMIT = 6;

type TExtractedExceptions = {
  booking: Booking;
} & ShipmentException;

export const extractExceptions = (bookings?: Booking[]): TExtractedExceptions[] => {
  let exceptions: TExtractedExceptions[] = [];

  if (!bookings?.length) {
    return [];
  }

  bookings.every((booking) => {
    if (exceptions.length >= LIMIT) {
      return false;
    }

    booking.exceptions?.every((exception) => {
      if (exceptions.length >= LIMIT) {
        return false;
      }

      exceptions.push({
        booking,
        ...exception,
      });

      return true;
    });

    return true;
  });

  return exceptions;
};
