import React, { CSSProperties } from 'react';
import DatePicker from 'react-datepicker';
import { CalendarIcon, CaretLeftIcon, CaretRightIcon, ClockIcon } from '@rouvia/icons';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import clsx from 'clsx';

import { TextFieldMeta } from 'components/textFieldMeta';
import { MONTHS } from 'constants/months';
import 'react-datepicker/dist/react-datepicker.css';
import { Colors } from 'scss/colors';
import { DATE_FORMAT, formatDate, formatJSDate, toJSDate } from 'utils/date';

import styles from './styles.module.scss';

type TProps = {
  value: string;
  onChange: (newValue: string) => void;
  timeOnly?: boolean;
  label?: string;
  disabled?: boolean;
  error?: string;
  hint?: string;
  minDate?: Date;
  placeholder?: string;
  metaPosition?: 'absolute' | 'relative';
  showRequired?: boolean;
  testId?: string;
  style?: CSSProperties;
  readonly?: boolean;
  showTimeSelect?: boolean;
};

const months: string[] = Object.values(MONTHS);
const currentYear = new Date().getFullYear();
const years = Array.from(Array(10).keys()).map((key) => String(currentYear + Number(key)));

const dropdownYearsOptions = years.map((year) => ({ value: year, label: year }));

const parseTime = (value: string) => {
  if (value.length >= 5) {
    const tempDate = new Date();

    tempDate.setHours(Number(value.slice(0, 2)));
    tempDate.setMinutes(Number(value.slice(3, 5)));

    return tempDate;
  }
};

export const CustomDatePicker: React.FC<TProps> = ({
  value,
  onChange,
  timeOnly,
  placeholder,
  label,
  disabled,
  minDate,
  error,
  hint,
  metaPosition = 'absolute',
  testId,
  showRequired,
  style,
  readonly,
  showTimeSelect,
  ...rest
}) => {
  const { t } = useTranslation('default', { keyPrefix: 'months' });

  const handleDateChange = (date: Date) => {
    if (timeOnly) {
      onChange(formatJSDate(date, DATE_FORMAT.TIME_SIMPLE));
    } else if (showTimeSelect) {
      onChange(formatJSDate(date, DATE_FORMAT.ISO));
    } else {
      onChange(formatJSDate(date, DATE_FORMAT.DATE_YEAR_DASHED));
    }
  };

  const dropdownMonthsOptions = months.map((month) => ({
    value: month,
    label: t(month as MONTHS),
  }));

  const getParsableValue = (valueStr: string) => {
    if (timeOnly) {
      return parseTime(valueStr);
    } else if (showTimeSelect) {
      return toJSDate(valueStr);
    } else {
      return new Date(String(valueStr));
    }
  };

  const getTimeInput = () => (
    <div className={clsx(styles.customInput, { [styles.readonly]: readonly })} data-testid={testId}>
      {value ? (
        formatDate(value, DATE_FORMAT.TIME_ONLY)
      ) : (
        <div className={styles.placeholder}>{placeholder || 'HH:mm'}</div>
      )}
      <ClockIcon style={{ color: Colors.black04 }} />
    </div>
  );

  const getDateInput = () => {
    let formatted;

    if (value) {
      if (showTimeSelect) {
        formatted = formatDate(value, DATE_FORMAT.DATE_YEAR_W_TIME);
      } else {
        formatted = formatDate(value, DATE_FORMAT.DATE_YEAR);
      }
    }

    return (
      <div className={clsx(styles.customInput, { [styles.readonly]: readonly })} data-testid={testId}>
        {formatted || <div className={styles.placeholder}>{placeholder || 'dd.mm.yyyy'}</div>}
        <CalendarIcon style={{ color: Colors.black04 }} />
      </div>
    );
  };

  return (
    <div
      className={clsx(styles.rootDatePicker, { [styles.disabled]: disabled, [styles.dateTime]: showTimeSelect })}
      style={style}
    >
      {label && (
        <div className={styles.label}>
          {label}
          {showRequired && <span className={styles.required}>*</span>}
        </div>
      )}
      <DatePicker
        {...(timeOnly
          ? {
              showTimeSelectOnly: true,
              timeIntervals: 30,
            }
          : {})}
        readOnly={readonly}
        showTimeSelect={timeOnly || showTimeSelect}
        timeFormat="HH:mm"
        dateFormat="dd/MM/yyyy"
        onChange={handleDateChange}
        minDate={minDate}
        selected={value ? getParsableValue(value) : null}
        disabled={disabled}
        className={styles.inputDate}
        formatWeekDay={(day: string) => day.slice(0, 3)}
        calendarStartDay={1}
        showMonthDropdown
        showYearDropdown
        customInput={timeOnly ? getTimeInput() : getDateInput()}
        renderCustomHeader={({
          date,
          changeYear,
          changeMonth,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
        }) => {
          const tempMonth = months[date.getMonth()];
          const tempYear = String(date.getFullYear());

          return (
            <div className={styles.datePickerHeader}>
              <div className={styles.datePickersWrapper}>
                <Select
                  className={clsx(styles.selectContainer, styles.selectMonth)}
                  classNamePrefix={styles.selectMonth}
                  value={dropdownMonthsOptions.find((option) => option.value === tempMonth)}
                  options={dropdownMonthsOptions}
                  onChange={(newValue) => {
                    if (newValue) {
                      changeMonth(months.indexOf(newValue.value));
                    }
                  }}
                />
                <Select
                  className={clsx(styles.selectContainer, styles.selectYear)}
                  classNamePrefix={styles.selectYear}
                  value={dropdownYearsOptions.find((option) => option.value === tempYear)}
                  options={dropdownYearsOptions}
                  onChange={(newValue) => {
                    if (newValue) {
                      changeYear(Number(newValue.value));
                    }
                  }}
                />
              </div>
              <div className={styles.buttonsGroup}>
                <button
                  type="button"
                  onClick={decreaseMonth}
                  disabled={prevMonthButtonDisabled}
                  data-testid="CustomDatePicker-prevMonth"
                >
                  <CaretLeftIcon />
                </button>
                <button
                  type="button"
                  onClick={increaseMonth}
                  disabled={nextMonthButtonDisabled}
                  data-testid="CustomDatePicker-nextMonth"
                >
                  <CaretRightIcon />
                </button>
              </div>
            </div>
          );
        }}
        {...rest}
      />
      {(error || hint) && (
        <div className={clsx(styles.meta, { [styles.metaRelative]: metaPosition === 'relative' })}>
          <TextFieldMeta error={error} hint={!error && hint} />
        </div>
      )}
    </div>
  );
};
