import { DateTime } from 'luxon';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller } from 'react-hook-form';
import type { Control } from 'react-hook-form';

import type { TLocationPoints } from 'constants/planning';
import { Quote as IQuote, RailServicesRequest } from 'generated/api';
import { ContentBox } from 'components/contentBox';
import { CustomSelect } from 'components/select';
import { shippingLinesOptions } from 'constants/shippingLine';
import { BooleanRadioButton } from 'components/booleanRadioButton';
import { Input } from 'components/input';
import { CustomDatePicker } from 'components/customDatePicker';
import { TLocationEmptyContainerPoints } from 'constants/planning';
import { Textarea } from 'components/textarea';
import { IForm } from '../../constants';
import styles from './styles.module.scss';

type TProps = {
  quote: IQuote;
  formControl: Control<IForm>;
  requestedServices: RailServicesRequest | null;
};

export const AdditionalQuoteInformation: React.FC<TProps> = ({ quote, formControl, requestedServices }) => {
  const [isOriginActive, setIsOriginActive] = useState<boolean>(false);
  const [isDestinationActive, setIsDestinationActive] = useState<boolean>(false);
  const earliestAvailableDate =
    quote.route.type === 'RAIL' ? new Date(quote.route.railLeg.earliestPickupTime) : new Date();

  const { t } = useTranslation('default', {
    keyPrefix: 'pages/quotes/components/additionalQuoteInformation',
  });
  const { t: commonT } = useTranslation('default', { keyPrefix: 'common' });
  const isTruckRoute = quote.route.type === 'TRUCK';

  const formEnabled = new Map<TLocationPoints | TLocationEmptyContainerPoints, boolean>([
    ['origin', 'type' in quote.origin && quote.origin.type === 'MARITIME'],
    ['destination', 'type' in quote.destination && quote.destination.type === 'MARITIME'],
    [
      'emptyContainerOrigin',
      Boolean(quote.route.emptyContainerPickupLeg) && (Boolean(requestedServices?.firstMile) || isTruckRoute),
    ],
    [
      'emptyContainerDestination',
      Boolean(quote.route.emptyContainerDropoffLeg) && (Boolean(requestedServices?.lastMile) || isTruckRoute),
    ],
  ]);

  // NOTE: this file cover PortInformation case
  // second case of this form (WarehouseInformation) is handled in singleQuotePage.tsx
  const getVesselForm = (type: TLocationPoints) => (
    <div className={styles.form}>
      <div className={styles.inputsRow}>
        <Controller
          name={`additionalInformation.${type}.vesselName`}
          control={formControl}
          shouldUnregister
          rules={{ required: commonT('validation.required') }}
          render={({ field, fieldState: { error: err } }) => (
            <Input
              showRequired
              className={styles.input}
              label={t('vesselName')}
              placeholder={t('vesselName')}
              error={err?.message}
              {...field}
            />
          )}
        />
        <Controller
          name={`additionalInformation.${type}.port`}
          shouldUnregister
          control={formControl}
          rules={{ required: commonT('validation.required') }}
          render={({ field, fieldState: { error: err } }) => (
            <Input
              showRequired
              className={styles.input}
              label={t(`${type}.portOf`)}
              placeholder={t(`${type}.portOf`)}
              error={err?.message}
              {...field}
            />
          )}
        />
      </div>
      <div className={styles.inputsRow}>
        <div className={styles.dateFlex}>
          <Controller
            name={`additionalInformation.${type}.dateTime`}
            rules={{
              required: commonT('validation.required'),
              validate:
                type === 'origin'
                  ? undefined
                  : (val: string) => {
                      if (quote.route.type !== 'RAIL') {
                        return true;
                      }

                      const selected = DateTime.fromISO(val);
                      const min = DateTime.fromISO(quote.route.railLeg.earliestPickupTime);

                      return selected > min || t(`${type}.dateError`);
                    },
            }}
            shouldUnregister
            control={formControl}
            render={({ field: { ref, ...restField }, fieldState: { error } }) => (
              <CustomDatePicker
                {...restField}
                label={t(`${type}.date`)}
                error={error?.message}
                minDate={type === 'origin' ? new Date() : earliestAvailableDate}
                showTimeSelect
                showRequired
              />
            )}
          />
        </div>
        <Controller
          name={`additionalInformation.${type}.shippingLine`}
          shouldUnregister
          control={formControl}
          rules={{ required: commonT('validation.required') }}
          render={({ field, fieldState: { error: err } }) => (
            <CustomSelect
              showRequired
              className={styles.input}
              label={t('shippingLine')}
              placeholder={t('shippingLine')}
              error={err?.message}
              options={shippingLinesOptions}
              {...field}
            />
          )}
        />
      </div>
    </div>
  );

  const getEmptyContainerForm = (type: TLocationEmptyContainerPoints) => (
    <div>
      <div className={styles.title}>{t(type)}</div>
      <div className={styles.inputsRow}>
        <Controller
          name={`additionalInformation.${type}.companyName`}
          control={formControl}
          shouldUnregister
          rules={{ required: commonT('validation.required') }}
          render={({ field, fieldState: { error: err } }) => (
            <Input
              showRequired
              className={styles.input}
              label={t('companyName')}
              placeholder={t('companyName')}
              error={err?.message}
              {...field}
            />
          )}
        />
        <Controller
          name={`additionalInformation.${type}.shippingLine`}
          control={formControl}
          shouldUnregister
          rules={{ required: commonT('validation.required') }}
          render={({ field, fieldState: { error: err } }) => (
            <CustomSelect
              showRequired
              className={styles.input}
              label={t('shippingLine')}
              placeholder={t('shippingLine')}
              error={err?.message}
              options={shippingLinesOptions}
              {...field}
            />
          )}
        />
      </div>
      <div className={styles.inputsRow}>
        <div className={styles.dateFlex}>
          <Controller
            name={`additionalInformation.${type}.workingHoursOpens`}
            control={formControl}
            shouldUnregister
            rules={{ required: commonT('validation.required') }}
            render={({ field: { ref, ...restField }, fieldState: { error } }) => (
              <CustomDatePicker
                {...restField}
                showRequired
                timeOnly={true}
                label={t('workingHours')}
                error={error?.message}
                placeholder={t('opens')}
              />
            )}
          />
          <Controller
            name={`additionalInformation.${type}.workingHoursCloses`}
            control={formControl}
            shouldUnregister
            rules={{ required: commonT('validation.required') }}
            render={({ field: { ref, ...restField }, fieldState: { error } }) => (
              <CustomDatePicker
                {...restField}
                showRequired
                timeOnly={true}
                error={error?.message}
                placeholder={t('closes')}
              />
            )}
          />
        </div>
        <Controller
          name={`additionalInformation.${type}.reference`}
          shouldUnregister
          control={formControl}
          rules={{ required: commonT('validation.required') }}
          render={({ field, fieldState: { error: err } }) => (
            <Input
              showRequired
              className={styles.input}
              label={t('reference')}
              placeholder={t('reference')}
              error={err?.message}
              {...field}
            />
          )}
        />
      </div>
    </div>
  );

  return (
    <ContentBox title={t('additionalInfo')} contentClassName={styles.additionalQuoteInformationRoot}>
      {formEnabled.get('origin') && (
        <div className={styles.item}>
          <BooleanRadioButton
            testId="AdditionalQuoteInformationOrigin"
            label={t('containerArriveVessel')}
            isActive={isOriginActive}
            setIsActive={setIsOriginActive}
          />
          {isOriginActive && getVesselForm('origin')}
        </div>
      )}
      {formEnabled.get('destination') && (
        <div className={styles.item}>
          <BooleanRadioButton
            label={t('containerDepartVessel')}
            isActive={isDestinationActive}
            setIsActive={setIsDestinationActive}
          />
          {isDestinationActive && getVesselForm('destination')}
        </div>
      )}
      {formEnabled.get('emptyContainerOrigin') && (
        <div className={styles.item}>{getEmptyContainerForm('emptyContainerOrigin')}</div>
      )}
      {formEnabled.get('emptyContainerDestination') && (
        <div className={styles.item}>{getEmptyContainerForm('emptyContainerDestination')}</div>
      )}

      <div className={styles.item}>
        <Controller
          name="additionalInformation.additionalNotes"
          control={formControl}
          shouldUnregister
          render={({ field, fieldState: { error: err } }) => (
            <Textarea
              className={styles.input}
              label={t('textAreaLabel')}
              placeholder={t('textAreaPlaceholder')}
              style={{ height: 80 }}
              error={err?.message}
              {...field}
            />
          )}
        />
      </div>
    </ContentBox>
  );
};
