import clsx from 'clsx';
import React from 'react';
import { SortDownIcon, UnfoldSmallIcon } from '@rouvia/icons';
import styles from 'components/dynamicTable/styles.module.scss';

export const getSortIcon = (isSortable: boolean, type: string | boolean) => {
  if (!isSortable) {
    return null;
  }

  if (type === 'asc') {
    return <SortDownIcon className={clsx(styles.asc, styles.icon)} data-testid="arrowIconAsc" />;
  }

  if (type === 'desc') {
    return <SortDownIcon className={styles.icon} data-testid="arrowIconDesc" />;
  }

  return <UnfoldSmallIcon className={styles.icon} data-testid="faSortIcon" />;
};
