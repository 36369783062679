import clsx from 'clsx';
import React, { useCallback, useEffect, useState } from 'react';
import { BinSimpleIcon } from '@rouvia/icons';
import styles from './styles.module.scss';

type TProps = {
  className?: string;
  tags?: string[];
  setTags: (tags: string[]) => void;
  validationFn?: (value: string) => boolean;
};

export const TagInput: React.FC<TProps> = ({ className, tags = [], setTags, validationFn }) => {
  const [inputValue, setInputValue] = useState('');
  const [isAttemptedToDelete, setAttemptedToDelete] = useState(false);

  const deleteTag = (deletionIndex: number) => {
    setTags([...tags.filter((_, index) => index !== deletionIndex)]);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const handleValue = useCallback(
    (value: string) => {
      let isValidValue: boolean = true;
      const isUnique = !tags?.includes(value);

      if (validationFn) {
        isValidValue = validationFn(value);
      }

      if (isValidValue && isUnique) {
        setTags([...tags, value]);
      }

      setInputValue('');
    },
    [setTags, tags, validationFn],
  );

  const handleBlur = () => {
    handleValue(inputValue);
  };

  const handleKeyEvent = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Backspace' && !inputValue.length && tags?.length > 0) {
      if (isAttemptedToDelete) {
        deleteTag(tags.length - 1);
        setAttemptedToDelete(false);
      } else {
        setAttemptedToDelete(true);
      }
    } else if (e.key === 'Enter') {
      handleValue(inputValue);
    }
  };

  useEffect(() => {
    if (!inputValue) {
      return;
    }

    if (inputValue.endsWith(',') || inputValue.endsWith(' ')) {
      handleValue(inputValue.replace(',', '').trim());
    }

    if (isAttemptedToDelete) {
      setAttemptedToDelete(false);
    }
  }, [handleValue, inputValue, isAttemptedToDelete, setTags, tags, validationFn]);

  return (
    <div data-testid="TagInputRoot" className={clsx(styles.tagInputRoot, className)}>
      <div className={styles.tags}>
        {tags.map((tag, index) => (
          <div key={tag} className={styles.tag}>
            {tag}
            <BinSimpleIcon data-testid={`TagInput${index}`} className={styles.close} onClick={() => deleteTag(index)} />
          </div>
        ))}

        <input
          onBlur={handleBlur}
          value={inputValue}
          type="text"
          className={styles.input}
          onChange={handleInputChange}
          onKeyUp={handleKeyEvent}
          data-testid="TagInput"
        />
      </div>
    </div>
  );
};
