import { captureException } from '@sentry/react';
import type { AuthContextProps } from 'react-oidc-context';
import type { AxiosError, AxiosResponse, InternalAxiosRequestConfig } from 'axios';

import { localAxiosInstance } from 'api/request';
import { queryClient } from 'constants/api';
import { devConsoleWarn, devConsoleError } from 'utils/console';

export const setAxiosInterceptors = (auth: AuthContextProps, reset?: boolean) => {
  const { user, signoutRedirect } = auth;

  if (reset) {
    localAxiosInstance.interceptors.request.clear();
    localAxiosInstance.interceptors.response.clear();
  }

  localAxiosInstance.interceptors.request.use(
    (originalConfig: InternalAxiosRequestConfig<any>) => {
      const config = { ...originalConfig };

      if (user?.access_token) {
        config.headers.Authorization = `Bearer ${user.access_token}`;
      } else {
        devConsoleError('Axios request interceptor did not received token');
      }

      return config;
    },
    (error) => Promise.reject(error),
  );

  localAxiosInstance.interceptors.response.use(
    (res: AxiosResponse) => res,
    (error: AxiosError) => {
      if (error?.response?.status) {
        switch (error.response.status) {
          case 401:
            if (user?.expired) {
              queryClient.clear();

              signoutRedirect();
            } else {
              captureException(error);
              devConsoleWarn('API responded with 401 but token has not expired');
            }

            throw error;

          case 502:
          case 503:
          case 504:
            captureException(error);
            throw error;

          default:
            throw error;
        }
      } else {
        devConsoleWarn('API responded with error without error.response.status');
        throw error;
      }
    },
  );
};
