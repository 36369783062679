import clsx from 'clsx';
import React from 'react';
import { round } from 'utils/misc';
import styles from './item.module.scss';

type TProps = {
  price?: string | number;
  description: string;
  rail?: boolean;
};

export const Item = ({ price, description, rail }: TProps) => (
  <div className={clsx(styles.root, { [styles.rail]: rail })} data-testid="SummaryItemRoot">
    <div className={styles.node} data-testid="SummaryItemDescription">
      {description}
    </div>
    <div className={styles.dashes} />
    <div className={styles.node}>
      {price ? (
        <>
          {'€ '}
          {round(price)}
        </>
      ) : (
        <span className={styles.noPrice}>requested</span>
      )}
    </div>
  </div>
);
