import { Booking, ShipmentEventName } from 'generated/api';
import styles from 'components/progressTrack/styles.module.scss';

const getColorBasedOnProgress = (fallback: string, booking?: Booking) => {
  let color = fallback;

  if (!booking) {
    return color;
  }

  if (booking.isLate) {
    color = styles.YELLOW;
  } else if (booking.isException) {
    color = styles.RED;
  }

  return color;
};

export type TProgress = {
  colorProgress: string;
  bulletActive: boolean;
  lineInProgress: boolean;
  bulletColor?: string;
};

export type SharedConfigsType = {
  isProgressMarked: boolean;
  firstScheduled: boolean;
  reverseBulletColor: string | null;
  reverseBulletActive: boolean;
  isProgressEnabled: boolean;
};

export type ConfigType = {
  prev: TProgress | null;
  options: {
    isReverseEnabled?: boolean;
    isProgressEnabled: boolean;
    isFirstPoint?: boolean;
  };
  shared: {
    isProgressMarked: boolean;
  };
};
export const getCalculatedSegmentProgress = (
  defaultColor: string,
  associatedEvents: ShipmentEventName[],
  config: ConfigType,
  currentEvent: ShipmentEventName | 'ALL_SCHEDULED' | null,
  booking: Booking | null,
): TProgress => {
  const calculateBulletReverse =
    config.options.isReverseEnabled && config.options.isProgressEnabled
      ? {
          bulletActive: Boolean(config.prev?.lineInProgress),
          bulletColor: config.prev?.colorProgress || styles.GRAY,
        }
      : {};

  if (!config.options.isProgressEnabled || currentEvent === null || booking === null) {
    return {
      colorProgress: defaultColor,
      bulletActive: false,
      lineInProgress: false,
      ...calculateBulletReverse,
    };
  }

  if (currentEvent === 'ALL_SCHEDULED') {
    return {
      colorProgress: styles.GRAY,
      bulletActive: Boolean(config.options.isFirstPoint),
      bulletColor: config.options.isFirstPoint ? defaultColor : styles.GRAY,
      lineInProgress: false,
      ...calculateBulletReverse,
    };
  }

  const calculatedColor = getColorBasedOnProgress(defaultColor, booking);

  if (config.shared.isProgressMarked) {
    return {
      colorProgress: calculatedColor,
      bulletActive: false,
      lineInProgress: false,
      ...calculateBulletReverse,
    };
  }

  const foundInProgress = currentEvent ? associatedEvents?.find((e) => e === currentEvent) : null;

  if (foundInProgress) {
    config.shared.isProgressMarked = true;

    return {
      colorProgress: calculatedColor,
      bulletActive: true,
      lineInProgress: true,
      ...calculateBulletReverse,
    };
  }

  return {
    colorProgress: styles.GRAY,
    bulletActive: false,
    lineInProgress: false,
    ...calculateBulletReverse,
  };
};
