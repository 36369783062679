import clsx from 'clsx';
import React, { useRef } from 'react';
import { CheckSmallIcon } from '@rouvia/icons';
import { useOnClickOutside } from 'utils/hooks/onClickOutside';
import styles from './styles.module.scss';

type TProps = {
  isOpen: boolean;
  items: {
    label: string;
    value: string;
  }[];
  selectedItem?: string;
  onClickHandler: (value: string) => void;
  contextClassName?: string;
  closeMenu: () => void;
  children?: React.ReactNode;
};

export const ContextMenu: React.FC<TProps> = ({
  isOpen = true,
  items,
  selectedItem,
  onClickHandler,
  contextClassName,
  closeMenu,
  children,
}) => {
  const menuRef = useRef(null);

  useOnClickOutside(menuRef, () => closeMenu());

  const handleOptionClick = (value: string) => {
    onClickHandler(value);
    closeMenu();
  };

  return (
    <div className={styles.contextMenuRoot}>
      {children}

      {isOpen && (
        <div data-testid="ContextMenu" className={clsx(styles.context, contextClassName)} ref={menuRef}>
          {items.map((item, index) => (
            <div
              key={item.value}
              className={styles.item}
              data-testid={`ContextMenu${index}`}
              onClick={() => handleOptionClick(item.value)}
            >
              {item.label} {item.value === selectedItem && <CheckSmallIcon className={styles.icon} />}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
