import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import { api } from 'api/request';
import {
  Booking,
  PaginatedResponseBooking,
  BookingCreationRequest,
  BookingAssignmentRequest,
  BookingCustomerDetailsInformationUpdateRequest,
  BookingStatus,
} from 'generated/api';

export const bookingsQueryKey = 'bookings';
export const bookingQueryKey = 'booking';

export type QueryBookings = {
  hasOpenTasks?: boolean;
  status?: BookingStatus[];
  page?: number;
  perPage?: number;
  hasExceptions?: boolean;
};

const getBookings = async (query?: QueryBookings): Promise<PaginatedResponseBooking> => {
  const { data } = await api.getBookings(query);

  return data;
};

export const useGetBookings = (query?: QueryBookings) => useQuery([bookingsQueryKey, query], () => getBookings(query));

export const useGetBookingsInfinite = (initialQuery: { query?: QueryBookings; key: string }) => {
  const defaultPerPage = 10;

  return useInfiniteQuery(
    [initialQuery.key],
    ({ pageParam }) => getBookings({ perPage: defaultPerPage, ...initialQuery.query, ...pageParam }),
    {
      getNextPageParam: (lastPage: PaginatedResponseBooking) => {
        if (!lastPage.pagination?.nextPage) {
          return undefined;
        }

        return { ...lastPage.pagination, page: lastPage.pagination.page + 1 };
      },
    },
  );
};

const getBooking = async (id?: string): Promise<Booking> => {
  const { data } = await api.getBooking(id || '');

  return data;
};

export const useGetBooking = (id?: string) => useQuery([bookingQueryKey, id], () => getBooking(id));

export const createBooking = async (requestData: BookingCreationRequest): Promise<Booking> => {
  const { data } = await api.createBooking(requestData);

  return data;
};

export const assignUsersToBooking = async (bookingId: string, assignes: BookingAssignmentRequest): Promise<void> => {
  await api.assignBookingToUser(bookingId, assignes);
};

export const updateBookingCustomerInfo = async (
  bookingId: string,
  body: BookingCustomerDetailsInformationUpdateRequest,
): Promise<Booking> => {
  const { data } = await api.updateBookingCustomerInfo(bookingId, body);

  return data;
};

export const cancelBooking = async (bookingId: string): Promise<void> => {
  const { data } = await api.cancelBooking(bookingId);

  return data;
};
