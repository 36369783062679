import React from 'react';
import { useGetBookings } from 'api/bookings';
import { useDashboardData } from 'api/dashboard';
import { ExceptionsListWidget } from 'components/exceptionsListWidget';
import { OpenTasksWidget } from 'components/openTasksWidget';
import { Layout } from 'components/layout';
import { Loader } from 'components/loader';
import { Map } from 'components/map';
import { SummaryWidget } from 'components/summaryWidget';
import { ShipmentListWidget } from 'components/shipmentListWidget';
import { BookingsListWidget } from 'components/bookingsListWidget';
import { bookingsInTransportationQuery } from './constants';
import styles from './styles.module.scss';

export const DashboardPage = () => {
  const { data: bookings, isLoading: isBookingsLoading } = useGetBookings(bookingsInTransportationQuery);
  const { data: stats, isLoading: isStatsLoading } = useDashboardData();

  return (
    <Layout>
      <SummaryWidget isLoading={isStatsLoading} stats={stats} />

      <div className={styles.gridDouble}>
        <BookingsListWidget />
        <>
          {isBookingsLoading || !bookings?.data ? (
            <div className={styles.map}>
              <Loader />
            </div>
          ) : (
            <Map mapConfig={{ zoom: 5 }} bookings={bookings?.data} className={styles.map} />
          )}
        </>
      </div>
      <div className={styles.gridTriple}>
        <ShipmentListWidget isLoading={isBookingsLoading} bookings={bookings?.data} />
        <OpenTasksWidget />
        <ExceptionsListWidget className={styles.lastCell} />
      </div>
    </Layout>
  );
};
