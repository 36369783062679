import React, { useState } from 'react';
import clsx from 'clsx';
import type { FunctionComponent } from 'react';
import { Icon } from 'components/icon';
import styles from './styles.module.scss';

type TProps = {
  disabled?: boolean;
  disabledTooltipText?: string;
  onClick?: () => void;
  theme?: 'accent' | 'neutral';
  size?: 'large' | 'medium';
  underline?: 'solid' | 'dashed' | 'none';
  iconOnly?: boolean;
  alignIconRight?: boolean;
  className?: string;
  icon?: typeof React.Component | FunctionComponent;
  children?: React.ReactNode;
  testId?: string;
};

export const Link: React.FC<TProps> = ({
  onClick,
  theme = 'neutral',
  size = 'medium',
  disabled,
  disabledTooltipText,
  iconOnly,
  alignIconRight,
  icon,
  className,
  underline = 'solid',
  children,
  testId = 'linkRoot',
}) => {
  const [isHovered, setIsHovered] = useState<boolean>(false);

  const handleClick = () => {
    if (!disabled && onClick) {
      onClick();
    }
  };

  const onMouseEnter = () => {
    if (disabled) {
      setIsHovered(true);
    }
  };

  const onMouseLeave = () => {
    if (disabled) {
      setIsHovered(false);
    }
  };

  return (
    <span
      data-testid={testId}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      className={clsx(
        styles.linkRoot,
        styles[theme],
        styles[size],
        styles[underline],
        { [styles.disabled]: disabled, [styles.reverse]: alignIconRight },
        className,
      )}
      onClick={handleClick}
    >
      {isHovered && disabledTooltipText && <div className={styles.textOnDisabled}>{disabledTooltipText}</div>}
      {icon && <Icon As={icon} size={size} />}

      {!iconOnly && <div className={styles.text}>{children}</div>}
    </span>
  );
};
