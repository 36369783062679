import React from 'react';
import { useTranslation } from 'react-i18next';
import { Layout } from 'components/layout';
import { SCREEN_SIZE } from 'constants/screenSize';
import { CookiePreferences } from './components/cookiePreferences';
import { PersonalInfo } from './components/personalInfo';
import { ChangePassword } from './components/changePassword';
import { NotificationsSetting } from './components/notificationsSetting';
import styles from './styles.module.scss';

export const AccountPage = () => {
  const { t } = useTranslation('default', { keyPrefix: 'pages/accounts' });

  return (
    <Layout title={t('userProfile')} contentMaxWidth={SCREEN_SIZE.MD}>
      <div className={styles.container}>
        <PersonalInfo />
        <NotificationsSetting />
        <ChangePassword />
        <CookiePreferences />
      </div>
    </Layout>
  );
};
