/* eslint-disable react/display-name */
import React from 'react';
import clsx from 'clsx';
import Select, { components as reactSelectComponents } from 'react-select';
import AsyncSelect from 'react-select/async';
import { SelectComponents } from 'react-select/dist/declarations/src/components';
import { AsyncAdditionalProps } from 'react-select/dist/declarations/src/useAsync';
import { StateManagerProps } from 'react-select/dist/declarations/src/useStateManager';
import type { ControlProps, ContainerProps } from 'react-select';
import type { FunctionComponent } from 'react';

import { Icon } from 'components/icon';
import { TextFieldMeta } from 'components/textFieldMeta';
import { Tooltip } from 'components/tooltip';
import styles from './styles.module.scss';

type TProps = {
  icon?: FunctionComponent;
  iconClassName?: string;
  async?: boolean;
  error?: string;
  hint?: string;
  label?: string;
  tooltip?: string;
  isDisabled?: boolean;
  showRequired?: boolean;
  customTheme?: {
    width?: string;
    maxWidth?: string;
    minWidth?: string;
  };
  testId?: string;
} & Partial<StateManagerProps> &
  Partial<AsyncAdditionalProps<any, any>>;

const indicator =
  (icon: FunctionComponent, iconClassName?: string) =>
  ({ children, ...props }: ControlProps) =>
    (
      <reactSelectComponents.Control {...props}>
        <Icon className={clsx(styles.icon, iconClassName)} As={icon} /> {children}
      </reactSelectComponents.Control>
    );

const customContainer =
  (testId: string) =>
  ({ children, ...props }: ContainerProps) =>
    (
      <reactSelectComponents.SelectContainer {...props}>
        <div data-testid={testId}>{children}</div>
      </reactSelectComponents.SelectContainer>
    );

export const CustomSelect = React.forwardRef<any, TProps>(
  (
    {
      icon,
      iconClassName,
      async,
      hint,
      error,
      label,
      tooltip,
      customTheme,
      className,
      isMulti,
      showRequired,
      isDisabled = false,
      testId,
      ...rest
    },
    ref,
  ) => {
    const components: Partial<SelectComponents<any, any, any>> = {
      IndicatorSeparator: null,
    };

    if (icon) {
      components.Control = indicator(icon, iconClassName);
    }

    if (testId) {
      components.SelectContainer = customContainer(testId);
    }

    const Component = async ? AsyncSelect : Select;

    return (
      <div
        className={clsx(styles.customSelectRoot, className)}
        style={{
          width: customTheme?.width,
          minWidth: customTheme?.minWidth,
          maxWidth: customTheme?.maxWidth,
        }}
      >
        <div className={styles.labelWrapper}>
          {label && (
            <div className={styles.label} data-testid="SelectLabel">
              {label}
              {showRequired && <span className={styles.required}>*</span>}
            </div>
          )}
          {tooltip && (
            <div className={styles.tooltip}>
              <Tooltip text={tooltip} position="right" />
            </div>
          )}
        </div>

        <Component
          ref={ref}
          className={clsx(styles.selectContainer, {
            [styles.multi]: isMulti,
            [styles.error]: error,
            [styles.withIcon]: Boolean(icon),
            [styles.disabled]: isDisabled,
          })}
          classNamePrefix={styles.selectContainer}
          components={components}
          styles={{
            control: (defaultStyles) => ({
              ...defaultStyles,
            }),
          }}
          isMulti={isMulti}
          isDisabled={isDisabled}
          isOptionDisabled={(option) => option.disabled}
          {...rest}
          data-testid="SelectRoot"
        />
        {(error || hint) && (
          <div className={styles.meta} data-testid="SelectMeta">
            <TextFieldMeta error={error} hint={hint} />
          </div>
        )}
      </div>
    );
  },
);

CustomSelect.displayName = 'CustomSelect';
