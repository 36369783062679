import React, { useEffect, useState, useMemo } from 'react';
import { Controller, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Control, UseFormSetValue } from 'react-hook-form/dist/types';

import { Input } from 'components/input';
import { CustomSelect } from 'components/select';
import { EquipmentType } from 'generated/api';
import {
  equipment,
  groupedLoadingUnits,
  sizes,
  unitProfileTable,
  containerSizes,
  containerUnits,
} from 'constants/equipments';
import { TDefaultSelectOption } from 'types/common';
import { ISearchForm } from 'constants/planning';

import styles from './styles.module.scss';

type TProps = {
  control: Control<ISearchForm>;
  index: number;
  setValue: UseFormSetValue<ISearchForm>;
  isEquipmentPrefilled?: boolean;
};

export const ShipmentInfoForm: React.FC<TProps> = ({ setValue, control, index, isEquipmentPrefilled = false }) => {
  const { t } = useTranslation('default', {
    keyPrefix: 'pages/planning/components/shipmentForm',
  });
  const { t: commonT } = useTranslation('default', { keyPrefix: 'common' });

  const watch = useWatch({
    name: 'shipmentInformation',
    control,
  });

  const [loadUnitOptions, setLoadUnitOptions] = useState<TDefaultSelectOption<string>[]>();
  const [unitProfilesOptions, setUnitProfilesOptions] = useState<TDefaultSelectOption<string>[]>();
  const [equipmentState, setEquipmentState] = useState<EquipmentType>();
  const watchEquipment = watch[index].equipment?.value;
  const watchSize = watch[index].size?.value;
  const watchLoadingUnit = watch[index].loadingUnit?.value;
  const isContainer = useMemo(() => equipmentState === EquipmentType.CONTAINER, [equipmentState]);

  useEffect(() => {
    if (equipmentState && watchEquipment && watchEquipment !== equipmentState) {
      setEquipmentState(watchEquipment);
      setLoadUnitOptions(groupedLoadingUnits[watchEquipment]);
      setUnitProfilesOptions(unitProfileTable[watchEquipment]);

      setValue(`shipmentInformation.${index}.loadingUnit`, null);
      setValue(`shipmentInformation.${index}.size`, null);
      setValue(`shipmentInformation.${index}.unitProfile`, null);
    }

    if (watchEquipment === EquipmentType.CONTAINER && watchSize) {
      setLoadUnitOptions(containerUnits[watchSize]);
    }
  }, [setValue, equipmentState, index, watchEquipment, watchSize]);

  useEffect(() => {
    if (watchEquipment && !equipmentState) {
      setEquipmentState(watchEquipment);
      setLoadUnitOptions(groupedLoadingUnits[watchEquipment]);
      setUnitProfilesOptions(unitProfileTable[watchEquipment]);
    }
  }, [watchEquipment, equipmentState]);

  useEffect(() => {
    if (!watchLoadingUnit || !loadUnitOptions) {
      return;
    }

    const isUnitExist = loadUnitOptions?.some((unit) => unit.value === watchLoadingUnit);

    if (!isUnitExist) {
      setValue(`shipmentInformation.${index}.loadingUnit`, null);
    }
  }, [watchLoadingUnit, setValue, loadUnitOptions, index]);

  return (
    <>
      <div className={styles.row}>
        <Controller
          name={`shipmentInformation.${index}.equipment`}
          control={control}
          rules={{ required: commonT('validation.required') }}
          render={({ field, fieldState: { error } }) => (
            <CustomSelect
              showRequired
              testId="shipmentEquipment"
              label={t('form.fields.equipment')}
              tooltip={t('tooltip')}
              placeholder={t('form.fields.equipment')}
              options={equipment}
              error={error?.message}
              isDisabled={isEquipmentPrefilled}
              customTheme={{
                maxWidth: '175px',
              }}
              {...field}
            />
          )}
        />

        {isContainer && (
          <Controller
            name={`shipmentInformation.${index}.size`}
            control={control}
            rules={{ required: commonT('validation.required') }}
            render={({ field, fieldState: { error } }) => (
              <CustomSelect
                showRequired
                testId="shipmentEquipmentSize"
                label={t('form.fields.size')}
                placeholder={t('form.fields.size')}
                options={containerSizes}
                error={error?.message}
                customTheme={{
                  maxWidth: '210px',
                }}
                {...field}
              />
            )}
          />
        )}

        <Controller
          name={`shipmentInformation.${index}.loadingUnit` as `shipmentInformation.${number}.loadingUnit`}
          control={control}
          rules={{
            validate: (option) => {
              const isValid = loadUnitOptions?.some((unit) => unit.value === option?.value);

              return isValid || commonT('validation.required');
            },
          }}
          render={({ field, fieldState: { error } }) => (
            <CustomSelect
              showRequired
              testId="shipmentEquipmentLoadingUnit"
              label={t('form.fields.loadingUnit')}
              placeholder={t('form.fields.loadingUnit')}
              error={error?.message}
              options={loadUnitOptions}
              customTheme={{
                maxWidth: '400px',
              }}
              {...field}
            />
          )}
        />

        {unitProfilesOptions?.length && (
          <Controller
            name={`shipmentInformation.${index}.unitProfile` as `shipmentInformation.${number}.unitProfile`}
            control={control}
            rules={{
              validate: (val) => {
                const isValid = Boolean(unitProfilesOptions?.length && val);

                return isValid || commonT('validation.required');
              },
            }}
            render={({ field, fieldState: { error } }) => (
              <CustomSelect
                showRequired
                customTheme={{
                  maxWidth: '120px',
                }}
                error={error?.message}
                label={t('form.fields.unitProfile')}
                placeholder={t('form.fields.unitProfile')}
                options={unitProfilesOptions}
                {...field}
              />
            )}
          />
        )}
      </div>

      <div className={styles.row}>
        {!isContainer && (
          <Controller
            name={`shipmentInformation.${index}.size`}
            control={control}
            rules={{
              validate: (option) => {
                const isValid = sizes?.some((unit) => unit.value === option?.value);

                return isValid || commonT('validation.required');
              },
            }}
            render={({ field, fieldState: { error } }) => (
              <CustomSelect
                showRequired
                label={t('form.fields.size')}
                placeholder={t('form.fields.size')}
                options={sizes}
                error={error?.message}
                customTheme={{
                  maxWidth: '210px',
                }}
                {...field}
              />
            )}
          />
        )}

        <Controller
          name={`shipmentInformation.${index}.netWeight`}
          control={control}
          rules={{
            validate: (value) => value >= 0 || commonT('validation.required'),
          }}
          render={({ field, fieldState: { error } }) => (
            <Input
              showRequired
              testId="shipmentEquipmentNetWeight"
              className={styles.numberInput}
              type="number"
              required
              label={t('form.fields.netWeight')}
              error={error?.message}
              min={0}
              {...field}
            />
          )}
        />

        <Controller
          name={`shipmentInformation.${index}.tareWeight`}
          control={control}
          rules={{
            validate: (value) => value > 0 || commonT('validation.required'),
          }}
          render={({ field, fieldState: { error } }) => (
            <Input
              showRequired
              testId="shipmentEquipmentTareWeight"
              min={0}
              required
              type="number"
              label={t('form.fields.tareWeight')}
              error={error?.message}
              className={styles.numberInput}
              {...field}
            />
          )}
        />

        <Controller
          name={`shipmentInformation.${index}.amount`}
          control={control}
          rules={{
            required: commonT('validation.required'),
            min: {
              value: 1,
              message: t('form.fields.amountErrorMsg'),
            },
          }}
          render={({ field, fieldState: { error } }) => (
            <Input
              showRequired
              testId="shipmentEquipmentAmount"
              min={1}
              required
              type="number"
              label={t('form.fields.amount')}
              className={styles.numberInput}
              error={error?.message}
              {...field}
            />
          )}
        />
      </div>
    </>
  );
};
