import { useQuery } from '@tanstack/react-query';
import { captureMessage } from '@sentry/react';
import { api } from 'api/request';
import { EmptyContainerDepot, Terminal } from 'generated/api';

export const terminalsQueryKey = 'terminals';
export const terminalQueryKey = 'terminal';
export const depotsQueryKey = 'depots';

const getTerminals = async (): Promise<Terminal[]> => {
  const { data } = await api.getAllTerminals();

  if (!Array.isArray(data)) {
    captureMessage(`Api request getAllTerminals did not return an array => ${JSON.stringify(data)}`);

    return [];
  }

  return data;
};

const getTerminalById = async (id: string): Promise<Terminal> => {
  const { data } = await api.getTerminalById(id);

  return data;
};

const getDepots = async (): Promise<EmptyContainerDepot[]> => {
  const { data } = await api.getAllEmptyContainerDepotAddresses();

  if (!Array.isArray(data)) {
    captureMessage(`Api request getAllEmptyContainerDepotAddresses did not return an array => ${JSON.stringify(data)}`);

    return [];
  }

  return data;
};

export const useGetTerminals = () => useQuery([terminalsQueryKey], () => getTerminals());
export const useGetTerminal = (id: string) => useQuery([terminalQueryKey, id], () => getTerminalById(id));

export const useGetDepots = () => useQuery([depotsQueryKey], () => getDepots());
