import React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { FeedEvent, FeedMessage, Notification } from 'generated/api';
import { ReactComponent as EventIcon } from 'assets/images/icons/event.svg';
import { ReactComponent as SupportIcon } from 'assets/images/icons/default/support.svg';
import { UserAvatar } from 'components/userAvatar';
import { formatDate, DATE_FORMAT } from 'utils/date';
import { markNotificationAsRead, NOTIFICATION_QUERY_KEY } from 'api/notifications';
import { queryClient } from 'constants/api';
import { getAuthorName } from 'utils/misc';
import { getNotificationEventLabel } from 'utils/notification';
import { BOOKING_TAB } from 'pages/booking/utils';
import styles from './style.module.scss';

type TProps = {
  notification: Notification;
  onClose?: () => void;
};

export const NotificationItem: React.FC<TProps> = ({ notification, onClose }) => {
  const { t } = useTranslation('default', {
    keyPrefix: 'components/notificationsWidget',
  });

  const navigate = useNavigate();

  const { mutate: markAsRead } = useMutation(() => markNotificationAsRead(notification.content.data.bookingId), {
    onSettled: () => {
      queryClient.invalidateQueries([NOTIFICATION_QUERY_KEY.NOTIFICATION_PAGE]);
      queryClient.invalidateQueries([NOTIFICATION_QUERY_KEY.NOTIFICATION_WIDGET]);
    },
  });

  const handleClickNotification = () => {
    markAsRead();
    navigate(`/bookings/${notification.content.data.bookingId}/${BOOKING_TAB.ACTIVITY}`);
    onClose?.();
  };

  const getNotificationMessageType = (data: FeedMessage) => (
    <div className={styles.content}>
      <div>
        {`${t('newMessage')} `}
        <span className={styles.bold}>{getAuthorName(data.author)}</span>
      </div>
    </div>
  );

  const getNotificationEventType = (data: FeedEvent) => {
    let title;
    let content;
    let optionalContent;

    if (data.content.type === 'BOOKING_STATUS_HAS_CHANGED') {
      title = <div className={styles.bold}>{t('newBookingNotification')}</div>;
      content = getNotificationEventLabel(data.content.newStatus);
    } else if (data.content.type === 'SHIPMENT_EVENT_HAS_CHANGED') {
      title = <div className={styles.bold}>{t('newShipmentStageNotification')}</div>;
      content = data.content.shipmentEventName ? getNotificationEventLabel(data.content.shipmentEventName) : '';

      const eta = data.content.newEta;
      const ata = data.content.newAta;

      optionalContent = (eta || ata) && (
        <div className={styles.eta}>
          {eta && (
            <div>
              {t('eta')} {formatDate(eta, DATE_FORMAT.COMPLETE)}
            </div>
          )}
          {ata && (
            <div>
              {t('ata')} {formatDate(ata, DATE_FORMAT.COMPLETE)}
            </div>
          )}
        </div>
      );
    } else if (data.content.type === 'BOOKING_WAS_UPDATED') {
      title = <div className={styles.bold}>{t('newBookingEditedNotification')}</div>;
    }

    return (
      <div className={styles.content}>
        {title}
        {content}
        {optionalContent}
      </div>
    );
  };

  const getContent = () => {
    const { data } = notification.content;

    if (data.type === 'MESSAGE') {
      return getNotificationMessageType(data);
    } else if (data.type === 'EVENT') {
      return getNotificationEventType(data);
    }
  };

  const getAvatar = () => {
    const { data } = notification.content;

    if (data.type === 'MESSAGE') {
      if (data.author.type === 'USER') {
        return <UserAvatar user={data.author} size="medium" testId="NotificationItemIconUser" />;
      } else if (data.author.type === 'OPS_TEAM') {
        return <UserAvatar Placeholder={SupportIcon} size="medium" testId="NotificationItemIconUser" />;
      } else {
        return <UserAvatar size="medium" testId="NotificationItemIconUser" />;
      }
    } else {
      return <UserAvatar Placeholder={EventIcon} size="medium" testId="NotificationItemIconEvent" />;
    }
  };

  return (
    <div
      data-testid="notificationItemRoot"
      className={clsx(styles.notificationItem, { [styles.unread]: !notification.read })}
      onClick={handleClickNotification}
    >
      {!notification.read && <div className={styles.redDot} />}
      <div className={styles.avatar}>{getAvatar()}</div>
      <div className={styles.flexBetween}>
        <div>{getContent()}</div>
        <div className={styles.date}>
          {formatDate(notification.content.data.createdAt, DATE_FORMAT.DYNAMIC_COMPLETE)}
        </div>
      </div>
    </div>
  );
};
