import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from 'components/activityFeed/styles.module.scss';
import { Switch } from 'components/switch';
import { useOnClickOutside } from 'utils/hooks/onClickOutside';
import { Link } from 'components/link';

type Filters = { EVENT: boolean; MESSAGE: boolean };

export type TProps = {
  filters: Filters;
  setFilters: (f: Filters) => void;
};

export const ActivityFilters: React.FC<TProps> = ({ filters, setFilters }) => {
  const { t } = useTranslation('default', {
    keyPrefix: 'components/activityFeed',
  });
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const ref = useRef(null);

  useOnClickOutside(ref, () => setIsOpen(false));

  const toggleFilterEvent = () => {
    setFilters({ ...filters, EVENT: !filters.EVENT });
  };

  const toggleFilterMessage = () => {
    setFilters({ ...filters, MESSAGE: !filters.MESSAGE });
  };

  return (
    <div className={styles.filter} data-testid="activityFiltersRoot">
      <Link underline="dashed" onClick={() => setIsOpen(true)} testId="ActivityFiltersLink">
        {t('filtersFeed')}
      </Link>
      {isOpen && (
        <div className={styles.filersWrapper} ref={ref} data-testid="ActivityFiltersPopup">
          <div className={styles.filtersFlex}>
            <div>{t('messageTypeLabel')}</div>
            <Switch
              testId="ActivityFiltersSwitchMessage"
              bold
              isActive={filters.MESSAGE}
              onClick={toggleFilterMessage}
            />
          </div>
          <div className={styles.filtersFlex}>
            <div>{t('eventTypeLabel')}</div>
            <Switch testId="ActivityFiltersSwitchEvent" bold isActive={filters.EVENT} onClick={toggleFilterEvent} />
          </div>
        </div>
      )}
    </div>
  );
};
