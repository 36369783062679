import { RailLeg, TruckLeg } from 'generated/api';
import { Route } from 'types/common';

export const getMainOperator = (route: Route) => {
  let mainLeg: TruckLeg | RailLeg;

  if (route.type === 'RAIL') {
    mainLeg = route.railLeg;
  } else if (route.type === 'TRUCK') {
    mainLeg = route.truckLeg;
  } else {
    return;
  }

  return mainLeg.operator;
};
