import { RailLeg, Booking, Quote, UploadTask, TruckLeg } from 'generated/api';

const LIMIT = 3;

type TExtractedTasks = Pick<Booking, 'fwdId'> &
  Pick<Quote, 'origin' | 'destination'> &
  Pick<RailLeg | TruckLeg, 'operator' | 'departureDatetime'> &
  UploadTask;

export const extractTasks = (bookings?: Booking[]): TExtractedTasks[] => {
  let tasks: TExtractedTasks[] = [];

  if (!bookings?.length) {
    return [];
  }

  bookings.every((booking) => {
    if (tasks.length >= LIMIT) {
      return false;
    }

    booking.tasks.every((task) => {
      if (tasks.length >= LIMIT) {
        return false;
      }

      if (task.completed) {
        return true;
      }

      const { origin, destination, route, fwdId } = booking;

      let mainLeg;

      if (route.type === 'RAIL') {
        mainLeg = route.railLeg;
      } else if (route.type === 'TRUCK') {
        mainLeg = route.truckLeg;
      }

      if (mainLeg) {
        tasks.push({
          origin,
          destination,
          departureDatetime: mainLeg.departureDatetime,
          operator: mainLeg.operator,
          fwdId,
          ...task,
        });
      }

      return true;
    });

    return true;
  });

  return tasks;
};
