import React from 'react';
import { useTranslation } from 'react-i18next';
import { BookingStatus } from 'generated/api';
import { Badge } from 'components/badge/index';

type TProps = {
  status: BookingStatus;
};

export const StatusPill: React.FC<TProps> = ({ status }) => {
  const { t } = useTranslation('default', {
    keyPrefix: 'booking.statuses',
  });

  return <Badge appearance={status}>{t(status)}</Badge>;
};
