import {
  BookingContainerInformation,
  BookingCreationRequest,
  BookingEquipmentInformation,
  CargoItem,
  EquipmentType,
  Operator,
  Quote as IQuote,
  RailPriceBreakdown,
  RailRoute,
  RailServicesRequest,
  TruckPriceBreakdown,
} from 'generated/api';
import { Route, ServicesTypeRequest } from 'types/common';
import { getUnixTime } from 'utils/date';
import { IForm, SORT_MAP, TFromBookingContainerInformation, TLocalEquipmentInformation } from './constants';

export const sortQuotes = (activeSorting: SORT_MAP, showTtTPrice: boolean | undefined) => (a: IQuote, b: IQuote) => {
  const priceKey = showTtTPrice ? 'costsTerminalToTerminal' : 'costsDoorToDoor';

  if (activeSorting === SORT_MAP.SPEED) {
    const aArrival = getUnixTime(a.route.deliveryTime);
    const bArrival = getUnixTime(b.route.deliveryTime);

    return aArrival - bArrival;
  }

  if (activeSorting === SORT_MAP.CO2) {
    return a.emissionsSaved - b.emissionsSaved;
  }

  return a[priceKey].price - b[priceKey].price;
};

const getEquipmentInformation = (equipments: TLocalEquipmentInformation[]): BookingEquipmentInformation[] => {
  if (!equipments?.length) {
    return [];
  }

  const getContainerInfo = (containerInfo: TFromBookingContainerInformation[]): BookingContainerInformation[] =>
    containerInfo.map((eq: TFromBookingContainerInformation) => ({
      ...eq,
      containerNumber: eq.containerNumber.toLocaleUpperCase(),
      originCountry: eq.originCountry?.value || '',
      destinationCountry: eq.destinationCountry?.value || '',
      goodsInformation: undefined,
    }));

  return equipments.map((equipmentItem: TLocalEquipmentInformation) => {
    if ('customsInformation' in equipmentItem) {
      return {
        goodsType: 'CUSTOMS_GOODS',
        customsInformation: {
          ...equipmentItem.customsInformation,
          customsOffice: equipmentItem?.customsInformation?.customsOffice?.value,
        },
        containerInformation: getContainerInfo(equipmentItem.containerInformation),
      };
    }

    if ('containerInformation' in equipmentItem) {
      return {
        goodsType: 'COMMUNITY_GOODS',
        containerInformation: getContainerInfo(equipmentItem.containerInformation),
      };
    }

    return {
      goodsType: 'EMPTY',
    };
  });
};

export const mapQuoteFormToBookingCreationRequest = (
  formData: IForm,
  quoteId: string,
  requestedServices?: ServicesTypeRequest,
): BookingCreationRequest => ({
  quoteId,
  fwdId: formData.fwdId,
  bookedServices: (requestedServices || null) as ServicesTypeRequest, // Type: Will be Solved with OpenAI/Swagger 3.0
  assignees: formData.assignees.map((userOption) => userOption.value),
  additionalInformation: formData.additionalInformation
    ? {
        additionalNotes: formData.additionalInformation.additionalNotes,
        origin: !formData.additionalInformation.origin
          ? undefined
          : 'shippingLine' in formData.additionalInformation.origin
          ? {
              ...formData.additionalInformation.origin,
              shippingLine: formData?.additionalInformation?.origin?.shippingLine?.value || '',
            }
          : { ...formData.additionalInformation.origin },
        destination: !formData.additionalInformation.destination
          ? undefined
          : 'shippingLine' in formData.additionalInformation.destination
          ? {
              ...formData.additionalInformation.destination,
              shippingLine: formData.additionalInformation?.destination?.shippingLine?.value || '',
            }
          : { ...formData.additionalInformation.destination },
        emptyContainerOrigin: formData.additionalInformation.emptyContainerOrigin
          ? {
              ...formData.additionalInformation.emptyContainerOrigin,
              shippingLine: formData.additionalInformation.emptyContainerOrigin.shippingLine.value,
            }
          : undefined,
        emptyContainerDestination: formData.additionalInformation.emptyContainerDestination
          ? {
              ...formData.additionalInformation.emptyContainerDestination,
              shippingLine: formData.additionalInformation.emptyContainerDestination.shippingLine.value,
            }
          : undefined,
      }
    : {},
  equipmentInformation: getEquipmentInformation(formData.equipmentInformation),
});

export const isContainer = (cargo?: CargoItem) => cargo?.equipmentType === EquipmentType.CONTAINER;

export const isProcurementIsAutomated = (
  route: Route,
  mainOperator: Operator,
  isRailRoute: boolean,
  requestedServices?: ServicesTypeRequest | RailPriceBreakdown | TruckPriceBreakdown | null,
): boolean => {
  if (isRailRoute) {
    const railRoute = route as RailRoute;
    const isFirstMileAutomated =
      (requestedServices as RailServicesRequest)?.firstMile && railRoute.firstMileLeg
        ? railRoute.firstMileLeg.operator.procurementIsAutomated
        : true;
    const isLastMileAutomated =
      (requestedServices as RailServicesRequest)?.lastMile && railRoute.lastMileLeg
        ? railRoute.lastMileLeg.operator.procurementIsAutomated
        : true;

    return Boolean(isFirstMileAutomated && isLastMileAutomated && mainOperator.procurementIsAutomated);
  } else {
    return mainOperator.procurementIsAutomated;
  }
};
