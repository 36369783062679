import { useQuery } from '@tanstack/react-query';
import { Quote, QuoteSearch, QuoteSearchRequest } from 'generated/api';
import { api } from 'api/request';

export const searchQuotesByIdQueryKey = 'searchQuotesById';
export const quotedQueryKey = 'quote';

export const searchQuotes = async (formData: QuoteSearchRequest): Promise<QuoteSearch> => {
  const { data } = await api.searchQuotes(formData);

  return data;
};

const searchQuotesById = async (id: string): Promise<QuoteSearch> => {
  const { data } = await api.getQuotesSearchResult(id);

  return data;
};

export const useSearchQuotesById = (id: string) =>
  useQuery([searchQuotesByIdQueryKey, id], () => searchQuotesById(id), {
    enabled: false,
  });

const getQuote = async (id: string): Promise<Quote> => {
  const { data } = await api.getQuote(id);

  return data;
};

export const useGetQuote = (id: string) => useQuery([quotedQueryKey, id], () => getQuote(id));
