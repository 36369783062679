import React from 'react';
import clsx from 'clsx';

import { Loader } from 'components/loader';
import styles from './styles.module.scss';

type TProps = {
  title: string;
  className?: string;
  bodyClassName?: string;
  isLoading?: boolean;
  children?: React.ReactNode;
  id?: string;
};

export const WidgetContainer: React.FC<TProps> = ({ title, className, bodyClassName, isLoading, id, children }) => (
  <div className={clsx(styles.widgetContainerRoot, className)} data-testid="WidgetContainer">
    <div className={styles.title} data-testid="WidgetContainerTitle">
      {title}
    </div>

    <div id={id}>
      <div className={clsx(styles.body, bodyClassName)} id={id}>
        {!isLoading ? (
          children
        ) : (
          <div className={styles.loading}>
            <Loader />
          </div>
        )}
      </div>
    </div>
  </div>
);
