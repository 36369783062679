import Hotjar from '@hotjar/browser';
import React, { useEffect, useState } from 'react';
import { useAuth } from 'react-oidc-context';
import config, { isTestExecution } from 'constants/config';
import { sentryClientStart } from 'modules/sentry';
import { devConsoleLog } from 'utils/console';
import { useGetUser } from 'utils/hooks/useGetUser';
import { getFullName } from 'utils/misc';

export const ConsentWrapper: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [user, roles] = useGetUser();
  const [isScriptsEnabled, setIsScriptsEnabled] = useState<boolean>(false);
  const [isConsentAvailable, setIsConsentAvailable] = useState<null | boolean>(null);

  const auth = useAuth();

  useEffect(() => {
    const consentInterval = setInterval(() => {
      if (window?.zaraz?.consent?.getAll) {
        const consentData = window.zaraz.consent.getAll();

        if (consentData.cPSZ) {
          setIsConsentAvailable(consentData.cPSZ);
        } else {
          setIsConsentAvailable(null);
        }
      }
    }, 2000);

    return () => {
      clearTimeout(consentInterval);
    };
  }, []);

  useEffect(() => {
    if (!isScriptsEnabled && auth.isAuthenticated && isConsentAvailable !== null && user) {
      if (isConsentAvailable) {
        setIsScriptsEnabled(true);
        devConsoleLog('Consent granted for the internal scripts');

        sentryClientStart();

        if (config.hotJarId && !isTestExecution) {
          Hotjar.init(config.hotJarId, 6);
          Hotjar.identify(user.id, {
            company: user?.organisation?.name,
            name: getFullName(user),
            isAdmin: roles.isAdmin,
          });
        }
      }
    }
  }, [isConsentAvailable, auth, isScriptsEnabled, roles.isAdmin, user]);

  return <>{children}</>;
};
