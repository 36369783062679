/* Generated file! Edit only for Testing! */
/* eslint-disable */
/* tslint:disable */
/** Address */
export interface Address {
  /**
   * Id
   * @format uuid
   */
  id: string;
  /** Street */
  street: string;
  /** City */
  city: string;
  /** Zip */
  zip: string;
  /** Country */
  country: string;
  /**
   * Point
   * Longitude and latitude of the address
   * @maxItems 2
   * @minItems 2
   */
  point: number[];
}

/** AddressCreationRequest */
export interface AddressCreationRequest {
  /**
   * Id
   * @format uuid
   */
  id?: string;
  /** Street */
  street: string;
  /** City */
  city: string;
  /** Zip */
  zip: string;
  /** Country */
  country: string;
  /**
   * Point
   * Longitude and latitude of the address
   * @maxItems 2
   * @minItems 2
   */
  point: number[];
}

/** ApiError */
export interface ApiError {
  /** Value of ErrorType enum to be translated at the frontend */
  errorType: ErrorType;
  /**
   * Detail
   * Various details about the error
   */
  detail: string;
}

/** AssignUserRolesRequest */
export interface AssignUserRolesRequest {
  roles: UserRoleToAssign[];
}

/** Booking */
export type Booking = RailBooking | TruckBooking;

/** BookingAdditionalInformation */
export interface BookingAdditionalInformation {
  /** Origin */
  origin?: PortInformation | WarehouseInformation | (PortInformation & WarehouseInformation);
  /** Destination */
  destination?: PortInformation | WarehouseInformation | (PortInformation & WarehouseInformation);
  emptyContainerOrigin?: EmptyContainerDepotInformation;
  emptyContainerDestination?: EmptyContainerDepotInformation;
  /** Additionalnotes */
  additionalNotes?: string;
}

/** BookingAssignmentRequest */
export interface BookingAssignmentRequest {
  /** Userids */
  userIds: string[];
}

/** BookingAssignmentResponse */
export interface BookingAssignmentResponse {
  /** Assignees */
  assignees: User[];
}

/** BookingContainerInformation */
export interface BookingContainerInformation {
  /**
   * Containernumber
   * Container number according to ISO 6346
   * @minLength 11
   * @maxLength 11
   */
  containerNumber: string;
  /**
   * Sealnumber
   * Container seal number according to ISO 17712
   */
  sealNumber: string;
  /** Origincountry */
  originCountry: string;
  /** Destinationcountry */
  destinationCountry: string;
  /** Goodsinformation */
  goodsInformation?: ContainerGoodsInformation[];
}

/**
 * BookingCosts
 * All types of costs of the booking, in terms of money, CO2 emissions and transportation time
 */
export interface BookingCosts {
  /** Price */
  price: number;
  /**
   * Duration
   * @format time-delta
   */
  duration: number;
}

/** BookingCreationRequest */
export interface BookingCreationRequest {
  /** Quoteid */
  quoteId: string;
  /**
   * Bookedservices
   * None only for TruckQuote, must be not none for RailQuote
   */
  bookedServices: RailServicesRequest;
  /**
   * Fwdid
   * Booking ID in the Forwarder's system
   */
  fwdId?: string;
  /**
   * Assignees
   * List of users to be assigned to booking
   */
  assignees?: string[];
  /**
   * Additionalinformation
   * Additional information to be provided to operator
   */
  additionalInformation: BookingAdditionalInformation;
  /**
   * Equipmentinformation
   * Equipment information to be provided to operator
   */
  equipmentInformation: BookingEquipmentInformation[];
}

/** BookingCustomerDetailsInformation */
export interface BookingCustomerDetailsInformation {
  shipperDetails: CustomerData;
  consigneeDetails: CustomerData;
  /** Goodslist */
  goodsList: GoodsAdditionalData[][];
  invoicingDetails: InvoicingDetails;
  /** Otherdetails */
  otherDetails?: string;
}

/** BookingCustomerDetailsInformationUpdateRequest */
export interface BookingCustomerDetailsInformationUpdateRequest {
  customerDetailsInformation: BookingCustomerDetailsInformation;
}

/** BookingCustomsInformation */
export interface BookingCustomsInformation {
  /** Receivername */
  receiverName: string;
  /** Receiveraddress */
  receiverAddress: string;
  /** Customsoffice */
  customsOffice?: string;
}

/** BookingEquipmentInformation */
export type BookingEquipmentInformation =
  | CustomsGoodsBookingEquipmentInformation
  | CommunityGoodsBookingEquipmentInformation
  | EmptyContainerBookingEquipmentInformation;

/**
 * BookingStatus
 * An enumeration.
 */
export enum BookingStatus {
  PENDING = "PENDING",
  CONFIRMED = "CONFIRMED",
  IN_PROGRESS = "IN_PROGRESS",
  COMPLETED = "COMPLETED",
  CANCELLED = "CANCELLED",
  FAILED = "FAILED",
  ARCHIVED = "ARCHIVED",
}

/** CargoItem */
export interface CargoItem {
  /** An enumeration. */
  equipmentType: EquipmentType;
  /** Loadingunit */
  loadingUnit: string;
  /** Size */
  size: string;
  /** Unitprofile */
  unitProfile?: string;
  /**
   * Netweight
   * @min 0
   */
  netWeight: number;
  /**
   * Tareweight
   * @min 0
   */
  tareWeight: number;
  /**
   * Grossweight
   * @min 0
   */
  grossWeight?: number;
  /**
   * Amount
   * @min 0
   */
  amount: number;
}

/** CargoItemUnitPrice */
export interface CargoItemUnitPrice {
  cargoItem: CargoItem;
  /** Price */
  price: number;
}

/** Client */
export interface Client {
  /** Internalid */
  internalId: string;
  /** Name */
  name: string;
  /**
   * Id
   * @format uuid4
   */
  id: string;
}

/** CommunityGoodsBookingEquipmentInformation */
export interface CommunityGoodsBookingEquipmentInformation {
  /**
   * Goodstype
   * @default "COMMUNITY_GOODS"
   */
  goodsType?: "COMMUNITY_GOODS";
  /** Containerinformation */
  containerInformation: BookingContainerInformation[];
}

/** ContainerGoodsInformation */
export interface ContainerGoodsInformation {
  /** Amount */
  amount: number;
  /** Description */
  description: string;
  /** Netweight */
  netWeight: number;
  /** Grossweight */
  grossWeight: number;
  /** An enumeration. */
  typeOfPackaging: TypeOfPackaging;
  /** Nhmcode */
  nhmCode: string;
  /** Volume */
  volume: number;
}

/**
 * Currency
 * An enumeration.
 */
export enum Currency {
  EUR = "EUR",
  CHF = "CHF",
  PLN = "PLN",
  DKK = "DKK",
  SEK = "SEK",
}

/** CustomerData */
export interface CustomerData {
  /** Vat */
  vat: string;
  /** Contactperson */
  contactPerson: string;
  /** Phone */
  phone: string;
  address: AddressCreationRequest;
  /** Customercode */
  customerCode?: string;
  /** Email */
  email?: string;
}

/** CustomsGoodsBookingEquipmentInformation */
export interface CustomsGoodsBookingEquipmentInformation {
  /**
   * Goodstype
   * @default "CUSTOMS_GOODS"
   */
  goodsType?: "CUSTOMS_GOODS";
  /** Containerinformation */
  containerInformation: BookingContainerInformation[];
  customsInformation: BookingCustomsInformation;
}

/** DashboardStats */
export interface DashboardStats {
  /** Totalbookings */
  totalBookings: number;
  /** Weight */
  weight: number;
  /** Emissions */
  emissions: number;
}

/** Document */
export interface Document {
  /**
   * Id
   * @format uuid
   */
  id: string;
  /** Name */
  name: string;
  /**
   * Path
   * @format path
   */
  path: string;
  /** An enumeration. */
  type: DocumentType;
  /** Bookingid */
  bookingId: string;
  /**
   * Createdby
   * @format uuid4
   */
  createdBy: string;
}

/** DocumentDownloadResponse */
export interface DocumentDownloadResponse {
  /**
   * Downloadurl
   * @format uri
   * @minLength 1
   * @maxLength 2083
   */
  downloadUrl: string;
}

/**
 * DocumentType
 * An enumeration.
 */
export enum DocumentType {
  OTHER = "OTHER",
  VGM = "VGM",
  BOF = "BOF",
  CBOF = "CBOF",
  CIM = "CIM",
  CMR = "CMR",
  SMGS = "SMGS",
  SAD = "SAD",
  COI = "COI",
  CUI = "CUI",
  TIR = "TIR",
  PAL = "PAL",
  LOS = "LOS",
  STS = "STS",
  COO = "COO",
  ECDC = "ECDC",
}

/** EmailNotificationSettings */
export interface EmailNotificationSettings {
  /** Allemails */
  allEmails: boolean;
}

/** EmptyContainerBookingEquipmentInformation */
export interface EmptyContainerBookingEquipmentInformation {
  /**
   * Goodstype
   * @default "EMPTY"
   */
  goodsType?: "EMPTY";
}

/** EmptyContainerDepot */
export interface EmptyContainerDepot {
  /**
   * Id
   * @format uuid
   */
  id: string;
  /** Name */
  name: string;
  address: Address;
}

/** EmptyContainerDepotInformation */
export interface EmptyContainerDepotInformation {
  /**
   * Companyname
   * Name of the company that's associated with this depot
   */
  companyName: string;
  /** Shippingline */
  shippingLine: string;
  /**
   * Reference
   * Container depot release reference, defines a reference for a loading point.
   */
  reference: string;
  /**
   * Workinghoursopens
   * @format time
   */
  workingHoursOpens: string;
  /**
   * Workinghourscloses
   * @format time
   */
  workingHoursCloses: string;
}

/**
 * EquipmentType
 * An enumeration.
 */
export enum EquipmentType {
  SEMITRAILER = "SEMITRAILER",
  SWAPBODY = "SWAPBODY",
  CONTAINER = "CONTAINER",
}

/**
 * ErrorType
 * An enumeration.
 */
export enum ErrorType {
  INVALID_REQUEST = "INVALID_REQUEST",
  UNAUTHORIZED = "UNAUTHORIZED",
  FORBIDDEN = "FORBIDDEN",
  NOT_FOUND = "NOT_FOUND",
  SERVER_ERROR = "SERVER_ERROR",
  UNPROCESSABLE_ENTITY = "UNPROCESSABLE_ENTITY",
}

/** FeedEntry */
export type FeedEntry = FeedMessage | FeedEvent;

/** FeedEvent */
export interface FeedEvent {
  /**
   * Id
   * @format uuid
   */
  id: string;
  /**
   * Type
   * @default "EVENT"
   */
  type?: "EVENT";
  /**
   * Createdat
   * @format date-time
   */
  createdAt: string;
  /** Bookingid */
  bookingId: string;
  content: FeedEventContent;
}

/** FeedEventBookingStatusHasChanged */
export interface FeedEventBookingStatusHasChanged {
  /**
   * Type
   * @default "BOOKING_STATUS_HAS_CHANGED"
   */
  type?: "BOOKING_STATUS_HAS_CHANGED";
  /** An enumeration. */
  newStatus: BookingStatus;
}

/** FeedEventBookingWasUpdated */
export interface FeedEventBookingWasUpdated {
  /**
   * Type
   * @default "BOOKING_WAS_UPDATED"
   */
  type?: "BOOKING_WAS_UPDATED";
  /** Olddata */
  oldData: object;
  /** Newdata */
  newData: object;
}

/** FeedEventContent */
export type FeedEventContent =
  | FeedEventBookingStatusHasChanged
  | FeedEventTaskIsCreated
  | FeedEventShipmentEventHasChanged
  | FeedEventExceptionHasOccurred
  | FeedEventBookingWasUpdated;

/** FeedEventExceptionHasOccurred */
export interface FeedEventExceptionHasOccurred {
  /**
   * Type
   * @default "EXCEPTION_HAS_OCCURRED"
   */
  type?: "EXCEPTION_HAS_OCCURRED";
  /**
   * Exceptionid
   * @format uuid4
   */
  exceptionId: string;
}

/** FeedEventShipmentEventHasChanged */
export interface FeedEventShipmentEventHasChanged {
  /**
   * Type
   * @default "SHIPMENT_EVENT_HAS_CHANGED"
   */
  type?: "SHIPMENT_EVENT_HAS_CHANGED";
  /** An enumeration. */
  shipmentEventName: ShipmentEventName;
  /** Iscompleted */
  isCompleted: boolean;
  /**
   * Neweta
   * @format date-time
   */
  newEta?: string;
  /**
   * Newata
   * @format date-time
   */
  newAta?: string;
}

/** FeedEventTaskIsCreated */
export interface FeedEventTaskIsCreated {
  /**
   * Type
   * @default "TASK_IS_CREATED"
   */
  type?: "TASK_IS_CREATED";
  /**
   * Taskid
   * @format uuid4
   */
  taskId: string;
  /** An enumeration. */
  taskType: TaskType;
}

/** FeedMessage */
export interface FeedMessage {
  /**
   * Id
   * @format uuid
   */
  id: string;
  /**
   * Type
   * @default "MESSAGE"
   */
  type?: "MESSAGE";
  /**
   * Createdat
   * @format date-time
   */
  createdAt: string;
  /** Bookingid */
  bookingId: string;
  author: MessageAuthor;
  /** Content */
  content: string;
  recipient: MessageRecipient;
}

/** GoodsAdditionalData */
export interface GoodsAdditionalData {
  /** Shippingmarks */
  shippingMarks: string;
  /** Declaredvalue */
  declaredValue: number;
  /** An enumeration. */
  currency: Currency;
  /** Sealnumber */
  sealNumber: string;
  /** Referencenumber */
  referenceNumber: string;
  /** Truckregistrationnumber */
  truckRegistrationNumber: string;
}

/** InviteUserRequest */
export interface InviteUserRequest {
  /** Emails */
  emails: string[];
}

/** InvoicingDetails */
export interface InvoicingDetails {
  /** Name */
  name: string;
  address: AddressCreationRequest;
  /** Vat */
  vat: string;
  /** Internalreference */
  internalReference: string;
}

/**
 * Language
 * An enumeration.
 */
export enum Language {
  EN = "EN",
  DE = "DE",
}

/**
 * LegStatus
 * The status of the Leg
 * * `PENDING` - Operator-side Booking Request for this particular leg is being processed
 * * `ACCEPTED` - Operator-side Booking Request for this particular leg is accepted, but possibly some additional
 * info is required for this Leg to be ready for shipment
 * * `READY_FOR_SHIPMENT` - Leg is ready for shipment
 * * `SHIPMENT` - Corresponding booking was transferred to shipment stage
 * * `DECLINED` - Operator-side Booking Request for this particular leg is declined OR Leg is cancelled (during
 * Booking Management or Shipment Tracking)
 */
export enum LegStatus {
  PENDING = "PENDING",
  ACCEPTED = "ACCEPTED",
  READY_FOR_SHIPMENT = "READY_FOR_SHIPMENT",
  SHIPMENT = "SHIPMENT",
  DECLINED = "DECLINED",
}

/** MessageAuthor */
export type MessageAuthor = UserAuthor | RailOperatorAuthor | RoadCarrierAuthor | OPSTeamAuthor;

/** MessageRecipient */
export interface MessageRecipient {
  /** Type of recipient */
  type: MessageRecipientType;
  /**
   * Operatorid
   * Should be present when the recipient is an operator or carrier
   * @format uuid4
   */
  operatorId?: string;
}

/**
 * MessageRecipientType
 * An enumeration.
 */
export enum MessageRecipientType {
  INTERNAL_TEAM = "INTERNAL_TEAM",
  ROUVIA_CUSTOMER_SERVICE = "ROUVIA_CUSTOMER_SERVICE",
  OPERATOR = "OPERATOR",
  ROAD_CARRIER = "ROAD_CARRIER",
}

/** Notification */
export interface Notification {
  /**
   * Id
   * @format uuid
   */
  id: string;
  /** Read */
  read: boolean;
  content: NotificationContent;
}

/** NotificationContent */
export interface NotificationContent {
  /** An enumeration. */
  type: NotificationType;
  data: FeedEntry;
}

/** NotificationSettings */
export interface NotificationSettings {
  email: EmailNotificationSettings;
}

/**
 * NotificationType
 * An enumeration.
 */
export enum NotificationType {
  FEED_ENTRY_EVENT = "FEED_ENTRY_EVENT",
}

/** OPSTeamAuthor */
export interface OPSTeamAuthor {
  /**
   * Type
   * @default "OPS_TEAM"
   */
  type?: "OPS_TEAM";
}

/** Operator */
export interface Operator {
  /**
   * Id
   * @format uuid
   */
  id: string;
  /** Name */
  name: string;
  /** Logo */
  logo: string;
  /** Procurementisautomated */
  procurementIsAutomated: boolean;
  /**
   * Contactinfo
   * @minLength 1
   */
  contactInfo: string;
  /** Contacts */
  contacts?: OperatorContact[];
  /** An enumeration. */
  operatorType: OperatorType;
  /** Documents */
  documents?: OperatorDocument[];
  /** Railoperatorcode */
  railOperatorCode?: string;
}

/** OperatorContact */
export interface OperatorContact {
  /** An enumeration. */
  type: OperatorContactType;
  /** Value */
  value: string;
  /** Description */
  description?: string;
}

/**
 * OperatorContactType
 * An enumeration.
 */
export enum OperatorContactType {
  PRIMARY_EMAIL = "PRIMARY_EMAIL",
  EMAIL = "EMAIL",
  PHONE = "PHONE",
}

/** OperatorDocument */
export interface OperatorDocument {
  /** An enumeration. */
  type: OperatorDocumentType;
  /**
   * Url
   * @format uri
   * @minLength 1
   * @maxLength 2083
   */
  url: string;
}

/**
 * OperatorDocumentType
 * An enumeration.
 */
export enum OperatorDocumentType {
  GTC = "GTC",
  OTHER = "OTHER",
}

/**
 * OperatorType
 * An enumeration.
 */
export enum OperatorType {
  TRUCK = "TRUCK",
  INTERMODAL = "INTERMODAL",
  MULTIMODAL = "MULTIMODAL",
}

/** Organisation */
export interface Organisation {
  /**
   * Id
   * @format uuid4
   */
  id: string;
  /** Name */
  name: string;
}

/** PageInfo */
export interface PageInfo {
  /**
   * Page
   * Current page number
   */
  page: number;
  /**
   * Perpage
   * Number of items per page
   */
  perPage: number;
  /**
   * Total
   * Total number of items in the response
   */
  total: number;
  /**
   * Nextpage
   * Is the next page available?
   */
  nextPage: boolean;
  /**
   * Prevpage
   * Is the previous page available?
   */
  prevPage: boolean;
}

/** PaginatedResponse[Booking] */
export interface PaginatedResponseBooking {
  /** Data */
  data: Booking[];
  pagination: PageInfo;
}

/** PaginatedResponse[Notification] */
export interface PaginatedResponseNotification {
  /** Data */
  data: Notification[];
  pagination: PageInfo;
}

/** PortInformation */
export interface PortInformation {
  /** Vesselname */
  vesselName: string;
  /**
   * Port
   * Details about the port, it could be the city or port name for instance.
   */
  port: string;
  /**
   * Datetime
   * This field can have different meanings depending on Import/Export. If Import: Vessel ETA, if Export: Ship closing
   * @format date-time
   */
  dateTime: string;
  /** Shippingline */
  shippingLine: string;
}

/** PostMessageRequest */
export interface PostMessageRequest {
  /** Message */
  message: string;
  recipient: MessageRecipient;
}

/** Quote */
export type Quote = RailQuote | TruckQuote;

/**
 * QuoteCosts
 * All types of costs of the quote, in terms of money, CO2 emissions and transportation time
 */
export interface QuoteCosts {
  /** Price */
  price: number;
  /**
   * Duration
   * @format time-delta
   */
  duration: number;
}

/** QuoteSearch */
export interface QuoteSearch {
  /**
   * Id
   * Unique identifier of the quote search
   * @pattern ^[BSQ]-[A-Z0-9]{5,7}$
   */
  id: string;
  searchRequest: QuoteSearchRequest;
  /** Quotes */
  quotes: Quote[];
}

/** QuoteSearchRequest */
export interface QuoteSearchRequest {
  /** Origin */
  origin: AddressCreationRequest | string;
  /** Origincontainerdepot */
  originContainerDepot?: AddressCreationRequest | string;
  /** Destination */
  destination: AddressCreationRequest | string;
  /** Destinationcontainerdepot */
  destinationContainerDepot?: AddressCreationRequest | string;
  /**
   * Collectiondate
   * @format date
   */
  collectionDate: string;
  /**
   * Collectiondaysoffset
   * @default 0
   */
  collectionDaysOffset?: number;
  /**
   * Earliestcollectiontime
   * @format time
   */
  earliestCollectionTime: string;
  /**
   * Latestcollectiontime
   * @format time
   */
  latestCollectionTime: string;
  /**
   * Deliverydate
   * @format date
   */
  deliveryDate: string;
  /**
   * Latestdeliverytime
   * @format time
   */
  latestDeliveryTime: string;
  /**
   * Earliestdeliverytime
   * @format time
   */
  earliestDeliveryTime: string;
  /**
   * Cargo
   * @minItems 1
   */
  cargo: CargoItem[];
}

/** RailBooking */
export interface RailBooking {
  /**
   * Createdat
   * @format date-time
   */
  createdAt: string;
  /**
   * Id
   * Booking ID
   * @pattern ^[BSQ]-[A-Z0-9]{5,7}$
   */
  id: string;
  /**
   * Fwdid
   * Booking ID in the Forwarder's system
   */
  fwdId: string;
  /** An enumeration. */
  status: BookingStatus;
  /** Bookmarked */
  bookmarked: boolean;
  /** Documents */
  documents: Document[];
  /** Tasks */
  tasks: UploadTask[];
  /**
   * Client
   * Client/customer the booking is made for. null if it hasn't been specified yet
   */
  client?: Client;
  /**
   * Emissions
   * Full door-to-door emissions for the whole route
   */
  emissions: number;
  /** Assignees */
  assignees: User[];
  additionalInformation: BookingAdditionalInformation;
  /** Equipmentinformation */
  equipmentInformation: BookingEquipmentInformation[];
  customerDetailsInformation?: BookingCustomerDetailsInformation;
  /** Numericid */
  numericId?: number;
  /**
   * Pricebreakdown
   * Must contain prices for first/last mile and rail legs.
   */
  priceBreakdown: RailPriceBreakdown;
  /**
   * Eta
   * Estimated time of arrival of the shipment
   * @format date-time
   */
  eta?: string;
  /** Exceptions */
  exceptions?: ShipmentException[];
  /** Isexception */
  isException?: boolean;
  /**
   * Islate
   * @default false
   */
  isLate?: boolean;
  /**
   * Searchid
   * Quote Search ID
   * @pattern ^[BSQ]-[A-Z0-9]{5,7}$
   */
  searchId: string;
  /**
   * Quoteid
   * Quote ID
   * @pattern ^[BSQ]-[A-Z0-9]{5,7}$
   */
  quoteId: string;
  /**
   * Origin
   * Origin address/terminal the quote was requested for
   */
  origin: Address | Terminal | (Address & Terminal);
  /**
   * Destination
   * Destination address/terminal the quote was requested for
   */
  destination: Address | Terminal | (Address & Terminal);
  /**
   * Cargo
   * @minItems 1
   */
  cargo: CargoItem[];
  /**
   * Route
   * Full journey of the cargo that may be partially booked by the user
   */
  route: RailRoute;
  /**
   * Emissionssaved
   * Emissions saved for this route in comparison with truck-only option. May be negative in case truck-only option is more efficient
   */
  emissionsSaved: number;
  /**
   * Costsbooked
   * Costs of the quote concerning all the services selected for booking
   */
  costsBooked: BookingCosts;
  /** Events */
  events: ShipmentEvent[];
  /**
   * Bookedservices
   * None only for TruckBooking, must be not none for RailBooking
   */
  bookedServices: RailServicesRequest;
  /**
   * Type
   * @default "RAIL"
   */
  type?: "RAIL";
}

/**
 * RailLeg
 * A part of multimodal route that is executed on one mode of transport by the Operator
 */
export interface RailLeg {
  /**
   * Id
   * @format uuid
   */
  id: string;
  /**
   * Departuredatetime
   * @format date-time
   */
  departureDatetime: string;
  /**
   * Arrivaldatetime
   * @format date-time
   */
  arrivalDatetime: string;
  operator: Operator;
  /** Status of the leg */
  status: LegStatus;
  /** Emissions */
  emissions: number;
  origin: Terminal;
  destination: Terminal;
  /** Trainnumber */
  trainNumber?: string;
  /** Internalid */
  internalId?: string;
  /**
   * Latesthandovertime
   * Latest time when the cargo can be handed over at the origin rail terminal
   * @format date-time
   */
  latestHandoverTime: string;
  /**
   * Earliestpickuptime
   * Earliest time when the cargo can be picked up at the destination rail terminal
   * @format date-time
   */
  earliestPickupTime: string;
}

/** RailOperatorAuthor */
export interface RailOperatorAuthor {
  /**
   * Id
   * @format uuid
   */
  id: string;
  /** Name */
  name: string;
  /** Logo */
  logo: string;
  /** Procurementisautomated */
  procurementIsAutomated: boolean;
  /**
   * Contactinfo
   * @minLength 1
   */
  contactInfo: string;
  /** Contacts */
  contacts?: OperatorContact[];
  /** An enumeration. */
  operatorType: OperatorType;
  /** Documents */
  documents?: OperatorDocument[];
  /** Railoperatorcode */
  railOperatorCode?: string;
  /**
   * Type
   * @default "OPERATOR"
   */
  type?: "OPERATOR";
}

/** RailPriceBreakdown */
export interface RailPriceBreakdown {
  /** Railleg */
  railLeg: number;
  /**
   * Firstmile
   * Price includes first mile transportation, and in case empty container pickup is requested during quote search, includes its price as well
   */
  firstMile?: number;
  /**
   * Lastmile
   * Price includes last mile transportation, and in case empty container dropoff is requested during quote search, includes its price as well
   */
  lastMile?: number;
  /** Railpricebycargoitem */
  railPriceByCargoItem: CargoItemUnitPrice[];
  /** Firstmilepricebycargoitem */
  firstMilePriceByCargoItem: CargoItemUnitPrice[];
  /** Lastmilepricebycargoitem */
  lastMilePriceByCargoItem: CargoItemUnitPrice[];
}

/** RailQuote */
export interface RailQuote {
  /**
   * Id
   * Unique identifier of the quote
   * @pattern ^[BSQ]-[A-Z0-9]{5,7}$
   */
  id: string;
  /**
   * Type
   * @default "RAIL"
   */
  type?: "RAIL";
  /** Searchid */
  searchId: string;
  /**
   * Origin
   * Origin address/terminal the quote was requested for
   */
  origin: Address | Terminal | (Address & Terminal);
  /**
   * Destination
   * Destination address/terminal the quote was requested for
   */
  destination: Address | Terminal | (Address & Terminal);
  /**
   * Cargo
   * @minItems 1
   */
  cargo: CargoItem[];
  /**
   * Route
   * Full journey of the cargo that may be partially booked by the user
   */
  route: RailRoute;
  /**
   * Pricebreakdown
   * Must contain boolean values indicating if the service was booked for Rail route.
   */
  priceBreakdown: RailPriceBreakdown;
  /**
   * Costsdoortodoor
   * Full door-to-door costs of the quote, regardless if the first or last mile was selected
   */
  costsDoorToDoor: QuoteCosts;
  /**
   * Coststerminaltoterminal
   * Terminal-to-terminal costs of the quote, regardless if the first or last mile was selected
   */
  costsTerminalToTerminal: QuoteCosts;
  /**
   * Emissions
   * Full door-to-door emissions for the whole route
   */
  emissions: number;
  /**
   * Emissionssaved
   * Emissions saved for this route in comparison with truck-only option. May be negative in case truck-only option is more efficient
   */
  emissionsSaved: number;
  /**
   * Expiredat
   * @format date-time
   */
  expiredAt: string;
}

/** RailRoute */
export interface RailRoute {
  /** A part of multimodal route that is executed on one mode of transport by the Operator */
  firstMileLeg?: TruckLeg;
  /** A part of multimodal route that is executed on one mode of transport by the Operator */
  emptyContainerPickupLeg?: TruckLeg;
  /** A part of multimodal route that is executed on one mode of transport by the Operator */
  railLeg: RailLeg;
  /** A part of multimodal route that is executed on one mode of transport by the Operator */
  lastMileLeg?: TruckLeg;
  /** A part of multimodal route that is executed on one mode of transport by the Operator */
  emptyContainerDropoffLeg?: TruckLeg;
  /**
   * Id
   * @format uuid
   */
  id: string;
  /**
   * Type
   * @default "RAIL"
   */
  type?: "RAIL";
  /**
   * Collectiontime
   * Estimated time of collection at the consignor
   * @format date-time
   */
  collectionTime: string;
  /**
   * Deliverytime
   * Estimated time of delivery at the consignee
   * @format date-time
   */
  deliveryTime: string;
  /**
   * Duration
   * @format time-delta
   */
  duration: number;
  /** Price */
  price: number;
  /** Emissions */
  emissions: number;
}

/**
 * RailServicesRequest
 * Information on which parts of the journey and which additional services were requested by the user for RailQuote
 */
export interface RailServicesRequest {
  /**
   * Firstmile
   * Stands for first mile transportation, and in case empty container pickup is requested during quote search, stands for it as well
   */
  firstMile: boolean;
  /**
   * Lastmile
   * Stands for last mile transportation, and in case empty container dropoff is requested during quote search, stands for it as well
   */
  lastMile: boolean;
}

/** RoadCarrierAuthor */
export interface RoadCarrierAuthor {
  /**
   * Id
   * @format uuid
   */
  id: string;
  /** Name */
  name: string;
  /** Logo */
  logo: string;
  /** Procurementisautomated */
  procurementIsAutomated: boolean;
  /**
   * Contactinfo
   * @minLength 1
   */
  contactInfo: string;
  /** Contacts */
  contacts?: OperatorContact[];
  /** An enumeration. */
  operatorType: OperatorType;
  /** Documents */
  documents?: OperatorDocument[];
  /** Railoperatorcode */
  railOperatorCode?: string;
  /**
   * Type
   * @default "ROAD_CARRIER"
   */
  type?: "ROAD_CARRIER";
}

/** ShipmentEvent */
export interface ShipmentEvent {
  /** Name/type of the event */
  name: ShipmentEventName;
  /**
   * Eta
   * Estimated time of the event
   * @format date-time
   */
  eta: string;
  /**
   * Ata
   * Actual time of the event. May be filled only for the events that have happened
   * @format date-time
   */
  ata?: string;
  /**
   * Iscompleted
   * Boolean indicating whether the event is completed or not
   */
  isCompleted: boolean;
}

/**
 * ShipmentEventName
 * An enumeration.
 */
export enum ShipmentEventName {
  EMPTY_CONTAINER_PICK_UP_STARTED = "EMPTY_CONTAINER_PICK_UP_STARTED",
  EMPTY_CONTAINER_PICK_UP_FINISHED = "EMPTY_CONTAINER_PICK_UP_FINISHED",
  PICKED_UP = "PICKED_UP",
  CHECKED_IN_AT_TERMINAL = "CHECKED_IN_AT_TERMINAL",
  DEPARTED = "DEPARTED",
  ARRIVED = "ARRIVED",
  CHECKED_OUT_FROM_TERMINAL = "CHECKED_OUT_FROM_TERMINAL",
  HANDED_OVER = "HANDED_OVER",
  EMPTY_CONTAINER_DROP_OFF_STARTED = "EMPTY_CONTAINER_DROP_OFF_STARTED",
  EMPTY_CONTAINER_DROP_OFF_FINISHED = "EMPTY_CONTAINER_DROP_OFF_FINISHED",
}

/** ShipmentException */
export interface ShipmentException {
  /**
   * Createdat
   * @format date-time
   */
  createdAt: string;
  /**
   * Id
   * @format uuid
   */
  id: string;
  /** Title */
  title: string;
  /** Message */
  message: string;
  /** Completed */
  completed: boolean;
  /** Operatorref */
  operatorRef?: string;
}

/**
 * TaskType
 * An enumeration.
 */
export enum TaskType {
  DOC_UPLOAD = "DOC_UPLOAD",
}

/** Terminal */
export interface Terminal {
  /**
   * Id
   * @format uuid
   */
  id: string;
  /** Name */
  name: string;
  address: Address;
  /** Operator */
  operator: string;
  /** Services */
  services: object;
  /**
   * Openinghours
   * @default "06:00 - 20:00"
   */
  openingHours?: string;
  /** An enumeration. */
  type: TerminalType;
  /** Workinghours */
  workingHours?: TerminalWorkingHours[];
  /** Unlocode */
  unLocode?: string;
  /** Aliases */
  aliases?: TerminalAlias[];
}

/** TerminalAlias */
export interface TerminalAlias {
  /** Name */
  name: string;
}

/**
 * TerminalType
 * An enumeration.
 */
export enum TerminalType {
  RAIL = "RAIL",
  MARITIME = "MARITIME",
}

/** TerminalWorkingHours */
export interface TerminalWorkingHours {
  /**
   * Dayoftheweek
   * Day of the week as an integer, where 1 represents Monday and 7 represents Sunday
   * @min 1
   * @max 7
   */
  dayOfTheWeek: number;
  /**
   * Opensat
   * @format time
   */
  opensAt: string;
  /**
   * Closesat
   * @format time
   */
  closesAt: string;
}

/** TruckBooking */
export interface TruckBooking {
  /**
   * Createdat
   * @format date-time
   */
  createdAt: string;
  /**
   * Id
   * Booking ID
   * @pattern ^[BSQ]-[A-Z0-9]{5,7}$
   */
  id: string;
  /**
   * Fwdid
   * Booking ID in the Forwarder's system
   */
  fwdId: string;
  /** An enumeration. */
  status: BookingStatus;
  /** Bookmarked */
  bookmarked: boolean;
  /** Documents */
  documents: Document[];
  /** Tasks */
  tasks: UploadTask[];
  /**
   * Client
   * Client/customer the booking is made for. null if it hasn't been specified yet
   */
  client?: Client;
  /**
   * Emissions
   * Full door-to-door emissions for the whole route
   */
  emissions: number;
  /** Assignees */
  assignees: User[];
  additionalInformation: BookingAdditionalInformation;
  /** Equipmentinformation */
  equipmentInformation: BookingEquipmentInformation[];
  customerDetailsInformation?: BookingCustomerDetailsInformation;
  /** Numericid */
  numericId?: number;
  /**
   * Pricebreakdown
   * Must contain prices for the truck leg.
   */
  priceBreakdown: TruckPriceBreakdown;
  /**
   * Eta
   * Estimated time of arrival of the shipment
   * @format date-time
   */
  eta?: string;
  /** Exceptions */
  exceptions?: ShipmentException[];
  /** Isexception */
  isException?: boolean;
  /**
   * Islate
   * @default false
   */
  isLate?: boolean;
  /**
   * Searchid
   * Quote Search ID
   * @pattern ^[BSQ]-[A-Z0-9]{5,7}$
   */
  searchId: string;
  /**
   * Quoteid
   * Quote ID
   * @pattern ^[BSQ]-[A-Z0-9]{5,7}$
   */
  quoteId: string;
  /**
   * Origin
   * Origin address/terminal the quote was requested for
   */
  origin: Address | Terminal | (Address & Terminal);
  /**
   * Destination
   * Destination address/terminal the quote was requested for
   */
  destination: Address | Terminal | (Address & Terminal);
  /**
   * Cargo
   * @minItems 1
   */
  cargo: CargoItem[];
  /**
   * Route
   * Full journey of the cargo that may be partially booked by the user
   */
  route: TruckRoute;
  /**
   * Emissionssaved
   * Emissions saved for this route in comparison with truck-only option. May be negative in case truck-only option is more efficient
   */
  emissionsSaved: number;
  /**
   * Costsbooked
   * Costs of the quote concerning all the services selected for booking
   */
  costsBooked: BookingCosts;
  /** Events */
  events: ShipmentEvent[];
  /**
   * Bookedservices
   * None only for TruckBooking, must be not none for RailBooking
   */
  bookedServices: RailServicesRequest;
  /**
   * Type
   * @default "TRUCK"
   */
  type?: "TRUCK";
}

/**
 * TruckLeg
 * A part of multimodal route that is executed on one mode of transport by the Operator
 */
export interface TruckLeg {
  /**
   * Id
   * @format uuid
   */
  id: string;
  /**
   * Departuredatetime
   * @format date-time
   */
  departureDatetime: string;
  /**
   * Arrivaldatetime
   * @format date-time
   */
  arrivalDatetime: string;
  operator: Operator;
  /** Status of the leg */
  status: LegStatus;
  /** Emissions */
  emissions: number;
  /** Origin */
  origin: Address | Terminal | EmptyContainerDepot | (Address & Terminal & EmptyContainerDepot);
  /** Destination */
  destination: Address | Terminal | EmptyContainerDepot | (Address & Terminal & EmptyContainerDepot);
  /** Dmbjoborderref */
  dmbJobOrderRef?: string;
}

/** TruckPriceBreakdown */
export interface TruckPriceBreakdown {
  /** Alltrucklegs */
  allTruckLegs: number;
  /** Pricebycargoitem */
  priceByCargoItem: CargoItemUnitPrice[];
}

/** TruckQuote */
export interface TruckQuote {
  /**
   * Id
   * Unique identifier of the quote
   * @pattern ^[BSQ]-[A-Z0-9]{5,7}$
   */
  id: string;
  /**
   * Type
   * @default "TRUCK"
   */
  type?: "TRUCK";
  /** Searchid */
  searchId: string;
  /**
   * Origin
   * Origin address/terminal the quote was requested for
   */
  origin: Address | Terminal | (Address & Terminal);
  /**
   * Destination
   * Destination address/terminal the quote was requested for
   */
  destination: Address | Terminal | (Address & Terminal);
  /**
   * Cargo
   * @minItems 1
   */
  cargo: CargoItem[];
  /**
   * Route
   * Full journey of the cargo that may be partially booked by the user
   */
  route: TruckRoute;
  /**
   * Pricebreakdown
   * Must contain boolean values indicating if the service was booked for Truck route.
   */
  priceBreakdown: TruckPriceBreakdown;
  /**
   * Costsdoortodoor
   * Full door-to-door costs of the quote, regardless if the first or last mile was selected
   */
  costsDoorToDoor: QuoteCosts;
  /**
   * Coststerminaltoterminal
   * Terminal-to-terminal costs of the quote, regardless if the first or last mile was selected
   */
  costsTerminalToTerminal: QuoteCosts;
  /**
   * Emissions
   * Full door-to-door emissions for the whole route
   */
  emissions: number;
  /**
   * Emissionssaved
   * Emissions saved for this route in comparison with truck-only option. May be negative in case truck-only option is more efficient
   */
  emissionsSaved: number;
  /**
   * Expiredat
   * @format date-time
   */
  expiredAt: string;
}

/** TruckRoute */
export interface TruckRoute {
  /** A part of multimodal route that is executed on one mode of transport by the Operator */
  emptyContainerPickupLeg?: TruckLeg;
  /** A part of multimodal route that is executed on one mode of transport by the Operator */
  truckLeg: TruckLeg;
  /** A part of multimodal route that is executed on one mode of transport by the Operator */
  emptyContainerDropoffLeg?: TruckLeg;
  /**
   * Id
   * @format uuid
   */
  id: string;
  /**
   * Type
   * @default "TRUCK"
   */
  type?: "TRUCK";
  /**
   * Collectiontime
   * Estimated time of collection at the consignor
   * @format date-time
   */
  collectionTime: string;
  /**
   * Deliverytime
   * Estimated time of delivery at the consignee
   * @format date-time
   */
  deliveryTime: string;
  /**
   * Duration
   * @format time-delta
   */
  duration: number;
  /** Price */
  price: number;
  /** Emissions */
  emissions: number;
}

/**
 * TypeOfPackaging
 * An enumeration.
 */
export enum TypeOfPackaging {
  BA = "BA",
  BL = "BL",
  BN = "BN",
  BX = "BX",
  CI = "CI",
  CR = "CR",
  CS = "CS",
  CT = "CT",
  DR = "DR",
  JC = "JC",
  KG = "KG",
  NE = "NE",
  PC = "PC",
  PK = "PK",
  PO = "PO",
  PX = "PX",
  PY = "PY",
  RL = "RL",
  SA = "SA",
  SK = "SK",
  TY = "TY",
  VO = "VO",
  BE = "BE",
  BG = "BG",
  BH = "BH",
  EC = "EC",
  SL = "SL",
  PA = "PA",
  PP = "PP",
}

/** UpdatePasswordRequest */
export interface UpdatePasswordRequest {
  /** Oldpassword */
  oldPassword: string;
  /** Newpassword */
  newPassword: string;
}

/** UpdateUserRequest */
export interface UpdateUserRequest {
  /** Firstname */
  firstName: string;
  /** Lastname */
  lastName: string;
  /** Timezone */
  timezone: string;
  /** An enumeration. */
  languagePreference: Language;
}

/** UploadTask */
export interface UploadTask {
  /**
   * Id
   * @format uuid
   */
  id: string;
  /**
   * Type
   * @default "DOC_UPLOAD"
   */
  type?: "DOC_UPLOAD";
  /** Bookingid */
  bookingId: string;
  /** Completed */
  completed: boolean;
  /**
   * Duedate
   * @format date-time
   */
  dueDate: string;
  /** An enumeration. */
  content: DocumentType;
}

/** User */
export interface User {
  /**
   * Id
   * @format uuid4
   */
  id: string;
  /** Firstname */
  firstName?: string;
  /** Lastname */
  lastName?: string;
  /** Isverified */
  isVerified: boolean;
  /**
   * Email
   * @format email
   */
  email: string;
  organisation: Organisation;
  roles: UserRole[];
  /**
   * Avatar
   * @format uri
   * @minLength 1
   * @maxLength 65536
   */
  avatar?: string;
  /** Timezone */
  timezone?: string;
  /** An enumeration. */
  languagePreference?: Language;
  /** Enabled */
  enabled: boolean;
}

/** UserAuthor */
export interface UserAuthor {
  /**
   * Id
   * @format uuid4
   */
  id: string;
  /** Firstname */
  firstName?: string;
  /** Lastname */
  lastName?: string;
  /** Isverified */
  isVerified: boolean;
  /**
   * Email
   * @format email
   */
  email: string;
  organisation: Organisation;
  roles: UserRole[];
  /**
   * Avatar
   * @format uri
   * @minLength 1
   * @maxLength 65536
   */
  avatar?: string;
  /** Timezone */
  timezone?: string;
  /** An enumeration. */
  languagePreference?: Language;
  /** Enabled */
  enabled: boolean;
  /**
   * Type
   * @default "USER"
   */
  type?: "USER";
}

/**
 * UserRole
 * Represents the roles available in Keycloak
 */
export enum UserRole {
  Admin = "admin",
  Superadmin = "superadmin",
  EquoteAdmin = "equote-admin",
  DefaultRolesRouvia = "default-roles-rouvia",
  OfflineAccess = "offline_access",
  Member = "member",
  UmaAuthorization = "uma_authorization",
}

/**
 * UserRoleToAssign
 * Represents the roles available for assignment via API
 */
export enum UserRoleToAssign {
  Admin = "admin",
}

/** WarehouseInformation */
export interface WarehouseInformation {
  /**
   * Companyname
   * Name of the company that's associated with this warehouse, consignee or consignor correspondingly
   */
  companyName: string;
}

/** UploadFile */
export interface UploadFile {
  /**
   * File
   * @format binary
   */
  file: File;
}

import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse, HeadersDefaults, ResponseType } from "axios";

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams extends Omit<AxiosRequestConfig, "data" | "params" | "url" | "responseType"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType;
  /** request body */
  body?: unknown;
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

export interface ApiConfig<SecurityDataType = unknown> extends Omit<AxiosRequestConfig, "data" | "cancelToken"> {
  securityWorker?: (
    securityData: SecurityDataType | null,
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
  secure?: boolean;
  format?: ResponseType;
}

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
  Text = "text/plain",
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance;
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private secure?: boolean;
  private format?: ResponseType;

  constructor({ securityWorker, secure, format, ...axiosConfig }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({ ...axiosConfig, baseURL: axiosConfig.baseURL || "" });
    this.secure = secure;
    this.format = format;
    this.securityWorker = securityWorker;
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected mergeRequestParams(params1: AxiosRequestConfig, params2?: AxiosRequestConfig): AxiosRequestConfig {
    const method = params1.method || (params2 && params2.method);

    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...((method && this.instance.defaults.headers[method.toLowerCase() as keyof HeadersDefaults]) || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected stringifyFormItem(formItem: unknown) {
    if (typeof formItem === "object" && formItem !== null) {
      return JSON.stringify(formItem);
    } else {
      return `${formItem}`;
    }
  }

  protected createFormData(input: Record<string, unknown>): FormData {
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key];
      const propertyContent: any[] = property instanceof Array ? property : [property];

      for (const formItem of propertyContent) {
        const isFileType = formItem instanceof Blob || formItem instanceof File;
        formData.append(key, isFileType ? formItem : this.stringifyFormItem(formItem));
      }

      return formData;
    }, new FormData());
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<AxiosResponse<T>> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const responseFormat = format || this.format || undefined;

    // NOTE: bug in the swagger-typescript-api; keep this line like this!
    if (type === ContentType.FormData && body && body !== null && typeof body === "object" && !(body instanceof FormData)) {
      body = this.createFormData(body as Record<string, unknown>);
    }

    if (type === ContentType.Text && body && body !== null && typeof body !== "string") {
      body = JSON.stringify(body);
    }

    return this.instance.request({
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        "Content-Type": type
      },
      params: query,
      responseType: responseFormat,
      data: body,
      url: path,
    });
  };
}

/**
 * @title rouvia
 * @version 0.1.0
 *
 * rouvia API
 */
export class Api<SecurityDataType extends unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  api = {
    /**
     * No description
     *
     * @tags users
     * @name GetUserById
     * @summary Get User By Id
     * @request GET:/api/v1/users/{user_id}
     * @secure
     */
    getUserById: (userId: string, params: RequestParams = {}) =>
      this.http.request<User, ApiError>({
        path: `/api/v1/users/${userId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags users
     * @name UpdateUser
     * @summary Update User
     * @request PUT:/api/v1/users/{user_id}
     * @secure
     */
    updateUser: (userId: string, data: UpdateUserRequest, params: RequestParams = {}) =>
      this.http.request<User, ApiError>({
        path: `/api/v1/users/${userId}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags users
     * @name DeleteUser
     * @summary Delete User
     * @request DELETE:/api/v1/users/{user_id}
     * @secure
     */
    deleteUser: (userId: string, params: RequestParams = {}) =>
      this.http.request<void, ApiError>({
        path: `/api/v1/users/${userId}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags users
     * @name GetUsersByOrganisationId
     * @summary Get Users By Organisation Id
     * @request GET:/api/v1/users/organisations/users
     * @secure
     */
    getUsersByOrganisationId: (params: RequestParams = {}) =>
      this.http.request<User[], ApiError>({
        path: `/api/v1/users/organisations/users`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags users
     * @name InviteUsers
     * @summary Invite Users
     * @request PUT:/api/v1/users/invite
     * @secure
     */
    inviteUsers: (data: InviteUserRequest, params: RequestParams = {}) =>
      this.http.request<void, ApiError>({
        path: `/api/v1/users/invite`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags users
     * @name EditRoles
     * @summary Edit Roles
     * @request PUT:/api/v1/users/{user_id}/edit-roles
     * @secure
     */
    editRoles: (userId: string, data: AssignUserRolesRequest, params: RequestParams = {}) =>
      this.http.request<void, ApiError>({
        path: `/api/v1/users/${userId}/edit-roles`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags users
     * @name ResetPassword
     * @summary Reset Password
     * @request PUT:/api/v1/users/{user_id}/password
     * @secure
     */
    resetPassword: (userId: string, data: UpdatePasswordRequest, params: RequestParams = {}) =>
      this.http.request<void, ApiError>({
        path: `/api/v1/users/${userId}/password`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags bookings
     * @name GetBookings
     * @summary Get all the Bookings ordered by collection date
     * @request GET:/api/v1/bookings
     * @secure
     */
    getBookings: (
      query?: {
        /**
         * Hasopentasks
         * Retrieve only the Bookings that have at least one Task that is not complete
         */
        hasOpenTasks?: boolean;
        /**
         * Hasexceptions
         * Retrieve only the Bookings that have at least one exception which is not resolved
         */
        hasExceptions?: boolean;
        /** Retrieve only the Bookings with any of the statuses provided in this parameter */
        status?: BookingStatus[];
        /**
         * Page
         * A page number to be returned. If the number is greater than current number of pages affected by the query the last available page is returned. E.g. page 5 is requested but the number of pages returned by query is 3 then page 3 is returned
         * @min 1
         * @default 1
         */
        page?: number;
        /**
         * Perpage
         * A number of elements contained within the page. Actual number of elements returned can be less. E.g. requesting a page of 100 elements when there are only 50 available
         * @min 1
         * @max 100
         * @default 50
         */
        perPage?: number;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<PaginatedResponseBooking, ApiError>({
        path: `/api/v1/bookings`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags bookings
     * @name CreateBooking
     * @summary Create Booking
     * @request POST:/api/v1/bookings
     * @secure
     */
    createBooking: (data: BookingCreationRequest, params: RequestParams = {}) =>
      this.http.request<Booking, ApiError>({
        path: `/api/v1/bookings`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags bookings
     * @name UpdateBookingCustomerInfo
     * @summary Update Booking Customer Info
     * @request PUT:/api/v1/bookings/{booking_id}/customer-info
     * @secure
     */
    updateBookingCustomerInfo: (
      bookingId: string,
      data: BookingCustomerDetailsInformationUpdateRequest,
      params: RequestParams = {},
    ) =>
      this.http.request<Booking, ApiError>({
        path: `/api/v1/bookings/${bookingId}/customer-info`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags bookings
     * @name GetBooking
     * @summary Get Booking
     * @request GET:/api/v1/bookings/{booking_id}
     * @secure
     */
    getBooking: (bookingId: string, params: RequestParams = {}) =>
      this.http.request<Booking, ApiError>({
        path: `/api/v1/bookings/${bookingId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags bookings
     * @name DeleteBooking
     * @summary Delete Booking
     * @request DELETE:/api/v1/bookings/{booking_id}
     * @secure
     */
    deleteBooking: (bookingId: string, params: RequestParams = {}) =>
      this.http.request<void, ApiError>({
        path: `/api/v1/bookings/${bookingId}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags bookings
     * @name AssignBookingToUser
     * @summary Assign Booking To User
     * @request PUT:/api/v1/bookings/{booking_id}/assignees
     * @secure
     */
    assignBookingToUser: (bookingId: string, data: BookingAssignmentRequest, params: RequestParams = {}) =>
      this.http.request<BookingAssignmentResponse, ApiError>({
        path: `/api/v1/bookings/${bookingId}/assignees`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Cancel Booking
     *
     * @tags bookings
     * @name CancelBooking
     * @summary Cancel Booking
     * @request DELETE:/api/v1/bookings/{booking_id}/cancel
     * @secure
     */
    cancelBooking: (bookingId: string, params: RequestParams = {}) =>
      this.http.request<void, ApiError>({
        path: `/api/v1/bookings/${bookingId}/cancel`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags documents
     * @name UploadDocument
     * @summary Upload new document to the booking
     * @request POST:/api/v1/bookings/{booking_id}/documents
     * @secure
     */
    uploadDocument: (
      bookingId: string,
      query: {
        /** An enumeration. */
        documentType: DocumentType;
      },
      data: UploadFile,
      params: RequestParams = {},
    ) =>
      this.http.request<Document, ApiError>({
        path: `/api/v1/bookings/${bookingId}/documents`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags documents
     * @name ReuploadDocument
     * @summary Reupload the document already attached to the booking
     * @request PUT:/api/v1/bookings/{booking_id}/documents/{document_id}
     * @secure
     */
    reuploadDocument: (bookingId: string, documentId: string, data: UploadFile, params: RequestParams = {}) =>
      this.http.request<Document, ApiError>({
        path: `/api/v1/bookings/${bookingId}/documents/${documentId}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags documents
     * @name DeleteDocument
     * @summary Delete the document already attached to the booking
     * @request DELETE:/api/v1/bookings/{booking_id}/documents/{document_id}
     * @secure
     */
    deleteDocument: (bookingId: string, documentId: string, params: RequestParams = {}) =>
      this.http.request<any, ApiError>({
        path: `/api/v1/bookings/${bookingId}/documents/${documentId}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags documents
     * @name GetDownloadLink
     * @summary Get a short-lived download link for the document
     * @request GET:/api/v1/bookings/{booking_id}/documents/{document_id}/download
     * @secure
     */
    getDownloadLink: (bookingId: string, documentId: string, params: RequestParams = {}) =>
      this.http.request<DocumentDownloadResponse, ApiError>({
        path: `/api/v1/bookings/${bookingId}/documents/${documentId}/download`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags activity_feed
     * @name ListFeedEntries
     * @summary Get all Activity Feed Entries (messages, events etc.) of the Booking
     * @request GET:/api/v1/bookings/{booking_id}/feed
     * @secure
     */
    listFeedEntries: (bookingId: string, params: RequestParams = {}) =>
      this.http.request<FeedEntry[], ApiError>({
        path: `/api/v1/bookings/${bookingId}/feed`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags activity_feed
     * @name PostMessage
     * @summary Post new message to Activity Feed of the Booking
     * @request POST:/api/v1/bookings/{booking_id}/feed
     * @secure
     */
    postMessage: (bookingId: string, data: PostMessageRequest, params: RequestParams = {}) =>
      this.http.request<FeedEntry, ApiError>({
        path: `/api/v1/bookings/${bookingId}/feed`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags notifications
     * @name GetNotifications
     * @summary Get Notifications
     * @request GET:/api/v1/notifications
     * @secure
     */
    getNotifications: (
      query?: {
        /**
         * Page
         * A page number to be returned. If the number is greater than current number of pages affected by the query the last available page is returned. E.g. page 5 is requested but the number of pages returned by query is 3 then page 3 is returned
         * @min 1
         * @default 1
         */
        page?: number;
        /**
         * Perpage
         * A number of elements contained within the page. Actual number of elements returned can be less. E.g. requesting a page of 100 elements when there are only 50 available
         * @min 1
         * @max 100
         * @default 50
         */
        perPage?: number;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<PaginatedResponseNotification, ApiError>({
        path: `/api/v1/notifications`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Mark all notifications for this user as read (in contrast to /bookings/{booking_id}/read, where only notifications regarding booking_id are read)
     *
     * @tags notifications
     * @name MarkAllNotificationsAsRead
     * @summary Mark All Notifications As Read
     * @request PUT:/api/v1/notifications/read
     * @secure
     */
    markAllNotificationsAsRead: (params: RequestParams = {}) =>
      this.http.request<Notification[], ApiError>({
        path: `/api/v1/notifications/read`,
        method: "PUT",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags notifications
     * @name MarkNotificationsAsRead
     * @summary Mark Notifications As Read
     * @request PUT:/api/v1/notifications/bookings/{booking_id}/read
     * @secure
     */
    markNotificationsAsRead: (bookingId: string, params: RequestParams = {}) =>
      this.http.request<Notification[], ApiError>({
        path: `/api/v1/notifications/bookings/${bookingId}/read`,
        method: "PUT",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags notifications, notification_settings
     * @name GetNotificationSettings
     * @summary Get Notification Settings
     * @request GET:/api/v1/notifications/settings/
     * @secure
     */
    getNotificationSettings: (params: RequestParams = {}) =>
      this.http.request<NotificationSettings, ApiError>({
        path: `/api/v1/notifications/settings/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags notifications, notification_settings
     * @name UpdateNotificationSettings
     * @summary Update Notification Settings
     * @request PUT:/api/v1/notifications/settings/
     * @secure
     */
    updateNotificationSettings: (data: NotificationSettings, params: RequestParams = {}) =>
      this.http.request<NotificationSettings, ApiError>({
        path: `/api/v1/notifications/settings/`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags quotes
     * @name SearchQuotes
     * @summary Search Quotes
     * @request POST:/api/v1/quotes/search
     * @secure
     */
    searchQuotes: (data: QuoteSearchRequest, params: RequestParams = {}) =>
      this.http.request<QuoteSearch, ApiError>({
        path: `/api/v1/quotes/search`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags quotes
     * @name GetQuotesSearchResult
     * @summary Get Quotes Search Result
     * @request GET:/api/v1/quotes/search/{search_id}
     * @secure
     */
    getQuotesSearchResult: (searchId: string, params: RequestParams = {}) =>
      this.http.request<QuoteSearch, ApiError>({
        path: `/api/v1/quotes/search/${searchId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags quotes
     * @name GetQuote
     * @summary Get Quote
     * @request GET:/api/v1/quotes/{quote_id}
     * @secure
     */
    getQuote: (quoteId: string, params: RequestParams = {}) =>
      this.http.request<Quote, ApiError>({
        path: `/api/v1/quotes/${quoteId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags analytics
     * @name GetDashboardStatistics
     * @summary Get Dashboard Statistics
     * @request GET:/api/v1/analytics/dashboard
     * @secure
     */
    getDashboardStatistics: (params: RequestParams = {}) =>
      this.http.request<DashboardStats, ApiError>({
        path: `/api/v1/analytics/dashboard`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags terminals
     * @name GetAllTerminals
     * @summary Get All Terminals
     * @request GET:/api/v1/terminals/
     */
    getAllTerminals: (params: RequestParams = {}) =>
      this.http.request<Terminal[], ApiError>({
        path: `/api/v1/terminals/`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags terminals
     * @name AddTerminal
     * @summary Add Terminal
     * @request POST:/api/v1/terminals/
     */
    addTerminal: (data: Terminal, params: RequestParams = {}) =>
      this.http.request<Terminal, ApiError>({
        path: `/api/v1/terminals/`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags terminals
     * @name GetTerminalById
     * @summary Get Terminal By Id
     * @request GET:/api/v1/terminals/{terminal_id}
     */
    getTerminalById: (terminalId: string, params: RequestParams = {}) =>
      this.http.request<Terminal, ApiError>({
        path: `/api/v1/terminals/${terminalId}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags terminals
     * @name UpdateTerminalById
     * @summary Update Terminal By Id
     * @request PUT:/api/v1/terminals/{terminal_id}
     */
    updateTerminalById: (terminalId: string, data: Terminal, params: RequestParams = {}) =>
      this.http.request<Terminal, ApiError>({
        path: `/api/v1/terminals/${terminalId}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags addresses
     * @name GetAllEmptyContainerDepotAddresses
     * @summary Get All Empty Container Depot Addresses
     * @request GET:/api/v1/addresses/empty_container_depot
     */
    getAllEmptyContainerDepotAddresses: (params: RequestParams = {}) =>
      this.http.request<EmptyContainerDepot[], ApiError>({
        path: `/api/v1/addresses/empty_container_depot`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
}
