import React, { useEffect } from 'react';
import { useGetUser } from 'utils/hooks/useGetUser';
import i18n from 'i18n';

type TProps = {
  children: React.ReactNode;
};

export const LangProvider: React.FC<TProps> = ({ children }) => {
  const [user] = useGetUser();

  useEffect(() => {
    const languagePreference = user?.languagePreference?.toLocaleLowerCase();

    if (languagePreference && languagePreference !== i18n.language) {
      i18n.changeLanguage(languagePreference);
    }
  }, [user.languagePreference]);

  return <React.Fragment>{children}</React.Fragment>;
};
