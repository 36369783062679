import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'react-oidc-context';
import { Link } from 'react-router-dom';

import { UserAvatar } from 'components/userAvatar';
import { useOnClickOutside } from 'utils/hooks/onClickOutside';
import { User } from 'generated/api';
import { useGetUser } from 'utils/hooks/useGetUser';
import { getFullName } from 'utils/misc';
import styles from './styles.module.scss';

export const UsersMenu: React.FC<{ user?: User }> = ({ user }) => {
  const { t } = useTranslation('default', { keyPrefix: 'components/userMenu' });
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef(null);
  const { signoutRedirect } = useAuth();
  const [, roles] = useGetUser();

  useOnClickOutside(menuRef, () => setIsOpen(false));

  const handleLogout = () => {
    signoutRedirect();
  };

  if (!user) {
    return null;
  }

  return (
    <div className={styles.root} ref={menuRef}>
      <div className={styles.contactCard} data-testid="UsersMenuClickable" onClick={() => setIsOpen(!isOpen)}>
        <UserAvatar user={user} size="medium" />
        <div className={styles.name}>{getFullName(user)}</div>
      </div>
      {isOpen && (
        <div className={styles.modal} data-testid="UsersMenuModal">
          <div className={styles.user}>
            <UserAvatar user={user} size="small" />
            <div className={styles.info}>
              <div className={styles.name}>{getFullName(user)}</div>
              <div className={styles.email}>{user.email}</div>
            </div>
          </div>

          <ul className={styles.menu}>
            <li>
              <Link className={styles.link} to="/profile">
                {t('profile')}
              </Link>
            </li>
            {roles.isAdmin && (
              <li>
                <Link className={styles.link} to="/team-management">
                  {t('teamManagement')}
                </Link>
              </li>
            )}
            <li>
              <button className={styles.link} onClick={handleLogout}>
                {t('logout')}
              </button>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};
