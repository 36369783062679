import React, { ReactNode, useState } from 'react';
import { flexRender, getCoreRowModel, useReactTable, getSortedRowModel } from '@tanstack/react-table';
import clsx from 'clsx';
import { Link, useNavigate } from 'react-router-dom';
import type { SortingState, ColumnDef } from '@tanstack/table-core';
import { ContentBox } from 'components/contentBox';

import { getSortIcon } from './utils';
import styles from './styles.module.scss';

type TProps<T> = {
  data: any[];
  columns: ColumnDef<T, any>[];
  generateRowLink?: (entity: any) => string;
  tableLabel?: string;
  filtersComponent?: ReactNode;
  className?: string;
  defaultSorting?: SortingState;
  paginatorComponent?: ReactNode | false;
};

// eslint-disable-next-line func-style,react/function-component-definition
export function Table<T>({
  data,
  columns,
  filtersComponent,
  tableLabel,
  defaultSorting = [],
  generateRowLink,
  className,
  paginatorComponent,
}: React.PropsWithChildren<TProps<T>>) {
  const [sorting, setSorting] = useState<SortingState>(defaultSorting);
  const navigate = useNavigate();
  const showTableHeader = Boolean(filtersComponent) || Boolean(tableLabel);

  const table = useReactTable<T>({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
  });

  const handleRowClick = (row: any) => () => {
    if (generateRowLink) {
      navigate(generateRowLink(row.original));
    }
  };

  return (
    <div className={className} data-testid="DynamicTableHeaderRoot">
      {showTableHeader && (
        <div data-testid="dynamicTableHeader">
          <ContentBox title={tableLabel}>
            {filtersComponent && <div className={styles.controls}>{filtersComponent}</div>}
          </ContentBox>
        </div>
      )}
      <div className={styles.tableWrapper}>
        <table className={styles.table}>
          <thead className={styles.thead}>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th className={styles.cell} key={header.id}>
                    {header.isPlaceholder ? null : (
                      <div onClick={header.column.getToggleSortingHandler()} className={styles.sortableCell}>
                        {flexRender(header.column.columnDef.header, header.getContext())}

                        {getSortIcon(header.column.getCanSort(), header.column.getIsSorted())}
                      </div>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => (
              <tr
                key={row.id}
                data-testid={`TableRow${row.index}`}
                onClick={handleRowClick(row)}
                className={clsx(styles.row, {
                  [styles.clickable]: Boolean(generateRowLink),
                })}
              >
                {row.getVisibleCells().map((cell, cellIndex) => (
                  <td className={styles.cell} key={cell.id} data-testid={`TableCell-row${row.index}-cell${cellIndex}`}>
                    {generateRowLink ? (
                      <Link to={generateRowLink(row.original)} className={styles.linkRow}>
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </Link>
                    ) : (
                      flexRender(cell.column.columnDef.cell, cell.getContext())
                    )}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
        {paginatorComponent}
      </div>
    </div>
  );
}
