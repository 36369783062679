import React from 'react';
import { Operator, OperatorDocument } from 'generated/api';
import { Route } from 'types/common';
import { SingleOperatorDocuments } from './singleOperatorTermsDocuments';

type TProps = {
  route: Route;
};

export const GeneralTermsDocuments = ({ route }: TProps) => {
  const listOfOperators: Array<{ name: string; documents?: OperatorDocument[] }> = [];

  const pushOperatorIfUnique = (operator: Operator) => {
    if (!listOfOperators.some((op) => op.name === operator.name)) {
      listOfOperators.push({ name: operator.name, documents: operator.documents });
    }
  };

  if (route.type === 'TRUCK') {
    if (route.truckLeg.operator) {
      pushOperatorIfUnique(route.truckLeg.operator);
    }
  } else if (route.type === 'RAIL') {
    if (route.firstMileLeg?.operator) {
      pushOperatorIfUnique(route.firstMileLeg.operator);
    }

    if (route.lastMileLeg?.operator) {
      pushOperatorIfUnique(route.lastMileLeg.operator);
    }

    if (route.railLeg.operator) {
      pushOperatorIfUnique(route.railLeg.operator);
    }
  }

  return (
    <div style={{ marginTop: 8 }}>
      {listOfOperators.map((op, index) => (
        <SingleOperatorDocuments key={index} name={op.name} documents={op.documents} extraIndex={index} />
      ))}
    </div>
  );
};
