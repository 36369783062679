import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { Booking } from 'generated/api';
import styles from 'components/map/styles.module.scss';
import { MapBadge } from 'components/map/mapBadge';
import { MapPopup } from 'components/map/mapPopup';
import { Tooltip } from 'components/tooltip';

type TProps = {
  bookings: Booking[];
};

export const UncoordinatedBadge: React.FC<TProps> = ({ bookings }) => {
  const { t } = useTranslation('default', { keyPrefix: 'components/map' });
  const [showPopup, setShowPopup] = useState<boolean>(false);

  const toggle = () => {
    setShowPopup(!showPopup);
  };

  const closePopup = () => {
    setShowPopup(false);
  };

  return (
    <div className={styles.uncoordinatedBadgeRoot}>
      <div className={styles.uncoordinatedBadgeTooltip}>
        <div onClick={toggle} data-testid="UncoordinatedBadgeBadgeBtn">
          <MapBadge bookings={bookings} />
        </div>
        <div className={styles.tooltip}>
          <Tooltip position="right" width={260} text={t('uncoordinated')} />
        </div>
      </div>
      {showPopup && (
        <div className={styles.uncoordinatedBadgePopup}>
          <MapPopup bookings={bookings} closePopup={closePopup} />
        </div>
      )}
    </div>
  );
};
