import React from 'react';
import InfiniteScroll, { Props as InfiniteScrollProps } from 'react-infinite-scroll-component';
import { Loader } from 'components/loader';

type TProps = {
  items: React.ReactNode[];
  scrollableTargetId: string;
} & Omit<InfiniteScrollProps, 'loader' | 'dataLength' | 'children'>;

export const CustomInfiniteScroll: React.FC<TProps> = ({ items, scrollableTargetId, ...rest }) => (
  <InfiniteScroll
    data-testid="InfiniteScroll"
    dataLength={items.length}
    loader={<Loader />}
    scrollableTarget={scrollableTargetId}
    {...rest}
  >
    {items}
  </InfiniteScroll>
);
