import React from 'react';
import { Address, EmptyContainerDepot, RailLeg, Terminal, TruckLeg } from 'generated/api';

export enum COLOR {
  BLUE = 'BLUE',
  PURPLE = 'PURPLE',
  ORANGE = 'ORANGE',
  RED = 'RED',
  GREEN = 'GREEN',
}

export enum NODE_TYPE {
  ORIGIN = 'ORIGIN',
  DESTINATION = 'DESTINATION',
  FIRST_MILE = 'FIRST_MILE',
  LAST_MILE = 'LAST_MILE',
  LOADING = 'LOADING',
  UNLOADING = 'UNLOADING',
  LONG_LEG = 'LONG_LEG',
  DELIVERY = 'DELIVERY',
  CONTAINER_PICKUP = 'CONTAINER_PICKUP',
  CONTAINER_DROPOFF = 'CONTAINER_DROPOFF',
}

export type OptionalContent = {
  Component: JSX.Element;
};

export type NodeRoad = {
  type: NODE_TYPE.ORIGIN | NODE_TYPE.DESTINATION;
  addressObj: Address | Terminal;
  optionalContent?: OptionalContent;
};

export type NodeMile = {
  type: NODE_TYPE.FIRST_MILE | NODE_TYPE.LAST_MILE;
  addressObj: Address | Terminal;
  departureDatetime?: string;
  arrivalDatetime?: string;
  mileLeg?: TruckLeg | RailLeg;
  serviceRequested?: boolean;
  optionalContent?: OptionalContent;
  cost?: number;
  isEmptyContainerOptionAvailable?: boolean;
};

export type NodeLoad = {
  type: NODE_TYPE.LOADING | NODE_TYPE.UNLOADING;
  operator: string;
  addressObj: Address | Terminal;
  terminal?: Terminal;
  departureDate?: string;
  earliestOrLatestTime?: string;
  serviceRequested?: boolean;
  optionalContent?: OptionalContent;
};

export type NodeLeg = {
  type: NODE_TYPE.LONG_LEG | NODE_TYPE.DELIVERY;
  carrier: string;
  trainNumber?: string;
  departureDatetime?: string;
  arrivalDatetime?: string;
  optionalContent?: OptionalContent;
};

export type NodeContainer = {
  type: NODE_TYPE.CONTAINER_PICKUP | NODE_TYPE.CONTAINER_DROPOFF;
  carrier: string;
  addressObj: Address | Terminal | EmptyContainerDepot;
  serviceRequested?: boolean;
  optionalContent?: OptionalContent;
};

export type ServicesState<ModalityServiceRequest> = {
  requestedServices: ModalityServiceRequest;
  setRequestedServices: React.Dispatch<React.SetStateAction<ModalityServiceRequest>>;
};
