export const downloadDocumentViaUrl = (data: Blob | MediaSource, documentName: string) => {
  const aElement = document.createElement('a');
  const href = URL.createObjectURL(data);

  aElement.setAttribute('download', documentName);
  aElement.href = href;
  aElement.setAttribute('target', '_blank');
  aElement.click();
  URL.revokeObjectURL(href);
};
