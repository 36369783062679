import React from 'react';
import { useTranslation } from 'react-i18next';
import { OperatorDocument, OperatorDocumentType } from 'generated/api';
import { Link } from 'components/link';
import styles from './styles.module.scss';

type TProps = {
  name: string;
  documents?: OperatorDocument[];
  extraIndex?: number;
};

export const SingleOperatorDocuments = ({ name, documents = [], extraIndex = 0 }: TProps) => {
  const { t } = useTranslation('default', { keyPrefix: 'components/generalTermsDocuments' });

  const GtcList = documents.filter((doc) => doc.type === OperatorDocumentType.GTC);
  const otherList = documents.filter((doc) => doc.type === OperatorDocumentType.OTHER);

  if (!documents.length || (!GtcList.length && !otherList.length)) {
    return null;
  }

  const extraLetter = String.fromCharCode('A'.charCodeAt(0) + extraIndex);

  return (
    <div className={styles.groupItem}>
      <div className={styles.name}>{name}</div>
      {Boolean(GtcList.length) && (
        <div>
          {t('gtc')}:&nbsp;
          {GtcList.map((doc, index) => (
            <Link key={`linkKey-${doc.url}`} className={styles.link}>
              <a href={doc.url} target="_blank" rel="noreferrer">
                {t('document')} {extraLetter}
                {index + 1}
              </a>
            </Link>
          ))}
        </div>
      )}
      {Boolean(otherList.length) && (
        <div>
          {t('other')}:&nbsp;
          {otherList.map((doc, index) => (
            <Link key={`linkKey-${doc.url}`} className={styles.link}>
              <a href={doc.url} target="_blank" rel="noreferrer">
                {t('document')} {extraLetter}
                {index + GtcList.length + 1}
              </a>
            </Link>
          ))}
        </div>
      )}
    </div>
  );
};
