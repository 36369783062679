import React from 'react';
import { useTranslation } from 'react-i18next';
import { PriceSheet } from 'components/priceSheet';
import { Booking } from 'generated/api';
import { ContentBox } from 'components/contentBox';
import styles from './styles.module.scss';

type TProps = {
  booking: Booking;
};

export const PriceInformation: React.FC<TProps> = ({ booking }) => {
  const { t } = useTranslation('default', {
    keyPrefix: 'components/orderOverview/priceInformation',
  });

  const { priceBreakdown, bookedServices } = booking;

  return (
    <ContentBox title={t('title')} contentClassName={styles.overview}>
      <PriceSheet priceBreakdown={priceBreakdown} requestedServices={bookedServices} />
    </ContentBox>
  );
};
