import { EquipmentType, Terminal } from 'generated/api';
import { TDefaultSelectOption } from 'types/common';
import { IGeocodeFeature } from 'types/geo';

export type TGeoOption = TDefaultSelectOption<IGeocodeFeature>;
export type TSelectOption<T> = { label: string; value: T } | string | null;
export type TLocationOption = TDefaultSelectOption<string | IGeocodeFeature | Terminal>;
export type TLocationPoints = 'origin' | 'destination';
export type TLocationEmptyContainerPoints = 'emptyContainerOrigin' | 'emptyContainerDestination';
export type TCommunityGoods = 'COMMUNITY_GOODS';
export type TEmptyGoods = 'EMPTY';
export type TCustomsGoods = 'CUSTOMS_GOODS';
export type TGoodsType = TCustomsGoods | TCommunityGoods | TEmptyGoods;
export type TAddressType = 'MARITIME' | 'RAIL' | 'WAREHOUSE';

export const EmptyGoods: TEmptyGoods = 'EMPTY';
export const CustomsGoods: TCustomsGoods = 'CUSTOMS_GOODS';
export const CommunityGoods: TCommunityGoods = 'COMMUNITY_GOODS';
export const GoodsTypeOptions = [CommunityGoods];
export const MAPBOX_SEARCH_MIN_CHARACTERS = 3;
export const MAPBOX_SEARCH_RESULTS_LIMIT = 6;

export interface ICustomerInfoFormValues {
  originAddress: TLocationOption | null;
  destinationAddress: TLocationOption | null;
  containerPickupAddress: TLocationOption | null;
  containerDropOffAddress: TLocationOption | null;
  deliveryDate: string;
  earliestDeliveryTime: string;
  latestDeliveryTime: string;
  collectionDate: string;
  earliestCollectionTime: string;
  latestCollectionTime: string;
  collectionDaysOffset: number;
  noDangerousGoods?: boolean;
}

export const customerInfoFormValues: ICustomerInfoFormValues = {
  earliestDeliveryTime: '07:00',
  latestDeliveryTime: '22:00',
  earliestCollectionTime: '07:00',
  latestCollectionTime: '22:00',
  collectionDaysOffset: 0,
  originAddress: null,
  destinationAddress: null,
  deliveryDate: '',
  collectionDate: '',
  noDangerousGoods: false,
  containerPickupAddress: null,
  containerDropOffAddress: null,
};

// Planning Shipment Form
export type TShipmentInfoFormValues = {
  equipment: {
    value: EquipmentType;
    label: string;
  } | null;
  loadingUnit: {
    value: string;
    label: string;
  } | null;
  size?: {
    value: string;
    label: string;
  } | null;
  unitProfile?: {
    value: string;
    label: string;
  } | null;
  netWeight: number;
  tareWeight: number;
  amount: number;
};

export type TFormArray = {
  shipmentInformation: TShipmentInfoFormValues[];
};

export const shipmentAppendFields = {
  equipment: null,
  loadingUnit: null,
  size: null,
  unitProfile: undefined,
  netWeight: 0,
  tareWeight: 0,
  amount: 1,
};

// Quotes Search
export interface ISearchForm extends ICustomerInfoFormValues {
  shipmentInformation: TShipmentInfoFormValues[];
}

export const SearchFormDefaultValues: ISearchForm = {
  ...customerInfoFormValues,
  shipmentInformation: [shipmentAppendFields],
};
