import React from 'react';
import clsx from 'clsx';
import { AlertSolidIcon, LateIcon } from '@rouvia/icons';
import type { TFunction } from 'i18next';
import type { Cell, Column, ColumnDef, Row, SortingState, Table } from '@tanstack/table-core';
import { Booking } from 'generated/api';
import { getAddressFromOnD } from 'utils/misc';
import { StatusPill } from 'components/badge/statusPill';
import { getMainOperator } from 'utils/shipment';
import { DATE_FORMAT, formatDate } from 'utils/date';
import styles from './styles.module.scss';

const accessorFnCarrier = (row: Booking) => getMainOperator(row.route)?.name || 'N/A';
const accessorFnTransport = (row: Booking) => row.origin;

export const testExports = {
  accessorFnCarrier,
  accessorFnTransport,
};

type TCell = {
  table: Table<Booking>;
  column: Column<Booking, unknown>;
  row: Row<Booking>;
  cell: Cell<Booking, unknown>;
  getValue: () => unknown;
  renderValue: () => unknown;
};

export const defaultSorting: SortingState = [
  {
    desc: true,
    id: 'created',
  },
];

export const getTableColumns = (t: TFunction): ColumnDef<Booking, unknown>[] => [
  {
    id: 'created',
    header: `${t('pages/bookings.table.created')}`,
    cell: (cell: TCell): JSX.Element => (
      <div className={styles.createdAt}>
        <div className={styles.createdAtYear}>
          {formatDate(cell.row.original.createdAt, DATE_FORMAT.MONTH_HALF_YEAR)}
        </div>
        <div className={styles.createdAtTime}>{formatDate(cell.row.original.createdAt, DATE_FORMAT.TIME_SIMPLE)}</div>
      </div>
    ),
    accessorFn: accessorFnCarrier,
    enableSorting: true,
  },
  {
    header: t('pages/bookings.table.orderN'),
    accessorKey: 'id',
    cell: (cell: TCell): JSX.Element => <div className={styles.bookingId}>{cell.getValue() as string}</div>,
    enableSorting: false,
  },
  {
    header: `${t('pages/bookings.table.origin')}`,
    enableSorting: false,
    cell: ({ cell }: TCell): JSX.Element => {
      const { route, origin } = cell.row.original;
      let mainLeg;

      if (route.type === 'RAIL') {
        mainLeg = route.railLeg;
      } else if (route.type === 'TRUCK') {
        mainLeg = route.truckLeg;
      }

      return (
        <div className={styles.transportInformationContainer}>
          <div className={styles.transportInformation}>
            <div data-testid="BookingsTable-origin">{getAddressFromOnD(origin).city}</div>
            <div>{mainLeg && formatDate(mainLeg.departureDatetime, DATE_FORMAT.DATE_MONTH_S_HOURS)}</div>
          </div>
        </div>
      );
    },
    accessorFn: accessorFnTransport,
  },
  {
    header: `${t('pages/bookings.table.destination')}`,
    enableSorting: false,
    cell: ({ cell }: TCell): JSX.Element => {
      const { route, destination } = cell.row.original;
      let mainLeg;

      if (route.type === 'RAIL') {
        mainLeg = route.railLeg;
      } else if (route.type === 'TRUCK') {
        mainLeg = route.truckLeg;
      }

      return (
        <div className={styles.transportInformationContainer}>
          <div className={clsx(styles.transportInformation)}>
            <div data-testid="BookingsTable-destination">{getAddressFromOnD(destination).city}</div>
            <div>{mainLeg && formatDate(mainLeg.arrivalDatetime, DATE_FORMAT.DATE_MONTH_S_HOURS)}</div>
          </div>
        </div>
      );
    },
    accessorFn: accessorFnTransport,
  },
  {
    id: 'carrier',
    header: `${t('pages/bookings.table.carrier')}`,
    cell: (cell: TCell): string => getMainOperator(cell.row.original.route)?.name || 'N/A',
    accessorFn: accessorFnCarrier,
    enableSorting: true,
  },
  {
    header: t('pages/bookings.table.fwId'),
    accessorKey: 'fwdId',
    enableSorting: false,
  },
  {
    header: t('pages/bookings.table.status'),
    accessorKey: 'status',
    cell: ({ cell }: TCell): JSX.Element => (
      <div className={styles.statusDiv}>
        <StatusPill status={cell.row.original.status} />
        {cell.row.original.isException && <AlertSolidIcon className={styles.alertIcon} />}
        {cell.row.original.isLate && <LateIcon className={styles.lateIcon} />}
      </div>
    ),
    enableSorting: false,
  },
];
