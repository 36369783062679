import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { ContainerIcon, TrainIcon, TruckIcon } from '@rouvia/icons';
import { ReactComponent as MarkerIcon } from 'assets/images/icons/bulletMarker.svg';

import { Booking, BookingStatus, Quote, ShipmentEvent, ShipmentEventName } from 'generated/api';
import { DATE_FORMAT, formatDate } from 'utils/date';
import { getTerminalNameOrAddress } from 'utils/misc';
import { getCalculatedSegmentProgress, TProgress } from 'components/progressTrack/util';
import { Colors } from 'scss/colors';
import styles from './styles.module.scss';

export const reversedOrderOfEvents: ShipmentEventName[] = [
  ShipmentEventName.EMPTY_CONTAINER_DROP_OFF_FINISHED,
  ShipmentEventName.EMPTY_CONTAINER_DROP_OFF_STARTED,
  ShipmentEventName.HANDED_OVER,
  ShipmentEventName.CHECKED_OUT_FROM_TERMINAL,
  ShipmentEventName.ARRIVED,
  ShipmentEventName.DEPARTED,
  ShipmentEventName.CHECKED_IN_AT_TERMINAL,
  ShipmentEventName.PICKED_UP,
  ShipmentEventName.EMPTY_CONTAINER_PICK_UP_FINISHED,
  ShipmentEventName.EMPTY_CONTAINER_PICK_UP_STARTED,
];

type ProgressController = {
  containerPickup: TProgress;
  firstLeg: TProgress;
  firstHalfMainLeg: TProgress;
  lastHalfMainLeg: TProgress;
  lastLeg: TProgress;
  containerDropOff: TProgress;
};

type ProgressStages = {
  [K in keyof ProgressController]: ShipmentEventName[];
};

const progressStages: ProgressStages = {
  containerPickup: [
    ShipmentEventName.EMPTY_CONTAINER_PICK_UP_STARTED,
    ShipmentEventName.EMPTY_CONTAINER_PICK_UP_FINISHED,
  ],
  firstLeg: [ShipmentEventName.PICKED_UP],
  firstHalfMainLeg: [ShipmentEventName.CHECKED_IN_AT_TERMINAL, ShipmentEventName.DEPARTED],
  lastHalfMainLeg: [ShipmentEventName.ARRIVED, ShipmentEventName.CHECKED_OUT_FROM_TERMINAL],
  lastLeg: [ShipmentEventName.HANDED_OVER],
  containerDropOff: [
    ShipmentEventName.EMPTY_CONTAINER_DROP_OFF_STARTED,
    ShipmentEventName.EMPTY_CONTAINER_DROP_OFF_FINISHED,
  ],
};

type TProps = {
  /**
   * The higher the object is in data hierarchy
   * the more data it can provide.
   * Quote: Basic route info with no progress
   * Booking: + empty container dropOff/pickup and progress based on events
   */
  entityWithQuote: Booking | Quote;
  isProgressDisabled?: boolean;
  isAllDetailsHidden?: boolean;
  details?: {
    isAdditionalIconsHidden?: boolean;
    isRailDetailsHidden?: boolean;
    isTimelineHidden?: boolean;
  };
};

export const ProgressTrack: React.FC<TProps> = ({
  entityWithQuote,
  isProgressDisabled,
  isAllDetailsHidden,
  details,
}) => {
  const isAdditionalIconsHidden = Boolean(isAllDetailsHidden || details?.isAdditionalIconsHidden);
  const isRailDetailsHidden = Boolean(isAllDetailsHidden || details?.isRailDetailsHidden);
  const isTimelineHidden = Boolean(isAllDetailsHidden || details?.isTimelineHidden);

  const { t } = useTranslation('default', {
    keyPrefix: 'components/progressTrack',
  });
  const [currentStatus, setCurrentStatus] = useState<BookingStatus | null>(null);
  const [events, setEvents] = useState<ShipmentEvent[]>([]);
  const [emptyContainerProcurement, setEmptyContainerProcurement] = useState({
    containerPickup: false,
    containerDropOff: false,
  });

  const quoteOrBooking: Quote | Booking = useMemo(() => {
    if ('quoteId' in entityWithQuote) {
      setEmptyContainerProcurement({
        containerPickup: Boolean(entityWithQuote.route.emptyContainerPickupLeg),
        containerDropOff: Boolean(entityWithQuote.route.emptyContainerDropoffLeg),
      });

      if (!isProgressDisabled) {
        setCurrentStatus(entityWithQuote.status);
        setEvents(entityWithQuote.events);
      }

      return entityWithQuote;
    } else {
      setEmptyContainerProcurement({
        containerPickup: Boolean(entityWithQuote.route.emptyContainerPickupLeg),
        containerDropOff: Boolean(entityWithQuote.route.emptyContainerDropoffLeg),
      });

      return entityWithQuote;
    }
  }, [entityWithQuote, isProgressDisabled]);

  const isMultiModal = quoteOrBooking.type === 'RAIL';
  let originLocation: string | null;
  let latestHandoverTime: string | null = null;
  let destinationLocation: string | null;
  let earliestPickupTime: string | null = null;
  let collectionTime = quoteOrBooking.route.collectionTime;
  let deliveryTime = quoteOrBooking.route.deliveryTime;

  if (isMultiModal) {
    const castedQuote = quoteOrBooking;

    latestHandoverTime = castedQuote.route.railLeg.latestHandoverTime;
    earliestPickupTime = castedQuote.route.railLeg.earliestPickupTime;

    if (castedQuote.route.firstMileLeg) {
      originLocation = getTerminalNameOrAddress(castedQuote.route.firstMileLeg.destination);
    } else {
      originLocation = getTerminalNameOrAddress(castedQuote.origin);
    }

    if (castedQuote.route.lastMileLeg) {
      destinationLocation = getTerminalNameOrAddress(castedQuote.route.lastMileLeg.origin);
    } else {
      destinationLocation = getTerminalNameOrAddress(castedQuote.destination);
    }
  } else {
    const castedQuote = quoteOrBooking;

    originLocation = getTerminalNameOrAddress(castedQuote.origin);
    destinationLocation = getTerminalNameOrAddress(castedQuote.destination);
  }

  const controller = useMemo(() => {
    let isFirstLeg: boolean = false;
    let isLastLeg: boolean = false;
    let leftGrid: string;
    let rightGrid: string;

    if (isMultiModal) {
      const castedQuote = quoteOrBooking;

      if (castedQuote.route.firstMileLeg) {
        isFirstLeg = true;
        leftGrid = styles.smallToBig2;

        if (emptyContainerProcurement.containerPickup) {
          leftGrid = styles.smallToBig3;
        }
      } else {
        leftGrid = styles.fullLength;
      }

      if (castedQuote.route.lastMileLeg) {
        isLastLeg = true;
        rightGrid = styles.bigToSmall2;

        if (emptyContainerProcurement.containerDropOff) {
          rightGrid = styles.bigToSmall3;
        }
      } else {
        rightGrid = styles.fullLength;
      }
    } else {
      if (emptyContainerProcurement.containerPickup) {
        leftGrid = styles.smallToBig2;
      } else {
        leftGrid = styles.fullLength;
      }

      if (emptyContainerProcurement.containerDropOff) {
        rightGrid = styles.bigToSmall2;
      } else {
        rightGrid = styles.fullLength;
      }
    }

    let currentEvent: ShipmentEventName | 'ALL_SCHEDULED' | undefined = undefined;
    const shared = {
      isProgressMarked: false,
    };

    if (events.every((e) => !e.isCompleted)) {
      currentEvent = 'ALL_SCHEDULED';
    } else {
      for (let index = 0; index < reversedOrderOfEvents.length; index++) {
        const foundEvent = events.find(
          (providedEvent) => providedEvent.name === reversedOrderOfEvents[index] && providedEvent.isCompleted,
        );

        if (foundEvent) {
          currentEvent = foundEvent.name;
          break;
        }
      }
    }

    const calcBooking = 'events' in quoteOrBooking ? quoteOrBooking : null;

    const containerDropOff = getCalculatedSegmentProgress(
      styles.BLUE,
      progressStages.containerDropOff,
      { prev: null, options: { isReverseEnabled: true, isProgressEnabled: Boolean(currentStatus) }, shared },
      currentEvent || null,
      calcBooking,
    );
    const lastLeg = getCalculatedSegmentProgress(
      styles.BLUE,
      progressStages.lastLeg,
      {
        prev: containerDropOff,
        options: { isReverseEnabled: true, isProgressEnabled: Boolean(currentStatus) },
        shared,
      },
      currentEvent || null,
      calcBooking,
    );
    const lastHalfMainLeg = getCalculatedSegmentProgress(
      isMultiModal ? styles.PURPLE : styles.BLUE,
      progressStages.lastHalfMainLeg,
      {
        prev: lastLeg,
        options: { isReverseEnabled: isLastLeg, isProgressEnabled: Boolean(currentStatus) },
        shared,
      },
      currentEvent || null,
      calcBooking,
    );
    const firstHalfMainLeg = getCalculatedSegmentProgress(
      isMultiModal ? styles.PURPLE : styles.BLUE,
      progressStages.firstHalfMainLeg,
      {
        prev: lastHalfMainLeg,
        options: { isProgressEnabled: Boolean(currentStatus), isFirstPoint: !isFirstLeg },
        shared,
      },
      currentEvent || null,
      calcBooking,
    );
    const firstLeg = getCalculatedSegmentProgress(
      styles.BLUE,
      progressStages.firstLeg,
      {
        prev: firstHalfMainLeg,
        options: {
          isProgressEnabled: Boolean(currentStatus),
          isFirstPoint: isFirstLeg && !emptyContainerProcurement.containerPickup,
        },
        shared,
      },
      currentEvent || null,
      calcBooking,
    );

    const containerPickup = getCalculatedSegmentProgress(
      styles.BLUE,
      progressStages.containerPickup,
      {
        prev: firstLeg,
        options: {
          isProgressEnabled: Boolean(currentStatus),
          isFirstPoint: emptyContainerProcurement.containerPickup,
        },
        shared,
      },
      currentEvent || null,
      calcBooking,
    );

    const progressController: ProgressController = {
      containerDropOff,
      lastLeg,
      lastHalfMainLeg,
      firstHalfMainLeg,
      firstLeg,
      containerPickup,
    };

    return { isFirstLeg, isLastLeg, leftGrid, rightGrid, progressController };
  }, [emptyContainerProcurement, events, isMultiModal, currentStatus, quoteOrBooking]);

  const getPoint = (current: boolean, color?: string, reverse?: boolean) =>
    current ? (
      <MarkerIcon className={clsx(styles.markerIcon, color, { [styles.floatRight]: reverse })} />
    ) : (
      <div className={clsx(styles.dot, color, { [styles.floatRight]: reverse, [styles.compact]: currentStatus })} />
    );

  return (
    <div className={styles.root}>
      {!isTimelineHidden && (
        <div className={styles.timelineGrid}>
          <div className={styles.timelineBox}>
            <div className={styles.timelineDay}>{formatDate(collectionTime, DATE_FORMAT.WEEKDAY_DATE)}</div>
            <div className={styles.timelineTime}>{formatDate(collectionTime, DATE_FORMAT.TIME_SIMPLE)}</div>
          </div>
          <div className={clsx(styles.timelineBox, styles.inverted)}>
            <div className={styles.timelineDay}>{formatDate(deliveryTime, DATE_FORMAT.WEEKDAY_DATE)}</div>
            <div className={styles.timelineTime}>{formatDate(deliveryTime, DATE_FORMAT.TIME_SIMPLE)}</div>
          </div>
        </div>
      )}
      <div className={clsx(styles.gridRoot, { [styles.compact]: currentStatus })}>
        <div className={clsx(styles.legGrid, controller.leftGrid, { [styles.compact]: currentStatus })}>
          {emptyContainerProcurement.containerPickup && (
            <div className={clsx(styles.leg, styles.BLUE)}>
              {getPoint(
                controller.progressController.containerPickup.bulletActive,
                controller.progressController.containerPickup.bulletColor,
              )}
              <div
                className={clsx(styles.line, styles.shiftedToRight, {
                  [styles.compact]: currentStatus,
                  [styles.inProgress]: controller.progressController.containerPickup.lineInProgress,
                })}
              />
              {!isAdditionalIconsHidden && (
                <div className={styles.legContent}>
                  <ContainerIcon className={styles.truckLegIcon} />
                </div>
              )}
            </div>
          )}
          {controller.isFirstLeg && (
            <div className={clsx(styles.leg, controller.progressController.firstLeg.colorProgress, {})}>
              {getPoint(
                controller.progressController.firstLeg.bulletActive,
                controller.progressController.firstLeg.bulletColor,
              )}
              <div
                className={clsx(styles.line, styles.shiftedToRight, {
                  [styles.compact]: currentStatus,
                  [styles.inProgress]: controller.progressController.firstLeg.lineInProgress,
                })}
              />
              {!isAdditionalIconsHidden && (
                <div className={styles.legContent}>
                  <TruckIcon className={styles.truckLegIcon} />
                </div>
              )}
            </div>
          )}
          <div className={clsx(styles.leg, controller.progressController.firstHalfMainLeg.colorProgress)}>
            {getPoint(
              controller.progressController.firstHalfMainLeg.bulletActive,
              controller.progressController.firstHalfMainLeg.bulletColor,
            )}
            <div
              className={clsx(styles.line, {
                [styles.compact]: currentStatus,
                [styles.inProgress]: controller.progressController.firstHalfMainLeg.lineInProgress,
              })}
            />
            {!isTimelineHidden && (
              <div className={styles.legContent}>
                <div className={styles.columnGrid}>
                  <div className={styles.city}>{originLocation}</div>
                  {latestHandoverTime && !isRailDetailsHidden && (
                    <div className={styles.terminalTime}>
                      <div>{t('latestHandoverTime')}:</div>
                      <div className={styles.strong}>
                        {formatDate(latestHandoverTime, DATE_FORMAT.WEEKDAY_DATE_MONTH_TIME)}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
        <div className={styles.iconWrapper}>
          {isMultiModal ? (
            <TrainIcon style={{ color: Colors.special04 }} />
          ) : (
            <TruckIcon style={{ color: Colors.blue01 }} />
          )}
        </div>
        <div className={clsx(styles.legGrid, controller.rightGrid, { [styles.compact]: currentStatus })}>
          <div className={clsx(styles.leg, controller.progressController.lastHalfMainLeg.colorProgress)}>
            {getPoint(
              controller.progressController.lastHalfMainLeg.bulletActive,
              controller.progressController.lastHalfMainLeg.bulletColor,
              true,
            )}
            <div
              className={clsx(styles.line, {
                [styles.compact]: currentStatus,
                [styles.inProgress]: controller.progressController.lastHalfMainLeg.lineInProgress,
              })}
            />
            {!isTimelineHidden && (
              <div className={clsx(styles.legContent, styles.inverted)}>
                <div className={styles.columnGrid}>
                  <div className={styles.city}>{destinationLocation}</div>
                  {earliestPickupTime && !isRailDetailsHidden && (
                    <div className={styles.terminalTime}>
                      <div>{t('earliestPickupTime')}:</div>
                      <div className={styles.strong}>
                        {formatDate(earliestPickupTime, DATE_FORMAT.WEEKDAY_DATE_MONTH_TIME)}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
          {controller.isLastLeg && (
            <div className={clsx(styles.leg, controller.progressController.lastLeg.colorProgress)}>
              {getPoint(
                controller.progressController.lastLeg.bulletActive,
                controller.progressController.lastLeg.bulletColor,
                true,
              )}
              <div
                className={clsx(styles.line, styles.shiftedToLeft, {
                  [styles.compact]: currentStatus,
                  [styles.inProgress]: controller.progressController.lastLeg.lineInProgress,
                })}
              />
              {!isAdditionalIconsHidden && (
                <div className={clsx(styles.legContent, styles.inverted)}>
                  <TruckIcon className={styles.truckLegIcon} />
                </div>
              )}
            </div>
          )}
          {emptyContainerProcurement.containerDropOff && (
            <div className={clsx(styles.leg, controller.progressController.containerDropOff.colorProgress)}>
              {getPoint(
                controller.progressController.containerDropOff.bulletActive,
                controller.progressController.containerDropOff.bulletColor,
                true,
              )}
              <div
                className={clsx(styles.line, styles.shiftedToLeft, {
                  [styles.compact]: currentStatus,
                  [styles.inProgress]: controller.progressController.containerDropOff.lineInProgress,
                })}
              />
              {!isAdditionalIconsHidden && (
                <div className={clsx(styles.legContent, styles.inverted)}>
                  <ContainerIcon className={styles.truckLegIcon} />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
