import React, { type MouseEvent, useState } from 'react';
import clsx from 'clsx';
import { EyeIcon, EyeSlashIcon, MinusIcon, PlusIcon } from '@rouvia/icons';
import { Tooltip } from 'components/tooltip';
import { TextFieldMeta } from 'components/textFieldMeta';

import styles from './styles.module.scss';

export type IBasicInput = React.InputHTMLAttributes<HTMLInputElement>;

type TProps = {
  label?: string;
  error?: string;
  onChange: (value: string) => void;
  attachmentLeft?: JSX.Element;
  attachmentRight?: JSX.Element;
  hint?: string;
  className?: string;
  min?: number | string;
  max?: number | string;
  testId?: string;
  showRequired?: boolean;
  tooltip?: string;
} & Partial<Omit<IBasicInput, 'onChange' | 'className'>>;

export const Input = React.forwardRef<HTMLInputElement, TProps>(
  (
    {
      label,
      placeholder,
      error,
      value = '',
      name,
      onChange,
      attachmentLeft,
      attachmentRight,
      hint,
      disabled,
      onFocus,
      onBlur,
      type = 'text',
      className,
      max,
      min,
      testId = 'BaseInput',
      showRequired,
      tooltip,
      ...rest
    },
    ref,
  ) => {
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const isNumberInput = type === 'number';

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      let val = e?.target?.value;

      if (isNumberInput) {
        if (min !== undefined) {
          val = String(Math.max(Number(val), Number(min)));
        }

        if (max !== undefined) {
          val = String(Math.min(Number(val), Number(max)));
        }

        if (val) {
          if (Number(value) === 0 && Number(val) >= 10) {
            val = String(Number(val) / 10);
          } else {
            val = String(Number(val));
          }
        }
      }

      onChange(val);
    };

    const increase = () => {
      const newValue = Number(value) + 1;

      onChange(String(typeof max === 'number' ? Math.min(newValue, max) : newValue));
    };

    const handleParentClick = (e: MouseEvent<HTMLDivElement>) => {
      e.preventDefault();
    };

    const decrease = () => {
      const newValue = Number(value) - 1;

      onChange(String(typeof min === 'number' ? Math.max(newValue, min) : newValue));
    };

    const getLeftAttachment = () => {
      if (isNumberInput) {
        return (
          <button
            type="button"
            onClick={decrease}
            disabled={disabled}
            data-testid="numberInputDecrease"
            className={styles.numberControls}
          >
            <MinusIcon className={styles.icon} />
          </button>
        );
      }

      if (attachmentLeft) {
        return <div className={clsx(styles.attachment, styles.attachmentLeft)}>{attachmentLeft}</div>;
      }
    };

    const getRightAttachment = () => {
      if (isNumberInput) {
        return (
          <button
            type="button"
            onClick={increase}
            disabled={disabled}
            data-testid="numberInputIncrease"
            className={styles.numberControls}
          >
            <PlusIcon className={styles.icon} />
          </button>
        );
      }

      if (!attachmentRight && type !== 'password') {
        return null;
      }

      let tempAttachment = null;

      if (attachmentRight) {
        tempAttachment = attachmentRight;
      } else if (showPassword) {
        tempAttachment = <EyeIcon data-testid="BaseInputHidePassword" onClick={() => setShowPassword(false)} />;
      } else if (!showPassword) {
        tempAttachment = <EyeSlashIcon data-testid="BaseInputShowPassword" onClick={() => setShowPassword(true)} />;
      }

      return (
        <div className={clsx(styles.attachment, styles.attachmentRight, { [styles.password]: type === 'password' })}>
          {tempAttachment}
        </div>
      );
    };

    const getType = () => {
      if (type === 'password' && showPassword) {
        return 'text';
      }

      return type;
    };

    return (
      <div className={className} data-testid={`${testId}Root`}>
        <label className={styles.label} htmlFor={`input_${name}`}>
          {label && (
            <span data-testid="InputLabel" className={styles.labelText}>
              {label}
              {showRequired && <span className={styles.required}>*</span>}
              {tooltip && <Tooltip text={tooltip} />}
            </span>
          )}

          <div
            className={clsx(styles.inputWrapper, {
              [styles.withError]: error,
              [styles.disabled]: disabled,
              [styles.numberInput]: isNumberInput,
            })}
            onClick={handleParentClick}
          >
            {getLeftAttachment()}

            <input
              ref={ref}
              className={clsx(styles.input, { [styles.numberInput]: isNumberInput })}
              onChange={handleChange}
              value={value}
              disabled={disabled}
              autoComplete="off"
              type={getType()}
              name={name}
              id={`input_${name}`}
              onFocus={onFocus}
              onBlur={onBlur}
              placeholder={placeholder}
              data-testid={testId}
              data-hj-allow="true"
              {...rest}
            />
            {getRightAttachment()}
          </div>
        </label>

        {(error || hint) && <TextFieldMeta className={styles.meta} hint={hint} error={error} />}
      </div>
    );
  },
);

Input.displayName = 'Input';
