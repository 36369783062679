import React, { forwardRef, FunctionComponent, KeyboardEvent, useCallback, useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import { GroupBase } from 'react-select';
import { SmallCaretDownIcon } from '@rouvia/icons';
import { TextFieldMeta } from 'components/textFieldMeta';
import { Tooltip } from 'components/tooltip';
import { Loader } from 'components/loader';
import { useDebounce } from 'utils/hooks/useDebounce';
import { EditableSelectDropdown, TOption } from './editableSelectDropdown';
import styles from './styles.module.scss';

type TProps<Option> = {
  Icon?: FunctionComponent;
  iconClassName?: string;
  className?: string;
  error?: string;
  hint?: string;
  label?: string;
  tooltip?: string;
  isDisabled?: boolean;
  showRequired?: boolean;
  placeholder?: string;
  customTheme?: {
    width?: string;
    maxWidth?: string;
    minWidth?: string;
  };
  loadOptions?: (
    inputValue: string,
    callback: (options: GroupBase<unknown>[]) => void,
  ) => Promise<GroupBase<unknown>[]> | void;
  testId?: string;
  options?: Option[];
  value: Option | null;
  onChange: (newValue: Option) => void;
};

export const EditableSelect = forwardRef<any, TProps<TOption<unknown>>>(
  (
    {
      testId,
      Icon,
      iconClassName,
      placeholder,
      className,
      hint,
      error,
      label,
      tooltip,
      customTheme,
      showRequired,
      isDisabled = false,
      value,
      onChange,
      loadOptions,
      options,
    },
    ref,
  ) => {
    const [localInput, setLocalInput] = useState<string>(value?.label || '');
    const debouncedLocalInput = useDebounce(localInput, Boolean(loadOptions) ? 500 : 0);

    const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [loadedGroups, setLoadedGroups] = useState<GroupBase<unknown>[]>([]);
    const [focusedOption, setFocusedOption] = useState<null | number>(null);

    const [optionRefs, setOptionRefs] = useState(new Map());

    // Pass this function to EditableSelectDropdown
    const handleOptionRefChange = useCallback((newRefs: any) => {
      setOptionRefs(newRefs);
    }, []);

    useEffect(() => {
      // Resetting the input based on the value change
      if (value) {
        setLocalInput(value.label);
      } else if (value === null) {
        setLocalInput('');
      }
    }, [value]);

    useEffect(() => {
      // Loading Handling
      if (loadOptions && isDropdownOpen) {
        if (debouncedLocalInput.length) {
          setIsLoading(true);
          loadOptions(debouncedLocalInput, (data) => {
            setLoadedGroups(data);
            setIsLoading(false);
          });
        } else {
          setIsLoading(false);
        }
      }
    }, [loadOptions, debouncedLocalInput, isDropdownOpen]);

    const filteredOptions = useMemo(() => {
      if (!options) {
        return [];
      }

      if (!localInput) {
        return options;
      }

      return options.filter((option) => option.label.toLowerCase().includes(localInput.toLowerCase()));
    }, [localInput, options]);

    const reset = () => {
      setIsDropdownOpen(false);
      setLocalInput(value?.label || '');
      setFocusedOption(null);
    };

    const openDropdown = () => {
      setIsDropdownOpen(true);
    };

    const toggleDropdown = () => {
      if (isDisabled) {
        return;
      }

      if (isDropdownOpen) {
        reset();
      } else {
        openDropdown();
      }
    };

    let totalOptions = 0;

    if (loadedGroups) {
      loadedGroups.forEach((group) => {
        totalOptions += group.options.length;
      });
    } else {
      totalOptions = filteredOptions.length;
    }

    const scrollToElement = (option: number) => {
      const focusedRef = optionRefs.get(option);

      if (focusedRef) {
        focusedRef.scrollIntoView({ block: 'nearest', inline: 'center', behavior: 'smooth' });
      }

      setFocusedOption(option);
    };

    const handleKeyDown = (event: KeyboardEvent) => {
      if (!isDropdownOpen) {
        openDropdown();
      }

      if (event.key === 'ArrowDown') {
        event.preventDefault();

        scrollToElement(focusedOption === null ? 0 : Math.min(focusedOption + 1, totalOptions - 1));
      } else if (event.key === 'ArrowUp') {
        event.preventDefault();

        scrollToElement(focusedOption === null ? 0 : Math.max(focusedOption - 1, 0));
      } else if (event.key === 'Enter' || event.key === 'Tab') {
        event.preventDefault();
        if (options?.length) {
          if (focusedOption !== null) {
            onChange(options[focusedOption]);
          } else {
            onChange(options[0]);
          }

          setIsDropdownOpen(false);
        } else if (loadedGroups?.length) {
          const combinedOptions = loadedGroups.reduce(
            (accumulator: any[], currentGroup: GroupBase<any>) => accumulator.concat(currentGroup.options),
            [],
          );

          if (combinedOptions.length) {
            if (focusedOption !== null) {
              onChange(combinedOptions[focusedOption]);
            } else {
              onChange(combinedOptions[0] as TOption<unknown>);
            }
          }

          setIsDropdownOpen(false);
        }
      }
    };

    return (
      <div
        className={clsx(styles.customSelectRoot, className)}
        style={{
          width: customTheme?.width,
          minWidth: customTheme?.minWidth,
          maxWidth: customTheme?.maxWidth,
        }}
      >
        <div className={styles.labelWrapper}>
          {label && (
            <div className={styles.label} data-testid="SelectLabel">
              {label}
              {showRequired && <span className={styles.required}>*</span>}
            </div>
          )}
          {tooltip && (
            <div className={styles.tooltip}>
              <Tooltip text={tooltip} position="right" />
            </div>
          )}
        </div>
        <div
          data-testid={testId}
          className={clsx(styles.selectContainer, { [styles.disabled]: isDisabled, [styles.error]: error })}
          onClick={toggleDropdown}
        >
          {Icon && (
            <div className={clsx(styles.defaultIcon, iconClassName)}>
              <Icon />
            </div>
          )}
          <input
            className={styles.input}
            placeholder={placeholder}
            value={localInput}
            disabled={isDisabled}
            ref={ref}
            onKeyDown={handleKeyDown}
            onChange={(event) => setLocalInput(event.target.value)}
          />
          <div className={styles.flexCenter}>
            {isLoading ? (
              <div className={styles.loader}>
                <Loader />
              </div>
            ) : (
              <SmallCaretDownIcon className={clsx(styles.arrow, { [styles.dropdownOpen]: isDropdownOpen })} />
            )}
          </div>
          {isDropdownOpen && (
            <EditableSelectDropdown
              value={value}
              onChange={onChange}
              filteredOptions={filteredOptions}
              setDropdownOpen={setIsDropdownOpen}
              reset={reset}
              isLoading={isLoading}
              filteredGroupOptions={loadedGroups}
              focusedOption={focusedOption}
              loadingOptionsEnabled={Boolean(loadOptions)}
              onOptionRefChange={handleOptionRefChange}
            />
          )}
        </div>
        {(error || hint) && (
          <div className={styles.meta} data-testid="SelectMeta">
            <TextFieldMeta error={error} hint={hint} />
          </div>
        )}
      </div>
    );
  },
);

EditableSelect.displayName = 'EditableSelect';
