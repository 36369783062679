import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'components/link';
import { Modal } from 'components/modal';
import { FormattedEquipment } from 'components/orderOverview/formattedEquipment';
import {
  BookingEquipmentInformation,
  CargoItem,
  CommunityGoodsBookingEquipmentInformation,
  CustomsGoodsBookingEquipmentInformation,
} from 'generated/api';
import { ContentBox } from 'components/contentBox';
import styles from './styles.module.scss';

type TProps = {
  cargo: CargoItem[];
  equipmentInformation: BookingEquipmentInformation[];
};

export const EquipmentInformation: React.FC<TProps> = ({ cargo, equipmentInformation }) => {
  const { t } = useTranslation('default', {
    keyPrefix: 'components/orderOverview/equipmentInformation',
  });

  const [isModalVisible, setModalVisible] = useState(false);
  const [selectedCargo, setSelectedCargo] = useState<CargoItem>();
  const [selectedEquipment, setSelectedEquipment] = useState<
    CustomsGoodsBookingEquipmentInformation | CommunityGoodsBookingEquipmentInformation
  >();

  const handleModalOpen = (index: number) => {
    if (!cargo[index] || !equipmentInformation[index]) {
      return;
    }

    const equipInfo = equipmentInformation[index];

    if ('containerInformation' in equipInfo) {
      setSelectedEquipment(equipInfo);
    }

    setSelectedCargo(cargo[index]);
    setModalVisible(true);
  };

  const handleModalClose = () => {
    setModalVisible(false);
  };

  return (
    <ContentBox title={t('title')} contentClassName={styles.overview}>
      {cargo.map((item, key) => (
        <div key={key} className={styles.cargoItem}>
          <FormattedEquipment cargoItem={item} size="medium" />

          {Boolean(equipmentInformation.length) && (
            <Link theme="accent" underline="dashed" onClick={() => handleModalOpen(key)}>
              View
            </Link>
          )}
        </div>
      ))}

      {isModalVisible && (
        <Modal onClose={handleModalClose}>
          {selectedCargo && <FormattedEquipment cargoItem={selectedCargo} size="big" />}

          {selectedEquipment?.containerInformation.map((equipment, key) => (
            <div className={styles.equipmentInfo} key={key}>
              <div className={styles.title}>
                {selectedCargo?.equipmentType} {key + 1}
              </div>

              <div className={styles.properties}>
                <div>
                  <div className={styles.label}>{t('properties.containerNumber')}</div>
                  <div className={styles.labelValue}>{equipment.containerNumber}</div>
                </div>

                <div>
                  <div className={styles.label}>{t('properties.sealNumber')}</div>
                  <div className={styles.labelValue}>{equipment.sealNumber}</div>
                </div>

                <div>
                  <div className={styles.label}>{t('properties.originCountry')}</div>
                  <div className={styles.labelValue}>{equipment.originCountry}</div>
                </div>

                <div>
                  <div className={styles.label}>{t('properties.destinationCountry')}</div>
                  <div className={styles.labelValue}>{equipment.destinationCountry}</div>
                </div>
              </div>
            </div>
          ))}
        </Modal>
      )}
    </ContentBox>
  );
};
