import React from 'react';

import { Booking, BookingStatus } from 'generated/api';

import styles from './styles.module.scss';

const generateBgFromProgress = (bookings: Booking[]) => {
  const totalBookings = bookings.length;
  let totalScheduleBookings = 0;
  let totalLateBookings = 0;
  let totalExceptionBookings = 0;
  let totalFailedBookings = 0;
  let totalCompletedBookings = 0;
  let percentages = '';

  bookings.forEach((booking) => {
    if (booking.status === BookingStatus.PENDING || booking.status === BookingStatus.IN_PROGRESS) {
      totalScheduleBookings++;
    } else if (booking.isLate) {
      totalLateBookings++;
    } else if (booking.isException) {
      totalExceptionBookings++;
    } else if (booking.status === BookingStatus.FAILED) {
      totalFailedBookings++;
    } else if (booking.status === BookingStatus.COMPLETED) {
      totalCompletedBookings++;
    }
  });

  //IMPORTANT: KEEP THE ORDER INTACT
  const scheduledPercent = Math.trunc((100 * totalScheduleBookings) / totalBookings);
  const latePercent = scheduledPercent + Math.trunc((100 * totalLateBookings) / totalBookings);
  const exceptionPercent = latePercent + Math.trunc((100 * totalExceptionBookings) / totalBookings);
  const failedPercent = exceptionPercent + Math.trunc((100 * totalFailedBookings) / totalBookings);
  const completedPercent = failedPercent + Math.trunc((100 * totalCompletedBookings) / totalBookings);

  if (totalScheduleBookings) {
    percentages += `, #088d39 0% ${scheduledPercent}%`;
  }

  if (totalLateBookings) {
    percentages += `, #ffb649 ${scheduledPercent}%  ${latePercent}%`;
  }

  if (totalExceptionBookings) {
    percentages += `, #d71e1e ${latePercent}% ${exceptionPercent}%`;
  }

  if (totalFailedBookings) {
    percentages += `, #d71e1e ${exceptionPercent}% ${failedPercent}%`;
  }

  if (totalCompletedBookings) {
    percentages += `, #638df7 ${failedPercent}% ${completedPercent}%`;
  }

  return `linear-gradient(90deg${percentages})`;
};

type TProps = {
  bookings: Booking[];
  testid?: string;
};

export const MapBadge: React.FC<TProps> = ({ bookings, testid = 'mapBadgeRoot' }) => {
  if (!bookings?.length) {
    return null;
  }

  const isMultipleQuotes = bookings.length > 1;

  return (
    <div className={styles.mapBadgeRoot} style={{ background: generateBgFromProgress(bookings) }} data-testid={testid}>
      {isMultipleQuotes ? (
        <div className={styles.number} data-testid="mapBadge">
          {bookings.length}
        </div>
      ) : (
        <div className={styles.bookingIdIcon}>{bookings[0].id}</div>
      )}
    </div>
  );
};
