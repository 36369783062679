import clsx from 'clsx';
import React, { useState } from 'react';
import { CaretUpIcon } from '@rouvia/icons';
import styles from './styles.module.scss';

type TProps = {
  title?: string;
  header?: React.ReactNode;
  wrapperClassName?: string;
  contentClassName?: string;
  boxShadow?: boolean;
  roundedBtm?: boolean;
  isExpandable?: boolean;
  defaultExpanded?: boolean;
  border?: boolean;
  rightAdornment?: React.ReactNode;
  children?: React.ReactNode;
  testId?: string;
  bodyId?: string;
};

export const ContentBox: React.FC<TProps> = ({
  title,
  header,
  wrapperClassName,
  contentClassName,
  boxShadow,
  roundedBtm,
  border,
  isExpandable = false,
  defaultExpanded = true,
  rightAdornment,
  children,
  testId = 'ContentBoxWrapper',
  bodyId,
}) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(defaultExpanded);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div
      data-testid={testId}
      className={clsx(styles.contentBoxRoot, wrapperClassName, {
        [styles.boxShadow]: boxShadow && isExpanded,
        [styles.border]: border,
      })}
    >
      {title && (
        <div
          className={clsx(styles.title, { [styles.clickable]: isExpandable, [styles.rounded]: !isExpanded })}
          onClick={isExpandable ? toggleExpand : undefined}
        >
          <div data-testid="ContentBoxTitle">{title}</div>
          {rightAdornment}
          {isExpandable && <CaretUpIcon className={clsx(styles.icon, { [styles.closed]: !isExpanded })} />}
        </div>
      )}
      {header && <div className={styles.header}>{header}</div>}
      {isExpanded && (
        <div
          id={bodyId}
          data-testid="ContentBoxChildren"
          className={clsx(styles.content, contentClassName, { [styles.roundedBtm]: roundedBtm })}
        >
          {children}
        </div>
      )}
    </div>
  );
};
