import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'components/link';
import { Booking, CargoItem } from 'generated/api';
import { ContentBox } from 'components/contentBox';
import { addressStringBuilder, getAddressFromOnD, getCityOrTerminalName, getClientData, getFullName } from 'utils/misc';
import { DATE_FORMAT, formatDate, getDurationInHours } from 'utils/date';
import { Icon } from 'components/icon';
import { Tooltip } from 'components/tooltip';
import { getAddressIcon, getAddressType } from 'utils/planning';
import { FormattedEquipment } from './formattedEquipment';
import { StakeholdersModal } from './stakeholdersModal';
import styles from './styles.module.scss';

type TProps = {
  booking: Booking;
};

export const BookingOverview: React.FC<TProps> = ({ booking }) => {
  const { t } = useTranslation('default', {
    keyPrefix: 'components/orderOverview/overviewTable',
  });

  const [isModalVisible, setModalVisible] = useState(false);
  const { route, cargo } = booking;
  const isRailRoute = route.type === 'RAIL';
  const isTruckRoute = route.type === 'TRUCK';

  const showStakeholdersModal = () => {
    setModalVisible(true);
  };

  const originIcon = getAddressIcon(booking.origin);
  const destinationIcon = getAddressIcon(booking.destination);

  return (
    <ContentBox title={t('title')} contentClassName={styles.overview}>
      <div className={styles.doubleFlex}>
        <div className={styles.flexGroup}>
          <div className={styles.subheader}>{t('origin')}</div>
          <div className={styles.flexItem}>
            <span className={styles.label}>{t(`addressType.${getAddressType(booking.origin)}`)}</span>

            <span className={styles.labelHeader}>
              {originIcon && <Icon As={originIcon} />}
              {getCityOrTerminalName(booking.origin)}
            </span>

            <span className={styles.labelSubValue}>
              {addressStringBuilder(getAddressFromOnD(booking.origin), {
                keys: ['street', 'zip', 'city', 'country'],
              })}
            </span>
          </div>

          <div className={styles.flexItem}>
            <span className={styles.label}>{t('collectionDate')}:</span>
            <span className={styles.labelValue}>{formatDate(booking.route.collectionTime, DATE_FORMAT.COMPLETE)}</span>
          </div>

          {'railLeg' in booking.route && (
            <div className={styles.flexItem}>
              <span className={styles.label}>{t('latestHandover')}:</span>
              <span className={styles.labelValue}>
                {formatDate(booking.route.railLeg.latestHandoverTime, DATE_FORMAT.COMPLETE)}
              </span>
            </div>
          )}
        </div>

        <div className={styles.flexGroup}>
          <div className={styles.subheader}>{t('destination')}</div>
          <div className={styles.flexItem}>
            <span className={styles.label}>{t(`addressType.${getAddressType(booking.destination)}`)}</span>
            <span className={styles.labelHeader}>
              {destinationIcon && <Icon As={destinationIcon} />}
              {getCityOrTerminalName(booking.destination)}
            </span>

            <span className={styles.labelSubValue}>
              {addressStringBuilder(getAddressFromOnD(booking.destination), {
                keys: ['street', 'zip', 'city', 'country'],
              })}
            </span>
          </div>

          <div className={styles.flexItem}>
            <span className={styles.label}>{t('delivery')}:</span>
            <span className={styles.labelValue}>{formatDate(booking.route.deliveryTime, DATE_FORMAT.COMPLETE)}</span>
          </div>

          {'railLeg' in booking.route && (
            <div className={styles.flexItem}>
              <span className={styles.label}>{t('earliestPickup')}:</span>
              <span className={styles.labelValue}>
                {formatDate(booking.route.railLeg.earliestPickupTime, DATE_FORMAT.COMPLETE)}
              </span>
            </div>
          )}
        </div>
      </div>

      <div className={styles.doubleFlex}>
        <div className={styles.flexGroup}>
          <div className={styles.flexItem}>
            <span className={styles.label}>{t('placedBy')}:</span>
            <span className={styles.labelValue}>{getClientData(booking.client)}</span>
          </div>
          <div className={styles.flexItem}>
            <span className={styles.label}>{t('bookingDate')}</span>
            <span className={styles.labelValue}>{formatDate(booking.createdAt, DATE_FORMAT.COMPLETE)}</span>
          </div>
          <div className={styles.flexItem}>
            <span className={styles.label}>{t('internalId')}</span>
            <span className={styles.labelValue}>{booking.fwdId}</span>
          </div>
          <div className={styles.flexItem}>
            <span className={styles.label}>{t('stakeholders')}</span>
            {Boolean(booking.assignees.length) && (
              <span className={styles.labelValue}>
                {booking.assignees.map((user, index) => (
                  <span key={user.id}>
                    {index > 0 && ', '}
                    {getFullName(user)}
                  </span>
                ))}
              </span>
            )}
            <Link onClick={showStakeholdersModal} theme="accent" underline="dashed" className={styles.editStakeholders}>
              {t('editStakeholders')}
            </Link>
          </div>
        </div>

        <div className={styles.flexGroup}>
          <div className={styles.flexItem}>
            <span className={styles.label}>{t('carrier')}</span>
            <span className={styles.labelValue}>
              {isRailRoute && route.railLeg.operator.name}
              {isTruckRoute && route.truckLeg.operator.name}
            </span>
          </div>
          <div className={styles.flexItem}>
            <span className={styles.label}>{t('equipmentInfo')}</span>

            {cargo.map((cargoItem: CargoItem, index: number) => (
              <div className={styles.equipmentItemCompact} key={index}>
                <FormattedEquipment cargoItem={cargoItem} />
              </div>
            ))}
          </div>
          <div className={styles.flexItem}>
            <div className={styles.labelTaller}>
              {`${t('totalDeliveryTime')} `}
              <span className={styles.totalDuration}>{getDurationInHours(booking.route.duration)}</span>
            </div>
            <div className={styles.labelTaller}>
              {`${t('carbonSavings')} `}
              <span className={styles.carbon}>
                {booking.emissionsSaved}
                {' Kg'}
              </span>
              <Tooltip text={t('co2tooltip')} position="bottom" />
            </div>
          </div>
        </div>
      </div>
      {booking.additionalInformation.additionalNotes && (
        <div className={styles.notes}>
          <div className={styles.notesLabel}>{t('textAreaLabel')}</div>
          <div className={styles.content}>{booking.additionalInformation.additionalNotes}</div>
        </div>
      )}
      {isModalVisible && (
        <StakeholdersModal
          bookingId={booking.id}
          defaultStakeholders={booking.assignees}
          onClose={() => setModalVisible(false)}
        />
      )}
    </ContentBox>
  );
};
