import React, { useState } from 'react';
import clsx from 'clsx';

import { ClipboardTextIcon } from '@rouvia/icons';
import { UseFormGetValues, UseFormReset } from 'react-hook-form';
import { TemplatePopup } from 'components/planningTemplate/templatePopup';
import { ISearchForm } from 'constants/planning';
import styles from './styles.module.scss';

type TProps = {
  getValues: UseFormGetValues<ISearchForm>;
  reset: UseFormReset<ISearchForm>;
};
export const PlanningTemplate: React.FC<TProps> = ({ getValues, reset }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const toggleState = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={clsx(styles.root)}>
      <ClipboardTextIcon onClick={toggleState} className={styles.mainIcon} />
      {isOpen && <TemplatePopup getValues={getValues} reset={reset} closePopup={() => setIsOpen(false)} />}
    </div>
  );
};
