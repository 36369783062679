import React, { useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@tanstack/react-query';
import { MoreHorizontalIcon } from '@rouvia/icons';
import { ApiError, InviteUserRequest, User } from 'generated/api';
import { queryClient } from 'constants/api';
import { deleteUser, inviteUsers, organisationUsersQueryKey } from 'api/user';
import { Icon } from 'components/icon';
import ToastContext from 'components/toast';
import { Modal } from 'components/modal';
import { Button } from 'components/button';
import { ContextMenu } from 'components/contextMenu';
import styles from './styles.module.scss';

type TProps = {
  user: User;
};

export const Actions: React.FC<TProps> = ({ user }) => {
  const { t } = useTranslation('default', { keyPrefix: 'pages/orgManagement.components.actions' });
  const [isModalVisible, setModalVisible] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const { playErrorToast, playSuccessToast } = useContext(ToastContext);

  const actionsItems = useMemo(
    () => [
      { label: t('contextMenu.remove'), value: 'remove' },
      { label: t('contextMenu.reinvite'), value: 'reinvite' },
    ],
    [t],
  );

  const contextItems = useMemo(() => {
    if (!user.isVerified) {
      return actionsItems;
    }

    return [actionsItems[0]];
  }, [actionsItems, user.isVerified]);

  const { mutate: deleteUserMutation } = useMutation((userId: string) => deleteUser(userId), {
    onSettled: (_data, error) => {
      if (error) {
        playErrorToast();
      } else {
        playSuccessToast(t('toasts.deletion.success'));
      }

      queryClient.invalidateQueries([organisationUsersQueryKey]);
    },
  });

  const { mutate: inviteUsersMutation } = useMutation<void, ApiError, InviteUserRequest>(
    (data: InviteUserRequest) => inviteUsers(data),
    {
      onSettled: (_data, error) => {
        if (error) {
          playErrorToast();
        } else {
          playSuccessToast(t('toasts.invite.success'));
        }

        queryClient.invalidateQueries([organisationUsersQueryKey]);
      },
    },
  );

  const handleAction = (value: string) => {
    switch (value) {
      case 'remove':
        setModalVisible(true);
        break;

      case 'reinvite':
        inviteUsersMutation({ emails: [user.email] });
        break;

      default:
        break;
    }
  };

  const handleUserDeletion = () => {
    deleteUserMutation(user.id);
    setModalVisible(false);
  };

  const handleModalClose = () => {
    setModalVisible(false);
  };

  return (
    <ContextMenu
      contextClassName={styles.contextMenu}
      closeMenu={() => setIsOpen(false)}
      isOpen={isOpen}
      items={contextItems}
      onClickHandler={handleAction}
    >
      <Button theme="ghost" testId="ActionsMainBtn" onClick={() => setIsOpen(true)} iconOnly>
        <Icon As={MoreHorizontalIcon} />
      </Button>

      {isModalVisible && (
        <Modal testId="ActionsModal" title={t('deletionModal.title', { email: user.email })} onClose={handleModalClose}>
          <div className={styles.modalContent}>{t('deletionModal.text', { email: user.email })}</div>

          <div className={styles.actions}>
            <Button theme="accent" onClick={handleUserDeletion} testId="ActionsDeleteUserBtn">
              {t('deletionModal.remove')}
            </Button>
            <Button theme="neutral" onClick={handleModalClose} testId="ActionsCloseModalBtn">
              {t('deletionModal.cancel')}
            </Button>
          </div>
        </Modal>
      )}
    </ContextMenu>
  );
};
