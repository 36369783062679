import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { NoContent } from 'components/noContent';
import { Layout } from 'components/layout';
import { Table } from 'components/dynamicTable';
import { useGetBookings } from 'api/bookings';
import { FILTER_TYPE, TMultiSelectData, TSelectData } from 'components/filtersGenerator/types';
import { useDebounce } from 'utils/hooks/useDebounce';
import { getTableFilteredData } from 'pages/bookings/getTableFilteredData';
import { getAddressFromOnD } from 'utils/misc';
import { Paginator } from 'components/paginator';
import { getBookingStatusMultiSelectData } from 'constants/filters';
import { getMainOperator } from 'utils/shipment';
import { bookingRequestQuery, generateRowLink } from 'pages/bookings/utils';
import { FiltersGenerator } from 'components/filtersGenerator';
import { getTableColumns, defaultSorting } from './getTableColumns';
import styles from './styles.module.scss';

const DEFAULT_PER_PAGE = 10;

export const BookingsOverviewPage = () => {
  const { t } = useTranslation('default', { keyPrefix: 'pages/bookings' });
  const { t: globalT } = useTranslation('default');
  const columns = useMemo(() => getTableColumns(globalT), [globalT]);
  const [page, setPage] = useState<number>(1);
  const [filterSearch, setFilterSearch] = useState<string>('');
  const debouncedFilterSearch = useDebounce(filterSearch, 500);
  const [filterStatus, setFilterStatus] = useState<TMultiSelectData[]>(getBookingStatusMultiSelectData(globalT));
  const [filterCarrier, setFilterCarrier] = useState<TMultiSelectData[]>([]);
  const [filterOrigin, setFilterOrigin] = useState<TSelectData | null>(null);
  const [filterDestination, setFilterDestination] = useState<TSelectData | null>(null);
  //TODO: isFiltersActive is temporary and must be removed when the proper pagination is implemented by the backend
  // Including the useEffect and other connected elements with it
  const [isFiltersActive, setIsFiltersActive] = useState<boolean>(false);

  const bookingRequestQueryMemo = useMemo(
    () => ({
      ...bookingRequestQuery,
      perPage: isFiltersActive ? 100 : DEFAULT_PER_PAGE,
      page,
    }),
    [isFiltersActive, page],
  );

  const { data: bookings, isLoading, isError, error } = useGetBookings(bookingRequestQueryMemo);

  useEffect(() => {
    if (
      debouncedFilterSearch.length ||
      filterStatus.find((item) => item.selected) ||
      filterOrigin ||
      filterDestination
    ) {
      setPage(1);
      setIsFiltersActive(true);
    } else {
      setIsFiltersActive(false);
    }
  }, [debouncedFilterSearch.length, filterDestination, filterOrigin, filterStatus]);

  const listOfAddresses: TSelectData[] = useMemo(() => {
    let tempListOfAddresses = new Set<string>();

    if (bookings) {
      bookings.data.forEach((booking) => {
        tempListOfAddresses.add(getAddressFromOnD(booking.origin).city);
        tempListOfAddresses.add(getAddressFromOnD(booking.destination).city);
      });
    }

    return Array.from(tempListOfAddresses).map((address) => ({ value: address, label: address }));
  }, [bookings]);

  useEffect(() => {
    let tempListOfOperators = new Set<string>();

    if (bookings) {
      bookings.data.forEach((booking) => {
        const operatorName = getMainOperator(booking.route)?.name;

        if (!operatorName) {
          return;
        }

        tempListOfOperators.add(operatorName);
      });
    }

    setFilterCarrier((prevSelectedCarriers) =>
      Array.from(tempListOfOperators).map(
        (operator) =>
          prevSelectedCarriers.find((prevCarrier) => prevCarrier.value === operator && prevCarrier.selected) || {
            value: operator,
            label: operator,
            selected: false,
          },
      ),
    );
  }, [bookings]);

  const filteredData = useMemo(
    () =>
      getTableFilteredData(bookings?.data || [], {
        debouncedFilterSearch,
        filterStatus,
        filterCarrier,
        filterOrigin,
        filterDestination,
      }),
    [bookings, debouncedFilterSearch, filterCarrier, filterStatus, filterOrigin, filterDestination],
  );

  if (isError || isLoading || !bookings) {
    return (
      <NoContent
        error={error}
        isError={isError}
        isLoading={isLoading}
        title={t('noContent.title')}
        testId="BookingsNoContent"
      />
    );
  }

  return (
    <Layout title={t('title')} testId="BookingsPageLayout">
      <div className={styles.filters}>
        <FiltersGenerator
          className={styles.filterGroup}
          filters={[
            {
              name: t('table.status'),
              controls: {
                type: FILTER_TYPE.MULTI_SELECT,
                data: filterStatus,
                setData: setFilterStatus,
              },
            },

            {
              name: t('table.origin'),
              controls: {
                type: FILTER_TYPE.SINGLE_SELECT,
                options: listOfAddresses,
                data: filterOrigin,
                setData: setFilterOrigin,
              },
            },
            {
              name: t('table.destination'),
              controls: {
                type: FILTER_TYPE.SINGLE_SELECT,
                options: listOfAddresses,
                data: filterDestination,
                setData: setFilterDestination,
              },
            },
          ]}
        />
        <FiltersGenerator
          className={styles.filterGroup}
          filters={[
            {
              name: t('table.searchAll'),
              controls: { type: FILTER_TYPE.SEARCH, data: filterSearch, setData: setFilterSearch },
            },
          ]}
        />
      </div>

      <Table
        data={filteredData}
        columns={columns}
        generateRowLink={generateRowLink}
        defaultSorting={defaultSorting}
        paginatorComponent={
          !isFiltersActive && (
            <Paginator
              currentPage={page}
              setCurrentPage={setPage}
              perPage={DEFAULT_PER_PAGE}
              totalElements={bookings.pagination.total}
            />
          )
        }
      />
    </Layout>
  );
};
