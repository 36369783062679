import React, { useMemo, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button } from 'components/button';
import { CustomSelect } from 'components/select';
import { documentTypes, DocumentTypeSelect } from 'constants/documents';
import { TDocumentUploadForm, TDocumentUploadRequest } from 'types/common';
import styles from './styles.module.scss';

type TProps = {
  callback: (data: TDocumentUploadRequest) => void;
  preselected?: DocumentTypeSelect;
};

export const UploadForm: React.FC<TProps> = ({ callback, preselected }) => {
  const { t } = useTranslation('default');
  const { t: docsT } = useTranslation('default', {
    keyPrefix: 'components/openTasksWidget',
  });

  const [file, setFile] = useState<FileList>();
  const [fileValue, setFileValue] = useState('');
  const { handleSubmit, control, register } = useForm<TDocumentUploadForm>();
  const options = useMemo(
    () => documentTypes.map((option) => ({ value: option.value, label: docsT(`types.${option.value}`) })),
    [docsT],
  );

  const onSubmit = (data: TDocumentUploadForm) => {
    callback({
      documentType: data.documentType.value,
      body: {
        file: file?.[0],
      },
    });
  };

  return (
    <form data-testid="UploadFormRoot" onSubmit={handleSubmit(onSubmit)} className={styles.rootUploadForm}>
      <Controller
        name="documentType"
        control={control}
        rules={{ required: t('common.validation.required') }}
        defaultValue={preselected}
        render={({ field, fieldState: { error } }) => (
          <CustomSelect options={options} error={error?.message} isDisabled={!!preselected} {...field} />
        )}
      />

      <div className={styles.fileSelector}>
        <label className={styles.label}>
          <input
            type="file"
            value={fileValue}
            className={styles.input}
            {...register('file')}
            onChange={(e) => {
              setFileValue(e.target.value);

              if (e.target.files) {
                setFile(e.target.files);
              }
            }}
          />
          {t('components/orderDocuments.chooseFile')}
        </label>

        <div className={styles.filename}>{file?.[0]?.name}</div>
      </div>

      <Button className={styles.submit} type="submit" theme="accent" disabled={!file}>
        {preselected ? t('components/orderDocuments.btnReUpload') : t('components/orderDocuments.btnUpload')}
      </Button>
    </form>
  );
};
