import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CheckCircleSolidIcon } from '@rouvia/icons';
import type { UseFormSetValue, Control, UseFormWatch, UseFormTrigger } from 'react-hook-form';
import { Button } from 'components/button';

import { ContentBox } from 'components/contentBox';
import { Link } from 'components/link';
import { Quote as IQuote } from 'generated/api';
import { kgToTons } from 'utils/common';
import { IForm } from '../../constants';
import { EquipmentInformationModal } from './equipmentInformationModal';
import styles from './styles.module.scss';

type TProps = {
  quote: IQuote;
  formWatch: UseFormWatch<IForm>;
  formControl: Control<IForm>;
  formTrigger: UseFormTrigger<IForm>;
  setFormValue: UseFormSetValue<IForm>;
  setEquipmentValidity: (state: boolean[]) => void;
};

export const EquipmentInformation: React.FC<TProps> = ({
  quote,
  formWatch,
  formControl,
  formTrigger,
  setFormValue,
  setEquipmentValidity,
}) => {
  const { t } = useTranslation('default', {
    keyPrefix: 'pages/quotes/components/equipmentInfo',
  });

  const [isModalOpen, setIsModalOpen] = useState<boolean>(true);
  const [validState, setValidState] = useState<boolean[]>([]);
  const [equipmentIndex, setEquipmentIndex] = useState<number>();

  const { cargo } = quote;

  const handleProvideInfo = (index: number) => {
    setEquipmentIndex(index);
    setIsModalOpen(true);
  };

  const setEquipmentState = (index: number, status: boolean) => {
    const copy = [...validState];

    copy[index] = status;
    setValidState(copy);
  };

  useEffect(() => {
    setEquipmentValidity(validState);
  }, [setEquipmentValidity, validState]);

  useEffect(() => {
    if (!validState.length) {
      setValidState(cargo.map(() => false));
    }
  }, [cargo, validState]);

  return (
    <ContentBox title={t('equipmentInfo')} contentClassName={styles.root}>
      {cargo.map((cargoItem, index) => (
        <div className={styles.item} key={index}>
          <div className={styles.innerFlex}>
            <div>{`${cargoItem.amount}x ${cargoItem.loadingUnit} (${cargoItem.size}", ${kgToTons(
              cargoItem.grossWeight,
            )}T)`}</div>
            {!validState[index] && <div className={styles.warning}>{t('needMoreInfo')}</div>}
          </div>
          <div className={styles.controls}>
            {validState[index] ? (
              <>
                <Link theme="accent" underline="dashed" onClick={() => handleProvideInfo(index)}>
                  {t('edit')}
                </Link>
                <CheckCircleSolidIcon className={styles.tick} />
              </>
            ) : (
              <Button
                onClick={() => handleProvideInfo(index)}
                testId="EquipmentInformationProvideInfoBtn"
                theme="accent"
              >
                {t('provideInfo')}
              </Button>
            )}
          </div>
        </div>
      ))}

      {cargo.map((item, index) => (
        <EquipmentInformationModal
          testId={`EquipmentInformationModal-${index}`}
          key={index}
          isOpen={isModalOpen && equipmentIndex === index}
          onClose={() => setIsModalOpen(false)}
          cargoItem={item}
          equipmentIndex={index}
          formControl={formControl}
          formWatch={formWatch}
          formTrigger={formTrigger}
          setFormValue={setFormValue}
          setEquipmentValidity={setEquipmentState}
        />
      ))}
    </ContentBox>
  );
};
