import React, { ReactNode } from 'react';
import clsx from 'clsx';
import { BookingStatus } from 'generated/api';
import styles from './styles.module.scss';

export type TBadgeAppearance =
  | 'ACCENT'
  | 'MUTED'
  | 'NEUTRAL'
  | 'SUCCESS'
  | 'WARNING'
  | 'DANGER'
  | 'BORDER'
  | BookingStatus;

export type TProps = {
  appearance: TBadgeAppearance;
  icon?: ReactNode;
  iconPosition?: 'left' | 'right';
  minWidth?: number;
  iconOnly?: boolean;
  onClick?: () => void;
  className?: string;
  children?: ReactNode;
};

export const Badge: React.FC<TProps> = ({
  children,
  appearance,
  icon,
  iconOnly,
  iconPosition = 'right',
  onClick,
  minWidth,
  className,
}) => (
  <div
    className={clsx(
      styles.badgeRoot,
      styles[appearance],
      {
        [styles.onClick]: onClick,
        [styles.iconPositionLeft]: iconPosition === 'left',
        [styles.iconOnly]: iconOnly,
      },
      className,
    )}
    style={{ minWidth }}
    onClick={onClick}
  >
    {!iconOnly && <div data-testid="BadgeContent">{children}</div>}
    {icon && <div className={clsx(styles.icon, { [styles.iconOnly]: iconOnly })}>{icon}</div>}
  </div>
);
