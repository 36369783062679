import React from 'react';
import { useTranslation } from 'react-i18next';
import { Layout } from 'components/layout';
import { NOTIFICATION_QUERY_KEY, useGetNotifications } from 'api/notifications';
import { Button } from 'components/button';
import { NotificationList } from 'components/notificationsWidget/notificationList';
import { SCREEN_SIZE } from 'constants/screenSize';
import { useExtractedItemsInfinite } from 'utils/hooks/useExtractedItemsInfinite';
import styles from './index.module.scss';

export const NotificationsPage = () => {
  const { t } = useTranslation('default', { keyPrefix: 'pages/notifications' });
  const {
    data: notificationsData,
    fetchNextPage,
    hasNextPage,
    isLoading,
    isFetching,
  } = useGetNotifications({ page: 1, key: NOTIFICATION_QUERY_KEY.NOTIFICATION_PAGE });

  const notifications = useExtractedItemsInfinite(notificationsData);

  const handleShowMore = () => {
    fetchNextPage();
  };

  return (
    <Layout title={t('title')} contentMaxWidth={SCREEN_SIZE.MD}>
      <div className={styles.root}>
        <NotificationList notifications={notifications} isLoading={isLoading} />
        {hasNextPage && !isLoading && (
          <Button className={styles.showMore} isLoading={isFetching} onClick={handleShowMore}>
            {t('showMore')}
          </Button>
        )}
      </div>
    </Layout>
  );
};
