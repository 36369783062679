import React, { useContext, useEffect, useState } from 'react';
import { Controller, useController, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { BinSimpleIcon, PlusIcon } from '@rouvia/icons';
import { ModalityLoader } from 'components/modalityLoader';

import { SCREEN_SIZE } from 'constants/screenSize';
import { useGetDepots, useGetTerminals } from 'api/terminal';
import { searchQuotes, useSearchQuotesById } from 'api/quotes';
import { Icon } from 'components/icon';
import { SectionHeading } from 'components/sectionHeading';
import { Button } from 'components/button';
import { Layout } from 'components/layout';
import { Link } from 'components/link';
import { Expandable } from 'components/expandable';
import { Checkbox } from 'components/checkbox';
import ToastContext from 'components/toast';
import { ISearchForm, SearchFormDefaultValues, shipmentAppendFields } from 'constants/planning';
import { ApiError, QuoteSearch, QuoteSearchRequest } from 'generated/api';
import { handleMappingData, prepareQuotesSearch } from 'utils/planning';
import { PlanningTemplate } from 'components/planningTemplate';
import { isExperimentalEnv } from 'constants/config';
import { CustomerInfoForm } from './components/customerForm';
import { ShipmentInfoForm } from './components/shipmentForm';
import styles from './index.module.scss';

export const PlanningPage = () => {
  const { t } = useTranslation('default', { keyPrefix: 'pages/planning' });
  const { t: commonT } = useTranslation('default', { keyPrefix: 'common' });
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(0);
  const { playApiErrorToast, playErrorToast } = useContext(ToastContext);
  const { data: terminals } = useGetTerminals();
  const { data: depots } = useGetDepots();
  const { id } = useParams();

  const { data: searchData, refetch } = useSearchQuotesById(id || '');

  const { mutate: quotesMutation, isLoading } = useMutation<QuoteSearch, ApiError, QuoteSearchRequest>(
    (formData: QuoteSearchRequest) => searchQuotes(formData),
    {
      onSuccess: (data: QuoteSearch) => {
        if (data.id) {
          navigate(`/quotes/search/${data.id}`);
        }
      },
      onError: (err: ApiError) => {
        playApiErrorToast(err);
      },
    },
  );

  const { getValues, control, resetField, handleSubmit, watch, setValue, reset, trigger } = useForm<ISearchForm>({
    defaultValues: SearchFormDefaultValues,
  });

  const watchedEquipmentIsFilled = watch('shipmentInformation.0.equipment');

  const { fields, append, remove } = useFieldArray<ISearchForm>({
    control,
    name: 'shipmentInformation',
  });

  useEffect(() => {
    if (id && !searchData) {
      refetch();
    } else if (!id) {
      reset(SearchFormDefaultValues);
    }
  }, [id, searchData, refetch, reset]);

  useEffect(() => {
    if (id && searchData) {
      handleMappingData(searchData.searchRequest, setValue, reset, terminals, depots);
    }
  }, [searchData, id, terminals, setValue, reset, depots]);

  const onSubmit = async (dataForm: ISearchForm) => {
    const requestData = prepareQuotesSearch(dataForm);

    if (requestData) {
      quotesMutation(requestData);
    } else {
      playErrorToast();
    }
  };

  const handleAddForm = () => {
    append(
      watchedEquipmentIsFilled
        ? { ...shipmentAppendFields, equipment: watchedEquipmentIsFilled }
        : shipmentAppendFields,
    );
    setActiveTab(fields.length);
  };

  const handleRemove = (index: number) => {
    const nextActive = index - 1;

    setActiveTab(nextActive >= 0 ? nextActive : 0);
    remove(index);
  };

  const handleReset = () => {
    reset(SearchFormDefaultValues);
  };

  const getActions = (index: number) => (
    <div className={styles.actions}>
      <Link theme="accent" onClick={() => setActiveTab(index)}>
        {t('edit')}
      </Link>
      <Icon As={BinSimpleIcon} onClick={() => handleRemove(index)} />
    </div>
  );

  const {
    fieldState: { error: formErrors },
  } = useController({
    name: 'shipmentInformation',
    control,
  });

  const shipmentFormHasErrors = (index: number) => {
    if (Array.isArray(formErrors) && index < formErrors.length) {
      return Boolean(formErrors[index]);
    }

    return false;
  };

  return (
    <Layout
      title={t('title')}
      contentMaxWidth={SCREEN_SIZE.MD}
      adornment={
        isExperimentalEnv ? (
          <div style={{ display: 'flex', flex: 1, justifyContent: 'flex-end' }}>
            <PlanningTemplate getValues={getValues} reset={reset} />
          </div>
        ) : undefined
      }
    >
      <form onSubmit={handleSubmit(onSubmit)} noValidate className={styles.content}>
        <div className={styles.formFields}>
          <CustomerInfoForm
            control={control}
            resetField={resetField}
            getValues={getValues}
            setValue={setValue}
            watch={watch}
            terminals={terminals}
            depots={depots}
            trigger={trigger}
          />

          <div className={styles.shipmentInfo}>
            <SectionHeading title={t('shipmentLabel')} description={t('shipmentDescription')} />

            <div className={styles.shipmentItems}>
              {fields.map((field, index) => (
                <Expandable
                  isActive={activeTab === index}
                  title={`${t('equipment')} ${index + 1}`}
                  key={field.id}
                  actions={getActions(index)}
                  hideActionsWhenActive
                  showError={shipmentFormHasErrors(index)}
                >
                  <ShipmentInfoForm
                    key={field.id}
                    control={control}
                    index={index}
                    setValue={setValue}
                    isEquipmentPrefilled={fields.length > 1 && Boolean(watchedEquipmentIsFilled)}
                  />

                  {fields.length > 1 && (
                    <Link
                      className={styles.remove}
                      size="large"
                      theme="accent"
                      icon={BinSimpleIcon}
                      onClick={() => handleRemove(index)}
                    >
                      {t('remove')}
                    </Link>
                  )}
                </Expandable>
              ))}
            </div>

            <Link
              disabled={!Boolean(watchedEquipmentIsFilled)}
              disabledTooltipText={t('selectEquipmentType')}
              underline="dashed"
              size="large"
              theme="accent"
              icon={PlusIcon}
              onClick={handleAddForm}
            >
              {t('add')}
            </Link>
          </div>
        </div>

        <div className={styles.confirmation}>
          <Controller
            name="noDangerousGoods"
            control={control}
            rules={{ required: commonT('validation.required') }}
            render={({ field, fieldState: { error } }) => (
              <Checkbox
                testId="noDangerousGoodsCheckbox"
                label={t('checkboxLabel')}
                {...field}
                value={undefined}
                checked={field.value}
                error={error?.message}
              />
            )}
          />
        </div>

        <div className={styles.footer}>
          <Link onClick={handleReset} underline="dashed">
            {t('clear')}
          </Link>

          <Button theme="accent" type="submit" size="large" disabled={isLoading} testId="searchButton">
            {t('submit')}
          </Button>
        </div>
      </form>

      {isLoading && <ModalityLoader />}
    </Layout>
  );
};
