import {
  Address,
  AddressCreationRequest,
  Client,
  EmptyContainerDepot,
  MessageAuthor,
  MessageRecipientType,
  Terminal,
  User,
  UserRole,
} from 'generated/api';
import { IGeocodeFeature } from 'types/geo';

export const extractError = (error: any): string => {
  if (typeof error === 'string') {
    return error;
  }

  if (typeof error?.message === 'string') {
    return error.message;
  }

  return 'Something went wrong, please try again.';
};

export const getAddressString = (address: AddressCreationRequest) =>
  `${address.street}, ${address.zip} ${address.city}, ${address.country}`;

export const extractAddress = (feature?: IGeocodeFeature): AddressCreationRequest => {
  if (!feature) {
    return {
      point: [0, 0],
      street: '',
      zip: '',
      city: '',
      country: '',
    };
  }

  const address: AddressCreationRequest = {
    point: [feature.center[0], feature.center[1]],
    street: `${feature.text}${feature.address ? ` ${feature.address}` : ''}`,
    zip: '',
    city: '',
    country: '',
  };

  feature.context.forEach((item) => {
    const id = item.id.substring(0, item.id.indexOf('.'));

    switch (id) {
      case 'postcode':
        address.zip = item.text;
        break;
      case 'country':
        address.country = item.text;
        break;
      case 'place':
        address.city = item.text;
    }
  });

  return address;
};

/**
 * Extract address keys to string with custom order and custom separator
 * @param address typeof Address
 * @param options typeof { keys: (keyof Address)[]; separator?: string }
 * @example:
 * * addressStringBuilder(route.destination), { keys: ['street', 'zip', 'country'] })
 * * result: "Am Kanal, 7122, Austria" (default separator ', ' applied)
 * @return string
 */
export const addressStringBuilder = (
  address: Address,
  options: { keys: (keyof Address)[]; separator?: string },
): string =>
  options.keys.reduce(
    (acc, curr, index) => acc + (index && acc.length ? options.separator || ', ' : '') + address[curr],
    '',
  );

export const getAddressFromOnD = (location: Address | Terminal | EmptyContainerDepot): Address => {
  if ('city' in location) {
    return location;
  } else {
    return location.address;
  }
};

export const getTerminalNameOrAddress = (location: Address | Terminal | EmptyContainerDepot): string => {
  if ('name' in location) {
    return location.name;
  } else if ('city') {
    return location.city;
  } else {
    return 'N/A';
  }
};

export const getCityOrTerminalName = (location: Address | Terminal): string => {
  if ('city' in location) {
    return location.city;
  } else {
    return location.name;
  }
};

export const escapeString = (str: string) => str.replace(/[|\\{}()[\]^$+*?.]/g, '\\$&').replace(/-/g, '\\x2d');

export const getFullName = (user: User): string => {
  if (user.firstName && user.lastName) {
    return `${user.firstName} ${user.lastName}`;
  }

  return user.firstName || user.lastName || user.email;
};

export const getAuthorName = (author: MessageAuthor) => {
  if (author.type === 'USER') {
    return getFullName(author);
  }

  if (author.type === 'OPS_TEAM') {
    return 'Support';
  }

  if (author.type === 'OPERATOR' || author.type === 'ROAD_CARRIER') {
    return author.name;
  }
};

export const getAuthorType = (author: MessageAuthor) => {
  if (author.type === 'USER') {
    return MessageRecipientType.INTERNAL_TEAM;
  }

  if (author.type === 'OPS_TEAM') {
    return MessageRecipientType.ROUVIA_CUSTOMER_SERVICE;
  }

  if (author.type === 'OPERATOR') {
    return MessageRecipientType.OPERATOR;
  }

  if (author.type === 'ROAD_CARRIER') {
    return MessageRecipientType.ROAD_CARRIER;
  }
};

export const getClientData = (client?: Client) => {
  if (!client) {
    return 'N/A';
  }

  return client.name;
};

export const validateEmail = (email = '') =>
  new RegExp(
    /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/,
  ).test(email);

export const isAdminUser = (user: User) => user.roles.includes(UserRole.Admin);

export const generateArrayFromEnums = (enums: Object[]) => {
  const finalArray: string[] = [];

  enums.forEach((singleEnum) => Object.values(singleEnum).forEach((singleValue) => finalArray.push(singleValue)));

  return finalArray;
};

export const round = (number: string | number, fractionDigits = 2) => {
  const value = number === 'string' ? number : number.toString();

  return parseFloat(value).toFixed(fractionDigits);
};

/* 1000 => 1 000 */
export const formatNumber = (value: number | string, separator = ' ') => {
  const number = typeof value === 'number' ? value.toString() : value;

  return number.replace(/(\d)(?=(\d{3})+$)/g, `$1${separator}`);
};

/**
 * 557 => "557 kg"
 * 39247910 => "39 248 t"
 */
export const getKgToTonString = (value: number): string => {
  if (value > 999) {
    return `${formatNumber(Math.round(value / 1000))} t`;
  }

  return `${value} kg`;
};

/*
 * Use to filter out false-like values from typed array
 * and keep TypeScript happy
 */
export const truthyFilter = <T>(x: T | false | undefined | null | '' | 0): x is T => !!x;
