import React from 'react';
import clsx from 'clsx';

import { CheckIcon } from '@rouvia/icons';
import { ShipmentEvent } from 'generated/api';
import { DATE_FORMAT, formatDate } from 'utils/date';

import styles from './styles.module.scss';

type TProps = {
  title: string;
  message?: string;
  ata?: string;
  eta?: string;
  stageStatus: ShipmentEvent;
  isLastItem?: boolean;
};

export const ListItem: React.FC<TProps> = ({ title, message, ata, eta, stageStatus, isLastItem }) => {
  const getIcon = () => {
    if (stageStatus.isCompleted) {
      return <CheckIcon className={styles.checkmark} data-testid="ListItemTitleIconCompleted" />;
    } else {
      return (
        <div className={clsx(styles.currentMark, styles[stageStatus.name])} data-testid="ListItemTitleIconCurrent" />
      );
    }
  };

  return (
    <li className={styles.listItem}>
      <div className={styles.iconDash}>
        {getIcon()}
        <div className={clsx(styles.dashLine, styles[stageStatus.name], { [styles.disableLine]: isLastItem })} />
      </div>
      <div className={styles.content}>
        <div className={styles.stageTitle} data-testid="ListItemTitle">
          {title}
        </div>
        {message !== 'NULL' && <div className={clsx(styles.stageMessage, styles[stageStatus.name])}>{message}</div>}
        <div className={styles.timing}>
          {eta && <span>ETA: {formatDate(eta, DATE_FORMAT.COMPLETE)}</span>}
          {ata && <span>ATA: {formatDate(ata, DATE_FORMAT.COMPLETE)}</span>}
        </div>
      </div>
    </li>
  );
};
