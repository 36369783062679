import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { useMutation } from '@tanstack/react-query';
import { BellIcon, CheckCircleIcon } from '@rouvia/icons';
import { ApiError, Notification } from 'generated/api';
import { markAllNotificationsAsRead, NOTIFICATION_QUERY_KEY } from 'api/notifications';
import { queryClient } from 'constants/api';
import { Loader } from 'components/loader';
import ToastContext from 'components/toast';
import { NotificationItem } from './notificationItem';
import styles from './style.module.scss';

type TProps = {
  notifications: Notification[];
  isLoading?: boolean;
  onClose?: () => void;
};

export const NotificationList: React.FC<TProps> = ({ notifications, isLoading, onClose }) => {
  const { t } = useTranslation('default', {
    keyPrefix: 'components/notificationsWidget',
  });

  const { playApiErrorToast } = useContext(ToastContext);

  const { mutate: markAllAsRead } = useMutation(() => markAllNotificationsAsRead(), {
    onSettled: () => {
      queryClient.invalidateQueries([NOTIFICATION_QUERY_KEY.NOTIFICATION_PAGE]);
      queryClient.invalidateQueries([NOTIFICATION_QUERY_KEY.NOTIFICATION_WIDGET]);
    },
    onError: (err: ApiError) => {
      playApiErrorToast(err);
    },
  });

  const statuses: { isEmpty: boolean; hasUnreadNotifications: boolean } = useMemo(
    () => ({
      isEmpty: Boolean(!notifications.length),
      hasUnreadNotifications: Boolean(notifications.find((n) => !n.read)),
    }),
    [notifications],
  );

  const handleMarkAllRead = () => {
    markAllAsRead();
  };

  const getContent = () => {
    if (isLoading) {
      return (
        <div className={styles.emptyNotifications}>
          <Loader />
        </div>
      );
    }

    if (!statuses.isEmpty) {
      return (
        <div className={styles.list}>
          {notifications.map((notification, index) => (
            <NotificationItem notification={notification} key={index} onClose={onClose} />
          ))}
        </div>
      );
    }

    return (
      <div className={styles.emptyNotifications} data-testid="notificationListEmpty">
        <BellIcon />
        <div className={styles.title}>{t('notificationsLiveHere')}</div>
        <div className={styles.subtitle}>{`${t('noNotifications')}.`}</div>
      </div>
    );
  };

  return (
    <div className={styles.notificationList}>
      <div className={styles.header}>
        <div>{t('notifications')}</div>
        <div
          className={clsx(styles.markAsRead, {
            [styles.disabled]: statuses.isEmpty || !statuses.hasUnreadNotifications,
          })}
          onClick={statuses.isEmpty ? undefined : handleMarkAllRead}
        >
          {t('markAsRead')} <CheckCircleIcon className={styles.checkmark} />
        </div>
      </div>
      {getContent()}
    </div>
  );
};
