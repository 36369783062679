import React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import styles from './styles.module.scss';

export type TProps = {
  label?: string;
  isActive: boolean | null;
  setIsActive: (b: boolean) => void;
  isActiveLabel?: string;
  isNotActiveLabel?: string;
  className?: string;
  testId?: string;
};

export const BooleanRadioButton: React.FC<TProps> = ({
  label,
  isActive,
  setIsActive,
  isActiveLabel,
  isNotActiveLabel,
  className,
  testId,
}) => {
  const { t } = useTranslation('default', {
    keyPrefix: 'components/booleanRadioButton',
  });

  const getDot = (active: boolean | null) => <div className={clsx(styles.dot, { [styles.active]: active === true })} />;

  return (
    <div className={clsx(styles.booleanRadioButtonRoot, className)} data-testid={testId}>
      {label && <div className={styles.label}>{label}</div>}
      <div className={styles.doubleGrid}>
        <div
          data-testid="activeOnTrue"
          onClick={() => setIsActive(true)}
          className={clsx(styles.radioButton, { [styles.active]: isActive === true })}
        >
          {getDot(isActive)}
          {isActiveLabel || t('yes')}
        </div>
        <div
          data-testid="activeOnFalse"
          onClick={() => setIsActive(false)}
          className={clsx(styles.radioButton, { [styles.active]: isActive === false })}
        >
          {getDot(isActive === null ? isActive : !isActive)}
          {isNotActiveLabel || t('no')}
        </div>
      </div>
    </div>
  );
};
