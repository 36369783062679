import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import clsx from 'clsx';
import { MenuLink } from './menuLink';
import styles from './styles.module.scss';

export const MainMenu: React.FC = () => {
  const { t } = useTranslation('default', { keyPrefix: 'components/mainMenu' });

  return (
    <nav className={styles.root}>
      <NavLink className={styles.link} to="/">
        {t('dashboard')}
      </NavLink>

      <MenuLink className={clsx(styles.link)} to="/planning" validPaths={['planning', 'quotes', 'quote']}>
        {t('planning')}
      </MenuLink>

      <NavLink className={styles.link} to="/bookings">
        {t('bookings')}
      </NavLink>
    </nav>
  );
};
