import React, { useMemo, useRef, useState } from 'react';
import { SmallCaretDownIcon } from '@rouvia/icons';
import { MultiSelectCheckboxPopup } from 'components/multiSelectCheckbox/MultiSelectCheckboxPopup';
import { TMultiSelectData } from 'components/filtersGenerator/types';
import styles from './styles.module.scss';

type TProps = {
  data: TMultiSelectData[];
  setData: (data: TMultiSelectData[]) => void;
  title: string;
};

export const MultiSelectCheckbox: React.FC<TProps> = ({ data, setData, title }) => {
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const popupRef = useRef<HTMLDivElement>(null);

  const totalSelected = useMemo(
    () => data.reduce((total, checkbox) => (checkbox.selected ? total + 1 : total), 0),
    [data],
  );

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  return (
    <div className={styles.root} ref={popupRef}>
      <div
        data-testid="MultiSelectCheckboxClickable"
        onClick={() => setShowPopup(!showPopup)}
        className={styles.rootContent}
      >
        {title}
        {Boolean(totalSelected) && <div className={styles.totalSelected}>{totalSelected}</div>}
        <SmallCaretDownIcon style={{ marginLeft: 8 }} />
      </div>
      {showPopup && (
        <MultiSelectCheckboxPopup
          title={title}
          onClose={handleClosePopup}
          parentRef={popupRef}
          data={data}
          setData={setData}
        />
      )}
    </div>
  );
};
