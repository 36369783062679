import clsx from 'clsx';
import React from 'react';
import styles from './styles.module.scss';

type TProps = {
  title: string;
  description?: string;
  className?: string;
  size?: 'large' | 'medium';
};

export const SectionHeading: React.FC<TProps> = ({ title, description, size = 'large', className }) => (
  <div className={clsx(styles.sectionHeadingRoot, styles[size], className)}>
    <div className={clsx(styles.title, styles[size])} data-testid="SectionHeadingTitle">
      {title}
    </div>
    {description && <div className={styles.description}>{description}</div>}
  </div>
);
