import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ContentBox } from 'components/contentBox';
import { SavingButtonSection } from 'components/savingButtonSection';

import { devConsoleError } from 'utils/console';
import styles from './styles.module.scss';

export const CookiePreferences = () => {
  const { t } = useTranslation('default', { keyPrefix: 'pages/accounts.cookiePreferences' });
  const [isConsentManagementAvailable, setIsConsentManagementAvailable] = useState<boolean>(false);

  useEffect(() => {
    if (window.zaraz && 'showConsentModal' in window.zaraz) {
      setIsConsentManagementAvailable(true);
    }
  }, []);

  const handleOpenConsentModal = () => {
    if (window.zaraz && window.zaraz.showConsentModal) {
      window.zaraz.showConsentModal();
    } else {
      devConsoleError("zaraz or showConsentModal doesn't exist in the global scope.");
    }
  };

  if (!isConsentManagementAvailable) {
    return null;
  }

  return (
    <ContentBox
      title={t('title')}
      roundedBtm
      boxShadow
      isExpandable
      contentClassName={styles.content}
      defaultExpanded={false}
    >
      <SavingButtonSection textLabel={t('button')} submitCallback={handleOpenConsentModal} />
    </ContentBox>
  );
};
