import clsx from 'clsx';
import React, { FunctionComponent, SVGProps } from 'react';
import { ReactComponent as AvatarIcon } from 'assets/images/icons/avatar.svg';
import { User } from 'generated/api';
import { getFullName } from 'utils/misc';
import { Colors } from 'scss/colors';
import styles from './styles.module.scss';

type TProps = {
  user?: User;
  size?: 'large' | 'medium' | 'small';
  Placeholder?: FunctionComponent<SVGProps<SVGSVGElement>>;
  testId?: string;
};

export const UserAvatar: React.FC<TProps> = ({ user, size, Placeholder, testId = 'UserAvatarRoot' }) => {
  const getPlaceholder = () => {
    if (Placeholder) {
      return <Placeholder className={clsx(size && styles[size])} />;
    } else {
      return (
        <AvatarIcon
          className={clsx(size && styles[size])}
          style={{ color: Colors.black04 }}
          data-testid="UserAvatarDefaultPlaceholder"
        />
      );
    }
  };

  return (
    <div className={clsx(styles.root)} data-testid={testId}>
      {user?.avatar ? (
        <img src={user.avatar} alt={getFullName(user)} className={clsx(styles.avatar, size && styles[size])} />
      ) : (
        getPlaceholder()
      )}
    </div>
  );
};
