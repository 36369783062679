import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { bookingQueryKey, bookingsQueryKey, cancelBooking } from 'api/bookings';
import { Button } from 'components/button';
import { Map } from 'components/map';

import { Modal } from 'components/modal';
import { RouteInformation } from 'components/routeInformation';
import ToastContext from 'components/toast';
import { queryClient } from 'constants/api';

import { type ApiError, type Booking, BookingStatus } from 'generated/api';
import { AdditionalInformation } from './additionalInformation';
import { BookingOverview } from './bookingOverview';
import { EquipmentInformation } from './equipmentInformation';
import { PriceInformation } from './priceInformation';
import styles from './styles.module.scss';

type TProps = {
  booking: Booking;
};

export const OverviewTab: React.FC<TProps> = ({ booking }) => {
  const navigate = useNavigate();
  const { playErrorToast, playSuccessToast } = useContext(ToastContext);
  const [isModalVisible, setModalVisible] = useState(false);
  const { t } = useTranslation('default', {
    keyPrefix: 'components/orderOverview',
  });

  const isBookingCancelable = [BookingStatus.PENDING, BookingStatus.CONFIRMED].includes(booking.status);
  const isBookingInTransportation = booking.status === BookingStatus.IN_PROGRESS;
  const { additionalInformation } = booking;

  const closeModal = () => {
    setModalVisible(false);
  };

  const { mutate: cancelBookingMutation, isLoading } = useMutation<void, ApiError, void>(
    () => cancelBooking(booking.id || ''),
    {
      onSuccess: () => {
        playSuccessToast();
        queryClient.invalidateQueries([bookingsQueryKey], { refetchType: 'all' });
        queryClient.invalidateQueries([bookingQueryKey, booking.id || '']).then(() => {
          closeModal();
          navigate('/bookings');
        });
      },
      onError: (apiError: ApiError) => {
        closeModal();
        playErrorToast(apiError.errorType);
      },
    },
  );

  return (
    <div className={styles.overviewTabRoot} data-testid="BookingOverviewTab">
      <div className={styles.columnFlex}>
        <BookingOverview booking={booking} />
        <PriceInformation booking={booking} />
        {(additionalInformation.origin || additionalInformation.destination) && (
          <AdditionalInformation data={additionalInformation} />
        )}
        <EquipmentInformation cargo={booking.cargo} equipmentInformation={booking.equipmentInformation} />
        <RouteInformation entity={booking} isServicesSubmitted />
      </div>
      <div className={styles.map}>
        <Map
          bookings={booking ? [booking] : undefined}
          quoteEntity={isBookingInTransportation ? undefined : booking}
          mapConfig={{ zoom: 5 }}
        />
      </div>

      {isBookingCancelable && (
        <Button
          theme="danger"
          testId="orderOverviewModalBtn"
          type="button"
          width={160}
          onClick={() => setModalVisible(true)}
        >
          {t('cancelBtn')}
        </Button>
      )}

      {isModalVisible && (
        <Modal
          title={t('modal.title')}
          onClose={closeModal}
          primaryAction={{
            callback: cancelBookingMutation,
            text: t('modal.primaryButton'),
          }}
          secondaryAction={{
            callback: closeModal,
            text: t('modal.secondaryButton'),
          }}
          isBackdropLoading={isLoading}
        >
          {t('modal.text')}
        </Modal>
      )}
    </div>
  );
};
