import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { DateTime, Interval } from 'luxon';

import { WidgetItem } from 'components/widgetContainer/item';
import { SpaceSeparator } from 'components/spaceSeparator';
import { Booking } from 'generated/api';
import { formatDate, DATE_FORMAT } from 'utils/date';
import { getTerminalNameOrAddress } from 'utils/misc';

import { StatusPill } from 'components/badge/statusPill';
import styles from './styles.module.scss';

export const BookingListItem: React.FC<{ booking: Booking; children?: React.ReactNode; testId?: string }> = ({
  booking,
  testId = 'BookingListItem',
}) => {
  const { t } = useTranslation('default', {
    keyPrefix: 'components/bookingsListWidget',
  });
  const { id, status, route, fwdId } = booking;

  const isRailRoute = route.type === 'RAIL';
  const isTruckRoute = route.type === 'TRUCK';
  const origin = getTerminalNameOrAddress(booking.origin);
  const destination = getTerminalNameOrAddress(booking.destination);

  const mainLeg = useMemo(() => {
    if (isRailRoute) {
      return route.railLeg;
    }

    if (isTruckRoute) {
      return route.truckLeg;
    }
  }, [isRailRoute, isTruckRoute, route]);

  const interval = useMemo(() => {
    if (mainLeg) {
      return Interval.fromDateTimes(DateTime.now(), DateTime.fromISO(mainLeg.departureDatetime));
    }
  }, [mainLeg]);

  const daysDiff = useMemo(() => interval?.length('days') || 0, [interval]);

  // console.log('bookings', booking);

  return (
    <Link to={`/bookings/${id}`} className={styles.bookingListItemRoot} key={booking.id} data-testid={testId}>
      <WidgetItem>
        <div className={styles.title}>
          <span>
            {booking.id} {fwdId && <span className={styles.refId}>({fwdId})</span>}
          </span>
          <StatusPill status={status} />
        </div>

        <div className={styles.pathInfo}>
          <SpaceSeparator>
            <span className={styles.direction}>
              {mainLeg && formatDate(mainLeg.departureDatetime, DATE_FORMAT.DATE_MONTH_S_HOURS)}
            </span>
            <span className={styles.direction}>{t('pathFrom')}</span>
            {origin}
            <span className={styles.direction}>{t('pathTo')}</span>
            {destination}
            <span className={styles.direction}>{t('with')}</span>
            {mainLeg?.operator.name}
          </SpaceSeparator>
        </div>

        <div className={styles.days}>
          <div className={styles.daysLabel}>{t('upcoming')}:</div>

          <div className={styles.daysTags}>
            {daysDiff < 1 && <div className={styles.dayTag}>24 {t('hours')}</div>}
            {daysDiff > 1 && daysDiff <= 2 && <div className={styles.dayTag}>2 {t('days')}</div>}
            {daysDiff > 2 && daysDiff <= 3 && <div className={styles.dayTag}>3 {t('days')}</div>}
            {daysDiff > 3 && daysDiff <= 7 && <div className={styles.dayTag}>1 {t('week')}</div>}
          </div>
        </div>
        <div className={styles.eta}>
          {t('ETA')}
          {': '}
          <span className={styles.etaDate}>
            {mainLeg && formatDate(mainLeg.arrivalDatetime, DATE_FORMAT.DATE_MONTH_FULL)}
          </span>
        </div>
      </WidgetItem>
    </Link>
  );
};
