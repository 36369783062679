import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';

import { BinSimpleIcon, CloseCircleIcon, DownloadIcon, PlusBoxIcon } from '@rouvia/icons';
import { nanoid } from 'nanoid';
import { UseFormGetValues, UseFormReset } from 'react-hook-form';
import { Button } from 'components/button';
import { Input } from 'components/input';
import { ISearchForm } from 'constants/planning';
import { useOnClickOutside } from 'utils/hooks/onClickOutside';
import styles from './styles.module.scss';

type TProps = {
  getValues: UseFormGetValues<ISearchForm>;
  reset: UseFormReset<ISearchForm>;
  closePopup: () => void;
};

type PlanningTemplate = {
  name: string;
  id: string;
  version: number;
  form: ISearchForm;
};

const PLANNING_TEMPLATE_KEY = 'PLANNING_TEMPLATE_KEY';

// to invalidate previous templates increase the version by 1
const PLANNING_TEMPLATE_VERSION: number = 0;

export const TemplatePopup: React.FC<TProps> = ({ getValues, reset, closePopup }) => {
  const [isAddNewOpen, setIsAddNewOpen] = useState<boolean>(false);
  const [selectedTemplate, setSelectedTemplated] = useState<PlanningTemplate | null>(null);
  const [templateList, setTemplateList] = useState<PlanningTemplate[]>([]);
  const [templateName, setTemplateName] = useState<string>('');
  const ref = useRef(null);

  useOnClickOutside(ref, closePopup);

  useEffect(() => {
    const jsonTemplates = localStorage.getItem(PLANNING_TEMPLATE_KEY);

    if (jsonTemplates) {
      const parsedList = JSON.parse(jsonTemplates);

      setTemplateList(parsedList);
    }
  }, []);

  const toggleAddNewTemplate = () => {
    setIsAddNewOpen(!isAddNewOpen);
  };

  const handleCreateTemplate = () => {
    if (templateName) {
      const randomId = nanoid();
      const form = getValues();

      const newTemplateList = [
        { name: templateName, id: randomId, version: PLANNING_TEMPLATE_VERSION, form },
        ...templateList,
      ];

      localStorage.setItem(PLANNING_TEMPLATE_KEY, JSON.stringify(newTemplateList));
      setTemplateList(newTemplateList);
      setIsAddNewOpen(false);
      setSelectedTemplated(null);
    }
  };

  const handleApplyTemplate = () => {
    if (selectedTemplate) {
      reset(selectedTemplate.form);
      setSelectedTemplated(null);
      closePopup();
    }
  };

  const handleDownloadTemplate = () => {
    if (!selectedTemplate) {
      return;
    }

    const dataStr = JSON.stringify(selectedTemplate, null, 2);
    const blob = new Blob([dataStr], { type: 'text/txt' });
    const url = URL.createObjectURL(blob);
    const downloadLink = document.createElement('a');

    downloadLink.download = `${selectedTemplate.name}.txt`;
    downloadLink.href = url;
    downloadLink.click();
    URL.revokeObjectURL(url);
  };

  const handleDeleteTemplate = () => {
    if (selectedTemplate) {
      const newTemplateList = templateList.filter((template) => template.id !== selectedTemplate.id);

      localStorage.setItem(PLANNING_TEMPLATE_KEY, JSON.stringify(newTemplateList));
      setTemplateList(newTemplateList);
      setSelectedTemplated(null);
    }
  };

  return (
    <div className={clsx(styles.popup)} ref={ref}>
      <div className={styles.header}>
        <div style={{ display: 'flex', flexWrap: 'nowrap', gap: '4px' }}>
          <div>Templates</div>
        </div>

        <div style={{ cursor: 'pointer' }} onClick={toggleAddNewTemplate}>
          {isAddNewOpen ? <CloseCircleIcon /> : <PlusBoxIcon />}
        </div>
      </div>
      {isAddNewOpen && (
        <div className={styles.addNewContent}>
          <div className={styles.description}>
            You can save the current planning form as a template to re-use in the future.
          </div>
          <div className={styles.newTemplateButtons}>
            <Input
              className={styles.inputName}
              onChange={setTemplateName}
              value={templateName}
              placeholder="Name for the new template"
            />
            <Button theme="accent" size="medium" disabled={!templateName.length} onClick={handleCreateTemplate}>
              Save
            </Button>
          </div>
        </div>
      )}
      <div className={styles.listContainer}>
        {templateList.length ? (
          templateList.map((template) => (
            <div
              key={template.id}
              className={clsx(styles.templateRow, {
                [styles.active]: template.id === selectedTemplate?.id,
                [styles.outdated]: template.version < PLANNING_TEMPLATE_VERSION,
              })}
              onClick={() => setSelectedTemplated(template)}
            >
              <div>{template.name}</div>{' '}
              {template.version < PLANNING_TEMPLATE_VERSION && <div className={styles.outdatedSpan}>OUTDATED</div>}
            </div>
          ))
        ) : (
          <div className={styles.emptyList}>No saved templates</div>
        )}
      </div>
      <div className={styles.footer}>
        <Button
          theme="accent"
          size="medium"
          disabled={!selectedTemplate || selectedTemplate.version < PLANNING_TEMPLATE_VERSION}
          onClick={handleApplyTemplate}
        >
          Apply
        </Button>
        <div className={styles.iconsFooter}>
          <Button
            theme="ghost"
            size="medium"
            iconOnly={true}
            disabled={!selectedTemplate}
            onClick={handleDownloadTemplate}
          >
            <DownloadIcon />
          </Button>
          <Button
            theme="ghost"
            size="medium"
            iconOnly={true}
            disabled={!selectedTemplate}
            onClick={handleDeleteTemplate}
          >
            <BinSimpleIcon />
          </Button>
        </div>
      </div>
    </div>
  );
};
