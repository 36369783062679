import clsx from 'clsx';
import React from 'react';
import { loadingUnits } from 'constants/equipments';
import { CargoItem } from 'generated/api';
import styles from './formattedEquipment.module.scss';

type TProps = {
  cargoItem: CargoItem;
  size?: 'big' | 'medium';
};

export const FormattedEquipment: React.FC<TProps> = ({ cargoItem, size }) => {
  const detailedInfo = loadingUnits.find((i) => i.value === cargoItem.loadingUnit)?.label;
  const label = `${cargoItem.amount}x ${cargoItem.equipmentType} (${cargoItem.size}')`;

  return (
    <div className={clsx(styles.formattedEquipmentRoot, size && styles[size])}>
      <div className={styles.title}>{label}</div>
      {detailedInfo && <div className={styles.detailed}>{detailedInfo}</div>}
    </div>
  );
};
