import React from 'react';
import clsx from 'clsx';
import { SCREEN_SIZE } from 'constants/screenSize';
import styles from './styles.module.scss';

type TProps = {
  className?: string;
  maxWidth?: SCREEN_SIZE;
  children?: React.ReactNode;
};

export const CentricContainer: React.FC<TProps> = ({ className, maxWidth = SCREEN_SIZE.XL, children }) => (
  <div
    data-testid="centricContainer"
    className={clsx(styles.centricContainerRoot, className, maxWidth && styles[maxWidth])}
  >
    {children}
  </div>
);
