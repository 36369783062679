import React from 'react';
import clsx from 'clsx';

import { SmallCaretLeftIcon, SmallCaretRightIcon } from '@rouvia/icons';
import { Link } from 'components/link';
import { devConsoleError } from 'utils/console';
import styles from './styles.module.scss';

type TProps = {
  //  Minimum currentPage is 1
  currentPage: number;
  setCurrentPage: (newPage: number) => void;
  perPage: number;
  totalElements: number;
  paginationGroup?: number;
};

export const Paginator: React.FC<TProps> = ({
  currentPage,
  setCurrentPage,
  totalElements,
  perPage,
  paginationGroup = 2,
}) => {
  if (currentPage < 1) {
    devConsoleError('Current Paginator page less than 1', currentPage);

    return null;
  }

  const maxPage = Math.ceil(totalElements / perPage);
  const hasPrevious = currentPage > 1;
  const hasNext = currentPage < maxPage;

  const goBack = () => {
    if (hasPrevious) {
      setCurrentPage(currentPage - 1);
    }
  };
  const goNext = () => {
    if (hasNext) {
      setCurrentPage(currentPage + 1);
    }
  };

  const getPages = () => {
    const pagesButtons: Array<number | null> = [];

    pagesButtons.push(1);
    if (currentPage - paginationGroup > 2) {
      if (currentPage - paginationGroup - 1 === 2) {
        pagesButtons.push(2);
      } else {
        pagesButtons.push(null);
      }
    }

    for (let i = currentPage - paginationGroup; i <= currentPage + paginationGroup; i++) {
      if (i > 1 && i < maxPage) {
        pagesButtons.push(i);
      }
    }

    if (currentPage + paginationGroup < maxPage - 1) {
      if (currentPage + paginationGroup === maxPage - 2) {
        pagesButtons.push(maxPage - 1);
      } else {
        pagesButtons.push(null);
      }
    }

    if (maxPage > 1) {
      pagesButtons.push(maxPage);
    }

    return pagesButtons.map((pageNumber, index) => {
      if (pageNumber === null) {
        return '...';
      }

      return (
        <Link
          key={index}
          theme={pageNumber === currentPage ? 'accent' : 'neutral'}
          underline="none"
          onClick={() => setCurrentPage(pageNumber)}
          className={clsx(styles.link, styles.pagesLink, { [styles.nonActive]: pageNumber !== currentPage })}
        >
          {pageNumber}
        </Link>
      );
    });
  };

  return (
    <div className={clsx(styles.root)}>
      <Link theme="neutral" underline="none" disabled={!hasPrevious} onClick={goBack} className={styles.link}>
        <div>
          <SmallCaretLeftIcon /> Prev
        </div>
      </Link>
      <div className={styles.pages}>{getPages()}</div>
      <Link theme="neutral" underline="none" disabled={!hasNext} onClick={goNext} className={styles.link}>
        <div>
          Next <SmallCaretRightIcon />
        </div>
      </Link>
    </div>
  );
};
