import { useMutation } from '@tanstack/react-query';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CheckSmallIcon } from '@rouvia/icons';
import { organisationUsersQueryKey, updateUserRoles } from 'api/user';
import { ContextMenu } from 'components/contextMenu';
import ToastContext from 'components/toast';
import { queryClient } from 'constants/api';
import { User, UserRoleToAssign } from 'generated/api';
import { isAdminUser } from 'utils/misc';
import styles from './styles.module.scss';

type TProps = {
  user: User;
};

export const SwitchRole: React.FC<TProps> = ({ user }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedRole, selectRole] = useState<string>();
  const isAdmin = useMemo(() => isAdminUser(user), [user]);
  const { playErrorToast, playSuccessToast } = useContext(ToastContext);
  const { t } = useTranslation('default', { keyPrefix: 'pages/orgManagement.components.switchRole' });
  const { t: rolesT } = useTranslation('default', { keyPrefix: 'common.roles' });

  const actionsItems = useMemo(
    () => [
      { label: rolesT('admin'), value: UserRoleToAssign.Admin },
      { label: rolesT('member'), value: '' },
    ],
    [rolesT],
  );

  const { mutate: assignRoleMutation, isLoading: isLoadingRoles } = useMutation(
    (data: { userId: string; roles: UserRoleToAssign[] }) => updateUserRoles(data.userId, { roles: data.roles }),
    {
      onSuccess: () => {
        playSuccessToast(t('toasts.success'));
        queryClient.invalidateQueries([organisationUsersQueryKey]);
      },
      onError: () => {
        playErrorToast();
      },
    },
  );

  const handleAction = (value: string) => {
    if (isLoadingRoles) {
      return;
    }

    const roles: UserRoleToAssign[] = [];

    if (value === UserRoleToAssign.Admin) {
      if (isAdmin) {
        return;
      }

      roles.push(value);
    } else if (!value && !isAdmin) {
      return;
    }

    assignRoleMutation({ userId: user.id, roles });
  };

  useEffect(() => {
    selectRole(isAdmin ? 'admin' : 'member');
  }, [isAdmin]);

  return (
    <ContextMenu
      selectedItem={selectedRole}
      closeMenu={() => setIsOpen(false)}
      isOpen={isOpen}
      items={actionsItems}
      onClickHandler={handleAction}
      contextClassName={styles.contextMenu}
    >
      <div
        data-testid="OrgManagementSwitchRoleContext"
        className={styles.switchRoleRoot}
        onClick={() => setIsOpen(true)}
      >
        {selectedRole} <CheckSmallIcon className={styles.icon} />
      </div>
    </ContextMenu>
  );
};
