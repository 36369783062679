import React from 'react';
import { useAuth } from 'react-oidc-context';
import { BrowserRouter, Route } from 'react-router-dom';
import { QueryClientProvider } from '@tanstack/react-query';
import { SentryRoutes } from 'modules/sentry';

import { queryClient } from 'constants/api';
import { getRoutesBasedOnUser } from 'constants/routes';
import { ToastProvider } from 'components/toast';
import { ConsentWrapper } from 'components/consentWrapper';
import { LangProvider } from 'components/langProvider';
import { setAxiosInterceptors } from 'utils/hooks/axiosInterceptor';
import { IRoute } from 'constants/routes/utils';
import { extractUser, ICustomToken } from 'utils/hooks/useGetUser';
import 'scss/common.scss';

export const App = () => {
  const auth = useAuth();

  setAxiosInterceptors(auth);

  return (
    <ConsentWrapper>
      <QueryClientProvider client={queryClient}>
        <LangProvider>
          <ToastProvider>
            <BrowserRouter>
              <SentryRoutes>
                {getRoutesBasedOnUser(extractUser(auth.user?.profile as ICustomToken)).map((route: IRoute) => (
                  <Route key={route.routeInfo.path} path={route.routeInfo.path} element={route.Component} />
                ))}
              </SentryRoutes>
            </BrowserRouter>
          </ToastProvider>
        </LangProvider>
      </QueryClientProvider>
    </ConsentWrapper>
  );
};
