import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { HeadphonesMicroSolidIcon, TrainIcon, TruckIcon, UserCircleSolidIcon } from '@rouvia/icons';
import { FeedEntry, MessageRecipientType, Operator } from 'generated/api';
import { DATE_FORMAT, formatDate } from 'utils/date';
import { getAuthorName, getAuthorType, getFullName } from 'utils/misc';
import { ReactComponent as RouviaBotIcon } from 'assets/images/icons/rouviaBot.svg';

import { Entity } from 'components/entity';
import { UserAvatar } from 'components/userAvatar';
import { useGetUser } from 'utils/hooks/useGetUser';
import { getNotificationEventLabel } from 'utils/notification';
import styles from './styles.module.scss';

const defaultIcons = new Map<string, ReactNode>([
  ['USER', <UserCircleSolidIcon data-testid="ActivityFeedDefaultIcon" key={MessageRecipientType.INTERNAL_TEAM} />],
  [
    'OPS_TEAM',
    <HeadphonesMicroSolidIcon
      className={styles.customerService}
      data-testid="ActivityFeedDefaultIcon"
      key={MessageRecipientType.ROUVIA_CUSTOMER_SERVICE}
    />,
  ],
  [
    'OPERATOR',
    <TrainIcon
      data-testid="ActivityFeedDefaultIcon"
      className={styles.trainIcon}
      key={MessageRecipientType.OPERATOR}
    />,
  ],
  [
    'ROAD_CARRIER',
    <TruckIcon
      data-testid="ActivityFeedDefaultIcon"
      className={styles.trainIcon}
      key={MessageRecipientType.ROAD_CARRIER}
    />,
  ],
]);

export type TProps = {
  notification: FeedEntry;
  roadOperators?: Operator[] | null;
  railOperator?: Operator | null;
};

export const ActivityFeedItem: React.FC<TProps> = ({ notification, railOperator, roadOperators }) => {
  const [user] = useGetUser();
  const { t } = useTranslation('default', {
    keyPrefix: 'components/activityFeed',
  });

  if (notification.type === 'MESSAGE') {
    const authorType = getAuthorType(notification.author) || MessageRecipientType.ROUVIA_CUSTOMER_SERVICE;
    let availableRecipientName: string = 'N/A';

    if (notification.recipient.type === MessageRecipientType.ROAD_CARRIER && roadOperators) {
      if (roadOperators.length === 1) {
        availableRecipientName = roadOperators[0].name;
      } else {
        if (notification.recipient.operatorId) {
          const foundOperator = roadOperators.find((operator) => operator.id == notification.recipient.operatorId);

          if (foundOperator) {
            availableRecipientName = foundOperator.name;
          }
        }
      }
    } else if (notification.recipient.type === MessageRecipientType.OPERATOR && railOperator) {
      availableRecipientName = railOperator.name;
    } else {
      availableRecipientName = t(notification.recipient.type);
    }

    let isSameUser = false;
    let title;
    let avatar;

    if (notification.author.type === 'USER') {
      avatar = <UserAvatar user={notification.author} testId="ActivityFeedUser" size="large" />;
      if (notification.author.id === user.id) {
        title = (
          <div>
            {getFullName(notification.author)}
            <span style={{ fontWeight: 400 }}>{` ${t('to')} `}</span> {availableRecipientName}
          </div>
        );
        isSameUser = true;
      }
    } else if (notification.author.type) {
      avatar = defaultIcons.get(notification.author.type);
    }

    return (
      <Entity
        iconSize="lg"
        image={avatar}
        subtitle={t(isSameUser ? 'companyTeam' : authorType)}
        title={title || getAuthorName(notification.author) || ''}
        dateTime={notification.createdAt}
      >
        <div
          data-testid="ActivityFeedMessage"
          className={clsx(styles.message, styles[isSameUser ? notification.recipient.type : authorType])}
        >
          {notification.content}
        </div>
      </Entity>
    );
  } else if (notification.type === 'EVENT') {
    let content;

    if (notification.content.type === 'BOOKING_STATUS_HAS_CHANGED') {
      content = (
        <div data-testid="ActivityFeedItemEventBookingStatusChanged">
          {getNotificationEventLabel(notification.content.newStatus)}
        </div>
      );
    } else if (notification.content.type === 'SHIPMENT_EVENT_HAS_CHANGED') {
      const eta = notification.content.newEta;
      const ata = notification.content.newAta;

      content = (
        <div className={styles.notificationBody} data-testid="ActivityFeedItemEventBookingStatusChanged">
          {notification.content.shipmentEventName ? (
            <div>{getNotificationEventLabel(notification.content.shipmentEventName)}</div>
          ) : (
            ''
          )}
          {eta && (
            <div className={styles.eta}>
              <strong>{t('eta')}</strong> {formatDate(eta, DATE_FORMAT.COMPLETE)}
            </div>
          )}
          {ata && (
            <div className={styles.eta}>
              <strong>{t('ata')}</strong> {formatDate(ata, DATE_FORMAT.COMPLETE)}
            </div>
          )}
        </div>
      );
    } else if (notification.content.type === 'BOOKING_WAS_UPDATED') {
      content = (
        <div className={styles.notificationBody} data-testid="ActivityFeedItemEventBookingStatusChanged">
          {t('bookingEdited')}
        </div>
      );
    }

    return (
      <Entity iconSize="lg" image={<RouviaBotIcon />} title="Rouvia Bot" dateTime={notification.createdAt}>
        <div className={clsx(styles.message, styles.rouviaSupport)}>{content}</div>
      </Entity>
    );
  }

  return null;
};
