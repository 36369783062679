import { useQuery } from '@tanstack/react-query';
import { FeedEntry, MessageRecipient } from 'generated/api';
import { api } from 'api/request';

export const activityFeedQueryKey = 'activityFeed';
export const activityFeedPostedMessage = 'activityFeedPostedMessage';

const getActivityFeed = async (bookingId: string): Promise<FeedEntry[]> => {
  const { data } = await api.listFeedEntries(bookingId);

  return data;
};

export const useGetActivityFeed = (bookingId: string) =>
  useQuery([activityFeedQueryKey, bookingId], () => getActivityFeed(bookingId), { refetchOnMount: true });

export const postActivityFeed = async (
  bookingId: string,
  message: string,
  recipient: MessageRecipient,
): Promise<FeedEntry> => {
  const { data } = await api.postMessage(bookingId, { message, recipient });

  return data;
};
