import clsx from 'clsx';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import DOMPurify from 'dompurify';
import { useOnClickOutside } from 'utils/hooks/onClickOutside';
import { ReactComponent as InfoIcon } from './i/i.svg';
import { ReactComponent as InfoIconActive } from './i/i-active.svg';
import styles from './styles.module.scss';

type TProps = {
  text: string;
  position?: 'top' | 'bottom' | 'left' | 'right';
  width?: number;
  isEmbeddedHtml?: boolean;
};

export const Tooltip: React.FC<TProps> = ({ text, position = 'bottom', width, isEmbeddedHtml }) => {
  const [isActive, setActive] = useState(false);
  const [timeoutFn, setTimeoutFn] = useState<NodeJS.Timeout>();
  const cloudRef = useRef<HTMLDivElement>(null);

  const cleanText = useMemo(() => {
    if (!isEmbeddedHtml) {
      return text;
    }

    const cleanT = DOMPurify.sanitize(text, { ADD_ATTR: ['target'] });

    return <span dangerouslySetInnerHTML={{ __html: cleanT }} />;
  }, [text, isEmbeddedHtml]);

  const handleTimeout = () => {
    const fn = setTimeout(() => setActive(false), 5000);

    setTimeoutFn(fn);
  };

  const handleShowHint = () => {
    setActive(true);
    handleTimeout();
  };

  const handleCloseHint = () => {
    setActive(false);
    clearTimeout(timeoutFn);
    setTimeoutFn(undefined);
  };

  useEffect(() => {
    const mouseenter = () => {
      clearTimeout(timeoutFn);
      setTimeoutFn(undefined);
    };

    const mouseleave = () => {
      handleTimeout();
    };

    if (cloudRef?.current) {
      cloudRef.current.addEventListener('mouseenter', mouseenter);
      cloudRef.current.addEventListener('mouseleave', mouseleave);
    }

    return () => {
      document.removeEventListener('mouseenter', mouseenter);
      document.removeEventListener('mouseleave', mouseleave);
    };
  });

  useOnClickOutside(cloudRef, handleCloseHint);

  return (
    <div className={styles.root}>
      <div
        className={clsx(styles.iconWrapper, { [styles.active]: isActive })}
        onClick={handleShowHint}
        data-testid="TooltipClickable"
      >
        <InfoIcon className={styles.icon} />
        <InfoIconActive className={styles.iconActive} />
      </div>

      <div
        className={clsx(styles.cloud, styles[position], {
          [styles.active]: isActive,
        })}
        ref={cloudRef}
        data-testid="TooltipText"
        style={width ? { width, whiteSpace: 'normal' } : undefined}
      >
        {cleanText}
      </div>
    </div>
  );
};
