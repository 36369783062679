import React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { TrainIcon, TruckIcon } from '@rouvia/icons';
import type { Booking } from 'generated/api';

import { addressStringBuilder, getAddressFromOnD } from 'utils/misc';
import { getMainOperator } from 'utils/shipment';

import styles from './styles.module.scss';

const Icon = ({ booking }: { booking: Booking }) => {
  let icon = booking.route.type === 'TRUCK' ? <TruckIcon /> : <TrainIcon />;

  return <div className={clsx(styles.popupIcon, styles[booking.status])}>{icon}</div>;
};

type TProps = {
  booking: Booking;
};

export const BadgeItem: React.FC<TProps> = ({ booking }) => {
  const { t } = useTranslation('default', { keyPrefix: 'booking.statuses' });
  const { fwdId, id, route, origin, destination, status } = booking;

  const bookingUrl = `/bookings/${id}/overview`;
  const isCurrentPage = window.location.pathname === bookingUrl;

  const getProgressbar = () => (
    <div className={styles.progressBarContainer}>
      <div className={styles.progressBarLabel}>{t(status)}</div>
      <div className={clsx(styles.progressBar, styles[status])} />
    </div>
  );

  return (
    <a href={isCurrentPage ? '#' : bookingUrl}>
      <div className={styles.badgeItemRoot}>
        <div>
          <Icon booking={booking} />
        </div>
        <div className={styles.columnGridGap}>
          <div className={styles.columnGrid}>
            <div className={styles.fwdId}>{fwdId}</div>
            <div className={styles.mainOperator}>{getMainOperator(route)?.name}</div>
            <div className={styles.details}>
              {addressStringBuilder(getAddressFromOnD(origin), {
                keys: ['street', 'zip', 'city', 'country'],
              })}
              <span style={{ color: 'white' }}>{' → '}</span>
              {addressStringBuilder(getAddressFromOnD(destination), {
                keys: ['street', 'zip', 'city', 'country'],
              })}
            </div>
          </div>
          <div>{getProgressbar()}</div>
        </div>
      </div>
    </a>
  );
};
