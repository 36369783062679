import { Booking, RailLeg, TruckLeg } from 'generated/api';
import { TMultiSelectData, TSelectData } from 'components/filtersGenerator/types';
import { getAddressFromOnD, getAddressString } from 'utils/misc';
import { getMainOperator } from 'utils/shipment';

export const getTableFilteredData = (
  bookings: Booking[],
  filters: {
    debouncedFilterSearch: string;
    filterStatus: TMultiSelectData[];
    filterCarrier: TMultiSelectData[];
    filterOrigin: TSelectData | null;
    filterDestination: TSelectData | null;
  },
) => {
  if (bookings.length) {
    let data = [...bookings];

    const { debouncedFilterSearch, filterStatus, filterCarrier, filterOrigin, filterDestination } = filters;

    // Filter based on Search field
    if (debouncedFilterSearch) {
      const lSearch = debouncedFilterSearch.toLowerCase();

      data = data.filter(
        (booking) =>
          booking.id.toLowerCase().includes(lSearch) ||
          booking.fwdId?.toLowerCase().includes(lSearch) ||
          getAddressString(getAddressFromOnD(booking.origin))?.toLowerCase().includes(lSearch) ||
          getAddressString(getAddressFromOnD(booking.destination))?.toLowerCase().includes(lSearch) ||
          getMainOperator(booking.route)?.name.toLowerCase().includes(lSearch),
      );
    }

    // Filter based on Status
    if (filterStatus.find((filterObj) => filterObj.selected)) {
      data = data.filter((booking) =>
        filterStatus.find((filterObj) => filterObj.selected && filterObj.value === booking.status),
      );
    }

    // Filter based on Carrier
    if (filterCarrier.find((filterObj) => filterObj.selected)) {
      data = data.filter((booking) => {
        const route = booking.route;
        let mainLeg: TruckLeg | RailLeg;

        if (route.type === 'RAIL') {
          mainLeg = route.railLeg;
        } else if (route.type === 'TRUCK') {
          mainLeg = route.truckLeg;
        } else {
          return false;
        }

        if (mainLeg?.operator.name) {
          return filterCarrier.find((filterObj) => filterObj.selected && filterObj.value === mainLeg.operator.name);
        } else {
          return false;
        }
      });
    }

    // Filter based on Origin
    if (filterOrigin) {
      data = data.filter((booking) => getAddressFromOnD(booking.origin).city === filterOrigin.value);
    }

    // Filter based on Destination
    if (filterDestination) {
      data = data.filter((booking) => getAddressFromOnD(booking.destination).city === filterDestination.value);
    }

    return data;
  } else {
    return [];
  }
};
