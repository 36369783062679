import React from 'react';
import { ReactComponent as ListIcon } from 'assets/images/icons/itemsContainer.svg';
import styles from './styles.module.scss';

type TProps = {
  title?: string;
  subtitle?: string;
  fixedHeight?: string;
};

export const EmptyList: React.FC<TProps> = ({ title, subtitle, fixedHeight = '100%' }) => (
  <div className={styles.root} style={{ height: fixedHeight }} data-testid="emptyListRoot">
    <ListIcon />
    {title && (
      <div className={styles.title} data-testid="emptyListTitle">
        {title}
      </div>
    )}
    {subtitle && (
      <div className={styles.subtitle} data-testid="emptyListSubtitle">
        {subtitle}
      </div>
    )}
  </div>
);
