import React from 'react';
import clsx from 'clsx';
import { Loader } from 'components/loader';
import styles from './styles.module.scss';

type TProps = {
  loading?: boolean;
  light?: boolean;
  rounded?: boolean;
};

/**
 * Parent component must have a set position, ie: position: relative
 * */
export const LoaderBackdrop: React.FC<TProps> = ({ loading, light, rounded }) => {
  if (!loading) {
    return null;
  }

  return (
    <div
      data-testid="LoaderBackdropRoot"
      className={clsx(styles.root, {
        [styles.light]: light,
        [styles.rounded]: rounded,
      })}
    >
      <Loader />
    </div>
  );
};
