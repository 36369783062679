import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller, useFieldArray } from 'react-hook-form';
import type { Control, UseFormTrigger } from 'react-hook-form';

import { ISOCountiesArray } from 'constants/countries';
import { CustomSelect } from 'components/select';
import { Expandable } from 'components/expandable';
import { Link } from 'components/link';
import { Input } from 'components/input';
import { isValidContainerNumber } from 'constants/equipments';

import { goodsInfoDefaultVales, IForm } from '../../constants';
import styles from './styles.module.scss';

type TFormName = `equipmentInformation.${number}.containerInformation.${number}`;

type TProps = {
  containerIndex: number;
  equipmentIndex: number;
  control: Control<IForm>;
  trigger: UseFormTrigger<IForm>;
  defaultExpanded?: boolean;
  expanded?: boolean;
  testId?: string;
};

export const ContainerInformationItem: React.FC<TProps> = ({
  equipmentIndex,
  containerIndex,
  control,
  trigger,
  expanded,
  defaultExpanded = false,
  testId,
}) => {
  const { t } = useTranslation('default', {
    keyPrefix: 'pages/quotes/components/equipmentInfo',
  });

  const { t: commonT } = useTranslation('default', {
    keyPrefix: 'common',
  });

  const [isExpanded, setIsExpanded] = useState<boolean>(defaultExpanded);
  const formPrefix: TFormName = `equipmentInformation.${equipmentIndex}.containerInformation.${containerIndex}`;

  const { fields, replace } = useFieldArray<IForm>({
    control,
    name: `${formPrefix}.goodsInformation`,
  });

  useEffect(() => {
    if (!fields.length) {
      replace(goodsInfoDefaultVales);
    }
  }, [fields.length, replace]);

  useEffect(() => {
    if (expanded !== undefined) {
      setIsExpanded(expanded);
    }
  }, [expanded]);

  return (
    <Expandable
      title={`Container №${containerIndex + 1}`}
      description={isExpanded ? undefined : t('needMoreInfo')}
      isActive={isExpanded}
      actions={
        isExpanded ? undefined : (
          <Link theme="accent" onClick={() => setIsExpanded(!isExpanded)} testId="ContainerInformationItemExpandBtn">
            {t('provideInfo')}
          </Link>
        )
      }
      testId={testId}
    >
      <div className={styles.doubleGrid}>
        <Controller
          name={`${formPrefix}.containerNumber`}
          shouldUnregister
          control={control}
          rules={{
            validate: (value) => isValidContainerNumber(value) || t('containerInformation.form.invalidNumber'),
            required: commonT('validation.required'),
          }}
          render={({ field, fieldState: { error: err } }) => (
            <Input
              showRequired
              className={styles.input}
              label={t('containerInformation.form.containerNumber')}
              placeholder={t('containerInformation.form.referenceNumber')}
              error={err?.message}
              {...field}
            />
          )}
        />

        <Controller
          name={`${formPrefix}.sealNumber`}
          shouldUnregister
          control={control}
          render={({ field, fieldState: { error: err } }) => (
            <Input
              className={styles.input}
              label={t('containerInformation.form.sealNumber')}
              placeholder={t('containerInformation.form.sealNumber')}
              error={err?.message}
              {...field}
            />
          )}
        />

        <Controller
          name={`${formPrefix}.originCountry`}
          shouldUnregister
          control={control}
          rules={{
            required: commonT('validation.required'),
          }}
          render={({ field: { onChange, ...field }, fieldState: { error: err } }) => (
            <CustomSelect
              showRequired
              menuPlacement="top"
              options={ISOCountiesArray}
              error={err?.message}
              label={t('containerInformation.form.originCountry')}
              onChange={async (option) => {
                await onChange(option);
                await trigger(`${formPrefix}.destinationCountry`);
              }}
              {...field}
            />
          )}
        />

        <Controller
          name={`${formPrefix}.destinationCountry`}
          shouldUnregister
          control={control}
          rules={{
            required: commonT('validation.required'),
          }}
          render={({ field: { onChange, ...field }, fieldState: { error: err } }) => (
            <CustomSelect
              showRequired
              menuPlacement="top"
              options={ISOCountiesArray}
              error={err?.message}
              label={t('containerInformation.form.destinationCountry')}
              onChange={async (option) => {
                await onChange(option);
                await trigger(`${formPrefix}.originCountry`);
              }}
              {...field}
            />
          )}
        />
      </div>
    </Expandable>
  );
};
