export enum FILTER_TYPE {
  SEARCH = 'SEARCH',
  MULTI_SELECT = 'MULTI_SELECT',
  SINGLE_SELECT = 'SINGLE_SELECT',
}

export type TSelectData = { value: string; label: string };

export type TMultiSelectData = {
  label: string;
  value: string;
  selected: boolean;
};

type TFilterSearch = { type: FILTER_TYPE.SEARCH; data: string; setData: (d: string) => void };

type TFilterMultiSelect = {
  type: FILTER_TYPE.MULTI_SELECT;
  data: TMultiSelectData[];
  setData: (data: TMultiSelectData[]) => void;
};

type TFilterSingleSelect = {
  type: FILTER_TYPE.SINGLE_SELECT;
  options: TSelectData[];
  data: TSelectData | null;
  setData: (data: TSelectData | null) => void;
};

export type TFilterType = {
  name: string;
  hide?: boolean;
  controls: TFilterSearch | TFilterMultiSelect | TFilterSingleSelect;
};
