import { UseFormSetValue } from 'react-hook-form/dist/types';
import type { UseFormReset } from 'react-hook-form';
import { BookingCustomerDetailsInformation, BookingCustomerDetailsInformationUpdateRequest } from 'generated/api';
import { extractAddress, getAddressString } from 'utils/misc';
import { geocode } from 'utils/geo';
import { TCustomerDetailsForm } from 'types/customerDetails';

export enum BOOKING_TAB {
  OVERVIEW = 'overview',
  ACTIVITY = 'activity',
  DOCUMENTS = 'documents',
  PROGRESS = 'progress',
}

export const mapCustomerFormToRequestData = (
  data: TCustomerDetailsForm,
): BookingCustomerDetailsInformationUpdateRequest => ({
  customerDetailsInformation: {
    shipperDetails: {
      ...data.shipperDetails,
      address: extractAddress(data.shipperDetails.address.value),
    },
    consigneeDetails: {
      ...data.consigneeDetails,
      address: extractAddress(data.consigneeDetails.address.value),
    },
    goodsList: data.goodsList.map((item) =>
      item.map((goods) => ({
        ...goods,
        currency: goods.currency.value,
      })),
    ),
    invoicingDetails: {
      ...data.invoicingDetails,
      address: extractAddress(data.invoicingDetails.address.value),
    },
    otherDetails: data.otherDetails,
  },
});

export const mapRequestDataToCustomerForm = (
  data: BookingCustomerDetailsInformation,
  reset: UseFormReset<TCustomerDetailsForm>,
  setValue: UseFormSetValue<TCustomerDetailsForm>,
) => {
  reset({
    shipperDetails: {
      ...data.shipperDetails,
      address: undefined,
    },
    consigneeDetails: {
      ...data.consigneeDetails,
      address: undefined,
    },
    goodsList: data.goodsList.map((item) =>
      item.map((goods) => ({
        ...goods,
        currency: { label: goods.currency, value: goods.currency },
      })),
    ),
    invoicingDetails: {
      ...data.invoicingDetails,
      address: undefined,
    },
    otherDetails: data.otherDetails,
  });

  geocode({
    query: getAddressString(data.shipperDetails.address),
    callback: (options) => {
      if (options.length) {
        setValue('shipperDetails.address', options[0]);
      }
    },
  });
  geocode({
    query: getAddressString(data.consigneeDetails.address),
    callback: (options) => {
      if (options.length) {
        setValue('consigneeDetails.address', options[0]);
      }
    },
  });
  geocode({
    query: getAddressString(data.invoicingDetails.address),
    callback: (options) => {
      if (options.length) {
        setValue('invoicingDetails.address', options[0]);
      }
    },
  });
};
