import { useEffect } from 'react';
import { init, reactRouterV6Instrumentation, withSentryReactRouterV6Routing } from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { Replay } from '@sentry/replay';
import { Routes, matchRoutes, useLocation, useNavigationType, createRoutesFromChildren } from 'react-router-dom';
import { env, isProduction } from 'constants/config';
import pkg from '../../package.json';

const DSN = 'https://7e861d72bf5f4e8d9ca79027c7216da5@o1354543.ingest.sentry.io/6746538';
const sampleRate = isProduction ? 0.1 : 1;

export const sentryClientStart = () => {
  if (env === 'local') {
    return;
  }

  init({
    dsn: DSN,
    autoSessionTracking: false,
    normalizeDepth: 10,
    release: pkg.version,
    environment: env,
    replaysOnErrorSampleRate: sampleRate,
    tracesSampleRate: sampleRate,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: reactRouterV6Instrumentation(
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        ),
      }),
      new Replay({
        blockAllMedia: true,
      }),
    ],
  });
};

export const SentryRoutes = withSentryReactRouterV6Routing(Routes);
