import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller } from 'react-hook-form';
import clsx from 'clsx';
import type { UseFormSetValue, Control, UseFormWatch, UseFormTrigger } from 'react-hook-form';
import { CustomsGoods, GoodsTypeOptions } from 'constants/planning';
import { Checkbox } from 'components/checkbox';
import { CargoItem } from 'generated/api';
import { Modal } from 'components/modal';
import { BooleanRadioButton } from 'components/booleanRadioButton';
import { CustomSelect } from 'components/select';
import { SectionHeading } from 'components/sectionHeading';
import { Input } from 'components/input';
import { Button } from 'components/button';
import { customsOptions } from 'constants/customs';
import { IForm } from '../../constants';
import { isContainer } from '../../utils';
import { ContainerInformation } from './containerInformation';
import styles from './styles.module.scss';

type TFormName = `equipmentInformation.${number}`;

type TProps = {
  isOpen: boolean;
  onClose: () => void;
  cargoItem: CargoItem;
  equipmentIndex: number;
  setEquipmentValidity: (index: number, status: boolean) => void;
  formWatch: UseFormWatch<IForm>;
  formControl: Control<IForm>;
  setFormValue: UseFormSetValue<IForm>;
  formTrigger: UseFormTrigger<IForm>;
  testId?: string;
};

export const EquipmentInformationModal: React.FC<TProps> = ({
  formWatch,
  formControl,
  formTrigger,
  setFormValue,
  cargoItem,
  setEquipmentValidity,
  equipmentIndex,
  onClose,
  isOpen,
  testId,
}) => {
  const { t } = useTranslation('default', {
    keyPrefix: 'pages/quotes/components/equipmentInfo',
  });

  const { t: commonT } = useTranslation('default', {
    keyPrefix: 'common',
  });

  const formPrefix: TFormName = `equipmentInformation.${equipmentIndex}`;
  const [isEquipmentEmptyType, setIsEquipmentEmptyType] = useState<boolean>(true);
  const goodsType = formWatch(`${formPrefix}.goodsType`);
  const hasClearanceConfirmation = formWatch(`${formPrefix}.customsInformation.clearanceConfirmation`);
  const isCustomsGoods = goodsType?.value === CustomsGoods && !isEquipmentEmptyType;

  const handleSave = async () => {
    const isValid = await formTrigger(formPrefix);

    setEquipmentValidity(equipmentIndex, isValid);

    if (isValid) {
      if (isEquipmentEmptyType) {
        setFormValue(formPrefix, {
          goodsType: undefined,
        });
      }

      onClose();
    }
  };

  return (
    <Modal
      classBody={styles.modalWrapper}
      title={`${cargoItem.amount}x ${cargoItem.equipmentType} (${cargoItem.size})`}
      onClose={onClose}
      hide={!isOpen}
      testId={testId}
    >
      <div className={styles.modal}>
        <div className={styles.modalItem}>
          <BooleanRadioButton
            label={t('selectEquipment')}
            isActiveLabel={t('empty')}
            isNotActiveLabel={t('full')}
            isActive={isEquipmentEmptyType}
            setIsActive={setIsEquipmentEmptyType}
            testId={`setEquipmentDataBooleanBtn${equipmentIndex}`}
          />
          {!isEquipmentEmptyType && (
            <div className={styles.doubleGrid}>
              <Controller
                name={`${formPrefix}.goodsType`}
                shouldUnregister
                control={formControl}
                rules={{ required: commonT('validation.required') }}
                render={({ field, fieldState: { error } }) => (
                  <CustomSelect
                    showRequired
                    options={GoodsTypeOptions.map((k) => ({ label: t(`goodsType.values.${k}`), value: k }))}
                    error={error?.message}
                    hint={t('customsGoodsNotice')}
                    label={t('goodsType.label')}
                    {...field}
                  />
                )}
              />
            </div>
          )}
        </div>

        {isCustomsGoods && (
          <div className={styles.modalItem} data-testid={`isCustomsGoodsSection${equipmentIndex}`}>
            <SectionHeading title={t('customsInfo')} description={t('provideInfoCustoms')} />

            <Controller
              shouldUnregister
              control={formControl}
              rules={{ required: commonT('validation.required') }}
              name={`${formPrefix}.customsInformation.clearanceConfirmation`}
              render={({ field, fieldState: { error: err } }) => (
                <Checkbox
                  {...field}
                  label={t('clearanceConfirmation')}
                  error={err?.message}
                  value={undefined}
                  checked={field.value || false}
                />
              )}
            />

            {hasClearanceConfirmation && (
              <div className={styles.doubleGrid}>
                <Controller
                  name={`${formPrefix}.customsInformation.receiverName`}
                  shouldUnregister
                  control={formControl}
                  rules={{ required: commonT('validation.required') }}
                  render={({ field, fieldState: { error: err } }) => (
                    <Input
                      showRequired
                      className={styles.input}
                      label={t('receiverName')}
                      placeholder={t('receiverName')}
                      error={err?.message}
                      {...field}
                    />
                  )}
                />
                <Controller
                  name={`${formPrefix}.customsInformation.receiverAddress`}
                  shouldUnregister
                  control={formControl}
                  rules={{ required: commonT('validation.required') }}
                  render={({ field, fieldState: { error: err } }) => (
                    <Input
                      showRequired
                      className={styles.input}
                      label={t('receiverAddress')}
                      placeholder={t('receiverAddress')}
                      error={err?.message}
                      {...field}
                    />
                  )}
                />
                <Controller
                  name={`${formPrefix}.customsInformation.customsOffice`}
                  shouldUnregister
                  control={formControl}
                  rules={{ required: commonT('validation.required') }}
                  render={({ field, fieldState: { error } }) => (
                    <CustomSelect
                      showRequired
                      options={customsOptions}
                      error={error?.message}
                      label={t('customOffice')}
                      {...field}
                    />
                  )}
                />
              </div>
            )}
          </div>
        )}

        {!isEquipmentEmptyType && isContainer(cargoItem) && (
          <ContainerInformation
            cargoItem={cargoItem}
            equipmentIndex={equipmentIndex}
            formControl={formControl}
            formWatch={formWatch}
            formTrigger={formTrigger}
          />
        )}

        <div className={clsx(styles.modalItem, styles.saveContainer)}>
          <Button
            testId={`EquipmentInformationModalSaveBtn${equipmentIndex}`}
            className={styles.saveBtn}
            theme="accent"
            onClick={handleSave}
          >
            {t('save')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};
