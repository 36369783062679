import axios from 'axios';
import { Document, DocumentType, UploadFile } from 'generated/api';
import { downloadDocumentViaUrl } from 'utils/document';
import { api } from './request';

export const uploadDocument = async (
  bookingId: string,
  documentType: DocumentType,
  file: UploadFile,
): Promise<Document> => {
  if (!documentType || !file?.file) {
    throw new Error('Upload file or its type were undefined');
  }

  const { data } = await api.uploadDocument(bookingId, { documentType }, file);

  return data;
};

export const reuploadDocument = async (bookingId: string, documentId: string, file: UploadFile): Promise<Document> => {
  if (!documentId || !file?.file) {
    throw new Error('Upload file or its type were undefined');
  }

  const { data } = await api.reuploadDocument(bookingId, documentId, file);

  return data;
};

export const deleteDocument = async (bookingId: string, documentId: string): Promise<any> => {
  await api.deleteDocument(bookingId, documentId);
};

export const downloadDocument = async (
  bookingId: string,
  documentId: string,
  documentName: string,
): Promise<boolean> => {
  const response = await api.getDownloadLink(bookingId, documentId);

  if (response.status === 200 && response.data.downloadUrl) {
    const res = await axios.get(response.data.downloadUrl, {
      responseType: 'blob',
    });

    downloadDocumentViaUrl(res.data, documentName);

    return true;
  }

  return false;
};
