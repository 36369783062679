import { TDefaultSelectOption } from 'types/common';

export enum ShippingLineEnum {
  ACL = 'ACL',
  ALI = 'ALI',
  ANL = 'ANL',
  APL = 'APL',
  BAL = 'BAL',
  BBC = 'BBC',
  BCF = 'BCF',
  BIN = 'BIN',
  BON = 'BON',
  CCN = 'CCN',
  CGM = 'CGM',
  CHI = 'CHI',
  CLI = 'CLI',
  CMA = 'CMA',
  CNA = 'CNA',
  COC = 'COC',
  COE = 'COE',
  COM = 'COM',
  CONT = 'CONT',
  CPV = 'CPV',
  CRD = 'CRD',
  CSCA = 'CSCA',
  CSCL = 'CSCL',
  CSG = 'CSG',
  CYL = 'CYL',
  DAL = 'DAL',
  DBRR = 'DBRR',
  DELN = 'DELN',
  DFD = 'DFD',
  DLO = 'DLO',
  DLT = 'DLT',
  DMS = 'DMS',
  EAF = 'EAF',
  ECLB = 'ECLB',
  EGH = 'EGH',
  EMC = 'EMC',
  EMS = 'EMS',
  EPS = 'EPS',
  ESF = 'ESF',
  ESSH = 'ESSH',
  ETH = 'ETH',
  FES = 'FES',
  FLSM = 'FLSM',
  GCL = 'GCL',
  GRF = 'GRF',
  HDS = 'HDS',
  HHST = 'HHST',
  HIM = 'HIM',
  HKS = 'HKS',
  HLC = 'HLC',
  HML = 'HML',
  HMM = 'HMM',
  HPE = 'HPE',
  HSG = 'HSG',
  HSL = 'HSL',
  HTM = 'HTM',
  ICA = 'ICA',
  IML = 'IML',
  IPLS = 'IPLS',
  IRI = 'IRI',
  ISC = 'ISC',
  KLK = 'KLK',
  KUJA = 'KUJA',
  LIB = 'LIB',
  LIND = 'LIND',
  LLT = 'LLT',
  LOTC = 'LOTC',
  LYK = 'LYK',
  LYSL = 'LYSL',
  MACA = 'MACA',
  MAE = 'MAE',
  MAMU = 'MAMU',
  MARU = 'MARU',
  MCM = 'MCM',
  MDV = 'MDV',
  MEDR = 'MEDR',
  MOL = 'MOL',
  MSC = 'MSC',
  NCLA = 'NCLA',
  NCOL = 'NCOL',
  NDR = 'NDR',
  NID = 'NID',
  NIR = 'NIR',
  NOR = 'NOR',
  NORI = 'NORI',
  NSA = 'NSA',
  NSC = 'NSC',
  NSH = 'NSH',
  NTR = 'NTR',
  ONE = 'ONE',
  OOL = 'OOL',
  OPD = 'OPD',
  OTA = 'OTA',
  OYC = 'OYC',
  PAPE = 'PAPE',
  PIL = 'PIL',
  PTSL = 'PTSL',
  PWL = 'PWL',
  RCL = 'RCL',
  RCLZ = 'RCLZ',
  ROKU = 'ROKU',
  SAF = 'SAF',
  SAM = 'SAM',
  SCA = 'SCA',
  SCI = 'SCI',
  SEA = 'SEA',
  SEAG = 'SEAG',
  SLF = 'SLF',
  SLS = 'SLS',
  SPH = 'SPH',
  SPLI = 'SPLI',
  STGS = 'STGS',
  STWP = 'STWP',
  SWA = 'SWA',
  SWT = 'SWT',
  TAL = 'TAL',
  TASA = 'TASA',
  TEA = 'TEA',
  TLB = 'TLB',
  TLBS = 'TLBS',
  TURK = 'TURK',
  UAL = 'UAL',
  UAS = 'UAS',
  UFCS = 'UFCS',
  UFE = 'UFE',
  VOLA = 'VOLA',
  WAER = 'WAER',
  WEC = 'WEC',
  WHL = 'WHL',
  XPC = 'XPC',
  XPF = 'XPF',
  YML = 'YML',
  ZIH = 'ZIH',
  ZIM = 'ZIM',
}

export const shippingLinesLabels: { [key in ShippingLineEnum]: string } = {
  [ShippingLineEnum.ACL]: 'Atlantic Container Line',
  [ShippingLineEnum.ALI]: 'Alianca Navegacao Ltd., Brasil',
  [ShippingLineEnum.ANL]: 'A N L, Australian National Line',
  [ShippingLineEnum.APL]: 'American President Line',
  [ShippingLineEnum.BAL]: 'Baltic Container Lines Co Ltd.',
  [ShippingLineEnum.BBC]: 'BBC',
  [ShippingLineEnum.BCF]: 'Boerde Container Feeder',
  [ShippingLineEnum.BIN]: 'Brointermed Lines',
  [ShippingLineEnum.BON]: 'Bonyad Marine Services',
  [ShippingLineEnum.CCN]: 'Cia Chilena De Navegacion Interoceanica S. A.',
  [ShippingLineEnum.CGM]: 'Compagnie Generale Maritime',
  [ShippingLineEnum.CHI]: 'CHIPOLBROK',
  [ShippingLineEnum.CLI]: 'CARGO-LEVANT',
  [ShippingLineEnum.CMA]: 'Compagnie Maritime d’Affretement',
  [ShippingLineEnum.CNA]: 'Compagnie Nationale Algérienne de Navigation S.p.a',
  [ShippingLineEnum.COC]: 'COSCO Container Lines, Shanghai',
  [ShippingLineEnum.COE]: 'COSCO Europe Containerlines',
  [ShippingLineEnum.COM]: 'Compagnie Marocaine de Nav.',
  [ShippingLineEnum.CONT]: 'Contargo Gruppe',
  [ShippingLineEnum.CPV]: 'Compania Sud Americana De Vapores',
  [ShippingLineEnum.CRD]: 'Credo Shipping',
  [ShippingLineEnum.CSCA]: 'China Shipping AGENCIES GmbH',
  [ShippingLineEnum.CSCL]: 'China Shipping Container Lines',
  [ShippingLineEnum.CSG]: 'Containerships CSG GmbH',
  [ShippingLineEnum.CYL]: 'Cho Yang Line',
  [ShippingLineEnum.DAL]: 'Deutsche Afrika Linien',
  [ShippingLineEnum.DBRR]: 'Deutsche Binnenreederei',
  [ShippingLineEnum.DELN]: 'Delphis NV',
  [ShippingLineEnum.DFD]: 'Det Forenede Dampskibs-Selskab',
  [ShippingLineEnum.DLO]: 'Delphis Logistics',
  [ShippingLineEnum.DLT]: 'Delta Shipping Lines',
  [ShippingLineEnum.DMS]: 'Schiffahrts-Kontor Detra',
  [ShippingLineEnum.EAF]: 'EuroAfrica Shipping Lines',
  [ShippingLineEnum.ECLB]: 'Euro Container Line Bergen',
  [ShippingLineEnum.EGH]: 'Evergreen Marine Hong Kong',
  [ShippingLineEnum.EMC]: 'Evergreen Marine Corporation',
  [ShippingLineEnum.EMS]: 'Evergreen Marine Singapore PTE Ltd.',
  [ShippingLineEnum.EPS]: 'Eurogate Port Systems GmbH',
  [ShippingLineEnum.ESF]: 'Euro Shipping & Forwarding',
  [ShippingLineEnum.ESSH]: 'European Shipping Services, Hamburg',
  [ShippingLineEnum.ETH]: 'Ethiopian Shipping Lines',
  [ShippingLineEnum.FES]: 'Fesco Lines Vladiswostok Ltd.',
  [ShippingLineEnum.FLSM]: 'FLSmidth AS',
  [ShippingLineEnum.GCL]: 'G & C AFRICA LINE',
  [ShippingLineEnum.GRF]: 'Green Feeder',
  [ShippingLineEnum.HDS]: 'Hafez Darya Arya Shipping Co',
  [ShippingLineEnum.HHST]: 'Hamburg Sugar Terminal',
  [ShippingLineEnum.HIM]: 'Himalaya Express',
  [ShippingLineEnum.HKS]: 'Hacklin Seatrans',
  [ShippingLineEnum.HLC]: 'Hapag Lloyd',
  [ShippingLineEnum.HML]: 'Evergreen Marine (UK) Limited',
  [ShippingLineEnum.HMM]: 'Hyundai Merchant Marine CO. LTD.',
  [ShippingLineEnum.HPE]: 'BMS PowerPac Project',
  [ShippingLineEnum.HSG]: 'Hamburg Süd Group',
  [ShippingLineEnum.HSL]: 'Hugo Stinnes Line',
  [ShippingLineEnum.HTM]: 'Hatsu Marine',
  [ShippingLineEnum.ICA]: 'Intercontainer Austria',
  [ShippingLineEnum.IML]: 'Inter Marine Container Lines',
  [ShippingLineEnum.IPLS]: 'IPLS International Packing and Logistic Solutions GmbH',
  [ShippingLineEnum.IRI]: 'Islamic Republic of Iran Shipping Lines',
  [ShippingLineEnum.ISC]: 'Iscont Shipping Limited',
  [ShippingLineEnum.KLK]: 'Kawasaki Kinkai Kisen Kaisha',
  [ShippingLineEnum.KUJA]: 'Kursio Linija',
  [ShippingLineEnum.LIB]: 'Libra',
  [ShippingLineEnum.LIND]: 'Linden Newburn',
  [ShippingLineEnum.LLT]: 'Italia Marittima S.P.A. (Lloyd Triestino)',
  [ShippingLineEnum.LOTC]: 'Lotus Container',
  [ShippingLineEnum.LYK]: 'Lykes Lines',
  [ShippingLineEnum.LYSL]: 'Lysline, Oslo',
  [ShippingLineEnum.MACA]: 'Macandrews',
  [ShippingLineEnum.MAE]: 'Maersk',
  [ShippingLineEnum.MAMU]: 'Mann Lines Multimodal Ltd.',
  [ShippingLineEnum.MARU]: 'Maruba Line',
  [ShippingLineEnum.MCM]: 'Marfret Compagnie Maritime',
  [ShippingLineEnum.MDV]: 'Delmas',
  [ShippingLineEnum.MEDR]: 'Medrepair',
  [ShippingLineEnum.MOL]: 'Mitsui OSK Lines',
  [ShippingLineEnum.MSC]: 'Mediterranean Shipping Company',
  [ShippingLineEnum.NCLA]: 'NCL North Sea Container Line AS',
  [ShippingLineEnum.NCOL]: 'Northland Container Lines,Eemshaven(NCL)',
  [ShippingLineEnum.NDR]: 'NDS West African Ro/Ro Services',
  [ShippingLineEnum.NID]: 'Nile Dutch Africa Lines',
  [ShippingLineEnum.NIR]: 'Nirint Shipping',
  [ShippingLineEnum.NOR]: 'CSAV Norasia',
  [ShippingLineEnum.NORI]: 'NORILSK',
  [ShippingLineEnum.NSA]: "Norwegian Shipowner's Association (NSA)",
  [ShippingLineEnum.NSC]: 'Natinal Shipping Company of Saudi Arabia',
  [ShippingLineEnum.NSH]: 'Northern Shipping Company',
  [ShippingLineEnum.NTR]: 'Nippon Yusen Kabushiki Kaisha (NYK)',
  [ShippingLineEnum.ONE]: 'Ocean Network Express',
  [ShippingLineEnum.OOL]: 'Orient Overseas Container Line',
  [ShippingLineEnum.OPD]: 'OPDR Germany GmbH',
  [ShippingLineEnum.OTA]: 'OT Africa Line (Otal Atlantique)',
  [ShippingLineEnum.OYC]: 'OY Containerships',
  [ShippingLineEnum.PAPE]: 'Papier',
  [ShippingLineEnum.PIL]: 'Pacific International Lines',
  [ShippingLineEnum.PTSL]: 'PTS Logistics GmbH',
  [ShippingLineEnum.PWL]: 'PERALTA WESTWARD LINE',
  [ShippingLineEnum.RCL]: 'Regional Container Line',
  [ShippingLineEnum.RCLZ]: 'Renaissance Container Lines',
  [ShippingLineEnum.ROKU]: 'Robert Kukla',
  [ShippingLineEnum.SAF]: 'Safmarine Container Lines',
  [ShippingLineEnum.SAM]: 'Samskip',
  [ShippingLineEnum.SCA]: 'SCA Transport Ab',
  [ShippingLineEnum.SCI]: 'Shipping Corporation of India',
  [ShippingLineEnum.SEA]: 'Seaconnect',
  [ShippingLineEnum.SEAG]: 'Seago Line Copenhagen',
  [ShippingLineEnum.SLF]: 'SL-Feeder',
  [ShippingLineEnum.SLS]: 'SEA LAND SERVICE',
  [ShippingLineEnum.SPH]: 'Spliethoff',
  [ShippingLineEnum.SPLI]: 'Speed Line',
  [ShippingLineEnum.STGS]: 'Stückgut / Schuppen 65',
  [ShippingLineEnum.STWP]: 'Steinweg-Packer',
  [ShippingLineEnum.SWA]: 'SWAN Container Line Ltd., Isle of Man',
  [ShippingLineEnum.SWT]: 'Süd-West-Terminal',
  [ShippingLineEnum.TAL]: 'Transatlantic Line/D.Oltmann',
  [ShippingLineEnum.TASA]: 'Trans Baltica Line',
  [ShippingLineEnum.TEA]: 'Teamline',
  [ShippingLineEnum.TLB]: 'Tschudi LINES Baltic Sea',
  [ShippingLineEnum.TLBS]: 'Transatlantic Shipping Agency',
  [ShippingLineEnum.TURK]: 'Turkon Line, Istanbul',
  [ShippingLineEnum.UAL]: 'United African Line',
  [ShippingLineEnum.UAS]: 'United Arab Shipping',
  [ShippingLineEnum.UFCS]: 'Unifeeder-Container Service',
  [ShippingLineEnum.UFE]: 'Unifeeder Aarhus',
  [ShippingLineEnum.VOLA]: 'Volans',
  [ShippingLineEnum.WAER]: 'Waertsilae',
  [ShippingLineEnum.WEC]: 'West European Container LINES',
  [ShippingLineEnum.WHL]: 'Wan Hai LINES',
  [ShippingLineEnum.XPC]: 'X-Press Container Line',
  [ShippingLineEnum.XPF]: 'X-Press Feeders',
  [ShippingLineEnum.YML]: 'Yang Ming Line',
  [ShippingLineEnum.ZIH]: 'Zheng Zhou International',
  [ShippingLineEnum.ZIM]: 'ZIM Israel Navigation Company',
};

export const shippingLinesOptions: TDefaultSelectOption<ShippingLineEnum>[] = (
  Object.keys(shippingLinesLabels) as ShippingLineEnum[]
).map((key) => ({
  label: shippingLinesLabels[key],
  value: key,
}));
