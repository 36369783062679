import { useQuery } from '@tanstack/react-query';
import { DashboardStats } from 'generated/api';
import { api } from 'api/request';

export const dashboardQueryKey = 'analyticStats';

const getDashboardData = async (): Promise<DashboardStats> => {
  const { data } = await api.getDashboardStatistics();

  return data;
};

export const useDashboardData = () => useQuery([dashboardQueryKey], () => getDashboardData());
