import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ChevronSmallIcon } from '@rouvia/icons';
import { QuotePrice } from 'components/quotePrice';
import { CargoItemUnitPrice, RailPriceBreakdown, TruckPriceBreakdown } from 'generated/api';
import { ServicesTypeRequest } from 'types/common';
import { kgToTons } from 'utils/common';
import { Item } from './item';
import styles from './styles.module.scss';

const ExpandablePriceSheetItem: React.FC<{ cargoPrices?: CargoItemUnitPrice[]; children: React.ReactNode }> = ({
  cargoPrices,
  children,
}) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const toggleExpanded = () => {
    setIsExpanded(!isExpanded);
  };

  if (!cargoPrices) {
    return (
      <div>
        <div style={{ paddingRight: 18 }}>{children}</div>
      </div>
    );
  }

  return (
    <div className={clsx(styles.expandableContainer, { [styles.containerIsExpanded]: isExpanded })}>
      <div onClick={toggleExpanded} className={styles.priceHeader}>
        <div style={{ flex: 1 }}>{children}</div>
        <div>
          <ChevronSmallIcon className={clsx(styles.chevronIcon, { [styles.expandedIcon]: isExpanded })} />
        </div>
      </div>
      {isExpanded && (
        <div className={styles.bodyContainer}>
          {cargoPrices.map(({ cargoItem, price }, index) => (
            <Item
              key={index}
              price={price}
              description={`${cargoItem.amount}x ${cargoItem.loadingUnit} (${cargoItem.size}", ${kgToTons(
                cargoItem.grossWeight,
              )}T)`}
            />
          ))}
        </div>
      )}
    </div>
  );
};

type TProps = {
  servicesClassName?: string;
  priceBreakdown: TruckPriceBreakdown | RailPriceBreakdown;
  requestedServices?: ServicesTypeRequest | null;
};

export const PriceSheet: React.FC<TProps> = ({ priceBreakdown, requestedServices, servicesClassName }) => {
  const { t } = useTranslation('default', { keyPrefix: 'components/priceSheet' });

  const [railLegPrice, setRailLegPrice] = useState<number>(0);
  const [firstMilePrice, setFirstMilePrice] = useState<number>(0);
  const [lastMilePrice, setLastMilePrice] = useState<number>(0);
  const [truckLegPrice, setTruckLegPrice] = useState<number>(0);

  let totalCost = railLegPrice + firstMilePrice + lastMilePrice + truckLegPrice;

  useEffect(() => {
    if ('railLeg' in priceBreakdown) {
      setRailLegPrice(priceBreakdown.railLeg);
      setTruckLegPrice(0);

      if ((!requestedServices || requestedServices.firstMile) && priceBreakdown.firstMile) {
        setFirstMilePrice(priceBreakdown.firstMile);
      } else {
        setFirstMilePrice(0);
      }

      if ((!requestedServices || requestedServices.lastMile) && priceBreakdown.lastMile) {
        setLastMilePrice(priceBreakdown.lastMile);
      } else {
        setLastMilePrice(0);
      }
    } else if ('allTruckLegs' in priceBreakdown) {
      setTruckLegPrice(priceBreakdown.allTruckLegs);
      setRailLegPrice(0);
    }
  }, [priceBreakdown, requestedServices]);

  return (
    <div>
      <div className={clsx(styles.services, servicesClassName)}>
        {Boolean(railLegPrice) && (
          <ExpandablePriceSheetItem
            cargoPrices={'railPriceByCargoItem' in priceBreakdown ? priceBreakdown.railPriceByCargoItem : undefined}
          >
            <Item price={railLegPrice} description={t('rail')} rail />
          </ExpandablePriceSheetItem>
        )}
        {'allTruckLegs' in priceBreakdown && (
          <ExpandablePriceSheetItem cargoPrices={priceBreakdown.priceByCargoItem}>
            <Item price={truckLegPrice} description={t('truck')} rail />
          </ExpandablePriceSheetItem>
        )}
        {Boolean(firstMilePrice) && (
          <ExpandablePriceSheetItem
            cargoPrices={
              'firstMilePriceByCargoItem' in priceBreakdown ? priceBreakdown.firstMilePriceByCargoItem : undefined
            }
          >
            <Item price={firstMilePrice} description={t('firstMile')} />
          </ExpandablePriceSheetItem>
        )}
        {Boolean(lastMilePrice) && (
          <ExpandablePriceSheetItem
            cargoPrices={
              'lastMilePriceByCargoItem' in priceBreakdown ? priceBreakdown.lastMilePriceByCargoItem : undefined
            }
          >
            <Item price={lastMilePrice} description={t('lastMile')} />
          </ExpandablePriceSheetItem>
        )}
      </div>

      <QuotePrice
        label={'railLeg' in priceBreakdown ? t('estimatedPriceLabel') : t('truckPriceLabel')}
        price={totalCost}
      />
    </div>
  );
};
